import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Grid,
  Typography,
  Button,
  Modal,
  IconButton,
  Link,
} from "@mui/material";
import {
  ArrowBack,
  KeyboardArrowLeftTwoTone,
  KeyboardArrowRightTwoTone,
} from "@mui/icons-material";
import { AgGridReact } from "ag-grid-react";
import {
  GridReadyEvent,
  GridOptions,
  FilterChangedEvent,
  SortChangedEvent,
  ColDef,
  SideBarDef,
} from "ag-grid-community";
import {
  getScreenDataCount,
  getScreenDataByPagination,
} from "src/services/screen.service";
import { useNavigate } from "react-router";
import DynamicScreen from "./DynamicScreen";
import * as XLSX from "xlsx";

const FarmerProfile = (): JSX.Element => {
  const [data, setData] = useState<any[]>([]);
  const [dataCount, setTotalCount] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [columnFilter, setColumnFilters] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>();

  const gridRef = useRef<AgGridReact>(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridRef?.current?.api?.sizeColumnsToFit();
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  const gridOptions: GridOptions = {
    rowData: data,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      setModalData(e.data);
      setOpenModal(true);
    },
  };

  const onPaginationChanged = useCallback(() => {}, []);

  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
  };

  const handleColumnFilter = (event: FilterChangedEvent) => {
    const filterModel = event.api.getFilterModel();
    const whereClause: any = { and: [] };

    if (Object.keys(filterModel).length > 0) {
      Object.entries(filterModel).forEach(([column, filter]) => {
        const { type, filterType, filter: filterValue } = filter;
        let filterCondition: any = {};
        switch (filterType) {
          case "text":
            filterCondition[column] = {
              like: `.*${filterValue}.*`,
              options: "i",
            };
            break;
          case "number":
            switch (type) {
              case "equals":
                filterCondition[column] = filterValue;
                break;
              case "notEqual":
                filterCondition[column] = { neq: filterValue };
                break;
              case "lessThan":
                filterCondition[column] = { lt: filterValue };
                break;
              case "lessThanOrEqual":
                filterCondition[column] = { lte: filterValue };
                break;
              case "greaterThan":
                filterCondition[column] = { gt: filterValue };
                break;
              case "greaterThanOrEqual":
                filterCondition[column] = { gte: filterValue };
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        whereClause.and.push(filterCondition);
      });
    }
    setColumnFilters(whereClause.and);
  };

  useEffect(() => {
    setSkip(0);
    getScreenDataByPagination("farmer-profiles", 0, columnFilter).then(
      (res: any) => {
        setData(res);
      }
    );

    getScreenDataCount("farmer-profiles", columnFilter).then((res: any) => {
      let cnt = Math.round(res.count / 30);

      if (res.count % 30 !== 0) cnt += 1;
      setTotalCount(cnt);
    });
  }, [columnFilter]);

  useEffect(() => {
    getScreenDataByPagination("farmer-profiles", skip, columnFilter).then(
      (res: any) => {
        setData(res);
      }
    );

    getScreenDataCount("farmer-profiles", columnFilter).then((res: any) => {
      let cnt = Math.round(res.count / 30);

      if (res.count % 30 !== 0) cnt += 1;
      setTotalCount(cnt);
    });
  }, [skip, openModal]);

  const onBtNext = () => {
    setSkip(skip + 30);
  };

  const onBtPrevious = () => {
    setSkip(skip - 30);
  };

  const PaginationIconProps = {
    fontSize: "small",
    color: "action",
  };

  const columnDefs = useMemo(() => {
    if (!data || data.length === 0 || !data[0]?.profile) {
      return [];
    }

    const profileKeys = Object.keys(data[0].profile);

    const dynamicCols: ColDef[] = profileKeys.map((key) => ({
      headerName: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " "),
      field: `profile.${key}`,
      sortable: true,
      filter: true,
    }));

    return dynamicCols;
  }, [data]);

  const exportToExcel = () => {
    const profileData = data.map((item) => {
      const profileFields: any = { ...item.profile };
      return profileFields;
    });
  
    const headers = profileData.length > 0 ? Object.keys(profileData[0]) : [];
  
    const ws = XLSX.utils.json_to_sheet(profileData, { header: headers });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Farmer Profiles");
  
    XLSX.writeFile(wb, "Farmer_Profiles.xlsx");
  };

  const navigate = useNavigate();

  return (
    <Grid my={2} mx={"auto"}>
      <div style={gridStyle} className="ag-theme-alpine">
        <Grid p={3}>
          <Grid
            borderRadius={20}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid display={"flex"} item alignItems={"center"} margin={1}>
              <IconButton onClick={() => navigate("/")}>
                <ArrowBack />
              </IconButton>
              <Typography m={1} variant="h5">
                Farmer Profile
              </Typography>
            </Grid>
            <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"flex-end"}
            >
              <input
                type="button"
                value="Add"
                style={{
                  padding: "10px",
                  fontSize: 18,
                  paddingTop: "10px",
                  alignSelf: "center",
                }}
                onClick={() => {
                  setOpenModal(true);
                  setModalData(null);
                }}
              ></input>
              <input
                type="button"
                value="Export to Excel"
                style={{
                  padding: "10px",
                  fontSize: 18,
                  paddingTop: "10px",
                  alignSelf: "center",
                  marginLeft: 8,
                }}
                onClick={exportToExcel}
              ></input>
            </Grid>
          </Grid>
          <AgGridReact
            ref={gridRef}
            rowData={data}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            sideBar={sideBar}
            animateRows={true}
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            domLayout="autoHeight"
            onPaginationChanged={onPaginationChanged}
            onSortChanged={handleColumnSort}
            onFilterChanged={handleColumnFilter}
          ></AgGridReact>
          <Grid
            container
            py={2}
            justifyContent={"flex-end"}
            alignItems={"center"}
            border={1}
            borderColor={"silver"}
            bottom={0}
            left={0}
            right={0}
          >
            <Button onClick={onBtPrevious} disabled={skip === 0}>
              <KeyboardArrowLeftTwoTone {...(PaginationIconProps as any)} />
            </Button>

            <Typography>
              Page {Math.round(skip / 30) + 1} of {dataCount}
            </Typography>

            <Button
              onClick={onBtNext}
              disabled={skip === (dataCount - 1) * 30}
              id="btNext"
            >
              <KeyboardArrowRightTwoTone {...(PaginationIconProps as any)} />
            </Button>
          </Grid>
        </Grid>
      </div>

      <Modal
        open={openModal}
        style={{ overflow: "scroll" }}
        onClose={() => setOpenModal(false)}
      >
        <Grid
          width={"95%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <DynamicScreen
            openModal={openModal}
            setOpenModal={setOpenModal}
            screenTitle={"Farmer Profile"}
            screenType={"farmerProfile"}
            modalData={modalData}
          />
        </Grid>
      </Modal>
    </Grid>
  );
};

export default FarmerProfile;
