import { CORE_API } from "../constants/api";
import { getData, postData, deleteData } from "./common.service";


export const getLAIAdvisory = async (cropId: string) => {
    const url = "/lai-advisories?filter=" + JSON.stringify({
        where: {
            cropId: cropId
        }
    });
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API
    })
}

export const getLAIAdvisorById = async (id: string) => {
    const url = "/lai-advisories/" + id;
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API
    })
}

export const postLAIAdvisory = async (payload: any) => {
    const url = `/lai-advisories`;
    return postData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
        body: payload
    })
}

export async function putLAIAdvisory(payload: any, id: any) {
    const url = `/lai-advisories/${id}`;

    return postData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
        usePutMethod: true,
        body: payload
    })
}

export async function deleteLAIAdvisory(id: any) {
    const url = `/lai-advisories/${id}`

    return deleteData(url, {
        basePath: CORE_API,
        sendTokenInHeader: true,
        useDeleteMethod: true
    })
}

