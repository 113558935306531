import React, { useState } from 'react';

export type ButtonClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;


type Props = {
  onClick?: (e: ButtonClickEvent) => void;
  onDoubleClick?: (e: ButtonClickEvent) => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

const Button: React.FC<Props> = ({ onClick, onDoubleClick, children, style }) => {
  // State to track the click count and timer for double click
  const [clickCount, setClickCount] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const handleClick = (e: ButtonClickEvent) => {
    // Increment click count
    setClickCount((prevCount) => prevCount + 1);

    // Start timer for double click
    if (clickCount === 0) {
      const newTimer = setTimeout(() => {
        // If no second click, treat as single click
        setClickCount(0);
        // Call onClick handler if provided
        if (onClick) {
          onClick(e);
        }
      }, 300); // Adjust the time according to your preference
      setTimer(newTimer);
    }
  };

  // Handle double click
  const handleDoubleClick = (e: ButtonClickEvent) => {
    // Clear timer for single click
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    // Call onDoubleClick handler if provided
    setClickCount(0);
    if (onDoubleClick) {
      onDoubleClick(e);
    }
  };

  return (
    <button
      style={{
        borderWidth: 0,
        borderRadius: 10,
        boxShadow: '0 0 5px rgb(100, 100, 100, 0.3)',
        padding: '5px 10px',
        fontSize: 12,
        backgroundColor: 'inherit',
        textAlign: 'start',
        ...style,
      }}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      {children}
    </button>
  );
};

export default Button;
