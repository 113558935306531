import { Grid } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { FC, memo, useEffect, useState } from 'react';
import { Skeleton } from 'src/components';
import { fetchActiveUsers } from 'src/services/userEvent.service';
import { TTimeRange, TUserActivity, TUsersData } from '../types';
import { usersTableCols } from '../utils/colDefs';

type Props = TTimeRange;

const UsersTable: FC<Props> = (props) => {
  const { startTime, endTime } = props;

  const [activeUsers, setActiveUsers] = useState<TUsersData>({
    loading: true,
    data: []
  });

  useEffect(() => {
    if(!!startTime && !!endTime) {
      getActiveUsers();
    }
  }, [startTime, endTime])

  /**
   * @function
   * fetches active users for selected time range
   * */ 
  const getActiveUsers = async () => {
    let data: TUserActivity[] = [];
    setActiveUsers((activeUsers) => ({...activeUsers, loading: true}));
    try {
        /**
       * TODO: count variable leke chal rahe for top 10, iska kuch karna padega
       * */ 
      const apiData = await fetchActiveUsers(startTime, endTime);
      let cnt = 0;
      apiData.sort((a, b) => b.eventCount - a.eventCount);
      for(const activity of apiData) {
        if(cnt === 10) break;
        cnt++;
        const { userId, eventCount } = activity;
        data.push({ index: cnt, userId, eventCount });
      }
    } catch (error) {
      data = [];
    } finally {
      setActiveUsers({ loading: false, data: data! });
    }
  }

  return (
    <Grid height={472} className="ag-theme-alpine" p={0} m={0}>
      {
        activeUsers.loading ? 
          <Skeleton.Table width={200} height={400} noOfRows={7} /> : 
          <AgGridReact 
            columnDefs={usersTableCols}
            rowData={activeUsers.data || []}
          />
      }
    </Grid>
  )
}

export default memo(UsersTable);