import { isOpenURL } from "src/utils/helper";
import { logoutUser } from "./user.service";
 
export const Regex = {
  id: /^[a-z0-9]+$/,
  mobile: /^([0-9]{10})$/,
  latitude: /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
  longitude: /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
};
 
export interface APIOptions {
  basePath?: string,
  sendTokenInUrl?: boolean,
  sendTokenInHeader?: boolean,
  body?: any,
  headers?: any,
  usePutMethod?: boolean,
  useDeleteMethod?: boolean,
  usePatchMethod?: boolean,
  isBlob?: boolean,
  signal?: any;
}

export const SignalAboutErrorMessage = "signal is aborted without reason";
 
export async function handleErrors(response: Response) {
  if (!response.ok) {
    const err = await response.json();
    throw Error(err.error.message);
  }
  return response.status === 200 ? response.json() : undefined;
}
 
export function getAccessToken(): string {
  return localStorage.getItem('access_token') as string;
}
 
export function setAccessToken(access_token: string) {
  localStorage.setItem('access_token', access_token);
}
 
export function clearAccessToken() {
  localStorage.removeItem('access_token');
}

export function clearBotChatId() {
  localStorage.removeItem('botChatId');
}
 
function addAccessToken(url: string): string {
  const qsSeperator = url.includes('?') ? '&' : '?';
  return url.concat(qsSeperator + 'access_token=' + getAccessToken());
}
 
async function makeApiRequest(
  url: string,
  options: APIOptions = {},
  method: string
) {
  const basePath = options.basePath || process.env.REACT_APP_API_BASEPATH;
  let finalUrl = `${basePath}${url}`;

  const tokenRequiredInUrl = !!options.sendTokenInUrl;
  if (tokenRequiredInUrl) {
    finalUrl = addAccessToken(finalUrl);
  }
 
  const finalOptions: any = {
    method,
    headers: { ...options.headers },
  };
 
  const tokenRequiredInHeader = !!options.sendTokenInHeader;
  if (tokenRequiredInHeader) {
    if (!finalOptions.headers) finalOptions.headers = {};
    finalOptions.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  }
 
  if (!finalOptions.headers?.hasOwnProperty("content-type")) {
    if (!finalOptions.headers) finalOptions.headers = {};
    finalOptions.headers["content-type"] = "application/json; charset=utf-8";
  }
 
  if (options.body) {
    finalOptions.body = JSON.stringify(options.body);
  }

  if(options.signal) {
    finalOptions.signal = options.signal;
  }
 
  const response = await fetch(finalUrl, finalOptions as RequestInit);
 
  try {
    const responseData = options.isBlob
      ? await response.blob()
      : await response.json();
 
    if (responseData.error) {
      const { statusCode } = responseData.error;
 
      switch (statusCode) {
        case 401:
          if (!isOpenURL(window.location.pathname)) {
            logoutUser().then(() => {
              window.location.replace("/login");
            });
          }
          break;
        default:
          return responseData;
      }
    } else {
      return responseData;
    }
  } catch {
    return { message: "Success" };
  }
}
 
export async function getData(url = "", options: APIOptions = {}) {
  return makeApiRequest(url, options, "get");
}
 
export async function postData(url = "", options: APIOptions = {}) {
  const method = options.usePutMethod
    ? "put"
    : options.usePatchMethod
    ? "PATCH"
    : "post";
  return makeApiRequest(url, options, method);
}
 
export async function deleteData(url = "", options: APIOptions = {}) {
  const method = options.useDeleteMethod ? "delete" : "post";
  return makeApiRequest(url, options, method);
}
 
export async function updateData(url = "", options: APIOptions = {}) {
  const method = options.usePatchMethod ? "patch" : "post";
  return makeApiRequest(url, options, method);
}
 
 
export const toInputLowercase = (e: any) => {
  e.target.value = ('' + e.target.value).toLowerCase();
};