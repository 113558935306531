import React, { useEffect } from 'react';
import './AddSacles.css';
import { Typography } from '@mui/material';

export const SCALES = ["Nill", "Low", "Medium", "High"];

type Props = {
	scales: Scale[],
	updateScales?: (scale: Scale[]) => void;
	edit?: boolean;
	maxLimit?: number
}
type TScale = number;
type ScaleValue = number | null;
type From = ScaleValue;
type To = ScaleValue;

export type Scale = [TScale, From, To];

const AddScales = (props: Props) => {
	const { scales, updateScales, edit, maxLimit } = props;

	useEffect(() => {
		if (!edit || scales.length === 0) {
			initializeScales();
		}
	}, []) // eslint-disable-line

	const initializeScales = () => {
		const scales = SCALES.map((_, index) => [index, null, null]) as Scale[];
		if (!!updateScales)
			updateScales(scales);
	}

	const handleInput = (e: React.FormEvent<HTMLTableElement>) => {
		const { name, value } = e.target as any;

		const rowIndex = Number(name.split("-")[1]);
		const colIndex = name.split("-")[0] === "from" ? 0 : 1;

		const newScales = [...scales];
		newScales[rowIndex][colIndex + 1] = (isNaN(value) || !value) ? null : Number(value);

		if (!!updateScales) updateScales(newScales);
	}

	return (
		<div>
			<Typography variant="subtitle2">Ratings</Typography>

			<table onInput={handleInput} className='scales-table'>
				<thead>
					<tr>
						<th>From</th>
						<th>To</th>
						<th>Rating</th>
					</tr>
				</thead>

				<tbody>
					{
						SCALES.map((scale, index) => {
							const fromValue = getScaleValue(scales, index, 1);
							const toValue = getScaleValue(scales, index, 2, maxLimit);

							const isFirstRow = index === 0;
							const isLastRow = index === scales.length - 1;

							return (
								<tr key={`rating-${index}`}>
									<td>
										<input
											type="number"
											disabled={isFirstRow || !updateScales}
											defaultValue={isFirstRow ? 0 : fromValue!}
											name={`from-${index}`}
											id={`from-${index}`}
										/>
									</td>
									<td>
										<input
											type="number"
											disabled={isLastRow || !updateScales}
											defaultValue={toValue!}
											name={`to-${index}`}
											id={`to-${index}`}
										/>
									</td>
									<td>{scale}</td>
								</tr>
							)
						})
					}
				</tbody>
			</table>
		</div>
	)
}

export default AddScales;

export const validateScales = (newScales: Scale[], rowIndex: number, colIndex: number): boolean => {
	// Check if rowIndex and colIndex are valid
	if (rowIndex < 0 || rowIndex >= newScales.length || colIndex < 0 || colIndex >= 3) {
		return false; // Invalid index
	}

	// Check if the current row is the first or last row
	const isFirstRow = rowIndex === 0;
	const isLastRow = rowIndex === newScales.length - 1;

	// Get the current value
	const currentValue = newScales[rowIndex][colIndex];

	// Check if the current value is null
	if (currentValue === null) {
		// If it's the first row, the null value should be at index 1
		// If it's the last row, the null value should be at index 2
		return (isFirstRow && colIndex === 1) || (isLastRow && colIndex === 2);
	}

	// Check if the current value is less than any values of previous rows
	for (let i = 0; i < rowIndex; i++) {
		const minValue = Math.max(newScales[i][2]!, newScales[i][1]!);
		if (currentValue < minValue) {
			return false;
		}
	}

	// if it's `TO`, check if it's less than `FROM` of same row
	if (colIndex === 2) {
		if (currentValue < newScales[rowIndex][1]!) {
			return false;
		}
	}

	return true;
};

export const getScaleValue = (scales: Scale[], rowIndex: number, colIndex: number, maxLimit: number = 0) => {
	if (!scales || !Array.isArray(scales)) return null;
	if (!scales[rowIndex] || !Array.isArray(scales[rowIndex])) return null;

	// last row, last col
	const isLastElement = rowIndex + 1 === scales.length && colIndex + 1 === scales[0].length;
	if (isLastElement) {
		return maxLimit ?? null;
	}

	return (typeof scales[rowIndex][colIndex] === "number") ? scales[rowIndex][colIndex] : null
}