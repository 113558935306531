import { CORE_API } from "../constants/api";
import { getData, postData, deleteData } from "./common.service";


export const getSoilStandard = async (region: string) => {
    const url = "/soil-standards?filter=" + JSON.stringify({
        where: {
            region: region
        }
    });
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API
    })
}


export const postSoilStandard = async (payload: any) => {
    const url = `/soil-standards`;
    return postData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
        body: payload
    })
}

export async function patchSoilStandard(payload: any, id: any) {
    const url = `/soil-standards/${id}`;

    return postData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
        usePatchMethod: true,
        body: payload
    })
}

export async function deleteSoilStandard(id: any) {
    const url = `/soil-standards/${id}`

    return deleteData(url, {
        basePath: CORE_API,
        sendTokenInHeader: true,
        useDeleteMethod: true
    })
}

