import React, { useMemo } from 'react'
import { RecentObservation } from '../sections/CardSection'
import dayjs from 'dayjs'
import Button, { ButtonClickEvent } from 'src/components/Button'

type Props = {
  observation: RecentObservation;
  onClick?: (id: string) => void;
  onDoubleClick?: (id: string) => void;
}

const COLORS = {
  charcoal: "#36454F",
  gray: "#808080",
  darkText: "#2A2D2F"
}

const ObservationCard: React.FC<Props> = (props) => {
  const { observation, onClick, onDoubleClick } = props;
  console.log("🚀 ~ observation:", observation)

  const { id, observationDate, probability, riskLevel, diseasePestId, modelPrediction, modelRiskLevel } = observation;
  const name = observation?.name ?? "";


  // styles
  const Style = useMemo((): Record<string, React.CSSProperties> => ({
    card: {
      borderWidth: 0,
      borderRadius: 10,
      boxShadow: "0 0 5px rgb(100, 100, 100, 0.3)",
      padding: "5px 10px",
      fontSize: 12,
      backgroundColor: "inherit",
      textAlign: "start",
      minWidth: 200,
      color: COLORS.charcoal,
      minHeight: 250
    },
    heading: {
      margin: 0,
    },
    value: {
      display: "block",
      marginTop: "5px",
      fontSize: "14px",
      color: COLORS.darkText,
      textTransform: "capitalize"
    },
    dataTitle: {
      display: "inline-block",
      margin: "5px 0px",
      fontWeight: "600"
    },
    data: {
      margin: 0,
    },
    footer: {
      display: "flex",
      justifyContent: "flex-end",
      color: COLORS.gray,
      fontSize: "10px",
      fontWeight: "500",
      margin: 0
    }
  }), []);

  // event handlers
  const handleClick = (event: ButtonClickEvent) => {
    event.stopPropagation();
    if (!!onClick) {
      onClick(diseasePestId);
    }
  }

  const handleDoubleClick = (event: ButtonClickEvent) => {
    if (!!onDoubleClick) {
      onDoubleClick(id);
    }
  }

  return (
    <Button
      style={Style.card}
      onClick={handleClick}
      onDoubleClick={handleDoubleClick}
    >
      <div style={Style.heading}>
        <p style={Style.value}>{name}</p>

        <p>
          <span style={Style.dataTitle}>Disease/Pest: </span>
          <span style={Style.value}>{diseasePestId}</span>
        </p>
      </div>

      <div style={Style.data}>
        <div>
          <span style={Style.dataTitle}> Probability </span>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <span> Observed </span>
              <span style={Style.value}> {Number(probability).toFixed(2)}% </span>
            </span>
            <span>
              <span> Predicted: </span>
              <span style={Style.value}>
                {isNaN(Number(modelPrediction)) ?
                  "-" :
                  Number(modelPrediction).toFixed(2) + "%"}
              </span>
            </span>
          </div>
        </div>
        <div>
          <span style={Style.dataTitle}> Risk Level </span>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <span> Observed </span>
              <span style={Style.value}> {riskLevel} </span>
            </span>
            <span>
              <span> Predicted: </span>
              <span style={Style.value}> {modelRiskLevel ?? "-"} </span>
            </span>
          </div>
        </div>
      </div>

      <div style={Style.footer}>
        <p>{dayjs(observationDate).format("DD-MM-YYYY")}</p>
      </div>
    </Button>
  )
}

export default ObservationCard