import {
  useEffect, useState
} from "react";

export default function Location(props: any) {
  const [locaString, setLocaString] = useState('');

  useEffect(() => {
    const parentDistrict = props.data.district;
    const locationDistrict = props.data?.plotLocation?.location?.SupplementalAdminAreas[0]?.EnglishName;
    const locationState = props.data?.plotLocation?.location?.AdministrativeArea?.EnglishName;
    const locationArr = [];

    if (locationDistrict != undefined) {
      locationArr.push(locationDistrict);
    }
    if (locationState != undefined) {
      locationArr.push(locationState);
    } else {
      locationArr.push(parentDistrict);
    }
    const locaString = locationArr.join(",");
    setLocaString(locaString);

  }, []);

  return (
    <div style={{ marginTop: '-14px' }}>
      <p>{locaString}</p>
    </div>
  );
}
