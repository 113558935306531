import React, { useEffect, useRef, useState } from "react";
import { TObservationType } from "..";
import { Button, Grid, Typography } from "@mui/material";
import { InputChip } from "./InputChips";
import { postObservationType, updateObservationType } from "src/services/observationType.service";
import AddScales, { Scale, validateScales } from "./AddScales";
import CropFilter, { CropPayload, DiseasePestCategory } from "src/components/Filters/crop";

const AddObservationType: React.FC<Props> = (props) => {
  const { appendObservationType, updateObservationType: updateGridData, data } = props;

  const [cropDetails, setCropDetails] = useState<CropDetails>();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [columns, setColumns] = useState<string[]>([]);
  const [subColumns, setSubColumns] = useState<string[]>([]);
  const [scales, setScales] = useState<Array<Scale>>([]);

  const columnsRef = useRef<HTMLInputElement>(null);
  const subColumnsRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!!data && Object.keys(data).length > 0) {
      preFillValues();
    }
  }, []); // eslint-disable-line

  const preFillValues = () => {
    // dropdown options will be prefilled after the options are fetched
    const { name, columns, subColumns, scale, description } = data as TObservationType;
    setName(name);
    setColumns(columns || []);
    setSubColumns(subColumns || []);
    setScales(scale);
    setDescription(description);
  }

  /**
   * API Calls
   */
  const save = async (observationType: TObservationType) => {
    try {
      const response = await postObservationType(observationType);
      if (!!response.error) throw new Error("Something went wrong");
      appendObservationType(response);
    } catch (error) {
      console.error(error);
    }
  }

  const update = async (observationtype: TObservationType) => {
    try {
      const response = await updateObservationType(observationtype);
      if (!!updateGridData) {
        updateGridData(observationtype);
      }
      if (!!response.error) throw new Error("Something went wrong");
    } catch (error) {
      console.error(error);
    }
  }


  /**
   * form handlers
   */

  const handleCropFilter = (details: any) => {
    const { id, category, diseasePest } = details as CropPayload;

    setCropDetails({
      cropId: id,
      diseasePestId: diseasePest!,
      category: category!
    })
  } 

  const handleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    const regex = /[^a-zA-Z0-9_ ]/g;

    value = value.replace(regex, '');

    setName(value);
};

  const handleDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setDescription(value);
  };

  /**
   * Columns and Sub columns handlers
   */
  const appendColumn = () => {
    if (!columnsRef.current) return;

    const value = columnsRef.current!.value.trim().replace(/\./g, '');
    if (value.length === 0) return;

    setColumns((columns) => [...columns, value]);
    columnsRef.current.value = "";
  };

  const removeColumn = (index: number) => {
    setColumns((cols) => cols.filter((_, i) => index !== i));
  };

  const appendSubColumn = () => {
    if (!columnsRef.current) return;

    const value = subColumnsRef.current!.value.trim().replace(/\./g, '');
    if (value.length === 0) return;

    setSubColumns((subColumns) => [...subColumns, value]);
    subColumnsRef.current!.value = "";
  };

  const removeSubColumn = (index: number) => {
    setSubColumns((subCols) => subCols.filter((_, i) => index !== i));
  };

  /**
   * OnKeyUp listeners
   * to append data on "Enter" press
   */

  const handleColumnsKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      appendColumn();
    }
  };

  const handleSubColumnsKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      appendSubColumn();
    }
  };

  /**
   * Scale handlers
   */
  const updateScales = (newScales: Scale[]) => {
    setScales(newScales);
  }

  const saveObservationType = () => {
    if (!(!!cropDetails?.cropId && !!cropDetails.diseasePestId)) return;

    if (!checkStates()) {
      return;
    }

    const observationType: TObservationType = {
      ...cropDetails,
      scale: scales,
      name: name.trim(),
      description: description.trim(),
      columns,
      subColumns,
      // attachments: attachments.map(attachment => attachment.image
    }

    if (!!data && !!data.id) {
      observationType.id = data.id;
      update(observationType);
    } else {
      save(observationType);
    }
  }

  const checkStates = (): boolean => {
    for (const ind in scales) {
      const index = Number(ind);

      const isLastRangeNull = (index === scales.length - 1 && scales[index][2] === null);

      const checkCol1 = (index === 0) ? true : validateScales(scales, index, 1);
      const checkCol2 = isLastRangeNull ? true : validateScales(scales, index, 2);
      if (!(checkCol1 && checkCol2)) {
        alert(`Ranges at row ${index + 1} cannot be smaller than any previous ranges`);
        return false;
      }
    }

    return true;
  }

  const submitButtonDisabled = !(!!cropDetails?.cropId && !!cropDetails.diseasePestId && columns.length > 0 && name.length > 0);

  return (
    <div>
      <Grid container spacing={2} my={1}>
        <Grid container px={2}>
          <CropFilter
            hideTitle
            defaultBackgroundColor="white"
            dropdowns={["crop", "disease-pest-merged"]}
            onChange={handleCropFilter}
            defaultValue={{
              cropId: data?.cropId,
              diseasePestId: data?.diseasePestId,
              isDisease: data?.isDisease,
              isPest: data?.isPest
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">Name</Typography>
          <input value={name} onChange={handleName} style={InputFieldStyles} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2"> Description </Typography>
          <textarea
            name="observation-type-description"
            id="observation-type-description"
            style={{ ...InputFieldStyles, height: "100px", padding: "10px" }}
            value={description}
            onChange={handleDescription}
          ></textarea>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <InputChip
            title="Columns"
            inputRef={columnsRef}
            handleKeyUp={handleColumnsKeyUp}
            onAdd={appendColumn}
            chips={columns}
            removeChip={removeColumn}
          />
        </Grid>

        <Grid item xs={6}>
          <InputChip
            title="Sub Columns"
            inputRef={subColumnsRef}
            handleKeyUp={handleSubColumnsKeyUp}
            onAdd={appendSubColumn}
            chips={subColumns}
            removeChip={removeSubColumn}
          />
        </Grid>
      </Grid>

      <Grid>
        <AddScales 
          edit={!!data} 
          scales={scales} 
          updateScales={updateScales} 
          maxLimit={cropDetails?.category === "disease" ? 100 : Infinity} 
        />
      </Grid>

      <Grid mt={5}>
        <Button
          fullWidth
          variant="contained"
          color="success"
          onClick={saveObservationType}
          disabled={submitButtonDisabled}
        >
          Submit
        </Button>
      </Grid>
    </div>
  );
};

export default AddObservationType;

export const CustomIconButton: React.FC<any> = ({ onClick }) => (
  <Button
    size="small"
    color="primary"
    variant="contained"
    onClick={onClick}
    style={{ borderRadius: "5px", fontSize: "14px", textTransform: "capitalize" }}
  >
    Add
  </Button>
)


/**
 * Types & Constants
 */

type Props = {
  appendObservationType: (data: TObservationType) => void;
  popLastObservationType: () => void;
  updateObservationType?: (data: TObservationType) => void;
  data?: TObservationType;
};

type CropDetails = {
  cropId: string;
  diseasePestId: string;
  category: DiseasePestCategory;
}

export const InputFieldStyles: React.CSSProperties = {
  height: "35px",
  borderRadius: "5px",
  border: "1px solid darkgray",
  width: "98%",
  padding: "1px 5px",
  fontSize: "16px",
  color: "#303234",
};