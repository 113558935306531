import { Grid, IconButton } from '@mui/material'
import { Download } from '@mui/icons-material'
import { ChartData } from 'chart.js';
import React from 'react'
import { Line } from 'react-chartjs-2';
import { validateLocaleAndSetLanguage } from 'typescript';
import { Mappings, UnitMapping } from '../../constants/WordMapping'
import { getDate } from '../../utils/helper';
import { getExcelData } from 'src/pages/Dashboard/utils';

interface ChartsProps {
  datafields: any[];
  skipFields?: string[];
  chartData: any[];
  chartWidth?: string | number;
  isPestDisease?: boolean
  isDaily?: boolean
}

const DisplayCharts: React.FC<ChartsProps> = (props: ChartsProps): JSX.Element => {
  const { datafields, skipFields, chartData, chartWidth = '45%', isPestDisease = false, isDaily=false } = props

  const footer = (tooltipItems: any) => {
    const { dataIndex } = tooltipItems[0]
    const time = chartData[dataIndex]?.timestamp
    if(isDaily)
      return 'Date: ' + getDate(time, 'MMMM Do YYYY');
    return 'Time: ' + getDate(time, 'lll');
  };

  const downloadGridData = async (field: any, chartData: any) => {
    try {
      const response = chartData?.filter((data: any) => data[field] !== undefined)?.map((item: any) => ({ [field]: item[field], date: item.timestamp, riskLevel: item[field+"_riskLevel"], field }));
      const columnsToRender = ["date", field, 'riskLevel']
      getExcelData(response, columnsToRender ?? [], true);
    } catch (err: any) {
      alert("Something went wrong")
    }
  }

  return (
    <Grid container justifyContent={'space-around'}>
      {
        datafields?.map((field) => {
          if (skipFields?.includes(field)) {
            return <span key={field}></span>
          }
          const graphData = generateGraphData(field, chartData)
          const options = {
            responsive: true,
            plugins: {
              legend: {
                position: 'top' as const,
              },
              title: {
                display: true,
                text: Mappings[field] ?? field,
              },
              tooltip: {
                callbacks: {
                  footer: footer,
                  // label: function(tooltipItem: any){
                  //   return 'custom tool tip'
                  // },
                }
              }
            },
            scales: {
              x: {
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 5,
                  minRotation: 0,
                  maxRotation: 0
                },
              },
              y: {
                title: {
                  color: 'green',
                  display: true,
                  text: UnitMapping[field] ?? ''
                },
                gridLines: {
                  display: false,
                  color: "rgba(0, 0, 0, 0)",
                }
              }
            }
          };
          return (
            <Grid width={chartWidth} key={field} >
              {isPestDisease && (
                <Grid position={'absolute'} paddingLeft={25}>
                  <IconButton data-testid="download-excel-onHome" onClick={() => downloadGridData(field, chartData)} > <Download /> </IconButton>
                </Grid>
              )}
              <Line options={options} data={graphData as ChartData<"line", any, unknown>} />
            </Grid>
          )
        })
      }
    </Grid>
  )
}

const generateGraphData = (field: string, data: any[]) => {
  const labels = data?.map((data: any) => getDate(data.timestamp, 'DD/MM'));
  return {
    labels,
    datasets: [
      {
        label: Mappings[field] ?? field,
        data: data?.map((data: any) => data[field]),
        borderColor: 'green',
        backgroundColor: 'green',
      }
    ]
  }
}


export default DisplayCharts