import React, { useEffect, useState } from 'react';
import { FilterProps } from '../types';
import Header from '../Header';
import { deleteButton, resetButton, submitButton } from '../action-buttons';
import { debounce } from 'lodash';
import { Grid } from '@mui/material';

const PlotFilter: React.FC<FilterProps> = (props) => {
  const {
    actionButtonType,
    defaultBackgroundColor,
    errorBackgroundColor,
    filterId,
    hasDeleteButton,
    hasResetButton,
    hasSubmitButton,
    submittedBackgroundColor,
    onChange,
    onDelete,
    onReset,
    onSubmit,
  } = props;

  const [plotFilter, setPlotFilter] = useState<Plot>({});

  const [isApplied, setIsApplied] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>();

  useEffect(() => {
    if (!!onChange) {
      onChange(plotFilter, filterId);
    }
  }, [plotFilter]); // eslint-disable-line

  // filter handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPlotFilter(plotFilter => ({ ...plotFilter, [name]: value.trim() }));
  }

  const debouncedHandleChange = debounce(handleChange, 400);

  const onFilterSubmit = () => {
    const payload = { ...plotFilter };
    if (!payload.id) {
      delete payload.id;
    }

    if (!payload.servicedBy) {
      delete payload.servicedBy;
    }

    if (!payload.tag) {
      delete payload.tag;
    }

    if (!!onSubmit) {
      onSubmit(payload, filterId);
    }
  }

  const onFilterReset = () => {
    if (!!onReset) {
      onReset(filterId ?? "");
    }

    // reset isApplied and error states
    setIsApplied(false);
    setHasError("");
  }

  const onFilterDelete = () => {
    setIsApplied(false);
    if (!!onDelete) {
      onDelete(filterId ?? "");
    }
  }

  /**
   * background colors for the filter
   */
  let backgroundColor = "#D6D6D6";
  if (defaultBackgroundColor) {
    backgroundColor = defaultBackgroundColor;
  }

  if (isApplied && submittedBackgroundColor) {
    backgroundColor = submittedBackgroundColor;
  }

  if (isApplied && !!hasError && errorBackgroundColor) {
    backgroundColor = errorBackgroundColor;
  }

  return (
    <Grid my={2}>
      <Header title='Plot' />

      <Grid container justifyContent={"space-between"} alignItems={"center"} borderRadius={2} p={1} style={{ backgroundColor }}>
        <InputField name='id' placeholder='Plot Id' label='Plot Id' onChange={debouncedHandleChange} />
        <InputField name='tag' placeholder='Tag' label='Tag' onChange={debouncedHandleChange} />
        <InputField name='servicedBy' placeholder='Serviced By' label='Serviced By' onChange={debouncedHandleChange} />

        <Grid item xs={2} mt={1} display={"flex"} justifyContent={"flex-end"} gap={"5px"}>
          {submitButton({ show: hasSubmitButton, onClick: onFilterSubmit, actionButtonType })}
          {resetButton({ show: hasResetButton, onClick: onFilterReset, actionButtonType })}
          {deleteButton({ show: hasDeleteButton, onClick: onFilterDelete, actionButtonType })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PlotFilter;

type InputFieldProps = {
  name: string;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
}

const InputField: React.FC<InputFieldProps> = ({
  name,
  placeholder,
  label,
  onChange,
}) => {
  return (
    <Grid item xs={3} display={"flex"} flexDirection={"column"}>
      <label>{label}</label>
      <input
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        style={{
          width: "95%",
          height: "35px",
          borderRadius: "5px",
          border: "0px",
          paddingLeft: "10px",
          fontSize: "16px",
          marginTop: "4px"
        }}
      />
    </Grid>
  )
}

/**
 * ================== TYPES ======================
 */
type Plot = {
  id?: string;
  tag?: string;
  servicedBy?: string;
}