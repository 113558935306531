import { ColDef, ColGroupDef, ICellRendererComp, ICellRendererParams } from "ag-grid-community";

export type TPredictedDiagnose = Record<string, any>;

export type TImageFeedback = {
  distance: string;
  focus: string;
};

export type TError = {
  message: string;
  type: string;
}

export type TImageAnalysis = {
  id: string;
  created_date: string;
  created_by: string;
  image: string;

  analysis: {
    crop_health: string;
    crops: string[];
    errors: TError[];

    image_feedback: TImageFeedback;

    predicted_diagnoses: TPredictedDiagnose[];
    plantix_trace_id: string;
  }
  plotId: string;
  isCorrect?: boolean;
  comment?: string;
}


/**
 * col def
 */

export const NonValidatedColDef: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Image',
    field: 'image',
    cellRendererSelector: (params: ICellRendererParams<any>) => {
      const renderer = {
        component: ImageRenderer,
      };
      return renderer;
    },
  },
  {
    headerName: 'Crop',
    field: 'analysis.crops',
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true
  },
  {
    headerName: 'Crop Health',
    field: 'analysis.crop_health',
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true
  },
  {
    headerName: 'Disease/Pest',
    field: 'analysis.predicted_diagnoses',
    flex: 1,
    cellRendererSelector: (params: ICellRendererParams<any>) => {
      const renderer = {
        component: DiseasePestRenderer,
      };
      return renderer;
    }
  },
  {
    headerName: 'Created By',
    field: 'created_by',
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true
  },
  {
    headerName: 'Is Correct',
    field: 'isCorrect',
    floatingFilter: true,
    filter: "agTextColumnFilter",
    sortable: true
  },
]

class ImageRenderer implements ICellRendererComp {
  eGui!: HTMLSpanElement;
  init(params: ICellRendererParams) {
    this.eGui = document.createElement('span');
    this.eGui.innerHTML += `<image style="width: 100px; height: 100px; object-fit: center;" src="${params.value}" />`;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}

class DiseasePestRenderer implements ICellRendererComp {
  eGui!: HTMLSpanElement;
  init(params: ICellRendererParams) {
    this.eGui = document.createElement('span');
    const diseasePests = (params.value ?? []).map((value: any) => value.common_name).join(", ");
    this.eGui.innerHTML += `<span>${diseasePests}</span>`;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}