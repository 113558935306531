import { CORE_API } from "../constants/api";
import { KeyValues } from "../constants/interfaces";
import { createApiFilter } from "../utils/helper";
import { getData } from "./common.service";

export const getDevices = async (values: KeyValues) => {
  const apiFilter = createApiFilter(values)

  const url = '/devices?filter=' + apiFilter;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  }) 
}

export const getAnomalousPlots = async () => {
  const url = '/get-anomalous-plots?sinceHours=24'
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getInactiveDevices = async () => {
  const url = '/inactive-devices?sinceHours=24'
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getExpiredPlots = async () => {
  const url = '/expired-plots'
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getRepeatPurchase = async (startDate: Date, endDate: Date) => {
  const url = `/repeat-purchase?startTime=${startDate}&endTime=${endDate}`
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

