import React, { useEffect, useState, useRef } from 'react'
import { Grid, Skeleton, Typography } from "@mui/material";
import { InformationCard, InformationText, InformationText2 } from "../components";
import { getPlotInsights } from "src/services/businessDashboard.service";
import { NotificationMapping, InformationCardTitles } from 'src/constants/WordMapping';
import { Roles } from "src/constants/interfaces"
import { getAnomalousPlots, getInactiveDevices, getExpiredPlots } from 'src/services/device.service';
import _ from 'lodash';
import ScrollableSearchableCard from '../components/ScrollableSearchCard';

const DataSkeleton: React.FC = (): JSX.Element => (
  <Grid>
    <Grid container justifyContent={'space-evenly'}>
      <Skeleton variant='rectangular' width={300} height={200} style={{ borderRadius: '15px', margin: '15px 0' }} />
      <Skeleton variant='rectangular' width={300} height={200} style={{ borderRadius: '15px', margin: '15px 0' }} />
      <Skeleton variant='text' height={40} width={300} style={{ borderRadius: '15px', margin: '15px 0' }} />
    </Grid>
  </Grid>
)

const InfoSection: React.FC = (): JSX.Element => {

  const [insightData, setInsightData] = useState<any>()
  const [isReady, setIsReady] = useState<Boolean>(false)

  const [falsyPlots, setFalsyPlots] = useState<{
    inactive: string[],
    anomalous: string[],
    expired: string[]
  }>()

  useEffect(() => {
    getPlotInsights()
      .then(res => {
        setInsightData(res)
        setIsReady(true)
      })

    // /get-anomalous-plots
    // /inactive-devices
    // /expired-plots
    getFalsyPlots();
  }, [])

  const getPlotIds = (plots: any): string[] => {
    return plots.map((plot: any) => plot.plotId);
  }

  const getFalsyPlots = async () => {
    const [anomalousPlots, inactiveDevices, expiredPlots] = await Promise.all([
      getAnomalousPlots(),
      getInactiveDevices(),
      getExpiredPlots()
    ]);


    setFalsyPlots({
      inactive: getPlotIds(inactiveDevices),
      anomalous: getPlotIds(anomalousPlots),
      expired: getPlotIds(expiredPlots.plots)
    })
  }

  return (
    <Grid item xs={3} px={4} height={'90vh'} style={{ overflowY: 'scroll' }} className="informationOverflow" >
      {
        isReady ? (
          <Grid>
            <InformationCard
              data-testid="total-plots"
              amount={insightData?.plotCount ?? 0}
              text={InformationCardTitles.plots}
              data={Object.keys(insightData?.devices)?.map((val: string) => insightData?.devices?.[val] as string[])}
            >
              {Object.keys(insightData?.devices)?.map((val: any) => (
                <InformationText data={insightData?.devices?.[val]} text={val} key={val} />
              ))}
            </InformationCard>

            <ScrollableSearchableCard
              text={InformationCardTitles.crops}
              data={insightData?.crops}
            />

            <InformationCard
              data-testid="inconsistent-irrigation"
              amount={
                insightData.interactions?.['Early irrigation']?.length ?? 0 +
                insightData.interactions?.['Insufficient irrigation']?.length ?? 0 +
                insightData.interactions?.['No irrigation']?.length ?? 0
              }
              data={[...insightData.interactions?.['Early irrigation'] ?? [], ...insightData.interactions?.['Insufficient irrigation'] ?? [], ...insightData.interactions?.['No irrigation'] ?? []]}
              amountFontSize={50}
              text={InformationCardTitles.inconsistentIrrigation}
              textFontSize={15}
              lastUpdated={true}
            >
              {!!insightData.interactions?.['No irrigation'] && <InformationText data={insightData.interactions?.['No irrigation']} text='no irrigation' />}
              {!!insightData.interactions?.['Early irrigation'] && <InformationText data={insightData.interactions?.['Early irrigation']} text='early irrigation' />}
              {!!insightData.interactions?.['Insufficient irrigation'] && <InformationText data={insightData.interactions?.['Insufficient irrigation']} text='insufficient irrigation' />}
            </InformationCard>

            <InformationCard data-testid="actions-for-today" text={InformationCardTitles.actions} data={[] as string[]} amount={0} >
              {Object.keys(insightData?.notifications)?.map((val) => (
                <InformationText key={NotificationMapping[val]} data={insightData?.notifications?.[val]} text={`${NotificationMapping[val]}`} />
              ))}
            </InformationCard>

            {/* {hasPermission && (
              <Grid>
                <InformationText2 text={`${insightData.interactions?.['No recent interaction']?.length} plots where no interactions has been initiated since last month`} />
              </Grid>
            )} */}
          </Grid>
        ) : (
          <DataSkeleton />
        )
      }

      {
        !!falsyPlots ? <>
          <InformationCard

            text={InformationCardTitles.falsyPlots}
            data={_.union(
              falsyPlots.anomalous,
              falsyPlots.inactive,
              falsyPlots.expired
            )}
            amount={0}
          >
            <InformationText text={'Anomalous Plots'} data={falsyPlots.anomalous} />
            <InformationText text={'Inactive'} data={falsyPlots.inactive} />
            <InformationText text={'Expired'} data={falsyPlots.expired} />
          </InformationCard>
        </> :
          <Grid container justifyContent={'space-evenly'}>
            <Skeleton variant='rectangular' width={300} height={200} style={{ borderRadius: '15px', margin: '15px 0' }} />
          </Grid>
      }

    </Grid>
  )
}

export default InfoSection;