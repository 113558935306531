import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { DisplayProperty } from "../../pages/PlotDetails";

interface PlotCardProps {
	plotId: string;
	plotDetails: {
		plotName: string,
		area: string,
		cropName: string,
		sowingDate: string,
		stage: string
	},
	handleClose: any;
}
const PlotCard: React.FC<PlotCardProps> = ({ plotId, plotDetails, handleClose }): JSX.Element => {
	const { plotName, area, cropName, sowingDate, stage } = plotDetails
	return (
		<Grid item boxShadow={4} borderRadius={3} m={1} p={2} width={450}>
			<Link style={{ color: 'inherit', textDecoration: 'inherit' }} onClick={handleClose} to={'/plots/' + plotId} >
				<DisplayProperty property='Plot Name' value={plotName} width={200} />
				<DisplayProperty property='Area' value={area} width={200} />
				<DisplayProperty property='Crop Name' value={cropName} width={200} />
				<DisplayProperty property='Sowing Date' value={sowingDate} width={200} />
				<DisplayProperty property='Stage' value={stage} width={200} />
			</Link>
		</Grid>
	)
}

export default PlotCard;