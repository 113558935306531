import { updateGeoBoundary } from "src/services/plot.service";
import { Modal, Typography, Grid, Button, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { DropDownProps } from "./CropFilterBusinessDashboard";
import { Close } from '@mui/icons-material';
import { CoordinatesArray } from "src/utils/types";
import { ToastSeverity } from "src/components/ToastAlert/types";
import _ from "lodash";

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  markersCovered: any[];
  boundaryCoords: CoordinatesArray;
  handleGeoBoundaryUpdate: (severity: ToastSeverity, message: string, markers: any[]) => void;
};

const AddGeolocationModal: React.FC<Props> = (props) => {
  const { isOpen, setIsOpen, markersCovered, boundaryCoords, handleGeoBoundaryUpdate } = props;

  const PlotOptions = markersCovered.map((marker: any) => ({ label: marker.plotId, value: marker.plotId }));

  const [selectedPlot, setSelectedPlot] = useState<SingleValue<any>>({ label: markersCovered[0]?.plotId, value: markersCovered[0]?.plotId });

  useEffect(() => {
    if (_.isEmpty(selectedPlot) && !!PlotOptions) {
      setSelectedPlot(PlotOptions[0]);
    }
  }, [])

  const closeModal = () => {
    setSelectedPlot(null)
    setIsOpen(false)
  }

  const saveGeoBoundary = () => {
    const payload = {
      geoBoundary: {
        type: "Polygon",
        coordinates: boundaryCoords
      }
    }

    updateGeoBoundary(selectedPlot.value, payload)
      .then((res) => {
        if (!!res.error) {
          throw "Failed";
        }

        // updates the plot with geoBoundary changes
        const updatedMarkers = markersCovered!.map((markerItr) => {
          if (selectedPlot.value === markerItr.plotId) {
            return { ...markerItr, geoBoundary: payload.geoBoundary }
          }

          return markerItr
        })
        handleGeoBoundaryUpdate('success', 'GeoBoundary updated Successfully!', updatedMarkers)
      })
      .catch(err => {
        handleGeoBoundaryUpdate('error', 'Something went wrong! Please try again', markersCovered)
      })
      .finally(() => {
        setIsOpen(false);
      })
  }

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Grid
        p={3}
        height={"20%"}
        width={"50%"}
        mt={"6%"}
        bgcolor={"rgba(255, 255, 255, 0.95)"}
        mx={"auto"}
        alignSelf={"center"}
        borderRadius={5}
        display={'flex'}
        flexDirection="column"
        // alignItems={'center'}
        justifyContent={'space-evenly'}
        position={'relative'}
      >
        <Typography variant="h5" textAlign={"center"}>
          Save Plots Boundary

          <IconButton onClick={closeModal} style={{ position: 'absolute', right: '10px', top: '10px' }} >
            <Close fontSize="small" />
          </IconButton>
        </Typography>

        <Grid mt={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
          <Grid width={400}>
            <Select
              options={PlotOptions}
              value={selectedPlot || PlotOptions[0]}
              onChange={(selected) => setSelectedPlot(selected)}
              {...DropDownProps}
            />
          </Grid>

          <Button
            variant="outlined"
            style={{ borderRadius: "20px" }}
            size="medium"
            color="success"
            onClick={saveGeoBoundary}
          >
            Save Geo Boundary
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AddGeolocationModal;
