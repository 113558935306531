import React, { useEffect, useRef, useState } from "react";
import { MapSection, InfoSection, FilterSection } from "./sections";
import { CircularProgress, Grid } from "@mui/material";
import MapCharts from "./sections/MapCharts";
import GetNdviImageModal from 'src/pages/BusinessDashboard/components/GetNdviImage';
import Lottie from "react-lottie";
import MapLoader from 'src/assets/lottieFiles/map-loader.json'
import images from "src/constants/images";

export type GraphType = 'environment' | 'pest' | 'disease' | 'future' | 'prediction' | 'crop';
export type GraphPlotsData = { plotId: string; value: number; }

export const FILTERS_WITH_NO_LEGEND = ['geoBoundary']

export type GraphPlots = {
  label: string;
  unit: string;
  data: GraphPlotsData[] | string[]
}

export const showNoDataOverlay = () => {
  const noDataDiv = document.getElementById("google-maps-no-data");
  if (noDataDiv) {
    noDataDiv.innerHTML = "No data available";
    noDataDiv.style.display = "flex";
  }
};

export const hideNoDataOverlay = () => {
  const noDataDiv = document.getElementById("google-maps-no-data");
  if (noDataDiv) {
    noDataDiv.style.display = "none";
  }
};

const BusinessDashboard: React.FC = (): JSX.Element => {
  const [cropData, setCropData] = useState<[]>([]);
  const [indexData, setIndexData] = useState<[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState<Boolean>(false);
  const [filterApplied, setFilterApplied] = useState<string>('');

  const [graphType, setGraphType] = useState<GraphType>();
  const [graphPlots, setGraphPlots] = useState<GraphPlots>()
  const [graphSubType, setGraphSubType] = useState<string>();
  const [showgetPrediction, setshowgetPrediction] = useState<Boolean>(false);
  const [getPredictionData, setGetPredictionData] = useState<any>(null);

  const [getndviImage, setGetndviImage] = useState<Boolean>(false)
  const [getndviImagePlotId, setGetndviImagePlotId] = useState<any>(null);
  const [getndviImageCrop, setGetndviImageCrop] = useState<any>(null);
  const [getndviImageFarmId, setGetndviImageFarmId] = useState<any>(null);

  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(false)

  useEffect(() => {
    const { data } = graphPlots || {};
    const MapChart = document.getElementById("MapCharts");

    if (data || showgetPrediction) {
      if (MapChart) {
        setTimeout(() => {
          MapChart.scrollIntoView({ behavior: "smooth" });
        }, 2500);
      }
    }
  }, [graphPlots, showgetPrediction]);

  useEffect(() => {
    setShowMap(!isFilterLoading)
  }, [isFilterLoading])

  useEffect(() => {
    const GetNdviImageModal = document.getElementById("GetNdviImageModal");

    if (getndviImage && !!GetNdviImageModal) {
      if (!!GetNdviImageModal) {
        setTimeout(() => {
          GetNdviImageModal.scrollIntoView({ behavior: "smooth" });
        }, 1500);
      }
    }
  }, [getndviImage]);


  const mapsDate = useRef<any>(null);

  const setMapsDate = (newDate: any) => {
    mapsDate.current = newDate;
  };

  const handleFilterUpdate = (filter: string) => {
    hideNoDataOverlay()
    const mapsDateOverlay = document.getElementById("google-maps-date-overlay");
    const cumulativeArea = document.getElementById("google-maps-plots-cumulative-area");
    const legendContainer = document.getElementById("legend");
    

    setFilterApplied(filter);

    if (!!mapsDateOverlay && !!cumulativeArea && !!legendContainer) {
      mapsDateOverlay.style.display = "none";
      cumulativeArea.style.display = "none";
      legendContainer.style.display = 'none';


      setTimeout(() => {
        if (mapsDate.current !== "Invalid Date" && !!mapsDate.current) {
          mapsDateOverlay.style.display = "block";
          mapsDateOverlay.innerHTML = "Date: " + mapsDate.current;
        }

        cumulativeArea.style.display = "flex";
        legendContainer.style.display = FILTERS_WITH_NO_LEGEND.includes(filter) ? 'none' : 'block';
      }, 1500);
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: MapLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Grid px={2} py={4}>
      <Grid container spacing={1} minHeight="90vh" maxHeight="90vh">
        <InfoSection />
        <Grid item xs={9} display={"flex"}>
          {
            showMap ? 
            <MapSection
              filterApplied={filterApplied}
              cropData={cropData}
              setCropData={setCropData}
              indexData={indexData}
              setIndexData={setIndexData}
              buttonDisabled={buttonDisabled}
              setButtonDisabled={setButtonDisabled}
              mapsDate={mapsDate}
              setGraphPlots={setGraphPlots}
              setshowgetPrediction={setshowgetPrediction}
              setGetndviImage={setGetndviImage}
              setGetndviImagePlotId={setGetndviImagePlotId}
              setGetndviImageCrop={setGetndviImageCrop}
              setGetndviImageFarmId={setGetndviImageFarmId}
              xs={8}
            /> : 
            <Grid item xs={8} height={'80vh'} position={'relative'} display={ showMap ? 'none' : 'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={8}
              style={{
                backgroundImage: `url(${images.IndiaMap})`, 
                backgroundSize: "cover",
                backgroundPosition: 'center',
                position: "relative",
                backgroundRepeat: 'no-repeat'
              }} 
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  borderRadius: '20px'
                }}
              ></div>
              <Lottie 
                options={defaultOptions}
                height={'50%'}
                width={'50%'}
                style={{
                  color: 'green',
                }}
              />
            </Grid>
          }
          <FilterSection
            setMapsDate={setMapsDate}
            handleFilterUpdate={handleFilterUpdate}
            cropData={cropData}
            setCropData={setCropData}
            indexData={indexData}
            setIndexData={setIndexData}
            buttonDisabled={buttonDisabled}
            setButtonDisabled={setButtonDisabled}
            setGraphType={setGraphType}
            setGraphSubType={setGraphSubType}
            setIsFilterLoading={setIsFilterLoading}
          />
        </Grid>
      </Grid>

      {
        (!!graphPlots && !!mapsDate.current && !!graphPlots.data && graphPlots.data.length > 0) && 
          <MapCharts graphPlots={graphPlots} graphType={graphType!} mapsDate={mapsDate} graphSubType={graphSubType!} setGetPredictionData={setGetPredictionData} showgetPrediction={showgetPrediction} getPredictionData={getPredictionData} />
      }
      
      {!!getndviImage && (
        <GetNdviImageModal isOpen={getndviImage} setIsOpen={setGetndviImage} plotId={getndviImagePlotId} crop={getndviImageCrop} farmname={getndviImageFarmId} />
      )}

    </Grid>
  );
};

export default BusinessDashboard;
