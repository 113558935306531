import { CORE_API } from "src/constants/api";
import {
  DailyRegionDataPayload as RegionDataPayload,
  PestDiseasePredictionsPayload,
  PestDiseasePredictionsPayloadForCharts,
  Region,
  RegionDataForChartsPayload,
} from "src/pages/Region/types";
import { deleteData, getData, postData } from "./common.service";
import moment from "moment";

export type TGetRegionsPayload = {
  state?: string;
  district?: string;
  village?: string;
  subDistrict?: string;
};

export type TDownloadRegionPestDiseasePrediction = TGetRegionsPayload & {
  startDate: string;
  endDate: string;
  cropId?: string[];
  type?: string;
  typeId?: string;
};


export type TRiskLevel = "NIL" | "LOW" | "MEDIUM" | "HIGH";
type TRegionType = "district" | "village" | "subDistrict" | "state";
type TGeoLocationType = "Point" | "Circle" | "Rectangle" | "Polygon";

export type TDownloadRegionPestDiseasePredictionResponse = {
  created_date: string;
  id: string;
  cropId: string;
  type: string;
  typeId: string;
  probability: number;
  riskLevel: TRiskLevel;
  date: string;
  regionId: string;
  region: {
    created_date: string;
    id: string;
    name: string;
    type: TRegionType;
    geoLocation: {
      type: TGeoLocationType;
      coordinates: [number, number];
    };
    district: string;
    subDistrict: string;
    village: string;
    state: string;
    country: string;
    plotIds: Array<string>;
    plotCount: number;
    weatherPlotCount: number;
    fylloWeatherPlotCount: number;
    fylloIrrigationPlotCount: number;
  };
};

const DISEASE_PEST_DOWNLOAD_LIMIT = 10000;

export const getRegionTypeCount = async (type: Region) => {
  const qs = {
    type: type,
  };

  const url = `/regions/count?where=${JSON.stringify(qs)}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
};

export const getAllRegions = async (payload: TGetRegionsPayload) => {
  const qs: any = {
    where: {
      and: [],
    },
  };

  const { state, district, subDistrict, village } = payload;
  if (!!state) {
    qs.where.and.push({ state });
    if (!!district) {
      qs.where.and.push({ district });
      if (!!subDistrict) {
        qs.where.and.push({ subDistrict });
        if (!!village) {
          qs.where.and.push({ village });
        }
      }
    }
  }

  const url = `/regions?filter=${JSON.stringify(qs)}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
};

export const getAllRegionForOptions = async () => {
  const url = `/all-regions?filter=${JSON.stringify({fields: {'id':true, 'name':true}})}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
};

export const getRegions = async () => {
  const qs = {
    where: {
      fylloWeatherPlotCount: { gt: 0 },
      type: "village",
    },
  };

  const url = `/regions?filter=${JSON.stringify(qs)}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
};

export const getTargetRegions = async () => {
  const qs = {
    where: {
      targetRegion: true
    },
  };

  const url = `/regions?filter=${JSON.stringify(qs)}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
};

export const getTargetRegionCount = async (filters: any[] = []) => {
  const qs = {
    where: {
      targetRegion: true,
      ...filters
    },
  };

  const url = `/regions/count?where=${JSON.stringify(qs.where)}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
};


export async function getRegionsByPagination(
    skip: number,
    filters: any[] = []
  ) {

  const qs:any = {
    limit: 15,
    skip: skip,
  }
  if (filters.length) {
    qs.where = { and: filters }
  }

  return getData('/regions?filter=' + JSON.stringify(qs), {
    basePath: CORE_API,
    sendTokenInHeader: true
  })
}


export const getFilteredRegionsCrop = async (Location:any, type:string) => {
  let qs:any = {
    where: {
      fylloWeatherPlotCount: { gt: 0 },
    },
  };
  qs.where = {...qs.where, ...Location};
  if(type){
    qs.where.type=type;
  }

  const url = `/regions?filter=${JSON.stringify(qs)}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
};

export const getDailyRegionData = (payload: RegionDataPayload) => {
  const { timestamp, fields, ids } = payload;

  const qs: any = {
    where: {
      timestamp,
    },
    fields,
    include: [
      {
        relation: "region",
        scope: {
          fields: {
            geoLocation: true,
            id: true,
            village: true,
            subDistrict: true,
            district: true,
            state: true,
          },
        },
      },
    ],
  };

  if (!!ids && ids.length > 0) {
    qs.where.regionId = { in: ids };
  }

  const url = "/daily-region-data?filter=" + JSON.stringify(qs);

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
};

export const getDailyRegionDataForCharts = (
  payload: RegionDataForChartsPayload
) => {
  const { fromDate, toDate, fields, ids } = payload;

  const qs: any = {
    where: {
      and: [{ timestamp: { lte: toDate } }, { timestamp: { gte: fromDate } }],
    },
    order: ["timestamp ASC"],
    fields: fields?.reduce((acc: any, item) => {
      acc[item] = true;
      return acc;
    }, {}),
    include: [
      {
        relation: "region",
        scope: {
          fields: {
            geoLocation: true,
            id: true,
            village: true,
            subDistrict: true,
            district: true,
            state: true,
          },
        },
      },
    ],
  };

  if (!!ids && ids.length > 0) {
    qs.where.regionId = { in: ids };
  }

  const url = "/daily-region-data?filter=" + JSON.stringify(qs);

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
};

export const downloadDailyRegionDataForCharts = (
  payload: RegionDataForChartsPayload
) => {
  const { fromDate, toDate, fields, ids } = payload;

  const qs: any = {
    where: {
      and: [{ timestamp: { lte: toDate } }, { timestamp: { gte: fromDate } }],
    },
    order: ["timestamp ASC"],
    fields: fields?.reduce((acc: any, item) => {
      acc[item] = true;
      return acc;
    }, {}),
    include: [
      {
        relation: "region",
        scope: {
          fields: {
            geoLocation: true,
            id: true,
            village: true,
            subDistrict: true,
            district: true,
            state: true,
          },
        },
      },
    ],
  };

  if (!!ids && ids.length > 0) {
    qs.where.regionId = { in: ids };
  }

  const url = "/daily-region-data?restype=csv&filter=" + JSON.stringify(qs);

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    isBlob: true,
  });
};

export const getHourlyRegionData = (payload: RegionDataPayload) => {
  const { timestamp, fields, ids } = payload;

  const qs: any = {
    where: {
      timestamp,
    },
    fields,
    include: [
      {
        relation: "region",
        scope: {
          fields: {
            geoLocation: true,
            id: true,
            village: true,
            subDistrict: true,
            district: true,
            state: true,
          },
        },
      },
    ],
  };

  if (!!ids && ids.length > 0) {
    qs.where.regionId = { in: ids };
  }

  const url = "/hourly-region-data?filter=" + JSON.stringify(qs);

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
};

export const getHourlyRegionDataForCharts = (
  payload: RegionDataForChartsPayload
) => {
  const { fromDate, toDate, fields, ids } = payload;

  const qs: any = {
    where: {
      and: [{ timestamp: { lte: toDate } }, { timestamp: { gte: fromDate } }],
    },
    fields,
    include: [
      {
        relation: "region",
        scope: {
          fields: {
            geoLocation: true,
            id: true,
            village: true,
            subDistrict: true,
            district: true,
            state: true,
          },
        },
      },
    ],
  };

  if (!!ids && ids.length > 0) {
    qs.where.regionId = { in: ids };
  }

  const url = "/hourly-region-data?filter=" + JSON.stringify(qs);

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
};

export const downloadPestDiseasePrediction = (
  payload: TDownloadRegionPestDiseasePrediction
): Promise<Array<TDownloadRegionPestDiseasePredictionResponse>> => {
  const qs: any = {
    where: {
      and: [],
    },
    include: [
      {
        relation: "region",
        scope: {
          fields: {
            district: true,
            state: true,
            subDistrict: true,
            village: true,
            fylloWeatherPlotCount: true,
            id: true,
          },
        },
      },
    ],
    limit: DISEASE_PEST_DOWNLOAD_LIMIT,
    order: ["date ASC", "cropId ASC", "type ASC", "typeId ASC"],
  };

  const { startDate, endDate, cropId, state, village, district, subDistrict } =
    payload;
  let crops: string = "";

  /**
   * generate crops string
   * crop1,crop2,crop3
   *
   * if crops is empty/undefined
   *    crops <-- cropId[0]
   * else
   *    crops <-- cropId[i] + crops
   * */
  if (cropId) {
    for (const crop of cropId) {
      if (!crops) crops = crop;
      else crops = crops + "," + crop;
    }
  }
  qs.where.and.push({
    date: { lte: moment(endDate).format("MM-DD-YYYY") },
  });
  qs.where.and.push({
    date: { gte: moment(startDate).format("MM-DD-YYYY") },
  });

  let url = `/download-region-disease-pest-predictions?filter=${JSON.stringify(
    qs
  )}`;

  // add valid locations in the url
  if (state) {
    url += `&state=${state}`;
  }
  if (district) {
    url += `&district=${district}`;
  }
  if (subDistrict) {
    url += `&subDistrict=${subDistrict}`;
  }
  if (village) {
    url += `&area=${village}`;
  }

  // add crops in the url
  if (crops) url += `&crops=${crops}`;

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
};

export const getPestDiseasePrediction = (
  paylaod: PestDiseasePredictionsPayload
) => {
  const { cropId, type, typeId, date, riskLevel } = paylaod;
  const qs: any = {
    where: {
      and: [{ cropId }, { type }, { typeId }, { date }],
    },
    include: [
      {
        relation: "region",
        scope: {
          fields: { geoLocation: true, id: true },
        },
      },
    ],
  };

  if (!!riskLevel) {
    const riskLevelQuery = {
      or: riskLevel.map((risk) => ({
        riskLevel: { like: `${risk.value}`, options: "i" },
      })),
    };
    qs.where.and.push(riskLevelQuery);
  }

  const url = "/region-disease-pest-predictions?filter=" + JSON.stringify(qs);

  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
};

export const getPestDiseasePredictionForCharts = (
  paylaod: PestDiseasePredictionsPayloadForCharts
) => {
  let { cropId, type, typeId, date, toDate, regionId } = paylaod;

  if (typeof regionId === "string") regionId = [regionId];

  const qs = {
    where: {
      and: [
        { cropId },
        { type },
        { typeId },
        { date: { lte: toDate } },
        { date: { gte: date } },
        { regionId: { in: regionId } },
      ],
    },
    order: "date ASC",
  };

  const url = "/region-disease-pest-predictions?filter=" + JSON.stringify(qs);

  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
};

export const getRegionsCount = () => {
  const qs = {
    fylloWeatherPlotCount: { gt: 0 },
    type: "village",
  };
  const url = "/regions/count?where=" + JSON.stringify(qs);
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
};

export const getTotalRegionsCount = (filters: any[] = []) => {
  let url = `/regions/count`;
  if (filters.length) {
    url += `?where=` + JSON.stringify({ and: filters })
  }
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getRegionFromPlot = (plotId: string) => {
  const qs = {
    where: {
      plotIds: plotId,
      type: "village",
    },
  };

  const url = "/regions?filter=" + JSON.stringify(qs);

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
};

export const getRegionDataFromRegionId = (regionId: string) => {
  const qs = {
    where: { id: regionId },
  };

  const url = "/regions?filter=" + JSON.stringify(qs);

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
};
export const getUserRegionCrop = (userId: string) => {
  const qs = {
    where: {
      userId: userId,
    },
    include: ["regionCropMappings"],
  };

  const url = "/user-region-crop-mappings?filter=" + JSON.stringify(qs);

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
};

export const updateUserRegionCrop = (id: string, data: any) => {
  const url = "/user-region-crop-mappings/" + id;
  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    usePatchMethod: true,
    body: {
      regionCropMappingIds: data,
    },
  });
};

export const addUserRegionCrop = (userId: string, Id: string) => {
  const url = "/user-region-crop-mappings";
  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    body: {
      regionCropMappingIds: [Id],
      userId: userId,
    },
  });
};

export const getRegionCrop = (regionIds: any) => {
  const qs = {
    where: {
      regionId: { inq: regionIds },
    },
  };

  const url = "/region-crop-mappings?filter=" + JSON.stringify(qs);

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
};

export const addRegionCrop = (regionCropMapBodyArr:Array<any>) => {
  const url = "/region-crop-mappings";

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    body: regionCropMapBodyArr,
  });
};

export const deleteRegionCrop = (id: any) => {
  const url = "/region-crop-mappings/" + id;

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true,
  });
};

export const getHotspotRegions = () => {
  const url = "/regions/hotspots";

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
};

export const getRegionAdress = (latLon: { lat: number, lon: number }): Promise<RegionAdress> => {
  const { lat, lon } = latLon;
  const url = `/regions/address?lat=${lat}&lon=${lon}`;
  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export const addRegion = (payload:any) => {
  const {id, name, regionName, targetRegion, type, fylloWeatherPlotCount, village, subDistrict, district, state, country, geoLocation} = payload;
  const url =  '/regions';
  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    body: {
      id: id, 
      name: name, 
      targetRegion: targetRegion, 
      type: type, 
      fylloWeatherPlotCount: fylloWeatherPlotCount, 
      village: village, 
      subDistrict: subDistrict, 
      district: district, 
      state: state, 
      country: country, 
      geoLocation: geoLocation,
      geoBoundary: {},
      regionName: regionName
      
    }
  })

}

export const updateRegionById = (id: string, payload: any) => {
  const url = `/regions/${id}`;
  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    usePatchMethod: true,
    body: payload,
  });
}

export const deleteRegionsByIds = (ids: string[]): Promise<void[]> => {
  const urls = ids.map(id => `/regions/${id}`);
  const deletePromises = urls.map(url =>
    deleteData(url, {
      basePath: CORE_API,
      sendTokenInHeader: true,
      useDeleteMethod: true,
    })
  );
  return Promise.all(deletePromises);
}





export type RegionAdress = {
  village?: string;
  district?: string;
  subDistrict?: string;
  state?: string;
  country?: string;
}