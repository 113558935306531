import {
    ICellRendererComp,
    ICellRendererParams,
  } from 'ag-grid-community';
  
  export class NumberRenderer implements ICellRendererComp {
    eGui!: HTMLSpanElement;
    init(params: ICellRendererParams) {
      this.eGui = document.createElement('span');

      const renderData = isNaN(Number(params.value)) ? "-" : Number(params.value).toFixed(2);

      this.eGui.innerHTML += `<span style="font-size: 12px;"> ${renderData}</span>`;
    }
  
    getGui() {
      return this.eGui;
    }
  
    refresh(params: ICellRendererParams): boolean {
      return false;
    }
  }