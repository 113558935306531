import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  SortChangedEvent,
  FilterChangedEvent,
} from "ag-grid-community";
import { localeColDef } from "../constants/columnDefs";
import {
  getLocale,
  postLocale,
  putLocale,
  deleteLocale,
  getLocaleById,
} from "../services/locale.service";
import { isArrayWithLength } from "../utils/helper";
import { useNavigate, useLocation } from "react-router";
import { Grid, IconButton, Typography, Modal } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

interface Props {
  selectedModalData?: any;
  setOpenModal?: any;
}

const Locale = (): JSX.Element => {
  const state = useLocation();
  const gridRef = useRef<AgGridReact>(null);
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "600px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [tableData, setTableData] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState<any>({
    id: String,
    en: String,
    hi: String,
    ma: String,
    ka: String,
    gu: String,
    te: String,
    ta: String,
  });

  const navigate = useNavigate();

  const startLoading = () => {
    gridRef?.current?.api?.showLoadingOverlay();
  };
  const stopLoading = () => {
    gridRef?.current?.api?.hideOverlay();
  };

  const gridOptions: GridOptions = {
    columnDefs: localeColDef,
    rowData: tableData,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      setSelectedModalData(e.data);
      setOpenModal(true);
    },
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // gridRef?.current?.api.setColumnDefs(farmActivitiesColDef);
    // gridRef?.current?.api.setRowData(tableData);
  }, []);

  // function to perform server side sorting
  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
  };

  // function to perform server side filtering
  const handleColumnFilter = (event: FilterChangedEvent) => {
    if (event.afterFloatingFilter) {
    }
  };

  const onPaginationChanged = useCallback(() => {}, []);

  useEffect(() => {
    getLocaleData();
  }, []);

  const getLocaleData = () => {
    getLocale().then((res) => {
      setTableData(res);
    });
  };

  const onSubmit = () => {
    if (selectedModalData?.id) {
      if (window.confirm("Do you want to submit?")) {
        getLocaleById(selectedModalData?.id).then((res: any) => {
          if (res?.id) {
            putLocale(selectedModalData, selectedModalData?.id).then(
              (res: any) => {
                if (res.error) alert("Some Error Occured");
                else {
                  setOpenModal(false)
                  alert("Locale Updated");
                  getLocaleData();
                }
              }
            );
          } else {
            postLocale(selectedModalData).then((res: any) => {
              if (res.id) {
                alert("Locale added");
                setOpenModal(false)
                getLocaleData();
              } else alert("Some Error Ocuured");
            });
          }
        });
      }
    } else alert("Some Error Ocuured");
  };

  const onDelete = () => {
    if (selectedModalData.id) {
      if (window.confirm("Do you want to delete?")) {
        deleteLocale(selectedModalData.id).then((res: any) => {
          if (res.error) alert("Some Error Occured");
          else {
            alert("Locale Deleted");
            setOpenModal(false);
            getLocaleData();
          }
        });
      }
    } else alert("Some Error Occured");
  };

  return (
    <div style={containerStyle}>
      <Grid
        p={2}
        borderRadius={20}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Grid display={"flex"} item alignItems={"center"} margin={1}>
          <IconButton onClick={() => navigate("/")}>
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Locale
          </Typography>
        </Grid>

        <input
          type="button"
          value="Add Locale"
          style={{
            width: "10%",
            padding: "10px",
            fontSize: 18,
            paddingTop: "10px",
            alignSelf: "center",
          }}
          onClick={() => {
            setSelectedModalData({
              id: String,
              en: String,
              hi: String,
              ma: String,
              ka: String,
              gu: String,
              te: String,
              ta: String,
            });
            setOpenModal(true);
          }}
        ></input>
      </Grid>
      <div style={gridStyle} className="ag-theme-alpine">
        {isArrayWithLength(tableData) && (
          <Grid p={3}>
            <AgGridReact
              ref={gridRef}
              rowData={tableData}
              columnDefs={localeColDef}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              animateRows={true}
              pagination={true}
              paginationPageSize={15}
              gridOptions={gridOptions}
              domLayout="autoHeight"
              onPaginationChanged={onPaginationChanged}
              onSortChanged={handleColumnSort}
              onFilterChanged={handleColumnFilter}
            ></AgGridReact>
          </Grid>
        )}
      </div>

      <Modal
        open={openModal}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <Grid
          style={{
            backgroundColor: "#fff",
            padding: 12,
            borderRadius: 12,
            margin: 5,
            alignSelf: "center",
            width: "70%",
            maxHeight: "95%",
            overflowY: "auto",
          }}
        >
          <Grid
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            mb={3}
          >
            <IconButton onClick={() => setOpenModal(false)}>
              <ArrowBack />
            </IconButton>
            <Typography variant={"h5"}>Locale Details</Typography>
          </Grid>
          <form>
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Id
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <br />
            <input
              onChange={(e) =>
                setSelectedModalData({
                  ...selectedModalData,
                  id: e.target.value.replace(/[^a-zA-Z0-9\s_]/g, '')
                })
              }
              onBlur={(e) =>
                setSelectedModalData({
                  ...selectedModalData,
                  id: e.target.value.replace(/\s+/g, '_')
                })
              }
              value={selectedModalData?.id}
              type="text"
              style={{
                width: "93%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 11,
              }}
            ></input>
            <br />
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                margin: 5,
              }}
            >
              English
            </label>{" "}
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <br />
            <input
              onChange={(e) =>
                setSelectedModalData({
                  ...selectedModalData,
                  en: e.target.value,
                })
              }
              value={selectedModalData?.en}
              type="text"
              style={{
                width: "93%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 11,
              }}
            ></input>
            <br />
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                margin: 5,
              }}
            >
              Hindi
            </label>{" "}
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <input
              onChange={(e) =>
                setSelectedModalData({
                  ...selectedModalData,
                  hi: e.target.value,
                })
              }
              value={selectedModalData?.hi}
              type="text"
              style={{
                width: "93%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 11,
              }}
            ></input>
            <br />
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Marathi
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <input
              value={selectedModalData?.ma}
              onChange={(e) =>
                setSelectedModalData({
                  ...selectedModalData,
                  ma: e.target.value,
                })
              }
              type="text"
              style={{
                width: "93%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 15,
                marginTop: 10,
              }}
            ></input>
            <br />
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Kannada
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <input
              onChange={(e) =>
                setSelectedModalData({
                  ...selectedModalData,
                  ka: e.target.value,
                })
              }
              value={selectedModalData?.ka}
              type="text"
              style={{
                width: "93%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 11,
                marginTop: 10,
              }}
            ></input>
            <br />
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Gujarati
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>{" "}
            <input
              onChange={(e) =>
                setSelectedModalData({
                  ...selectedModalData,
                  gu: e.target.value,
                })
              }
              value={selectedModalData?.gu}
              type="text"
              style={{
                width: "93%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 11,
              }}
            ></input>
            <br />
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Telugu
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>{" "}
            <input
              onChange={(e) =>
                setSelectedModalData({
                  ...selectedModalData,
                  te: e.target.value,
                })
              }
              value={selectedModalData?.te}
              type="text"
              style={{
                width: "93%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 11,
              }}
            ></input>
            <br />
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Tamil
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <input
              onChange={(e) =>
                setSelectedModalData({
                  ...selectedModalData,
                  ta: e.target.value,
                })
              }
              value={selectedModalData?.ta}
              type="text"
              style={{
                width: "93%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 11,
                marginTop: 10,
              }}
            ></input>
            <br />
            <Grid display={"flex"} justifyContent={"space-between"} my={5}>
              <input
                type="button"
                value="Submit"
                onClick={onSubmit}
                style={{
                  width: "40%",
                  padding: "20px",
                  fontSize: 18,
                  paddingTop: "15px",
                }}
              />
              <input
                type="button"
                value="Delete"
                onClick={onDelete}
                style={{
                  width: "40%",
                  padding: "20px",
                  fontSize: 18,
                  paddingTop: "15px",
                }}
              />
            </Grid>
          </form>
        </Grid>
      </Modal>
    </div>
  );
};

export default Locale;
