import { fieldLegends } from "../constant";

export type MarkerIcon = {
  url: string;
  scaledSize: google.maps.Size;
  origin: google.maps.Point;
};

export const extractAppliedFilter = (appliedFilter: string): string => {
  if (appliedFilter.length > 3) {
    const prefix = appliedFilter.substring(0, 3).toLowerCase();
    if (prefix === "min" || prefix === "max") {
      return appliedFilter.substring(3).toLowerCase();
    }
  }
  return appliedFilter.toLowerCase();
};

// maps helper functions

export const myMapOptions: any = {
  styles: [
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        { color: '#e9e9e9' },
        { lightness: 17 }
      ]
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [
        { color: '#f5f5f5' },
        { lightness: 20 }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [
        { color: '#cfcfcf' },
        { weight: 1.5 }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        { color: '#444444' }
      ]
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry.stroke',
      stylers: [
        { color: '#cfcfcf' },
        { weight: 1.5 }
      ]
    },
    {
      featureType: 'administrative.province',
      elementType: 'geometry.stroke',
      stylers: [
        { color: '#cfcfcf' },
        { weight: 1.5 }
      ]
    },
    {
      featureType: 'administrative.locality',
      elementType: 'geometry.stroke',
      stylers: [
        { color: '#cfcfcf' },
        { weight: 1 }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      stylers: [
        { visibility: 'off' }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        { color: '#939393' }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.stroke',
      stylers: [
        { color: '#ffffff' },
        { weight: 0.5 }
      ]
    },
    {
      featureType: 'road',
      stylers: [
        { visibility: 'off' }
      ]
    },
    {
      featureType: 'transit',
      stylers: [
        { visibility: 'off' }
      ]
    },
    {
      featureType: 'poi.attraction',
      stylers: [
        { visibility: 'off' }
      ]
    },
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        { color: "#ffffff" },
        { "visibility": "on" },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        { color: '#ffffff' }
      ]
    },
    {
      featureType: 'administrative.country',
      elementType: 'labels.text.fill',
      stylers: [
        { color: '#ffffff' }
      ]
    },
    {
      featureType: 'administrative.province',
      elementType: 'labels.text.fill',
      stylers: [
        { color: '#ffffff' }
      ]
    },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [
        { color: '#ffffff' }
      ]
    }
    
  ]
};

export const updateMapOverlayDatas = (markersCovered: any[]) => {
  const selectedRegionInfoContainer: any = document.querySelector(
    "#google-maps-selected-region-info-container"
  );
  const selectedRegionInfoLabel = document.querySelector(
    "#google-maps-selected-region-info-label"
  );
  const selectedRegionMinValue = document.querySelector(
    "#google-maps-selected-region-min"
  );
  const selectedRegionAvgValue = document.querySelector(
    "#google-maps-selected-region-avg"
  );
  const selectedRegionMaxValue = document.querySelector(
    "#google-maps-selected-region-max"
  );
  const selectedRegionPlotsCount = document.querySelector(
    "#google-maps-selected-region-info-count"
  );

  const allElementsLoaded =
    !!selectedRegionInfoContainer &&
    !!selectedRegionMaxValue &&
    !!selectedRegionInfoLabel &&
    !!selectedRegionMinValue &&
    !!selectedRegionAvgValue &&
    !!selectedRegionPlotsCount;

  if (markersCovered.length > 0 && allElementsLoaded) {
    let maxValue: any = 0,
      minValue: any = 100000,
      avg: any = 0,
      label = "",
      unit = "";
    markersCovered.forEach((marker) => {
      const { color, location, plotArea, plotId, value, ...otherProp } = marker;
      maxValue = Math.max(maxValue, value);
      minValue = Math.min(minValue, value);

      avg += value;

      let flag = false;
      for (const key in otherProp) {
        if (key === "probability") {
          flag = true;
          label = "probability";
          unit = "%";
        }

        if (flag) break;
        if (!!fieldLegends[key]) {
          flag = true;
          label = fieldLegends[key][0].label;
          unit = fieldLegends[key][0].unit;
        }
      }
    });

    if (!label) {
      label = "Area";
      unit = "Acres";
    }

    avg /= markersCovered.length;
    avg = avg.toFixed(2);
    maxValue = maxValue.toFixed(2);
    minValue = minValue.toFixed(2);

    selectedRegionInfoContainer.style.display = "block";
    selectedRegionInfoLabel.innerHTML = label + `(${unit})`;
    selectedRegionAvgValue.innerHTML = ` ${avg}`;
    selectedRegionMaxValue.innerHTML = ` ${maxValue}`;
    selectedRegionMinValue.innerHTML = ` ${minValue}`;
    selectedRegionPlotsCount.innerHTML = `Plots: ${markersCovered.length}`;
  } else {
    if (!!selectedRegionInfoContainer) {
      selectedRegionInfoContainer.style.display = "none";
    }
  }
};

export const updateLegends = (legends: any[]) => {
  const legend = document.getElementById("legend");
  if (!!legend) {
    legend.innerHTML = "";
    let cnt = 0;

    const appliedFilter = document.createElement("div");

    if (legends.length > 0) {
      appliedFilter.innerHTML = `
      <div style="padding: 2px 10px; font-size: 1em; display: flex; align-items: center; font-weight: bold; margin-bottom: 8px; }"> 
        <span>${
          (legends[0]?.label === "Probability"
            ? "Crop Pest/Disease"
            : legends[0]?.label) || "Crop"
        }</span>
      </div>
    `;
    }

    legend.appendChild(appliedFilter);

    for (const key in legends) {
      const type = legends[key];
      const name = type.name;
      const color = type.color;
      const min = type.min;
      const max = type.max;
      const icon = type.icon;
      const div = document.createElement("div");
      const unit = type.unit;

      div.innerHTML = `
    <div style="padding: 2px 10px; font-size: 0.6em; display: flex; align-items: center; }"> 
      ${
        !!icon
          ? `<img style="height: 15px;" src=${icon} />`
          : `${
              !!color
                ? `<div style="width: 35px; height: 15px; background-color: ${color}; border-radius: 5px" > </div>`
                : ``
            }`
      }
      <span style="margin-left: 10px">
        ${name || ""} 
        ${
          min !== undefined || max !== undefined
            ? "[" + (min || "lower") + " to " + (max || "higher") + "]"
            : ""
        } 
        ${!!unit ? unit : ""} 
      </span> 
    </div>`;

      legend.appendChild(div);
      cnt++;
    }
  }
};

export const getGraphPlotsDataForMarker = (marker: any) => {
  const { color, location, plotArea, plotId, value, ...otherProp } = marker

  let flag = false, label = '', unit = '';
  for (const key in otherProp) {
    if (key === 'probability') {
      flag = true;
      label = 'probability'
      unit = '%'
    }

    if (flag) break;
    if (!!fieldLegends[key]) {
      flag = true;
      label = fieldLegends[key][0].label
      unit = fieldLegends[key][0].unit
    }
  }

  return {
    label,
    unit,
    data: {
      plotId,
      value
    }
  }
}

export const getRegionDataForMarker = (marker: any, timestamp: string) => {
  const { color, locatoin, regionId, value, ...otherProps } = marker
  let flag = false, label = '', unit = ''

  for (const key in otherProps) {
    if (key === 'probability') {
      flag = true;
      label = 'probability'
      unit = '%'
    }

    if (flag) break;
    if (!!fieldLegends[key]) {
      flag = true;
      label = fieldLegends[key][0].label
      unit = fieldLegends[key][0].unit
    }
  }

  return {
    label,
    unit,
    data: {
      regionId,
      value,
      timestamp
    }
  }
}