export const SENSORS: any = [
  {
    label: 'Air Temperature',
    value: 'airTemp',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'MinAirTemp',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'MaxAirTemp',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Air Humidity',
    value: 'airHumidity',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'MinAirHumidity',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'MaxAirHumidity',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Rainfall',
    value: 'rainFall',
    isChecked: false,
    field: []
  },
  {
    label: 'Wind Speed',
    value: 'windSpeed',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'MinWindSpeed',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'MaxWindSpeed',
        isChecked: false,
      },
    ]
  },
  // {
  //   label: 'Delta SoilMoisture1',
  //   value: 'deltaSoilMoisture1',
  //   isChecked: false,
  //   field: []
  // },
  // {
  //   label: 'Delta SoilMoisture2',
  //   value: 'deltaSoilMoisture2',
  //   isChecked: false,
  //   field: []
  // },
  {
    label: 'Sunlight',
    value: 'lightIntensity',
    isChecked: false,
    field: [
      {
        label: 'Max',
        value: 'MaxLux',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Sunshine Hours',
    value: 'sunshineHours',
    isChecked: false,
    field: []
  },
  {
    label: 'Leaf Wetness Hours',
    value: 'leafWetnessHours',
    isChecked: false,
    field: []
  },
  {
    label: 'Soil Temperature',
    value: 'soilTemp',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'MinSoilTemp',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'MaxSoilTemp',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Soil Moisture1',
    value: 'soilMoisture1',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'MinSoilMoisture1',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'MaxSoilMoisture1',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Soil Moisture2',
    value: 'soilMoisture2',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'MinSoilMoisture2',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'MaxSoilMoisture2',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Air Pressure',
    value: 'airPressure',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'MinAirPressure',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'MaxAirPressure',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Baro Pressure',
    value: 'baroPressure',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'MinBaroPressure',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'MaxBaroPressure',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Signal Strength',
    value: 'signalStrength',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'MinSignalStrength',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'MaxSignalStrength',
        isChecked: false,
      },
    ]
  },
].sort((a, b) => (a.label < b.label ? 0 : 1));

export const FUTURESENSORS: any = [
  {
    label: 'Air Temperature',
    value: 'airTemp',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'min',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'max',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Air Humidity',
    value: 'airHumidity',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'min',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'max',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Wind Speed',
    value: 'windSpeed',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'min',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'max',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Air Pressure',
    value: 'airPressure',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'min',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'max',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Baro Pressure',
    value: 'baroPressure',
    isChecked: false,
    field: [
      {
        label: 'Min',
        value: 'min',
        isChecked: false,
      },
      {
        label: 'Max',
        value: 'max',
        isChecked: false,
      },
    ]
  },
  {
    label: 'Rainfall',
    value: 'rainFall',
    isChecked: false,
    field: []
  },
  {
    label: 'Wind Direction',
    value: 'windDirection',
    isChecked: false,
    field: []
  },
  {
    label: 'Day Length',
    value: 'dayLength',
    isChecked: false,
    field: []
  },
  {
    label: 'Weather Icon',
    value: 'weatherIcon',
    isChecked: false,
    field: []
  },
  // {
  //   label: 'Has Precipitation?',
  //   value: 'hasPrecipitation',
  //   isChecked: false,
  //   field: []
  // },
  // {
  //   label: "Precipitation Type",
  //   value: "precipitationType",
  //   isChecked: false,
  //   field: []
  // },
  // {
  //   label: 'Precipitation Intensity',
  //   value: 'precipitationIntensity',
  //   isChecked: false,
  //   field: []
  // },
  // {
  //   label: "Precipitation Accumulation",
  //   value: 'precipitationAccumulation',
  //   isChecked: false,
  //   field: []
  // },
  // {
  //   label: "Precipitation Probability",
  //   value: 'precipitationProbability',
  //   isChecked: false,
  //   field: []
  // },
  {
    label: "Cloud Cover",
    value: 'cloudCover',
    isChecked: false,
    field: []
  }
].sort((a, b) => (a.label < b.label ? 0 : 1));
