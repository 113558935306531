import { Grid, IconButton, Typography } from "@mui/material";
import { AddCircleOutline, ArrowBack } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { Option } from "../constants/interfaces";
import {
  getFertilizerDetails,
  postFertilizer,
  updateFertilizer,
  deleteFertilizer,
} from "../services/plot.service";
import { TextArea } from "../components/DynamicForm/fields/_fieldStyles";
import DeleteIcon from "@mui/icons-material/Delete";
import LocaleDropdown from "src/components/LocaleDropdown";
import { SingleValue } from "react-select";
import { CropFilter } from "src/components/Filters";

const FertilizerDetails: React.FC = (): JSX.Element => {
  let res: any = {};
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();

  const [Id, setId] = useState<any>("");
  const [alias, setAlias] = useState<String[]>([]);
  const [name, setName] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [unit, setUnit] = useState<any>("kg");
  const [group, setGroup] = useState<any>("Organic");
  const [type, setType] = useState<any>("");
  const [soilApplication, setSoilApplication] = useState<boolean>(false);
  const [fertigation, setFertigation] = useState<boolean>(false);
  const [foliar, setFoliar] = useState<boolean>(false);
  const [content, setContent] = useState<any>([]);
  const [aliasView, setAliasView] = useState<any>(alias.join(","));
  const [applicableTo, setApplicableTo] = useState<any[]>([]);

  useEffect(() => {
    getFertilizerDetails(id).then((result: any) => {
      if (result.id) {
        res = result;
        setId(res?.id || "");
        setAlias(res?.alias || []);
        setName(res?.name || "");
        setDescription(res?.description || "");
        setUnit(res?.unit || "kg");
        setGroup(res?.group || "organic");
        setType(res?.type || "--Select--");
        setSoilApplication(res?.usedInSoilApplication || false);
        setFertigation(res?.usedInFertigation || false);
        setFoliar(res?.usedInFoliation || false);
        setContent(res?.content || []);
        setAliasView(res?.alias.join(","));
        setApplicableTo(res?.applicableTo || []);
      }
    });
  }, [id]);

  const addcontent = async () => {
    const obj = {
      element: "",
      percentage: 0,
    };
    const cont: any = content.slice();
    cont.push(obj);
    setContent(cont);
  };
  const addContentElement = async (i: any, val: any) => {
    let cont: any = content.slice();
    cont[i].element = val;
    setContent(cont);
  };

  const deleteContentElement = (i: Number) => {
    setContent(content.filter((obj: any, index: Number) => index !== i));
  };

  const addContentPercentage = async (i: any, val: any) => {
    let cont: any = content.slice();
    cont[i].percentage = parseFloat(val);
    setContent(cont);
  };
  const unitOptions = [
    { value: "gm", label: "gm" },
    { value: "kg", label: "kg" },
    { value: "lt", label: "lt" },
    { value: "ml", label: "ml" },
    { value: "ppm", label: "ppm" },
  ];
  const groupArray = [
    { value: "Contact", label: "Contact" },
    { value: "Systemic", label: "Systemic" },
    { value: "Organic", label: "Organic" },
    { value: "Inorganic", label: "Inorganic" },
    { value: "Fertilizer", label: "Fertilizer" },
    { value: "PGR", label: "PGR" },
    { value: "Biological", label: "Biological" },
  ];

  const typeArray = [
    { value: "--Select--", label: "--Select--" },
    { value: "Other", label: "Other" },
    { value: "Insecticides", label: "Insecticides" },
    { value: "Fungicides", label: "Fungicides" },
  ];

  const onSubmit = () => {
    if (Id) {
      if (window.confirm("Do you want to submit?")) {
        let aliasArr = aliasView.split(",");
        aliasArr = aliasArr?.map((i: any) => i.trim());
        const payload: any = {
          Id,
          name,
          description,
          unit,
          content,
          soilApplication,
          fertigation,
          foliar,
          group,
          aliasArr,
          type,
          applicableTo,
        };
        getFertilizerDetails(Id).then((res: any) => {
          if (res.id) {
            updateFertilizer(payload).then((res: any) => {
              if (res.id) alert("Chemical Updated");
              else alert("Some Error Ocuured");
            });
          } else {
            postFertilizer(payload).then((res: any) => {
              if (res.id) alert("Chemical Added");
              else alert("Some Error Ocuured");
            });
          }
        });
      }
    } else alert("No such chemical");
  };

  const onDelete = () => {
    if (Id) {
      if (window.confirm("Do you want to delete?")) {
        getFertilizerDetails(Id).then((res: any) => {
          if (res.id) {
            deleteFertilizer(Id).then((res: any) => {
              if (res.count) {
                alert("Chemical Deleted");
                navigate("/fertilizer");
              } else alert("Some Error Occured");
            });
          } else alert("Some Error Occured");
        });
      }
    } else alert("No such chemical");
  };

  const addApplicableTo = async () => {
    let newcontentdata = applicableTo || [];
    const obj = {
      cropId: "",
      diseasePestId: "",
      phi: 0,
    };
    newcontentdata = [obj, ...newcontentdata];
    setApplicableTo(newcontentdata);
  };

  const deleteApplicableTo = (index: number) => {
    let newcontentdata = applicableTo || [];
    const updatedData = newcontentdata?.filter((_: any, i: any) => i !== index);
    setApplicableTo(updatedData);
  };

  const updateApplicableTo = (index: number, field: string, value: number) => {
    let newcontentdata = applicableTo || [];
    const updatedApplicable = newcontentdata?.map((n: any, ind: any) =>
      ind === index ? { ...n, [field]: value } : n
    );
    setApplicableTo(updatedApplicable);
  };

  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid p={2} borderRadius={20}>
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton onClick={() => navigate("/fertilizer")}>
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Chemical Details
          </Typography>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 30,
        }}
      >
        <form>
          <LocaleDropdown
            value={id}
            title={"Chemical"}
            titleStyle={{ fontSize: 19, fontWeight: "bold" }}
            onChange={(selected: SingleValue<any>) => {
              setId(selected.value);
              setName(selected.label);
            }}
            containerStyle={{ width: "98%" }}
            isImportant={true}
          />

          <h3 style={{ margin: 5 }}>Type</h3>
          <select
            onChange={(e) => setType(e.target.value)}
            style={{
              width: "99%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 5,
            }}
            value={type}
          >
            {typeArray.map(({ value, label }, index) => (
              <option key={index} value={value}>
                {label}
              </option>
            ))}
          </select>
          <br />

          <h3 style={{ margin: 5 }}>Description</h3>
          <TextArea
            name="description"
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
            style={{
              width: "700px",
              height: "120px",
              padding: "18px",
              resize: "none",
              fontSize: 18,
              margin: "3px",
              marginBottom: 11,
            }}
          ></TextArea>
          <br />

          <h3 style={{ margin: 5 }}>Alias</h3>
          <input
            name="fertilizerAlias"
            onChange={(e) => setAliasView(e.target.value)}
            value={aliasView}
            type="text"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
            }}
          ></input>
          <br />

          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            style={{ marginBottom: 11, marginTop: 8 }}
          >
            <label
              style={{
                fontSize: 20,
                fontWeight: "bold",
                margin: 8,
                marginRight: "70%",
              }}
            >
              Content
            </label>

            <input
              type="button"
              value="Add Content"
              onClick={addcontent}
              style={{
                width: "20%",
                padding: "8px",
                fontSize: 18,
                paddingTop: "10px",
                alignSelf: "center",
              }}
            ></input>
            <br />
          </Grid>
          {content?.map((i: any, index: Number) => {
            return (
              <div>
                <input
                  name="fertilizerContentEle"
                  value={i.element}
                  onChange={(e: any) =>
                    addContentElement(index, e.target.value)
                  }
                  type="text"
                  style={{
                    width: "39%",
                    padding: "20px",
                    fontSize: 18,
                    paddingTop: "15px",
                    margin: "3px",
                    marginBottom: 11,
                  }}
                />
                <input
                  name="fertilizerContentPer"
                  value={i.percentage}
                  onChange={(e: any) =>
                    addContentPercentage(index, e.target.value)
                  }
                  type="number"
                  style={{
                    width: "39%",
                    padding: "20px",
                    fontSize: 18,
                    paddingTop: "15px",
                    margin: "3px",
                    marginBottom: 11,
                  }}
                ></input>
                <IconButton
                  onClick={() => {
                    deleteContentElement(index);
                  }}
                  style={{ marginLeft: 11 }}
                >
                  <DeleteIcon style={{}} />
                </IconButton>
                <br />
              </div>
            );
          })}

          <Grid style={{ marginTop: 11, marginBottom: 11 }}>
            <label
              style={{
                fontSize: 20,
                fontWeight: "bold",
                marginRight: 15,
                marginBottom: 5,
                marginTop: 5,
                marginLeft: 2,
              }}
            >
              Unit
            </label>
            <select
              onChange={(e) => setUnit(e.target.value)}
              style={{
                marginRight: 15,
                marginBottom: 5,
                marginTop: 5,
                height: 53,
                width: 100,
                fontSize: 21,
                padding: 2,
              }}
              value={unit}
            >
              {unitOptions.map(({ value, label }, index) => (
                <option value={value}>{label}</option>
              ))}
            </select>

            <label
              style={{
                fontSize: 20,
                fontWeight: "bold",
                marginRight: 15,
                marginBottom: 5,
                marginTop: 5,
              }}
            >
              Group
            </label>
            <select
              onChange={(e) => setGroup(e.target.value)}
              style={{
                marginRight: 15,
                marginTop: 5,
                height: 53,
                width: 170,
                fontSize: 21,
                padding: 2,
              }}
              value={group}
            >
              {groupArray.map(({ value, label }, index) => (
                <option value={value}>{label}</option>
              ))}
            </select>
            <br />
          </Grid>

          <h3 style={{ margin: 5, marginBottom: 8, marginTop: 25 }}>
            Available For
          </h3>

          <label style={{ fontSize: 18, fontWeight: "bold", marginRight: 11 }}>
            Fertigation
          </label>
          <input
            type="checkbox"
            name="fertigation"
            onChange={(e) => setFertigation(e.target.checked)}
            checked={fertigation}
            style={{ width: "22px", height: "22px", marginRight: 30 }}
          ></input>

          <label style={{ fontSize: 18, fontWeight: "bold", marginRight: 11 }}>
            Soil Application
          </label>
          <input
            type="checkbox"
            name="soilApplication"
            onChange={(e) => setSoilApplication(e.target.checked)}
            checked={soilApplication}
            style={{ width: "22px", height: "22px", marginRight: 30 }}
          ></input>

          <label style={{ fontSize: 18, fontWeight: "bold", marginRight: 11 }}>
            Foliar Application
          </label>
          <input
            type="checkbox"
            onChange={(e) => setFoliar(e.target.checked)}
            name="foliarApplication"
            checked={foliar}
            style={{ width: "22px", height: "22px" }}
          ></input>

          <Grid>
            <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"85%"}
              marginY={2}
            >
              <h3>Applicable To</h3>
              <IconButton onClick={addApplicableTo}>
                <AddCircleOutline />
              </IconButton>
            </Grid>
            {applicableTo?.map((a: any, index: number) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                key={`${index}_${a?.cropId ?? ""}_${a?.diseasePestId ?? ""}`}
              >
                <div style={{ marginRight: "20px" }}>
                  <CropFilter
                    hideTitle
                    defaultBackgroundColor="white"
                    dropdowns={["disease-pest-merged"]}
                    onChange={(e) => {
                      if (e?.id) updateApplicableTo(index, "cropId", e.id);
                      if (e?.diseasePest)
                        updateApplicableTo(
                          index,
                          "diseasePestId",
                          e.diseasePest
                        );
                    }}
                    defaultValue={{
                      cropId: a?.cropId,
                      diseasePestId: a?.diseasePestId
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: -18,
                  }}
                >
                  <h3 style={{ margin: 5, marginBottom: 8 }}>Phi</h3>

                  <input
                    type="number"
                    onChange={(e) =>
                      updateApplicableTo(index, "phi", Number(e.target.value))
                    }
                    name="applicableTo"
                    min={0}
                    value={a?.phi}
                    style={{ width: "100px", height: "35px", fontSize: 18 }}
                  />
                </div>
                <Grid item xs={3}>
                  <IconButton
                    onClick={() => deleteApplicableTo(index)}
                    color="error"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </div>
            ))}
          </Grid>

          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            style={{ marginTop: 55 }}
          >
            <input
              type="button"
              value="Submit"
              onClick={onSubmit}
              style={{
                width: "60%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                alignSelf: "center",
                margin: "30px",
              }}
            />
            <input
              type="button"
              value="Delete"
              onClick={onDelete}
              style={{
                width: "60%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                alignSelf: "center",
                margin: "30px",
              }}
            />
          </Grid>
        </form>
      </div>
    </Grid>
  );
};

export default FertilizerDetails;
