import React from "react";
import AppRouter from "./router";
import { ThemeProvider } from "@mui/material";

import { theme } from './styles/theme'
import { Navbar } from "./components";
import { AuthProvider } from "./context/AuthProvider";
import { Dashboard, Exporter } from "./pages";
import { 
  Chart, 
  ArcElement, 
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend, 
} from "chart.js";

Chart.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
  );

const App: React.FC = (): JSX.Element => {
  return (
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Navbar />
          <Dashboard />
          <Exporter />
          <AppRouter />
        </ThemeProvider>
      </AuthProvider>
  )
}

export default App