import React, { useEffect } from "react";
import { Box, Grid, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import {
  HistoricalData,
  LiveData,
  PlotDetailsMap,
  WeatherData,
} from "../components";
import FilterMap from "src/components/Map";
import { getPlot } from "../services/plot.service";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface ShowPlotData {
  propsPlotId?: string;
  backToPlot?: any;
  tabValue?: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PlotData: React.FC<ShowPlotData> = (props) => {
  const { backToPlot, propsPlotId, tabValue } = props;
  const navigate = useNavigate();
  let { plotId } = useParams();
  if (propsPlotId !== undefined) {
    plotId = propsPlotId;
  }

  const [value, setValue] = React.useState(tabValue ?? 0);
  const [plotInfo, setPlotInfo] = React.useState<any>(null);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (plotId?.length) {
      getPlot(plotId).then((plotInfo: any) => {
        setPlotInfo(plotInfo);
      });
    }
  }, [plotId]);

  return (
    <Grid width={"90%"} my={2} mx={"auto"} boxShadow={2} borderRadius={4}>
      <Grid p={2} borderRadius={20}>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Grid display={"flex"} item alignItems={"center"}>
            <IconButton
              onClick={
                propsPlotId !== undefined ? backToPlot : () => navigate(-1)
              }
            >
              <ArrowBack />
            </IconButton>
            <Typography m={1} variant="h5">
              Plot Data
            </Typography>
          </Grid>
        </Grid>

        {plotInfo && (
          <Grid
            container
            spacing={2}
            justifyContent="center"
          >

            {plotInfo?.farm?.name && (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" align="center">
                  <b>Farm Name:</b> {plotInfo.farm.name}
                </Typography>
              </Grid>
            )}

            {plotInfo?.name && (
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" align="center">
                  <b>Plot Name:</b> {plotInfo.name}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}

        <Grid px={2} py={1}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Live Data" {...a11yProps(0)} />
                <Tab label="Historical Data" {...a11yProps(1)} />
                <Tab label="Weather" {...a11yProps(2)} />
                <Tab label="Map" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <LiveData propsPlotId={propsPlotId} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <HistoricalData propsPlotId={propsPlotId} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <WeatherData propsPlotId={propsPlotId} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <PlotDetailsMap plotId={plotId!} />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlotData;
