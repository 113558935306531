
import { Button, CircularProgress, Grid, Typography } from '@mui/material'
import moment from 'moment'
import { useState } from 'react'
import Select, { MultiValue } from 'react-select'
import { DatePicker } from 'src/components'
import { Location } from 'src/components/Filters'
import { TDownloadRegionPestDiseasePrediction, TGetRegionsPayload, downloadPestDiseasePrediction } from 'src/services/region.service'
import { getExcelData } from '../utils'
import { TDownloadStatus } from '../components/PestFilter'

type Props = {
  crops: MultiValue<any>;
  setDownloadStatus: (downloadStatus: TDownloadStatus) => void;
}

const DiseaseGridProps = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  margin: '10px 0px',
};

const DropDownStyles = {
  container: (baseStyles: any, state: any) => ({
    ...baseStyles,
    width: "100%",
    marginLeft: "5px",
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderTop: state.isFocused ? "1px" : "0px",
    borderLeft: state.isFocused ? "1px" : "0px",
    borderRight: state.isFocused ? "1px" : "0px",
  }),
  menu: (baseStyles: any, state: any) => ({
    ...baseStyles,
    zIndex: 100,
  }),
};

const DropDownProps = {
  styles: DropDownStyles,
  isClearable: true,
  isSearchable: true,
  placeholder: "- select -",
};


const DownloadRegionData = (props: Props) => {

  const { crops, setDownloadStatus } = props;

  // Date
  const [startDate, setStartDate] = useState<Date>(moment().subtract(1, 'day').toDate());
  const [endDate, setEndDate] = useState<Date>(moment().toDate());

  // selected crop from dropdown
  const [selectedCropOption, setSelectedCropOption] = useState<MultiValue<any>>();

  // drodown options
  const [selectedLocation, setSelectedLocation] = useState<TGetRegionsPayload>();

  // loading states
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);

  /**
   * @function to call getRegions when location filter is submitted
   * @param location
   * */
  const getLocation = (location: TGetRegionsPayload) => {
    setSelectedLocation(location);
  }

  /**
   * @function to update selected crops
   * triggered when crop selected from crop-dropdown
   * */
  const handleCropChange = (selected: MultiValue<any>) => {
    setSelectedCropOption(selected);
  };

  /**
   * @function
   * triggered when option selected from dropdown(currently crop only)
   * */
  const handleDropdownChange = (
    dropdown: string,
    selected: MultiValue<any>
  ) => {
    switch (dropdown) {
      case "crop":
        handleCropChange(selected);
        break;
      default:
        break;
    }
  };

  /**
   * @function to download the region-data in excel file
   * calls /get-region-disease-pest-predictions api
   * 
   * if regions has some data (i.e location filter is used),
   *   then use the regions as payload
   * else use downloadDataRegionIds (passed from parent component, has all valid regionIds for the user)
   * 
   * if crop is selected
   *   use selected crops
   * else use all valid crops for the user 
   * */
  const downloadData = () => {
    setIsDownloadLoading(true)
    const payload: TDownloadRegionPestDiseasePrediction = {
      startDate: moment(startDate).startOf('day').toString(),
      endDate: moment(endDate).endOf('day').toString(),
    };

    if (!!selectedCropOption && selectedCropOption.length > 0)
      payload.cropId = selectedCropOption.map(cropOption => cropOption.value);

    if (selectedLocation?.state)
      payload.state = selectedLocation.state;

    if (selectedLocation?.district)
      payload.district = selectedLocation.district;

    if (selectedLocation?.subDistrict)
      payload.subDistrict = selectedLocation.subDistrict;

    if (selectedLocation?.village)
      payload.village = selectedLocation.village;

    downloadPestDiseasePrediction(payload)
      .then((res) => {
        getExcelData(res);
        setDownloadStatus('fulfilled');
      })
      .catch(err => {
        setDownloadStatus('failed');
      })
      .finally(() => {
        setIsDownloadLoading(false);
      })
  }

  /**
   * @functions to update start and end date
   */
  const updateStartDate = (dt: any) => {
    setStartDate(dt);
  }

  const updateEndDate = (dt: any) => {
    setEndDate(dt);
  }

  return (
    <Grid position={'relative'} py={4} px={2}>
      <Grid zIndex={10} bgcolor={'rgba(0, 0, 0, 0.5)'} position={'absolute'} display={isDownloadLoading ? 'flex' : 'none'} justifyContent={'center'} alignItems={'center'} top={0} left={0} right={0} bottom={0} >
        <CircularProgress style={{ color: 'white' }} />
      </Grid>

      <Typography px={2} variant='subtitle2' fontSize={20} textAlign={'center'} mb={2}> Download Pest/Disease Data </Typography>

      <Grid px={2}>
        {/* Dates */}
        <Grid mb={2}>
          <Typography variant="subtitle2" mt={1} >Date</Typography>
          <Grid display={'flex'} p={0}>
            <DatePicker
              showCalendarIcon
              label="From: "
              labelStyle={{ fontWeight: "bold" }}
              value={startDate}
              onChange={updateStartDate}
            />
            <DatePicker
              showCalendarIcon
              label="To: "
              labelStyle={{ fontWeight: "bold" }}
              value={endDate}
              onChange={updateEndDate}
            />
          </Grid>
        </Grid>

        {/* Location */}
        <Grid mb={2}>
          <Location
            getLocation={getLocation}
            handleFilterSearchDataChange={() => { }}
            handleFilterDataChange={() => { }}
            handleRemove={() => { }}
            getLocationOnly
          />
        </Grid>


        {/* Crop */}
        <Grid mb={2}>
          <Typography variant="subtitle2" mt={1} >Crop</Typography>
          <Grid>
            <Grid {...DiseaseGridProps}>
              <Select
                options={crops}
                onChange={(selected: MultiValue<any>) =>
                  handleDropdownChange("crop", selected)
                }
                value={selectedCropOption}
                isMulti
                {...DropDownProps}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid mt={2}>
          <Button
            variant="outlined"
            fullWidth
            style={{ borderRadius: "20px" }}
            size="large"
            color="success"
            onClick={downloadData}
          >
            Download
          </Button>
        </Grid>

      </Grid>
    </Grid>
  )
}

export default DownloadRegionData;
