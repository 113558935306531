import { atom } from "recoil";

type PlotsAtom = {
  ids: string[],
  text: string
}

type ColumnFilteredPlotsAtom = {
  ids: string[],
  columnFilters: any
}


export const plotsAtom = atom({
  key: "plotIds",
  default: {} as PlotsAtom,
})

export const columnFilteredPlotsAtom = atom({
  key: "columnFilteredPlotIds",
  default: {} as ColumnFilteredPlotsAtom,
})

export const filteredPlotsAtom = atom({
  key: 'filteredPlots',
  default: {} as PlotsAtom
})