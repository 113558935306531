import { clearAccessToken, clearBotChatId, getData, postData } from "./common.service";
import { APP_API, CORE_API } from "../constants/api";

export async function getUsers(text: string = '') {
  const qs = {
    where: {
      or:
        [
          { farmUserId: { like: `.*${text}.*`, options: 'i' } },
          { name: { like: `.*${text}.*`, options: 'i' } },
          { mobile: { like: `.*${text}.*`, options: 'i' } }
        ]
    },
    limit: 20
  };
  return getData('/farmusers?filter=' + JSON.stringify(qs), {
    sendTokenInUrl: true
  })
}

export async function getUser(userId: string) {
  return getData(`/farmusers/${userId}`, {
    sendTokenInUrl: true
  })}

export async function generateOTP(payload: any) {
  return postData('/farmusers/generateOTP', {
    body: payload
  });
}

export async function login(credentials: any) {
  return postData('/farmusers/loginAndCheckPushToken', {
    body: credentials,
  });
}

export async function logoutUser() {
  clearAccessToken();
  clearBotChatId();
  return postData('/farmusers/logout', {
    sendTokenInUrl: true,
  })
}

export async function getUserProfile() {
  return getData('/farmusers/getfarmuserData', {
    sendTokenInUrl: true
  });
}

export async function canAccessCustomerData() {
  return getData('/farmusers/canAccessCustomerData', {
    sendTokenInUrl: true,
  });
}

export async function registerUser(userData: any) {
  return postData('/farmusers/register', {
    sendTokenInUrl: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: userData
  });
}

export async function updateUser(userData: any) {
  return postData('/farmusers', {
    sendTokenInUrl: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: userData,
    usePutMethod: true
  });
}

export async function appNotif(plotId: string, skip: number) {

  const qs = {
    where: {
      plotId: plotId
    },
    order: ["date DESC"],
    limit: 5,
    skip: skip
  };
  return getData('/app-notifs?filter=' + JSON.stringify(qs), {
    basePath: CORE_API,
    headers: {
      'lang': 'en',
    },
    sendTokenInHeader: true
  })
}

export async function appNotifCount(plotId: string) {

  const qs = {
      plotId: plotId
  };
  return getData('/app-notifs/count?where=' + JSON.stringify(qs), {
    basePath: CORE_API,
    headers: {
      'lang': 'en',
    },
    sendTokenInHeader: true
  })
}

export async function getAppAnalytics(fromDate: Date, endDate: Date) {
  const url = `/user-events/app-analytics?fromDate=${fromDate}&endDate=${endDate}`
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export async function getReferralAnalytics(fromDate: Date, endDate: Date) {
  const url = `/user-referral/data?startTime=${fromDate}&endTime=${endDate}`
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}