import { Grid, Tooltip, Typography } from "@mui/material"
import { getDate, removeUnderScores } from "../../utils/helper"

interface CropStagesProps {
  cropsSown: any;
}
const CropStages: React.FC<CropStagesProps> = ({ cropsSown }): JSX.Element => {
	const { cropStageIds, activeStage, stageStartDate } = cropsSown
	return (
		<Grid display={'flex'} my={1} >
			<Typography sx={{ fontWeight: '600' }} width={100}> Stages : </Typography>
			<Grid container display={'flex'} flexWrap={'nowrap'} style={{ overflowX: 'scroll', scrollbarWidth: 'thin' }} pb={1} >
				{
					cropStageIds?.map((stage: string, ind: number) => {
						const totalDates = stageStartDate?.length
						const title = ind < totalDates ? getDate(stageStartDate[ind], 'lll') : ''

						const isActiveStage = stage === activeStage
						const bgColor = isActiveStage ? '#0da00a' : ''
						const color = isActiveStage ? 'white' : ''
						const fontSize = isActiveStage ? 15 : 14

						return (
							<Tooltip title={title} placement="bottom" key={`crop_stage_${ind}_at_${stage}`}>
								<Typography
									px={2} mr={1} mb={1}
									borderRadius={10} border={1} borderColor={'#0da00a'}
									color={color}
									bgcolor={bgColor}
									fontSize={fontSize}
									minWidth={'max-content'}
									textTransform={'capitalize'}
								>
									{removeUnderScores(stage)}
								</Typography>
							</Tooltip>
						)
					})
				}
			</Grid>
		</Grid>
	)
}

export default CropStages;