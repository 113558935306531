import { Typography } from '@mui/material';
import React from 'react'

type Props = {
  title: string;
}

const Header: React.FC<Props> = ({
  title
}) => {
  return (
    <Typography>{title}</Typography>
  )
}

export default Header