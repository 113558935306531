import Date from 'src/components/Filters/date';
import React from 'react'
import { Location } from 'src/components/Filters/location.filter';
import { Grid } from '@mui/material';
import { LocationFilter } from 'src/components/Filters';
import dayjs from 'dayjs';

type Props = {
  startDate: Date | undefined,
  endDate: Date | undefined,
  updateStartDate: (newValue: Date) => void;
  updateEndDate: (newValue: Date) => void;
  updateLocation: (newValue: Location) => void;
}

const Filters: React.FC<Props> = (props) => {
  const { startDate, endDate, updateEndDate, updateStartDate, updateLocation } = props;

  const handleDateChange = (filterResponse: any, filterId: any) => {
    const { date } = filterResponse;
    if (filterId === 'startDate') {
      updateStartDate(dayjs(date).startOf("day").toDate());
    } else {
      updateEndDate(dayjs(date).endOf("day").toDate());
    }
  }

  const handleLocationChange = (filterResponse: any, filterId: any) => {
    updateLocation(filterResponse);
  }

  return (
    <Grid p={2}>
      <Grid container alignItems={"center"} justifyContent={"center"} gap={1}>
        <LocationFilter 
          dropdowns={["state", "district"]}
          defaultBackgroundColor='white'
          hideTitle
          onChange={handleLocationChange}
        />

        <Date
          label={'Start Date'}
          defaultValue={startDate}
          onChange={handleDateChange}
          filterId='startDate'
          defaultBackgroundColor='white'
        />

        <Date
          label='End Date'
          filterId='endDate'
          defaultValue={endDate}
          onChange={handleDateChange}
          defaultBackgroundColor='white'
        />
      </Grid>
    </Grid>
  )
}

export default Filters;