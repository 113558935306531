import { Download, BarChart, Refresh, DeleteOutline } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header: React.FC<HeaderProps> = ({
  title,
  date,
  href,
  plotId,
  observationId,
  cropId,
  download,
  deleteObservation,
  recalculate,
}) => {
  return (
    <Grid container justifyContent={"space-between"}>
      <Grid item>
        <Typography fontSize={17} >
          {
            !!href ? <Link to={href} style={{ color: "black" }} target="_blank"> {title}</Link> : <>{title}</>
          }
  
        </Typography>
      </Grid>
      <Grid item display={"flex"} justifyContent={"space-around"} gap={1} alignItems={"center"} >
        <Typography fontSize={16} mr={2}> {dayjs(date).format("DD/MM/YYYY")} </Typography>
        {
          !!download && (
            <Button size="small" variant="contained" color="success" onClick={download}>
              <Download fontSize="small" />
              <Typography ml={1} fontSize={14} textTransform={"capitalize"}>Download</Typography>
            </Button>
          )
        }
  
        {!!plotId && !!cropId && (
          <Link
            to={`/observation-insight`}
            state={{ cropId, plotId }}
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "14px",
              backgroundColor: "blue",
              padding: "5px 10px",
              borderRadius: "5px",
              color: "white",
              textDecoration: "none",
              cursor: "pointer",
              boxShadow: '0 0 5px rgb(0, 0, 0, 0.7)',
            }}
          >
            <BarChart fontSize="small" />
            <span style={{ marginLeft: 4 }}>Insights</span>
          </Link>
        )}
  
        {!!observationId && (
          <Button variant="contained" color="warning" size="small" onClick={recalculate} >
            <Refresh fontSize="small" />
            <Typography textTransform={"capitalize"} ml={1} fontSize={14}>Recalculate</Typography>
          </Button>
        )}
  
        {!!observationId && (
          <Button variant="outlined" color="error" onClick={deleteObservation} size="small">
            <DeleteOutline fontSize="small" />
            <Typography textTransform={"capitalize"} ml={1} fontSize={14}>Delete</Typography>
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

const InfoRow1: React.FC<Row1Props> = ({
  plot,
  crop,
  diseasePest,
  cropStage,
  seasonStartDate
}) => (
  <Grid container gap={5} textAlign={"left"} >
    <InfoRenderer title='Plot' value={plot} href={`/plots/${plot}`} />
    <InfoRenderer title='Crop' value={crop} />
    <InfoRenderer title='Disease/Pest' value={diseasePest} />
    <InfoRenderer title='Crop stage' value={cropStage} />
    <InfoRenderer title='Season start date' value={dayjs(seasonStartDate).format("lll")} />
  </Grid>
)

const ObservationResult: React.FC<ObservationResultProps> = ({
  probability,
  rating,
  riskLevel,
  modelPrediction,
  modelRiskLevel
}) => {
  const GridArgs: any = {
    item: true,
    boxShadow: 1,
    borderRadius: 1.5,
    padding: "5px 15px",
    textAlign: "center",
    minWidth: "100px",
    style: { backgroundColor: BgColorMapping[riskLevel] },
  }

  return (
    <Grid container alignItems={"flex-start"} gap={5}>
      <Grid {...GridArgs}>
        <Typography variant="subtitle1" fontSize={12}>Probability</Typography>
        <div style={{ display: "flex", gap: "10px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" fontSize={12}>Observed</Typography>
            <Typography fontSize={14}>{Number(probability).toFixed(2)}</Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" fontSize={12}>Predicted</Typography>
            <Typography fontSize={14}>{!isNaN(Number(modelPrediction)) ? Number(modelPrediction).toFixed(2) : "-"}</Typography>
          </div>
        </div>
      </Grid>
      <Grid {...GridArgs}>
        <Typography variant="subtitle1" fontSize={12}>Risk Level</Typography>
        <div style={{ display: "flex", gap: "10px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" fontSize={12}>Observed</Typography>
            <Typography fontSize={14}>{riskLevel}</Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" fontSize={12}>Predicted</Typography>
            <Typography fontSize={14}>{modelRiskLevel ?? "-"}</Typography>
          </div>
        </div>
      </Grid>
      <Grid {...GridArgs}>
        <InfoRenderer title='Rating' value={rating} />
      </Grid>
    </Grid>
  )
}

const VegetationReading: React.FC<VegetationReadingProps> = ({
  data
}) => {
  const [indexToRender, setIndexToRender] = useState<number>(0);

  const updateIndexToRender = (index: number) => {
    setIndexToRender(index);
  }

  const dataToRender = data[indexToRender];
  return (
    <Grid item xs={12}>
      <Typography> Vegetation Reading </Typography>

      <PlantationTabs
        totalPlantation={data?.length ?? 0}
        onTabClick={updateIndexToRender}
        activeIndex={indexToRender}
      />

      <PlantationReading
        data={dataToRender}
      />
    </Grid>
  )
}

const PlantationReading: React.FC<{ data: any }> = ({ data }) => {
  const { sample } = data;
  return (
    <Grid container flexWrap={"wrap"} flexShrink={0} flexGrow={0}>
      {Object.entries(sample ?? {}).map(([key, value]: [string, any]) => {
        const reading = value?.reading;
        if (typeof reading === "undefined") {
          return (
            <PlantationSubRow data={value} key={key} title={key} />
          )
        }

        return (
          <ReadingCard key={key} title={key} data={value} hasShadow={true} />
        )
      })}
    </Grid>
  )
}

const PlantationSubRow: React.FC<{ data: any, title: string }> = ({ data, title }) => {
  return (
    <Grid item boxShadow={1} p={1} m={1} xs={5}>
      {title}
      <Grid display={"flex"} flexWrap={"wrap"} item xs={12}>
        {
          Object.entries(data ?? {}).map(([key, value]: [string, any]) => {
            return (
              <ReadingCard key={key} title={key} data={value} hasShadow={false} />
            )
          })
        }
      </Grid>
    </Grid>
  )
}

const ReadingCard: React.FC<any> = ({ data, title, hasShadow }) => {
  const { reading, thumbnails, attachments } = data;

  const GridArgs: any = {
    m: 0.5,
    boxShadow: hasShadow ? 1 : 0,
    item: true,
    padding: 1,
    backgroundColor: "rgba(211, 211, 211, 0.2)",
    borderRadius: 1
  }

  if (title === "rating" || title === "sum") {
    return null;
  }

  return (
    <Grid {...GridArgs}>
      <InfoRenderer title={title} value={reading} />
    </Grid>
  )
}

const PlantationTabs: React.FC<PlantationTabsProps> = ({ totalPlantation, onTabClick, activeIndex }) => {
  return (
    <Grid
      container
      display={"flex"}
      flexWrap={"nowrap"}
      overflow={"scroll"}
      width={"90%"}
      gap={1}
      py={1}
      pb={2}
    >
      {
        Array.from({ length: totalPlantation }).map((_, index) => (
          <PlantationTab index={index} key={index} onTabClick={onTabClick} activeIndex={activeIndex} />
        ))
      }
    </Grid>
  )
}

const PlantationTab: React.FC<PlantationTabProps> = ({ index, onTabClick, activeIndex }) => (
  <Grid item flexShrink={0} borderRadius={1} boxShadow={1}>
    <Button
      onClick={() => onTabClick(index)}
      style={{
        padding: "5px 10px",
        textTransform: "capitalize",
        color: activeIndex === index ? "white" : "gray",
        backgroundColor: activeIndex === index ? "green" : "white",
      }}
    >
      {`Plant ${index + 1}`}
    </Button>
  </Grid>
)

const InfoRenderer: React.FC<InfoRendererProps> = ({
  title,
  value,
  href
}) => {
  if (value === undefined || value === null) return null;

  const renderValue = !isNaN(Number(value)) ? Number(value).toFixed(2) : value;
  return (
    <div className="info-renderer">
      <Typography variant="subtitle1" fontSize={12}>{title}</Typography>
      {href ?
        <Link to={href} target="_blank" >
          <Typography color={"black"} fontSize={14.5}>{renderValue}</Typography>
        </Link> :
        <Typography fontSize={14}>{renderValue}</Typography>
      }
    </div>
  )
}

export {
  Header,
  InfoRow1,
  ObservationResult,
  VegetationReading
}

/**
 * ================== TYPES ======================
 */

type HeaderProps = {
  title: string;
  date: string;
  href?: string;
  plotId?: string;
  cropId?: string;
  observationId?: string;
  download?: () => void;
  deleteObservation?: () => void;
  recalculate?: () => void;
}

type Row1Props = {
  plot: string;
  crop: string;
  diseasePest: string;
  cropStage: string,
  seasonStartDate: string
}

type ObservationResultProps = {
  probability: number;
  riskLevel: string;
  rating: number;
  modelPrediction: any;
  modelRiskLevel?: string;
}

type InfoRendererProps = {
  title: string;
  value: string | number;
  href?: string;
}

type VegetationReadingProps = {
  data: any[]
}

type PlantationTabsProps = {
  totalPlantation: number,
  onTabClick: (index: number) => void,
  activeIndex?: number,
};
type PlantationTabProps = {
  index: number,
  onTabClick: (index: number) => void,
  activeIndex?: number
};

/**
 * ================== CONSTANTS ======================
 */

const BgColorMapping: any = {
  "NIL": "#e9e9e9",
  "LOW": "#80E58B",
  "MEDIUM": "#FFE06B",
  "HIGH": "#FF6B6B",
}