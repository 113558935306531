import { Grid } from "@mui/material";
import { FC } from "react";
import { AppFlowSection, UserInfoSection } from "./sections";

/**
 * 
 * User Event Analysis
 * 
 * Common Component:
 *  time range button group
 * 
 * Seciton 1:
 * 
 * Left:
 *  unique users : line chart, no of unique users in this range
 *  active users : list of top 10 active users in that time frame
 * 
 * Right:
 *  pie chart for highest active feature
 * 
 * Section 2:
 *  user flows
 *  conversion ratio
 * 
 * */ 

const UserEventAnalysis: FC = () => {

  return (
    <Grid px={5} py={2}>
      <UserInfoSection />
      <AppFlowSection />
    </Grid>
  )
}

export default UserEventAnalysis;