import { CalendarMonthOutlined, Clear } from "@mui/icons-material";
import { Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import './datepicker.css';
import { ArrowBack, ArrowForward, PlayCircle, PauseCircle } from '@mui/icons-material';
import useDatepicker from "src/hooks/useDatePicker";
import dayjs from "dayjs";

const defaultDatePickerPlaceholder = {
  dayPlaceholder: 'D',
  monthPlaceholder: 'M',
  yearPlaceholder: 'YYYY',
  // showLeadingZeros: true,
  calendarIcon: <></>,
  clearIcon: <Grid ml={0.5}><Clear style={{ color: 'gray' }} fontSize="small" /></Grid>,
}


type Props = {
  placeholder?: object;
  value: Date | null;
  onChange: (x: Date | null) => void;
  showCalendarIcon?: boolean,
  hasAutoplay?: boolean;
  hasDateChangeIcon?: boolean,
  futurePred?: boolean,
  label?: string,
  labelStyle?: any,
  maxDate?: Date,
  display?: "flex" | "block",
}

const CustomDatePicker: React.FC<Props> = (props: Props): JSX.Element => {
  const { showCalendarIcon, hasAutoplay, hasDateChangeIcon, futurePred, label = 'Date', labelStyle = {}, maxDate = new Date(), display, ...datePickerProps } = props;

  const { currentDate, toggleAutoPlay, updateDate, isAutoPlay, buttonDisabled, lastDate, dateHandler } = useDatepicker(dayjs(datePickerProps.value));

  const [MAX_DATE, SET_MAX_DATE] = useState<Date>(new Date())

  useEffect(() => {
    props.onChange(new Date(currentDate.toISOString()))
  }, [currentDate])

  if (props.showCalendarIcon) {
    defaultDatePickerPlaceholder.calendarIcon = <IconButton><CalendarMonthOutlined fontSize="small" /></IconButton>
  }

  const calendarClosed = () => {
    dateHandler(props.value)
  }

  useEffect(() => {
    if (!!futurePred) {
      SET_MAX_DATE(new Date(Infinity))
    }
    else {
      SET_MAX_DATE(maxDate)
    }
  }, [])

  return (
    <Grid display={display ? display : "flex"} alignItems={'center'} flexWrap={'wrap'} my={1}>
      <Typography variant="subtitle1" ml={2} style={labelStyle} >{label}</Typography>

      {
        hasDateChangeIcon &&
        <IconButton
          onClick={() => updateDate(-1)}
          disabled={buttonDisabled}
        >
          <ArrowBack fontSize="small" />
        </IconButton>
      }

      <DatePicker
        {...defaultDatePickerPlaceholder}
        {...datePickerProps}
        maxDate={MAX_DATE}
        format={'d-M-y'}
        onCalendarClose={calendarClosed}
      />

      {
        hasDateChangeIcon && <IconButton
          onClick={() => updateDate(1)}
          disabled={buttonDisabled}
        >
          <ArrowForward fontSize="small" />
        </IconButton>
      }

      {
        hasAutoplay &&
        <Button
          variant={isAutoPlay ? 'contained' : 'outlined'}
          color="success" size="small"
          onClick={toggleAutoPlay}
          style={{ borderRadius: '20px', display: 'flex', justifyContent: 'space-between', padding: '2px 5px' }}
          disabled={lastDate === dayjs(currentDate).format('DD/MM/YYYY')}
        > {
            isAutoPlay ?
              <>
                <PauseCircle />
                <Typography textTransform={'capitalize'} fontSize={13} >Stop</Typography>
              </> :
              <>
                <PlayCircle />
                <Typography textTransform={'capitalize'} fontSize={13} >Play</Typography>
              </>
          }
        </Button>
      }

    </Grid>
  )
}

export default CustomDatePicker;