import { Grid, Typography } from "@mui/material";
import { FC } from "react";

interface Props {
  label: string;
  value: string;
}

const Chip: FC<Props> = ({ label, value }) => {
  return (
    <Grid position={'relative'} mx={0.5} border={0.4} px={1} py={0} borderRadius={5} style={{
      borderColor: 'green'
    }}>
      <Typography position={'absolute'} top={-15}  ml={-1} fontSize={10}>{label}</Typography>
      <Typography color={'green'} fontSize={12} fontWeight={600}>{value}</Typography>
    </Grid>
  )
}

export default Chip;