import { Grid, Skeleton, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { getLatestWeatherForecast } from "../../services/weather.service"
import { WeatherCard } from "../"

const WeatherCardSkeleton: React.FC = (): JSX.Element => (
  <>
    <Skeleton variant='rectangular' width={430} height={330} style={{ borderRadius: '15px', margin: '15px 10px' }} />
    <Skeleton variant='rectangular' width={430} height={330} style={{ borderRadius: '15px', margin: '15px 10px' }} />
    <Skeleton variant='rectangular' width={430} height={330} style={{ borderRadius: '15px', margin: '15px 10px' }} />
  </>
)

interface PlotID {
  propsPlotId?: string;
}

const WeatherData: React.FC<PlotID> = (props): JSX.Element => {
  const { propsPlotId } = props;
  let { plotId } = useParams();
  if (propsPlotId !== undefined) { plotId = propsPlotId; }
  const [latestWeatherForecast, setLatestWeatherForecast] = useState<any>()
  const [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    getLatestWeatherForecast(plotId as string)
      .then(res => {
        setLatestWeatherForecast(res)
        setIsReady(true)
      })
  }, [plotId])

  return (
    <Grid>
      <Grid item component={'div'} >
        <Typography textAlign={'center'} variant='h5' >Daily Weather Data</Typography>
        <Grid container flexWrap={'nowrap'} style={{ overflowX: 'scroll' }} >
          {
            isReady ?
              latestWeatherForecast?.dailyWeatherForecastData?.map((weather: any) => {
                return <WeatherCard data={weather} />
              }) : <WeatherCardSkeleton />
          }
        </Grid>
      </Grid>

      <Grid item component={'div'} mt={4}>
        <Typography textAlign={'center'} variant='h5' >Hourly Weather Data</Typography>
        <Grid container flexWrap={'nowrap'} style={{ overflowX: 'scroll' }} >
          {
            isReady ?
              latestWeatherForecast?.hourlyWeatherForecastData?.map((weather: any) => {
                return <WeatherCard data={weather} />
              }) : <WeatherCardSkeleton />
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WeatherData