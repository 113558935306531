import { Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack, Cancel } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  postInventoryItem,
  putInventoryItem,
  deleteInventoryItem,
  getInventoryItems,
} from "../services/inventory.service";
import Select, { MultiValue } from "react-select";
import { generateOptions } from "src/utils/helper";

interface Props {
  selectedModalData?: any;
  setOpenModal?: any;
}
const InventoryItem: React.FC<Props> = (props: Props): JSX.Element => {
  const res: any = props?.selectedModalData;
  const [name, setName] = useState<any>(res?.name || "");
  const [description, setDescription] = useState<string>(
    res?.description || ""
  );
  const [deviceOptions, setdeviceOptions] = useState<MultiValue<any>>();
  const [components, setComponents] = useState<MultiValue<any>>([]);
  const [isGroupedItem, setIsGroupedItem] = useState<boolean>(
    res?.isGroupedItem || false
  );

  const onSubmit = () => {
    if (name) {
      if (window.confirm("Do you want to submit?")) {
        const payload: any = {
          name: name,
          description: description,
          isGroupedItem: isGroupedItem,
        };
        if (!!components?.length && !!isGroupedItem)
          payload["components"] = components?.map((component) => ({
            itemId: component?.itemId,
            quantity: component?.quantity,
          }));
        if(!isGroupedItem && !!components?.length){
            payload["components"] =[];
        }
        if (res?.id) {
          putInventoryItem(payload, res.id).then((res: any) => {
            if (res.message === "Success") {
              alert("Item updated");
              props?.setOpenModal(false);
            } else alert("Some Error Ocuured");
          });
        } else {
          postInventoryItem(payload).then((res: any) => {
            if (res.id) {
              alert("Item added");
              props?.setOpenModal(false);
            } else alert("Some Error Ocuured");
          });
        }
      }
    } else alert("Some Error Ocuured");
  };

  const onDelete = () => {
    if (res?.id) {
      if (window.confirm("Do you want to delete?")) {
        deleteInventoryItem(res.id).then((res: any) => {
          if (res.message === "Success") {
            alert("Item deleted");
            props?.setOpenModal(false);
          } else alert("Some Error Ocuured");
        });
      }
    } else alert("Some Error Occured");
  };

  useEffect(() => {
    getInventoryItems().then((response) => {
      const itemOptions = generateOptions(response, "id", "name");
      setdeviceOptions(itemOptions);
      if (res?.components) {
        const existingComponents: any[] = [];
        res?.components?.forEach((item: any) => {
          existingComponents.push({
            ...item,
            label: itemOptions.filter((i: any) => i.value === item?.itemId)[0]
              ?.label,
            value: item.itemId,
          });
        });
        setComponents(existingComponents);
      }
    });
  }, []);
  const navigate = useNavigate();

  return (
    <Grid
      width="90%"
      my={2}
      mx="auto"
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid p={2} borderRadius={25} style={{ backgroundColor: "#f5f5f5" }}>
        <Grid container alignItems="center">
          <IconButton
            onClick={() =>
              props?.setOpenModal
                ? props.setOpenModal(false)
                : navigate("/inventory")
            }
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" style={{ marginLeft: 10 }}>
            Item
          </Typography>
        </Grid>
      </Grid>

      <div style={{ justifyContent: "center", padding: 20 }}>
        <form>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label
                style={{
                  fontSize: 19,
                  fontWeight: "bold",
                  marginRight: 5,
                  margin: 5,
                }}
              >
                Name
              </label>
              <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
                *
              </label>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label
                style={{ fontSize: 18, fontWeight: "bold", marginRight: 11 }}
              >
                Grouped Item
              </label>
              <input
                type="checkbox"
                name="groupedItem"
                onChange={(e) => setIsGroupedItem(!isGroupedItem)}
                checked={isGroupedItem}
                style={{ width: "22px", height: "22px", marginRight: 30 }}
              ></input>
            </div>
          </div>
          <input
            name="name"
            onChange={(e) => setName(e.target.value)}
            value={name}
            type="text"
            style={{
              width: "93%",
              padding: "10px",
              fontSize: 18,
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
              border: "1px solid #ccc",
              borderRadius: 5,
            }}
          />

          <h3 style={{ margin: 5 }}>Description</h3>
          <textarea
            name="description"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            style={{
              width: "93%",
              padding: "10px",
              fontSize: 18,
              margin: "3px",
              marginBottom: 11,
              border: "1px solid #ccc",
              borderRadius: 5,
            }}
          ></textarea>

          {!!isGroupedItem && (
            <Grid>
              <h3 style={{ margin: 5 }}>Components</h3>
              <Select
                options={deviceOptions}
                onChange={(selected: MultiValue<any>) =>
                  setComponents(selected)
                }
                value={components}
                isClearable={true}
                isSearchable={true}
                isMulti
                placeholder={"- select -"}
                styles={{
                  container: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    width: "95%",
                    marginLeft: "5px",
                    border: "1px solid #ccc",
                    borderRadius: 5,
                    marginBottom: 8,
                  }),
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderTop: state.isFocused ? "1px" : "0px",
                    borderLeft: state.isFocused ? "1px" : "0px",
                    borderRight: state.isFocused ? "1px" : "0px",
                  }),
                  menu: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    zIndex: 100,
                  }),
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                {components?.map((c: any, index: any) => (
                  <Grid
                    key={c.value}
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    style={{
                      marginTop: 5,
                      padding: "10px",
                      border: "1px solid #ddd",
                      borderRadius: "8px",
                      backgroundColor: "#f9f9f9",
                      width: "100%",
                      maxWidth: "500px",
                    }}
                  >
                    <Typography variant="body2" style={{ flex: 1 }}>
                      {c.label}
                    </Typography>
                    <input
                      value={c?.quantity}
                      onChange={(e) => {
                        components[index].quantity = Number(e.target.value);
                        components[index].itemId = c?.value;
                        setComponents([...components]);
                      }}
                      type="number"
                      min={1}
                      style={{
                        padding: "8px 12px",
                        width: "100px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        marginLeft: "10px",
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        setComponents(
                          components.filter((i) => i.value !== c.value)
                        );
                      }}
                      style={{
                        marginLeft: "10px",
                        padding: "8px",
                        color: "#f44336",
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  </Grid>
                ))}
              </div>
            </Grid>
          )}
          <Grid
            container
            justifyContent="space-between"
            style={{ marginTop: 55 }}
          >
            <input
              type="button"
              value="Submit"
              onClick={onSubmit}
              style={{
                width: "42%",
                padding: "10px",
                fontSize: 18,
                backgroundColor: "#4caf50",
                color: "#fff",
                border: "none",
                borderRadius: 5,
                cursor: "pointer",
              }}
            />
            {res?.id && (
              <input
                type="button"
                value="Delete"
                onClick={onDelete}
                style={{
                  width: "42%",
                  padding: "10px",
                  fontSize: 18,
                  backgroundColor: "#f44336",
                  color: "#fff",
                  border: "none",
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              />
            )}
          </Grid>
        </form>
      </div>
    </Grid>
  );
};

export default InventoryItem;
