import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';

type Props = {
	value: number;
	setValue: (value: number) => void;
	tabs: string[]
}

const TabMenu = (props: Props) => {
	const { tabs, value, setValue } = props;

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Tabs
				onChange={handleChange}
				value={value}
				aria-label="Tabs where selection follows focus"
				selectionFollowsFocus
			>
				{tabs.map((tab, index) => <Tab label={tab} key={`tab-${tab}-${index}`} />)}
			</Tabs>
		</Box>
	)
}

export default TabMenu;
