import { DeleteOutline, Done } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { KeyValues, FilterDataValue, FilterProps } from '../../constants/interfaces';
import { getCropOptions, getCrops, getCropStages, getCropVarieties } from "../../services/crop.service";
import { generateOptions, sortByKey } from "../../utils/helper";

const CropFilter: React.FC<FilterProps> = (props: FilterProps): JSX.Element => {
  const { handleFilterDataChange, handleRemove, filterId } = props

  const [isApplied, setIsApplied] = useState(false)

  // drodown options
  const [crops, setCrops] = useState<SingleValue<any>>([])
  const [stages, setStages] = useState<SingleValue<any>>([])
  const [varieties, setVarieties] = useState<SingleValue<any>>([])

  // selected options
  const [selectedCropOption, setSelectedCropOption] = useState<any>(null)
  const [selectedStageOption, setSelectedStageOption] = useState<any>(null)
  const [selectedVarietyOption, setSelectedVarietyOption] = useState<any>(null)

  // loading states
  const [isCropLoading, setIsCropLoading] = useState<boolean>(true)
  const [isStageLoading, setIsStageLoading] = useState<boolean>(false)
  const [isVarietyLoading, setIsVarietyLoading] = useState<boolean>(false)

  const handleFitlerChange = (status: boolean, plotIds: FilterDataValue) => {
    handleFilterDataChange(filterId, status, plotIds)
    setIsApplied(status)
  }

  useEffect(() => {
    getCropOptions()
      .then(res => {
        setCrops(generateOptions(res, 'cropId', 'cropName'))
        setIsCropLoading(false)
      })
  }, [])

  const handleCropChange = (selected: SingleValue<any>) => {
    setSelectedCropOption(selected)
    const { value } = selected;

    setIsStageLoading(true)
    setIsVarietyLoading(true)

    getCropStages(value)
      .then((res: any[]) => {
        const sortedStages = sortByKey(res, 'order')
        setStages(generateOptions(sortedStages, 'stageId', 'name'))
        setIsStageLoading(false)
      })

    getCropVarieties(value)
      .then(res => {
        setVarieties(generateOptions(res, 'id', 'name'))
        setIsVarietyLoading(false)
      })

  }

  const handleStageChange = (selected: SingleValue<any>) => {
    setSelectedStageOption(selected)
  }

  const handleVarietyChange = (selected: SingleValue<any>) => {
    setSelectedVarietyOption(selected)
  }

  const handleDropdownChange = (dropdown: string, selected: SingleValue<any>) => {
    const value = selected?.value;

    switch (dropdown) {
      case 'crop':
        if (value) handleCropChange(selected)
        else unselectOptions(['crop', 'variety', 'stage']);
        break;
      case 'stage':
        if (value) handleStageChange(selected)
        else unselectOptions(['stage'])
        break;
      case 'variety':
        if (value) handleVarietyChange(selected)
        else unselectOptions(['variety'])
        break;
      default:
        break;
    }
  }

  const unselectOptions = (toUnselect: string[]) => {
    let cnt = 0;
    for (const field of toUnselect) {
      if (field === 'crop') {
        setSelectedCropOption(null)
        cnt++;
      } else if (field === 'stage') {
        setSelectedStageOption(null)
        if (cnt++ > 0) setStages([])
      } else {
        setSelectedVarietyOption(null)
        if (cnt++ > 0) setVarieties([])
      }
    }
  }

  const onSubmit = (): void => {
    if (!selectedCropOption) {
      alert('select crop')
      return
    }

    let payload: any = {
      cropId: selectedCropOption.value
    }

    if (selectedStageOption) {
      payload.stageId = selectedStageOption.value
    }

    if (selectedVarietyOption) {
      payload.varietyId = selectedVarietyOption.value
    }

    getCrops(payload)
      .then((res: KeyValues[]) => {
        const plotIds = res.map((plotId) => plotId.plotId) as string[];
        handleFitlerChange(true, plotIds)
      })
  }

  return (
    <>
      <Typography variant="subtitle2" mt={1} >Crop</Typography>
      <div style={{
        backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
        margin: '0px 0 15px 0',
        padding: '5px 10px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center'
      }} >

        <Grid width={250} mr={1} data-tesid="crop-select-dashboard">
          <Typography variant="subtitle1" fontWeight={600}>Crop</Typography>
          <Select
            options={crops}
            onChange={(selected: SingleValue<any>) => handleDropdownChange('crop', selected)}
            isSearchable={true}
            placeholder={'- select -'}
            isLoading={isCropLoading}
            isDisabled={isCropLoading}
            value={selectedCropOption}
            isClearable
          />
        </Grid>

        <Grid width={250} mr={1} data-tesid="variety-dashboard">
          <Typography variant="subtitle1" fontWeight={600}>Variety</Typography>
          <Select
            options={varieties}
            onChange={(selected: SingleValue<any>) => handleDropdownChange('variety', selected)}
            isSearchable={true}
            placeholder={'- select -'}
            isLoading={isVarietyLoading}
            isDisabled={!selectedCropOption || isVarietyLoading}
            value={selectedVarietyOption}
            isClearable
          />
        </Grid>

        <Grid width={250} mr={1} data-tesid="stage-dashboard">
          <Typography variant="subtitle1" fontWeight={600}>Stage</Typography>
          <Select
            options={stages}
            onChange={(selected: SingleValue<any>) => handleDropdownChange('stage', selected)}
            isSearchable={true}
            placeholder={'- select -'}
            isLoading={isStageLoading}
            isDisabled={!selectedCropOption || isStageLoading}
            value={selectedStageOption}
            isClearable
          />
        </Grid>

        <Grid>
          <IconButton onClick={onSubmit} style={{ position: 'relative', top: '12px' }} >
            <Done sx={{ border: 1, borderRadius: '100%' }} color="success" />
          </IconButton>
          <IconButton onClick={() => handleRemove(filterId)} style={{ position: 'relative', top: '12px' }} >
            <DeleteOutline style={{ border: '1px solid red', borderRadius: '100%', padding: '1px' }} color="error" />
          </IconButton>
        </Grid>
      </div>
    </>
  )
}

export default memo(CropFilter);