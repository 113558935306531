import { ArrowBack } from '@mui/icons-material';
import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import './Header.css';

type Props = {
  title: string;
  buttonTitle?: string;
  onButtonClick?: () => void;
}

const Header: React.FC<Props> = (props) => {
  const { title, buttonTitle, onButtonClick } = props;

  const navigate = useNavigate();

  return (
    <Grid p={2} borderRadius={20} display={"flex"} alignItems={'center'} justifyContent={"space-between"}>
      <Grid display={'flex'} alignItems={'center'} margin={1}>
        <IconButton size='small' onClick={() => navigate('/')} ><ArrowBack fontSize='small' /></IconButton>
        <Typography m={1} variant='h6'> {title} </Typography>
      </Grid>
      
      {
        !!buttonTitle && (
          <button onClick={onButtonClick ? onButtonClick : () => {}} className='add-button' >
            {buttonTitle}
          </button>
        )
      }
    </Grid>
  )
}

export default Header