// navbar code link: https://codesandbox.io/s/gzc2rq?file=/demo.tsx:0-75
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AppBar,
  Button,
  Toolbar,
  Grid
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import images from 'src/constants/images';
import { logoutUser } from 'src/services/user.service';
import { Roles } from "src/constants/interfaces";
import { isOpenURL, getClientName } from 'src/utils/helper';
import { NavbarLinks } from 'src/components';

const ResponsiveAppBar: React.FC = () => {
  const isLoggedIn = (typeof localStorage.getItem('access_token') === 'string' ? true : false);
  const roles = JSON.parse(localStorage.getItem('roles')!);
  let allowedRoles = [Roles.ADMIN.toString(), Roles.SUPPORT.toString()]
  let hasPermission = roles?.find((role: string) => allowedRoles?.includes(role));

  const hasPlantProtectionRole = !!roles && roles.length === 1 && roles[0] === 'plantProtection'
  const hasfarmUserRole = !!roles && roles.length === 1 && roles[0] === 'farmuser'

  const [logo, setLogo] = useState<any>();
  const [companySlogan, setCompanySlogan] = useState<string>("");

  const navigate = useNavigate()
  const handleLogout = () => {
    logoutUser()
      .then((res) => {
        redirectToLogin();
      })
  }

  const redirectToLogin = () => {
    navigate('/login')
  }

  const navMenuStyleRevisit = {
    textDecoration: 'none',
    color: '#565656',
    fontSize: 16,
    marginRight: 30,
    fontWeight: '400',
    alignSelf: 'center',
    marginBottom: '-2px',
  }
  let location = useLocation();
  let showNavbarMenu = true;
  if (location.pathname === '/conversations') {
    showNavbarMenu = false
  }

  useEffect(() => {
    checkForIcon();
  }, [location]); // eslint-disable-line

  const checkForIcon = () => {
    let { search } = location;
    if (!search) {
      const clientName = localStorage.getItem("client") ?? "";
      if (!!clientName) {
        search = `client=${clientName}`;
      }
    }

    const clientName = getClientName({ search });
    if (!!clientName) {
      setLogo((images as any)[clientName]);
    } else {
      setLogo(images.logo);
      setCompanySlogan("Where technology meets agriculture");
    }
  }

  return (
    <AppBar position="static" style={{
      backgroundColor: isLoggedIn ? '#FFFFFF' : '#006737',
      boxShadow: '0px 4px 10px -10px #111',
      display: (location.pathname === '/conversations') ? 'none' : '',
    }}>

      <Grid px={2}>
        <Toolbar disableGutters style={{
          display: 'flex',
          justifyContent: 'space-between'
        }} >
          {/* company logo */}
          <Link
            style={{
              textDecoration: 'none',
              paddingTop: '10px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start'
            }}
            to={isOpenURL(window.location.pathname) ? '/login' : !!hasPlantProtectionRole ? '/region' : '/'}
          >
            <img src={logo} width='140px' alt="fyllo" />
            <p style={{
              textDecoration: "none",
              fontSize: 10,
              fontWeight: 'bold',
              textTransform: "capitalize",
              color: "green",
              margin: 0,
              padding: 0,
              marginBottom: 5,
            }}>
              {companySlogan}
            </p>
          </Link>

          {
            isLoggedIn &&
            <div data-testid="dashboard-navbar" style={{ color: 'black', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginLeft: '-20px' }}>
              {(hasPlantProtectionRole || !roles?.length) ? (
                <></>
              ) : (
                <Link data-testid="Plots-navbar" style={navMenuStyleRevisit} to={'/home'}>Plots</Link>
              )}
              {(hasfarmUserRole || !roles?.length) ? (
                <></>
              ) : (
                <Link data-testid="Regions-navbar" style={{ ...navMenuStyleRevisit, marginRight: 16 }} to={'/region'}>Regions</Link>
              )}
              {
                hasPermission && showNavbarMenu ?
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginRight: '3vw' }}>
                    <NavbarLinks title={'Agronomy'} links={[
                      {
                        'label': 'Chemicals',
                        'link': 'fertilizer',
                      },
                      {
                        'label': 'Fertigations',
                        'link': 'nutrient',
                      },
                      {
                        'label': 'Pesticide Schedule',
                        'link': 'pesticide',
                      },
                      {
                        'label': 'LAI Advisory',
                        'link': 'lai-advisory'
                      },
                      {
                        'label': 'IDPM',
                        'link': 'idpm'
                      },
                      {
                        'label': 'Crop',
                        'link': 'cropCharacteristics'
                      },
                      {
                        'label': 'Observations',
                        'link': 'observations'
                      },
                      {
                        'label': 'Observation Type',
                        'link': 'observation-type'
                      },
                      {
                        'label': 'Spray Guidelines',
                        'link': 'spray-config'
                      },
                      {
                        'label': 'Image Analysis',
                        'link': 'image-analysis',
                      },
                      {
                        'label': 'Soil Standard',
                        'link': 'soil-standard'
                      },
                    ]} />
                    <NavbarLinks title={'Admin'} links={[
                      {
                        'label': 'Farm Users',
                        'link': 'farmUser',
                      },
                      {
                        'label': 'Videos Guide',
                        'link': 'videos-guide',
                      },
                      {
                        'label': 'Consultants',
                        'link': 'consultants',
                      },
                      {
                        'label': 'Inventory',
                        'link': 'inventory',
                      },
                      {
                        'label': 'Crop Region',
                        'link': 'region-crop',
                      },
                      {
                        'label': 'Locale',
                        'link': 'locale',
                      },
                      {
                        'label': 'Region',
                        'link': 'regions'
                      },
                      {
                        'label': 'Farmer Profile',
                        'link': 'farmer-profile',
                      }
                    ]} />
                    <NavbarLinks title={'CRM'} links={[
                      {
                        'label': 'Notifications',
                        'link': 'notifications',
                      },
                      {
                        'label': 'Follow Ups',
                        'link': 'followups',
                      },
                      {
                        'label': 'Maintenance',
                        'link': 'maintenance',
                      },
                      {
                        'label': 'Leads',
                        'link': 'leads'
                      },
                      {
                        'label': 'Analytics',
                        'link': 'analytics',
                      }
                    ]} />
                    <NavbarLinks title={'Finance'} links={[
                      {
                        'label': 'Payments',
                        'link': 'payments',
                      },
                      {
                        'label': 'Vouchers',
                        'link': 'voucher',
                      },
                    ]} />
                    <NavbarLinks title={'Analysis'} links={[
                      {
                        'label': 'Adherence',
                        'link': 'adherence',
                      },
                      {
                        'label': 'Farm Activities',
                        'link': 'farm-activities',
                      },
                      {
                        'label': 'Sensor Data',
                        'link': 'analysis',
                      },
                      {
                        'label': 'User Events',
                        'link': 'user-events-analysis',
                      },
                      {
                        'label': 'Sales',
                        'link': 'sales',
                      },
                      {
                        'label': 'Region Report',
                        'link': 'region-report'
                      }
                    ]} /></div>
                  :
                  null
              }
              <Button color="success" data-testid="logout-navbar" variant='contained' onClick={handleLogout} >Logout</Button>
            </div>
          }

        </Toolbar>
      </Grid>
    </AppBar>
  );
};
export default ResponsiveAppBar;