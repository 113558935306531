import { getData, postData } from "./common.service";

const CORE_API = process.env.REACT_APP_CORE_API;

export const startConversation = async (payload: any, signal?: AbortSignal) => {
  const url = "/dharti?path=start_conversation";
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    signal,
    body: payload
  })
}

export const sendMessageToBot = async (id: any, payload: any, signal?: AbortSignal) => {
  const url = `/dharti?path=send_message/${id}`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    signal,
    body: payload
  })
}

export const checkConversation = async (id: any) => {
  const url = `/dharti?path=is_conversation/${id}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  })
}