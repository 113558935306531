import React, { useEffect, useRef, useState } from 'react';
import { DropDownContainerProps, DropdownProps, DropdownState, FilterProps, RenderDropdownArgs } from '../types';
import {
  getAreas,
  getDistricts, getStates,
  getSubDistricts
} from "../../../services/location.service";
import { generateOptions } from 'src/utils/helper';
import { Option } from 'src/constants/interfaces';
import Select, { SingleValue } from 'react-select';
import { Grid } from '@mui/material';
import Label from '../Label';
import Header from '../Header';
import { deleteButton, resetButton, submitButton } from '../action-buttons';

const LocationFilter: React.FC<FilterProps> = (props) => {
  const {
    actionButtonType,
    defaultBackgroundColor,
    defaultValue,
    dropdowns,
    errorBackgroundColor,
    filterId,
    hasDeleteButton,
    hasResetButton,
    hasSubmitButton,
    hideTitle,
    submittedBackgroundColor,
    onChange,
    onDelete,
    onReset,
    onSubmit,
  } = props;

  const [state, setState] = useState<DropdownState>();
  const [district, setDistrict] = useState<DropdownState>();
  const [subDistrict, setSubDistrict] = useState<DropdownState>();
  const [village, setVillage] = useState<DropdownState>();

  const [isApplied, setIsApplied] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>();

  const defaultSelected = useRef<any>();

  const showDistrictDropdown = dropdowns?.includes("district");
  const showSubDistrictDropdown = dropdowns?.includes("sub-district");
  const showVillageDropdown = dropdowns?.includes("village");

  useEffect(() => {
    defaultSelected.current = defaultValue;
    getStateOptions();
  }, []) // eslint-disable-line

  useEffect(() => {
    if(!!onChange) {
      const payload = generatePayload();
      onChange(payload);
    }
  }, [state?.selected, district?.selected, subDistrict?.selected, village?.selected]) // eslint-disable-line

  // api calls
  const getStateOptions = async () => {
    setState({ isLoading: true, options: [], selected: null, error: null });
    getStates()
      .then((res: { state: string }[]) => {
        const options = generateOptions(res.map((obj) => obj.state).sort());
        setState((data) => ({ ...data, options }));
        handleDefaultState(options);
      })
      .catch(error => {
        setState((data) => ({ ...data, error }));
      })
      .finally(() => {
        setState((data) => ({ ...data, isLoading: false }));
      })
  }

  const getDistrictOptions = (state: string) => {
    setDistrict({ isLoading: true, options: [], selected: null, error: null });
    getDistricts(state)
      .then((res: { district: string }[]) => {
        const options = generateOptions(res.map((obj) => obj.district).sort());
        setDistrict((data) => ({ ...data, options }));
        // handleDefaultState(options);
      })
      .catch(error => {
        setDistrict((data) => ({ ...data, error }));
      })
      .finally(() => {
        setDistrict((data) => ({ ...data, isLoading: false }));
      })
  }

  const getSubDistrictOptions = (state: string, district: string) => {
    setSubDistrict({ isLoading: true, options: [], selected: null, error: null });
    getSubDistricts(state, district)
      .then((res: { subDistrict: string }[]) => {
        const options = generateOptions(res.map((obj) => obj.subDistrict).sort());
        setSubDistrict((data) => ({ ...data, options }));
        // handleDefaultState(options);
      })
      .catch(error => {
        setSubDistrict((data) => ({ ...data, error }));
      })
      .finally(() => {
        setSubDistrict((data) => ({ ...data, isLoading: false }));
      })
  }

  const getVillageOptions = (state: string, district: string, subDistrict: string) => {
    setVillage({ isLoading: true, options: [], selected: null, error: null });
    getAreas(state, district, subDistrict)
    .then((res: { area: string }[]) => {
      const options = generateOptions(res.map((obj) => obj.area).sort());
      setVillage((data) => ({ ...data, options }));
      // handleDefaultState(options);
    })
    .catch(error => {
      setVillage((data) => ({ ...data, error }));
    })
    .finally(() => {
      setVillage((data) => ({ ...data, isLoading: false }));
    })
  }



  // dropdown handlers
  const handleDropdownChange = (
    dropdown: Dropdown,
    selected: SingleValue<Option>
  ) => {
    switch (dropdown) {
      case "state":
        handleStateChange(selected)
        unselectOptions(['district', 'sub-district', 'village']);
        break;
      case "district":
        handleDistrictChange(selected);
        unselectOptions(['sub-district', 'village']);
        break;
      case "sub-district":
        handleSubDistrictChange(selected);
        unselectOptions(['village']);
        break;
      case "village":
        handleVillageChange(selected);
        unselectOptions([]);
        break;
    }
  }

  const unselectOptions = (toUnselect: Dropdown[]) => {
    let cnt = 0;
    for (const field of toUnselect) {
      if (field === "state") {
        setState(data => ({ ...data, selected: null }));
        cnt++;
      } else if (field === "district") {
        setDistrict(data => ({ ...data, selected: null }));
        if (cnt++ > 0) setDistrict(data => ({ ...data, options: [], selected: null }));
      } else if (field === "sub-district") {
        setSubDistrict(data => ({ ...data, selected: null }));
        if (cnt++ > 0) setSubDistrict(data => ({ ...data, options: [], selected: null }));
      } else {
        setVillage(data => ({ ...data, selected: null }));
        if (cnt++ > 0) setVillage(data => ({ ...data, options: [], selected: null }));
      }
    }
  }

  const handleStateChange = (selected: Option | null) => {
    const value = selected?.value as string;
    setState(data => ({ ...data, selected }));

    if (!value) return;
    getDistrictOptions(value);
  }

  const handleDistrictChange = (selected: Option | null) => {
    const value = selected?.value as string;
    setDistrict(data => ({ ...data, selected }));

    if (!value || !state?.selected?.value) return;
    getSubDistrictOptions(state.selected.value as string, value);
  }

  const handleSubDistrictChange = (selected: Option | null) => {
    const value = selected?.value as string;
    setSubDistrict(data => ({ ...data, selected }));

    if (!value || !state?.selected?.value) return;
    getVillageOptions(state.selected.value as string, district?.selected?.value as string, value);
  }

  const handleVillageChange = (selected: Option | null) => {
    const value = selected?.value as string;
    setVillage(data => ({ ...data, selected }));

    if (!value || !state?.selected?.value) return;
  }


  // default values handlers
  const handleDefaultState = (options: Option[]) => {
    const defaultState = defaultSelected.current?.state;
    if (defaultState) {
      const defaultSelected = options.filter(option => option.value === defaultState);
      if (defaultSelected.length !== 1) {
        console.warn(`Can't apply prefill for Crop ${defaultState} ${defaultSelected.length}`);
      } else {
        setState(data => ({
          ...data,
          selected: defaultSelected[0]
        }));

        handleDropdownChange("state", defaultSelected[0]);
      }
    }
  }

  const generatePayload = () => {
    const updateValue: any = {};

    if(!!state?.selected) {
      updateValue.state = state.selected.value as string;
    }

    if(!!district?.selected) {
      updateValue.district = district.selected.value as string; 
    }

    if(!!subDistrict?.selected) {
      updateValue.subDistrict = subDistrict.selected.value as string; 
    }

    if(!!village?.selected) {
      updateValue.village = village.selected.value as string; 
    }

    return updateValue;
  }

  // action button handlers
  const onFilterSubmit = () => {
    if (!state?.selected) {
      alert("no state selected");
      return;
    }

    const payload = generatePayload();

    setIsApplied(true);
    if (!!onSubmit) {
      onSubmit(payload, filterId ?? "");
    }
  }

  const onFilterReset = () => {
    if (!!onReset) {
      onReset(filterId ?? "");
    }

    // reset isApplied and error states
    setIsApplied(false);
    setHasError("");
  }

  const onFilterDelete = () => {
    setIsApplied(false);
    if (!!onDelete) {
      onDelete(filterId ?? "");
    }
  }

  /**
   * background colors for the filter
   */
  let backgroundColor = "#D6D6D6";
  if (defaultBackgroundColor) {
    backgroundColor = defaultBackgroundColor;
  }

  if (isApplied && submittedBackgroundColor) {
    backgroundColor = submittedBackgroundColor;
  }

  if (isApplied && !!hasError && errorBackgroundColor) {
    backgroundColor = errorBackgroundColor;
  }


  return (
    <Grid width={"100%"}>
      {!hideTitle && <Header title='Location' />}

      <Grid container justifyContent={"space-between"} alignItems={"center"} borderRadius={2} p={1} gap={1} style={{ backgroundColor }} >
        <Grid display={"flex"} gap={2}>
          {renderDropdown({
            title: "State",
            dropdownState: state,
            dropdownType: "state",
            isDisabled: !!state?.isLoading,
            handleDropdownChange
          })}

          {showDistrictDropdown && renderDropdown({
            title: "District",
            dropdownState: district,
            dropdownType: "district",
            isDisabled: !state?.selected || !!district?.isLoading,
            handleDropdownChange
          })}

          {showSubDistrictDropdown && renderDropdown({
            title: "Sub District",
            dropdownState: subDistrict,
            dropdownType: "sub-district",
            isDisabled: !district?.selected || !!subDistrict?.isLoading,
            handleDropdownChange
          })}

          {showVillageDropdown && renderDropdown({
            title: "Area",
            dropdownState: village,
            dropdownType: "village",
            isDisabled: !subDistrict?.selected || !!village?.isLoading,
            handleDropdownChange
          })}
        </Grid>

        {/* action buttons */}
        <Grid item mt={1} display={"flex"} justifyContent={"flex-end"} gap={"5px"} >
          {submitButton({ show: hasSubmitButton, onClick: onFilterSubmit, actionButtonType })}
          {resetButton({ show: hasResetButton, onClick: onFilterReset, actionButtonType })}
          {deleteButton({ show: hasDeleteButton, onClick: onFilterDelete, actionButtonType })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LocationFilter;

/**
 * ================== TYPES ======================
 */

type Dropdown = "state" | "district" | "sub-district" | "village";

const renderDropdown = ({
  title,
  dropdownState,
  dropdownType,
  isDisabled,
  handleDropdownChange
}: RenderDropdownArgs<Dropdown>) => (
  <Grid {...DropDownContainerProps}>
    <Label title={title} />
    <Select
      isLoading={dropdownState?.isLoading}
      isDisabled={isDisabled}
      value={dropdownState?.selected}
      options={dropdownState?.options ?? []}
      onChange={
        (selected: SingleValue<Option>) => handleDropdownChange(dropdownType, selected)
      }
      {...DropdownProps}
    />
  </Grid>
)