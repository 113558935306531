import React, { useEffect, useState } from "react";
import Header from "./sections/Header";
import DataGrid from "./sections/DataGrid";
import { getObservationTypes } from "src/services/observationType.service";
import { Box, Modal, Typography } from "@mui/material";
import AddObservationType from "./components/AddObservationType";
import { Scale } from "./components/AddScales";

const ObservationType: React.FC = () => {
  const [observationTypes, setObservationTypes] = useState<TObservationType[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<TObservationType>();

  useEffect(() => {
    fetchObservationTypes();
  }, []);

  /**
   * API calls
   */

  const fetchObservationTypes = async () => {
    try {
      const response = await getObservationTypes();
      setObservationTypes(response);
    } catch (error) {
      console.error(error);
    }
  };


  /**
   * State handlers
   */
  const appendObservationType = (observationType: TObservationType) => {
    setObservationTypes(observationTypes => ([observationType, ...observationTypes]));
    handleModalClose();
  }

  const popLastObservationType = () => {
    if (!!observationTypes.length) {
      setObservationTypes(observationTypes => observationTypes.slice(0, observationTypes.length -1));
    }
    handleModalClose();
  }

  const updateObservationType = (observationType: TObservationType) => {
    const tempObservationTypes = observationTypes.map((obType) => {
      if (obType.id === selectedItem?.id) { 
        return observationType
      }
      return obType;
    });
    setObservationTypes(tempObservationTypes);
    handleModalClose();
  }

  const openAddObservationModal = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedItem({} as TObservationType);
  }

  const selectObservationTypeToEdit = (observationType: TObservationType, editType: "copy" | "edit" = "edit") => {
    if(editType === "copy") {
      delete observationType.id;
    }

    setSelectedItem(observationType);
    openAddObservationModal();
  }

  return (
    <div>
      <Header
        title="Observation Type"
        buttonTitle="Add Observation Type"
        onButtonClick={openAddObservationModal}
      />

      <DataGrid data={observationTypes} onEdit={selectObservationTypeToEdit} />

      <Modal
        open={openModal}
        onClose={handleModalClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxHeight: "100vh",
          minHeight: "100vh"
        }}
      >
        <Box bgcolor={"white"} p={3} minHeight={"85%"} overflow={"scroll"} maxHeight={"85%"} width={750}>
          <Typography variant="h5" mb={3} textAlign={"center"} >Observation Type Form</Typography>
          <AddObservationType 
            data={selectedItem}
            appendObservationType={appendObservationType} 
            popLastObservationType={popLastObservationType} 
            updateObservationType={updateObservationType}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default ObservationType;

/**
 * Types
 */


export type TObservationType = {
  id?: string;
  description: string;
  name: string;
  cropId: string;
  diseasePestId: string;
  columns: string[];
  subColumns?: string[];
  scale: Scale[];
  attachments?: string[];
  isDisease?: boolean;
  isPest?: boolean;
};