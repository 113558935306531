import { CORE_API } from '../constants/api';
import { deleteData, getData, postData } from './common.service'

export async function getAllFarmDataBetweenDates(payload: any) {
  const url = '/hourlyAverageFieldData/getAllFarmDataBetweenDates'
  return postData(url, {
    sendTokenInUrl: true,
    body: payload
  })
}

export async function getAllDailyFarmDataBetweenDates(plotId: any, fromDate: Date, toDate: Date) {
const qs ={
  where: {
    and: [{ timestamp: { lte: toDate } }, { timestamp: { gte: fromDate } }, {plotId: plotId}],
  },
}
  const url = '/daily-average-field-data?filter='+JSON.stringify(qs)
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export async function getIrrigationAnalyses(text: string) {
  const qs = {
    where: {
      and: [
        {
          area: { gt: 0 },
        },
        {
          or:
            [
              { plotId: { like: `.*${text}.*`, options: 'i' } },
              { farmId: { like: `.*${text}.*`, options: 'i' } },
              { name: { like: `.*${text}.*`, options: 'i' } },
              { place: { like: `.*${text}.*`, options: 'i' } },
              { farmUserId: { like: `.*${text}.*`, options: 'i' } }
            ]
        }
      ]
    },
    order: 'created_date DESC',
    // limit: 20
  };
  const url = '/irrigationanalyses?filter=' + JSON.stringify(qs)
  return getData(url, {
    sendTokenInUrl: true
  })

}

export async function getPlotActivities(plotId: string, fromDate: Date, toDate: Date) {
  const qs = {
    order: 'created_date DESC',
    where: {
      and: [{ created_date: { lte: toDate } }, { created_date: { gte: fromDate } }, { plotId: plotId }],
    },
  }

  const url = '/farm-activities?filter=' + JSON.stringify(qs)
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export async function getFarmActivitiesType() {
  const url = "/farm-activity-types?filter=" + JSON.stringify({
    order: ["created_date ASC"],
  })
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export async function getCulturalActivities(cropId: string) {
  const url = "/cultural-practices/?filter=" + JSON.stringify({
    order: ["fromDayOfSowing ASC"], where: {
      cropId: cropId,
    }
  })
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export async function addFarmActivites(payload: any) {
  const url = '/farm-activities';
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload
  })
}

export async function deleteFarmActivites(id: string) {
  const url = '/farm-activities/' + id;
  return deleteData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    useDeleteMethod: true
  })
}

export async function updateFarmActivites(id: string, payload: any) {
  const url = "/farm-activities/" + id
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    usePatchMethod: true,
    body: payload
  })
}