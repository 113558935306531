import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Grid, IconButton } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import { AgGridReact } from "ag-grid-react";
import {
  ArrowBack,
  KeyboardArrowLeftTwoTone,
  KeyboardArrowRightTwoTone,
} from "@mui/icons-material";
import { LeadColDef } from "../constants/columnDefs";
import {
  ColDef,
  FilterChangedEvent,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  SortChangedEvent,
} from "ag-grid-community";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import {
  getOpenLeads,
  getLeadsCount,
  getLeadsByPagination
} from "../services/businessDashboard.service";

import LeadDetails from "./LeadDetails";

import dayjs from "dayjs";
import { Bar } from "react-chartjs-2";
import { ChartColors } from "src/constants/interfaces";
const LeadPage = (): JSX.Element => {
  const state = useLocation();
  const navigate = useNavigate();
  const gridRef = useRef<AgGridReact>(null);
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "600px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [tableData, setTableData] = useState<any[]>([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [chartData, setChartData] = useState<any>();
  const [totalLeads, setTotalLeads] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [columnFilter, setColumnFilters] = useState<any>();

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // gridRef?.current?.api.setColumnDefs(farmActivitiesColDef);
    // gridRef?.current?.api.setRowData(tableData);
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  const [selectedRowId, setSelectedRowId] = useState("");
  const onCellClicked = (id: string) => {
    setShowEditModal(true);
    setSelectedRowId(id);
  };
  const gridOptions: GridOptions = {
    columnDefs: LeadColDef,
    rowData: tableData,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => onCellClicked(e.data.id),
  };

  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
  };
  const handleColumnFilter = (event: FilterChangedEvent) => {
    const filterModel = event.api.getFilterModel();
    const whereClause: any = { and: [] };

    if (Object.keys(filterModel).length > 0) {
      Object.entries(filterModel).forEach(([column, filter]) => {
        const { type, filterType, filter: filterValue } = filter;
        let filterCondition: any = {};
        switch (filterType) {
          case "text":
            filterCondition[column] = {
              like: `.*${filterValue}.*`,
              options: "i",
            };
            break;
          case "number":
            switch (type) {
              case "equals":
                filterCondition[column] = filterValue;
                break;
              case "notEqual":
                filterCondition[column] = { neq: filterValue };
                break;
              case "lessThan":
                filterCondition[column] = { lt: filterValue };
                break;
              case "lessThanOrEqual":
                filterCondition[column] = { lte: filterValue };
                break;
              case "greaterThan":
                filterCondition[column] = { gt: filterValue };
                break;
              case "greaterThanOrEqual":
                filterCondition[column] = { gte: filterValue };
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        whereClause.and.push(filterCondition);
      });
    }
    setColumnFilters(whereClause.and);
  };

  const onPaginationChanged = useCallback(() => {}, []);

  const generateChartData = (data: any) => {
    const chartData: {
      labels: string[];
      datasets: { label: string; data: number[]; backgroundColor: string }[];
    } = {
      labels: [],
      datasets: [{ label: "Count", data: [], backgroundColor: "#007bff" }],
    };

    const groupedData: { [key: string]: number } = {};

    data.forEach((item: any, index: number) => {
      const { servicedBy } = item;
      if (!groupedData[servicedBy]) {
        groupedData[servicedBy] = 0;
        chartData.labels.push(servicedBy);
      }
      groupedData[servicedBy]++;
      const colorIndex = index % ChartColors.length;
      chartData.datasets[0].backgroundColor = ChartColors[colorIndex];
    });

    chartData.labels.forEach((servicedBy) => {
      const count = groupedData[servicedBy] || 0;
      chartData.datasets[0].data.push(count);
    });

    chartData.labels.sort((a, b) => groupedData[b] - groupedData[a]);
    chartData.datasets[0].data = chartData.labels.map(label => groupedData[label] || 0);

    return chartData;
  };

  const generateBarChartOptions = () => ({
    plugins: {
      title: {
        display: true,
        text: "Open Leads",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  });

  useEffect(() => {
    setSkip(0);
    getLeadsByPagination(0, columnFilter).then((res: any) => {
      const updatedRes = res.map((item: any) => ({
        ...item,
        date: dayjs(item.date).format("LL"),
      }));

      setTableData(updatedRes);
    });

    getLeadsCount(columnFilter).then((res: any) => {
      let cnt = Math.round(res.count / 15);

      if (res.count % 15 !== 0) cnt += 1;
      setTotalLeads(cnt);
    });
  }, [columnFilter]);

  useEffect(() => {
    getLeadsByPagination(skip, columnFilter).then((res: any) => {
      const updatedRes = res.map((item: any) => ({
        ...item,
        date: dayjs(item.date).format("LL"),
      }));

      setTableData(updatedRes);
    });

    getLeadsCount(columnFilter).then((res: any) => {
      let cnt = Math.round(res.count / 15);

      if (res.count % 15 !== 0) cnt += 1;
      setTotalLeads(cnt);
    });
  }, [skip]);

  const onBtNext = () => {
    setSkip(skip + 15);
  };

  const onBtPrevious = () => {
    setSkip(skip - 15);
  };

  const PaginationIconProps = {
    fontSize: "small",
    color: "action",
  };

  useEffect(() => {
    callLeadsApi();
    getOpenLeads().then((res) => setChartData(generateChartData(res)));
  }, []);

  const callLeadsApi = (): void => {
    
    getLeadsByPagination(skip, columnFilter).then((res: any) => {
      const updatedRes = res.map((item: any) => ({
        ...item,
        date: dayjs(item.date).format("LL"),
      }));

      setTableData(updatedRes);
    });
  };
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("md");

  const onBtExport = () => {
    gridRef.current!.api.exportDataAsCsv();
  };

  return (
    <div style={containerStyle}>
      <Grid
        p={2}
        borderRadius={20}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Grid display={"flex"} item alignItems={"center"} margin={1}>
          <IconButton onClick={() => navigate("/")}>
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Leads
          </Typography>
        </Grid>

        <div
          style={{
            textDecoration: "none",
            color: "black",
            fontWeight: "bold",
            fontSize: 18,
            marginRight: 50,
            alignSelf: "center",
            display: "flex",
          }}
        >
          <input
            type="button"
            value="Add Leads"
            onClick={() => setShowAddModal(true)}
            style={{
              width: "100%",
              padding: "10px",
              fontSize: 18,
              paddingTop: "10px",
              alignSelf: "center",
              marginRight: "10px",
            }}
          ></input>
          <input
            type="button"
            value="Export Leads as CSV"
            onClick={() => onBtExport()}
            style={{
              width: "100%",
              padding: "10px",
              fontSize: 18,
              paddingTop: "10px",
              alignSelf: "center",
            }}
          ></input>
        </div>
      </Grid>

      <div>
        <div style={{ width: "90%", margin: "auto" }}>
          {!!chartData && (
            <Bar data={chartData} options={generateBarChartOptions()} />
          )}
        </div>
      </div>

      <div style={gridStyle} className="ag-theme-alpine">
          <Grid p={3}>
            <AgGridReact
              ref={gridRef}
              rowData={tableData}
              columnDefs={LeadColDef}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              animateRows={true}
              gridOptions={gridOptions}
              domLayout="autoHeight"
              onPaginationChanged={onPaginationChanged}
              onSortChanged={handleColumnSort}
              onFilterChanged={handleColumnFilter}
            ></AgGridReact>
            <Grid
              container
              py={2}
              justifyContent={"flex-end"}
              alignItems={"center"}
              border={1}
              borderColor={"silver"}
              bottom={0}
              left={0}
              right={0}
            >
              <Button onClick={onBtPrevious} disabled={skip === 0}>
                <KeyboardArrowLeftTwoTone {...(PaginationIconProps as any)} />
              </Button>

              <Typography>
                Page {Math.round(skip / 15) + 1} of {totalLeads}
              </Typography>

              <Button
                onClick={onBtNext}
                disabled={skip === (totalLeads - 1) * 15}
                id="btNext"
              >
                <KeyboardArrowRightTwoTone {...(PaginationIconProps as any)} />
              </Button>
            </Grid>
          </Grid>
\      </div>

      <Dialog
        onClose={() => setShowAddModal(false)}
        open={showAddModal}
        maxWidth={maxWidth}
        fullWidth={true}
      >
        <LeadDetails updateData={callLeadsApi} showAddModal={setShowAddModal} />
      </Dialog>
      <Dialog
        onClose={() => setShowEditModal(false)}
        open={showEditModal}
        maxWidth={maxWidth}
        fullWidth={true}
      >
        <LeadDetails
          selectedId={selectedRowId}
          edit={true}
          updateData={callLeadsApi}
          showAddModal={setShowEditModal}
        />
      </Dialog>
    </div>
  );
};

export default LeadPage;
