import { FC } from 'react'
import { Box } from '@mui/material';
import { KeyValues } from 'src/constants/interfaces';
import FilterMap from 'src/components/Map';
interface Props{
  mapLoaded: boolean;
  mapLocations: KeyValues[];
  height?: string | number;
  polygonRegion?: any;
  handlePolygonClick?: any;
}

const PlotMap: FC<Props> = (props: Props): JSX.Element => {
  const { mapLoaded, mapLocations, height } = props;

  return(
    <Box alignItems={'center'} justifyContent={'center'} >
      <FilterMap 
        mapLoaded={mapLoaded} 
        mapLocations={mapLocations} 
        height={height} 
        polygonRegion={{}}
        plotsMap
      />
    </Box>
  )
}

export default PlotMap