import { CORE_API } from "src/constants/api";
import { deleteData, getAccessToken, getData, postData } from "./common.service";

export const getImageAnalysis = (skip:number, payload: Record<string, any> = {}) => {

  const qs: any = {
    ...payload,
    order: ["created_date DESC"],
    limit: 30,
    skip: skip,
  };
  let url = '/image-analyses';

  if(Object.keys(payload).length > 0) {
    url += `?filter=${JSON.stringify(qs)}`
  }

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true
  })
}

export const updateImageAnalysis = (id: string, payload: Record<string, any>) => {
  let url = '/image-analyses/' + id;

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    body: payload,
    usePatchMethod: true
  })
}

export const deleteImageAnalysis = (id: string) => {
  let url = '/image-analyses/' + id;

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true
  })
}

export const generateImageAnalysis = async (fileBlob: any) => {
  const url = `${CORE_API}/image-analyses/fyllo-dashboard`;
  return fetch(url, {
    method: "POST",
    headers: new Headers({
      Authorization: `Bearer ${getAccessToken()}`,
    }),
    body: fileBlob
  }).then(res => res.json())
}

export const getImageAnalysisCount = async () => {
  const url = `${CORE_API}/image-analyses/count`;
  return fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: `Bearer ${getAccessToken()}`,
    }),
  }).then(res => res.json())
}