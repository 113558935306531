import { ArrowBack } from "@mui/icons-material"
import { Grid, IconButton, Modal, Skeleton, Typography } from "@mui/material"
import { getDate } from "../../utils/helper"
import PlotCard from "./PlotCard";


interface ModalProps {
  open: boolean,
  handleClose: any,
  otherPlots: any[],
  isReady?: boolean;
}
const OtherPlots: React.FC<ModalProps> = ({ open, handleClose, otherPlots, isReady }): JSX.Element => (
  <Modal
    open={open}
    onClose={handleClose}
    style={{ overflow: 'scroll', height: '100%' }}
  >
    <Grid width={'75%'} my={2} mx={'auto'} bgcolor={'white'} boxShadow={2} borderRadius={4} >
      <Grid p={2} borderRadius={20}  >
        <Grid container alignItems={'center'}>
          <IconButton onClick={handleClose} ><ArrowBack /></IconButton>
          <Typography m={1} variant='h5'>View Other Plots</Typography>
        </Grid>
        {
          isReady ?
            <Grid px={2} py={1} container flexWrap={'wrap'} justifyContent={'space-evenly'} >
              {
                otherPlots.map((plot) => {
                  const { plotId, area, name, cropsSown } = plot
                  const plotDetails = {
                    plotName: name,
                    area: area,
                    cropName: cropsSown?.name,
                    sowingDate: getDate(cropsSown?.sowingDate),
                    stage: cropsSown?.activeStage
                  }
                  return (
                    <PlotCard key={'plot_card_' + plotId} plotId={plotId} plotDetails={plotDetails} handleClose={handleClose} />
                  )
                })
              }
            </Grid> :
            <Grid px={4} py={2} container flexWrap={'wrap'} justifyContent={'space-evenly'} >
              <Skeleton animation="wave" variant='rectangular' style={{ borderRadius: '15px' }} height={200} width={400} />
              <Skeleton animation="wave" variant='rectangular' style={{ borderRadius: '15px' }} height={200} width={400} />
            </Grid>
        }
      </Grid>
    </Grid>
  </Modal>
)

export default OtherPlots;