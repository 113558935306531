import { Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import Select, { SingleValue } from "react-select";
import { getCropOptions, getCrops, getCropStages, getCropVarieties } from "src/services/crop.service"
import { generateOptions, sortByKey } from "src/utils/helper";
import { DropDownProps, DiseaseGridProps } from "./CropFilterBusinessDashboard";
import { KeyValues } from "src/constants/interfaces";
import { Collapsibles } from "../sections/FilterSection";
import dayjs from "dayjs";


const CropVariety: React.FC<any> = (props) => {
  const { setCropData, isVisible, closeRestCollapsiblesExcept, handleFilterUpdate, setMapsDate, setIndexData, setGraphType, setGraphSubType } = props;

  const [crops, setCrops] = useState<SingleValue<any>>([])
  const [stages, setStages] = useState<SingleValue<any>>([])
  const [varieties, setVarieties] = useState<SingleValue<any>>([])

  // selected options
  const [selectedCropOption, setSelectedCropOption] = useState<any>(null)
  const [selectedStageOption, setSelectedStageOption] = useState<any>(null)
  const [selectedVarietyOption, setSelectedVarietyOption] = useState<any>(null)

  // loading states
  const [isCropLoading, setIsCropLoading] = useState<boolean>(true)
  const [isStageLoading, setIsStageLoading] = useState<boolean>(false)
  const [isVarietyLoading, setIsVarietyLoading] = useState<boolean>(false)

  useEffect(() => {
    getCropOptions()
      .then(res => {
        setCrops(generateOptions(res, 'cropId', 'cropName'))
        setIsCropLoading(false)
      })
  }, [])

  const handleCropChange = (selected: SingleValue<any>) => {
    setSelectedCropOption(selected)
    const { value } = selected;

    setIsStageLoading(true)
    setIsVarietyLoading(true)

    unselectOptions(['stage', 'variety'])

    getCropStages(value)
      .then((res: any[]) => {
        const sortedStages = sortByKey(res, 'order')
        setStages(generateOptions(sortedStages, 'stageId', 'name'))
        setIsStageLoading(false)
      })

    getCropVarieties(value)
      .then(res => {
        setVarieties(generateOptions(res, 'id', 'name'))
        setIsVarietyLoading(false)
      })

  }

  const handleStageChange = (selected: SingleValue<any>) => {
    setSelectedStageOption(selected)
  }

  const handleVarietyChange = (selected: SingleValue<any>) => {
    setSelectedVarietyOption(selected)
  }

  const handleDropdownChange = (dropdown: string, selected: SingleValue<any>) => {
    const value = selected?.value;

    switch (dropdown) {
      case 'crop':
        if (value) handleCropChange(selected)
        else unselectOptions(['crop', 'variety', 'stage']);
        break;
      case 'stage':
        if (value) handleStageChange(selected)
        else unselectOptions(['stage'])
        break;
      case 'variety':
        if (value) handleVarietyChange(selected)
        else unselectOptions(['variety'])
        break;
      default:
        break;
    }
  }

  const deviceTypeRanges = [
    {
      name: 'Nero Unit',
      color: 'green'
    },
    {
      name: 'Irrigation Unit',
      color: 'grey'
    },
    {
      name: 'Master Unit',
      color: 'yellow'
    },
    {
      name: 'Kairo Unit',
      color: 'turquoise'
    },
    {
      name: 'Weather Unit',
      color: 'red'
    },
  ];

  const getColor = (deviceType: any): string => {
    if (deviceType === 'WEATHER_UNIT') return 'red';
    if (deviceType === 'MASTER_UNIT') return 'yellow';
    if (deviceType === 'IRRIGATION_UNIT') return 'grey';
    if (deviceType === 'NERO_UNIT') return 'green';
    return 'blue';
  }
  const unselectOptions = (toUnselect: string[]) => {
    let cnt = 0;
    for (const field of toUnselect) {
      if (field === 'crop') {
        setSelectedCropOption(null)
        cnt++;
      } else if (field === 'stage') {
        setSelectedStageOption(null)
        if (cnt++ > 0) setStages([])
      } else {
        setSelectedVarietyOption(null)
        if (cnt++ > 0) setVarieties([])
      }
    }
  }

  const showResults = () => {
    if (!selectedCropOption) {
      alert('select crop')
      return
    }

    let payload: any = {
      cropId: selectedCropOption.value
    }

    if (selectedStageOption) {
      payload.stageId = selectedStageOption.value
    }

    if (selectedVarietyOption) {
      payload.varietyId = selectedVarietyOption.value
    }

    getCrops(payload)
      .then((res: KeyValues[]) => {
        setMapsDate(dayjs(new Date()).format("DD/MM/YYYY").toString())
        setGraphType('crop')
        setGraphSubType('airTemp')
        const plots: any[] = res.map((plots) => {
          const { plotId, location, plotArea, deviceType } = plots
          return {
            plotId,
            location,
            color: getColor(plots.deviceType),
            plotArea,
            deviceType,
            value: plotArea
          }
        });

        setIndexData(deviceTypeRanges)
        setCropData(plots)
        handleFilterUpdate('crop')
      })
  }

  return (
    <Grid mb={3} boxShadow={2} borderRadius={3} px={2}>

      <Grid data-testid="dashboard-crop" display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
        <Typography letterSpacing={0.8} variant="overline" textTransform={'capitalize'} fontSize={20}>Crop</Typography>
        <IconButton onClick={() => closeRestCollapsiblesExcept(Collapsibles.variety)}>
          {isVisible ? (<KeyboardArrowUp />) : (<KeyboardArrowDown />)}
        </IconButton>
      </Grid>

      {
        isVisible && (
          <Grid pb={2}>
            <Grid {...DiseaseGridProps}>
              <Typography variant="subtitle1" mr={3} >Crop</Typography>
              <Select
                options={crops}
                onChange={(selected: SingleValue<any>) => handleDropdownChange('crop', selected)}
                isLoading={isCropLoading}
                isDisabled={isCropLoading}
                value={selectedCropOption}
                {...DropDownProps}
              />
            </Grid>

            <Grid {...DiseaseGridProps}>
              <Typography variant="subtitle1" mr={1}>Variety</Typography>
              <Select
                options={varieties}
                onChange={(selected: SingleValue<any>) => handleDropdownChange('variety', selected)}
                isLoading={isVarietyLoading}
                isDisabled={!selectedCropOption || isVarietyLoading}
                value={selectedVarietyOption}
                {...DropDownProps}
              />
            </Grid>

            <Grid {...DiseaseGridProps}>
              <Typography variant="subtitle1" mr={2}>Stage</Typography>
              <Select
                options={stages}
                onChange={(selected: SingleValue<any>) => handleDropdownChange('stage', selected)}
                isLoading={isStageLoading}
                isDisabled={!selectedCropOption || isStageLoading}
                value={selectedStageOption}
                {...DropDownProps}
              />
            </Grid>

            <Grid mt={2}>
              <Button disabled={!selectedCropOption} variant="outlined" fullWidth style={{ borderRadius: '20px' }} size="large" color="success" onClick={showResults}> Show Results </Button>
            </Grid>
          </Grid>
        )
      }

    </Grid>
  )
}

export default CropVariety;