import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  SortChangedEvent,
  FilterChangedEvent,
} from 'ag-grid-community';

import { farmActivitiesColDef } from 'src/constants/columnDefs';
import { isArrayWithLength } from 'src/utils/helper';
import { Modal, Typography } from '@mui/material';
import { Grid, IconButton } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { TextArea } from 'src/components/DynamicForm/fields/_fieldStyles'
import { ReAnalzeActivity } from 'src/services/plot.service'

interface Props {
  tableData: any;
  fromDate: string;
  toDate: string;
  setshowFarmActivity: any;
  showFarmActivity: Boolean
}

const ActivitiesTable = (props: Props): JSX.Element => {
  const { tableData, fromDate, toDate, setshowFarmActivity, showFarmActivity } = props;
  const gridRef = useRef<AgGridReact>(null);
  const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [toShow, settoShow] = useState<any>({})

  const getChemicalScreen = (val: any) => {
    setshowFarmActivity(false);
    settoShow(val);
  }

  const gridOptions: GridOptions = {
    columnDefs: farmActivitiesColDef,
    rowData: tableData,
    rowSelection: 'single',
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => getChemicalScreen(e.data)
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ['filterMenuTab'],
    };
  }, [tableData]);

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return {
      toolPanels: ['filters'],
    };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // gridRef?.current?.api.setColumnDefs(farmActivitiesColDef);
    // gridRef?.current?.api.setRowData(tableData);
  }, []);

  // function to perform server side sorting
  const handleColumnSort = (event: SortChangedEvent) => {
    // const columns = event.columnApi.getColumnState();
    // let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId
  }

  // function to perform server side filtering
  const handleColumnFilter = (event: FilterChangedEvent) => {
    if (event.afterFloatingFilter) { }
  }

  const reanalyze = () => {
    if (toShow.id) {
      if (window.confirm("Do you want to continue?")) {
        ReAnalzeActivity(toShow.id)
          .then((result: any) => {
            if (result.id) {
              settoShow(result);
            }
            else {
              alert("Some Error Occured");
            }
          })
      }
    }
  }
  const onPaginationChanged = useCallback(() => { }, []);

  useEffect(() => {
    if (fromDate.length > 0 && toDate.length > 0) {
    }
  }, [tableData, fromDate, toDate])

  return (
    <div style={containerStyle}  >
      <div style={gridStyle} className="ag-theme-alpine">
        {
          isArrayWithLength(tableData) &&
          <>
            <Typography textAlign={'center'} variant={'h6'} my={2} > Tabular data for above charts </Typography>
            {/* <Typography textAlign={'center'} variant={'h6'} my={2} > Displaying data for Month Range {getDate(fromDate, 'MM/YYYY')} - {getDate(toDate, 'MM/YYYY')} </Typography> */}
            <AgGridReact
              ref={gridRef}
              rowData={tableData}
              columnDefs={farmActivitiesColDef}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              animateRows={true}
              pagination={true}
              paginationPageSize={10}
              gridOptions={gridOptions}
              domLayout="autoHeight"
              onPaginationChanged={onPaginationChanged}
              onSortChanged={handleColumnSort}
              onFilterChanged={handleColumnFilter}
            ></AgGridReact>
          </>
        }
      </div>

      <Modal
        open={!showFarmActivity}
        style={{ overflow: 'scroll' }}
      >
        <Grid width={'90%'} my={2} mx={'auto'} boxShadow={2} borderRadius={4} paddingBottom={5} style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }} >
          <Grid p={2} borderRadius={20}  >
            <Grid display={'flex'} item alignItems={'center'}>
              <IconButton onClick={() => setshowFarmActivity(true)} ><ArrowBack /></IconButton>
              <Typography m={1} variant='h5'>Details</Typography>
            </Grid>

          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: 30,
            }}
          >
            <form>
              <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }} >Plot Id</label>
              <input value={toShow?.plotId} type="text" style={{ width: '93%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 15, marginTop: 10 }} ></input><br />

              <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }} >Plot Name</label>
              <input value={toShow?.plot?.name} type="text" style={{ width: '93%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 15, marginTop: 10 }} ></input><br />

              <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }} >Activity</label>
              <input value={toShow?.farmActivityTypeId} type="text" style={{ width: '93%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 15, marginTop: 10 }} ></input><br />

              <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }} >Notes</label><br />
              <TextArea value={toShow?.notes} type="text" style={{ width: '700px', height: '120px', padding: '18px', resize: 'none', fontSize: 18, margin: '3px', marginBottom: 11 }} ></TextArea><br />

              <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }} >Processed Notes</label><br />
              <TextArea name="chemicals" value={toShow?.processedNotes} style={{ width: '700px', height: '120px', padding: '18px', resize: 'none', fontSize: 18, margin: '3px', marginBottom: 11 }} ></TextArea><br />

              {toShow?.analysisResult && !Array.isArray(toShow?.analysisResult) && Object.keys(toShow?.analysisResult)?.map((val) => (
                <>
                  <h3 style={{ margin: 5 }}>{val}</h3>
                  {val === 'CHEM' ? (
                    <Grid flexDirection={"row"}>
                      {toShow?.analysisResult?.[val]?.map((key: any) => (
                        <a target={'_blank'} href={`fertilizer/fertilizer-details/${key.chemicalId}`} style={{ textDecoration: 'none', color: 'blue', fontWeight: 'bold', fontSize: 18, marginRight: 15, alignSelf: 'center' }} rel="noreferrer">
                          {key.chemicalId || key.chemicalName} {key?.quantity}
                        </a>
                      ))}
                    </Grid>
                  )
                    : (
                      <TextArea name="chemicals" value={toShow?.analysisResult?.[val]} style={{ width: '700px', height: '120px', padding: '18px', resize: 'none', fontSize: 18, margin: '3px', marginBottom: 11 }} ></TextArea>
                    )
                  }
                  <br />
                </>
              ))}

              {toShow?.analysisResult && Array.isArray(toShow?.analysisResult) && (toShow?.analysisResult)?.map((val: any) => (
                <>
                  {Object.keys(val)?.map((key) => (
                    <>
                      <h3 style={{ margin: 5 }}>{key}</h3>
                      {key === 'CHEM' ? (
                        <Grid>
                          {Object.values([val])?.map((key: any) => (
                            <a target={'_blank'} href={`fertilizer/fertilizer-details/${key.chemicalId}`} style={{ textDecoration: 'none', color: 'blue', fontWeight: 'bold', fontSize: 18, marginRight: 15, alignSelf: 'center' }} rel="noreferrer">
                              {key.chemicalId || key.chemicalName} {key?.quantity}
                            </a>
                          ))}
                        </Grid>
                      ) : (
                        <TextArea name="chemicals" value={Object.values(val)} style={{ width: '700px', height: '120px', padding: '18px', resize: 'none', fontSize: 18, margin: '3px', marginBottom: 11 }} ></TextArea>
                      )}
                      <br />
                    </>

                  ))}
                </>
              ))}
              <input type="button" value="Re Analyze" onClick={reanalyze} style={{ width: '99%', padding: '20px', fontSize: 18, paddingTop: '15px', marginTop: 20 }} />
            </form>
          </div>
        </Grid>
      </Modal>

    </div>
  )
}

export default ActivitiesTable
