import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

interface Props {
    title?: any;
    links?: any;
}

const NavbarLinks: React.FC<Props> = (props) => {

    const navigate = useNavigate();

    const { title, links } = props;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div style={{ margin: '0px 7px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '1px', marginBottom: '-5px' }}>
                    <Button
                        style={{ color: '#565656', fontSize: '16px', fontWeight: '400', textTransform: 'capitalize' }}
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        {title}
                    </Button>
                    <ArrowDropDownIcon style={{ marginTop: '6px', marginLeft: '-12px', color: '#565656' }} />
                </div>
                <Menu
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                >
                    {links.map((link: any, i: number) => {
                        return (
                            <MenuItem key={`navbar-link-${link.link}-${i}`} onClick={() => { handleClose(); navigate(`/${link['link']}`) }}>
                                <Typography>{link['label']}</Typography>
                            </MenuItem>
                        )
                    })}
                </Menu>
            </div>
        </>
    );
}
export default NavbarLinks
