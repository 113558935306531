const Maharashtra = {
  "geometry": {
    "coordinates": [
      [
        [
          72.73502487924591,
          20.132618539399857
        ],
        [
          72.67830212091557,
          19.772312738719947
        ],
        [
          72.81749990041982,
          19.214393143590517
        ],
        [
          72.98394086638153,
          18.137564793901255
        ],
        [
          73.23003861203338,
          17.324073848447608
        ],
        [
          73.32402154532274,
          16.6955718880195
        ],
        [
          73.46789947708865,
          16.09320113043151
        ],
        [
          73.70866387397416,
          15.723337939386354
        ],
        [
          73.86661675425549,
          15.782314772029544
        ],
        [
          74.02256197711762,
          15.633522044072226
        ],
        [
          74.21948634366407,
          15.788074715869877
        ],
        [
          74.35993149933012,
          15.831755411412761
        ],
        [
          74.45814272542535,
          16.03529788326044
        ],
        [
          74.5142455005394,
          16.22444248021126
        ],
        [
          74.33244476542264,
          16.265740266840737
        ],
        [
          74.26454007999507,
          16.540483438091826
        ],
        [
          74.56255475720538,
          16.592428091579652
        ],
        [
          74.66878276703602,
          16.69578271479424
        ],
        [
          74.9026768222602,
          16.787084212870194
        ],
        [
          74.93179039526899,
          16.959404277202246
        ],
        [
          75.09054712278426,
          16.96288962445944
        ],
        [
          75.20700141482234,
          16.865949297980997
        ],
        [
          75.30178477229448,
          16.957116326577285
        ],
        [
          75.38742907690371,
          16.969600511388684
        ],
        [
          75.65924726761689,
          16.97708604036914
        ],
        [
          75.63791115246818,
          17.2651163160357
        ],
        [
          75.64069463401546,
          17.474268908848863
        ],
        [
          75.8749106959338,
          17.376760304249178
        ],
        [
          76.079216545501,
          17.34649507556368
        ],
        [
          76.23950134935762,
          17.377601478732686
        ],
        [
          76.38040886019088,
          17.334727318826182
        ],
        [
          76.34586288408113,
          17.600634922355237
        ],
        [
          76.47426514818734,
          17.72601651409942
        ],
        [
          76.62204470532009,
          17.759113092556966
        ],
        [
          76.72123877114464,
          17.756928997180616
        ],
        [
          76.74732041406168,
          17.915781289896124
        ],
        [
          76.90915233425642,
          18.015881855017533
        ],
        [
          76.92208837729277,
          18.15510412594528
        ],
        [
          77.00279478054576,
          18.198944769475634
        ],
        [
          77.10023421700853,
          18.165064333438764
        ],
        [
          77.23113971989092,
          18.357721458706216
        ],
        [
          77.29189156266617,
          18.456594473524195
        ],
        [
          77.37683903676208,
          18.43754431917118
        ],
        [
          77.36395292769,
          18.312648262643204
        ],
        [
          77.47526650249225,
          18.262309464943883
        ],
        [
          77.56080785914747,
          18.300471833060964
        ],
        [
          77.61056427894516,
          18.505873604895406
        ],
        [
          77.72886359521885,
          18.55524405935637
        ],
        [
          77.8466545405429,
          18.810016285651777
        ],
        [
          77.95428969110449,
          18.843409993123856
        ],
        [
          77.84055408914884,
          18.91894434101888
        ],
        [
          77.75930773104972,
          19.05291306670773
        ],
        [
          77.85158693310166,
          19.168223324452065
        ],
        [
          77.88573425608365,
          19.39333373602581
        ],
        [
          78.03249387727249,
          19.240095400518292
        ],
        [
          78.19863079148763,
          19.178318983224585
        ],
        [
          78.20666126824497,
          19.44285642292698
        ],
        [
          78.3075629717449,
          19.465615577173622
        ],
        [
          78.251438711862,
          19.641379861023125
        ],
        [
          78.3687062796,
          19.729298388401546
        ],
        [
          78.30138765881279,
          19.837093337740917
        ],
        [
          78.3503327961717,
          19.94488828708029
        ],
        [
          78.49186741988433,
          19.84430323270965
        ],
        [
          78.67889848988526,
          19.80314209924893
        ],
        [
          78.82085725744552,
          19.773444754965553
        ],
        [
          78.8756182063994,
          19.65255515305458
        ],
        [
          78.99526034128161,
          19.58190074803546
        ],
        [
          79.11321328779425,
          19.546571994938784
        ],
        [
          79.20125570058703,
          19.46497298392734
        ],
        [
          79.26992082035503,
          19.58427769894125
        ],
        [
          79.39027452990922,
          19.555795062686904
        ],
        [
          79.50858326375774,
          19.523278211698024
        ],
        [
          79.65595793714436,
          19.582083745445004
        ],
        [
          79.76520868232436,
          19.599345869896126
        ],
        [
          79.88765458050058,
          19.53494919889164
        ],
        [
          79.97134589262714,
          19.39749999174046
        ],
        [
          79.94410060641371,
          19.257961638927796
        ],
        [
          79.93047796330546,
          19.133329470951118
        ],
        [
          79.86553476267986,
          19.089354937331326
        ],
        [
          79.93963827399993,
          19.030746780224725
        ],
        [
          79.95560990624699,
          18.926361782946714
        ],
        [
          79.94520177709515,
          18.810842618300377
        ],
        [
          80.05626147066532,
          18.753055027837274
        ],
        [
          80.15054590349865,
          18.687452489304533
        ],
        [
          80.24613135247517,
          18.728044756199665
        ],
        [
          80.31265086191513,
          18.84203057257419
        ],
        [
          80.28636648777808,
          19.05356109058428
        ],
        [
          80.56216268152787,
          19.373178016224827
        ],
        [
          80.8145021431742,
          19.3350853256256
        ],
        [
          80.83468930010497,
          19.430300354081936
        ],
        [
          80.91543792783142,
          19.50643222420709
        ],
        [
          80.63281773078893,
          19.73461245357494
        ],
        [
          80.45113331840366,
          19.84858069272495
        ],
        [
          80.54197552459715,
          19.924514112848243
        ],
        [
          80.55206910306254,
          20.11418789757451
        ],
        [
          80.40066542607514,
          20.199466275882386
        ],
        [
          80.61263057385816,
          20.33202870070835
        ],
        [
          80.59244341692573,
          20.587363608294368
        ],
        [
          80.58234983846035,
          20.71015072125624
        ],
        [
          80.54197552459715,
          20.936573108771356
        ],
        [
          80.45113331840366,
          21.087331652489226
        ],
        [
          80.50160121073395,
          21.181478199493426
        ],
        [
          80.64291130925432,
          21.313182671715566
        ],
        [
          80.42085258300744,
          21.3883894237658
        ],
        [
          80.40066542607514,
          21.491735587502262
        ],
        [
          80.18870027829365,
          21.613777379980434
        ],
        [
          79.89598650278583,
          21.538686887190266
        ],
        [
          79.75467640426376,
          21.59500840608176
        ],
        [
          79.54271125648228,
          21.56685038201681
        ],
        [
          79.50233694261914,
          21.68882890163887
        ],
        [
          79.22981032404203,
          21.707585680094155
        ],
        [
          79.15915527478097,
          21.641926273366025
        ],
        [
          78.9068158131364,
          21.58562300627696
        ],
        [
          78.95728370546499,
          21.501127060610273
        ],
        [
          78.69485066535498,
          21.454163635610684
        ],
        [
          78.41223046831249,
          21.60439319738755
        ],
        [
          78.22045247746183,
          21.491735587502262
        ],
        [
          77.71577355417094,
          21.37899069084135
        ],
        [
          77.50380840638951,
          21.369591354321344
        ],
        [
          77.45334051406087,
          21.538686887190266
        ],
        [
          77.60474419104673,
          21.5480753263073
        ],
        [
          77.45334051406087,
          21.80133287603674
        ],
        [
          77.16062673855146,
          21.726340014380085
        ],
        [
          76.83763222764577,
          21.59500840608176
        ],
        [
          76.77707075685174,
          21.482343508234393
        ],
        [
          76.62566707986434,
          21.350790871354874
        ],
        [
          76.66604139372748,
          21.247345150018887
        ],
        [
          76.52473129520547,
          21.200300326849117
        ],
        [
          76.4237955105483,
          21.06849517315112
        ],
        [
          76.15126889197126,
          21.13441240098649
        ],
        [
          76.18154962736907,
          21.219120056159696
        ],
        [
          76.0705202642448,
          21.369591354321344
        ],
        [
          75.77780648873693,
          21.37899069084135
        ],
        [
          75.39425050703568,
          21.37899069084135
        ],
        [
          75.16209820232183,
          21.416581998831518
        ],
        [
          75.0308816822668,
          21.557463157986575
        ],
        [
          74.87947800527934,
          21.632543918181838
        ],
        [
          74.57667065130613,
          21.68882890163887
        ],
        [
          74.50601560204515,
          21.904383964440697
        ],
        [
          74.4252669743187,
          22.007360581749438
        ],
        [
          74.14264677727624,
          21.960562278198935
        ],
        [
          73.81965226637058,
          21.820074964141938
        ],
        [
          73.78937153097274,
          21.651308018988928
        ],
        [
          73.82974584483594,
          21.5480753263073
        ],
        [
          73.97105594335798,
          21.56685038201681
        ],
        [
          74.32433118965989,
          21.519908187497066
        ],
        [
          73.95086878642562,
          21.43537402697774
        ],
        [
          73.78937153097274,
          21.247345150018887
        ],
        [
          73.58749996165668,
          21.143826757551537
        ],
        [
          73.77927795250733,
          21.059076038647575
        ],
        [
          73.900400894097,
          20.96485193470329
        ],
        [
          73.92058805102778,
          20.74791139533923
        ],
        [
          73.82226740514812,
          20.60556665428105
        ],
        [
          73.6470796950928,
          20.55870798878611
        ],
        [
          73.5255208758717,
          20.66913751262591
        ],
        [
          73.45759094748365,
          20.71930624782715
        ],
        [
          73.39681153787308,
          20.639028312176265
        ],
        [
          73.47546724442725,
          20.582139120404037
        ],
        [
          73.46831672564971,
          20.508486321854832
        ],
        [
          73.4037890098594,
          20.378822064163444
        ],
        [
          73.42427756385689,
          20.282761252295728
        ],
        [
          73.41223978580834,
          20.234488875996036
        ],
        [
          73.41450093741685,
          20.20266154995393
        ],
        [
          73.38623654231827,
          20.192050994817023
        ],
        [
          73.38058366329767,
          20.21221043140548
        ],
        [
          73.36249445043526,
          20.211149473505742
        ],
        [
          73.3534498440041,
          20.214332325506874
        ],
        [
          73.33875235855228,
          20.1984174146438
        ],
        [
          73.30031278121754,
          20.209027536007895
        ],
        [
          73.27996241674666,
          20.19947845931584
        ],
        [
          73.27770126513954,
          20.153847014592074
        ],
        [
          73.2505674458445,
          20.123064784586077
        ],
        [
          73.22343362654942,
          20.138987384867477
        ],
        [
          73.21099729270617,
          20.11881848395143
        ],
        [
          73.21438902011826,
          20.08696755588383
        ],
        [
          73.18612462501972,
          20.076349140274914
        ],
        [
          73.18951635243175,
          20.05192405527808
        ],
        [
          73.17708001858841,
          20.05192405527808
        ],
        [
          73.13072641062604,
          20.083782106742802
        ],
        [
          73.1058537429395,
          20.08696755588383
        ],
        [
          73.05271668015442,
          20.101832128766603
        ],
        [
          73.05045552854591,
          20.068915821366417
        ],
        [
          73.02106055764378,
          20.096523514697495
        ],
        [
          72.99957961736789,
          20.11881848395143
        ],
        [
          72.97470694968138,
          20.11881848395143
        ],
        [
          72.96000946423104,
          20.132618539399857
        ],
        [
          72.97809867709341,
          20.1803785494551
        ],
        [
          72.96000946423104,
          20.184623176193284
        ],
        [
          72.96000946423104,
          20.209027536007895
        ],
        [
          72.91591700787708,
          20.22600222578791
        ],
        [
          72.88765261277851,
          20.220697834191412
        ],
        [
          72.85825764187496,
          20.20796655641037
        ],
        [
          72.81642633712954,
          20.181439716975433
        ],
        [
          72.8220792161487,
          20.14217171025247
        ],
        [
          72.79946770007072,
          20.151724296914026
        ],
        [
          72.79155366944306,
          20.12837249822975
        ],
        [
          72.73502487924591,
          20.132618539399857
        ]
      ]
    ],
  },
  children:
  {
    Pune: {
      "geometry": {
        "coordinates": [
          [
            [
              73.82191297682255,
              18.97416025113604
            ],
            [
              73.77803001460617,
              18.972116419562493
            ],
            [
              73.73457284636136,
              18.96600475758241
            ],
            [
              73.69196304015877,
              18.95588456646479
            ],
            [
              73.65061375805699,
              18.941854027292358
            ],
            [
              73.61092566690927,
              18.924049226883973
            ],
            [
              73.57328298391177,
              18.902642808914145
            ],
            [
              73.53804969879963,
              18.877842264796648
            ],
            [
              73.50556601211929,
              18.84988788269577
            ],
            [
              73.47614502602346,
              18.819050376565162
            ],
            [
              73.45006972062306,
              18.78562822034634
            ],
            [
              73.4275902451638,
              18.74994471535097
            ],
            [
              73.40892154925604,
              18.712344821374458
            ],
            [
              73.39424137515877,
              18.673191784222997
            ],
            [
              73.38368862777983,
              18.632863594069878
            ],
            [
              73.37736213468318,
              18.591749310384543
            ],
            [
              73.3753198040582,
              18.55024529010118
            ],
            [
              73.37757818436556,
              18.508751356221005
            ],
            [
              73.38411242528318,
              18.467666944185712
            ],
            [
              73.39485663567336,
              18.42738726313621
            ],
            [
              73.40970463061296,
              18.388299508599978
            ],
            [
              73.42851105609381,
              18.350779162254035
            ],
            [
              73.45109287682625,
              18.31518641321176
            ],
            [
              73.4772312096715,
              18.28186273380388
            ],
            [
              73.5066734825906,
              18.251127641090957
            ],
            [
              73.53913589662515,
              18.223275673379618
            ],
            [
              73.57430616631153,
              18.198573608840658
            ],
            [
              73.61184651206676,
              18.177257950965917
            ],
            [
              73.65139687646146,
              18.159532703073918
            ],
            [
              73.69257833490083,
              18.145567451402528
            ],
            [
              73.73499667006128,
              18.13549577353052
            ],
            [
              73.77824607846604,
              18.129413985968075
            ],
            [
              73.82191297682255,
              18.127380241769558
            ],
            [
              73.86557987517905,
              18.129413985968075
            ],
            [
              73.9088292835838,
              18.13549577353052
            ],
            [
              73.95124761874426,
              18.145567451402528
            ],
            [
              73.99242907718363,
              18.159532703073918
            ],
            [
              74.03197944157833,
              18.177257950965917
            ],
            [
              74.06951978733356,
              18.198573608840658
            ],
            [
              74.10469005701994,
              18.223275673379618
            ],
            [
              74.13715247105449,
              18.251127641090957
            ],
            [
              74.1665947439736,
              18.28186273380388
            ],
            [
              74.19273307681883,
              18.31518641321176
            ],
            [
              74.21531489755127,
              18.350779162254035
            ],
            [
              74.23412132303213,
              18.388299508599978
            ],
            [
              74.24896931797173,
              18.42738726313621
            ],
            [
              74.25971352836191,
              18.467666944185712
            ],
            [
              74.26624776927953,
              18.508751356221005
            ],
            [
              74.2685061495869,
              18.55024529010118
            ],
            [
              74.26646381896191,
              18.591749310384543
            ],
            [
              74.26013732586527,
              18.632863594069878
            ],
            [
              74.2495845784863,
              18.673191784222997
            ],
            [
              74.23490440438906,
              18.712344821374458
            ],
            [
              74.21623570848129,
              18.74994471535097
            ],
            [
              74.19375623302203,
              18.78562822034634
            ],
            [
              74.16768092762163,
              18.819050376565162
            ],
            [
              74.13825994152579,
              18.84988788269577
            ],
            [
              74.10577625484545,
              18.877842264796648
            ],
            [
              74.07054296973331,
              18.902642808914145
            ],
            [
              74.03290028673582,
              18.924049226883973
            ],
            [
              73.9932121955881,
              18.941854027292358
            ],
            [
              73.95186291348631,
              18.955884566464785
            ],
            [
              73.90925310728373,
              18.96600475758241
            ],
            [
              73.86579593903892,
              18.972116419562493
            ],
            [
              73.82191297682255,
              18.97416025113604
            ]
          ]
        ]
      }
    },
    Nashik: {
      "geometry": {
        "coordinates": [
          [
            [
              73.76461713430355,
              20.39784198381982
            ],
            [
              73.72540888826941,
              20.396032221936622
            ],
            [
              73.68658096125564,
              20.3906204920831
            ],
            [
              73.6485099026412,
              20.38165928755308
            ],
            [
              73.61156476314144,
              20.369235518737433
            ],
            [
              73.57610344644941,
              20.353469652038196
            ],
            [
              73.54246918044846,
              20.334514517375656
            ],
            [
              73.51098714523575,
              20.31255379709155
            ],
            [
              73.48196129303336,
              20.287800212395556
            ],
            [
              73.4556713924611,
              20.26049342662224
            ],
            [
              73.43237032665806,
              20.23089768742281
            ],
            [
              73.41228167143817,
              20.19929923257909
            ],
            [
              73.39559757611458,
              20.166003486369956
            ],
            [
              73.38247696590133,
              20.131332075325183
            ],
            [
              73.37304408096708,
              20.095619693755374
            ],
            [
              73.36738736333993,
              20.059210850644764
            ],
            [
              73.36555869900697,
              20.0224565303355
            ],
            [
              73.36757301876955,
              19.98571079992367
            ],
            [
              73.37340825775384,
              19.949327396438406
            ],
            [
              73.38300566997319,
              19.913656326699456
            ],
            [
              73.39627049102633,
              19.87904051226245
            ],
            [
              73.41307293891461,
              19.845812511082457
            ],
            [
              73.43324954008962,
              19.81429134647673
            ],
            [
              73.4566047652064,
              19.78477947266551
            ],
            [
              73.48291295666289,
              19.757559904638956
            ],
            [
              73.51192052784997,
              19.732893538357178
            ],
            [
              73.54334841211544,
              19.71101668536201
            ],
            [
              73.57689473775156,
              19.69213884378211
            ],
            [
              73.61223770384196,
              19.676440725467504
            ],
            [
              73.64903863053877,
              19.664072556615103
            ],
            [
              73.6869451562778,
              19.65515266676059
            ],
            [
              73.72559455356796,
              19.649766378433142
            ],
            [
              73.76461713430355,
              19.647965207114822
            ],
            [
              73.80363971503913,
              19.649766378433142
            ],
            [
              73.84228911232928,
              19.65515266676059
            ],
            [
              73.88019563806832,
              19.664072556615103
            ],
            [
              73.91699656476513,
              19.676440725467504
            ],
            [
              73.95233953085554,
              19.69213884378211
            ],
            [
              73.98588585649165,
              19.71101668536201
            ],
            [
              74.01731374075712,
              19.732893538357178
            ],
            [
              74.04632131194421,
              19.757559904638956
            ],
            [
              74.07262950340069,
              19.78477947266551
            ],
            [
              74.09598472851746,
              19.81429134647673
            ],
            [
              74.11616132969247,
              19.845812511082457
            ],
            [
              74.13296377758077,
              19.87904051226245
            ],
            [
              74.14622859863391,
              19.913656326699456
            ],
            [
              74.15582601085325,
              19.949327396438406
            ],
            [
              74.16166124983754,
              19.98571079992367
            ],
            [
              74.16367556960013,
              20.0224565303355
            ],
            [
              74.16184690526717,
              20.059210850644764
            ],
            [
              74.15619018764001,
              20.095619693755374
            ],
            [
              74.14675730270577,
              20.131332075325183
            ],
            [
              74.13363669249252,
              20.166003486369956
            ],
            [
              74.11695259716892,
              20.19929923257909
            ],
            [
              74.09686394194902,
              20.23089768742281
            ],
            [
              74.07356287614599,
              20.26049342662224
            ],
            [
              74.04727297557372,
              20.287800212395556
            ],
            [
              74.01824712337134,
              20.31255379709155
            ],
            [
              73.98676508815862,
              20.334514517375656
            ],
            [
              73.95313082215768,
              20.353469652038196
            ],
            [
              73.91766950546564,
              20.369235518737433
            ],
            [
              73.88072436596589,
              20.38165928755308
            ],
            [
              73.84265330735144,
              20.3906204920831
            ],
            [
              73.80382538033767,
              20.396032221936622
            ],
            [
              73.76461713430355,
              20.39784198381982
            ]
          ]
        ]
      }
    },
    Nagpur: {
      "geometry": {
        "coordinates": [
          [
            [
              79.0803980791448,
              21.57736798288606
            ],
            [
              79.03590097216778,
              21.575329288914777
            ],
            [
              78.99183609592933,
              21.56923301186388
            ],
            [
              78.94863137284912,
              21.559138368792087
            ],
            [
              78.90670615610968,
              21.545143398546575
            ],
            [
              78.86646706282738,
              21.52738398499407
            ],
            [
              78.82830394659979,
              21.50603250463884
            ],
            [
              78.79258605265815,
              21.48129611344602
            ],
            [
              78.75965839619587,
              21.453414691537112
            ],
            [
              78.72983840125313,
              21.422658467998303
            ],
            [
              78.7034128338926,
              21.389325351296712
            ],
            [
              78.68063505938954,
              21.35373799369706
            ],
            [
              78.66172264887102,
              21.316240620583145
            ],
            [
              78.64685535636637,
              21.277195657695305
            ],
            [
              78.63617348266192,
              21.236980190985232
            ],
            [
              78.6297766377717,
              21.19598229506037
            ],
            [
              78.62772290931892,
              21.154597267045325
            ],
            [
              78.6300284397337,
              21.113223803138254
            ],
            [
              78.63666741096921,
              21.072260155201437
            ],
            [
              78.6475724314631,
              21.03210030441738
            ],
            [
              78.66263531635701,
              20.99313018838733
            ],
            [
              78.68170824855608,
              20.955724017073642
            ],
            [
              78.70460530507565,
              20.92024071171604
            ],
            [
              78.73110433028602,
              20.887020499311706
            ],
            [
              78.76094913512748,
              20.85638169346543
            ],
            [
              78.79385199911533,
              20.828617690414593
            ],
            [
              78.82949644997872,
              20.803994206838215
            ],
            [
              78.86754029405988,
              20.782746783692552
            ],
            [
              78.90761886912756,
              20.765078577799855
            ],
            [
              78.94934849001181,
              20.75115846027172
            ],
            [
              78.99233005643251,
              20.741119438093452
            ],
            [
              79.03615279155409,
              20.735057412349786
            ],
            [
              79.0803980791448,
              20.733030283652013
            ],
            [
              79.1246433667355,
              20.735057412349786
            ],
            [
              79.16846610185709,
              20.741119438093452
            ],
            [
              79.21144766827777,
              20.75115846027172
            ],
            [
              79.25317728916204,
              20.765078577799855
            ],
            [
              79.2932558642297,
              20.782746783692552
            ],
            [
              79.33129970831088,
              20.803994206838215
            ],
            [
              79.36694415917427,
              20.828617690414593
            ],
            [
              79.39984702316211,
              20.85638169346543
            ],
            [
              79.42969182800357,
              20.887020499311706
            ],
            [
              79.45619085321395,
              20.92024071171604
            ],
            [
              79.47908790973352,
              20.955724017073635
            ],
            [
              79.49816084193259,
              20.99313018838733
            ],
            [
              79.51322372682648,
              21.03210030441738
            ],
            [
              79.52412874732039,
              21.072260155201437
            ],
            [
              79.5307677185559,
              21.113223803138254
            ],
            [
              79.53307324897067,
              21.154597267045325
            ],
            [
              79.5310195205179,
              21.19598229506037
            ],
            [
              79.52462267562767,
              21.236980190985232
            ],
            [
              79.51394080192321,
              21.277195657695305
            ],
            [
              79.49907350941858,
              21.316240620583145
            ],
            [
              79.48016109890006,
              21.35373799369706
            ],
            [
              79.45738332439699,
              21.389325351296712
            ],
            [
              79.43095775703647,
              21.422658467998303
            ],
            [
              79.40113776209373,
              21.453414691537112
            ],
            [
              79.36821010563145,
              21.48129611344602
            ],
            [
              79.33249221168981,
              21.50603250463884
            ],
            [
              79.2943290954622,
              21.52738398499407
            ],
            [
              79.25409000217991,
              21.545143398546575
            ],
            [
              79.21216478544046,
              21.559138368792087
            ],
            [
              79.16896006236027,
              21.56923301186388
            ],
            [
              79.1248951861218,
              21.575329288914777
            ],
            [
              79.0803980791448,
              21.57736798288606
            ]
          ]
        ]
      }
    },
    Solapur: {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              75.89730370037773,
              18.040817075802213
            ],
            [
              75.86193264268907,
              18.039161636817624
            ],
            [
              75.8269041950138,
              18.034211355576883
            ],
            [
              75.79255759061027,
              18.026014181108383
            ],
            [
              75.75922534485667,
              18.01464950309708
            ],
            [
              75.72722998491014,
              18.00022736967487
            ],
            [
              75.69688088436568,
              17.98288740391386
            ],
            [
              75.66847123574719,
              17.962797430421503
            ],
            [
              75.64227519186802,
              17.94015182643058
            ],
            [
              75.61854520492848,
              17.91516961458192
            ],
            [
              75.5975095897204,
              17.888092317181258
            ],
            [
              75.57937033453253,
              17.859181594044756
            ],
            [
              75.56430118035034,
              17.8287166881084
            ],
            [
              75.55244598577448,
              17.796991704745786
            ],
            [
              75.54391739179717,
              17.764312752205495
            ],
            [
              75.53879579722982,
              17.730994971734937
            ],
            [
              75.53712865221598,
              17.69735948680036
            ],
            [
              75.53893007393584,
              17.663730301344
            ],
            [
              75.54418078535421,
              17.630431177245033
            ],
            [
              75.55282837471367,
              17.597782521079424
            ],
            [
              75.5647878704602,
              17.566098309917113
            ],
            [
              75.57994262343048,
              17.5356830852662
            ],
            [
              75.59814548544611,
              17.506829043388674
            ],
            [
              75.61922027096362,
              17.47981324908673
            ],
            [
              75.642963486127,
              17.45489499871059
            ],
            [
              75.66914630746767,
              17.432313356584785
            ],
            [
              75.69751679059651,
              17.412284887309227
            ],
            [
              75.7278022875337,
              17.395001604480502
            ],
            [
              75.75971204982302,
              17.380629154317546
            ],
            [
              75.79293999327506,
              17.36930525048035
            ],
            [
              75.82716759907599,
              17.361138374059845
            ],
            [
              75.86206692508044,
              17.356206750308765
            ],
            [
              75.89730370037773,
              17.354557611195013
            ],
            [
              75.93254047567503,
              17.356206750308765
            ],
            [
              75.96743980167948,
              17.361138374059845
            ],
            [
              76.0016674074804,
              17.36930525048035
            ],
            [
              76.03489535093244,
              17.380629154317546
            ],
            [
              76.06680511322175,
              17.395001604480502
            ],
            [
              76.09709061015896,
              17.412284887309227
            ],
            [
              76.12546109328778,
              17.432313356584785
            ],
            [
              76.15164391462845,
              17.45489499871059
            ],
            [
              76.17538712979183,
              17.47981324908673
            ],
            [
              76.19646191530936,
              17.506829043388674
            ],
            [
              76.21466477732498,
              17.5356830852662
            ],
            [
              76.22981953029527,
              17.566098309917113
            ],
            [
              76.2417790260418,
              17.597782521079424
            ],
            [
              76.25042661540125,
              17.630431177245033
            ],
            [
              76.25567732681961,
              17.663730301344
            ],
            [
              76.25747874853948,
              17.69735948680036
            ],
            [
              76.25581160352564,
              17.730994971734937
            ],
            [
              76.25069000895829,
              17.764312752205495
            ],
            [
              76.24216141498097,
              17.796991704745786
            ],
            [
              76.23030622040511,
              17.8287166881084
            ],
            [
              76.21523706622294,
              17.859181594044756
            ],
            [
              76.19709781103506,
              17.888092317181258
            ],
            [
              76.17606219582697,
              17.91516961458192
            ],
            [
              76.15233220888743,
              17.94015182643058
            ],
            [
              76.12613616500828,
              17.962797430421503
            ],
            [
              76.09772651638978,
              17.98288740391386
            ],
            [
              76.06737741584531,
              18.00022736967487
            ],
            [
              76.0353820558988,
              18.01464950309708
            ],
            [
              76.0020498101452,
              18.026014181108383
            ],
            [
              75.96770320574167,
              18.034211355576883
            ],
            [
              75.93267475806638,
              18.039161636817624
            ],
            [
              75.89730370037773,
              18.040817075802213
            ]
          ]
        ]
      }
    },
    Kolhapur: {
      "geometry": {
        "coordinates": [
          [
            [
              74.23351815991231,
              16.89879936476072
            ],
            [
              74.21386333245843,
              16.89787454207959
            ],
            [
              74.19439836302872,
              16.895109007638947
            ],
            [
              74.17531125898469,
              16.89052947515378
            ],
            [
              74.15678634506295,
              16.884180178052702
            ],
            [
              74.13900246859845,
              16.87612243840644
            ],
            [
              74.12213125998943,
              16.86643406953744
            ],
            [
              74.10633546582768,
              16.855208618334945
            ],
            [
              74.09176737128956,
              16.842554454901975
            ],
            [
              74.07856732737442,
              16.828593718674778
            ],
            [
              74.06686239740293,
              16.813461131566896
            ],
            [
              74.05676513586698,
              16.797302689983162
            ],
            [
              74.04837251127432,
              16.7802742487115
            ],
            [
              74.04176498307824,
              16.762540010721484
            ],
            [
              74.03700574114329,
              16.74427093776888
            ],
            [
              74.03414011449765,
              16.72564309741909
            ],
            [
              74.03319515438153,
              16.706835962653397
            ],
            [
              74.03417939483805,
              16.688030680609316
            ],
            [
              74.03708279233166,
              16.669408327227327
            ],
            [
              74.04187684413424,
              16.65114816463338
            ],
            [
              74.04851488351032,
              16.633425917981384
            ],
            [
              74.05693254807363,
              16.61641208821688
            ],
            [
              74.06704841609307,
              16.600270316807563
            ],
            [
              74.07876480400768,
              16.58515581792452
            ],
            [
              74.09196871697871,
              16.57121389285799
            ],
            [
              74.10653294297082,
              16.558578540621188
            ],
            [
              74.12231727962167,
              16.54737117774462
            ],
            [
              74.13916988203601,
              16.53769947920134
            ],
            [
              74.15692871863129,
              16.529656351240707
            ],
            [
              74.1754231212716,
              16.523319045656116
            ],
            [
              74.19447541515922,
              16.5187484236809
            ],
            [
              74.2139026133087,
              16.515988376309156
            ],
            [
              74.23351815991231,
              16.515065406385137
            ],
            [
              74.25313370651591,
              16.515988376309156
            ],
            [
              74.2725609046654,
              16.5187484236809
            ],
            [
              74.291613198553,
              16.523319045656116
            ],
            [
              74.31010760119331,
              16.529656351240707
            ],
            [
              74.32786643778859,
              16.53769947920134
            ],
            [
              74.34471904020293,
              16.54737117774462
            ],
            [
              74.3605033768538,
              16.558578540621188
            ],
            [
              74.37506760284589,
              16.57121389285799
            ],
            [
              74.38827151581692,
              16.58515581792452
            ],
            [
              74.39998790373154,
              16.600270316807563
            ],
            [
              74.41010377175097,
              16.61641208821688
            ],
            [
              74.4185214363143,
              16.633425917981384
            ],
            [
              74.42515947569036,
              16.65114816463338
            ],
            [
              74.42995352749296,
              16.669408327227327
            ],
            [
              74.43285692498655,
              16.688030680609316
            ],
            [
              74.43384116544307,
              16.706835962653397
            ],
            [
              74.43289620532695,
              16.72564309741909
            ],
            [
              74.43003057868131,
              16.74427093776888
            ],
            [
              74.42527133674636,
              16.762540010721484
            ],
            [
              74.41866380855029,
              16.7802742487115
            ],
            [
              74.41027118395763,
              16.797302689983162
            ],
            [
              74.40017392242167,
              16.813461131566896
            ],
            [
              74.38846899245019,
              16.828593718674778
            ],
            [
              74.37526894853505,
              16.842554454901975
            ],
            [
              74.36070085399692,
              16.855208618334945
            ],
            [
              74.34490505983517,
              16.86643406953744
            ],
            [
              74.32803385122615,
              16.87612243840644
            ],
            [
              74.31024997476166,
              16.884180178052702
            ],
            [
              74.29172506083991,
              16.89052947515378
            ],
            [
              74.27263795679588,
              16.895109007638947
            ],
            [
              74.25317298736617,
              16.89787454207959
            ],
            [
              74.23351815991231,
              16.89879936476072
            ]
          ]
        ]
      }
    }
  }
}

const MadhyaPradesh = {
  "geometry": {
    "coordinates": [
      [
        [
          78.27347101034024,
          26.820874959523934
        ],
        [
          78.00895392084811,
          26.69539837932905
        ],
        [
          77.39725815140133,
          26.37740001065798
        ],
        [
          76.80209470004706,
          26.01396029400115
        ],
        [
          76.52931145150865,
          25.813214602549635
        ],
        [
          76.48798065627608,
          25.53010673572301
        ],
        [
          76.70290079148799,
          25.29118044569944
        ],
        [
          77.21540265237576,
          25.336015153374987
        ],
        [
          77.3311288790286,
          25.433100027779687
        ],
        [
          77.3889919923551,
          25.201461301092692
        ],
        [
          77.3559273561687,
          25.089219348694698
        ],
        [
          76.94261940383865,
          25.059270744519793
        ],
        [
          76.90128860860608,
          24.834423293514348
        ],
        [
          77.06661178953789,
          24.564066580487705
        ],
        [
          76.86822397241974,
          24.353384891831794
        ],
        [
          76.80209470004706,
          24.081992643214775
        ],
        [
          76.66156999625406,
          24.2705208610099
        ],
        [
          76.48798065627608,
          24.180062072560702
        ],
        [
          76.19039893059892,
          24.29312551045274
        ],
        [
          75.6613647516173,
          23.787334792596198
        ],
        [
          75.48777541163929,
          23.991400370869385
        ],
        [
          75.76055866017634,
          24.4211424201102
        ],
        [
          75.82668793254908,
          24.72184598453572
        ],
        [
          75.30591991261375,
          24.84942588133083
        ],
        [
          75.18192752691462,
          25.03680448651383
        ],
        [
          74.85954732409738,
          24.931907577272156
        ],
        [
          74.8099503698185,
          24.744369611990635
        ],
        [
          74.74382109744582,
          24.4211424201102
        ],
        [
          74.80168421077227,
          24.23283750915843
        ],
        [
          74.98353970979647,
          23.983848132787642
        ],
        [
          74.96700741943579,
          23.787334791514624
        ],
        [
          74.90914430610923,
          23.610956563941954
        ],
        [
          74.60330219674856,
          23.413879866194847
        ],
        [
          74.53717292437588,
          23.28486344237875
        ],
        [
          74.75209305958782,
          23.208912935179015
        ],
        [
          74.3305189482115,
          23.07969800084264
        ],
        [
          74.487575970097,
          22.836133135525827
        ],
        [
          74.3305189482115,
          22.63791489398166
        ],
        [
          74.06600185872071,
          22.508155688321978
        ],
        [
          74.04120338158063,
          22.347696683580608
        ],
        [
          74.13213113109342,
          22.09517354736262
        ],
        [
          74.1817280853736,
          21.903566584502514
        ],
        [
          74.47930981105074,
          22.033887496950214
        ],
        [
          74.52064060628334,
          21.734738996851434
        ],
        [
          74.88435160433323,
          21.611830517871354
        ],
        [
          75.23153028429053,
          21.434966549908594
        ],
        [
          75.83495989469108,
          21.358002152378532
        ],
        [
          76.0912108251357,
          21.32720506078651
        ],
        [
          76.13254162036822,
          21.11144483821228
        ],
        [
          76.54584957269827,
          21.142287022164794
        ],
        [
          76.67810811744363,
          21.273294599943043
        ],
        [
          76.91782672979434,
          21.627199804640938
        ],
        [
          77.39726395449713,
          21.757772659563102
        ],
        [
          77.58738561256894,
          21.581087044878146
        ],
        [
          77.76097495254697,
          21.381095724237227
        ],
        [
          78.28174297248228,
          21.550337045500797
        ],
        [
          78.70331708385856,
          21.473433543193835
        ],
        [
          78.94303569620928,
          21.542648526784802
        ],
        [
          79.24061742188644,
          21.673297854856827
        ],
        [
          79.5134006704235,
          21.680979431365813
        ],
        [
          79.99283789512623,
          21.565712860520605
        ],
        [
          80.27388730271088,
          21.6348838351863
        ],
        [
          80.62933214171312,
          21.33490494002166
        ],
        [
          80.76985684550613,
          21.64256745701603
        ],
        [
          80.84425227692509,
          22.04154970598242
        ],
        [
          81.05090625308941,
          22.255922717394625
        ],
        [
          81.2244955930687,
          22.48524433651764
        ],
        [
          81.60473890921111,
          22.531063244303738
        ],
        [
          81.8279252034693,
          22.82851467646522
        ],
        [
          81.96844990726095,
          23.06448806188071
        ],
        [
          82.20816851961291,
          23.36077064660452
        ],
        [
          81.83619136251559,
          23.51245471263941
        ],
        [
          81.62953738635116,
          23.50487464676675
        ],
        [
          81.66260202253756,
          23.739653339674533
        ],
        [
          81.63780354539745,
          23.898457207674184
        ],
        [
          81.90232063488827,
          23.853104508035486
        ],
        [
          82.3569593824509,
          23.785045693146245
        ],
        [
          82.62147647194166,
          23.853104508035486
        ],
        [
          82.78679965287341,
          23.981562507341494
        ],
        [
          82.67107342622188,
          24.177779942652037
        ],
        [
          82.78679965287341,
          24.388754106442875
        ],
        [
          82.74546885764084,
          24.61440760026977
        ],
        [
          82.49748408624254,
          24.65948961671819
        ],
        [
          82.29083011007822,
          24.599376647711097
        ],
        [
          82.18337004247286,
          24.82465090089778
        ],
        [
          81.90232063488827,
          24.98209969592068
        ],
        [
          81.55514195493225,
          25.184237892749962
        ],
        [
          81.25756022925509,
          25.146830092975577
        ],
        [
          81.05917241213564,
          24.93713482960203
        ],
        [
          80.86905075406514,
          25.184237892749962
        ],
        [
          80.38134737031612,
          25.057004605720252
        ],
        [
          80.29868577984962,
          25.415909518074926
        ],
        [
          79.83578087324071,
          25.124379907432257
        ],
        [
          79.4307390799583,
          25.221634211591876
        ],
        [
          79.36460980758557,
          25.079467161112007
        ],
        [
          79.28194821711901,
          25.333754158620152
        ],
        [
          78.90170490097665,
          25.565139495513577
        ],
        [
          78.52972774387922,
          25.288918613986112
        ],
        [
          78.77771251527753,
          24.817148333129424
        ],
        [
          78.79424483337004,
          24.58434388964305
        ],
        [
          79.00089880953573,
          24.343574880651957
        ],
        [
          78.84384178765026,
          24.20040106745779
        ],
        [
          78.49666310769294,
          24.403810261471037
        ],
        [
          78.38920304008764,
          24.30591320817247
        ],
        [
          78.2404121772484,
          24.51667415710702
        ],
        [
          78.28174297248228,
          24.704555354187747
        ],
        [
          78.19908138201578,
          24.88465507517283
        ],
        [
          78.44706615341403,
          25.124379907432257
        ],
        [
          78.3230737677149,
          25.32628272031252
        ],
        [
          78.42226767627398,
          25.550224841452618
        ],
        [
          78.77771251527753,
          25.602418004524196
        ],
        [
          78.85210794669649,
          25.885354109911844
        ],
        [
          79.03396293974606,
          26.189863550245207
        ],
        [
          79.1496891663989,
          26.389964287744192
        ],
        [
          78.94303519023453,
          26.67100071070317
        ],
        [
          78.53799339695081,
          26.78174627820553
        ],
        [
          78.27347101034024,
          26.820874959523934
        ]
      ]
    ]
  },
  children:
  {
    Khajuraho: {
      "geometry": {
        "coordinates": [
          [
            [
              79.89364348059246,
              25.23918665668451
            ],
            [
              79.85197550005206,
              25.237329226915517
            ],
            [
              79.81071257230793,
              25.23177499499852
            ],
            [
              79.77025570130183,
              25.222577952503205
            ],
            [
              79.7309978382254,
              25.209827485391518
            ],
            [
              79.69331996685588,
              25.193647481051805
            ],
            [
              79.65758732103943,
              25.174195092065244
            ],
            [
              79.62414577525095,
              25.151659170364812
            ],
            [
              79.59331844659278,
              25.126258388988745
            ],
            [
              79.56540254351334,
              25.09823907191568
            ],
            [
              79.54066649301186,
              25.067872755454133
            ],
            [
              79.51934737422667,
              25.035453507310805
            ],
            [
              79.50164868217652,
              25.00129503175294
            ],
            [
              79.48773844112151,
              24.965727591192156
            ],
            [
              79.47774768262556,
              24.92909477604019
            ],
            [
              79.471769299013,
              24.891750155818176
            ],
            [
              79.46985727859642,
              24.854053845246277
            ],
            [
              79.47202632487445,
              24.816369019408594
            ],
            [
              79.47825185791214,
              24.779058412096713
            ],
            [
              79.48847039236597,
              24.74248083110281
            ],
            [
              79.5025802831324,
              24.706987723583346
            ],
            [
              79.52044282640477,
              24.672919823671425
            ],
            [
              79.54188370102875,
              24.640603913305892
            ],
            [
              79.566694732454,
              24.610349725794208
            ],
            [
              79.59463595928445,
              24.582447019959496
            ],
            [
              79.62543798041989,
              24.55716285086517
            ],
            [
              79.65880455904228,
              24.534739061085933
            ],
            [
              79.6944154582125,
              24.515390014324094
            ],
            [
              79.73192948158781,
              24.499300590875826
            ],
            [
              79.77098769172483,
              24.48662446205095
            ],
            [
              79.81121677758047,
              24.47748265816062
            ],
            [
              79.85223254214178,
              24.471962442125097
            ],
            [
              79.89364348059246,
              24.470116498133724
            ],
            [
              79.93505441904314,
              24.471962442125097
            ],
            [
              79.97607018360445,
              24.47748265816062
            ],
            [
              80.01629926946009,
              24.48662446205095
            ],
            [
              80.05535747959709,
              24.499300590875826
            ],
            [
              80.09287150297241,
              24.515390014324094
            ],
            [
              80.12848240214264,
              24.534739061085933
            ],
            [
              80.16184898076501,
              24.55716285086517
            ],
            [
              80.19265100190047,
              24.582447019959496
            ],
            [
              80.22059222873091,
              24.610349725794208
            ],
            [
              80.24540326015615,
              24.640603913305892
            ],
            [
              80.26684413478014,
              24.672919823671425
            ],
            [
              80.28470667805252,
              24.706987723583346
            ],
            [
              80.29881656881894,
              24.74248083110281
            ],
            [
              80.30903510327278,
              24.779058412096713
            ],
            [
              80.31526063631046,
              24.816369019408594
            ],
            [
              80.31742968258848,
              24.854053845246277
            ],
            [
              80.3155176621719,
              24.891750155818176
            ],
            [
              80.30953927855936,
              24.92909477604019
            ],
            [
              80.29954852006341,
              24.965727591192156
            ],
            [
              80.2856382790084,
              25.00129503175294
            ],
            [
              80.26793958695824,
              25.035453507310805
            ],
            [
              80.24662046817306,
              25.067872755454133
            ],
            [
              80.22188441767156,
              25.09823907191568
            ],
            [
              80.19396851459214,
              25.126258388988745
            ],
            [
              80.16314118593395,
              25.15165917036481
            ],
            [
              80.12969964014549,
              25.174195092065244
            ],
            [
              80.09396699432902,
              25.193647481051805
            ],
            [
              80.05628912295951,
              25.209827485391518
            ],
            [
              80.01703125988307,
              25.222577952503205
            ],
            [
              79.97657438887697,
              25.23177499499852
            ],
            [
              79.93531146113286,
              25.237329226915517
            ],
            [
              79.89364348059246,
              25.23918665668451
            ]
          ]
        ]
      }
    },
    Indore: {
      "geometry": {
        "coordinates": [
          [
            [
              75.89282250204286,
              23.294513433246596
            ],
            [
              75.83170766627045,
              23.29174403681292
            ],
            [
              75.77118889841502,
              23.28346286208234
            ],
            [
              75.71185622817306,
              23.26975067880044
            ],
            [
              75.65428767923913,
              23.250741193211375
            ],
            [
              75.5990434411763,
              23.226619694275108
            ],
            [
              75.54666024773881,
              23.197621180594137
            ],
            [
              75.49764602492488,
              23.164027989796836
            ],
            [
              75.45247486751929,
              23.126166957676322
            ],
            [
              75.41158239750635,
              23.08440613947134
            ],
            [
              75.3753615516577,
              23.03915113022711
            ],
            [
              75.34415883899501,
              22.990841025135516
            ],
            [
              75.31827110187638,
              22.939944064087978
            ],
            [
              75.2979428073276,
              22.88695300736097
            ],
            [
              75.28336388810435,
              22.832380291389185
            ],
            [
              75.27466814597331,
              22.776753014975416
            ],
            [
              75.27193222297156,
              22.720607807062784
            ],
            [
              75.27517514004595,
              22.664485627386576
            ],
            [
              75.2843583965702,
              22.608926550971187
            ],
            [
              75.29938661883973,
              22.554464586587812
            ],
            [
              75.32010874078702,
              22.50162257798903
            ],
            [
              75.34631969585026,
              22.450907235037196
            ],
            [
              75.37776259516014,
              22.402804339790922
            ],
            [
              75.41413136395774,
              22.35777417025489
            ],
            [
              75.45507380538822,
              22.316247181873035
            ],
            [
              75.50019505848759,
              22.27861998399316
            ],
            [
              75.54906141524683,
              22.24525164548404
            ],
            [
              75.60120446005261,
              22.216460360475683
            ],
            [
              75.65612549352014,
              22.19252050184343
            ],
            [
              75.7133002017063,
              22.1736600865909
            ],
            [
              75.77218353088645,
              22.160058673724702
            ],
            [
              75.83221472745441,
              22.151845711572506
            ],
            [
              75.89282250204286,
              22.14909934779276
            ],
            [
              75.95343027663131,
              22.151845711572506
            ],
            [
              76.01346147319927,
              22.160058673724702
            ],
            [
              76.07234480237942,
              22.1736600865909
            ],
            [
              76.12951951056559,
              22.19252050184343
            ],
            [
              76.1844405440331,
              22.216460360475683
            ],
            [
              76.2365835888389,
              22.24525164548404
            ],
            [
              76.28544994559813,
              22.278619983993156
            ],
            [
              76.3305711986975,
              22.316247181873035
            ],
            [
              76.37151364012799,
              22.35777417025489
            ],
            [
              76.40788240892559,
              22.402804339790922
            ],
            [
              76.43932530823547,
              22.450907235037196
            ],
            [
              76.4655362632987,
              22.50162257798903
            ],
            [
              76.48625838524599,
              22.554464586587812
            ],
            [
              76.50128660751552,
              22.608926550971187
            ],
            [
              76.51046986403978,
              22.664485627386576
            ],
            [
              76.51371278111417,
              22.720607807062784
            ],
            [
              76.51097685811241,
              22.776753014975416
            ],
            [
              76.50228111598136,
              22.832380291389185
            ],
            [
              76.48770219675812,
              22.88695300736097
            ],
            [
              76.46737390220935,
              22.939944064087978
            ],
            [
              76.44148616509071,
              22.990841025135516
            ],
            [
              76.41028345242802,
              23.03915113022711
            ],
            [
              76.37406260657937,
              23.08440613947134
            ],
            [
              76.33317013656644,
              23.126166957676322
            ],
            [
              76.28799897916085,
              23.164027989796836
            ],
            [
              76.23898475634691,
              23.197621180594137
            ],
            [
              76.18660156290943,
              23.226619694275108
            ],
            [
              76.13135732484659,
              23.250741193211375
            ],
            [
              76.07378877591266,
              23.26975067880044
            ],
            [
              76.0144561056707,
              23.28346286208234
            ],
            [
              75.95393733781528,
              23.29174403681292
            ],
            [
              75.89282250204286,
              23.294513433246596
            ]
          ]
        ]
      }
    }
  }
}

const Punjab = {
  "geometry": {
    "coordinates": [
      [
        [
          75.3450313069462,
          32.34920854056806
        ],
        [
          75.33213252517274,
          32.28925560982819
        ],
        [
          75.35148069783185,
          32.240173704960256
        ],
        [
          75.22894227099746,
          32.081840520019256
        ],
        [
          75.06770749884879,
          32.065445530960574
        ],
        [
          74.90002333581444,
          32.038114017428086
        ],
        [
          74.77748490898205,
          31.950598348004334
        ],
        [
          74.57110440063147,
          31.857521517332202
        ],
        [
          74.50016110088464,
          31.654617613843143
        ],
        [
          74.59690196417426,
          31.566737788273116
        ],
        [
          74.59045257328853,
          31.50627231582719
        ],
        [
          74.62269952771908,
          31.45126974062684
        ],
        [
          74.50016110088464,
          31.170254644413433
        ],
        [
          74.62269952771908,
          31.115055452846732
        ],
        [
          74.63559830949049,
          31.06534871899835
        ],
        [
          74.44211658291132,
          30.95479628963605
        ],
        [
          74.20993851101588,
          30.700040038130325
        ],
        [
          73.92616531203484,
          30.41124286161717
        ],
        [
          73.98420983000815,
          30.132745061286073
        ],
        [
          73.87457018494717,
          29.982028333059972
        ],
        [
          74.66139587303334,
          29.909379274899962
        ],
        [
          74.80973186341055,
          29.970855009542362
        ],
        [
          75.00321358998974,
          29.87023858199929
        ],
        [
          75.21604348922608,
          29.84227153771735
        ],
        [
          75.23539166188311,
          29.567780642735258
        ],
        [
          75.31278435251565,
          29.57899902587387
        ],
        [
          75.45467095200723,
          29.808700744509764
        ],
        [
          75.63525389681294,
          29.763922152822488
        ],
        [
          75.75779232364738,
          29.82548755069307
        ],
        [
          75.96417283199801,
          29.741525347545704
        ],
        [
          76.09316064971608,
          29.81989226182418
        ],
        [
          76.25439542186467,
          29.87023858199929
        ],
        [
          76.17055334034859,
          29.93732750012832
        ],
        [
          76.23504724920764,
          30.104851870832718
        ],
        [
          76.4091808031277,
          30.155053940457876
        ],
        [
          76.53816862084784,
          30.060206387231318
        ],
        [
          76.64135887502312,
          30.182932944256663
        ],
        [
          76.58976374793542,
          30.249810363673646
        ],
        [
          76.78324547451462,
          30.35560654825177
        ],
        [
          76.9315814648919,
          30.377864874487045
        ],
        [
          76.92513207400623,
          30.489080449389334
        ],
        [
          76.9186826831185,
          30.600169062926298
        ],
        [
          76.80904303805744,
          30.688948358586003
        ],
        [
          76.83484060160231,
          30.772104870690626
        ],
        [
          76.80259364717176,
          30.87733336001709
        ],
        [
          76.62846009325176,
          31.015615993736105
        ],
        [
          76.62846009325176,
          31.20335872938969
        ],
        [
          76.3769338486992,
          31.434762665427357
        ],
        [
          76.29954115806663,
          31.30260147019905
        ],
        [
          76.19635090389141,
          31.32464123906999
        ],
        [
          76.17700273123427,
          31.462272841788817
        ],
        [
          75.97062222288372,
          31.698526401484997
        ],
        [
          75.97062222288372,
          31.86299923009443
        ],
        [
          75.60945633327009,
          32.076375850325164
        ],
        [
          75.63525389681294,
          32.234718521735886
        ],
        [
          75.94482465933882,
          32.3982314057216
        ],
        [
          75.82873562339213,
          32.496197307123126
        ],
        [
          75.5514118152968,
          32.34375991435151
        ],
        [
          75.3450313069462,
          32.34920854056806
        ]
      ]
    ]
  },
  children: {
    Amritsar: {
      "geometry": {
        "coordinates": [
          [
            [
              74.86381778649849,
              31.894742981231506
            ],
            [
              74.83502891691951,
              31.893538957711993
            ],
            [
              74.80651953321262,
              31.889938576491634
            ],
            [
              74.77856634256884,
              31.88397678933307
            ],
            [
              74.751440525033,
              31.875711462934525
            ],
            [
              74.72540504503732,
              31.86522280398583
            ],
            [
              74.70071205185967,
              31.85261256301173
            ],
            [
              74.67760039667418,
              31.838003025615777
            ],
            [
              74.65629329223081,
              31.821535801986787
            ],
            [
              74.63699613923394,
              31.80337042762606
            ],
            [
              74.61989454123598,
              31.783682790172104
            ],
            [
              74.60515252736407,
              31.762663398917642
            ],
            [
              74.59291099951574,
              31.740515515113074
            ],
            [
              74.58328641784051,
              31.71745316242
            ],
            [
              74.57636973542697,
              31.693699037907177
            ],
            [
              74.57222559018804,
              31.669482344766365
            ],
            [
              74.57089175902951,
              31.645036568467923
            ],
            [
              74.57237887654196,
              31.620597218376712
            ],
            [
              74.57667041771134,
              31.59639955691887
            ],
            [
              74.58372294153071,
              31.5726763382357
            ],
            [
              74.59346658993958,
              31.5496555778969
            ],
            [
              74.60580583423837,
              31.52755837468485
            ],
            [
              74.62062045903242,
              31.506596804718896
            ],
            [
              74.63776677186566,
              31.486971907281383
            ],
            [
              74.6570790250031,
              31.46887178064923
            ],
            [
              74.6783710343173,
              31.45246980504415
            ],
            [
              74.70143797891596,
              31.437923008505667
            ],
            [
              74.72605836401026,
              31.42537059007876
            ],
            [
              74.75199612855232,
              31.41493261320732
            ],
            [
              74.77900287835764,
              31.406708880647788
            ],
            [
              74.80682022475688,
              31.400778000577574
            ],
            [
              74.83518220828482,
              31.397196651881217
            ],
            [
              74.86381778649849,
              31.39599905486427
            ],
            [
              74.89245336471214,
              31.397196651881217
            ],
            [
              74.92081534824008,
              31.400778000577574
            ],
            [
              74.94863269463931,
              31.406708880647788
            ],
            [
              74.97563944444464,
              31.41493261320732
            ],
            [
              75.0015772089867,
              31.42537059007876
            ],
            [
              75.026197594081,
              31.437923008505667
            ],
            [
              75.04926453867965,
              31.45246980504415
            ],
            [
              75.07055654799386,
              31.46887178064923
            ],
            [
              75.0898688011313,
              31.486971907281383
            ],
            [
              75.10701511396454,
              31.506596804718896
            ],
            [
              75.12182973875859,
              31.52755837468485
            ],
            [
              75.13416898305738,
              31.5496555778969
            ],
            [
              75.14391263146626,
              31.5726763382357
            ],
            [
              75.15096515528562,
              31.59639955691887
            ],
            [
              75.15525669645501,
              31.620597218376712
            ],
            [
              75.15674381396745,
              31.645036568467923
            ],
            [
              75.15540998280892,
              31.669482344766365
            ],
            [
              75.15126583757,
              31.693699037907177
            ],
            [
              75.14434915515646,
              31.71745316242
            ],
            [
              75.13472457348124,
              31.740515515113074
            ],
            [
              75.1224830456329,
              31.762663398917642
            ],
            [
              75.10774103176098,
              31.783682790172104
            ],
            [
              75.09063943376302,
              31.80337042762606
            ],
            [
              75.07134228076616,
              31.821535801986787
            ],
            [
              75.05003517632278,
              31.838003025615777
            ],
            [
              75.0269235211373,
              31.85261256301173
            ],
            [
              75.00223052795964,
              31.86522280398583
            ],
            [
              74.97619504796396,
              31.875711462934525
            ],
            [
              74.94906923042812,
              31.88397678933307
            ],
            [
              74.92111603978435,
              31.889938576491634
            ],
            [
              74.89260665607745,
              31.893538957711993
            ],
            [
              74.86381778649849,
              31.894742981231506
            ]
          ]
        ]
      }

    },
    Jalandhar: {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              75.58162235231634,
              31.558688967457357
            ],
            [
              75.5560725333803,
              31.55761687632147
            ],
            [
              75.53077051564746,
              31.55441100124377
            ],
            [
              75.50596164602858,
              31.549102434200744
            ],
            [
              75.48188638916805,
              31.541742652882103
            ],
            [
              75.45877795174397,
              31.532403011141458
            ],
            [
              75.43685998427944,
              31.521174033286997
            ],
            [
              75.4163443846458,
              31.508164519744483
            ],
            [
              75.3974292260668,
              31.493500473598573
            ],
            [
              75.38029683077637,
              31.47732385936361
            ],
            [
              75.36511200857694,
              31.459791207030083
            ],
            [
              75.35202047743024,
              31.441072075957248
            ],
            [
              75.34114748092942,
              31.421347394522403
            ],
            [
              75.33259661509531,
              31.400807692578304
            ],
            [
              75.32644887445325,
              31.37965124470708
            ],
            [
              75.32276192482281,
              31.358082142983893
            ],
            [
              75.32156960773086,
              31.33630831847836
            ],
            [
              75.32288167887494,
              31.314539531025847
            ],
            [
              75.32668378065138,
              31.292985346900956
            ],
            [
              75.33293764644921,
              31.271853123927833
            ],
            [
              75.3415815322177,
              31.25134602327654
            ],
            [
              75.35253086876244,
              31.23166106673185
            ],
            [
              75.36567912632272,
              31.212987257593326
            ],
            [
              75.38089888124203,
              31.195503782585863
            ],
            [
              75.39804307296554,
              31.17937831124117
            ],
            [
              75.41694643818553,
              31.164765408167472
            ],
            [
              75.43742710770533,
              31.151805072469195
            ],
            [
              75.45928835049763,
              31.140621417324517
            ],
            [
              75.48232044848928,
              31.131321501388765
            ],
            [
              75.50630268480394,
              31.12399432227767
            ],
            [
              75.53100542752573,
              31.118709980908235
            ],
            [
              75.5561922905065,
              31.115519023946977
            ],
            [
              75.58162235231634,
              31.114451970046066
            ],
            [
              75.6070524141262,
              31.115519023946977
            ],
            [
              75.63223927710696,
              31.118709980908235
            ],
            [
              75.65694201982876,
              31.12399432227767
            ],
            [
              75.68092425614343,
              31.131321501388765
            ],
            [
              75.70395635413506,
              31.140621417324517
            ],
            [
              75.72581759692734,
              31.151805072469195
            ],
            [
              75.74629826644717,
              31.164765408167472
            ],
            [
              75.76520163166715,
              31.17937831124117
            ],
            [
              75.78234582339064,
              31.195503782585863
            ],
            [
              75.79756557830997,
              31.212987257593326
            ],
            [
              75.81071383587025,
              31.23166106673185
            ],
            [
              75.82166317241499,
              31.25134602327654
            ],
            [
              75.83030705818348,
              31.271853123927833
            ],
            [
              75.83656092398132,
              31.292985346900956
            ],
            [
              75.84036302575775,
              31.314539531025847
            ],
            [
              75.84167509690182,
              31.33630831847836
            ],
            [
              75.84048277980989,
              31.358082142983893
            ],
            [
              75.83679583017944,
              31.37965124470708
            ],
            [
              75.83064808953739,
              31.400807692578304
            ],
            [
              75.82209722370328,
              31.421347394522403
            ],
            [
              75.81122422720244,
              31.441072075957248
            ],
            [
              75.79813269605575,
              31.459791207030083
            ],
            [
              75.78294787385633,
              31.47732385936361
            ],
            [
              75.76581547856588,
              31.493500473598573
            ],
            [
              75.74690031998689,
              31.508164519744483
            ],
            [
              75.72638472035325,
              31.521174033286997
            ],
            [
              75.70446675288873,
              31.532403011141458
            ],
            [
              75.68135831546464,
              31.541742652882103
            ],
            [
              75.65728305860411,
              31.549102434200744
            ],
            [
              75.63247418898523,
              31.55441100124377
            ],
            [
              75.6071721712524,
              31.55761687632147
            ],
            [
              75.58162235231634,
              31.558688967457357
            ]
          ]
        ]
      }
    },
    Ludhiana: {
      "geometry": {
        "coordinates": [
          [
            [
              75.84805071368375,
              31.08559695582344
            ],
            [
              75.82836990063076,
              31.084767444944045
            ],
            [
              75.8088796446231,
              31.082286944144677
            ],
            [
              75.78976862794525,
              31.078179469909436
            ],
            [
              75.7712218029704,
              31.072484787098528
            ],
            [
              75.75341857597971,
              31.065258017914026
            ],
            [
              75.73653104881166,
              31.056569100185587
            ],
            [
              75.72072233646783,
              31.046502100620977
            ],
            [
              75.70614497784489,
              31.03515439015502
            ],
            [
              75.69293945560224,
              31.02263568992949
            ],
            [
              75.68123283983486,
              31.009066997729228
            ],
            [
              75.67113756872327,
              30.99457940587227
            ],
            [
              75.6627503777064,
              30.979312822593
            ],
            [
              75.65615138699653,
              30.96341460985556
            ],
            [
              75.65140335545415,
              30.94703815128527
            ],
            [
              75.64855110699678,
              30.93034136450174
            ],
            [
              75.64762113385281,
              30.913485172577786
            ],
            [
              75.64862137911656,
              30.896631949631665
            ],
            [
              75.65154119923623,
              30.879943955689086
            ],
            [
              75.65635150529377,
              30.863581775929216
            ],
            [
              75.66300508023129,
              30.847702779260377
            ],
            [
              75.67143706755914,
              30.8324596108633
            ],
            [
              75.68156562555588,
              30.81799873289949
            ],
            [
              75.69329273955185,
              30.804459027018808
            ],
            [
              75.70650518357971,
              30.791970471621575
            ],
            [
              75.72107562148346,
              30.780652906047376
            ],
            [
              75.73686383650242,
              30.770614892983094
            ],
            [
              75.7537180773892,
              30.761952689418333
            ],
            [
              75.77147650828094,
              30.754749335435122
            ],
            [
              75.78996874881608,
              30.74907386901189
            ],
            [
              75.80901749037494,
              30.74498067385759
            ],
            [
              75.82844017381657,
              30.74250896607993
            ],
            [
              75.84805071368375,
              30.74168242424184
            ],
            [
              75.8676612535509,
              30.74250896607993
            ],
            [
              75.88708393699254,
              30.74498067385759
            ],
            [
              75.9061326785514,
              30.74907386901189
            ],
            [
              75.92462491908654,
              30.754749335435122
            ],
            [
              75.94238334997829,
              30.761952689418333
            ],
            [
              75.95923759086506,
              30.770614892983094
            ],
            [
              75.97502580588402,
              30.780652906047376
            ],
            [
              75.98959624378777,
              30.791970471621575
            ],
            [
              76.00280868781563,
              30.804459027018808
            ],
            [
              76.0145358018116,
              30.81799873289949
            ],
            [
              76.02466435980833,
              30.8324596108633
            ],
            [
              76.0330963471362,
              30.847702779260377
            ],
            [
              76.03974992207371,
              30.863581775929216
            ],
            [
              76.04456022813123,
              30.879943955689086
            ],
            [
              76.0474800482509,
              30.896631949631665
            ],
            [
              76.04848029351466,
              30.913485172577786
            ],
            [
              76.0475503203707,
              30.93034136450174
            ],
            [
              76.04469807191333,
              30.94703815128527
            ],
            [
              76.03995004037094,
              30.96341460985556
            ],
            [
              76.03335104966108,
              30.979312822593
            ],
            [
              76.02496385864423,
              30.99457940587227
            ],
            [
              76.0148685875326,
              31.009066997729228
            ],
            [
              76.00316197176524,
              31.02263568992949
            ],
            [
              75.9899564495226,
              31.03515439015502
            ],
            [
              75.97537909089964,
              31.046502100620977
            ],
            [
              75.95957037855582,
              31.056569100185587
            ],
            [
              75.94268285138776,
              31.065258017914026
            ],
            [
              75.92487962439706,
              31.072484787098528
            ],
            [
              75.90633279942223,
              31.078179469909436
            ],
            [
              75.88722178274436,
              31.082286944144677
            ],
            [
              75.86773152673673,
              31.084767444944045
            ],
            [
              75.84805071368375,
              31.08559695582344
            ]
          ]
        ]
      }
    }
  }
}

const WestBengal = {
  "geometry": {
    "coordinates": [
      [
        [
          89.7562622043447,
          26.247171926216097
        ],
        [
          89.84698714920319,
          26.707661353255077
        ],
        [
          89.18711944245621,
          26.826717920717073
        ],
        [
          88.89074910045929,
          27.115601938938568
        ],
        [
          88.44627048479617,
          27.09389447572228
        ],
        [
          88.01574151169632,
          27.217859082253312
        ],
        [
          88.10088865214527,
          26.394551022547077
        ],
        [
          88.06351481785083,
          26.207363543240874
        ],
        [
          87.80612750972335,
          25.967538755025032
        ],
        [
          88.11075659327173,
          25.47093065799639
        ],
        [
          87.79318968693389,
          25.38155934899291
        ],
        [
          87.84102284032974,
          24.562041634217167
        ],
        [
          87.50934644446386,
          24.117845436548777
        ],
        [
          87.14292874952821,
          23.842820300779408
        ],
        [
          86.59612701863693,
          23.638503860653216
        ],
        [
          85.9393510786316,
          23.458077317676768
        ],
        [
          86.11183169682886,
          23.095184034194233
        ],
        [
          86.45483490088452,
          22.974838050777933
        ],
        [
          86.85465937747841,
          22.471778884638283
        ],
        [
          86.82152836161845,
          22.17846669363226
        ],
        [
          87.30122900357168,
          21.94069561505225
        ],
        [
          87.43283378185913,
          21.69990632300491
        ],
        [
          89.12568644458702,
          21.6288656065518
        ],
        [
          89.03452553678812,
          22.274972148828965
        ],
        [
          88.89734454211896,
          22.899703971089252
        ],
        [
          89.03881944026443,
          23.31362388508029
        ],
        [
          88.72842391172367,
          23.333538173455125
        ],
        [
          88.59005668562213,
          23.869242265302546
        ],
        [
          88.76179327095667,
          24.343425010727472
        ],
        [
          88.03834067953784,
          24.677657045972566
        ],
        [
          88.31473206518189,
          24.893946547312154
        ],
        [
          88.42920710489119,
          25.181857663353426
        ],
        [
          89.01238041693438,
          25.213167371614418
        ],
        [
          88.81071838001833,
          25.455767465536795
        ],
        [
          88.1822646537496,
          25.82405031416164
        ],
        [
          88.20389700686565,
          26.137835108347417
        ],
        [
          88.59199951155063,
          26.45554122853831
        ],
        [
          88.99499864155484,
          26.288067038047885
        ],
        [
          89.36293374531596,
          25.97334331248524
        ],
        [
          89.65918139756002,
          25.9778994796998
        ],
        [
          89.7562622043447,
          26.247171926216097
        ]
      ]
    ]
  },
  children: {
    Kharagpur: {
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              87.31752713134472,
              22.68785804990277
            ],
            [
              87.2814067153292,
              22.686216834938868
            ],
            [
              87.24563671843899,
              22.681309113086847
            ],
            [
              87.21056408475319,
              22.673182495492348
            ],
            [
              87.17652884575043,
              22.661915809196323
            ],
            [
              87.14386075720707,
              22.647618315791544
            ],
            [
              87.11287604646088,
              22.630428629371913
            ],
            [
              87.08387430441175,
              22.610513345401774
            ],
            [
              87.05713555463424,
              22.58806539517004
            ],
            [
              87.03291752957247,
              22.5633021433283
            ],
            [
              87.01145318102637,
              22.536463248608193
            ],
            [
              86.9929484490857,
              22.507808310142053
            ],
            [
              86.97758031038278,
              22.477614323848645
            ],
            [
              86.9654951230862,
              22.446172975075743
            ],
            [
              86.95680728250747,
              22.413787795101793
            ],
            [
              86.9515981976027,
              22.38077121018505
            ],
            [
              86.94991559507959,
              22.347441512609468
            ],
            [
              86.95177315431602,
              22.314119783618413
            ],
            [
              86.95715047290578,
              22.28112679825742
            ],
            [
              86.96599335940509,
              22.248779941979343
            ],
            [
              86.97821444679066,
              22.217390168413854
            ],
            [
              86.99369411727832,
              22.187259026985977
            ],
            [
              87.01228172650404,
              22.15867578810339
            ],
            [
              87.03379711264729,
              22.131914692439665
            ],
            [
              87.05803237388207,
              22.10723234943961
            ],
            [
              87.08475389557186,
              22.084865308583876
            ],
            [
              87.11370460687822,
              22.065027825191702
            ],
            [
              87.14460644491929,
              22.047909840632837
            ],
            [
              87.17716300328621,
              22.033675194779743
            ],
            [
              87.21106234059171,
              22.0224600863773
            ],
            [
              87.24597992377697,
              22.01437179475588
            ],
            [
              87.2815816801278,
              22.0094876739812
            ],
            [
              87.31752713134472,
              22.007854428136138
            ],
            [
              87.35347258256164,
              22.0094876739812
            ],
            [
              87.3890743389125,
              22.01437179475588
            ],
            [
              87.42399192209774,
              22.0224600863773
            ],
            [
              87.45789125940325,
              22.033675194779743
            ],
            [
              87.49044781777015,
              22.047909840632837
            ],
            [
              87.52134965581124,
              22.065027825191702
            ],
            [
              87.5503003671176,
              22.084865308583876
            ],
            [
              87.57702188880737,
              22.10723234943961
            ],
            [
              87.60125715004216,
              22.131914692439665
            ],
            [
              87.62277253618542,
              22.15867578810339
            ],
            [
              87.64136014541111,
              22.187259026985977
            ],
            [
              87.65683981589878,
              22.217390168413854
            ],
            [
              87.66906090328436,
              22.248779941979343
            ],
            [
              87.67790378978366,
              22.28112679825742
            ],
            [
              87.68328110837342,
              22.314119783618413
            ],
            [
              87.68513866760985,
              22.347441512609468
            ],
            [
              87.68345606508673,
              22.38077121018505
            ],
            [
              87.67824698018198,
              22.413787795101793
            ],
            [
              87.66955913960327,
              22.446172975075743
            ],
            [
              87.65747395230669,
              22.477614323848645
            ],
            [
              87.64210581360375,
              22.507808310142053
            ],
            [
              87.62360108166308,
              22.536463248608193
            ],
            [
              87.60213673311699,
              22.5633021433283
            ],
            [
              87.57791870805522,
              22.58806539517004
            ],
            [
              87.55117995827771,
              22.610513345401774
            ],
            [
              87.52217821622857,
              22.630428629371913
            ],
            [
              87.4911935054824,
              22.647618315791544
            ],
            [
              87.45852541693903,
              22.661915809196323
            ],
            [
              87.42449017793626,
              22.673182495492348
            ],
            [
              87.38941754425046,
              22.681309113086847
            ],
            [
              87.35364754736024,
              22.686216834938868
            ],
            [
              87.31752713134472,
              22.68785804990277
            ]
          ]
        ]
      }
    }
  }
}

export const States = { MadhyaPradesh, Maharashtra, Punjab, WestBengal };