import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Modal } from '@mui/material';
import React, { useState } from 'react';
import { Header, InfoRow1, ObservationResult, VegetationReading } from './Renderingcomponents';
import ObservationTypeDetail from './ObservationTypeDetail';
import * as XLSX from "xlsx";
import dayjs from 'dayjs';
import { deleteObservation as deleteObservationApi, recalculateObservation } from 'src/services/observation.service';
import { ToastSeverity } from 'src/components/ToastAlert/types';
import { ToastMessages } from '..';

export type SetToastArgs = {message: string, severity: ToastSeverity, isOpen: boolean};

type Props = {
	data: any;
	onClose: () => void;
	open: boolean;
	setToast: (args: SetToastArgs) => void;
}

const ObservationModal: React.FC<Props> = (props) => {
	const { data, onClose, open, setToast } = props;
	const {
		observationTypeId,
		plotId,
		riskLevel,
		probability,
		rating,
		diseasePestId,
		observationDate,
		seasonStartDate,
		cropId,
		cropStage,
		observationType,
		vegetationReading,
		modelPrediction,
		modelRiskLevel,
		id
	} = data;

	const [observationTypeDetail, setObservationTypeDetail] = useState<any>({});

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);

	const updateObservationTypeDetail = (data: any) => {
		setObservationTypeDetail(data);
	}

	const downloadData = () => {
		const finalData: any[] = formatColumns(data);

		const workbook = XLSX.utils.book_new();
		const worksheet = XLSX.utils.json_to_sheet(finalData);
		XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

		const fileName = `${plotId}-${diseasePestId}-${observationType}-${dayjs(observationDate).format("DD-MM-YYYY")}.xlsx`;

		XLSX.writeFile(workbook, fileName);
	}

	const deleteObservation = () => {
		setIsLoading(true);
		deleteObservationApi(id)
			.then(() => {
				setToast({
					isOpen: true,
					message: ToastMessages.deleteSuccess,
					severity: "error"
				})
			}).catch(() => {
				setToast({
					isOpen: true,
					message: ToastMessages.deleteFailure,
					severity: "warning"
				})
			})
			.finally(() => {
				setIsLoading(false);
				handleDialogBoxClose();
				if(!!onClose) {
					onClose();
				}
			})
	}

	const recalculate = () => {
		setIsLoading(true);
		recalculateObservation(id)
			.then(() => {
				setToast({
					isOpen: true,
					message: ToastMessages.recalculateSuccess,
					severity: "success"
				})
			})
			.catch(() => {
				setToast({
					isOpen: true,
					message: ToastMessages.recalculateFailure,
					severity: "error"
				})
			})
			.finally(() => {
				setIsLoading(false);
				if(!!onClose) {
					onClose();
				}
			})
	}

	const openDialogBox = () => setOpenDeleteConfirmation(true);
	const handleDialogBoxClose = () => setOpenDeleteConfirmation(false);

	return (
		<Modal open={open} onClose={onClose}>
			<Grid
				container
				direction="column"
				justifyContent="flex-start"
				alignItems="stretch"
				style={{
					minWidth: '40%',
					maxWidth: '90%',
					height: '80%',
					margin: '5% auto',
					padding: '24px',
					backgroundColor: 'white',
					borderRadius: 2,
					gap: "20px"
				}}
			>
				{ !!isLoading && <Grid display={"flex"} width={"100%"} justifyContent={"center"} alignItems={"center"} >
					<CircularProgress size={"small"} color="success" />
				</Grid> }

				<Grid flex={1} mt={2} overflow={"auto"}>
					<Grid mb={2} >
						<Header
							title={observationTypeDetail?.name ?? ""}
							date={observationDate}
							download={downloadData}
							plotId={plotId}
							cropId={cropId}
							observationId={id}
							recalculate={recalculate}
							deleteObservation={openDialogBox}
						/>
					</Grid>

					<Grid style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
						<Grid my={2}>
							<InfoRow1
								plot={plotId}
								crop={cropId}
								diseasePest={diseasePestId}
								cropStage={cropStage}
								seasonStartDate={seasonStartDate}
							/>

							<Grid mt={3}>
								<ObservationResult
									probability={probability}
									riskLevel={riskLevel}
									rating={rating}
									modelPrediction={modelPrediction}
									modelRiskLevel={modelRiskLevel}
								/>
							</Grid>
						</Grid>

						<Grid item xs={6} m={"auto"} >
							<ObservationTypeDetail detail={observationTypeDetail} setObervationTypeDetail={updateObservationTypeDetail} id={observationTypeId} />
						</Grid>
					</Grid>

					<VegetationReading data={vegetationReading} />
				</Grid>

				<Dialog
					open={openDeleteConfirmation}
					onClose={handleDialogBoxClose}
					aria-labelledby="delete-observation-dialog-title"
					aria-describedby="delete-observation-dialog-description"
				>
					<DialogTitle id="delete-observation-dialog-title">
						Delete
					</DialogTitle>
					<DialogContent>
					<DialogContentText id="delete-observation-dialog-description">
						Are you sure you want to delete this observation? This action cannot be undone.
					</DialogContentText>
					</DialogContent>
					<DialogActions>
					<Button variant='contained' color='secondary' onClick={handleDialogBoxClose}>Cancel</Button>
					<Button variant='outlined' color='error' onClick={deleteObservation} autoFocus>
						Delete
					</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		</Modal>

	)
}

export default ObservationModal;

export const formatColumns = (data: any) => {

	const { vegetationReading } = data;

	const updated = vegetationReading?.map((dt: any, index: number) => {
		const { sample } = dt;
		let plantationRow: Record<string, number | string> = {};
		plantationRow["Plant Number"] = `Plant ${index + 1}`;

		Object.entries(sample).forEach(([col, value]: [string, any]) => {
			const obj: Record<string, number> = {};

			// observation without sub-col
			if (value.hasOwnProperty("reading")) {
				obj[col] = value.reading;
			} else {
				Object.entries(value).forEach(([subCol, subValue]: [string, any]) => {
					if (subCol !== "rating") {
						obj[col.trim() + "." + subCol] = subValue.reading;
					}
				})
			}

			plantationRow = { ...plantationRow, ...obj };
		});

		return plantationRow;
	});

	return updated;
}
