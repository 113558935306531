import { Grid, Typography } from '@mui/material';
import { ChartData } from 'chart.js';
import _ from 'lodash';
import { FC, memo, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Skeleton } from 'src/components';
import { fetchActiveUsers, fetchDailyActiveUsers } from 'src/services/userEvent.service';
import { TActiveUsers, TActiveUsersData, TTimeRange } from '../types';
import { getFarmUsersCount } from 'src/services/farmUser.service';

type Props = TTimeRange;

type InfoContainerProps = {
  title: string;
  value: number;
  totalValue: number; // total farmusers
  bottomTitle: string;
}

type InfoData = {
  min: number;
  max: number;
  avg: number;
  total: number;
}

const LineChartOptions = {
  responsive: true,
  scales: {
    x: {
      title: { color: '#565656', display: true, text: 'Timestamp' },
      ticks: { autoSkip: true, maxTicksLimit: 7 },
    },
    y: { beginAtZero: true, }
  },
  plugins: {
    legend: {
      position: 'bottom' as const,
      display: true,
    }
  },
}

const InfoContainer: FC<InfoContainerProps> = (props) => {
  const { title, value, totalValue, bottomTitle } = props;

  let percentage: number | string = value / totalValue;
  percentage = (percentage * 100).toFixed(2);

  return (
    <Grid boxShadow={1} width={160} borderRadius={2} p={2} color={'#666666'} my={2}>
      <Typography fontWeight={'bold'} fontSize={12}> {title} </Typography>
      <Typography fontSize={35} > {value} </Typography>
      <Typography fontSize={10} fontWeight={'bold'} > {percentage}% of {totalValue} {bottomTitle} </Typography>
    </Grid>
  )
}

const UsersChart: FC<Props> = (props) => {
  const { startTime, endTime } = props;

  const [totalFarmUsers, setTotalFarmUsers] = useState<number>();
  const [activeUsers, setActiveUsers] = useState<TActiveUsersData>({ loading: true, data: [] });
  const [chartData, setChartData] = useState<ChartData<"line", any, unknown>>();

  const [infoData, setInfoData] = useState<InfoData>({
    min: 0,
    max: 0,
    avg: 0,
    total: 0 // us time frame ke overall engagement mein jitne userIds hain
  });

  /**
   * @function
   * called on intial render
   * fetches total farm users
   */
  useEffect(() => {
    (async () => {
      const totalFarmers = await getFarmUsersCount();
      setTotalFarmUsers(totalFarmers.count);
    })();
  }, [])

  /**
   * @function
   * called on time range updates
   * calls getUniqueUsersCount 
   * */
  useEffect(() => {
    if (!!startTime && !!endTime) {
      getUniqueUsersCount();
    }
  }, [startTime, endTime])

  /**
   * @function
   * called on activeUsers update
   * generates graph data for active users
   * calculates the infoData, min/max/avg/total
   * */
  useEffect(() => {
    const { loading, data } = activeUsers;
    if (!loading && !_.isEmpty(data)) {

      // generating chart data
      generateChartData();

      // calculating info data
      calculateInfoData();
    }
  }, [activeUsers])

  const calculateInfoData = async () => {
    const { data } = activeUsers;

    const apiData = await fetchActiveUsers('2023-07-10', '2023-07-16');

    let currMin = data[0].uniqueUserCount, 
        currMax = data[0].uniqueUserCount,
        currAvg = 0;

    for(const currData of data) {
      currAvg += currData.uniqueUserCount;
      currMax = Math.max(currMax, currData.uniqueUserCount);
      currMin = Math.min(currMin, currData.uniqueUserCount);
    }

    currAvg = Number((currAvg/data.length).toFixed(0));
    
    setInfoData({
      max: currMax,
      min: currMin,
      avg: currAvg,
      total: apiData.length
    })
  }

  const generateChartData = () => {
    const { data } = activeUsers;
    const cData = data.map((daily) => daily.uniqueUserCount);

    setChartData({
      labels: data.map((daily) => daily.date),
      datasets: [
        {
          label: 'Users Count',
          data: cData
        }
      ]
    })
  }

  /**
   * @function
   * fetches unique users for selected time range
   * updates activeUsers state
   * */
  const getUniqueUsersCount = async () => {
    let data: TActiveUsers = [];
    setActiveUsers((activeUsers) => ({ ...activeUsers, loading: true }));
    try {
      data = await fetchDailyActiveUsers(startTime, endTime);
    } catch (error) {
      data = [];
    } finally {
      setActiveUsers({ loading: false, data: data! });
    }
  }

  return (
    <Grid height={472} className="ag-theme-alpine" p={0} m={0}>
      {
        !!chartData ?
          <>
            <Line
              data={chartData!}
              options={LineChartOptions}
            />

            {/* avg, max and min */}
            <Grid container justifyContent={'space-between'}>
              <InfoContainer title='Total Daily Users' value={infoData.total} totalValue={totalFarmUsers!} bottomTitle='users' />
              <InfoContainer title='Average Daily Users' value={infoData.avg} totalValue={totalFarmUsers!} bottomTitle='users' />
              <InfoContainer title='Maximum Daily Users' value={infoData.max} totalValue={totalFarmUsers!} bottomTitle='users' />
              <InfoContainer title='Minimum Daily Users' value={infoData.min} totalValue={totalFarmUsers!} bottomTitle='users' />
            </Grid>
          </> : 
          <>
            <Skeleton.LineChart width={600} height={300} data={[[150, 135, 165, 155, 176, 160, 186]]} />
            <Grid container justifyContent={'space-between'} mt={4}>
              <Skeleton.BoxWithText width={160} height={200} />
              <Skeleton.BoxWithText width={160} height={200} />
              <Skeleton.BoxWithText width={160} height={200} />
              <Skeleton.BoxWithText width={160} height={200} />
            </Grid>
          </>
      }
    </Grid>
  )
}

export default memo(UsersChart);