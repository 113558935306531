import AgGrid from "src/components/ag-grid";
import { ObservationColDef } from "../colDef";
import { RowClickedEvent } from "ag-grid-community";
import { CORE_API } from "src/constants/api";

type Props = {
  observationIds: string[] | null,
  onRowClicked: (rowData: any) => void;
  ignoreData?: string[];
}

const DataGrid: React.FC<Props> = ({
  observationIds,
  onRowClicked,
  ignoreData
}) => {
  const handleRowClick = (event: RowClickedEvent<any>) => {
    const { data } = event;
    onRowClicked(data);
  }

  return (
    <div className="ag-theme-alpine" style={{ height: "550px", width: "95%", margin: "auto" }}>
      <AgGrid
        colDef={ObservationColDef}
        ids={observationIds}
        idField="id"
        baseUrl={`${CORE_API}/observations`}
        pagination
        paginationPageSize={10}
        filterUrl=""
        onRowClicked={handleRowClick}
        ignoreData={ignoreData}
      />
    </div>
  )
}

export default DataGrid;