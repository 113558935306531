import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DatePicker } from "src/components";
import dayjs from "dayjs";
import { Option, Roles } from "src/constants/interfaces";
import { Collapsibles, getFieldColor } from "../sections/FilterSection";
import {
  fieldLegends,
  WeatherIconRanges,
  WindDirectionanges,
} from "../constant";
import {
  WeatherIcon,
  WindDirectionIconMapping,
} from "src/constants/WordMapping";
import UnknownIcon from "src/assets/weatherIcons/unknown.png";
import { getFuturePredictionData } from "src/services/crop.service";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import Select, { SingleValue } from "react-select";
import { DropDownProps } from "./CropFilterBusinessDashboard";
import { GraphType } from "..";

type Props = {
  checkboxOptions: any;
  closeRestCollapsiblesExcept: (x: Collapsibles) => void;
  handleFilterUpdate: (x: any) => void;
  isVisible: boolean;
  setButtonDisabled: any;
  setCropData: (x: any) => void;
  setIndexData: (x: any) => void;
  setMapsDate: (x: any) => void;
  setGraphType: (x: GraphType) => void;
  setGraphSubType: (x: string) => void;
};

const sourceOptions: Option[] = [
  {
    value: "ibm",
    label: "IBM",
  },
  {
    value: "accuweather",
    label: "Accuweather",
  },
  {
    value: "climacell",
    label: "Climacell",
  },
  {
    value: "fyllo",
    label: "Fyllo",
  },
  {
    value: "meteomatics_ecmwf_ifs",
    label: "Meteomatics Ecmwf Ifs",
  },
  {
    value: "meteomatics_ncep_gfs",
    label: "Meteomatics Ncep Gfs",
  },
  {
    value: "combined",
    label: "combined",
  },
];

const FuturePredictions: React.FC<Props> = (props): JSX.Element => {
  const {
    checkboxOptions,
    setMapsDate,
    setIndexData,
    setCropData,
    handleFilterUpdate,
    closeRestCollapsiblesExcept,
    isVisible,
    setGraphSubType,
    setGraphType
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const roles = JSON.parse(localStorage.getItem("roles")!);
  let allowedRoles = [Roles.ADMIN.toString(), Roles.SUPPORT.toString()];
  let hasPermission = roles?.find((role: string) =>
    allowedRoles?.includes(role)
  );

  const [futurePredictionSensors, setFuturePredictionSensors] =
    useState<string>("");
  const [futurePredictionSensorsType, setFuturePredictionSensorsType] =
    useState<string>("");
  const [futurePredictionSource, setFuturePredictionSource] = useState<Option>({
    label: "Fyllo",
    value: "fyllo",
  });
  const [futurePredictionDate, setfuturePredictionDate] = useState<Date | null>(
    dayjs(new Date()).add(1, "days").toDate()
  );

  const handleSensorsChange = (
    updatedFuturePredictionSensors: string,
    sensorType: string
  ) => {
    setFuturePredictionSensors(updatedFuturePredictionSensors);
    setFuturePredictionSensorsType(sensorType);
  };

  const date = useRef<any>(null);
  useEffect(() => {
    if (!dayjs(date.current).isSame(dayjs(futurePredictionDate))) {
      date.current = futurePredictionDate;
      showResults();
    }
  }, [futurePredictionDate]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    label: string,
    index: number,
    i: number,
    type: string
  ) => {
    checkboxOptions.map((checkboxLabel: any, index: number) => {
      checkboxLabel.field.map((value: any) => {
        value.isChecked = false;
      });
      checkboxLabel.isChecked = false;
    });
    if (event.target.checked) {
      setGraphSubType(checkboxOptions[index].value)
      setGraphType('future')
      handleSensorsChange(label, type);
      if (i === -1) {
        checkboxOptions[index].isChecked = true;
      } else {
        checkboxOptions[index].field[i].isChecked = true;
      }
    } else {
      handleSensorsChange("", "");
    }
  };
  useEffect(() => {
    showResults();
  }, [isLoading]);

  const futurePredictionDateHandler = (newDate: Date | null): void => {
    setfuturePredictionDate(newDate);
  };

  const showResults = () => {
    if (futurePredictionSensors.length > 0) {
      setMapsDate(dayjs(futurePredictionDate).format("DD/MM/YYYY").toString());
      handleFilterUpdate(futurePredictionSensors);
      setIndexData([]);
      getFuturePredictionData(
        futurePredictionSensors,
        dayjs(futurePredictionDate).subtract(1, "day").format('YYYY-MM-DD').toString(),
        futurePredictionSource.value as string
      ).then((res: any) => {
        const noMinMax: string[] = [
          "rainFall",
          "dayLength",
          "cloudCover",
          "weatherIcon",
        ];
        let filterData;
        if (futurePredictionSensors === "dayLength") {
          res = res?.map((val: any) => {
            return {
              dayLength: {
                value: Math.round(val[futurePredictionSensors].value / 3600),
              },
              location: val.location,
              plotId: val.location?.plotId || "",
            };
          });
        }
        if (noMinMax.includes(futurePredictionSensors)) {
          filterData = res
            .filter(
              (val: any) => val[futurePredictionSensors] && val["location"]
            )
            .map((val: any) => ({
              color: getFieldColor(
                val[futurePredictionSensors].value,
                futurePredictionSensors
              ),
              value: val[futurePredictionSensors].value,
              location: {
                lat: val.location.geolocation.coordinates[1],
                lng: val.location.geolocation.coordinates[0],
              },
              plotId: val.location.plotId || "",
            }));
        } else {
          filterData = res
            .filter(
              (val: any) => val[futurePredictionSensors] && val["location"]
            )
            .map((val: any) => ({
              color: getFieldColor(
                val[futurePredictionSensors][futurePredictionSensorsType].value,
                futurePredictionSensors
              ),
              value:
                val[futurePredictionSensors][futurePredictionSensorsType].value,
              location: {
                lat: val.location.geolocation.coordinates[1],
                lng: val.location.geolocation.coordinates[0],
              },
              plotId: val.location.plotId || "",
            }));
        }
        if (filterData.length > 0) {
          if (futurePredictionSensors === "weatherIcon") {
            filterData = filterData?.map((val: any) => {
              const temp: any = {
                name: WeatherIcon[val.value] || "Unknown",
                color: val.color,
                location: val.location,
                icon:
                  WeatherIconRanges.find(
                    (range) => range.name === WeatherIcon[val.value]
                  )?.icon || UnknownIcon,
                plotId: val.plotId,
              };

              if (!!val.plotId && val.plotId.length > 0) {
                temp.plotId = val.plotId;
              }

              return temp;
            });
          }
          if (futurePredictionSensors === "windDirection") {
            filterData = filterData?.map((val: any) => {
              const temp: any = {
                name: WindDirectionIconMapping[val.value],
                color: val.color,
                location: val.location,
                icon: WindDirectionanges.find(
                  (range) => range.name === WindDirectionIconMapping[val.value]
                )?.icon,
                plotId: val.plotId,
              };
              if (!!val.plotId && val.plotId.length > 0) {
                temp.plotId = val.plotId;
              }

              return temp;
            });
          }

          setCropData(filterData);
        } else setCropData([]);
        if (fieldLegends[futurePredictionSensors]) {
          setIndexData(fieldLegends[futurePredictionSensors]);
        }
      });
    }
  };

  const GridProps = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "1px",
  };

  return (
    <Grid my={3} boxShadow={2} borderRadius={3} px={2}>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        data-testid="dashboard-Future-Predictions"
      >
        <Typography
          letterSpacing={0.8}
          variant="overline"
          textTransform={"capitalize"}
          fontSize={20}

        >
          Future Predictions
        </Typography>
        <IconButton
          onClick={() => closeRestCollapsiblesExcept(Collapsibles.futurePred)}
        >
          {isVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Grid>

      {isVisible && (
        <Grid pb={2}>
          {hasPermission && (
            <Grid {...GridProps}>
              <Typography variant="subtitle1" mr={3}>
                Source
              </Typography>
              <Select
                options={sourceOptions}
                value={futurePredictionSource}
                onChange={(selected: SingleValue<any>) => setFuturePredictionSource(selected)}
                {...DropDownProps}
              />
            </Grid>
          )}
          <FormGroup>
            {checkboxOptions.map((checkboxLabel: any, index: number) => (
              <Grid
                flexDirection={"row"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <FormControlLabel
                  style={{ width: checkboxLabel.field.length > 0 ? 150 : 300 }}
                  control={
                    <Checkbox
                      checked={checkboxLabel.isChecked}
                      onChange={(event) =>
                        handleChange(
                          event,
                          checkboxLabel.value,
                          index,
                          -1,
                          "avg"
                        )
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                    />
                  }
                  key={`future-prediction-${checkboxLabel.value}-${index}`}
                  label={
                    <Typography fontSize={13}>{checkboxLabel.label}</Typography>
                  }
                />
                {checkboxLabel.field.map((checkboxValue: any, i: number) => (
                  <FormControlLabel
                    key={`sensor-${checkboxValue.value}-${index}-${i}`}
                    control={
                      <Checkbox
                        checked={checkboxValue.isChecked}
                        onChange={(event) =>
                          handleChange(
                            event,
                            checkboxLabel.value,
                            index,
                            i,
                            checkboxValue.value
                          )
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                      />
                    }
                    label={
                      <Typography fontSize={13}>
                        {checkboxValue.label}
                      </Typography>
                    }
                  />
                ))}
              </Grid>
            ))}
          </FormGroup>

          <DatePicker
            onChange={futurePredictionDateHandler}
            value={futurePredictionDate}
            hasDateChangeIcon
            hasAutoplay
            futurePred
          />
          <Grid>
            <Button
              variant="outlined"
              fullWidth
              style={{ borderRadius: "20px" }}
              size="large"
              color="success"
              onClick={showResults}
            >
              Show Results
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FuturePredictions;
