import { downloadPlotWiseObservation, filterObservation } from "src/services/observation.service";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import DataGrid from "./components/DataGrid";
import Header from "../ObservationType/sections/Header";
import ObservationModal, { SetToastArgs, formatColumns } from "./components/ObservationModal";
import DropdownFilters, { Props as DropdownFilterProps } from "src/components/Filters/dropdown-filters";
import { generateLikeFilter } from "src/utils/helper";
import _ from "lodash";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { filterObservationTypes } from "src/services/observationType.service";
import weekday from "dayjs/plugin/weekday";
import updateLocale from "dayjs/plugin/updateLocale";
import { ToastAlert } from 'src/components';
import { ToastSeverity } from "src/components/ToastAlert/types";

dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.updateLocale('en', {
  weekStart: 5, // making friday as week's start day
})

type Props = {};

export const ToastMessages = {
  deleteSuccess: "Deleted Successfully!",
  deleteFailure: "Failed to delete",
  recalculateSuccess: "Recalculated Successfully",
  recalculateFailure: "Failed to recalculate"
}

const Observations = (props: Props) => {
  // stores observationIds for each filterId
  // value: keys length -> 0, filter not applied
  const [filteredObservationIds, setFilteredObservationIds] = useState<Record<string, string[] | null>>({});
  const [observationIds, setObservationIds] = useState<string[] | null>(null);
  const [observationTypes, setObservationTypes] = useState<Record<string, string>>();
  // const [error, setError] = useState<any>();
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const [appliedFilter, setAppliedFilter] = useState<Record<string, any>>({ and: [] });

  // modal states
  const [showObservationModal, setShowObservationModal] = useState<boolean>(false);
  const [selectedObservation, setSelectedObservation] = useState<any>();

  // toast alert
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastSeverity, setToastSeverity] = useState<ToastSeverity>('info');

  // state to delete in ag-grid
  const [ignoreObservations, setIgnoreObservation] = useState<string[]>([]);

  useEffect(() => {
    fetchObservationTypes();
  }, [])

  useEffect(() => {
    if (Object.keys(filteredObservationIds).length > 0) {
      const observationIds = Object.values(filteredObservationIds)
      .reduce((acc: string[] | null, arr) => (acc ?? [])
        .filter(value => (arr ?? []).includes(value)));
      setObservationIds(observationIds);
    } else {
      setObservationIds(null);
    }
  }, [filteredObservationIds]);

  const fetchObservationTypes = () => {
    const tempObservationTypes: Record<string, string> = {};
    filterObservationTypes({}, { id: true, name: true })
      .then(observationTypes => {
        observationTypes.forEach(observationType => {
          tempObservationTypes[observationType.id] = observationType.name;
        })

        setObservationTypes(tempObservationTypes);
      })
  }

  // grid handlers
  const onRowClicked = (rowData: any) => {
    setSelectedObservation(rowData);
    openObservationModal();
  }

  // modal handlers
  const openObservationModal = () => setShowObservationModal(true);
  const closeObservationModal = () => setShowObservationModal(false);

  /**
   * avoid repeating the keys
   */
  const appendToAppliedFilter = (payload: any) => {
    const tempAppliedFilter = _.cloneDeep(appliedFilter);
    const comingKeys: string[] = payload?.and?.map((data: any) => Object.keys(data)).flat() ?? [];

    tempAppliedFilter.and = tempAppliedFilter.and.filter((filter: any) => {
      const key = Object.keys(filter)[0];
      const flag = comingKeys.includes(key);
      return !flag;
    });

    tempAppliedFilter.and.push(...payload.and);
    setAppliedFilter(tempAppliedFilter);
    return tempAppliedFilter;
  }

  const Dropdownfilter: DropdownFilterProps = {
    filters: [
      {
        type: "crop",
        onChange(values, filterId) { },
        onSumbit(values, filterId) {
          if (!values.id) return;

          const payload: any = { and: [] };
          payload.and.push({ cropId: values.id });
          if (!!values.stage) {
            payload.and.push({ cropStage: values.stage });
          }

          appendToAppliedFilter(payload);

          filterObservation(payload, { id: true, observationDate: true })
            .then(res => {
              const ids = res.map((observation) => observation.id);
              setFilteredObservationIds(filteredObservationIds => ({ ...filteredObservationIds, [filterId ?? "crop"]: ids }));
            })
            .catch(error => {
              console.log("crop error", error);
              setFilteredObservationIds({});
            })
        },
        onDelete(filterId) {
          deleteFromAppliedFilter(["cropId", "cropStage"]);
          deleteFilter(filterId);
        },
        onReset(filterId) { },
      },
      {
        type: "location",
        dropdowns: ["state", "district", "sub-district"],
        onChange(values, filterId) { },
        onSumbit(values, filterId) {
          const payload = { and: [] } as any;
          if (values.state) {
            payload.and.push({ state: values.state as string });
          }

          if (values.district) {
            payload.and.push({ district: values.district as string });
          }

          appendToAppliedFilter(payload);

          filterObservation(payload, { id: true, plotId: true, observationDate: true })
            .then(res => {
              const ids = res.map((observation) => observation.id);
              setFilteredObservationIds(filteredObservationIds => ({ ...filteredObservationIds, [filterId ?? "location"]: ids }));
            });
        },
        onDelete(filterId) {
          console.log('delete location')
          deleteFromAppliedFilter(["state", "district", "subDistrict", "village"]);
          deleteFilter(filterId);
        },
        onReset(filterId) { },
      },
      {
        type: "date-range",
        defaultValue: {
          startDate: dayjs().startOf("week").toISOString(),
          endDate: dayjs().toISOString(),
        },
        onChange(values, filterId) { },
        onSumbit(values, filterId) {
          const payload = { and: [] } as any;
          if (!!values.startDate) {
            payload.and.push({ "observationDate": { "gte": dayjs(values.startDate as string).toISOString() } })
          }

          if (!!values.endDate) {
            payload.and.push({ "observationDate": { "lte": dayjs(values.endDate as string).toISOString() } })
          }

          appendToAppliedFilter(payload);

          filterObservation(payload, { id: true, observationDate: true })
            .then(res => {
              const ids = res.map((observation) => observation.id);
              setFilteredObservationIds(filteredObservationIds => ({ ...filteredObservationIds, [filterId ?? "date-range"]: ids }));
            });
        },
        onDelete(filterId) {
          deleteFromAppliedFilter(["startDate", "endDate"]);
          deleteFilter(filterId);
        },
        onReset(filterId) { },
      },
      {
        type: "plot",
        onChange(values, filterId) { },
        onSumbit(values, filterId) {
          const payload = { and: [] } as any;
          if (values.id) {
            payload.and.push({ plotId: generateLikeFilter(values.id as string) });
          }

          if (values.tag) {
            payload.and.push({ tag: generateLikeFilter(values.tag as string) });
          }

          if (values.servicedBy) {
            payload.and.push({ servicedBy: generateLikeFilter(values.servicedBy as string) });
          }

          appendToAppliedFilter(payload);

          filterObservation(payload, { id: true, observationDate: true })
            .then(res => {
              const ids = res.map((observation) => observation.id);
              setFilteredObservationIds(filteredObservationIds => ({ ...filteredObservationIds, [filterId ?? "plot"]: ids }));
            });
        },
        onDelete(filterId) {
          deleteFromAppliedFilter(["plotId", "tag", "servicedBy"]);
          deleteFilter(filterId);
        },
        onReset(filterId) { },
      },
    ],
    supportmultipleFilters: true,
    supportMultipleInstanceOfSameFilter: false
  }

  const deleteFilter = (filterId: string | undefined) => {
    setFilteredObservationIds(prevFilteredObservationIds => {
      const newFilteredObservationIds = { ...prevFilteredObservationIds };

      if (typeof filterId === 'string') {
        delete newFilteredObservationIds[filterId]; // Remove the filter by ID
      } else if (typeof filterId === 'number') {
        const filteredObservations = prevFilteredObservationIds[filterId] || [];
        newFilteredObservationIds[filterId] = filteredObservations.filter(id => id !== filterId);
      } else {
        console.error("Invalid filterId type. Please use a string or a number.");
      }

      return newFilteredObservationIds;
    });
  }

  /**
   * @method delete removed filters from `AppliedFilter` state
   * @param keys that were passed from the payload on filter change/submit
   */
  const deleteFromAppliedFilter = (keys: string[]) => {
    const tempAppliedFilter = _.cloneDeep(appliedFilter);
    const deletedFilterAnd = (tempAppliedFilter?.and ?? []).filter((filter: any) => !keys.includes(Object.keys(filter)[0]));

    const updatedAppliedFilter = { ...tempAppliedFilter, and: deletedFilterAnd };

    setAppliedFilter(updatedAppliedFilter);
  }

  const downloadObservations = async () => {
    // download observations of max 10 plots, and download in DESC order of observation date
    if (!observationIds || observationIds?.length === 0) {
      alert("please apply some filter");
      return;
    }

    const observationDetails = await downloadPlotWiseObservation(appliedFilter);
    downloadData(observationDetails as any);
  }

  const downloadData = (data: Record<string, any[]>) => {
    const workbook = XLSX.utils.book_new();

    Object.entries(data).forEach(([plotId, observations]: [string, any[]]) => {
      const finalData: any[] = [];
      
      observations.forEach(observation => {
        const formattedData = formatColumns(observation);

        // treat the columns as values as well
        const fixedColumns = [
          "Observation Date",
          "Crop",
          "Disease/Pest",
          "Observation Type",
          "Probability",
          "Model Prediction",
          "Risk Level",
          "Model Risk Level",
          "Spray Date",
          "Spray Details"
        ];
        const columns = [
          ...fixedColumns,
          ...Object.keys(formattedData[0])
        ]

        const excelData = formattedData.map((data: any, index: number) => {
          // display crop and disease only once
          if (index === 0) {
            return [
              dayjs(observation.created_date).format("DD/MM/YYYY"),
              observation.cropId,
              observation.diseasePestId,
              observationTypes![observation.observationTypeId],
              Number(observation.probability).toFixed(2),
              Number(observation.modelPrediction).toFixed(2),
              observation.riskLevel,
              observation.modelRiskLevel,
              !!observation.previousSprayDate ?  dayjs(observation.previousSprayDate).format("DD/MM/YYYY") : '-',
              observation.previousSpray ?? '-',
              ...Object.values(data)
            ];
          } else {
            return [
              ...Array.from(fixedColumns).map(() => null),
              ...Object.values(data)
            ]
          }
        })

        finalData.push(columns, ...excelData);
        Array.from({ length: 5 }).forEach(() => finalData.push([])); // gap in row
      })

      const worksheet = XLSX.utils.aoa_to_sheet(finalData);
      XLSX.utils.book_append_sheet(workbook, worksheet, plotId.slice(0, 30));
    })

    const appliedFiltersString = generateFileName();

    const fileName = `${appliedFiltersString}.xlsx`;

    XLSX.writeFile(workbook, fileName, { cellStyles: true });
  }

  const generateFileName = () => {
    return dayjs().format("DD/MM/YYYY");
  }

  const setToast = (args: SetToastArgs) => {
    setIsToastOpen(args.isOpen);
    setToastSeverity(args.severity);
    setToastMessage(args.message);

    if (args.message === ToastMessages.deleteSuccess) {
      setIgnoreObservation(ignore => [...ignore, selectedObservation.id]);
    }
  }

  return (
    <Grid>
      <Header title="Observations" buttonTitle="Download" onButtonClick={downloadObservations} />
      <Grid style={{ width: "90%", margin: "10px auto" }}>
        <DropdownFilters {...Dropdownfilter} />
      </Grid>

      <Grid p={2}>
        <DataGrid observationIds={observationIds} ignoreData={ignoreObservations} onRowClicked={onRowClicked} />
      </Grid>

      {
        !!selectedObservation && <ObservationModal
          open={showObservationModal}
          data={selectedObservation}
          onClose={closeObservationModal}
          setToast={setToast}
        />
      }

      <ToastAlert
        message={toastMessage}
        severity={toastSeverity}
        duration={3000}
        setOpen={setIsToastOpen}
        open={isToastOpen}
      />
    </Grid>
  );
};

export default Observations;