import { InventoryReportResponse, InventoryReport as TInventoryReport, getInventoryItems, getInventoryLocations, getInventoryReport } from 'src/services/inventory.service';
import React, { useEffect, useRef, useState } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import { DatePicker } from 'src/components';
import dayjs from 'dayjs';
import LocationFilter, {Location} from 'src/components/Filters/location.filter';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { Button, Card, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Box, Tab, Tabs } from '@mui/material';
import { KeyValues } from 'src/constants/interfaces';



const InventoryReport = () => {
  const stockGridRef = useRef<AgGridReact>(null);
  const ordersGridRef = useRef<AgGridReact>(null);
 
  const [inventoryItems, setInventoryItems] = useState<InventoryState>({
    options: [],
    isLoading: false,
    error: "",
    selected: null
  });
  const [inventoryDict, setInventoryDict] = useState<{ [key: string]: string }>({});
  const [inventoryLocation, setInventoryLocation] = useState<Location>();
  const [startDate, setStartDate] = useState<Date | null>(dayjs().startOf("month").toDate());
  const [fetchingReport, setFetchingReport] = useState<boolean>(false);
  const [reportData, setReportData] = useState<InventoryReportResponse>({});

  useEffect(() => {
    fetchInventoryItems();
    handleStartDateChange(null);
  }, []);



  /**
   * Api Calls
   */
  const inventoryNeeds = ["nero_single", "nero_double", "kairo"];
  const fetchInventoryItems = async () => {
    try {
      setInventoryItems((item) => ({ ...item, isLoading: true }))
      
      let inventoryItemsResponse: InventoryItem[] = await getInventoryItems("name", inventoryNeeds);
      if(!!inventoryItemsResponse && Array.isArray(inventoryItemsResponse)) {
        const options: DropDownOptions = inventoryItemsResponse.map((item) => ({ label: item.name, value: item.id }));
        const newInventoryDict: { [key: string]: string } = {};


        inventoryItemsResponse.forEach((item) => {
          newInventoryDict[item.name] = item.id;
        });
        setInventoryDict(newInventoryDict);

        setInventoryItems({
          options,
          isLoading: false,
          error: "",
          selected: null
        });
      }
    } catch(error) {
      setInventoryItems({
        options: [],
        isLoading: false,
        error,
        selected: null
      })
    } 
  };

  
  
  const fetchReport = async () => {
    const hasItem = !!inventoryItems.selected;

    const hasLocation = Object.values(inventoryLocation ?? {}).some((val) => val.length > 0);
    const hasDate = !!startDate;

      setFetchingReport(true);

      try {
        let locationIds: [] = [];
        if (hasLocation) {
          const ilocations = await getInventoryLocations(inventoryLocation as Location);
          locationIds = ilocations.map((location: any) => location.id);
        }

        try {
          const data = await getInventoryReport({
            inventoryLocationId: locationIds,
            startTime: dayjs(startDate).toString(),
            inventoryItemId: inventoryItems.selected?.value!
          });
          setReportData(data);
        } catch (error) {
          console.log("Error in fetching Inventory Report: ", error);
          setReportData({});
        }

      } catch (error) {
        console.log("Error in fetching data");
        console.error(error);
      } finally {
        setFetchingReport(false);
      }
    
  };

  type ItemReportState = {
    [key: string]: {
      inventoryReport?: TInventoryReport;
      distributorReport?: DistributorReport[];
    };
  };

  const initialItemReportState: ItemReportState = {};
    inventoryNeeds.forEach((item) => {
      initialItemReportState[inventoryDict[item]] = {};
  });
  
  const [itemReport, setItemReport] = useState<ItemReportState>(initialItemReportState);


  const alignData = () => {
    Object.keys(reportData).forEach((item) => {
      try{
        var {summary, locations} = reportData[item];
        const distributorReports = Object.keys(locations).map((id) => {
          const { name, ...report } = locations[id];
          return {
            id,
            name,
            ...report
          } as DistributorReport;
        });

        setItemReport((prevItemReport) => ({
          ...prevItemReport,
          [item]: {
            inventoryReport: summary,
            distributorReport: distributorReports
          }
        }));
      } catch(error){
        console.log("Error in aligning: ", error);
      }

    });

    
  }

  useEffect(() => {
    if(Object.keys(reportData).length > 0)
    {
      alignData();
    }
  }, [reportData])

      

  /**
   * Form handlers
   */
  const handleInventoryItemDropdown = (e: DropDownOption) => {
    updateSelectedInventoryItems(e);
  }

  const handleInventoryLocationDropdown = (e: Location) => {
    setInventoryLocation(e);
  }

  const handleStartDateChange = (startDate: Date | null) => {
    setStartDate(startDate);
  }

  /**
   * State setters
   */
  const updateSelectedInventoryItems = (selected: DropDownOption) => {
    setInventoryItems(items => ({ ...items, selected }));
  }


  const distributorReportGridProps: AgGridReactProps = {
    animateRows: true,
    domLayout: "autoHeight",
    pagination: true,
    paginationPageSize: 15
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const tabItems: { [key: number]: string } = {};

  
  Object.keys(inventoryDict).forEach((item, index) => {
    tabItems[index] = inventoryDict[item];
  });
  
  function TabPanel(props: TabPanelProps){
    
    const {children, index, value, ...other} = props;
    
    return(
      <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
    );
    
  }

  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };



  const ReportContent = ({ item }: { item: string }) => {

    if (Object.keys(itemReport).length > 1 || !itemReport.hasOwnProperty('undefined')) {
      if (itemReport.hasOwnProperty(item)) {
        const inventoryReport = itemReport[item].inventoryReport!;
        const distributorReport = itemReport[item].distributorReport!;
        return (
          <section style={{ padding: "0px 10px" }}>
            {!!inventoryReport && (
              <Grid>
                <Typography variant="h5" mt={5}> Summary </Typography>
                <Grid container>
                  <Grid p={3} item>
                    <Typography variant='subtitle2' fontSize={16} fontWeight={500}>
                      Orders
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody style={{ display: "flex", gap: "15px" }} >
                          <TableRowRenderer title='Completed' value={inventoryReport.completedOrders} />
                          <TableRowRenderer title='Pending' value={inventoryReport.openOrders} />
                          <TableRowRenderer title='All Orders' value={inventoryReport.totalOrders} />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid p={3} item>
                    <Typography variant='subtitle2' fontSize={16} fontWeight={500}>
                      Stock
                    </Typography>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody style={{ display: "flex", gap: "15px" }}>
                          <TableRowRenderer title='In Transit' value={inventoryReport.intransitOrders} />
                          <TableRowRenderer title='Stock' value={inventoryReport.inventoryStocks} />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Typography display={distributorReport.length > 0 ? "block" : "none"} variant='h6'> Channel Partners </Typography>
            <Grid display={distributorReport.length > 0 ? "flex" : "none"} p={1} justifyContent={"space-between"} >
              <div style={{ width: "55%" }}>
                <Typography variant='subtitle2' fontSize={16} fontWeight={500}>
                  Orders
                </Typography>
                <AgGridReact
                  ref={ordersGridRef}
                  columnDefs={reportOrdersColDef}
                  {...distributorReportGridProps}
                  rowData={itemReport[item].distributorReport}

                />
              </div>
              <div style={{ width: "40%" }}>
                <Typography variant='subtitle2' fontSize={16} fontWeight={500}>
                  Stock
                </Typography>
                <AgGridReact
                  ref={stockGridRef}
                  columnDefs={reportStockColDef}
                  {...distributorReportGridProps}
                  rowData={itemReport[item].distributorReport}
                />
              </div>
            </Grid>
          </section>
        );
      }
      else {
        return (<></>);
      }

    }
    else {
      return (<></>);
    }
  }


  return (
    <div style={{ padding: "10px 10px" }}>
      {/* report filters */}
      <section style={{ padding: "0px 20px" }}>
        <div style={{ margin: "20px 0px" }}>
          <LocationFilter
            filterId={"inventory-report-location-filter"}
            handleFilterDataChange={() => { }}
            getLocation={handleInventoryLocationDropdown}
            getLocationOnly
          />
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "10px 0px" }}>
          <InventoryReportFilter
            label='Inventory Items'
            inventoryState={inventoryItems}
            handleChange={handleInventoryItemDropdown}
            width='50%'
          />

          <DatePicker
            value={startDate}
            onChange={handleStartDateChange}
            hasDateChangeIcon
            label='Start Date'
            display='block'
            labelStyle={{
              fontSize: "14px",
              fontWeight: "500"
            }}
          />

          <Button
            variant="outlined"
            style={{ borderRadius: "20px" }}
            color="success"
            disabled={fetchingReport}
            onClick={() => fetchReport()}
          >
            Show Results
          </Button>
        </div>

      </section>


      {
        Object.keys(reportData).length > 1 && !(!!inventoryItems.selected) && (
          <>
            <Box sx={{ width: '100%' }}>

              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">

                  {Object.keys(inventoryDict).map((item, index) => (
                    <Tab key={index} label={item} {...a11yProps(index)} />
                  ))}
                </Tabs>
              </Box>

              <TabPanel value={tabValue} index={0}>
                <ReportContent item={tabItems[0]} />
              </TabPanel>

              <TabPanel value={tabValue} index={1} >
                <ReportContent item={tabItems[1]} />
              </TabPanel>

              <TabPanel value={tabValue} index={2} >
                <ReportContent item={tabItems[2]} />
              </TabPanel>


            </Box>
          </>
        )

      }
      {/* grid section */}
      {
        !fetchingReport && !!inventoryItems.selected && (

          <>

            {/* add a tab format with each tab for each section */}
            {(Object.keys(itemReport).length > 1 || !itemReport.hasOwnProperty('undefined')) && itemReport.hasOwnProperty(inventoryItems.selected.value) && <ReportContent item={inventoryItems.selected.value} />}
          </>
        )
      }

    </div>
  )

}

const InventoryReportFilter: React.FC<InventoryReportFilterProps> = (props) => {
  const { label, inventoryState, handleChange, width } = props;

  return (
    <div style={{ width: width ? width : "300px" }}>
      <p style={{ fontWeight: "500", fontSize: "14.5px" }}>{label}</p>
      {
        !inventoryState.error ? (
          <Select
            isLoading={inventoryState.isLoading}
            name={"Inventory Locations"}
            options={inventoryState.options}
            onChange={handleChange}
            value={inventoryState.selected}
            isClearable
          />
        ) : (
          <p> Something went wrong </p>
        )
      }
    </div>
  )
}



const TableRowRenderer: React.FC<{ title: string, value: string | number }> = ({ title, value }) => {
  return (
    <TableRow>
      <TableCell>{title}: </TableCell>
      <TableCell style={{ paddingLeft: 0 }} >{value}</TableCell>
    </TableRow>
  )
}

export default InventoryReport

// types
type InventoryItem = {
  created_date: string;
  id: string;
  name: string;
  description: string;
}

type InventoryReportFilterProps = {
  label: string;
  inventoryState: InventoryState,
  handleChange: (e: DropDownOption) => void,
  width?: string;
}

type DropDownOptions = MultiValue<{ label: string, value: string }>;
type DropDownOption = SingleValue<{ label: string, value: string }> | null;

type InventoryState = {
  options: DropDownOptions
  isLoading: boolean;
  // error: string | { message: string };
  error: any;
  selected: DropDownOption
};

type DistributorReport = TInventoryReport & {
  id: string;
  name: string;
}

// col def
const reportStockColDef: (ColDef | ColGroupDef | KeyValues)[] = [
  {
    headerName: 'Name',
    field: 'name',
    resizable: true,
  },
  {
    headerName: "In Transit",
    field: "intransitOrders",
    resizable: true,
  },
  {
    headerName: "Stock",
    field: "inventoryStocks",
    resizable: true,
  }
]

const reportOrdersColDef: (ColDef)[] = [
  {
    headerName: 'Name',
    field: 'name',
    resizable: true,
  },
  {
    headerName: "All Orders",
    field: "totalOrders",
    resizable: true,
  },
  {
    headerName: "Completed",
    field: "completedOrders",
    resizable: true,
  },
  {
    headerName: "Pending",
    field: "openOrders",
    resizable: true,
  }
]

