import { removeUnderScores } from "src/utils/helper";
import moment from "moment";
import {
  TDownloadRegionPestDiseasePredictionResponse,
  TRiskLevel,
} from "src/services/region.service";
import * as XLSX from "xlsx";

type TExcelRow = {
  Date: string;
  Crop: string;
  ProblemType: string;
  Problem: string;
  Extent: string | number;
  Color: string;
  Village: string;
  "Sub-District": string;
  District: string;
  State: string;
  DeviceCount: number;
};

type TExcelData = Array<TExcelRow>;

type TRiskColor = "Gray" | "Green" | "Yellow" | "Red";

const riskLevelToColorMapping: Record<TRiskLevel, TRiskColor> = {
  NIL: "Gray",
  LOW: "Green",
  MEDIUM: "Yellow",
  HIGH: "Red",
};

export const getExcelData = (
  data: Array<TDownloadRegionPestDiseasePredictionResponse>
) => {
  const wb = XLSX.utils.book_new();
  const excelData: TExcelData = generateExcelData(data);
  const ws = XLSX.utils.json_to_sheet(excelData);

  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, "data.xlsx");
};

const generateExcelData = (
  data: Array<TDownloadRegionPestDiseasePredictionResponse>
): TExcelData => {
  const excelData: TExcelData = data.map((dt): TExcelRow => {
    const { date, cropId, type, typeId, probability, region, riskLevel } = dt;

    const { district, state, subDistrict, fylloWeatherPlotCount, village } =
      region;

    const color = riskLevelToColorMapping[riskLevel];

    return {
      Date: moment(date).format("LL"),
      Village: village,
      "Sub-District": subDistrict,
      District: district,
      State: state,
      Crop: removeUnderScores(cropId, "capitalize") as string,
      ProblemType: removeUnderScores(type, "capitalize") as string,
      Problem: removeUnderScores(typeId, "capitalize") as string,
      Color: color,
      Extent: probability.toFixed(2),
      DeviceCount: fylloWeatherPlotCount ? fylloWeatherPlotCount : 0,
    };
  });

  return excelData;
};
