import xlsx from "json-as-xlsx";
import { getHeaders } from "./helper";
import moment from "moment";

let callback = function (sheet) {
  console.log("Download complete:", sheet);
};
export const saveToXlsx = (dataObj, fileName = '', isDaily=false) => {
  const { data } = dataObj
  let sheetData = [
    {
      sheet: "Data",
      columns: getHeaders(data[0]),
      content: data?.map(d=>{
        return {
         ...d,
          timestamp: moment(d.timestamp).format(isDaily?"MMMM Do YYYY":"MMMM Do YYYY, h A")
        }
      })
    },
  ];

  let settings = {
    fileName, // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
  };

  xlsx(sheetData, settings, callback);
}