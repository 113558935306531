import {
  FilterForm,
} from './interfaces'

export const filter = {
  "where": {
    "and": [
      {
        "area":
          { "gt": 0 }
      },
      {
        "or": [
          {
            "plotId":
              { "like": ".*.*", "options": "i" }
          },
          {
            "farmId":
              { "like": ".*.*", "options": "i" }
          },
          {
            "name":
              { "like": ".*.*", "options": "i" }
          },
          {
            "place":
              { "like": ".*.*", "options": "i" }
          },
          {
            "taluka":
              { "like": ".*.*", "options": "i" }
          },
          {
            "district":
              { "like": ".*.*", "options": "i" }
          },
          {
            "farmUserId":
              { "like": ".*.*", "options": "i" }
          },
        ]
      }
    ]
  },
  "include": ['farm', 'farmUser'],
  "order": "created_date DESC",
  // "limit": 20,
  "skip": 0
}

export const filter2 = { "where": { "name": "Anushka Plot" }, "include": ["farm", "farmUser", "device", "plotStatus", "cropSown"] }

// const filter2 = { "where": { "name": "Anushka Plot" }, "include": ["cropsSown", "farm", "farmUser", "device", "plotStatus"] }

export const filters = [
  { label: 'Crop', value: 'crop' },
  { label: 'Device', value: 'device' },
  { label: 'Plot', value: 'plot' },
  { label: 'Farm', value: 'farm' },
  { label: 'Farm user', value: 'farmUser' },
  { label: 'Inactive Plots', value: 'inactive' },
  { label: 'Location', value: 'location' },
  { label: 'Outlier Plots', value: 'outlier' },
  { label: 'Sensors', value: 'sensors' }
]

export const filterFormJSON: FilterForm = {
  crop: {
    fields: [
      {
        id: "name",
        label: "Crop Name",
        placeholder: "crop name",
        type: "text",
        validationType: "string",
        value: "",
        validations: [],
      },
      {
        id: "activeStage",
        label: "Active Stage",
        placeholder: "Active Stage",
        type: "text",
        validationType: "string",
        value: "",
        validations: [],
      },
    ]
  },
  device: {
    fields: [
      {
        id: "deviceType",
        label: "Device Type",
        placeholder: "Device type",
        type: "text",
        validationType: "string",
        value: "",
        validations: [],
      },
      {
        id: "network_type",
        label: "Network Type",
        placeholder: "Network Type",
        type: "text",
        validationType: "string",
        value: "",
        validations: [],
      },
      {
        id: "imei",
        label: "Device IMEI",
        placeholder: "Device IMEI",
        type: "text",
        validationType: "string",
        value: "",
        validations: [],
      },
    ]
  },
  plot: {
    fields: [
      {
        id: "plotName",
        label: "Plot Name",
        placeholder: "Plot Name",
        type: "text",
        validationType: "string",
        value: "",
        validations: [],
      },
    ]
  },
  farm: {
    fields: [
      {
        id: "farmName",
        label: "Farm Name",
        placeholder: "Farm Name",
        type: "text",
        validationType: "string",
        value: "",
        validations: [],
      },
      {
        id: "farmPlace",
        label: "Farm Place",
        placeholder: "Farm Place",
        type: "text",
        validationType: "string",
        value: "",
        validations: [],
      },
    ]
  },
  farmUser: {
    fields: [
      {
        id: "farmUserName",
        label: "Name",
        placeholder: "",
        type: "text",
        validationType: "string",
        value: "",
        validations: [],
      },
      {
        id: "farmUserMobile",
        label: "Mobile",
        placeholder: "Moblie",
        type: "text",
        validationType: "",
        value: "",
        validations: [],
      },
      {
        id: "farmUserLang",
        label: "Language",
        placeholder: "Language",
        type: "multiselect",
        validationType: "",
        value: "",
        options: [
          {
            label: 'Hindi',
            value: 'hn'
          },
          {
            label: 'Kannada',
            value: 'kn'
          },
          {
            label: 'Marathi',
            value: 'mt'
          },
          {
            label: 'English',
            value: 'en'
          },
        ],
        validations: []
      },
    ]
  }
};


/*

checkbox, radio -> options: ['option1', 'option2']
select, multiselect -> options: [{label:'label1', value: 'value1'}]

*/ 