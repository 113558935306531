import { Grid } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ExporterFilters, PlotMap, ExporterTable } from 'src/components/'
import { KeyValues } from 'src/constants/interfaces'
import { isDashboardConditionsSatisfied, isLoggedIn, isOpenURL } from 'src/utils/helper';

const Exporter: React.FC = (): JSX.Element => {
  const [plotIds, setPlotIds] = useState<String[] | undefined>(undefined)
  const [cnt, setCnt] = useState<number>(0);
  const [showDashboard, setShowDashboard] = useState<boolean>(false);
  const ref: any = useRef(null)
  // map
  const [showMap, setShowMap] = useState<boolean>(false);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false)
  const [mapLocations, setMapLocations] = useState<KeyValues[]>()

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(! isLoggedIn() && !isOpenURL(window.location.pathname) ){
      navigate('/login', { replace: true, state: location })
    }
  }, [])

  useEffect(() => {
    setShowDashboard(isDashboardConditionsSatisfied(location.pathname, true))
  }, [location])

  useEffect(() => {
    setCnt(cnt + 1);
  }, [plotIds])

  const handlePlotIdChange = (newPlotIds: string[]) => {
    setPlotIds(newPlotIds)
  }

  const handleMapLoaded = (status: boolean) => {
    setMapLoaded(status)
  }

  const toggleShowMap = () => {
    setShowMap(!showMap)
  }

  const handleMapLocations = (newMapLocations: KeyValues[]) => {
    setMapLocations(newMapLocations)
  }

  return (
    <Grid container display={ showDashboard ? 'block' : 'none' } >
      {/* filters */}
      <Grid item xs={12} my={1}>
        <ExporterFilters handlePlotIdChange={handlePlotIdChange} />
      </Grid>

      {/* table & map */}
      <div style={{
        display: 'flex',
        width: '100%',
        paddingBottom: '20px'
      }}>
        <Grid item md={showMap ? 7 : 12} xs={12} p={0} ref = {ref}>
          <ExporterTable
            key={`exporter_table_key_${cnt}`}
            mapLoaded={mapLoaded}
            setMapLoaded={handleMapLoaded}
            plotIds={plotIds}
            setMapLocations={handleMapLocations}
            toggleMap={toggleShowMap}
          />
        </Grid>
        {showMap &&
          <Grid item mt={2} md={5} xs={12} pr={1}>
            <PlotMap
              mapLoaded={mapLoaded}
              mapLocations={mapLocations as KeyValues[]}
              height={Number(ref?.current?.clientHeight) + 60}
              polygonRegion={{}}
              handlePolygonClick={() => {}}
            />
          </Grid>}
      </div>
    </Grid>
  )
}

export default Exporter