import { ColDef, ICellRendererParams, ICellRendererComp } from "ag-grid-community";
import { DateRenderer } from "./components/GridRenderers";
import { NumberRenderer } from "src/utils/GridDataRenderer/NumberRenderer";

export const ObservationColDef: (ColDef)[] = [
  {
    headerName: 'Observation Date',
    field: 'observationDate',
    resizable: true,
    cellRenderer: DateRenderer
  },
  {
    headerName: 'Plot',
    field: 'plotId',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    headerName: 'Crop',
    field: 'cropId',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    headerName: 'Crop Stage',
    field: 'cropStage',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    headerName: 'Disease/Pest',
    field: 'diseasePestId',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    headerName: 'Probability',
    field: 'probability',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellRendererSelector: (params: ICellRendererParams<any>) => {
      const renderer = {
        component: NumberRenderer,
      };
      return renderer;
    },
  },
  {
    headerName: 'Model Prediction',
    field: 'modelPrediction',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellRendererSelector: (params: ICellRendererParams<any>) => {
      const renderer = {
        component: NumberRenderer,
      };
      return renderer;
    },
  },
  {
    headerName: 'Risk Level',
    field: 'riskLevel',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    headerName: 'Model Risk Level',
    field: 'modelRiskLevel',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    headerName: 'Created/Updated By',
    field: 'created_by',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cellRendererSelector: (params: ICellRendererParams<any>) => {
      const renderer = {
        component: UpdatedByCreatedByRenderer,
      };
      return renderer;
    } 
  },
]

export class UpdatedByCreatedByRenderer implements ICellRendererComp {
  eGui!: HTMLSpanElement;
  init(params: ICellRendererParams) {
    this.eGui = document.createElement('span');
    const renderData = params?.data?.updated_by ?? params?.data?.created_by;
    this.eGui.innerHTML += `<span style="font-size: 12px;"> ${renderData}</span>`;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}