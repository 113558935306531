import React, { useEffect, useRef, useState } from "react";
import { Tab } from "..";
import { ColDef, RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import { Grid, IconButton, Typography } from "@mui/material";
import { Download } from "@mui/icons-material";
import * as XLSX from "xlsx";

type Props = {
  activeTab: Tab;
  data: any[];
  loading: boolean;
  earlyIrrigationData?: any;
  noFertigationPlots?: any[]
};

const DataTable: React.FC<Props> = (props) => {
  const { activeTab, data, loading, earlyIrrigationData, noFertigationPlots } = props;
  const [colDef, setColDef] = useState<ColDef[]>([]);
  const [rowData, setRowData] = useState<any>([]);

  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    setColDef(activeTab === "irrigation" ? IrrigationColDef : AdherenceColDef);
  }, [activeTab]);

  useEffect(() => {
    setRowData(data ?? []);
  }, [data]);

  useEffect(() => {
    showLoading();
  }, [loading, gridRef]); // eslint-disable-line

  const showLoading = () => {
    if (loading && gridRef.current) {
      gridRef.current.api.showLoadingOverlay();
    } else if (gridRef.current) {
      gridRef.current.api?.hideOverlay();
    }
  };

  const handleRowClick = (event: RowClickedEvent<any>) => {
    const plotId = typeof event.data === "string" ? event.data : event.data.plotId;
    const newWindow = window.open(
      `/plots/${plotId}`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  const downloadData = () => {
    const columns = [
      "Date",
      "Plot Id",
      "Farm User Id",
      "RainFall(Last 3 day aggregated)"
    ];

    const rowData = earlyIrrigationData?.map((dt: any) => ([dt.date, dt.plotId, dt.farmUserId, dt.rainfall]));

    let excelData: any[] = [columns, ...rowData];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const fileName = `early-irrigation-${dayjs().format("DD/MM/YYYY")}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  if(!Array.isArray(data) || data.length === 0) return null;

  return (
    <Grid container pb={10}>
      <Grid item xs={12}>
        <div
          className="ag-theme-alpine"
          style={{ height: "550px", width: "95%", marginTop: 10 }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={loading ? undefined : rowData}
            columnDefs={colDef}
            pagination
            paginationPageSize={15}
            onRowClicked={handleRowClick}
          />
        </div>
      </Grid>
      {earlyIrrigationData && activeTab === "irrigation" && (
        <Grid item xs={12}>
          <div
            className="ag-theme-alpine"
            style={{ height: "550px", width: "95%", marginTop: 10 }}
          >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography variant="h6" gutterBottom>
                Early Irrigation
              </Typography>
              <IconButton
                size="medium"
                onClick={downloadData}
                disabled={!!!earlyIrrigationData}
              >
                <Download
                  fontSize="small"
                  color={!!earlyIrrigationData ? "success" : "disabled"}
                />
              </IconButton>
            </div>
            <AgGridReact
              columnDefs={EarlyIrrigationColDef}
              rowData={earlyIrrigationData}
              pagination
              paginationPageSize={15}
            />
          </div>
        </Grid>
      )}

      {
        activeTab === 'nutrient' && !!noFertigationPlots && (
          <Grid item xs={12}>
            <div
              className="ag-theme-alpine"
              style={{ height: "550px", width: "95%", marginTop: 10 }}
            >
              <Typography gutterBottom mt={3}>
                Plots with no fertigation report
              </Typography>
              <AgGridReact
                rowData={noFertigationPlots ?? []}
                columnDefs={NoFertigationColDef}
                pagination
                paginationPageSize={15}
                onRowClicked={handleRowClick}
              />
            </div>
          </Grid>
        )
      }
    </Grid>
  );
};

export default DataTable;

/**
 * ================== Col Defs ======================
 */

const AdherenceColDef: ColDef[] = [
  {
    headerName: "Plot",
    field: "plotId",
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    headerName: "Done Date",
    field: "doneDate",
    valueFormatter: (param: any) => {
      const doneDate = param.data?.doneDate;
      if (doneDate && dayjs(doneDate).isValid()) {
        return dayjs(doneDate).format("LLL");
      } else {
        return "";
      }
    },
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    minWidth: 200,
  },
  {
    headerName: "Suggested Date",
    field: "suggestedDate",
    valueFormatter: (param: any) => {
      const suggestedDate = param.data?.suggestedDate;
      if (suggestedDate && dayjs(suggestedDate).isValid()) {
        return dayjs(suggestedDate).format("LLL");
      } else {
        return "";
      }
    },
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    minWidth: 200,
  },
  {
    headerName: "Notes",
    field: "notes",
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    width: 500,
    flex: 1,
  },
  {
    headerName: "Suggested and done",
    field: "suggestedAndDone",
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    flex: 1,
  },
  {
    headerName: "Not suggested and done",
    field: "notSuggestedAndDone",
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    flex: 1,
  },
  {
    headerName: "Not done",
    field: "notDone",
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    flex: 1,
  },
];

const IrrigationColDef: ColDef[] = [
  {
    headerName: "Suggested Date",
    field: "suggestedDate",
    valueFormatter: (param: any) =>
      dayjs(param.data?.suggestedDate).format("LLL"),
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    headerName: "Plot",
    field: "plotId",
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    headerName: "Result",
    field: "result",
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    flex: 1,
  },
  {
    headerName: "Done Date",
    field: "doneDate",
    valueFormatter: (param: any) => {
      const doneDate = param.data?.doneDate;
      if (doneDate && dayjs(doneDate).isValid()) {
        return dayjs(doneDate).format("LLL");
      } else {
        return "";
      }
    },
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
];
const EarlyIrrigationColDef: ColDef[] = [
  {
    headerName: "Date",
    field: "date",
    valueFormatter: (param: any) => dayjs(param.data?.date).format("LLL"),
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    flex: 1
  },
  {
    headerName: "Plot",
    field: "plotId",
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    flex: 1
  },
  {
    headerName: "Farm User",
    field: "farmUserId",
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    flex: 1
  },
  {
    headerName: "Rainfall (Last 3 day aggregated)",
    field: "rainfall",
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    flex: 1
  },
];

const NoFertigationColDef = [
  {
    headerName: 'Plot Id',
    field: 'plotId',
    flex: 1,
  },
  {
    headerName: 'Plot Name',
    field: 'name',
    flex: 1,
  },
  {
    headerName: 'Farm Id',
    field: 'farmId',
    flex: 1,
  },
  {
    headerName: 'Farmuser Id',
    field: 'farmUserId',
    flex: 1,
  },
  {
    headerName: 'Device Type',
    field: 'device.deviceType',
    flex: 1,
  },
  {
    headerName: 'Crop',
    field: 'cropSown.name',
    flex: 1,
  },
];