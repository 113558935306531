import { Typography } from '@mui/material';
import React from 'react'

type Props = {
  title: string;
}

const Label: React.FC<Props> = ({ title }) => {
  return (
    <Typography>{title}</Typography>
  )
}

export default Label