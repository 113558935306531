import { Card, Grid, Typography } from "@mui/material";
import { KeyValues } from "../../constants/interfaces";
import { isArrayWithLength, getDate, sortByKey } from "../../utils/helper";

interface Props {
  quantityPredictions: KeyValues[];
}

const Notes = (props: Props): JSX.Element => {
  let { quantityPredictions } = props;
  if (isArrayWithLength(quantityPredictions)) {
    quantityPredictions = sortByKey(quantityPredictions, 'created_at', true)
    return (
      <Grid p={1} >
        {
          quantityPredictions.map((note, ind: number) => {
            const date = note.created_date as string;
            const notes = note.note ?? '';
            const value = Number(note.value as string).toFixed(2)
            return (
              <Card key={`quality_prediction_note_${ind}`} style={{
                padding: '10px',
                borderRadius: '10px',
                marginBottom: '10px'
              }}>
                <Grid item display={'flex'} justifyContent={'space-between'} >
                  <Typography fontWeight={600} fontSize={12} >{getDate(date, 'DD/MM/YYYY')}</Typography>
                  <Typography fontSize={12}>{getDate(date, 'h:mma')}</Typography>
                </Grid>
                <Typography fontSize={13}>{`Amount: ${value} tonnes/acre`}</Typography>
                <Typography fontSize={13}>{`${notes}`}</Typography>
                <Grid item display={'flex'} justifyContent={'flex-end'}>
                  <Typography variant='caption' fontSize={10} >Team Fyllo</Typography>
                </Grid>
              </Card>
            )
          })
        }
      </Grid>
    )
  }

  return (
    <Grid p={2} container justifyContent={'center'} >
      <Typography variant={'overline'} >No Notes</Typography>
    </Grid>
  )
}

export default Notes