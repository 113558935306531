import { DeleteOutline, Done } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import { FilterDataValue, FilterProps } from "../../constants/interfaces";
import {
  getAreas,
  getCountries,
  getDistricts,
  getPlots,
  getStates,
  getSubDistricts,
} from "../../services/location.service";
import { generateOptionsFromArray as generateOptions } from "src/utils/helper";
import { useLocation } from "react-router";
import { getFilteredRegionsCrop } from "src/services/region.service";

export interface Location {
  state: string;
  district?: string;
  subDistrict?: string;
  area?: string;
  country: string;
}

const LocationFilter: React.FC<FilterProps> = (
  props: FilterProps
): JSX.Element => {
  const {
    handleFilterDataChange,
    handleRemove,
    filterId,
    getLocation,
    allLocation,
    getLocationOnly,
    setRegionsToMap,
    setDynamicType,
    checked,
  } = props;

  const [regionsArr, setRegionsArr] = useState([]);
  const [isApplied, setIsApplied] = useState(false);
  const [isLocationSet, setIsLocationSet] = useState(true);

  // dropdown options
  const [states, setStates] = useState<MultiValue<any>>([]);
  const [districts, setDistricts] = useState<MultiValue<any>>([]);
  const [subDistricts, setSubdistricts] = useState<MultiValue<any>>([]);
  const [area, setArea] = useState<MultiValue<any>>([]);
  const [countries, setCountries] = useState<MultiValue<any>>([]);

  // selected locations
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedDistrict, setSelectedDistrict] = useState<string>("");
  const [selectedSubDistrict, setSelectedSubDistrict] = useState<string>("");
  const [selectedArea, setSelectedArea] = useState<string>("");
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  // select options
  const [selectedStateOption, setSelectedStateOption] = useState<any>(null);
  const [selectedCountryOption, setSelectedCountryOption] = useState<any>(null);
  const [selectedDistrictOption, setSelectedDistrictOption] =
    useState<any>(null);
  const [selectedSubDistrictOption, setSelectedSubDistrictOption] =
    useState<any>(null);
  const [selectedAreaOption, setSelectedAreaOption] = useState<any>(null);
  // loading states
  const [isCountryLoading, setIsCountryLoading] = useState<boolean>(true);
  const [isStateLoading, setIsStateLoading] = useState<boolean>(true);
  const [isDistrictLoading, setIsDistrictLoading] = useState<boolean>(false);
  const [isSubDistrictLoading, setIsSubDistrictLoading] =
    useState<boolean>(false);
  const [isAreaLoading, setIsAreaLoading] = useState<boolean>(false);
  const location = useLocation();

  const handleFitlerChange = (status: boolean, plotIds: FilterDataValue) => {
    handleFilterDataChange(filterId, status, plotIds);
    setIsApplied(status);
  };

  useEffect(() => {

    getCountries().then((res: {country: string}[]) => {
      setCountries(generateOptions(res.map((obj) => obj.country).sort()));
      setIsCountryLoading(false);
    })

    handleCountryChange("India");
  }, []);

  useEffect(() => {
    // if we just want get location
    if (getLocationOnly) onSubmit();
  }, [
    selectedAreaOption,
    selectedDistrictOption,
    selectedStateOption,
    selectedCountryOption,
    selectedSubDistrictOption,
  ]);


  const handleCountryChange = (selected: string) => {
    setSelectedCountry(selected);
    setSelectedCountryOption({
      label: selected,
      value: selected,
    });
    setIsStateLoading(true);
    getStates(selected).then((res: { state: string }[]) => {
      // unselect others
      unselectOptions(["state", "district", "subDistrict", "area"]);

      setStates(generateOptions(res.map((obj) => obj.state).sort()));
      setIsStateLoading(false);
    });
  };

  const handleStateChange = (selected: string) => {
    setSelectedState(selected);
    setSelectedStateOption({
      label: selected,
      value: selected,
    });
    setIsDistrictLoading(true);
    getDistricts(selected).then((res: { district: string }[]) => {
      // unselect others
      unselectOptions(["district", "subDistrict", "area"]);

      setDistricts(generateOptions(res.map((obj) => obj.district).sort()));
      setIsDistrictLoading(false);
    });
  };

  const handleDistrictChange = (selected: string) => {
    setSelectedDistrictOption({
      label: selected,
      value: selected,
    });
    setSelectedDistrict(selected);
    setIsSubDistrictLoading(true);
    getSubDistricts(selectedState, selected).then(
      (res: { subDistrict: string }[]) => {
        unselectOptions(["subDistrict", "area"]);
        setSubdistricts(
          generateOptions(res.map((obj) => obj.subDistrict).sort())
        );
        setIsSubDistrictLoading(false);
      }
    );
  };

  const handleSubDistrictChange = (selected: string) => {
    setSelectedSubDistrictOption({
      label: selected,
      value: selected,
    });
    setSelectedSubDistrict(selected);
    setIsAreaLoading(true);
    getAreas(selectedState, selectedDistrict, selected).then(
      (res: { area: string }[]) => {
        unselectOptions(["area"]);
        setArea(generateOptions(res.map((obj) => obj.area).sort()));
        setIsAreaLoading(false);
      }
    );
  };

  const handleAreaChange = (selected: string) => {
    setSelectedArea(selected);
    setSelectedAreaOption({
      label: selected,
      value: selected,
    });
  };

  const handleDropdownChange = (
    dropdown: string,
    selected: SingleValue<any>
  ) => {
    const value = selected?.value;
    switch (dropdown) {
      case "country":
        if(value) handleCountryChange(value);
        else{
          unselectOptions(["country", "state", "district", "subDistrict", "area"])
          setStates([]);
          setIsStateLoading(false);
        }
        break;

      case "state":
        if (value) handleStateChange(value);
        else {
          unselectOptions(["state", "district", "subDistrict", "area"]);
          setDistricts([]);
          setIsDistrictLoading(false);
        }
        break;
      case "district":
        if (value) handleDistrictChange(value);
        else {
          unselectOptions(["district", "subDistrict", "area"]);
          setSubdistricts([]);
          setIsSubDistrictLoading(false);
        }
        break;
      case "subDistrict":
        if (value) handleSubDistrictChange(value);
        else {
          setSelectedSubDistrict("");
          unselectOptions(["subDistrict", "area"]);
          setArea([]);
          setIsAreaLoading(false);
        }
        break;
      case "area":
        if (value) handleAreaChange(value);
        else {
          unselectOptions(["area"]);
        }
        break;
      default:
        return;
    }
  };

  const unselectOptions = (toUnselect: string[]) => {
    let cnt = 0;
    for (const field of toUnselect) {
      if (field === "country"){
        setSelectedCountry("");
        setSelectedCountryOption(null);
      }
      else if (field === "state") {
        setSelectedState("");
        setSelectedStateOption(null);
        cnt++;
      } else if (field === "district") {
        setSelectedDistrict("");
        setSelectedDistrictOption(null);
        if (cnt++ > 0) setDistricts([]);
      } else if (field === "subDistrict") {
        setSelectedSubDistrict("");
        setSelectedSubDistrictOption(null);
        if (cnt++ > 0) setSubdistricts([]);
      } else {
        setSelectedArea("");
        setSelectedAreaOption(null);
        if (cnt++ > 0) setArea([]);
      }
    }
  };

  const getAllRegionsCrop = async (Location: any, type: string) => {
    const res = await getFilteredRegionsCrop(Location, type);
    return res;
  };

  const onSubmit = async () => {


    let Location: any = {};
    let type: string = "";

    if (setRegionsToMap) {
      if(selectedCountryOption != null){
        Location.country = selectedCountryOption.value;
      }
      if (selectedStateOption != null) {
        Location.state = selectedStateOption.value;
      }
      if (selectedDistrictOption != null) {
        Location.district = selectedDistrictOption.value;
        type = "district";
      }
      if (selectedSubDistrictOption != null) {
        Location.subDistrict = selectedSubDistrictOption.value;
        type = "subDistrict";
      }
      if (selectedAreaOption != null) {
        Location.village = selectedAreaOption.value;
        type = "village";
      }
      if (setDynamicType && !type) {
        setRegionsToMap([]);
        setRegionsArr([]);
        return;
      }
      if (setDynamicType && type) {
        const res = await getAllRegionsCrop(Location, type);
        setRegionsToMap(res);
        setRegionsArr(res);
        return;
      }
      if (!type) {
        window.alert("Please Select atleast 1 District!");
        return;
      }

      const res = await getAllRegionsCrop(Location, checked ? "" : type);
      setRegionsToMap(res);
      setRegionsArr(res);
      return;
    }

    let payload: Location = {
      country: selectedCountry,
      state: selectedState,
    };

    if (selectedDistrict.length > 0) {
      payload.district = selectedDistrict;
    }

    if (selectedSubDistrict.length > 0) {
      payload.subDistrict = selectedSubDistrict;
    }

    if (selectedArea.length > 0) {
      payload.area = selectedArea;
    }

    if (!!getLocation) {
      getLocation(payload);
      return;
    }
    getPlots(payload).then((res) => {
      const plotIds = res.map((data: any) => data.plotId) as string[];
      handleFitlerChange(true, plotIds);
    });
  };

  const onReset = () => {
    handleFitlerChange(false, undefined);
  };

  const setOptions = (allLocation: string) => {
    setIsLocationSet(false);
    const locationsData = allLocation;
    let locaArr = locationsData.split("_");
    if (locaArr.length > 0) {
      locaArr = locaArr.reverse();
      setSelectedState(locaArr[0]);
      setSelectedStateOption({
        label: locaArr[0],
        value: locaArr[0],
      });

      setSelectedDistrict(locaArr[1]);
      setSelectedDistrictOption({
        label: locaArr[1],
        value: locaArr[1],
      });

      setSelectedSubDistrict(locaArr[2]);
      setSelectedSubDistrictOption({
        label: locaArr[2],
        value: locaArr[2],
      });

      setSelectedArea(locaArr[3]);
      setSelectedAreaOption({
        label: locaArr[3],
        value: locaArr[3],
      });
    }
  };

  const filterBackgroundColor = getLocationOnly ? "white" : isApplied ? "#B6F7BC" : "#E6E6E6"
  const labelArgs: any = getLocationOnly ? {
    fontWeight: 500,
    fontSize: 14
  } : {
    variant: "subtitle1", 
    fontWeight: 600,
    fontSize: 16
  }

  return (
    <>
      {isLocationSet && allLocation && setOptions(allLocation)}
      <Typography variant="subtitle2" mt={1}>
        Location
      </Typography>
      <div
        style={{
          backgroundColor: filterBackgroundColor,
          margin: "0px 0 15px 0",
          padding: "5px 10px",
          borderRadius: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >

        <Grid width={250} mr={1}>
          <Typography {...labelArgs}>
            Country
          </Typography>
          <Select
            options={countries}
            onChange={(selected: SingleValue<any>) =>
              handleDropdownChange("country", selected)
            }
            isSearchable={true}
            placeholder={"- select -"}
            isLoading={isCountryLoading}
            isDisabled={isCountryLoading}
            value={selectedCountryOption}
            isClearable
          />
        </Grid>

        <Grid width={250} mr={1}>
          <Typography {...labelArgs}>
            State
          </Typography>
          <Select
            options={states}
            onChange={(selected: SingleValue<any>) =>
              handleDropdownChange("state", selected)
            }
            isSearchable={true}
            placeholder={"- select -"}
            isLoading={isStateLoading}
            isDisabled={isStateLoading}
            value={selectedStateOption}
            isClearable
          />
        </Grid>

        <Grid width={250} mr={1}>
          <Typography {...labelArgs}>
            District
          </Typography>
          <Select
            options={districts}
            onChange={(selected: SingleValue<any>) =>
              handleDropdownChange("district", selected)
            }
            isSearchable={true}
            placeholder={"- select -"}
            isLoading={isDistrictLoading}
            isDisabled={selectedState?.length === 0 || isDistrictLoading}
            value={selectedDistrictOption}
            isClearable
          />
        </Grid>

        <Grid width={250} mr={1}>
          <Typography {...labelArgs}>
            Sub District
          </Typography>
          <Select
            options={subDistricts}
            onChange={(selected: SingleValue<any>) =>
              handleDropdownChange("subDistrict", selected)
            }
            isSearchable={true}
            placeholder={"- select -"}
            isLoading={isSubDistrictLoading}
            isDisabled={selectedDistrict?.length === 0 || isSubDistrictLoading}
            value={selectedSubDistrictOption}
            isClearable
          />
        </Grid>

        <Grid width={250} mr={1}>
          <Typography {...labelArgs}>
            Area
          </Typography>
          <Select
            options={area}
            onChange={(selected: SingleValue<any>) =>
              handleDropdownChange("area", selected)
            }
            isSearchable={true}
            placeholder={"- select -"}
            isLoading={isAreaLoading}
            isDisabled={selectedSubDistrict?.length === 0 || isAreaLoading}
            value={selectedAreaOption}
            isClearable
          />
        </Grid>

        <Grid display={getLocationOnly ? "none" : "block"}>
          <IconButton
            data-testid="check-btn-green"
            onClick={onSubmit}
            style={{ position: "relative", top: "12px" }}
          >
            <Done sx={{ border: 1, borderRadius: "100%" }} color="success" />
          </IconButton>
          <IconButton
            data-testid="delete-btn-red"
            onClick={() => handleRemove(filterId)}
            style={{ position: "relative", top: "12px" }}
          >
            <DeleteOutline
              style={{
                border: "1px solid red",
                borderRadius: "100%",
                padding: "1px",
              }}
              color="error"
            />
          </IconButton>
        </Grid>
      </div>
      {setRegionsToMap && (
        <div>
          <h4 style={{ color: "green" }}>
            {regionsArr.length} Regions are found!
          </h4>
        </div>
      )}
    </>
  );
};

export default memo(LocationFilter);
