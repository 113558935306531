import { Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { KeyValues, FilterDataValue, FilterProps } from '../../constants/interfaces'
import { getTagPlots } from "../../services/plot.service";
import FormikApp from '../DynamicForm'

const PlotFilter: React.FC<FilterProps> = (props: FilterProps): JSX.Element => {
    const { handleFilterDataChange, handleRemove, filterId } = props

    const fields = [
        {
            id: "tag",
            label: "Tag",
            placeholder: "Tag",
            type: "text",
            validationType: "string",
            value: '',
            validations: [],
        },
        {
            id: "servicedBy",
            label: "Serviced By",
            placeholder: "Serviced By",
            type: "text",
            validationType: "string",
            value: '',
            validations: [],
        }
    ]

    const [isApplied, setIsApplied] = useState(false)

    const handleFitlerChange = (status: boolean, plotIds: FilterDataValue) => {
        handleFilterDataChange(filterId, status, plotIds)
        setIsApplied(status)
    }

    const onSubmit = (values: KeyValues) => {
        const { tag, servicedBy } = values
        getTagPlots(tag as string, servicedBy as string)
            .then((res: KeyValues[]) => {
                const plotIds = res.map((plotId) => plotId.plotId) as string[];
                handleFitlerChange(true, plotIds)
            })
    }

    const onReset = () => {
        handleFitlerChange(false, undefined)
    }

    return (
        <>
            <Typography variant="subtitle2" mt={1}>Plots</Typography>
            <div style={{
                backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
                margin: '0px 0 15px 0',
                padding: '5px',
                borderRadius: '10px',
                display: 'flex',
                alignItems: 'center'
            }} >
                <FormikApp
                    formFields={fields}
                    onSubmit={(values: KeyValues) => onSubmit(values)}
                    onReset={() => { }}
                    filter={true}
                    showReset
                    removeField={() => handleRemove(filterId)}
                />
            </div>
        </>
    )
}

export default memo(PlotFilter);