import React, { useState } from 'react';
import {CardSection, ChartSection, Filters, Header} from './sections';
import { Grid } from '@mui/material';
import dayjs from 'dayjs';

const ObservationInsights: React.FC = () => {

  // filter states
  const [diseasePestIds, setDiseasePestIds] = useState<string[]>();
  const [startDate, setStartDate] = useState<Date>(dayjs().subtract(30, "days").toDate());
  const [endDate, setEndDate] = useState<Date>(dayjs().toDate());
  const [observationTypeId, setObservationTypeId] = useState<string>();

  /**
   * filters
   * - date range
   * - observation type
   * - disease=pest
   */

  // state updaters
  const updateDiseaseSelected = (selectedDiseasePest: string) => {
    setDiseasePestIds([selectedDiseasePest]);
  }

  const updateStartDate = (data: Record<string, Date>) => {
    const newDate = data.date;
    setStartDate(newDate);
  }

  const updateEndDate = (data: Record<string, Date>) => {
    const newDate = data.date;
    setEndDate(newDate);
  }

  const updateObservationTypeId = (data: string) => {
    setObservationTypeId(data);
  }

  // card click handlers
  const handleCardClick = (diseasePestId: string) => {
    setDiseasePestIds([diseasePestId]);
  }

  const handleCardDoubleClick = (observationId: string) => {
    console.log("observation id", observationId);
  }

  return (
    <Grid p={2}>
      {/* header -> plotId, cropId */}
      <Header />

      {/* filter section */}
      <Filters  
        startDate={startDate}
        endDate={endDate}
        updateDiseasePest={updateDiseaseSelected}
        updateEndDate={updateEndDate}
        updateStartDate={updateStartDate}
        updateObservationTypeId={updateObservationTypeId}
      />

      {/* cards, last 5-10 observations */}
      <CardSection 
        onClick={handleCardClick} 
        onDoubleClick={handleCardDoubleClick} 
      />

      {/* charts section */}
      <ChartSection 
        observationTypeId={observationTypeId!}
        diseasePestIds={diseasePestIds} 
        startDate={dayjs(startDate).toISOString()}
        endDate={dayjs(endDate).toISOString()}
      />
    </Grid>
  )
}

export default ObservationInsights