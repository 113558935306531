import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { postMaintenance, updateMaintenance } from "src/services/plot.service";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  getSearchList,
  getFarmIds,
  getPlotIds,
  fetchMaintenanceTypes,
  fetchSensorsTypes,
} from "../services/farm.service";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function ChildModal(props: any) {
  const {
    open2,
    handleClose2,
    label,
    farmUserId,
    farmId,
    setFarmUserId,
    setFarmId,
    setPlotId,
    setServicedBy,
  } = props;

  const [list, setList] = useState<any>([]);

  const handleSearch = async (text: string) => {
    const res = await getSearchList(text, "support");
    setList(res);
  };

  const getAllFarmIDs = async (val: string) => {
    const farmIds = await getFarmIds(val);
    setList(farmIds);
  };
  const getAllPlotIDs = async (val: string) => {
    const plotids = await getPlotIds(val);
    setList(plotids);
  };

  useEffect(() => {
    if (label === "FarmUser ID" || label === "Serviced By") {
      handleSearch("");
    } else if (label === "Farm ID") {
      getAllFarmIDs(farmUserId);
    } else if (label === "Plot ID") {
      getAllPlotIDs(farmId);
    }
  }, []); //eslint-disable-line

  const handleInputChange = (inputtext: string) => {
    if (label === "Serviced By" && inputtext !== "") {
      setServicedBy(inputtext);
      handleClose2();
    }
    if (label === "FarmUser ID" && inputtext !== "") {
      getAllFarmIDs(inputtext);
    }
    if (label === "Farm ID" && inputtext !== "") {
      getAllPlotIDs(inputtext);
    }
    if (label === "FarmUser ID") {
      setFarmUserId(inputtext);
      setFarmId("");
      setPlotId("");
    } else if (farmUserId === "" && label !== "FarmUser ID") {
      return;
    } else if (farmUserId === "" && label === "Farm ID") {
      return;
    } else if (farmUserId !== "" && label === "Farm ID") {
      setFarmId(inputtext);
      setPlotId("");
    } else if (farmId === "" && label === "Plot ID") {
      return;
    } else if (farmId !== "" && label === "Plot ID") {
      setPlotId(inputtext);
    } else return;
    handleClose2();
  };

  return (
    <React.Fragment>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{ ...style, width: 400, height: 400 }}
          style={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <h2
            style={{ width: "100%", textAlign: "center" }}
            id="child-modal-title"
          >
            {label}
          </h2>

          <TextField
            label={label}
            onChange={(e) => handleSearch(e.target.value)}
            variant="standard"
            color="success"
            style={{ marginTop: "-10px" }}
            focused
          />

          <div style={{ width: "100%", height: "70%", overflow: "scroll" }}>
            {label === "FarmUser ID" && list.length > 0
              ? list.map((item: any, ind: number, list: Array<any>) => (
                  <ListItemButton key={`farm-user-${ind}`}>
                    <ListItemText
                      onClick={(e) => {
                        handleInputChange(item.farmUserId);
                      }}
                      primary={item.farmUserId}
                    />
                  </ListItemButton>
                ))
              : null}
            {label === "Farm ID" && list.length > 0
              ? list.map((item: any, ind: number, list: Array<any>) => (
                  <ListItemButton key={`farm-${ind}`}>
                    <ListItemText
                      onClick={(e) => {
                        handleInputChange(item.farmId);
                      }}
                      primary={item.farmId}
                    />
                  </ListItemButton>
                ))
              : null}
            {label === "Plot ID" && list.length > 0
              ? list.map((item: any, ind: number, list: Array<any>) => (
                  <ListItemButton key={`plot-${ind}`}>
                    <ListItemText
                      onClick={(e) => {
                        handleInputChange(item.plotId);
                      }}
                      primary={item.plotId}
                    />
                  </ListItemButton>
                ))
              : null}
            {label === "Serviced By" && list.length > 0
              ? list.map((item: any, ind: number, list: Array<any>) => (
                  <ListItemButton key={`serviced-by-${ind}`}>
                    <ListItemText
                      onClick={(e) => {
                        handleInputChange(item.farmUserId);
                      }}
                      primary={item.name}
                    />
                  </ListItemButton>
                ))
              : null}
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default function NewMaintenanceForm(props: any) {
  const {
    open,
    edit,
    handleOpen,
    handleClose,
    formState,
    onlyCreate,
  } = props;

  const [open2, setOpen2] = React.useState(false);

  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };

  //states
  const [details, setDetails] = useState<any>("");
  const [plotId, setPlotId] = useState<any>("");
  const [farmId, setFarmId] = useState<any>("");
  const [farmUserId, setFarmUserId] = useState<any>("");
  const [servicedBy, setServicedBy] = useState<any>("");
  const [done, setDone] = useState<Boolean>(false);
  const [sensorId, setSensorId] = useState<string>("");
  const [maintenanceId, setMaintenanceId] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [editDate, setEditDate] = useState<string>("");

  const [label, setLabel] = useState<string>("");
  const [maintenanceTypesArr, setMaintenanceTypesArr] = React.useState([]);
  const [sensorsTypesArr, setSensorsTypesArr] = React.useState([]);
  const [tasks, setTasks] = React.useState([
    {
      maintenanceType: " ",
      sensor: " ",
    },
  ]);

  const maintenanceTypesFun = async () => {
    const res = await fetchMaintenanceTypes();
    setMaintenanceTypesArr(res.maintenanceType);
  };

  const sensorTypesFun = async () => {
    const res = await fetchSensorsTypes();
    setSensorsTypesArr(res);
  };

  const structureFetchDate = (dateString: string) => {
    // if(!dateString) return

    if (dateString !== "") {
      const fetchedDate = dateString.split("T");
      setEditDate(fetchedDate[0]);
    } else throw new Error("Selected field has no assigned date!");
  };

  useEffect(() => {
    maintenanceTypesFun();
    sensorTypesFun();
    if(formState.farmUserId!==''){
      setFarmUserId(formState.farmUserId);
      setFarmId(formState.farmId);
      setPlotId(formState.plotId);
    }
    

    if (edit === true) {
      if(formState.date){
      structureFetchDate(formState.date);
      }
      setFarmUserId(formState.farmUserId);
      setFarmId(formState.farmId);
      setPlotId(formState.plotId);
      setServicedBy(formState.servicedBy);
      setTasks(formState.tasks);
      setDone(formState.done);
      setDetails(formState.details);
    } else {
      setTasks(formState.tasks);
    }
  }, [handleOpen]); //eslint-disable-line

  const addNewtask = () => {
    let newTaskArr = tasks.slice();
    newTaskArr.push({
      maintenanceType: "",
      sensor: "",
    });
    setTasks(newTaskArr);
  };  

  const handleSubmit = async () => {
    let dateobj = new Date(date);
    const isoDate = dateobj.toISOString();
    
    let payload = {
      date: isoDate,
      done: done,
      farmUserId: farmUserId,
      farmId: farmId,
      plotId: plotId,
      details: details,
      servicedBy: servicedBy,
      tasks: tasks,
    };

    await postMaintenance(payload);
    handleClose();
  };
  const handleDelete = (task: any, ind: number, tasks: Array<any>) => {
    const reducedTasks = tasks.filter((task, i) => i !== ind);
    setTasks(reducedTasks);
  };

  const handleInputFromModal = (value: any, placeholder: string) => {
    setLabel(placeholder);
    handleOpen2();
  };

  // mapping of which tasks is selected
  const funcMaintenance = (option: any, taskInd: number) => {
    let newTasks = tasks.slice();
    newTasks[taskInd].maintenanceType = option;
    setTasks(newTasks);
  };
  // mapping of which tasks is selected
  const funcSensors = (option: any, taskInd: number) => {
    let newTasks = tasks.slice();
    newTasks[taskInd].sensor = option;
    setTasks(newTasks);
  };


  const createNew = () => {
    handleOpen();
    setFarmUserId("");
    setFarmId("");
    setPlotId("");
    setServicedBy("");
    setTasks([{
      maintenanceType: " ",
      sensor: " ",
    }]);
    setDone(false);
    setDetails("");
  };

  const setMyDate = (editedDate:any)=>{
    let formattedDate = new Date(editedDate);
    let requiredDate = formattedDate.toISOString();
    let finalDate = requiredDate.split('T')[0];
    setEditDate(finalDate);
  }

  const handleEdit = async ()=>{
    let dateobj = new Date(editDate);
    const isoDate = dateobj.toISOString();
    let payload2 = {
      date: isoDate,
      done: done,
      farmUserId: farmUserId,
      farmId: farmId,
      plotId: plotId,
      details: details,
      servicedBy: servicedBy,
      tasks: tasks,
      id:(edit===true)?formState.id:''
    };
    await updateMaintenance(payload2);
    handleClose();
  }

  return (
    <div>
     {(onlyCreate)?null: <Button
        style={{
          backgroundColor: "black",
          color: "white",
          border: "1px solid black",
        }}
        onClick={createNew}
      >
        Create New
      </Button>}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            overflow: "scroll",
            width: "70%",
            height: "90%",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
              height: "70px",
              backgroundColor: "lightgray",
              borderRadius: "10px",
            }}
          >
            <h2 id="parent-modal-title">
              {edit === true ? "Edit Maintenance" : "New Maintenance"}
            </h2>
          </div>

          {open2 && (
            <ChildModal
              open2={open2}
              handleClose2={handleClose2}
              label={label}
              farmUserId={farmUserId}
              farmId={farmId}
              setFarmUserId={setFarmUserId}
              setFarmId={setFarmId}
              setPlotId={setPlotId}
              setServicedBy={setServicedBy}
            />
          )}

          <TextField
            label="FarmUser ID"
            value={farmUserId}
            sx={{ m: 2 }}
            disabled={(edit===true)?true:false}
            onClick={(e) => {
              if(edit===false)handleInputFromModal(e, "FarmUser ID");
              return;
            }}
            // focused
          />
          <TextField
            label="Farm ID"
            value={farmId}
            disabled={(edit===true)?true:false}
            onClick={(e) => {
              if(edit===false)handleInputFromModal(e, "Farm ID");
              return;
            }}
            sx={{ m: 2 }}
          />
          <TextField
            label="Plot ID"
            value={plotId}
            disabled={(edit===true)?true:false}
            onClick={(e) => {
              if(edit===false)handleInputFromModal(e, "Plot ID");
              return;
            }}
            sx={{ m: 2 }}
          />
          <TextField
            value={servicedBy}
            label="Serviced By"
            placeholder="Serviced By"
            onClick={(e) => handleInputFromModal(e, "Serviced By")}
            sx={{ m: 2 }}
          />

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: "65px",
            }}
          >
            {/* date field */}
            <div style={{ paddingTop: "25px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {edit === true ? (
                  <DatePicker
                    onChange={(val: any) => setMyDate(val)}
                    label="Edit Date"
                    value={editDate && dayjs(editDate)}
                  />
                ) : (
                  <DatePicker
                    onChange={(val: any) => setDate(val)}
                    label="Select Date"
                    // defaultValue={dayjs(structuredDate)}
                  />
                )}
              </LocalizationProvider>
            </div>

            {/* Done Field */}
            <FormControl variant="standard" sx={{ m: 2, minWidth: 196 }}>
              <InputLabel id="demo-simple-select-standard-label">
                Done
              </InputLabel>
              {edit === true ? (
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={done === false ? "False" : "True"}
                  onChange={(e) => {
                    if (e.target.value === "True") setDone(true);
                    else setDone(false);
                  }}
                  label="Done"
                >
                  <MenuItem value={"True"}>Yes</MenuItem>
                  <MenuItem value={"False"}>No</MenuItem>
                </Select>
              ) : (
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  onChange={(e) => {
                    if (e.target.value === "True") setDone(true);
                    else setDone(false);
                  }}
                  label="Done"
                >
                  <MenuItem value={"True"}>Yes</MenuItem>
                  <MenuItem value={"False"}>No</MenuItem>
                </Select>
              )}
            </FormControl>
          </div>

          {/* Details */}
          <TextField
            label="Details"
            onChange={(e) => setDetails(e.target.value)}
            value={details}
            variant="standard"
            sx={{ m: 2 }}
            fullWidth={true}
          />

          {/* Tasks */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "center",
                height: "60px",
                textDecoration: "underline",
                marginLeft: "-30px",
              }}
            >
              {" "}
              <h3 id="parent-modal-title">Tasks</h3>
            </div>

            {tasks.map((task: any, ind: number, tasks: Array<any>) => (
              <div
                key={`maintenance-type-${ind}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                  paddingLeft: "70px",
                }}
              >
                <FormControl variant="standard" sx={{ m: 1, minWidth: 196 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Maintenance Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => funcMaintenance(e.target.value, ind)}
                    label="Serviced By"
                    value={task && task.maintenanceType}
                  >
                    {maintenanceTypesArr.map(
                      (maintenanceType: any, ind: number) => (
                        <MenuItem
                          key={`${maintenanceType}-${ind}`}
                          value={maintenanceType.id}
                          onClick={(e) => setMaintenanceId(maintenanceType.id)}
                        >
                          {maintenanceType.value}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>

                <FormControl variant="standard" sx={{ m: 1, minWidth: 196 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Sensors
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={(e) => funcSensors(e.target.value, ind)}
                    label="Sensors"
                    value={task && task.sensor}
                  >
                    {sensorsTypesArr.map((sensor, ind) => (
                      <MenuItem
                        key={`${sensor}-${ind}`}
                        value={sensor}
                        onClick={(e) => setSensorId(sensor)}
                      >
                        {sensor}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <DeleteForeverIcon
                  onClick={() => handleDelete(task, ind, tasks)}
                  style={{
                    height: "40px",
                    marginLeft: "-20px",
                    width: "30px",
                    marginTop: "26px",
                    cursor: "pointer",
                  }}
                  color="error"
                />
              </div>
            ))}

            <div style={{ width: "100%", textAlign: "center", height: "60px" }}>
              {" "}
              <Button
                onClick={() => addNewtask()}
                style={{
                  marginTop: "20px",
                  width: "110px",
                  marginLeft: "67%",
                  marginRight: "10px",
                }}
                color="success"
                variant="outlined"
              >
                Add Task
              </Button>
            </div>
            <div style={{ width: "100%", textAlign: "center", height: "60px" }}>
              {" "}
              <Button
                onClick={(edit===true)?handleEdit:handleSubmit}
                style={{ marginTop: "20px" }}
                color="success"
                variant="contained"
              >
                {(edit===true)?'Edit':'Submit'}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
