import { Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  getInventoryItems,
  getInventoryLocations,
  postInventoryOrder,
  completeInventoryOrder,
  cancelInventoryOrder,
  deleteInventoryOrder,
} from "../services/inventory.service";
import Select, { SingleValue } from "react-select";
import { generateOptions } from "src/utils/helper";
import { DatePicker } from "src/components";
import moment from "moment";

interface Props {
  selectedModalData?: any;
  setOpenModal?: any;
  locationOrder?: any;
  locationDevice?: any;
}
const InventoryOrder: React.FC<Props> = (props: Props): JSX.Element => {
  const res: any = props?.selectedModalData;
  const [quantity, setQuantity] = useState<any>(
    !props?.locationOrder && res?.id ? res?.quantity : ""
  );
  const [deviceOptions, setdeviceOptions] = useState<SingleValue<any>>();
  const [locationOptions, setlocationOptions] = useState<SingleValue<any>>();
  const [comments, setComments] = useState<any>(res?.comments || "");
  const [selectedDeviceOption, setSelectedDeviceOption] = useState<any>();
  const [selectedLocationOption, setSelectedLocationOption] = useState<any>({
    label: props?.locationOrder ? res?.name : null,
    value: props?.locationOrder ? res?.id : null,
  });
  // const [selectedStatusOption, setSelectedStatusOption] = useState<any>({ label: (!props?.locationOrder && res?.id) ? res?.status : 'OPEN', value: (!props?.locationOrder && res?.id) ? res?.status : 'OPEN' });
  const [movementDate, setMovementDate] = useState<Date>(
    res?.date ? moment(res?.date).toDate() : moment().toDate()
  );

  useEffect(() => {
    getInventoryItems().then((response) => {
      if (response.length > 0) {
        setdeviceOptions(generateOptions(response, "id", "name"));
        if (res?.inventoryItemId) {
          setSelectedDeviceOption({
            label: res?.inventoryItem?.name,
            value: res?.inventoryItem?.id,
          });
        } else if (props?.locationDevice && props?.locationOrder) {
          const selectedDevice = response?.filter(
            (dev: any) => dev.name === props?.locationDevice
          );
          setSelectedDeviceOption({
            label: selectedDevice[0]?.name,
            value: selectedDevice[0]?.id,
          });
        }
      }
    });

    getInventoryLocations().then((result) => {
      if (result.length > 0) {
        setlocationOptions(generateOptions(result, "id", "name"));
        if (res?.inventoryLocationId) {
          setSelectedLocationOption({
            label: res?.inventoryLocation?.name,
            value: res?.inventoryLocation.id,
          });
        }
      }
    });
  }, []);

  const onSubmit = () => {
    if (
      selectedLocationOption?.value?.length > 0 &&
      quantity &&
      selectedDeviceOption?.value?.length > 0 &&
      !(!props.locationOrder && res?.id)
    ) {
      let finalData:any= {
        date: movementDate,
        quantity: Number(quantity),
        inventoryLocationId: selectedLocationOption.value,
        // status: selectedStatusOption.value,
        inventoryItemId: selectedDeviceOption.value,
      };
      if(!!comments?.length){
        finalData['comments'] = comments;
      }
      if (window.confirm("Do you want to submit?")) {
        postInventoryOrder(finalData).then((res: any) => {
          if (res.id) {
            alert("Order created");
            props?.setOpenModal(false);
          } else alert("Some Error Ocuured");
        });
      }
    } else alert("Some Error Ocuured");
  };

  const onComplete = () => {
    if (res?.id && props.locationOrder === false) {
      if (window.confirm("Do you want to submit?")) {
        completeInventoryOrder(res.id).then((res: any) => {
          if (res.status === "COMPLETED") {
            alert("Order Completed");
            props?.setOpenModal(false);
          } else alert("Some Error Ocuured");
        });
      }
    } else alert("Some Error Ocuured");
  };

  const onCancel = () => {
    if (res?.id && props.locationOrder === false) {
      if (window.confirm("Do you want to submit?")) {
        cancelInventoryOrder(res.id).then((res: any) => {
          if (res.status === "CANCELED") {
            alert("Order Cancelled");
            props?.setOpenModal(false);
          } else alert("Some Error Ocuured");
        });
      }
    } else alert("Some Error Ocuured");
  };

  const onDelete = () => {
    if (res?.id) {
      if (window.confirm("Do you want to delete?")) {
        deleteInventoryOrder(res.id).then((res: any) => {
          alert("Order deleted");
          props?.setOpenModal(false);
        });
      }
    } else alert("Some Error Ocuured");
  };

  const navigate = useNavigate();

  return (
    <Grid
      width="90%"
      my={2}
      mx="auto"
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid p={2} borderRadius={25} style={{ backgroundColor: "#f5f5f5" }}>
        <Grid container alignItems="center">
          <IconButton
            onClick={() =>
              props?.setOpenModal
                ? props.setOpenModal(false)
                : navigate("/inventory")
            }
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h5" style={{ marginLeft: 10 }}>
            Order
          </Typography>
        </Grid>
      </Grid>

      <Grid display={"flex"} justifyContent={"flex-end"} mr={3}>
        <DatePicker
          showCalendarIcon
          label=""
          maxDate={new Date(Infinity)}
          labelStyle={{ fontWeight: "bold" }}
          value={movementDate}
          onChange={(d: any) =>
            !(!props?.locationOrder && res?.id) && setMovementDate(d)
          }
        />
      </Grid>
      <div style={{ justifyContent: "center", padding: 20 }}>
        <form>
          <h3 style={{ margin: 5 }}>Location</h3>
          <Select
            options={locationOptions}
            onChange={(selected: SingleValue<any>) =>
              !(!props?.locationOrder && res?.id) &&
              setSelectedLocationOption(selected)
            }
            value={selectedLocationOption}
            isClearable={true}
            isSearchable={true}
            placeholder={"- select -"}
            styles={{
              container: (baseStyles: any, state: any) => ({
                ...baseStyles,
                width: "95%",
                marginLeft: "5px",
                border: "1px solid #ccc",
                borderRadius: 5,
                marginBottom: 12,
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderTop: state.isFocused ? "1px" : "0px",
                borderLeft: state.isFocused ? "1px" : "0px",
                borderRight: state.isFocused ? "1px" : "0px",
              }),
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
                zIndex: 100,
              }),
            }}
          />

          <h3 style={{ margin: 5 }}>Device</h3>
          <Select
            options={deviceOptions}
            onChange={(selected: SingleValue<any>) =>
              !(!props?.locationOrder && res?.id) &&
              setSelectedDeviceOption(selected)
            }
            value={selectedDeviceOption}
            isClearable={true}
            isSearchable={true}
            placeholder={"- select -"}
            styles={{
              container: (baseStyles: any, state: any) => ({
                ...baseStyles,
                width: "95%",
                marginLeft: "5px",
                border: "1px solid #ccc",
                borderRadius: 5,
                marginBottom: 8,
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderTop: state.isFocused ? "1px" : "0px",
                borderLeft: state.isFocused ? "1px" : "0px",
                borderRight: state.isFocused ? "1px" : "0px",
              }),
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
                zIndex: 100,
              }),
            }}
          />

          <h3 style={{ margin: 5 }}>Quantity</h3>
          <input
            name="quantity"
            onChange={(e) =>
              !(!props?.locationOrder && res?.id) && setQuantity(e.target.value)
            }
            value={quantity}
            type="number"
            min={0}
            style={{
              width: "94%",
              padding: "10px",
              fontSize: 18,
              margin: "3px",
              marginBottom: 2,
              marginTop: 2,
              border: "1px solid #ccc",
              borderRadius: 5,
            }}
          />
          <br />

          <h3 style={{ margin: 5 }}>Comments</h3>
          <input
            name="comments"
            onChange={(e) => !res?.id && setComments(e.target.value)}
            value={comments}
            type="text"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              border: "1px solid #ccc",
              borderRadius: 5,
            }}
          />
          <br />

          {!(!props.locationOrder && res?.id) && (
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: 55 }}
            >
              <input
                type="button"
                value="Submit"
                onClick={onSubmit}
                style={{
                  width: "42%",
                  padding: "10px",
                  fontSize: 18,
                  backgroundColor: "#4caf50",
                  color: "#fff",
                  border: "none",
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              />
            </Grid>
          )}

          {!props.locationOrder && res?.id && (
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: 55 }}
            >
              <input
                type="button"
                value="Delete"
                onClick={onDelete}
                style={{
                  width: "42%",
                  padding: "10px",
                  fontSize: 18,
                  backgroundColor: "#f44336",
                  color: "#fff",
                  border: "none",
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              />
            </Grid>
          )}

          {/* {(!props.locationOrder && res?.id) && selectedStatusOption?.value === "OPEN" && (
                        <Grid container justifyContent="space-between" style={{ marginTop: 55 }}>
                            <input
                                type="button"
                                value="Complete"
                                onClick={onComplete}
                                disabled={selectedStatusOption?.value === "COMPLETED"}
                                style={{
                                    width: '42%',
                                    padding: '10px',
                                    fontSize: 18,
                                    backgroundColor: '#4caf50',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: 5,
                                    cursor: 'pointer',
                                }}
                            />
                            <input
                                type="button"
                                value="Cancel"
                                onClick={onCancel}
                                disabled={selectedStatusOption?.value === "CANCELED"}
                                style={{
                                    width: '42%',
                                    padding: '10px',
                                    fontSize: 18,
                                    backgroundColor: '#f44336',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: 5,
                                    cursor: 'pointer',
                                }}
                            />
                        </Grid>
                    )} */}
        </form>
      </div>
    </Grid>
  );
};

export default InventoryOrder;
