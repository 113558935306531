import { isArrayWithLength } from "src/utils/helper";
import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import images from "src/constants/images";
import { InformationCardTitles } from "src/constants/WordMapping";
import { useRecoilState } from "recoil";
import { plotsAtom } from "src/recoil/atom";

type Props = {
  amount: number;
  amountFontSize?: number;
  text: string;
  textFontSize?: number;
  children: React.ReactNode;
  lastUpdated?: Boolean;
  data: string[] | string[][]
}

type WrapperProps = {
  plotIds: string[];
  children: React.ReactNode;
  text: string;
}

const CardWrapper: React.FC<WrapperProps> = ({ plotIds, text, children }): JSX.Element => {
  const [_, setPlotIds] = useRecoilState(plotsAtom)

  const handleOnClick = () => {
    setPlotIds({
      ids: plotIds,
      text
    })
  }

  if (isArrayWithLength(plotIds)) {
    return (
      <Link to={'/home'} onClick={handleOnClick} style={{ color: 'inherit', textDecoration: 'inherit' }} >
        {children}
      </Link>
    )
  }

  return (
    <div>
      {children}
    </div>
  )
}

const InformationCard: React.FC<Props> = ({ amount, text, children, lastUpdated, data }: Props): JSX.Element => {
  const [plotIds, setPlotIds] = useState<string[]>([])
  useEffect(() => {
    if (isArrayWithLength(data[0])) {
      for (const plotIdsArray of data) {
        setPlotIds((prevIds) => [...prevIds, ...plotIdsArray as string[]])
      }
    } else setPlotIds(data as string[])
  }, [])

  // let IMAGE = ''
  // switch (text) {
  //   case InformationCardTitles.plots:
  //     IMAGE = images.GrapesFarm
  //     break;
  //   case InformationCardTitles.inconsistentIrrigation:
  //     IMAGE = images.Irrigation
  //     break;
  //   default:
  //     IMAGE = images.Fertigation
  //     break;
  // }

  const GridStyles = {
    boxShadow: 3,
    px: 2, pb: 2, mb: 2,
    borderRadius: 3,
    style: {
      // borderColor: 'lightgreen',
      // backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(${IMAGE})`,
      // backgroundPosition: 'center',
      // backgroundSize: 'cover',
    }
  }

  return (
    <Grid {...GridStyles}>
      <CardWrapper plotIds={plotIds} text={text}>
        <Grid display={'flex'} alignItems={'center'}>
          <Typography letterSpacing={0.8} variant="overline" textTransform={'capitalize'} fontSize={20}>
            {text} {text === InformationCardTitles.plots && ` - ${amount}`}
          </Typography>
        </Grid>
      </CardWrapper>

      <Grid display={'flex'} flexWrap={'wrap'} >
        {children}
      </Grid>

      <>
        {lastUpdated && (
          <Typography fontSize={12} color={'gray'} mt={2} mb={-1}>* Since last 7 days</Typography>
        )}
      </>
    </Grid>
  )
}

export default InformationCard;