import { Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { postCropVariety, deleteCropVariety } from "src/services/crop.service";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Select, { SingleValue } from "react-select";
import { ToastAlert } from 'src/components';
import LocaleDropdown from "../LocaleDropdown";
import { ToastSeverity } from 'src/components/ToastAlert/types';

interface Props {
  selectedModalData?: any;
  setOpenModal?: any;
  cropOptions?: any;
}

const DropdownProps = {
  isClearable: true,
  isSearchable: true,
  placeholder: "- select -",
  styles: {
    container: (baseStyles: any) => ({
      ...baseStyles,
      width: "100%",
      padding: 2,
      fontSize: 16,
      margin: "3px 0",
      marginBottom: 11,
      marginTop: 6,
    }),
  },
};

const CropVariety: React.FC<Props> = (props: Props): JSX.Element => {
  const { selectedModalData, setOpenModal, cropOptions } = props;
  const [cropId, setcropId] = useState<SingleValue<any>>(
    cropOptions?.filter((c: any) => c?.id === selectedModalData?.cropId)[0] ||
      ""
  );
  const [variety, setVariety] = useState<SingleValue<any>>();

  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<ToastSeverity>("success");

  const onSubmit = () => {
    if (cropId?.id) {
      if (window.confirm("Do you want to submit?")) {
        const finalData: any = {
          name: variety?.label,
          cropId: cropId?.id,
          id: variety?.value,
        };
        if (selectedModalData?.id) {
          deleteCropVariety(selectedModalData?.id).then((response: any) => {
            if (response?.message === "Success") {
              postCropVariety(finalData?.cropId, [finalData]).then((response: any) => {
                if (response[0].id) {
                  setToastMessage("Variety Updated");
                  setToastSeverity("success");
                  setOpenModal(false);
                } else {
                  setToastMessage("Some Error Ocuured");
                  setToastSeverity("error");
                  setOpenModal(false);
                }
              });
            } else {
              setToastMessage("Some Error Ocuured");
              setToastSeverity("error");
              setOpenModal(false);
            }
          });
        } else {
            postCropVariety(finalData?.cropId, [finalData]).then((response: any) => {
              if (response[0].id) {
                setToastMessage("Variety Added");
                setToastSeverity("success");
                setOpenToast(true);
                setOpenModal(false);
              } else {
                setToastMessage("Some Error Ocuured");
                setToastSeverity("error");
                setOpenModal(false);
              }
            });
        }
      }
    } else {
      setToastMessage("Some Error Ocuured");
      setToastSeverity("error");
    }
    setOpenToast(true);
  };

  const onDelete = () => {
    if (selectedModalData?.id) {
      if (window.confirm("Do you want to delete?")) {
        deleteCropVariety(selectedModalData?.id).then((response: any) => {
          if (response?.message === "Success") {
            setToastMessage("Variety Deleted");
            setToastSeverity("success");
            setOpenModal(false);
          } else {
            setToastMessage("Some Error Ocuured");
            setToastSeverity("error");
            setOpenModal(false);
          }
        });
      }
    } else {
      setToastMessage("Some Error Ocuured");
      setToastSeverity("error");

    }
    setOpenToast(true);

  };

  const navigate = useNavigate();

  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid
        p={2}
        borderRadius={20}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton
            onClick={() =>
              setOpenModal
                ? setOpenModal(false)
                : navigate("/cropCharacteristics")
            }
          >
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Crop Variety
          </Typography>
        </Grid>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 30,
        }}
      >
        <form style={{ width: "94%" }}>
          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Crop
          </label>
          <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
            *
          </label>
          <Select
            options={cropOptions}
            onChange={(selected: SingleValue<any>) => {
              setcropId(selected);
            }}
            value={cropId}
            {...DropdownProps}
          />

          <LocaleDropdown
            value={selectedModalData?.id}
            title="Variety"
            titleStyle={{ fontSize: 19, fontWeight: "bold" }}
            onChange={(selected: SingleValue<any>) => {
              setVariety(selected);
            }}
            containerStyle={{ width: "98%" }}
            isImportant={true}
          />

          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            style={{ marginTop: 55 }}
          >
            <input
              type="button"
              value="Submit"
              onClick={onSubmit}
              style={{
                width: "45%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
              }}
            />
            <input
              type="button"
              value="Delete"
              onClick={onDelete}
              style={{
                width: "45%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
              }}
            />
          </Grid>
        </form>
      </div>
      <ToastAlert
        open={openToast}
        setOpen={setOpenToast}
        severity={toastSeverity}
        message={toastMessage}
      />
    </Grid>
  );
};

export default CropVariety;
