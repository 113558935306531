import React from 'react'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

type Props = {
  data: any
}

const DataTable: React.FC<Props> = (props) => {
  const { data } = props;

  return (
    <div className="ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
      <AgGridReact 
        rowData={data} 
        columnDefs={colDef}   
        pagination
        paginationPageSize={10}
        embedFullWidthRows
      />
    </div>
  )
}

export default DataTable;

const colDef: ColDef[] = [
  {
    headerName: 'State',
    field: 'state',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    minWidth: 300
  },
  {
    headerName: 'District',
    field: 'district',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    minWidth: 300
  },
  {
    headerName: 'Expired',
    field: 'expired',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    minWidth: 300
  },
  {
    headerName: 'Recharged',
    field: 'voucher',
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    minWidth: 300
  },
]