import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {
    ColDef,
    GridOptions,
    GridReadyEvent,
    SideBarDef,
    SortChangedEvent,
    FilterChangedEvent,
} from 'ag-grid-community';
import { Link } from 'react-router-dom';
import { ConsultantsColDef } from '../constants/columnDefs';
import { getAllConsultants } from '../services/farm.service';
import { isArrayWithLength } from '../utils/helper';
import { Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router';
import { Grid, IconButton } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import AddCircleIcon from '@mui/icons-material/AddCircle';


const Consultants = (): JSX.Element => {
    const state = useLocation();
    const gridRef = useRef<AgGridReact>(null);
    const containerStyle = useMemo(() => ({ width: '100%', height: '600px' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [tableData, setTableData] = useState<any[]>([]);
    const navigate = useNavigate();
    const startLoading = () => {
        gridRef?.current?.api?.showLoadingOverlay();
    }
    const stopLoading = () => {
        gridRef?.current?.api?.hideOverlay();
    }

    const gridOptions: GridOptions = {
        columnDefs: ConsultantsColDef,
        rowData: tableData,
        rowSelection: 'single',
        animateRows: true,
        overlayLoadingTemplate:
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
        onCellClicked: (e) => navigate(`consultants/${e.data.id}`, { replace: true, state })
    };

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            resizable: true,
            sortable: true,
            floatingFilter: true,
            menuTabs: ['filterMenuTab'],
        };
    }, []);

    const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
        return {
            toolPanels: ['filters'],
        };
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        // gridRef?.current?.api.setColumnDefs(farmActivitiesColDef);
        // gridRef?.current?.api.setRowData(tableData);
    }, []);

    // function to perform server side sorting
    const handleColumnSort = (event: SortChangedEvent) => {
        const columns = event.columnApi.getColumnState();
        let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId
    }

    // function to perform server side filtering
    const handleColumnFilter = (event: FilterChangedEvent) => {
        if (event.afterFloatingFilter) { }
    }

    const onPaginationChanged = useCallback(() => { }, []);

    useEffect(() => {
        callFarmActivityApi()
    }, [])

    const callFarmActivityApi = (): void => {
        getAllConsultants()
            .then((res: any) => {
                setTableData(res);
            })
    }
    return (
        <div style={containerStyle}  >
            <Grid p={2} borderRadius={20} display={"flex"} justifyContent={"space-between"}>
                <Grid display={'flex'} item alignItems={'center'} margin={1}>
                    <IconButton onClick={() => navigate('/')} ><ArrowBack /></IconButton>
                    <Typography m={1} variant='h5'>Dashboard</Typography>
                </Grid>

                <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bold', fontSize: 18, marginRight: 50, alignSelf: 'center' }} to={'consultants'}>
                    <input data-testid="add-consultants-button" type="button" value="Add New Subscription" style={{ width: '100%', padding: '10px', fontSize: 18, paddingTop: '10px', alignSelf: 'center', }}></input>
                </Link>
            </Grid>
            <div style={gridStyle} className="ag-theme-alpine">
                {
                    isArrayWithLength(tableData) &&
                    <Grid p={3}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={tableData}
                            columnDefs={ConsultantsColDef}
                            onGridReady={onGridReady}
                            defaultColDef={defaultColDef}
                            sideBar={sideBar}
                            animateRows={true}
                            pagination={true}
                            paginationPageSize={15}
                            gridOptions={gridOptions}
                            domLayout="autoHeight"
                            onPaginationChanged={onPaginationChanged}
                            onSortChanged={handleColumnSort}
                            onFilterChanged={handleColumnFilter}
                        ></AgGridReact>
                    </Grid>
                }
            </div>
        </div>
    )
}

export default Consultants