import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { isDevelopmentMode } from "../utils/helper";

interface Props {
  allowedRoles: string[];
}

const RequireAuth = ({ allowedRoles }: Props) => {
  const { auth } = useAuth();
  const location = useLocation();

  const roles = auth?.roles || JSON.parse(localStorage.getItem('roles')!);
  let hasPermission = roles?.find((role: string) => allowedRoles?.includes(role));
  const isLoggedIn = localStorage.getItem('access_token')?.length! > 0;

  if(isDevelopmentMode() && isLoggedIn){
    return <Outlet />
  }
  
  return (
    hasPermission
      ? <Outlet />
      : isLoggedIn
        ? <Navigate to="/unauthorized" state={{ from: location }} replace />
        : <Navigate to="/login" state={{ from: location }} replace />
  );
}


export default RequireAuth;
export {}