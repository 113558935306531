import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Grid, Typography } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  SortChangedEvent,
  FilterChangedEvent,
} from 'ag-grid-community';
import { MaintenanceCols } from '../constants/columnDefs';
import { getAllMaintenanceRecords } from '../services/plot.service';
import { Loader } from '../components';

// import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



// THIS IS THE MAIN COMPONENT
export default function MaintenanceSlider(props: any) {
  const { setEdit, handleOpen, setFormState } = props;
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const [doneMaintenance, setDoneMaintenance] = useState<any[]>([]);
  const [pendingMaintenance2, setPendingMaintenance2] = useState<any[]>([]);

  const [showLoader] = useState<Boolean>(false);

  const [showGrid] = useState<Boolean>(true);
  const gridRef = useRef<AgGridReact>(null);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const gridStyleTwo = useMemo(() => ({ height: '100%', width: '100%', display: 'none' }), []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getMaintenanceRecordsFunc = async () => {
    const res = await getAllMaintenanceRecords();
    // setTableData(res);

    // let pending:any = res.filter((record:any,idx:number,res:Array<any>)=> record.done==false);
    let done: any = res.filter((record: any, idx: number, res: Array<any>) => record.done === true);
    let done2: any = res.filter((record: any, idx: number, res: Array<any>) => record.done === undefined);
    let pending2: any = res.filter((record: any, idx: number, res: Array<any>) => record.done === false); // ask this // what if there is no done field
    let ans = done.concat(done2);
    setDoneMaintenance(ans);
    setPendingMaintenance2(pending2);
  }

  useEffect(() => {
    getMaintenanceRecordsFunc();
  }, [])

  const gridOptions: GridOptions = {
    columnDefs: MaintenanceCols,
    rowSelection: 'single',
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      // do some action
      setFormState({
        "details": e.data.details,
        "id": e.data.id,
        "plotId": e.data.plotId,
        "date": e.data.date,
        "farmId": e.data.farmId,
        "farmUserId": e.data.farmUserId,
        "servicedBy": e.data.servicedBy,
        "done": e.data.done,
        "tasks": e.data.tasks,
      })
      setEdit(true);
      handleOpen();
      return;
    }
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ['filterMenuTab'],
    };
  }, []);

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return {
      toolPanels: ['filters'],
    };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
  }, []);

  const handleColumnSort = (event: SortChangedEvent) => {
    // const columns = event.columnApi.getColumnState();
  }

  const handleColumnFilter = (event: FilterChangedEvent) => {
    if (event.afterFloatingFilter) { }
  }

  const onPaginationChanged = useCallback(() => { }, []);

  return (
    <Box sx={{ width: '100vw' }}>
      <AppBar style={{ backgroundColor: '#ffffff', width: '40%', marginLeft: '49px', marginTop: '20px' }} position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        // color="success"
        >
          <Tab style={{ color: 'black' }} label="PENDING" {...a11yProps(0)} />
          <Tab style={{ color: 'black' }} label="DONE" {...a11yProps(1)} />
          {/* <Tab label="WHAT TO WRITE HERE" {...a11yProps(2)} /> */}
        </Tabs>
      </AppBar>
      {/* <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{}}

      > */}
        <TabPanel value={value} index={0} dir={theme.direction}>
          {(showLoader === true) ?
            <div style={{ marginTop: '20vh', width: '95%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Loader />
            </div> :
            <div data-testid="ag-grid-noti" style={(showGrid) ? gridStyle : gridStyleTwo} className="ag-theme-alpine">
              <Grid p={3}>
                <AgGridReact
                  ref={gridRef}
                  rowData={pendingMaintenance2}
                  columnDefs={MaintenanceCols}
                  onGridReady={onGridReady}
                  defaultColDef={defaultColDef}
                  sideBar={sideBar}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={15}
                  gridOptions={gridOptions}
                  domLayout="autoHeight"
                  onPaginationChanged={onPaginationChanged}
                  onSortChanged={handleColumnSort}
                  onFilterChanged={handleColumnFilter}
                ></AgGridReact>
              </Grid>
            </div>
          }
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {(showLoader === true) ?
            <div style={{ marginTop: '20vh', width: '95%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Loader />
            </div> :
            <div data-testid="ag-grid-noti" style={(showGrid) ? gridStyle : gridStyleTwo} className="ag-theme-alpine">
              <Grid p={3}>
                <AgGridReact
                  ref={gridRef}
                  rowData={doneMaintenance}
                  columnDefs={MaintenanceCols}
                  onGridReady={onGridReady}
                  defaultColDef={defaultColDef}
                  sideBar={sideBar}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={15}
                  gridOptions={gridOptions}
                  domLayout="autoHeight"
                  onPaginationChanged={onPaginationChanged}
                  onSortChanged={handleColumnSort}
                  onFilterChanged={handleColumnFilter}
                ></AgGridReact>
              </Grid>
            </div>
          }
        </TabPanel>
      {/* </SwipeableViews> */}
    </Box>
  );
}
