import { getRegionsCount, getRegionTypeCount } from "src/services/region.service"
import { Button, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { Roles } from "src/constants/interfaces"


const InformationText = (props: { title: string, amount: number }) => {
  const { title, amount } = props;
  return (
    <Grid textAlign={'center'} >
      <Typography fontSize={14} variant="overline" textTransform={'capitalize'}> {title} - </Typography>
      <Typography fontSize={14} variant="overline" ml={0.5} fontWeight={600}> {amount} </Typography>
    </Grid>
  )
}

const InfoSection: React.FC = () => {
  const roles = JSON.parse(localStorage.getItem('roles')!);
  let allowedRoles = [Roles.ADMIN.toString(), Roles.SUPPORT.toString()]
  let hasPermission = roles?.find((role: string) => allowedRoles?.includes(role));

  const [regionCount, setRegionCount] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    // api call for regions count
    (async () => {
      try {
        setIsLoading(true)
        const { count }: { count: number } = await getRegionsCount()
        setRegionCount(count)
      } catch (err) {
        console.log('err', err)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [])
  return (
    < Grid item >
      {isLoading ? <>loading...</> : hasPermission ? <InformationText title={'Locations'} amount={regionCount} /> : <></>
      }
    </Grid >
  )
}

export default InfoSection