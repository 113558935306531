import { Button, ButtonGroup } from '@mui/material';
import { FC, useState } from 'react';
import { TTimeFrame, TTimeFrameFor } from '../types';

const TimeFrameArray: TTimeFrame[] = ['1D', '1W', '1M'];

interface Props {
  activeButton?: TTimeFrame,
  handleDateChange?: (timeFrame: TTimeFrame) => void;
  timeFrameFor: TTimeFrameFor
}

const TimeButtonGroup: FC<Props> = (props) => {
  const { activeButton, handleDateChange, timeFrameFor } = props;

  const handleClick = (timeFrame: TTimeFrame) => {
    if(!!handleDateChange) handleDateChange(timeFrame);
  }

  return (
    <ButtonGroup color='success' size='small' >
      {
        TimeFrameArray.map((timeFrame: TTimeFrame, index: number) => (
          <Button
            key={`timeFrame-button-${timeFrameFor}-${timeFrame}-${index}`}
            variant={timeFrame === activeButton ? 'contained' : 'outlined'}
            onClick={() => handleClick(timeFrame)}
          >{timeFrame}</Button>
        ))
      }
    </ButtonGroup>
  )
}

export default TimeButtonGroup;