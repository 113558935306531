import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router';
import Select, { MultiValue, OptionsOrGroups, SingleValue } from "react-select";
import { postTarget, updateTarget} from '../services/businessDashboard.service';

interface TargetDetailsProps {
    updateData: () => void;
    closeModal: () => void;
    region: MultiValue<any>;
    months: MultiValue<any>;
    targetDetails?: any;
    edit?: boolean;
}

interface Collections {
    recievable: {
        target: number, 
        actual: number
    },
    freshSales: {
        target: number, 
        actual: number
    },
    estimation: {
        start: number,
        first: number, 
        second: number, 
        end: number
    }
}

const TargetDetails: React.FC<TargetDetailsProps> = ({ closeModal, updateData, region, months, targetDetails={}, edit }): JSX.Element => {
        
    const [selectedRegion, setSelectedRegion] = useState<any>(targetDetails? targetDetails.region: null)
    const [selectedMonth, setSelectedMonth] = useState<any>(targetDetails? months[parseInt(targetDetails.month)-1]: '');
    const [selectedYear, setSelectedYear] = useState<any>(targetDetails? {label: targetDetails.year, value: parseInt(targetDetails.year)}: '');

    const [revenueDetails, setRevenueDetails] = useState<any>(edit? targetDetails?.revenue: {target: 0, actual: 0, estimation: {start: 0, first: 0, second: 0, end: 0}});
    const [installationDetails, setInstallationDetails] = useState<any>(edit? targetDetails?.installation: {target: 0, actual: 0, estimation: {start: 0, first: 0, second: 0, end: 0}});
    const [collections, setCollections] = useState<Collections>(edit? targetDetails?.collections: {
        recievable: { target: 0, actual: 0 },
        freshSales: { target: 0, actual: 0 },
        estimation: {start: 0, first: 0, second: 0, end: 0}
    });
   
    const currentYear = new Date().getFullYear();

    const yearList: OptionsOrGroups<any, any> | undefined = Array.from({ length: 21 }, (_, i) => ({
                                                            value: currentYear - 10 + i,
                                                            label: `${currentYear - 10 + i}`
                                                        }));


    const handleSave = () => {
        
        // Save logic here
        const payload = {
            id: '',
            region: selectedRegion.value,
            month: selectedMonth.value,
            year: selectedYear.label,
            revenue: revenueDetails,
            installation: installationDetails,
            collections: collections,
        }
        if(edit){
            payload.id = targetDetails.id;
            updateTarget(payload).then((res) => {
                console.log(res);
                if(res.error){
                    alert('Error in Target Updation')
                }
                else {
                    alert('Target Updated');
                    updateData();
                }
            })
        }
        else{
            postTarget(payload).then((res) => {
                console.log(res);
                if(res.error){
                    alert('Error in Target Addition')
                }
                else {
                    alert('Target Added')
                    updateData();
                }
            });
        }
        closeModal();
    };

    const handleRegionChange = (value: any) => {
        setSelectedRegion(value)
        
      }

    const handleDropdownChange = (dropdown: string, selected: MultiValue<any>) => {
        console.log(selected);
        
        switch(dropdown){
            case 'region':
                handleRegionChange(selected);
                break;
            case 'month':
                setSelectedMonth(selected);
                break;
            case 'year':
                setSelectedYear(selected);
                break;
        }
      }

    useEffect(() => {
        console.log(targetDetails);
        console.log(region);
        region.map((item) => {
            if(item.value===selectedRegion){
                setSelectedRegion(item);
            }
        })
    }, []);

    useEffect(() => {
        setCollections({ ...collections, estimation: { ...collections.estimation, end: Number(parseFloat((collections.freshSales.actual + collections.recievable.actual).toString()).toFixed(2)) } })
    }, [collections.freshSales.actual, collections.recievable.actual])

    useEffect(() => {
        setRevenueDetails({ ...revenueDetails, estimation: { ...revenueDetails.estimation, end: Number(parseFloat(revenueDetails.actual).toFixed(2)) } })
    }, [revenueDetails.actual])

    useEffect(() => {
        setInstallationDetails({ ...installationDetails, estimation: { ...installationDetails.estimation, end: parseInt(installationDetails.actual)} })
    }, [installationDetails.actual])

    

    return (
        
            <Grid container spacing={2} padding={2}>
                <Typography variant="h6">{targetDetails? 'Add Target': 'Edit Target'}</Typography>

                
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Month</Typography>
                    <Select
                        options={months}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        onChange={(selected: MultiValue<any>) => handleDropdownChange('month', selected)}
                        placeholder="Select Month"
                        value={selectedMonth}
                        isClearable
                        
                    />
                    
                    
                    <Typography variant="subtitle1">Year</Typography>
                    <Select
                        options={yearList}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        onChange={(selected: SingleValue<any>) => handleDropdownChange('year', selected)}
                        placeholder="Select Year"
                        isClearable
                        value={selectedYear}
                    />

                    
                    <Grid width={380} mr={1}>
                        <Typography variant="subtitle1">Region</Typography>
                        <Select
                            options={region}
                            onChange={(selected: MultiValue<any>) => handleDropdownChange('region', selected)}
                            isSearchable={true}
                            placeholder={'- select -'}
                            value={selectedRegion}
                            isClearable
                            
                        />
                    </Grid>
                    
                </Grid>
                
                <Grid item xs={12}>
                    <Typography variant="h6">Revenue</Typography>
                    {/* Form fields and inputs here */}
                    
                    <Grid item xs={12} display={'flex'}>
                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Target (in Lacs)</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                // value={targetDetails?.revenue.target ?? ''}
                                value={revenueDetails.target}
                                onChange={(e) => setRevenueDetails({ ...revenueDetails, target: parseFloat(e.target.value) })}
                                placeholder="Enter target revenue"

                            />
                        </Grid>

                        <Grid item xs={12} style={{ marginLeft: '10px' }}>
                            <Typography variant="subtitle1">Actual (in Lacs)</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                value={revenueDetails.actual}
                                onChange={(e) => setRevenueDetails({ ...revenueDetails, actual: parseFloat(e.target.value) })}
                                placeholder="Enter actual revenue"
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6">Estimations</Typography>
                    <Grid container xs={12} display={'flex'}>
                        
                        <Grid item xs={2} style={{ marginLeft: '10px' }}>
                            <Typography variant="subtitle1">Start</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                // value={targetDetails?.revenue.target ?? ''}
                                value={revenueDetails.estimation.start}
                                onChange={(e) => setRevenueDetails({ ...revenueDetails, estimation: { ...revenueDetails.estimation, start: parseFloat(e.target.value) } })}
                                placeholder="Enter start estimation"

                            />
                        </Grid>

                        <Grid item xs={2} style={{ marginLeft: '10px' }}>
                            <Typography variant="subtitle1">1st Review</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                value={revenueDetails.estimation.first}
                                onChange={(e) => setRevenueDetails({ ...revenueDetails, estimation: { ...revenueDetails.estimation, first: parseFloat(e.target.value) } })}
                                placeholder="Enter first review estimation"
                            />
                        </Grid>

                        <Grid item xs={2} style={{ marginLeft: '10px' }}>
                            <Typography variant="subtitle1">2nd Review</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                value={revenueDetails.estimation.second}
                                onChange={(e) => setRevenueDetails({ ...revenueDetails, estimation: { ...revenueDetails.estimation, second: parseFloat(e.target.value) } })}
                                placeholder="Enter second review estimation"
                            />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Collections</Typography>
                    {/* Form fields and inputs here */}
                    
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" fontWeight={600}>Recievables</Typography>
                        <Grid item xs={12} display={'flex'}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Target (in Lakhs)</Typography>
                                <TextField
                                    type="number"
                                    fullWidth
                                    onChange={(e) => setCollections({ ...collections, recievable: { ...collections.recievable, target: parseFloat(e.target.value) } })}
                                    placeholder="Enter target recievables"
                                    value={collections.recievable.target}
                                />
                            </Grid>
                            
                            <Grid item xs={12} style={{ marginLeft: '10px' }}>
                                <Typography variant="subtitle1">Actual (in Lakhs)</Typography>
                                <TextField
                                    type="number"
                                    fullWidth
                                    // value={targetDetails?.installation.target ?? ''}
                                    value={collections.recievable.actual}
                                    onChange={(e) => setCollections({ ...collections, recievable: { ...collections.recievable, actual: parseFloat(e.target.value) } })}
                                    placeholder="Enter actual recievables"
                                />
                            </Grid>
                        </Grid>



                        <Typography variant="subtitle1" fontWeight={600}>Fresh Sales</Typography>
                        <Grid item xs={12} display={'flex'}>
                            <Grid item xs={12} >
                                <Typography variant="subtitle1">Target (in Lakhs)</Typography>
                                <TextField
                                    type="number"
                                    fullWidth
                                    onChange={(e) => setCollections({ ...collections, freshSales: { ...collections.freshSales, target: parseFloat(e.target.value) } })}
                                    placeholder="Enter target fresh sales"
                                    value={collections.freshSales.target}
                                />
                            </Grid>
                            
                            <Grid item xs={12} style={{ marginLeft: '10px' }} >
                                <Typography variant="subtitle1">Actual (in Lakhs)</Typography>
                                <TextField
                                    type="number"
                                    fullWidth
                                    // value={targetDetails?.installation.target ?? ''}
                                    value={collections.freshSales.actual}
                                    onChange={(e) => setCollections({ ...collections, freshSales: { ...collections.freshSales, actual: parseFloat(e.target.value) } })}
                                    placeholder="Enter actual fresh sales"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Typography variant="h6">Estimations</Typography>
                    <Grid container xs={12} display={'flex'}>
                        
                        <Grid item xs={2} style={{ marginLeft: '10px' }}>
                            <Typography variant="subtitle1">Start</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                // value={targetDetails?.revenue.target ?? ''}
                                value={collections.estimation.start}
                                onChange={(e) => setCollections({ ...collections, estimation: { ...collections.estimation, start: parseFloat(e.target.value) } })}
                                placeholder="Enter start estimation"

                            />
                        </Grid>

                        <Grid item xs={2} style={{ marginLeft: '10px' }}>
                            <Typography variant="subtitle1">1st Review</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                // value={targetDetails?.revenue.target ?? ''}
                                value={collections.estimation.first}
                                onChange={(e) => setCollections({ ...collections, estimation: { ...collections.estimation, first: parseFloat(e.target.value) } })}
                                placeholder="Enter first review estimation"
                            />
                        </Grid>

                        <Grid item xs={2} style={{ marginLeft: '10px' }}>
                            <Typography variant="subtitle1">2nd Review</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                // value={targetDetails?.revenue.target ?? ''}
                                value={collections.estimation.second}
                                onChange={(e) => setCollections({ ...collections, estimation: { ...collections.estimation, second: parseFloat(e.target.value) } })}
                                placeholder="Enter second review estimation"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Installation</Typography>
                    {/* Form fields and inputs here */}
                    
                    <Grid item xs={12} display={'flex'}>
                        
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">Target (in Units)</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                onChange={(e) => setInstallationDetails({ ...installationDetails, target: parseInt(e.target.value) })}
                                placeholder="Enter target installations"
                                value={installationDetails.target}
                            />
                        </Grid>
                        
                        <Grid item xs={12} style={{ marginLeft: '10px' }}>
                            <Typography variant="subtitle1">Actual (in Units)</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                // value={targetDetails?.installation.target ?? ''}
                                onChange={(e) => setInstallationDetails({ ...installationDetails, actual: parseInt(e.target.value) })}
                                placeholder="Enter actual installations"
                                value={installationDetails.actual}
                            />
                        </Grid>
                    </Grid>

                    <Typography variant="h6">Estimations</Typography>
                    <Grid container xs={12} display={'flex'}>
                        <Grid item xs={2} style={{ marginLeft: '10px' }}>
                            <Typography variant="subtitle1">Start</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                // value={targetDetails?.revenue.target ?? ''}
                                onChange={(e) => setInstallationDetails({ ...installationDetails, estimation: { ...installationDetails.estimation, start: parseInt(e.target.value) } })}
                                placeholder="Enter start estimation"
                                value={installationDetails.estimation.start}
                            />
                        </Grid>

                        <Grid item xs={2} style={{ marginLeft: '10px' }}>
                            <Typography variant="subtitle1">1st Review</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                // value={targetDetails?.revenue.target ?? ''}
                                onChange={(e) => setInstallationDetails({ ...installationDetails, estimation: { ...installationDetails.estimation, first: parseInt(e.target.value) } })}
                                placeholder="Enter first review estimation"
                                value={installationDetails.estimation.first}
                            />
                        </Grid>

                        <Grid item xs={2} style={{ marginLeft: '10px' }}>
                            <Typography variant="subtitle1">2nd Review</Typography>
                            <TextField
                                type="number"
                                fullWidth
                                // value={targetDetails?.revenue.target ?? ''}
                                onChange={(e) => setInstallationDetails({ ...installationDetails, estimation: { ...installationDetails.estimation, second: parseInt(e.target.value) } })}
                                placeholder="Enter second review estimation"
                                value={installationDetails.estimation.second}
                            />
                        </Grid>

                    </Grid>
                </Grid>
                
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={closeModal} style={{ marginLeft: '10px' }}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        
    );
};


export default TargetDetails;

