import { Button, Typography } from "@mui/material";
import { FC } from "react";

type Color = "primary" | "info" | "inherit" | "success" | "warning" | "error" | "secondary";

interface InfoModalButtonProps {
  onClick?: () => void;
  color?: Color;
  style?: any;
  text?: string;
  icon?: any;
}

export const InfoModalButton: FC<InfoModalButtonProps> = (props) => {
  let { onClick, color, text, style, icon } = props;

  return (
    <Button onClick={onClick} color={color || 'primary'} style={style || { marginBottom: '5px' }} >
      {!!icon && <img src={icon} alt={!!text ? text : 'Icon'} style={{ objectFit: 'contain', width: '12px', marginRight: '10px' }} />}
      <Typography variant='caption' textTransform={'capitalize'}> {text || 'Option'} </Typography>
    </Button>
  )
}

interface InfoModalButtonWithIconProps {
  text?: string;
  onClick?: () => void;
  icon: any;
}

const ButtonWithIconStyle: any = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  borderRadius: '14px',
  border: '0.4px solid rgba(255, 255, 255, 0.36)',
  width: '95px',
  height: '50px',
}

export const InfoModalButtonWithIcon: FC<InfoModalButtonWithIconProps> = ({ text, icon, onClick }) => (
  <Button
    style={ButtonWithIconStyle}
    variant={'outlined'}
    color={'inherit'}
    onClick={onClick}
  >
    <img src={icon} alt={text} style={{ objectFit: 'contain', width: '17px' }} />
    <Typography textTransform={'capitalize'} mt={0.5} color={'white'} style={{
      // fontFamily: 'Inter',
      fontSize: '8px'
    }}>
      {text}
    </Typography>
  </Button>
)