import { useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../services/user.service";

const Unauthorized = (): JSX.Element => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleLogout = () => {
    logoutUser().then(() => {
      navigate('/login', { replace: true, state })
    })
  }

  const goBack = () => {
    navigate(-1);
  }

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <p style={{ fontSize: '18px', color: 'red' }}>
        You are not authorized for this page
      </p>
      <button
        onClick={handleLogout}
        style={{
          backgroundColor: 'blue',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          margin: '10px',
          cursor: 'pointer',
        }}
      >
        Wanna login with different account?
      </button>
      <button
        onClick={goBack}
        style={{
          backgroundColor: 'green',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          margin: '10px',
          cursor: 'pointer',
        }}
      >
        Go back
      </button>
    </div>
  )
}

export default Unauthorized;