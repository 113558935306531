import { getObservationType } from 'src/services/observationType.service';
import React, { useEffect, useState } from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { SCALES, getScaleValue } from 'src/pages/ObservationType/components/AddScales';
import { Skeleton } from 'src/components';

type Props = {
  id: string;
  detail: any;
  setObervationTypeDetail: (detail: any) => void;
}

const ObservationTypeDetail: React.FC<Props> = ({ id, detail, setObervationTypeDetail }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!!id)
      fetchDetail(id);
  }, [id]); // eslint-disable-line

  // api calls
  const fetchDetail = (id: string) => {
    setIsLoading(true);

    getObservationType(id)
      .then(res => {
        setObervationTypeDetail(res);
      })
      .catch(error => {
        console.error("Error", error);
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  if (isLoading) {
    return (
      <Skeleton.BoxWithText height={400} width={500} />
    )
  }

  const scales = detail?.scale ?? [];

  return (
    <Grid>
      <Typography> Observation Type </Typography>
      <Typography my={2} fontSize={12} variant='caption'>{detail?.description ?? ""}</Typography>

      <Grid mt={2}>
        <TableContainer component={Paper}>
          <Table aria-label="Ratings Table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell style={TableCellStyle}>From</TableCell>
                <TableCell style={TableCellStyle}>To</TableCell>
                <TableCell style={TableCellStyle}>Rating</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                scales.map((_: any, index: number) => {
                  const fromValue = getScaleValue(scales, index, 1);
                  const toValue = getScaleValue(scales, index, 2);

                  return (
                    <TableRow key={`rating-${index}`}>
                      <TableCell padding='none' style={TableCellStyle} >{fromValue}</TableCell>
                      <TableCell padding='none' style={TableCellStyle} >{toValue}</TableCell>
                      <TableCell component="th" padding='none' scope="row" style={TableCellStyle} >{SCALES[index]}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default ObservationTypeDetail;

const TableCellStyle = { padding: "5px 10px" };