import { Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Mappings } from '../constants/WordMapping'
interface Props {
    location: any;
    func: any;
    sensor: any;
    analysisData: any;
    place: string
}
const AnalysisComp: React.FC<Props> = (props: Props): JSX.Element => {
    const { location, func, sensor, analysisData, place } = props
    return (
        <div
            style={{

                width: '99%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <>
                {location.length > 0 && (
                    <Typography
                        fontSize={21}
                        fontWeight={'bold'}
                        margin={4}
                    >
                        {place.toUpperCase()}
                    </Typography>
                )}
            </>
            {location?.map((i: string) => (
                <Grid flexDirection={"column"}>
                    <Typography
                        fontSize={18}
                        fontWeight={'bold'}
                        textAlign={'center'}
                        margin={7}
                    >{i.toUpperCase()}</Typography>
                    <table
                        style={{
                            borderWidth: 2,
                            borderColor: '#B6F7BC',
                            width: '99%',
                        }}
                    >
                     
                        <tr
                        style={{
                            backgroundColor: '#B6F7BC'
                        }}
                        >
                            <th
                                style={{
                                    borderBottomWidth: 1,
                                    borderBottomColor: 'black'
                                }}
                            >Field</th>
                            {func?.map((func: string) => (

                                <>
                                    <>
                                        {func === 'mean' && (
                                            <th
                                                style={{
                                                    borderBottomWidth: 1,
                                                    borderBottomColor: 'black'
                                                }}
                                            >
                                                Average
                                            </th>
                                        )}

                                    </>
                                    <>
                                        {func === 'sum' && (
                                            <th
                                                style={{
                                                    borderBottomWidth: 1,
                                                    borderBottomColor: 'black'
                                                }}
                                            >
                                                Total
                                            </th>
                                        )}
                                    </>
                                    <>
                                        {func !== 'mean' && func !== 'sum' && (
                                            <th
                                                style={{
                                                    borderBottomWidth: 1,
                                                    borderBottomColor: 'black'
                                                }}
                                            >
                                                {`${func[0].toUpperCase()}${func.slice(1)}`}</th>

                                        )}
                                    </>
                                </>

                            ))}
                        </tr>
    
                        {sensor?.map((sens: string) => (
                            <>
                                <tr
                                style={{
                                    backgroundColor: '#E6E6E6'
                                }}
                                >
                                    <td
                                        style={{
                                            textAlign: 'center'
                                        }}
                                    > 
                                    {Mappings[sens] ?? sens}
                                    </td>

                                    {func?.map((func: string) => {

                                        let value = analysisData[func]?.[place]?.[sens]
                                        if (value)
                                            return (

                                                <>

                                                    <td
                                                        style={{
                                                            textAlign: 'center'
                                                        }}
                                                    >{analysisData[func]?.[place]?.[sens][i]}</td>
                                                </>
                                            )
                                        return (
                                            <td
                                                style={{
                                                    textAlign: 'center'
                                                }}
                                            >-</td>
                                        )
                                    })}
                                </tr>

                            </>
                        ))}
                    </table>

                </Grid>
            ))}
        </div >
    )
}

export default AnalysisComp