import React, { useState } from 'react';

interface Props {
    onChange: any;
    value: any;
}

const MonthYearPicker: React.FC<Props> = ({ onChange, value }) => {
    const [selectedMonth, setSelectedMonth] = useState(value.month || '');
    const [selectedYear, setSelectedYear] = useState(value.year || '');

    const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const month = e.target.value;
        setSelectedMonth(month);
        onChange({ month, year: selectedYear });
    };

    const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const year = e.target.value;
        setSelectedYear(year);
        onChange({ month: selectedMonth, year });
    };

    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 10;
    const endYear = currentYear + 10;

    return (
        <div style={{ alignItems: 'center' }}>
            <select
                value={selectedMonth}
                onChange={handleMonthChange}
                style={{ marginRight: '10px', padding: '10px', borderRadius: '8px', fontSize: '16px' }}
            >
                <option value="">Select Month</option>
                <option value={1}>January</option>
                <option value={2}>February</option>
                <option value={3}>March</option>
                <option value={4}>April</option>
                <option value={5}>May</option>
                <option value={6}>June</option>
                <option value={7}>July</option>
                <option value={8}>August</option>
                <option value={9}>September</option>
                <option value={10}>October</option>
                <option value={11}>November</option>
                <option value={12}>December</option>
            </select>
            <select
                value={selectedYear}
                onChange={handleYearChange}
                style={{ padding: '10px', borderRadius: '8px', fontSize: '16px' }}
            >
                <option value="">Select Year</option>
                {Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i).map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default MonthYearPicker;