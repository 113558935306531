import { Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect, useState, memo, useRef, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { PlotFilters, PlotMap, PlotTable } from 'src/components/';
import { KeyValues, Roles } from 'src/constants/interfaces';
import { isDashboardConditionsSatisfied, isLoggedIn, isOpenURL } from 'src/utils/helper';
import { Close } from '@mui/icons-material';
import { useRecoilState, useRecoilValue } from 'recoil';
import { columnFilteredPlotsAtom, filteredPlotsAtom, plotsAtom } from 'src/recoil/atom/plotsAtom';
import _ from 'lodash';
import { UserPlotTable } from './components';

const iconButtonStyle = { fontSize: '12px', width: '20px', height: '20px', padding: '3px' }

const Dashboard: React.FC = (): JSX.Element => {

  const [plotIds, setPlotIds] = useState<string[]>([])
  const ref: any = useRef(null)
  const [showDashboard, setShowDashboard] = useState<boolean>(false);
  const navigate = useNavigate();

  const userRoles = JSON.parse(localStorage.getItem('roles') || '[]')
  const isAdmin: string[] = userRoles.includes(Roles.ADMIN) || userRoles.includes(Roles.SUPPORT)
  const userPlotViewString = localStorage.getItem("plotView");
  const userPlotView = userPlotViewString ? JSON.parse(userPlotViewString) : isAdmin;

  const location = useLocation();

  // maps
  const [showMap, setShowMap] = useState<boolean>(false);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false)
  const [isGridView, toggleGridView] = useReducer(state => !state, !!userPlotView);
  const [mapLocations, setMapLocations] = useState<KeyValues[]>()

  // recoil
  const [selectedPlots, updatedPlotAtom] = useRecoilState(plotsAtom)
  const [filteredPlots, updateFilteredPlots] = useRecoilState(filteredPlotsAtom)
  const columnFilteredPlots = useRecoilValue(columnFilteredPlotsAtom)

  useEffect(() => {
    if (!isLoggedIn() && !isOpenURL(window.location.pathname)) {
      navigate('/login', { replace: true })
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("plotView", `${isGridView}`)
  }, [isGridView])

  useEffect(() => {
    const isDashboardPath = isDashboardConditionsSatisfied(location.pathname)
    setShowDashboard(isDashboardPath)
  }, [location])

  useEffect(() => {
    if (columnFilteredPlots?.ids?.length > 0) {
      handlePlotIdChange(!!plotIds && plotIds.length > 0 ? _.intersection(columnFilteredPlots.ids, plotIds) : columnFilteredPlots.ids);
    } else {
      intersectionOfSelectedAndFiltered();
    }
  }, [columnFilteredPlots])

  useEffect(() => {
    intersectionOfSelectedAndFiltered();
  }, [selectedPlots, filteredPlots])

  const intersectionOfSelectedAndFiltered = () => {
    const fp = filteredPlots || []
    const sp = selectedPlots.ids || []
    if ((fp.text || '').length > 0 && sp.length > 0) {
      handlePlotIdChange(_.intersection(fp.ids, sp))
    } else if ((fp.text || '').length > 0) {
      handlePlotIdChange(fp.ids)
    } else if (sp.length > 0) {
      handlePlotIdChange(sp)
    } else {
      setPlotIds([])
    }
  }

  const handlePlotIdChange = (newPlotIds: string[]) => {
    setPlotIds(newPlotIds)
  }

  const handleDashboardFilterChange = (newPlotIds: string[], filterApplied: boolean) => {
    updateFilteredPlots({ ids: newPlotIds, text: filterApplied ? 'Applied' : '' })
  }

  const handleMapLoaded = (status: boolean) => {
    setMapLoaded(status)
  }

  const handleMapLocations = (newMapLocations: KeyValues[]) => {
    setMapLocations(newMapLocations)
  }

  const toggleShowMap = () => {
    setShowMap(!showMap)
  }

  const emptyState = () => {
    updatedPlotAtom({ text: '', ids: [] })
  }

  const removeFilter = () => {
    navigate("/home", { replace: true })
    emptyState();
  }


  return (
    <Grid container display={showDashboard ? 'block' : 'none'} >
      {/* filters */}

      <Grid item xs={12}>
        <PlotFilters handlePlotIdChange={handleDashboardFilterChange} isGridView={isGridView} toggleGridView={toggleGridView} />
      </Grid>

      {selectedPlots.text?.length > 0 && (
        <Grid pt={2} pl={2}>
          <h5 style={{ marginBottom: '5px', marginTop: '0' }}>Labels</h5>
          <Grid container alignItems={'center'}>
            <Typography
              bgcolor={'Highlight'} px={1} borderRadius={2}
              variant='caption'
              display={'flex'}
              alignItems={'center'}
            >
              {selectedPlots.text}
              <IconButton onClick={removeFilter} style={iconButtonStyle}>
                <Close fontSize='inherit' color='action' style={{
                  stroke: 'black',
                  strokeWidth: 1
                }} />
              </IconButton>
            </Typography>
          </Grid>
        </Grid>

      )}

      {/* table & map */}
      <Grid container >
        <Grid ref={ref} item xs={showMap ? 7 : 12} p={0}>
          {isGridView ? (
            <PlotTable
              plotIds={plotIds}
              mapLoaded={mapLoaded}
              setMapLoaded={handleMapLoaded}
              setMapLocations={handleMapLocations}
              toggleMap={toggleShowMap}
            />
          ) : (
            <UserPlotTable
              plotIds={plotIds}
            />
          )}
        </Grid>
        {showMap &&
          <Grid item mt={5} md={5} xs={12} pr={1} position={'relative'} >
            <PlotMap
              mapLoaded={mapLoaded}
              mapLocations={mapLocations as KeyValues[]}
              height={900}
              polygonRegion={{}}
              handlePolygonClick={() => { }}
            />
          </Grid>}
      </Grid>
    </Grid>
  )
}

export default memo(Dashboard)
