import { Grid, IconButton, Modal, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router';
import { postpetioleAnalysisConfig, updatepetioleAnalysisConfig, deletepetioleAnalysisConfig } from '../services/plot.service'
import { generateOptions } from '../utils/helper';
import { getCropOptions } from "src/services/crop.service";
import Select, { SingleValue } from "react-select";
import LocaleDropdown from 'src/components/LocaleDropdown';
interface Props {
    selectedModalData?: any;
    setOpenModal?: any;
    appendGrid?: any
}

const DropdownProps = {
    isClearable: true,
    isSearchable: true,
    placeholder: '- select -',
    styles: {
        container: (baseStyles: any) => ({
            ...baseStyles,
            width: '100%',
            padding: 2,
            fontSize: 16,
            margin: '3px 0',
            marginBottom: 11,
            marginTop: 6
        }),
    }
}

const PetioleAnalysis: React.FC<Props> = (props: Props): JSX.Element => {
    const res: any = props?.selectedModalData;
    const [content, setContent] = useState<any>(res || {})
    const [stage, setStage] = useState<SingleValue<any>>([])
    const [cropId, setcropId] = useState<any>(res?.cropId || "")
    const [cropOptions, setcropOptions] = useState<any>();

    const onSubmit = () => {
        content.cropId = cropId;
        content.name = stage?.label;
        content.stageId = stage?.value;
        if (window.confirm("Do you want to submit?")) {
            if (content?.id) {
                updatepetioleAnalysisConfig(content)
                    .then((res: any) => {
                        if (res?.count === 1) {
                            alert("Updated");
                            props?.appendGrid(0)
                        }
                        else
                            alert("Some Error Occured");
                    })

            }
            else {
                postpetioleAnalysisConfig(content)
                    .then((res: any) => {
                        if (res.id) {
                            alert("Petiole Analysis added");
                            props?.appendGrid(0)
                        }
                        else
                            alert("Some Error Ocuured");
                    })
            }
        }
        props.setOpenModal(false);
    }

    const onDelete = () => {
        if (content.id) {
            if (window.confirm("Do you want to delete?")) {
                if (content.id) {
                    deletepetioleAnalysisConfig(content)
                        .then((res: any) => {
                            if (res?.count === 1){
                                alert("Configuration Deleted");
                                props?.appendGrid(0)
                            }
                            else
                                alert("Some Error Occured");
                        })
                }
                else
                    alert("Some Error Occured");
            }
        }
        else
            alert("No such Configuration");
        props.setOpenModal(false);
    }

    useEffect(() => {
        getCropOptions()
            .then((res: any) => {
                setcropOptions(
                    [{
                        label: '--select--',
                        value: '--select--'
                    }].concat(generateOptions(res, 'id', 'cropName') as any))
            })
    }, [])

    useEffect(() => {
        if (content?.stageId) {
            setStage(content?.stageId)
        }
    }, [])
    const navigate = useNavigate()

    return (
        <Grid width={'90%'} my={2} mx={'auto'} boxShadow={2} borderRadius={4} paddingBottom={5}>
            <Grid p={2} borderRadius={20}  >
                <Grid display={'flex'} item alignItems={'center'}>
                    <IconButton onClick={() => props?.setOpenModal ? props.setOpenModal(false) : navigate('/nutrient')} ><ArrowBack /></IconButton>
                    <Typography m={1} variant='h5'>Type</Typography>
                </Grid>

            </Grid>

            <div
                style={{
                    justifyContent: "center",
                    padding: 30,
                }}
            >
                <form>
                    <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }} >Crop</label>
                    <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red' }}>*</label>
                    <select onChange={(e) => { setcropId(e.target.value) }}
                        style={{ width: '97%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11, marginTop: 10 }}
                        value={cropId}
                    >
                        {cropOptions?.map(({ value, label }: any, index: any) => <option value={value} >{label}</option>)}
                    </select><br />

                    <LocaleDropdown
                        value={stage}
                        title='Stage'
                        titleStyle={{ fontSize: 18, fontWeight: 'bold', width: '15%' }}
                        onChange={(selected: SingleValue<any>) => {
                            setStage(selected);
                        }}
                        containerStyle={{ width: '99%' }}
                    />

                    <Grid display={"flex"} justifyContent={"space-between"} style={{ marginTop: 55 }} >
                        <input type="button" value="Submit" onClick={onSubmit} style={{ width: '45%', padding: '20px', fontSize: 18, paddingTop: '15px' }} />
                        <input type="button" value="Delete" onClick={onDelete} style={{ width: '45%', padding: '20px', fontSize: 18, paddingTop: '15px' }} />
                    </Grid>
                </form>
            </div>
        </Grid >
    )
}

export default PetioleAnalysis