import { ChartColors } from 'src/constants/interfaces';
import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2';
import { Grid } from '@mui/material';

type Props = {
  data: any,
  expiredPlots?: any[]
}

const generateBarChartOptions = (chartData: any[], label: string) => {
  return {
    plugins: {
      title: {
        display: true,
        text: label,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const { dataIndex } = context;

            const labels = chartData.map((data) => ({ label: data.label, data: data.data[dataIndex] }));

            let myLabel = "";

            let sum = 0;
            labels.forEach((label) => {
              myLabel += `${label.label}: ${label.data ?? 0}   `;
              sum += label.data ?? 0;
            })

            myLabel += `Total: ${sum}`;
            return myLabel;
          }
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          gap: 10,
          autoSkip: false,
          minRotation: 45,
          maxRotation: 45
        },
      },
      y: {
        stacked: true,
      }
    }
  }
};

const Charts: React.FC<Props> = (props) => {
  const { data, expiredPlots } = props;

  const [chartData, setChartData] = useState<any>();
  const [expiredPlotsChart, setExpiredPlotsChart] = useState<any>();
  const [chartData3, setChartData3] = useState<any>();

  useEffect(() => {
    if (!!data) {
      setChartData(generateChartData(data, "district", "rechargeType"));
      setChartData3(generateChartData(data, "district", "deviceType"));
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    if (!!expiredPlots) {
      setExpiredPlotsChart(generateChartData(expiredPlots, "district", "deviceType"));
    }
  }, [expiredPlots]);

  return (
    <Grid container gap={2} justifyContent={"center"}>
      <div style={{ display: "flex", justifyContent: "center", width: "100%", gap: "50px" }}>
        <Grid item xs={5}>
          {!!chartData && (
            <Bar 
              data={chartData} 
              options={generateBarChartOptions(chartData.datasets, "District Wise Activated Recharge")} 
            />
          )}
        </Grid>
        <Grid item xs={5}>
          {!!chartData3 && (
            <Bar 
              data={chartData3} 
              options={generateBarChartOptions(chartData3.datasets, "District Wise Activated Device")} 
            />
          )}
        </Grid>
      </div>
      {!!expiredPlotsChart &&
        <Grid item xs={10} height={700} position={"relative"} overflow={"scroll"}>
          <Grid width={"1400px"}>
            <Bar
              style={{ position: "absolute", left: 0, top: 0 }}
              data={expiredPlotsChart}
              options={generateBarChartOptions(expiredPlotsChart.datasets, "District Wise Expired Plots (As of Today)")}
            />
          </Grid>
        </Grid>
      }
    </Grid>
  )
}

export default Charts;

const generateChartData = (dataArr: any[], labelField: string, valueField: string) => {
  const labelSet = new Set<string>();
  dataArr.forEach(data => {
    labelSet.add(data[labelField]);
  })

  const labels = Array.from(labelSet).sort(); // District Names
  
  const labelValueMapping: Record<string, Record<string, number>> = {}; // Monthly, Yearly, Quaterly etc
  
  const valueLabelSet = new Set<string>();
  
  dataArr.forEach(data => {
    const label = data[labelField];
    const value = data[valueField];
    valueLabelSet.add(value);
    
    if (labelValueMapping.hasOwnProperty(label)) {
      if (labelValueMapping[label].hasOwnProperty(value)) {
        labelValueMapping[label][value] = labelValueMapping[label][value] + 1;
      } else {
        labelValueMapping[label][value] = 1;
      }
    } else {
      labelValueMapping[label] = { [value]: 1 };
    }
  });
  
  const valueLabels = Array.from(valueLabelSet).sort();

  labels.sort((a, b) => {
    const sum1 = Object.values(labelValueMapping[a] ?? {}).reduce((sum, curr) => sum + curr, 0);
    const sum2 = Object.values(labelValueMapping[b] ?? {}).reduce((sum, curr) => sum + curr, 0);    
    return sum2 - sum1;
  })


  const datasets: any[] = [];

  valueLabels.forEach((valueLabel, index) => {
    const currDataset: any = {
      label: valueLabel,
      data: [],
      borderColor: ChartColors[index],
      backgroundColor: ChartColors[index],
      minBarLength: 10,
      maxBarThickness: 50,
    };
    labels.forEach(label => {
      currDataset.data.push(labelValueMapping[label][valueLabel]);
    })
    datasets.push(currDataset);
  })

  const chartData = {
    labels,
    datasets
  }

  return chartData;
}