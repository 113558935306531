import { CORE_API } from "src/constants/api";
import { getData } from "./common.service";
import { Adherence, IrrigationAdherence } from "src/pages/Adherence/types";

const EndPoints = {
	spray: '/plots/spray-adherence',
	nutrient: '/plots/nutrient-adherence',
	irrigation: '/plots/irrigation-adherence',
	earlyIrrigation: '/plots/early-irrigations'
}

export const getSprayAdherence = (payload: GetAdherenceRequest, signal?: AbortSignal): Promise<Adherence[]> => {
	const url = EndPoints.spray + `?${generateParams(payload)}`;
	
	return getData(url, {
		sendTokenInHeader: true,
		basePath: CORE_API,
		signal
	})
}
export const getNutrientAdherence = (payload: GetAdherenceRequest, signal?: AbortSignal): Promise<Adherence[]> => {
	const url = EndPoints.nutrient + `?${generateParams(payload)}`;
	
	return getData(url, {
		sendTokenInHeader: true,
		basePath: CORE_API,
		signal
	})
}
export const getIrrigationAdherence = (payload: GetAdherenceRequest, signal?: AbortSignal): Promise<IrrigationAdherence[]> => {
	const url = EndPoints.irrigation + `?${generateParams(payload)}`;
	
	return getData(url, {
		sendTokenInHeader: true,
		basePath: CORE_API,
		signal
	})
}

export const getIEarlyrrigation = (payload: GetAdherenceRequest, signal?: AbortSignal): Promise<IrrigationAdherence[]> => {
	const url = EndPoints.earlyIrrigation + `?${generateParams(payload)}`;
	
	return getData(url, {
		sendTokenInHeader: true,
		basePath: CORE_API,
		signal
	})
}

/**
 * ================== TYPES ======================
 */

export type GetAdherenceRequest = {
	startTime: string;
	endTime: string;
	state?: string;
	district?: string;
	subDistrict?: string;
	village?: string;
}

/**
 * ================== HELPER ======================
 */

const generateParams = (payload: GetAdherenceRequest) => {
	return Object.entries(payload??{}).reduce((str, curr) => {
		return str + (str.length === 0 ? "" : "&") + `${curr[0]}=${curr[1]}`;
	}, "")
}
