import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { Option } from "src/constants/interfaces";
import {
  CropFilter,
  DateFilter,
  DateRangeFilter,
  DeviceFilter,
  FarmFilter,
  FarmManagerFilter,
  FarmUserFilter,
  InactivePlotFilter,
  LocationFilter,
  OutlierPlotFilter,
  PlotFilter,
  SensorFilter
} from ".";
import { generateOptions } from "src/utils/helper";
import { ButtonType, FilterProps } from "./types";

const DropdownFilters: React.FC<Props> = (props) => {
  const { onSubmit, filters, supportmultipleFilters, supportMultipleInstanceOfSameFilter, actionButtonType } = props;

  const [filterOptions, setFilterOptions] = useState<Option[]>([]);

  const [addedFilters, setAddedFilters] = useState<AddedFilters>({});
  // const appliedFilters = useRef<Record<string, FilterValue>>(null);

  useEffect(() => {
    if (!!filters && filters.length > 0) {
      setFilterOptions(generateOptions(filters, "type", "type"));
    }
  }, [filters]) // eslint-disable-line

  // dropdown methods
  const handleDropdown = (filter: SingleValue<Option>) => {
    if (!filter) {
      console.log("remove all filters");
      return;
    }

    if (!supportmultipleFilters && Object.keys(addedFilters).length > 0) {
      console.log("can't add new filter, one filter at a time");
      return;
    }

    const filterType = filter?.value as AvailableFilters;

    const filterAlreadyExists = checkFilterAlreadyExists(filterType);
    if (!supportMultipleInstanceOfSameFilter && filterAlreadyExists) {
      console.log("already opened the filter");
      return;
    }

    addFilter(filterType);
  }

  // filter methods
  const addFilter = (filter: AvailableFilters) => {
    const newFilterId = Math.random().toString(16).slice(2);
    setAddedFilters((addedFilters) => ({ ...addedFilters, [newFilterId]: filter }));
  }

  const checkFilterAlreadyExists = (filter: AvailableFilters): boolean => {
    return Object.values(addedFilters).includes(filter);
  }

  const submitFilters = () => {
    if (!!onSubmit) {
      onSubmit({} as FilterValue);
    }
  }

  const removeFilter = (filterId: string) => {
    setAddedFilters((prevAddedFilters) => {
      const { [filterId]: deletedFilter, ...restFilters } = prevAddedFilters;
      return restFilters;
    });
  }

  return (
    <div>
      <div>
        <Select
          options={filterOptions}
          onChange={handleDropdown}
        />
      </div>

      <div>
        <RenderFilters 
          filtersToRender={addedFilters} 
          filters={filters} 
          actionButtonType={actionButtonType ?? "icon"} 
          removeFilter={removeFilter}
        />
      </div>

      {
        !!onSubmit && (
          <div>
            <button onClick={submitFilters}>Submit</button>
          </div>
        )
      }
    </div>
  );
};

export default DropdownFilters;

const RenderFilters: React.FC<RenderFiltersProps> = (props) => {
  const { filters, filtersToRender, actionButtonType, removeFilter } = props;

  return (
    <div style={{ marginTop: "15px"  }}>
      {
        Object.entries(filtersToRender).map(([id, filter]) => {
          const FilterComponent = FilterMapping[filter];
          const currentFilter = filters.find((filterItr) => filterItr.type === filter);

          const dropdowns = currentFilter?.dropdowns;
          const onChange = currentFilter?.onChange;
          const onSubmit = currentFilter?.onSumbit;
          const onReset = currentFilter?.onReset;

          const onDelete = () => {
            removeFilter(id);
            if(!!currentFilter?.onDelete) {
              currentFilter?.onDelete(id);
            }
          };

          return (
            <div key={id}>
              <FilterComponent 
                filterId={id} 
                onChange={onChange} 
                onSubmit={onSubmit} 
                onDelete={onDelete}
                onReset={onReset}
                hasDeleteButton={!!onDelete}
                hasResetButton={!!onReset}
                hasSubmitButton={!!onSubmit}
                actionButtonType={actionButtonType}
                dropdowns={dropdowns}
                defaultValue={currentFilter?.defaultValue}
              />
            </div>
          )
        })
      }
    </div>
  )
}


/**
 * ================== TYPES ======================
 */
type ValidFormValues = string | number | Option;
type FilterValue = Record<string, ValidFormValues>;
type AvailableFilters =
  | "crop"
  | "date"
  | "date-range"
  | "device"
  | "farm"
  | "farm-user"
  | "plot"
  | "inactive-plot"
  | "location"
  | "outlier-plot"
  | "sensor"
  | "farm-manager";

type Filter = {
  type: AvailableFilters;
  defaultValue?: Record<string, any>;
  onSumbit?: (values: FilterValue, filterId?: string) => void;
  onChange?: (values: FilterValue, filterId?: string) => void;
  onReset?: (filterId?: string) => void;
  onDelete?: (filterId?: string) => void;
  dropdowns?: AvailableDropdowns[];
};

type AvailableDropdowns = 
  "crop" | "crop-variety" | "crop-stage" | "disease" | "pest" | "disease-pest-merged" // crop
  | "state" | "district" | "sub-district" | "village" // location
  | "locale" // locale

export type Props = {
  onSubmit?: (values: FilterValue) => void;
  filters: Filter[];
  supportMultipleInstanceOfSameFilter?: boolean;
  supportmultipleFilters?: boolean;
  actionButtonType?: ButtonType;
};

type AddedFilters = Record<string, AvailableFilters>;

type RenderFiltersProps = {
  filtersToRender: AddedFilters;
  filters: Filter[];
  actionButtonType?: ButtonType;
  removeFilter: (filterId: string) => void;
}

/**
 * ================== Filters Mapping ======================
 */

const FilterMapping: Record<AvailableFilters, React.FC<FilterProps>> = {
  crop: CropFilter,
  date: DateFilter,
  "date-range": DateRangeFilter,
  device: DeviceFilter,
  farm: FarmFilter,
  "farm-user": FarmUserFilter,
  plot: PlotFilter,
  "inactive-plot": InactivePlotFilter,
  location: LocationFilter,
  "outlier-plot": OutlierPlotFilter,
  sensor: SensorFilter,
  "farm-manager": FarmManagerFilter,
}