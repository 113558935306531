import { Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { KeyValues, FilterDataValue, FilterProps } from '../../constants/interfaces'
import { getFarmsByFarmUser } from "../../services/farmUser.service";
import FormikApp from '../DynamicForm'
import { Alert } from "../../components"

const FarmUserFilter: React.FC<FilterProps> = (props: FilterProps): JSX.Element => {
  const { handleFilterDataChange, handleRemove, filterId } = props

  const fields = [
    {
      id: "id",
      label: "Farmer Id",
      placeholder: "Farmer Id",
      type: "text",
      validationType: "string",
      value: '',
      validations: [],
    },
    {
      id: "name",
      label: "Farmer Name",
      placeholder: "Farmer Name",
      type: "text",
      validationType: "string",
      value: '',
      validations: [],
    },
    {
      id: "mobile",
      label: "Mobile Number",
      placeholder: "Mobile Number",
      type: "text",
      validationType: "string",
      value: '',
      validations: [],
    },
  ]


  const [isApplied, setIsApplied] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)

  const handleFitlerChange = (status: boolean, plotIds: FilterDataValue) => {
    handleFilterDataChange(filterId, status, plotIds)
    setIsApplied(status)
  }

  const onSubmit = (values: KeyValues) => {
    const { id, name, mobile } = values
    if (
      (id.length > 0 && id.length < 3) ||
      (name.length > 0 && name.length < 3) ||
      (mobile.length > 0 && mobile.length < 3)
    ) {
      setIsAlertOpen(true)
      return
    }

    getFarmsByFarmUser(values)
      .then((farmUsers: any) => {
        const plotIds = getPlotIds(farmUsers)
        handleFitlerChange(true, plotIds)
      })
  }

  const getPlotIds = (farmUsers: any) => {
    const plotIds: string[] = []
    for (const farmUser of farmUsers) {
      const { farms } = farmUser
      if (!farms) {
        continue;
      }
      for (const farm of farms) {
        const { plots } = farm
        if (!plotIds) {
          continue
        }
        for (const plot of plots) {
          const { plotId } = plot
          plotIds.push(plotId)
        }
      }
    }

    return plotIds
  }

  const onReset = () => {
    handleFitlerChange(false, undefined)
  }

  return (
    <>
      <Typography variant="subtitle2" mt={1}>Farmers</Typography>
      <div style={{
        backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
        margin: '0px 0 15px 0',
        padding: '5px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center'
      }} >
        <FormikApp
          formFields={fields}
          onSubmit={(values: KeyValues) => onSubmit(values)}
          onReset={() => { }}
          filter={true}
          showReset
          removeField={() => handleRemove(filterId)}
        />
      </div>
      <Alert isOpen={isAlertOpen} close={() => setIsAlertOpen(false)} messageFor={'Farmer'} type="error" message="Minimum characters to search should be 3" />
    </>
  )
}

export default memo(FarmUserFilter);