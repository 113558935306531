import { Grid, IconButton, Typography, Modal, Button } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router';
import Select, { MultiValue, SingleValue } from "react-select";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { generateOptions, isArrayWithLength } from '../utils/helper';
import { getCropOptions, getIDPMConfigs, postIDPMConfigs, putIDPMConfigs, deleteIDPMConfig } from "src/services/crop.service";
import { idpmConfigDef } from '../constants/columnDefs';
import {
    ColDef,
    GridOptions,
    GridReadyEvent,
    SideBarDef,
    FilterChangedEvent,
} from 'ag-grid-community';
import { ToastAlert } from 'src/components';
import { ToastSeverity } from 'src/components/ToastAlert/types';
import LocaleDropdown from 'src/components/LocaleDropdown';

const Idpm: React.FC = (): JSX.Element => {
    const gridRef = useRef<AgGridReact>(null);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [isApplied, setIsApplied] = useState(false)
    const [isError, setisError] = useState(false)
    const [isIdpmData, setisIdpmData] = useState(false)
    const [idpmData, setidmData] = useState([])
    const [crops, setCrops] = useState<SingleValue<any>>([])
    const [isCropLoading, setIsCropLoading] = useState<boolean>(true)
    const [cropOptions, setcropOptions] = useState<any>();
    const [selectedCropOption, setSelectedCropOption] = useState<any>(null)
    const [openModal, setOpenModal] = useState(false)
    const [selectedModalData, setSelectedModalData] = useState<any>({
        cropId: String,
        items: [],
        diseasePestId: String
    });
    const [selectedLocale, setselectedLocale] = useState<MultiValue<any>>([])
    const [selectedDiseasePest, setSeelctedDiseasePest] = useState<SingleValue<any>>();
    const [openToast, setOpenToast] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string>('');
    const [toastSeverity, setToastSeverity] = useState<ToastSeverity>('success');

    const navigate = useNavigate();

    const gridOptions: GridOptions = {
        columnDefs: idpmConfigDef,
        rowData: idpmData,
        rowSelection: 'single',
        animateRows: true,
        overlayLoadingTemplate:
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
        onCellClicked: (e) => {
            setselectedLocale(e.data?.items);
            setSelectedModalData(e.data);
            setOpenModal(true);
        }
    };

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            resizable: true,
            sortable: true,
            floatingFilter: true,
            menuTabs: ['filterMenuTab'],
        };
    }, []);

    const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
        return {
            toolPanels: ['filters'],
        };
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        // gridRef?.current?.api.setColumnDefs(farmActivitiesColDef);
        // gridRef?.current?.api.setRowData(tableData);
    }, []);

    // function to perform server side filtering
    const handleColumnFilter = (event: FilterChangedEvent) => {
        if (event.afterFloatingFilter) { }
    }

    const onPaginationChanged = useCallback(() => { }, []);

    const onReset = () => {
        setIsApplied(false);
        setisError(false);
        setisIdpmData(false);
        setidmData([]);
    }

    const onSubmit = async (crop: any) => {
        setisError(false);
        setisIdpmData(false);
        setidmData([]);

        if (crop.length === 0) {
            alert('Choose one crop');
            return;
        }

        setIsApplied(true);
        try {
            let res: any = await getIDPMConfigs(crop);

            if (res.length > 0) {
                setisIdpmData(true);
                setidmData(res);
            } else {
                setisError(true);
            }
        } catch (err) {
            console.log(err);
            setisError(true);
            setisIdpmData(false);
            setidmData([]);
        } finally {
            setIsApplied(false);
        }
    };

    const onPostSubmit = async () => {
        if (window.confirm("Do you want to submit?")) {
            if (selectedModalData?.id) {
                putIDPMConfigs(selectedModalData, selectedModalData.id)
                    .then((res: any) => {
                        if (res.message === 'Success') {
                            setToastMessage("Item updated");
                            setToastSeverity('success')
                            setOpenToast(true);
                            setOpenModal(false);
                            onSubmit(selectedCropOption?.value);
                        }
                        else {
                            setToastMessage("Some Error Ocuured");
                            setToastSeverity('error');
                            setOpenToast(true);
                        }
                    })
            }
            else {
                postIDPMConfigs(selectedModalData)
                    .then((res: any) => {
                        if (res.id) {
                            setToastMessage("Item added");
                            setToastSeverity('success')
                            setOpenToast(true)
                            setOpenModal(false);
                            onSubmit(selectedCropOption?.value);
                        }
                        else {
                            setToastMessage("Some Error Ocuured");
                            setToastSeverity('error')
                            setOpenToast(true)
                        }
                    })
            }
        }
    };
    const onDelete = async (id: any) => {
        if (selectedModalData?.id) {
            if (window.confirm("Do you want to delete?")) {
                deleteIDPMConfig(selectedModalData.id)
                    .then((res: any) => {
                        if (res.message === 'Success') {
                            setToastMessage("Item deleted");
                            setToastSeverity('success')
                            setOpenToast(true)
                            setOpenModal(false);
                            onSubmit(selectedCropOption?.value);
                        }
                        else {
                            setToastMessage("Some Error Ocuured");
                            setToastSeverity('error')
                            setOpenToast(true)
                        }
                    })
            }
        }
        else {
            setToastMessage("Some Error Occured");
            setToastSeverity('error');
            setOpenToast(true)
        }
    };

    useEffect(() => {
        getCropOptions()
            .then(res => {
                setCrops(generateOptions(res, 'id', 'cropName'))
                setcropOptions(
                    [{
                        label: '--select--',
                        value: '--select--'
                    }].concat(generateOptions(res, 'id', 'cropName') as any))
                setIsCropLoading(false)
            })
    }, [])

    return (
        <Grid width={'90%'} my={2} mx={'auto'} boxShadow={2} borderRadius={4} paddingBottom={5}>
            <Grid p={2} borderRadius={20} display={'flex'} justifyContent={'space-between'} marginBottom={3}>
                <Grid display={'flex'} item alignItems={'center'}>
                    <IconButton onClick={() => navigate('/')} ><ArrowBack /></IconButton>
                    <Typography m={1} variant='h5'>IDPM</Typography>
                </Grid>
                {selectedCropOption?.value && (
                    <input type="button" value={"Add IDPM Config"} style={{ width: '15%', padding: '10px', fontSize: 18, paddingTop: '10px', alignSelf: 'center', }}
                        onClick={() => {
                            setSelectedModalData({
                                cropId: selectedCropOption?.value,
                                items: [],
                                diseasePestId: String
                            });
                            setselectedLocale([]);
                            setOpenModal(true)
                        }}
                    ></input>
                )}
            </Grid>

            <Grid>
                <div style={{
                    backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
                    margin: '0px 0 15px 0',
                    padding: '5px',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column'
                }} >
                    <Typography variant='h6' ml={1} fontWeight='500' my={1}>Crop Id</Typography>
                    <Select
                        options={crops}
                        onChange={(selected: SingleValue<any>) => {
                            setSelectedCropOption(selected);
                            if (selected?.value && selected?.value?.length > 0) {
                                onSubmit(selected?.value);
                            }
                            else {
                                onReset()
                            }
                        }}
                        isLoading={isCropLoading}
                        isDisabled={isCropLoading}
                        value={selectedCropOption}
                        isClearable={true}
                        isSearchable={true}
                        placeholder={'- select -'}
                        styles={{
                            container: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                width: '25%',
                                marginLeft: '5px',
                            }),
                            control: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                borderTop: (state.isFocused ? '1px' : '0px'),
                                borderLeft: (state.isFocused ? '1px' : '0px'),
                                borderRight: (state.isFocused ? '1px' : '0px'),
                            }),
                            menu: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                zIndex: 100
                            })
                        }}
                    />
                </div>
            </Grid>
            {isIdpmData && (
                <div style={gridStyle} className="ag-theme-alpine">
                    {
                        isArrayWithLength(idpmData) &&
                        <Grid p={3}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={idpmData}
                                columnDefs={idpmConfigDef}
                                onGridReady={onGridReady}
                                defaultColDef={defaultColDef}
                                sideBar={sideBar}
                                animateRows={true}
                                pagination={true}
                                paginationPageSize={15}
                                gridOptions={gridOptions}
                                domLayout="autoHeight"
                                onPaginationChanged={onPaginationChanged}
                                onFilterChanged={handleColumnFilter}
                            ></AgGridReact>
                        </Grid>
                    }
                </div>

            )}

            {isError && (
                <Typography
                    fontSize={17}
                    color={'red'}
                    margin={3}
                >There seems to be an error. Please try again!</Typography>
            )}
            <ToastAlert
                open={openToast}
                setOpen={setOpenToast}
                severity={toastSeverity}
                message={toastMessage}
            />
            <Modal
                open={openModal}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                onClose={() => setOpenModal(false)}
            >
                <Grid
                    style={{
                        backgroundColor: '#fff',
                        padding: 20,
                        borderRadius: 12,
                        margin: 10,
                        width: '70%',
                        height: '90%',
                        overflowY: 'auto'
                    }}
                >
                    <Grid
                        container
                        alignItems='center'
                        marginBottom={3}
                        spacing={1}
                    >
                        <IconButton onClick={() => setOpenModal(false)}>
                            <ArrowBack />
                        </IconButton>
                        <Typography variant='h5'>IDPM Details</Typography>
                    </Grid>

                    <form>
                        <div style={{ marginBottom: 15 }}>
                            <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5 }}>Crop</label>
                            <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red' }}>*</label>
                            <select
                                onChange={(e) => setSelectedModalData({ ...selectedModalData, cropId: e.target.value })}
                                value={selectedModalData.cropId}
                                style={{ width: '100%', padding: 10, fontSize: 16, margin: '3px 0', marginBottom: 11, marginTop: 6 }}
                            >
                                {cropOptions?.map(({ value, label }: any, index: any) => (
                                    <option key={index} value={value}>{label}</option>
                                ))}
                            </select>
                        </div>

                        <div style={{ marginBottom: 15 }}>
                            <LocaleDropdown
                                value={selectedDiseasePest}
                                title='Disease/Pest'
                                titleStyle={{ fontSize: 19, fontWeight: 'bold', width: '15%' }}
                                onChange={(selected: SingleValue<any>) => {
                                    setSeelctedDiseasePest(selected);
                                    setSelectedModalData({ ...selectedModalData, diseasePestId: selected.value })
                                }}
                                isMulti={false}
                                containerStyle={{ width: '99%' }}
                            />
                        </div>

                        <LocaleDropdown
                            value={selectedLocale}
                            title='Items'
                            titleStyle={{ fontSize: 19, fontWeight: 'bold', width: '15%' }}
                            onChange={(selected: MultiValue<any>) => {
                                setselectedLocale(selected);
                                setSelectedModalData({ ...selectedModalData, items: selected?.map((v) => v?.value) })
                            }}
                            isMulti={true}
                            containerStyle={{ width: '99%' }}
                        />

                        <Grid container justifyContent='space-between' marginTop={6}>
                            <Button variant='contained' color='primary' onClick={onPostSubmit} style={{ width: '48%' }}>
                                Submit
                            </Button>
                            <Button variant='contained' color='error' onClick={onDelete} style={{ width: '48%' }}>
                                Delete
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Modal>

        </Grid >
    )


}

export default Idpm