import { ExpandMoreTwoTone } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import Lottie from "react-lottie";
import MapLoader from 'src/assets/lottieFiles/map-loader.json';
import images from 'src/constants/images';
import { getHotspotRegions } from 'src/services/region.service';
import { isArrayWithLength, removeUnderScores } from 'src/utils/helper';
import { FILTERS_WITH_NO_LEGEND, GraphPlots, GraphType, hideNoDataOverlay } from '../BusinessDashboard';
import { MapSection } from '../BusinessDashboard/sections';
import MapCharts from '../BusinessDashboard/sections/MapCharts';
import { FilterSection, InfoSection } from './sections';

type THRegion = {
  count: number;
  regionId: string;
  regionLocation: {
    type: 'Point',
    coordinates: Array<number>
  }
  regionType: 'village' | "subDistrict" | 'district' | 'state';
}

type HotspotRegions = {
  cropId: string;
  count: number;
  disease: [
    {
      typeId: string;
      regions: THRegion
    }
  ]
}

const Region: React.FC = () => {
  const [cropData, setCropData] = useState<[]>([]);
  const [indexData, setIndexData] = useState<[]>([]);
  const [buttonDisabled, setButtonDisabled] = useState<Boolean>(false);
  const [filterApplied, setFilterApplied] = useState<string>('');
  const [isFilterLoading, setIsFilterLoading] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(false);

  // map
  const [mapData, setMapData] = useState<any>([]);

  // selected crop from dropdown
  const [selectedCropOption, setSelectedCropOption] = useState<any>(null);

  // hotspots
  const [hotspotRegions, setHotspotRegions] = useState<Array<HotspotRegions>>([]);
  const [selectedCrop, setSelectedCrop] = useState<string>('');

  // to be used for charts
  const [graphType, setGraphType] = useState<GraphType>();
  const [graphPlots, setGraphPlots] = useState<GraphPlots>()
  const [graphSubType, setGraphSubType] = useState<string>();

  const mapsDate = useRef<any>(null);
  const [filterDate, setFilterDate] = useState<any>(null);

  // regionIds for download-data
  const [downloadDataRegionIds, setDownloadDataRegionIds] = useState<string[]>();

  useEffect(() => {
    getHotspots();
  }, [])

  useEffect(() => {
    // whenever any filter is applied, remove the selected crop
    if (isFilterLoading) {
      removeSelectedCrop();
    }
    setShowMap(!isFilterLoading)
  }, [isFilterLoading])

  useEffect(() => {
    if (!!cropData && cropData.length >= 0) {
      setMapData(cropData as any);
    }
  }, [cropData])

  useEffect(() => {
    if (!downloadDataRegionIds && isArrayWithLength(mapData)) {
      const regionIds = mapData.map((region: any) => region.regionId);
      setDownloadDataRegionIds(regionIds);
    }
  }, [mapData])

  const groupObjectsByCropId = (inputArray: any) => {
    const groupedObjects: any = {};

    inputArray.forEach((obj: any) => {
      const { cropId, typeId, regions, type } = obj;
      const typeIdDisplayName = removeUnderScores(typeId, 'capitalize');

      if (!groupedObjects[cropId]) {
        groupedObjects[cropId] = {
          cropId: cropId,
          count: 0,
          disease: [],
        };
      }

      groupedObjects[cropId].count += obj.count;

      groupedObjects[cropId].disease.push({
        typeId,
        typeIdDisplayName,
        regions,
        type,
        count: regions.length,
      });
    });

    // Sort groupedObjects based on cropId
    const sortedGroupedObjects = Object.values(groupedObjects).sort((a: any, b: any) => a.cropId.toLowerCase().localeCompare(b.cropId.toLowerCase()));

    // Sort diseases array within each group based on typeId
    sortedGroupedObjects.forEach((group: any) => {
      group.disease.sort((a: any, b: any) => a.typeIdDisplayName.toLowerCase().localeCompare(b.typeIdDisplayName.toLowerCase()));
    });

    return Object.values(sortedGroupedObjects);
  }


  const getHotspots = async () => {
    const hotspots = await getHotspotRegions();
    const grouped: any = groupObjectsByCropId(hotspots);
    setHotspotRegions(grouped);
  }

  const setMapsDate = (newDate: any) => {
    newDate = dayjs(newDate).toDate()
    mapsDate.current = newDate;
    setFilterDate(newDate)
  };

  const handleFilterUpdate = (filter: string) => {
    hideNoDataOverlay()
    const cumulativeArea = document.getElementById("google-maps-plots-cumulative-area");
    const legendContainer = document.getElementById("legend");

    setFilterApplied(filter);

    if (!!cumulativeArea && !!legendContainer) {
      legendContainer.style.display = 'none';
      legendContainer.style.display = FILTERS_WITH_NO_LEGEND.includes(filter) ? 'none' : 'block';
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: MapLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const updateSelectedCrop = (newCrop: any) => {
    setSelectedCropOption(newCrop);
  }

  const handleDiseaseClick = (disease: any, cropId: string) => {
    const { typeId, regions, type } = disease;
    const mapData = regions.map((region: any) => {
      const { regionId, regionLocation, highs } = region;
      const [lng, lat] = regionLocation.coordinates;
      return {
        typeId: typeId,
        cropId,
        value: highs,
        location: { lat, lng },
        regionId,
        region: {
          id: regionId,
          geoLocation: regionLocation
        }
      }
    });

    setGraphType(type);
    setGraphSubType(typeId);
    setSelectedCrop(`${cropId}: ${typeId}`);
    setMapData(mapData);
  }

  const removeSelectedCrop = () => {
    setSelectedCrop('');
    setMapData(cropData as any);
  }

  return (
    <Grid px={2} py={4}>
      <Grid container minHeight="90vh" maxHeight="90vh" justifyContent={'space-between'} >
        <Grid item xs={2.5} maxHeight={'85vh'} display={'flex'} flexDirection={'column'} overflow={'scroll'} pr={2} boxShadow={1}>
          <Typography variant='caption' fontSize={22} m={2} > Hotspots </Typography>
          {
            hotspotRegions?.map((region, index) => {
              return (
                <Accordion
                  key={`hotspot-region-for-${region.cropId}-${index}`}
                  aria-controls={`hotspot-region-for-${region.cropId}-${index}`}
                  id={`hotspot-region-for-${region.cropId}-${index}`}
                  style={{ margin: '0 0px 15px 5px', borderRadius: '10px', border: '0px' }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreTwoTone />} style={{ padding: '0px 15px', margin: '5px 0px', border: '0px', height: '40px' }} >
                    <Typography border={0} letterSpacing={0.8} variant="overline" textTransform={'capitalize'} fontSize={20} m={0} p={0}>{region.cropId}</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ margin: 0, padding: '0px 10px', paddingBottom: '15px' }}>
                    {
                      region.disease.map((disease: any) => (
                        <Button key={`region-${region.cropId}-disesase-${disease.typeIdDisplayName}`} variant="outlined" size="small" color="success" style={{ margin: '2px 5px', borderRadius: '20px', padding: '0px 10px' }} onClick={() => handleDiseaseClick(disease, region.cropId)} >
                          <Typography variant="overline" textTransform={'capitalize'} >{disease.typeIdDisplayName}: </Typography>
                          <Typography variant="overline" ml={0.5} fontWeight={600}> {disease.count} </Typography>
                        </Button>
                      ))
                    }
                  </AccordionDetails>
                </Accordion>
              )
            })
          }
        </Grid>
        <Grid item xs={5.5} py={0} mt={-4}  >
          <Grid container justifyContent={'space-evenly'} alignItems={'center'}>
            {!!selectedCrop ?
              <Chip style={{ margin: '10px 0' }} label={removeUnderScores(selectedCrop, 'capitalize')} size='small' variant="outlined" onDelete={removeSelectedCrop} /> :
              <InfoSection />
            }
          </Grid>
          <Grid display={showMap ? 'block' : 'none'} >
            <MapSection
              filterApplied={filterApplied}
              cropData={mapData}
              setCropData={setCropData}
              indexData={selectedCrop ? null : indexData}
              setIndexData={setIndexData}
              buttonDisabled={buttonDisabled}
              setButtonDisabled={setButtonDisabled}
              mapsDate={mapsDate}
              setGraphPlots={setGraphPlots}
              setshowgetPrediction={(...args: any) => { }}
              setGetndviImagePlotId={(...args: any) => { }}
              setGetndviImageCrop={(...args: any) => { }}
              setGetndviImageFarmId={(...args: any) => { }}
              setGetndviImage={(...args: any) => { }}
              xs={12}
              isRegion
              hotspot={!!selectedCrop}
              setMapsDate={setMapsDate}
            />
          </Grid>
          <Grid item height={'80vh'} position={'relative'} display={showMap ? 'none' : 'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={8}
            style={{
              backgroundImage: `url(${images.IndiaMap})`,
              backgroundSize: "cover",
              backgroundPosition: 'center',
              position: "relative",
              backgroundRepeat: 'no-repeat'
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                borderRadius: '20px'
              }}
            ></div>
            <Lottie
              options={defaultOptions}
              height={'50%'}
              width={'50%'}
              style={{
                color: 'green',
              }}
            />
          </Grid>
        </Grid>
        <FilterSection
          setMapsDate={setMapsDate}
          mapsDate={filterDate}
          handleFilterUpdate={handleFilterUpdate}
          cropData={cropData}
          setCropData={setCropData}
          indexData={indexData}
          setIndexData={setIndexData}
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
          setGraphType={setGraphType}
          setGraphSubType={setGraphSubType}
          setIsFilterLoading={setIsFilterLoading}
          selectedCropOption={selectedCropOption}
          setSelectedCropOption={updateSelectedCrop}
        />
      </Grid>

      {
        (!!graphPlots && !!mapsDate.current && !!graphPlots.data && graphPlots.data.length > 0) &&
        <MapCharts
          cropId={!!selectedCrop ? selectedCrop.split(':')[0] : (selectedCropOption?.value ?? '')}
          graphPlots={graphPlots}
          graphType={graphType!}
          mapsDate={mapsDate}
          graphSubType={graphSubType!}
          setGetPredictionData={() => { }}
          showgetPrediction={false}
          getPredictionData={() => { }}
          isRegion
          hotspot={!!selectedCrop}
        />
      }
    </Grid>
  )
}

export default Region