import { Grid } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";
import showChartIcon from 'src/assets/chart.png';
import prediction from 'src/assets/prediction.png';
import removeShape from 'src/assets/remove-shape.png';
import viewPlots from 'src/assets/viewPlots.png';
import { InfoModalButton } from ".";

interface Props {
  showOptionsModal: any;
  deleteSelectedShape: (...args: any) => void;
  saveGeolocation: (...args: any) => void;
  viewCharts: (...args: any) => void;
  viewPrediction: (...args: any) => void;
  viewSelectedMarkersInGrid: (...args: any) => void;
  hotspot?: boolean;
}

const DrawnRegionInfoModal: FC<Props> = (props) => {
  const { viewCharts, showOptionsModal, saveGeolocation, viewSelectedMarkersInGrid, deleteSelectedShape, viewPrediction, hotspot } = props;

  const wrapperStyle = { textDecoration: 'none', width: '100%', backgroundColor: 'green', borderRadius: '5px', margin: '2.5px 0' };

  return (
    <div style={{ padding: 3 }} >
      <Grid display={'flex'} flexDirection={'column'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <div style={wrapperStyle}>
          <InfoModalButton
            onClick={viewCharts}
            text={'View Charts'}
            icon={showChartIcon}
            style={{ color: 'white' }}
          />
        </div>

        {showOptionsModal.includes('polygon') && (
          <div style={wrapperStyle}>
            <InfoModalButton
              onClick={saveGeolocation}
              text={'Save Geo Boundary'}
              style={{ color: 'white' }}
            />
          </div>
        )}

        {
          !hotspot &&
          <>
            <Link to={'/home'} onClick={viewSelectedMarkersInGrid} style={wrapperStyle} >
              <InfoModalButton text={'View Plot Details'} icon={viewPlots} style={{ color: 'white' }} />
            </Link>
            <div style={wrapperStyle}>
              <InfoModalButton icon={prediction} onClick={viewPrediction} text={'Get Prediction'} style={{ color: 'white' }} />
            </div>
          </>
        }
        <div style={{ ...wrapperStyle, backgroundColor: 'rgba(255, 0, 0, 1)' }}>
          <InfoModalButton icon={removeShape} onClick={() => deleteSelectedShape(showOptionsModal)} style={{ color: 'white' }} text={'Remove Shape'} />
        </div>
      </Grid>
    </div>
  )
}

export default DrawnRegionInfoModal;