import { getPlot } from 'src/services/plot.service';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import Map from 'src/components/Map';
import GetNdviImageModal from 'src/pages/BusinessDashboard/components/GetNdviImage';

type Props = {
  plotId: string;
}

type TCoordsType = 'point' | 'polygon' | 'circle';

type TCoordsArray = [number, number];

type TGeoCoords = {
  type: TCoordsType;
  coordinates: Array<Array<TCoordsArray>>;
}

const PlotMap = (props: Props) => {

  const { plotId } = props;

  const [latLng, setLatLng] = useState<{
    lat: number;
    lng: number;
  }>();

  const [geoBoundary, setGeoBoundary] = useState<TGeoCoords>({
    type: 'polygon',
    coordinates: [[]]
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [mapLocations, setMapLocations] = useState<any>();
  const [getndviImage, setGetndviImage] = useState<Boolean>(false)
  const [getndviImagePlotId, setGetndviImagePlotId] = useState<any>(null);
  const [getndviImageCrop, setGetndviImageCrop] = useState<any>(null);
  const [getndviImageFarmId, setGetndviImageFarmId] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if(plotId) {
      getPlotDetails();
    } else {
      navigate(-1);
    }
  }, [])

  useEffect(() => {
    setMapLocations([{ location: latLng, plotId, geoBoundary, color: 'green' }]);
  }, [latLng, geoBoundary])

  const getPlotDetails = async () => {
    try {
      const response = await getPlot(plotId, []);
      const { location, geoBoundary } = response;
      if(!!geoBoundary && geoBoundary?.length>0) {
        setGeoBoundary(geoBoundary);
      }

      if(!!location && location.lat && location.lng) {
        setLatLng(location);
      }
    } catch(err) {
      console.log('something went wrong');
      navigate(-1);
    } finally {
      setIsLoading(false);
    }
  }

  const updateMapLocations = (newValue: any) => {
    setMapLocations(newValue);
  }

  return (
    <div>
      {
        isLoading ? (
          <div> Loading... </div>
        ) : (
            <Map
              mapLocations={mapLocations}
              height={'60vh'}
              filterApplied="geoBoundary"
              setMapLocations={updateMapLocations}
              setGetndviImage={setGetndviImage}
              setGetndviImagePlotId={setGetndviImagePlotId}
              setGetndviImageCrop={setGetndviImageCrop}
              setGetndviImageFarmId={setGetndviImageFarmId}
              singlePlot
            />
        )
      }

      {!!getndviImage && (
        <GetNdviImageModal isOpen={getndviImage} setIsOpen={setGetndviImage} plotId={getndviImagePlotId} crop={getndviImageCrop} farmname={getndviImageFarmId} />
      )}
    </div>
  )
}
export default PlotMap;