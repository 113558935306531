import { getCropOptions } from "src/services/crop.service";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Option } from "src/constants/interfaces";
import { Grid, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { columnFilteredPlotsAtom, columnFiltersAtom } from "src/recoil/atom";
import Select, { SingleValue } from 'react-select'
import { generateOptions } from "src/utils/helper";

const DropDownStyles = {
  container: (baseStyles: any, state: any) => ({
    ...baseStyles,
    width: "100%",
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderTop: state.isFocused ? "1px" : "0px",
    borderLeft: state.isFocused ? "1px" : "0px",
    borderRight: state.isFocused ? "1px" : "0px",
  }),
  menu: (baseStyles: any, state: any) => ({
    ...baseStyles,
    zIndex: 100,
    paddingBottom: '10px',
    marginBottom: '30px'
  }),
};

const DropDownProps = {
  styles: DropDownStyles,
  isClearable: true,
  isSearchable: true,
  placeholder: "- select -",
};

export default forwardRef((props: any, ref: any) => {
  const [filterState, setFilterState] = useState("off");
  const [isCropLoading, setIsCropLoading] = useState<boolean>(true)
  const [crops, setCrops] = useState<Option[]>([])
  const [cropFilters, setCropFilters] = useState<string>()
  const [columnFilters, setColumnFilters] = useRecoilState<any>(columnFiltersAtom)
  const [__, updateFilteredPlots] = useRecoilState(columnFilteredPlotsAtom)

  useEffect(() => {
    // console.log(props.title + " filter created");
    fetchCrops();
    // return () => console.log(props.title + " filter destroyed");
  }, []);

  useEffect(() => {
    if(!!cropFilters && cropFilters.length > 0){
      const columnFiltersTemp: any = {...columnFilters}
      columnFiltersTemp.cropSown = {name: ''}
      columnFiltersTemp.cropSown = cropFilters
      setColumnFilters(columnFiltersTemp)
      updateFilteredPlots({
        ids: [],
        columnFilters: columnFiltersTemp
      })
    } else {
      const columnFiltersTemp: any = {...columnFilters}
      if ('cropSown' in columnFiltersTemp) {
        delete columnFiltersTemp.cropSown;
      }
      setColumnFilters(columnFiltersTemp)
      updateFilteredPlots({
        ids: [],
        columnFilters: columnFiltersTemp
      })
    }
  }, [cropFilters])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterState != "off";
      },
      doesFilterPass(params: any) {
        const field = props.colDef.field;
        return params.data[field] == filterState;
      },
      getModel() {
        if (filterState == "off") {
          return undefined;
        }
        return {
          state: filterState,
        };
      },
      setModel(model: any) {
        if (model == null) {
          setFilterState("off");
        } else {
          setFilterState(model.state);
        }
      },
      getModelAsString() {
        return filterState == "off" ? "" : filterState;
      },
      onNewRowsLoaded() {
        // console.log("new rows were loaded");
      },
      onAnyFilterChanged() {
        // console.log("another filter was changed");
      },
      afterGuiAttached() {
        // console.log("focus something???");
      },
    };
  });

  const fetchCrops = useCallback(async () => {
    getCropOptions()
    .then((res) => {
      setCrops(generateOptions(res, "id", "cropName"));
      setIsCropLoading(false);
    });
  }, [])

  useEffect(() => props.filterChangedCallback(), [filterState]);

  const handleFilterSelectChange = (newValue: SingleValue<any>): void => {
    setCropFilters(newValue?.value || '')
  }

  return (
    <Grid minHeight={350} minWidth={250} >
      <Typography py={1} textAlign={'center'} fontSize={12} >{props.title}</Typography>

      {
        !isCropLoading && !!crops && crops.length > 0 && (
          <Grid px={2} height={200} width={'100%'} mr={10} display={'flex'} flexDirection={'column'} >
            <Select
              options={crops}
              onChange={handleFilterSelectChange}
              {...DropDownProps}
            />
          </Grid>
        )
      }
      
    </Grid>
  );
});
