import { Box, Grid, Typography, Switch } from '@mui/material'
import React, { memo, useEffect, useState } from 'react'
import Select, { ActionMeta, SingleValue } from 'react-select'
import * as _ from 'lodash'
import { FilterData, PlotFiltersProps, Roles } from '../../constants/interfaces'
import { filters as FilterOptions } from '../../constants/filters'
import { Crop, Device, OutlierPlots, Location, Farm, FarmUser, InactivePlots, LiveData, Plot } from '../Filters'
import useAuth from '../../hooks/useAuth'
import { intersection } from 'lodash'
import {GridOn, GridView} from '@mui/icons-material';

const PlotFilters: React.FC<PlotFiltersProps> = (props: PlotFiltersProps): JSX.Element => {
  const { handlePlotIdChange, toggleGridView, isGridView } = props

  const { auth } = useAuth();
  const userRoles = auth?.roles || JSON.parse(localStorage.getItem('roles')!);
  const showFilters = intersection(userRoles, [Roles.ADMIN, Roles.SUPPORT]).length !== 0;
  const filterOptions = showFilters ? FilterOptions : FilterOptions.filter((filter: any) => ['Location'].includes(filter?.label));

  // filters
  const [filters, setFilters] = useState<any[]>([])
  const [filterData, setFilterData] = useState<FilterData>({});
  const [isInterSection, setIsIntersection] = useState<boolean>(true);
  const FilterComponentsMapping: any = {
    crop: Crop,
    device: Device,
    farm: Farm,
    farmUser: FarmUser,
    inactive: InactivePlots,
    sensors: LiveData,
    location: Location,
    outlier: OutlierPlots,
    plot: Plot
  }

  useEffect(() => {
    resetFilters()
  }, [])

  useEffect(() => {
    doStuff()
  }, [filterData])

  const doStuff = () => {
    const commonPlots = getCommonPlots(filterData as FilterData)
    // check if filter applied or not
    const filterApplied = !_.isEmpty(filterData)
    handlePlotIdChange(commonPlots, filterApplied)
  }

  const resetFilters = () => {
    setFilterData({})
  }

  const getCommonPlots = (filterData: FilterData): (undefined | String[]) => {
    let commonPlotIds: string[] = []
    let cnt = 0;

    for (const filter in filterData) {
      if (filterData[filter] !== undefined) {
        // commonPlotIds <--- firstResponse (varna empty array se intersection hota rahega)
        if (cnt < 1) {
          commonPlotIds = filterData[filter] as string[]
        } else if (isInterSection) {
          commonPlotIds = _.intersection(commonPlotIds, filterData[filter])
        } else {
          commonPlotIds = _.union(commonPlotIds, filterData[filter])
        }
        cnt++;
      }
    }

    if (cnt === 0) {
      return undefined
    }
    return commonPlotIds
  }

  const handleFilterSelectChange = (newValue: SingleValue<any>, actionMeta: ActionMeta<any>): void => {
    setFilters(filters.concat({ filterId: newValue.value + '_' + Math.random().toString(16).slice(2), type: newValue.value }))
  }

  const handleFilterDataChange = (filterId: string, appliedStatus: boolean, newFilterData: FilterData) => {
    setFilterData(Object.assign({}, filterData, { [filterId]: newFilterData }))
  }

  const handleRemove = (filterId: string) => {
    setFilters(filters.filter(obj => obj.filterId !== filterId))
    let clone = Object.assign({}, filterData);
    delete clone[filterId];
    setFilterData(clone)
  }

  const childProps: any = {
    handleFilterDataChange,
    handleRemove
  }

  const handleSwitchChange = () => {
    setIsIntersection(!isInterSection)
  }

  const label = { inputProps: { 'aria-label': 'Filter Switch for Interseciton & Union' } };

  return (
    <Box p={2}>
      {/* filter options */}
      <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'} >
        <Grid item xs={10} data-testid="select-option-home">
          <h5 style={{ marginBottom: '5px' }}>Filter By</h5>
          <Select

            options={filterOptions}
            onChange={handleFilterSelectChange}
            isSearchable={true}
            placeholder={'- select filter -'}
          />
        </Grid>
        
        <Grid display={"flex"} xs={2} alignItems={"flex-end"} justifyContent={"flex-end"} padding={2} gap={2}>
          {
            showFilters &&
            <Grid item xs={5} p={0} pb={0.8}>
              <Grid container display={'flex'} alignItems={'center'} >
                <Typography variant='subtitle2' fontSize={12} >AND</Typography>
                <Switch size='small' data-testid="switch-option-home" {...label} defaultChecked={!isInterSection} onChange={handleSwitchChange} />
                <Typography variant='subtitle2' fontSize={12} >OR</Typography>
              </Grid>
            </Grid>
          }

          <Grid item xs={4} boxShadow={1} borderRadius={2} padding={0.8} mt={2}>
            <div onClick={toggleGridView} style={{ display: "flex", cursor: 'pointer', justifyContent: 'space-around'}}>
              <GridOn htmlColor={isGridView ? '#0c9611' : '#7d7d7d'} />
              <GridView htmlColor={!isGridView ? '#0c9611' : '#7d7d7d'} />
            </div>
          </Grid>
        </Grid>
      </Grid>


      <Grid item xs={12} data-testid="filter-selects-option-home">
        {
          filters.map((filter) => {
            const { filterId, type } = filter
            const FilterComponent = FilterComponentsMapping[type]

            return (
              <FilterComponent value={''} key={filterId} filterId={filterId} {...childProps} />
            )
          })
        }
      </Grid>
    </Box>
  )
}

export default memo(PlotFilters)