import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { Mappings } from '../../constants/WordMapping';
import { Alert } from '..'
import { getSensorsFilterResult } from '../../services/plot.service';
import { FilterDataValue, FilterProps, Option } from '../../constants/interfaces'
import FormikApp from '../DynamicForm'

const filterOptions: Option[] = [
  {
    label: Mappings.airHumidity,
    value: 'airHumidity'
  },
  {
    label: Mappings.airPressure,
    value: 'airPressure'
  },
  {
    label: Mappings.airTemp,
    value: 'airTemp'
  },
  {
    label: Mappings.battery,
    value: 'battery'
  },
  {
    label: Mappings.rainFall,
    value: 'rainFall'
  },
  {
    label: Mappings.leafWetness,
    value: 'leafWetness'
  },
  {
    label: Mappings.lightIntensity,
    value: 'lightIntensity'
  },
  {
    label: Mappings.soilMoisture1,
    value: 'soilMoisture1'
  },
  {
    label: Mappings.soilMoisture2,
    value: 'soilMoisture2'
  },
  {
    label: Mappings.soilTemp,
    value: 'soilTemp'
  },
  {
    label: Mappings.windSpeed,
    value: 'windSpeed'
  }
]

const functionOptions: Option[] = [
  {
    label: 'Sum',
    value: 'sum'
  },
  {
    label: 'Average',
    value: 'avg'
  },
  {
    label: 'Maximum',
    value: 'max'
  },
  {
    label: 'Minimum',
    value: 'min'
  },
]

const operationOptions: Option[] = [
  {
    label: 'Equal (=)',
    value: 'eq'
  },
  {
    label: 'Greater (>)',
    value: 'gt'
  },
  {
    label: 'Greater or Equal (>=)',
    value: 'gte'
  },
  {
    label: 'Less Than (<)',
    value: 'lt'
  },
  {
    label: 'Less Than or Equal (<=)',
    value: 'lte'
  },
]

interface FormResponse {
  field: Option;
  functionType: Option;
  operator: Option;
  value: number;
  fromDate: Date | string;
  toDate: Date | string;
  sinceHours: number;
}

export interface SensorsData {
  field: string;
  type: string;
  operator: string;
  value: number;
  sinceHours?: number;
  fromDate?: string | Date;
  toDate?: string | Date;
}

interface Response {
  result: number;
  plotId: string;
}

enum AlertMessage {
  outOfRange = 'Since hours should be in range 0 to 100',
  negative = 'Min value is 1',
  missedRequiredFields = 'All Fields are required *(either Date or Since hours)',
  eitherDateOrHours = 'Select either date or since hours',
  invalidDate = 'Ending Date can not be smaller than Starting Date'
}

const LiveDataFilter: React.FC<FilterProps> = (props: FilterProps): JSX.Element => {
  const { handleFilterDataChange, handleRemove, filterId } = props

  const fields = [
    {
      id: "field",
      label: "Field",
      placeholder: "",
      type: "select",
      validationType: "",
      value: '',
      options: filterOptions,
      validations: [],
    },
    {
      id: "functionType",
      label: "Function",
      placeholder: "",
      type: "select",
      validationType: "",
      value: '',
      options: functionOptions,
      validations: [],
    },
    {
      id: "operator",
      label: "Operator",
      placeholder: "",
      type: "select",
      validationType: "",
      value: '',
      options: operationOptions,
      validations: [],
    },
    {
      id: "value",
      label: "Value",
      placeholder: "Value",
      type: "text",
      validationType: "",
      value: '',
      validations: [],
    },
    {
      id: "fromDate",
      label: "From Date",
      placeholder: "",
      type: "date",
      validationType: "",
      value: '',
      validations: [],
    },
    {
      id: "toDate",
      label: "To Date",
      placeholder: "",
      type: "date",
      validationType: "",
      value: '',
      validations: [],
    },
    {
      id: "sinceHours",
      label: "Since Hours",
      placeholder: "Since Hours",
      type: "text",
      validationType: "",
      value: '',
      validations: [],
    }
  ]

  const [isApplied, setIsApplied] = useState<boolean>(false);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')

  const handleFitlerChange = (status: boolean, plotIds: FilterDataValue) => {
    handleFilterDataChange(filterId, status, plotIds)
    setIsApplied(status)
  }

  const handleAlert = (status: boolean, message: string = '') => {
    if (status) {
      setIsAlertOpen(true);
      setAlertMessage(message);
    } else {
      setIsAlertOpen(false);
    }
  }

  const onSubmit = (values: FormResponse): void => {
    let { field, functionType, operator, value, fromDate, toDate, sinceHours } = values;
    setIsApplied(false);
    value = Number(value);
    sinceHours = Number(sinceHours);
    fromDate = moment(fromDate).toDate();
    toDate = moment(toDate).toDate()

    const isAnyFieldMissing = !(
      field &&
      functionType &&
      operator &&
      value &&
      (sinceHours || (fromDate && toDate))
    );

    let hasError: boolean = false;
    const hasDate = (moment(fromDate).isValid() || moment(toDate).isValid());

    if (isAnyFieldMissing) {
      handleAlert(true, AlertMessage.missedRequiredFields);
      hasError=true;
    } else if (Number(value) < 0) {
      handleAlert(true, AlertMessage.negative);
      hasError = true;
    } else if (sinceHours >= 1 && hasDate) {
      handleAlert(true, AlertMessage.eitherDateOrHours);
      hasError = true;
    } else if ((!sinceHours || sinceHours > 100) && !hasDate) {
      handleAlert(true, AlertMessage.outOfRange);
      hasError = true;
    } else if (moment(fromDate) > moment(toDate)) {
      handleAlert(true, AlertMessage.invalidDate)
    }

    if (hasError) {
      return;
    }

    let payload: SensorsData = {
      "field": field?.value.toString(),
      "type": `$${functionType?.value}`,
      "operator": `$${operator?.value}`,
      "value": value,
    }

    if (sinceHours && sinceHours > 0 && (!fromDate || !toDate)) {
      payload.sinceHours = sinceHours;
    } else {
      payload.fromDate = fromDate;
      payload.toDate = toDate;
    }

    getSensorsFilterResult(payload)
      .then((res: Response[]) => {
        const plotIds = res.map((data: Response) => data.plotId);
        handleFitlerChange(true, plotIds)
      })
  }

  const onReset = () => {
    handleFitlerChange(false, undefined)
  }

  return (
    <Grid>
      <Typography variant="subtitle2" mt={1}>Sensors Data</Typography>
      <div style={{
        backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
        margin: '0px 0 15px 0',
        padding: '5px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center'
      }} >
        <FormikApp
          formFields={fields}
          onSubmit={(values: FormResponse) => onSubmit(values)}
          onReset={() => {}}
          filter={true}
          showReset={true}
          removeField = {() => handleRemove(filterId)}
        />
      </div>
      <Alert isOpen={isAlertOpen} close={() => setIsAlertOpen(false)} messageFor={'Hours'} type="error" message={alertMessage} />
    </Grid>
  )
}

export default LiveDataFilter