import { Grid, IconButton, Modal, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getRegions,
  getRegionCrop,
  addRegionCrop,
  deleteRegionCrop,
  updateUserRegionCrop,
  addUserRegionCrop,
} from "../services/region.service";
import { generateOptions, isArrayWithLength } from "../utils/helper";
import Select, { SingleValue, MultiValue } from "react-select";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import { getCropOptions } from "../services/crop.service";
import UserCropRegion from "../components/UserCropRegion";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import LocationFilter from "src/components/Filters/location.filter";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  FilterChangedEvent,
  GridReadyEvent,
  SideBarDef,
  SortChangedEvent,
} from "ag-grid-community";
import { regionCropColDef } from "src/constants/columnDefs";
interface Props {
  openModal?: any;
  userCropData?: any;
  setuserCropData?: any;
  getUserCrop?: any;
  userId?: any;
}

const RegionCrop: React.FC<Props> = (props: Props): JSX.Element => {
  const [region, setRegion] = useState<SingleValue<any>>();
  const [selectedRangeOption, setSelectedRangeOption] = useState<any>({
    label: null,
    value: null,
  });

  const [checked, setChecked] = React.useState(false);
  const [regionCrop, setRegionCrop] = useState<any[]>();
  const [addCropModal, setAddCropModal] = useState<boolean>(false);
  const [selectedCropOption, setSelectedCropOption] = useState<MultiValue<any>>(
    []
  );
  const [crops, setCrops] = useState<MultiValue<any>>([]);

  // regionsToMap : all regions with type:village || regionsToMapArr : all region with mapped crops
  const [regionsToMap, setRegionsToMap] = useState<any>([]);
  const [regionsToMapArr, setRegionsToMapArr] = useState<any>([]);

  const gridRef = useRef<AgGridReact>(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const getAllregionCrops = async (allRegions: any) => {
    const RegionIdsArr = allRegions.map((regionObj: any) => regionObj.id);
    const res = await getRegionCrop(RegionIdsArr);
    setRegionsToMapArr(res);
  };

  useEffect(() => {
    getAllregionCrops(regionsToMap);
  }, [regionsToMap]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const addNewCropToAllRegions = async (regionAndNewCrop: Array<any>) => {
    try {
      const res = await addRegionCrop(regionAndNewCrop);
      if (res && res.length > 0) {
        setRegionsToMapArr([...res, ...regionsToMapArr]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const isNewCropAlreadyMapped = async (regionCropObj: any, crop: any) => {
    let isMatched: Boolean = false;
    const regionId = regionCropObj?.id;
    if (regionId != null) {
      const res = await getRegionCrop([regionId]);
      if (res && res.length > 0) {
        res.map((cropObj: any) => {
          if (cropObj.cropId == crop) {
            isMatched = true;
          }
        });
      }
    }

    return isMatched;
  };

  const addCropToRegion = async () => {
    try {
      let regionsToMapWithNewCrops: Array<any> = [];
      Promise.all(
        selectedCropOption?.map(async (crop) => {
          const cropVal = crop?.value;
          await Promise.all(
            regionsToMap.map(async (regionCropObj: any) => {
              const res = await isNewCropAlreadyMapped(regionCropObj, cropVal);
              if (!res) {
                let regionCropMap = {
                  cropId: cropVal,
                  regionId: regionCropObj.id,
                };
                regionsToMapWithNewCrops.push(regionCropMap);
              }
            })
          );
        })
      ).then((res) => {
        addNewCropToAllRegions(regionsToMapWithNewCrops);
      });

      // After adding crops, update state and fetch updated region crops
      setSelectedCropOption([]);
      const updatedRegionCrops = await getRegionCrop([
        selectedRangeOption?.value,
      ]);
      setRegionCrop(updatedRegionCrops);

      // Close the addCropModal
      setAddCropModal(false);
    } catch (error) {
      // Handle errors here
      console.error("Error adding crops:", error);
    }
  };

  const deleteCropFromRegion = async (id: any) => {
    if (window.confirm("Do you want to delete?")) {
      await deleteRegionCrop(id);
      getRegionCrop([selectedRangeOption?.value]).then((res) =>
        setRegionCrop(res)
      );
    }
  };

  // ag grid configurations
  const onPaginationChanged = useCallback(() => {}, []);
  const handleColumnFilter = (event: FilterChangedEvent) => {
    if (event.afterFloatingFilter) {
    }
  };
  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
  };
  const gridOptions: any = {
    columnDefs: regionCropColDef,
    rowData: regionsToMapArr,
    rowSelection: "single",
    // suppressHorizontalScroll: false,
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e: any) => {
      return;
    },
  };

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);

  useEffect(() => {
    getRegions().then((res) => setRegion(generateOptions(res, "id", "name")));
    getCropOptions().then((res) => {
      setCrops(generateOptions(res, "id", "cropName"));
    });
  }, []);

  useEffect(() => {
    setSelectedCropOption([]);
    if (selectedRangeOption?.value?.length > 0) {
      getRegionCrop([selectedRangeOption?.value]).then((res) => {
        setRegionCrop(res);
      });
    } else {
      setRegionCrop([]);
    }
  }, [selectedRangeOption]);

  const updateUserCrop = (type: any, cropId: string) => {
    if (type === "add") {
      if (props?.userCropData?.id) {
        const regionCropMappingIds =
          props?.userCropData?.regionCropMappingIds?.concat(cropId);
        updateUserRegionCrop(
          props?.userCropData?.id,
          regionCropMappingIds
        ).then((res) => {
          if (res.message === "Success") {
            props?.getUserCrop();
          } else alert("some error occurred");
        });
      } else {
        addUserRegionCrop(props?.userId, cropId).then((res) => {
          if (res?.id) {
            props?.getUserCrop();
          } else alert("some error occurred");
        });
      }
    } else {
      const regionCropMappingIds =
        props?.userCropData?.regionCropMappingIds?.filter(
          (item: any) => item !== cropId
        );
      updateUserRegionCrop(props?.userCropData?.id, regionCropMappingIds).then(
        (res) => {
          if (res.message === "Success") {
            props?.getUserCrop();
          } else alert("some error occurred");
        }
      );
    }
  };

  return (
    <Grid
      width="90%"
      my={2}
      mx="auto"
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <div style={{ justifyContent: "center", padding: 20 }}>
        <Typography mb={1.5}>
          <h3>Region</h3>
        </Typography>
        <LocationFilter
          checked={checked}
          setRegionsToMap={setRegionsToMap}
          handleFilterDataChange={undefined}
          handleRemove={undefined}
        ></LocationFilter>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <h4>Include all Sub Districts and Areas</h4>
        </div>
        <Grid
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mt={1.5}
        >
          <Typography>Region Crop</Typography>
          <IconButton onClick={() => setAddCropModal(true)}>
            <AddBoxIcon style={{ color: "grey" }} fontSize="small" />
          </IconButton>
        </Grid>

        {isArrayWithLength(regionCrop) && (
          <div style={{ display: "flex", maxWidth: "90%", flexWrap: "wrap" }}>
            {regionCrop?.map((crop: any, index: number) => (
              <Grid
                key={`crop-${crop.cropId}-${index}`}
                m={1}
                boxShadow={2}
                borderRadius={2}
                px={3}
                py={2}
                position={"relative"}
              >
                <Grid display="flex">
                  <Typography textTransform={"capitalize"}>
                    {" "}
                    {crop?.cropId}{" "}
                  </Typography>
                  <Grid position={"absolute"} top={-4} right={-6}>
                    {props?.openModal ? (
                      <Grid>
                        {props?.userCropData?.regionCropMappingIds.includes(
                          crop?.id
                        ) ? (
                          <IconButton
                            onClick={() => {
                              updateUserCrop("sub", crop?.id);
                            }}
                          >
                            <RemoveCircleIcon
                              style={{ color: "red" }}
                              fontSize="small"
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => {
                              updateUserCrop("add", crop?.id);
                            }}
                          >
                            <AddBoxIcon
                              style={{ color: "green" }}
                              fontSize="small"
                            />
                          </IconButton>
                        )}
                      </Grid>
                    ) : (
                      <IconButton
                        onClick={() => deleteCropFromRegion(crop?.id)}
                      >
                        <DeleteIcon
                          style={{ color: "grey" }}
                          fontSize="small"
                        />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </div>
        )}
      </div>

      {
        <div
          data-testid="ag-grid-noti"
          style={gridStyle}
          className="ag-theme-alpine"
        >
          <Grid p={3}>
            <AgGridReact
              ref={gridRef}
              rowData={regionsToMapArr}
              columnDefs={regionCropColDef}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              animateRows={true}
              pagination={true}
              paginationPageSize={15}
              gridOptions={gridOptions}
              domLayout="autoHeight"
              onPaginationChanged={onPaginationChanged}
              onSortChanged={handleColumnSort}
              onFilterChanged={handleColumnFilter}
            ></AgGridReact>
          </Grid>
        </div>
      }

      {!props?.openModal && <UserCropRegion />}
      <Modal
        open={addCropModal}
        style={{ overflow: "scroll" }}
        onClose={() => setAddCropModal(false)}
      >
        <Grid
          width={"40%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
          alignItems={"center"}
        >
          <div
            style={{
              backgroundColor: "#E6E6E6",
              margin: "0px 0 15px 0",
              padding: "5px",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" ml={1} fontWeight="500" my={1}>
              Crop Id
            </Typography>
            <Grid display={"flex"} flexDirection={"row"}>
              <Select
                options={crops}
                isMulti
                onChange={(selected: MultiValue<any>) => {
                  setSelectedCropOption(selected);
                }}
                value={selectedCropOption}
                isClearable={true}
                isSearchable={true}
                placeholder={"- select -"}
                styles={{
                  container: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    width: "85%",
                    marginLeft: "5px",
                  }),
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderTop: state.isFocused ? "1px" : "0px",
                    borderLeft: state.isFocused ? "1px" : "0px",
                    borderRight: state.isFocused ? "1px" : "0px",
                  }),
                  menu: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    zIndex: 100,
                  }),
                }}
              />
              <IconButton
                style={{ marginLeft: 8 }}
                onClick={() => addCropToRegion()}
              >
                <CheckCircleOutlineIcon
                  style={{ color: "green" }}
                  fontSize="medium"
                />
              </IconButton>
              <IconButton onClick={() => setSelectedCropOption([])}>
                <CancelIcon style={{ color: "red" }} fontSize="medium" />
              </IconButton>
            </Grid>
          </div>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default RegionCrop;
