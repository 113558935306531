import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Button,
  Paper,
  IconButton,
} from "@mui/material";
import Markdown from "react-markdown";
import {
  startConversation,
  sendMessageToBot,
  checkConversation,
} from "src/services/chatSupport.service";
import UploadAttachments, {
  UploadedImage,
  dataUrlToFile,
} from "./ObservationType/components/UploadAttachments";
import { generateImageAnalysis } from "src/services/imageAnalysis.service";
import moment from "moment";
import {
  Cancel,
  CloseFullscreen,
  OpenInFull,
  Remove,
  Send,
} from "@mui/icons-material";
import dhartiIcon from "../assets/dharti-icon.png";
import { isOpenURL } from "src/utils/helper";
import { useLocation } from "react-router";
import remarkGfm from "remark-gfm";
type TChat = { message: string; sender: "customer" | "bot" };

const ChatSupport: React.FC = (): JSX.Element => {
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState<TChat[]>([]);
  const currentConversationId = useRef(localStorage.getItem("botChatId") ?? "");
  const plantixResponse = useRef<any>();

  const [loading, setLoading] = useState<boolean>(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [abortControllor, setAbortControllor] = useState<AbortController>();
  const [chatbotVisible, setChatBotVisible] = useState(false);

  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [fetchingPlantixData, setFetchingPlantixData] =
    useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const buttonRef = useRef<HTMLDivElement | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: window.screen.width*0.93,
    y: window.screen.height*0.73,
  });
  const [offset, setOffset] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [startPos, setStartPos] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const location = useLocation();

  const handleSendMessage = async () => {
    if (!message.trim()) return;

    appendMessage({ message, sender: "customer" });
    setLoading(true);

    let isSession = false;
    if (!!currentConversationId.current) {
      const { isValid } = await checkConversation(
        currentConversationId.current
      );
      isSession = isValid;
    }

    let payloadMessage = message;
    if (!!plantixResponse.current && plantixResponse.current?.image) {
      const imagePath = extractPlotAndImageName(plantixResponse.current.image);
      payloadMessage += `<user uploaded image for analysis at '${imagePath}'>`;
      const imageText = `![Uploaded Image](${plantixResponse.current?.image})`;
      appendMessage({ message: imageText, sender: "customer" });
    }

    const controller = new AbortController();
    setAbortControllor(controller);

    if (isSession) {
      sendMessageToBot(
        currentConversationId.current,
        { message: payloadMessage },
        controller.signal
      )
        .then((res: any) => {
          if (res?.response) {
            appendMessage({ message: res?.response, sender: "bot" });
          } else {
            appendMessage({
              message:
                "Sorry! I am having trouble retrieving the information right now. Please try again later or provide more details.",
              sender: "bot",
            });
          }
        })
        .finally(() => setLoading(false));
    } else {
      startConversation({ initial_message: payloadMessage }, controller.signal)
        .then((res) => {
          if (res?.conversation_id) {
            localStorage.setItem("botChatId", res?.conversation_id);
            currentConversationId.current = res?.conversation_id;
            appendMessage({ message: res?.response, sender: "bot" });
          } else {
            appendMessage({
              message:
                "Sorry! I am having trouble retrieving the information right now. Please try again later or provide more details.",
              sender: "bot",
            });
          }
        })
        .finally(() => setLoading(false));
    }
    setMessage("");
    setSelectedImage(null);
  };

  const handleCancel = () => {
    if (!!abortControllor) {
      abortControllor.abort();
      setLoading(false);
    }
    setMessage("");
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chats]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const onUpload = async (attachments: UploadedImage[]) => {
    if (attachments.length > 0) {
      setFetchingPlantixData(true);
      const imageBase64 = attachments[0].image;
      const imageFile = await dataUrlToFile(imageBase64);
      setSelectedImage(attachments[0].file);
      const formdata = new FormData();
      formdata.append(moment.toString(), imageFile);

      generateImageAnalysis(formdata)
        .then((res) => {
          if (res.error) {
            throw new Error("Something went wrong");
          }
          plantixResponse.current = res;
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setFetchingPlantixData(false);
        });
    }
  };

  const appendMessage = (newChat: TChat) => {
    setChats((chats) => {
      const updatedChats = [...chats, newChat];
      return updatedChats.slice(-100);
    });
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (e.button === 0 && buttonRef.current) {
      setIsDragging(true);
      const rect = buttonRef.current.getBoundingClientRect();
      setOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
      setStartPos({ x: e.clientX, y: e.clientY });
      e.preventDefault();
    }
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging && buttonRef.current) {
      const newX = e.clientX - offset.x;
      const newY = e.clientY - offset.y;
      setPosition({ x: newX, y: newY });
      buttonRef.current.style.left = `${newX}px`;
      buttonRef.current.style.top = `${newY}px`;
    }
  };

  const handleMouseUp = (e: MouseEvent) => {
    if (isDragging) {
      setIsDragging(false);
      const distance = Math.sqrt(
        Math.pow(e.clientX - startPos.x, 2) +
          Math.pow(e.clientY - startPos.y, 2)
      );
      if (distance < 5) {
        e.preventDefault();
        e.stopPropagation();
        toggleChatbot();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, offset]);

  const toggleChatbot = () => setChatBotVisible((state) => !state);
  const toggleExpand = () => setIsExpanded((prev) => !prev);

  if (isOpenURL(location.pathname)) return <></>;
  return (
    <>
      {chatbotVisible && (
        <Grid
          style={{
            height: isExpanded ? "92%" : "60%",
            width: isExpanded ? "95%" : "25%",
            minWidth: "22%",
            zIndex: 1300,
            position: "fixed",
            bottom: "45px",
            right: "30px",
            borderRadius: "16px",
            border: "1px solid #ddd",
            backgroundColor: "#fff",
            overflow: "hidden",
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{ height: "100%", width: "100%" }}
          >
            {/* header */}
            <ChatBotHeader
              toggleChatbot={toggleChatbot}
              toggleExpand={toggleExpand}
              isExpanded={isExpanded}
            />

            {/* messages list */}
            <Grid
              item
              style={{
                width: "100%",
                overflowY: "auto",
                padding: "0px 10px",
                boxSizing: "border-box",
                height: "42%",
                flexGrow: 1,
              }}
            >
              <RenderMessages chats={chats} />
              <div ref={messagesEndRef} />
            </Grid>

            {/* loader, image preview, and input field container */}
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ width: "100%", padding: "10px" }}
            >
              {/* loader */}
              {loading && (
                <Grid item>
                  <CircularProgress size={"20px"} color="success" />
                </Grid>
              )}

              {/* image preview */}
              {!!selectedImage && (
                <Grid
                  item
                  px={2}
                  position={"absolute"}
                  bottom={"50px"}
                  right={"0px"}
                >
                  <img
                    src={selectedImage}
                    alt="Preview"
                    style={{
                      maxWidth: "100%",
                      width: "50px",
                      height: "50px",
                      borderRadius: "8px",
                      marginTop: loading ? "10px" : "0px", // Space between loader and image preview
                    }}
                  />
                </Grid>
              )}

              {/* input field */}
              <ChatInput
                onUpload={onUpload}
                message={message}
                setMessage={setMessage}
                handleKeyDown={handleKeyDown}
                disabled={fetchingPlantixData}
                isLoading={loading}
                onSubmit={handleSendMessage}
                onAbort={handleCancel}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {!chatbotVisible && (
        <div
          ref={buttonRef}
          style={{
            position: "fixed",
            left: `${position.x}px`,
            top: `${position.y}px`,
            zIndex: 1400,
          }}
        >
          <IconButton
            onMouseDown={handleMouseDown}
            style={{ height: 85, width: 85 }}
          >
            <img
              src={dhartiIcon}
              alt="Dharti Icon"
              style={{ height: "100%", width: "100%" }}
            />
          </IconButton>
        </div>
      )}
    </>
  );
};

export default ChatSupport;

/**
 * components
 */
const ChatBotHeader = ({ toggleChatbot, toggleExpand, isExpanded }: any) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      bgcolor={"#0c9611"}
      width={"100%"}
      color={"#fff"}
      py={1}
      borderRadius={"16px 16px 0 0"}
      borderBottom={0}
    >
      <Grid
        display={"flex"}
        flexDirection={"row"}
        flexGrow={1}
        alignItems={"center"}
      >
        <img
          src={dhartiIcon}
          alt="Dharti Icon"
          style={{ height: 50, width: 50, marginLeft: 6 }}
        />
        <Typography textAlign={"center"} variant="h6" flexGrow={1}>
          Dharti
        </Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={toggleExpand} color="inherit">
          {isExpanded ? <CloseFullscreen /> : <OpenInFull />}
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={toggleChatbot} color="inherit">
          <Remove />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const RenderMessages = ({ chats }: { chats: TChat[] }) => {
  return (
    <Grid flexDirection={"column"} spacing={1}>
      {chats.map((chat: TChat, index: number) => (
        <Grid
          key={`dharti-message-${index}`}
          item
          display={"flex"}
          justifyContent={chat.sender === "bot" ? "flex-start" : "flex-end"}
        >
          <Paper
            sx={{
              paddingX: 1,
              marginY: 1,
              bgcolor: chat.sender === "bot" ? "#e0e0e0" : "#0c9611",
              color: chat.sender === "bot" ? "#000" : "#fff",
              borderRadius: "16px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              maxWidth: "80%",
              wordBreak: "break-word",
            }}
          >
            <Typography variant="body1" fontSize={14}>
              <Markdown
                remarkPlugins={[remarkGfm]}
                components={{
                  table: ({ node, ...props }) => (
                    <table
                      style={{ width: "100%", border: "1px solid black" }}
                      {...props}
                    />
                  ),
                  th: ({ node, ...props }) => (
                    <th
                      style={{ border: "1px solid black", padding: "4px" }}
                      {...props}
                    />
                  ),
                  td: ({ node, ...props }) => (
                    <td
                      style={{ border: "1px solid black", padding: "4px" }}
                      {...props}
                    />
                  ),
                  img: ({ node, ...props }) => (
                    <img {...props} style={{ maxWidth: "100%" }} />
                  ),
                  a: ({ node, ...props }) => (
                    <a {...props} target="_blank" rel="noopener noreferrer" />
                  ),
                }}
              >
                {chat.message}
              </Markdown>
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

const ChatInput = ({
  onUpload,
  message,
  setMessage,
  handleKeyDown,
  disabled,
  isLoading,
  onSubmit,
  onAbort,
}: any) => {
  return (
    <Grid
      container
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"row"}
      alignItems="center"
      alignSelf={"center"}
      style={{ width: "95%" }}
    >
      <Grid item ml={-2}>
        <UploadAttachments onUpload={onUpload} buttonType="icon" />
      </Grid>
      <Grid item width={"79%"}>
        <TextField
          disabled={isLoading}
          fullWidth
          variant="outlined"
          placeholder="Ask me anything..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          size="small"
          sx={{
            fontSize: "14px",
            borderRadius: 20,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#0c9611", // Border color when the field is not focused
              },
              "&:hover fieldset": {
                borderColor: "#0c9611", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#0c9611", // Border color when the field is focused
              },
            },
            "& textarea": {
              resize: "none", // Prevent resizing of the text area
            },
          }}
        />
      </Grid>
      <Grid item>
        {isLoading ? (
          <IconButton color="error" onClick={onAbort}>
            <Cancel />
          </IconButton>
        ) : (
          <IconButton
            color="primary"
            onClick={onSubmit}
            sx={{ color: "#0c9611" }}
          >
            <Send />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

/**
 * helper methods
 */
const extractPlotAndImageName = (url: string) => {
  const regex = /plantix-analysis\/(.+)$/;
  const match = url.match(regex);

  if (match && match[1]) {
    return match[1];
  } else {
    throw new Error("Invalid URL format");
  }
};
