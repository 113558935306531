import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { BusinessDashboardMapping } from "src/constants/WordMapping";
import { useRecoilState } from "recoil";
import { plotsAtom } from "src/recoil/atom/plotsAtom";

type Props = {
  text: string;
  data: string[]
}

const InformationText: React.FC<Props> = (props: Props): JSX.Element => {
  const { text, data } = props;
  const amount = data.length

  const [_, setPlotIds] = useRecoilState(plotsAtom)

  const handleOnClick = () => {
    setPlotIds({
      ids: data,
      text
    })
  }

  return (
    <Link data-testid="links-left" to={'/home'} onClick={handleOnClick} state={{ data, text }} style={{ color: 'inherit', textDecoration: 'inherit' }}>
      {/* <Grid display={'flex'} width={'max-content'} border={1} boxSizing={'border-box'} borderColor={'green'} borderRadius={4} mb={1} px={1}>
        <Typography variant="overline" textTransform={'capitalize'}> {BusinessDashboardMapping[text] || text} - </Typography>
        <Typography variant="overline" ml={0.5} fontWeight={600}> {amount} </Typography>
      </Grid> */}

      <Button variant="outlined" size="small" color="success" style={{ margin: '2px 5px', borderRadius: '20px', padding: '0px 10px' }} >
        <Typography variant="overline" textTransform={'capitalize'}> {BusinessDashboardMapping[text] || text} - </Typography>
        <Typography variant="overline" ml={0.5} fontWeight={600}> {amount} </Typography>
      </Button>
    </Link>
  )
}

export default InformationText;