import { CORE_API } from "../constants/api";
import { getData, postData, deleteData } from "./common.service";


export const getScreenType = async (formId: string) => {
    const url = "/form/" + formId;
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API
    })
}

export const getScreenDataById = async (screenType: string, id: string) => {
    const url = `/${screenType}/${id}`
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API
    })
}

export const postScreenData = async (screenType: string, payload: any) => {
    const url = `/${screenType}`;
    return postData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
        body: payload
    })
}

export async function patchScreenData(screenType: string, payload: any) {
    const { id } = payload;
    const url = `/${screenType}/${id}`;

    return postData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API,
        usePatchMethod: true,
        body: payload
    })
}

export async function deleteScreenData(screenType: string, id: any) {
    const url = `/${screenType}/${id}`

    return deleteData(url, {
        basePath: CORE_API,
        sendTokenInHeader: true,
        useDeleteMethod: true
    })
}

export async function getScreenDataCount(screenType: string, filters: any[] = []
) {
    let url = `/${screenType}/count`;
    if (filters.length) {
        url += `?where=` + JSON.stringify({ and: filters })
    }
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API
    })
}
export async function getScreenDataByPagination(
    screenType: string,
    skip: number,
    filters: any[] = []
) {

    const qs: any = {
        order: ["created_date DESC"],
        limit: 30,
        skip: skip,
    };
    if (filters.length) {
        qs.where = { and: filters }
    }

    return getData(`/${screenType}?filter=` + JSON.stringify(qs), {
        basePath: CORE_API,
        sendTokenInHeader: true,
    })
}


