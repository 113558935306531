import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate} from "react-router";
import { AgGridReact } from "ag-grid-react";
import {
    ArrowBack,
    KeyboardArrowLeftTwoTone,
    KeyboardArrowRightTwoTone,
    } from "@mui/icons-material";
import { RegionColDef } from "../constants/columnDefs";
import {
    ColDef,
    FilterChangedEvent,
    GridOptions,
    SideBarDef,
    SortChangedEvent,
    } from "ag-grid-community";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import { deleteRegionsByIds, getRegionsByPagination, getTotalRegionsCount, updateRegionById } from "../services/region.service";
import RegionDetails from "./RegionDetails";

const RegionPage = (): JSX.Element => {
    const gridRef = useRef<AgGridReact>(null);
    const containerStyle = useMemo(
        () => ({ width: "100%", height: "600px" }),
        []
    );
    const navigate = useNavigate();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    
    const [tableData, setTableData] = useState<any[]>([]);
    const defaultColDef = useMemo<ColDef>(() => {
        return {
          flex: 1,
          resizable: true,
          sortable: true,
          floatingFilter: true,
          menuTabs: ["filterMenuTab"],
        };
      }, []);
    
    const [skip, setSkip] = useState<number>(0);
    const [columnFilter, setColumnFilters] = useState<any>();
    const sideBar = useMemo<
      SideBarDef | string | string[] | boolean | null
    >(() => {
      return {
        toolPanels: ["filters"],
      };
    }, []);

    const [totalRegions, settotalRegions] = useState<number>(0);

    

    const handleColumnSort = (event: SortChangedEvent) => {
        const columns = event.columnApi.getColumnState();
        let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
      };
      const handleColumnFilter = (event: FilterChangedEvent) => {
        const filterModel = event.api.getFilterModel();
        const whereClause: any = { and: [] };
    
        if (Object.keys(filterModel).length > 0) {
          Object.entries(filterModel).forEach(([column, filter]) => {
            const { type, filterType, filter: filterValue } = filter;
            let filterCondition: any = {};
            switch (filterType) {
              case "text":
                filterCondition[column] = {
                  like: `.*${filterValue}.*`,
                  options: "i",
                };
                break;
              case "number":
                switch (type) {
                  case "equals":
                    filterCondition[column] = filterValue;
                    break;
                  case "notEqual":
                    filterCondition[column] = { neq: filterValue };
                    break;
                  case "lessThan":
                    filterCondition[column] = { lt: filterValue };
                    break;
                  case "lessThanOrEqual":
                    filterCondition[column] = { lte: filterValue };
                    break;
                  case "greaterThan":
                    filterCondition[column] = { gt: filterValue };
                    break;
                  case "greaterThanOrEqual":
                    filterCondition[column] = { gte: filterValue };
                    break;
                  default:
                    break;
                }
                break;
              default:
                break;
            }
            whereClause.and.push(filterCondition);
          });
        }
        setColumnFilters(whereClause.and);
      };
    const gridOptions: GridOptions = {
        columnDefs: RegionColDef,
        rowData: tableData,
        rowSelection: "single",
        animateRows: true,
        overlayLoadingTemplate:
          '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
        
    };

    const onPaginationChanged = useCallback(() => {}, []);
    const onBtNext = () => {
        setSkip(skip + 15);
    };
    
    const onBtPrevious = () => {
        setSkip(skip - 15);
    };

    const PaginationIconProps = {
        fontSize: "small",
        color: "action",
    };

    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('xl');


    useEffect(() => {
        setSkip(0);
        getRegionsByPagination(0, columnFilter).then((res: any) => {    
          setTableData(res);
        });

        getTotalRegionsCount(columnFilter).then((res: any) => {
            let cnt = Math.round(res.count / 15);
      
            if (res.count % 15 !== 0) cnt += 1;
            settotalRegions(cnt);
          });
    }, [columnFilter]);

    useEffect(() => {
        getRegionsByPagination(skip, columnFilter).then((res: any) => {
          setTableData(res);
        });
    
        getTotalRegionsCount(columnFilter).then((res: any) => {
          let cnt = Math.round(res.count / 15);
    
          if (res.count % 15 !== 0) cnt += 1;
          settotalRegions(cnt);
        });
      }, [skip]);

    const onCellValueChanged = async (event:any) => {
        updateRegionById(event.data.id, event.data);
        
    };

    const onRemoveSelected = useCallback(() => {
      const selectedData = gridRef.current!.api.getSelectedRows();
      const res = gridRef.current!.api.applyTransaction({
        remove: selectedData,
      });
      const selectedIds = selectedData.map((data) => data.id);
      
      deleteRegionsByIds(selectedIds).then((res:any) => {
        alert("Records deleted succesfully");
      })
      
      
    }, []);


    return (
        <div style={containerStyle}>
          <Grid
            p={2}
            borderRadius={20}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid display={"flex"} item alignItems={"center"} margin={1}>
              <IconButton onClick={() => navigate("/")}>
                <ArrowBack />
              </IconButton>
              <Typography m={1} variant="h5">
                Regions
              </Typography>
            </Grid>
    
            <div
              style={{
                textDecoration: "none",
                color: "black",
                fontWeight: "bold",
                fontSize: 18,
                marginRight: 50,
                alignSelf: "center",
                display: "flex",
              }}
            >
              <input
                type="button"
                value="Delete Selected Regions"
                onClick={onRemoveSelected}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: 18,
                  paddingTop: "10px",
                  alignSelf: "center",
                  marginRight: "10px",
                }}
              ></input>
              <input
                type="button"
                value="Add Regions"
                onClick={() => setShowAddModal(true)}
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: 18,
                  paddingTop: "10px",
                  alignSelf: "center",
                  marginRight: "10px",
                }}
              ></input>
            </div>
          </Grid>
    
          <div style={gridStyle} className="ag-theme-alpine">
              <Grid p={3}>
                <AgGridReact
                  ref={gridRef}
                  rowData={tableData}
                  columnDefs={RegionColDef}
                  defaultColDef={defaultColDef}
                  sideBar={sideBar}
                  animateRows={true}
                  gridOptions={gridOptions}
                  domLayout="autoHeight"
                  onPaginationChanged={onPaginationChanged}
                  onSortChanged={handleColumnSort}
                  onFilterChanged={handleColumnFilter}
                  onCellValueChanged={onCellValueChanged}
                  rowSelection={"multiple"}
                ></AgGridReact>
                <Grid
                  container
                  py={2}
                  justifyContent={"flex-end"}
                  alignItems={"center"}
                  border={1}
                  borderColor={"silver"}
                  bottom={0}
                  left={0}
                  right={0}
                >
                  <Button onClick={onBtPrevious} disabled={skip === 0}>
                    <KeyboardArrowLeftTwoTone {...(PaginationIconProps as any)} />
                  </Button>
    
                  <Typography>
                    Page {Math.round(skip / 15) + 1} of {totalRegions}
                  </Typography>
    
                  <Button
                    onClick={onBtNext}
                    disabled={skip === (totalRegions - 1) * 15}
                    id="btNext"
                  >
                    <KeyboardArrowRightTwoTone {...(PaginationIconProps as any)} />
                  </Button>
                </Grid>
              </Grid>
    \      </div>
    
          <Dialog
            onClose={() => setShowAddModal(false)}
            open={showAddModal}
            maxWidth={maxWidth}
            fullWidth={true}
          >
            <RegionDetails setOpenModal={setShowAddModal}/>
          </Dialog>
          {/*<Dialog
            onClose={() => setShowEditModal(false)}
            open={showEditModal}
            maxWidth={maxWidth}
            fullWidth={true}
          >
            <LeadDetails
              selectedId={selectedRowId}
              edit={true}
              updateData={callLeadsApi}
              showAddModal={setShowEditModal}
            />
          </Dialog> */}
        </div>
      );
}
export default RegionPage; 