import { Button, Grid, IconButton, Typography } from '@mui/material'
import React, { useState, useEffect, useReducer } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';

import { Draggable } from 'react-drag-reorder';
interface Props {
    setOpenModal: any;
    content: any;
    setContent: any;
    setSelectedModalData: any;
}

const DraggableStage: React.FC<Props> = (props: Props): JSX.Element => {
    const { content, setContent, setSelectedModalData, setOpenModal } = props;

    const [draggableDone, updateDraggable] = useReducer(state => !state, false);

    const removeStage = (stage: any) => {
        let newStages = content?.stages?.filter((val: any) => val?.order !== stage?.order)
        for(let i=0; i<newStages.length; i++) {
            newStages[i].order = i+1;
        }
        content.stages = newStages
        setContent({ ...content, stages: newStages })
        updateDraggable()
    }

    const dragItems = (sourceIndex: any, destinationIndex: any) => {

        let newStages = [...content.stages];

        let sourceStage = newStages[sourceIndex];
        newStages = newStages.filter((stage: any, index: any) => index !== sourceIndex)

        const updatesStage = [
            ...newStages.slice(0, destinationIndex),
            sourceStage,
            ...newStages.slice(destinationIndex)
        ].map((stage: any, index: any) => ({
            ...stage,
            order: index + 1
        }));

        setContent({ ...content, stages: updatesStage });
        updateDraggable();
    }

    useEffect(() => {
        updateDraggable();
    }, [content])

    if (content?.stages?.length) {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                <Draggable key={`${draggableDone}`} onPosChange={dragItems}>
                    {Array.isArray(content?.stages) && content?.stages?.map((val: any, index: number) => {
                        const key = `${val.stageId}-${val.order}-${index}`;

                        const chemicals = Object.entries(val.element);

                        return (
                            <div key={key}>
                                <Grid my={2} boxShadow={2} borderRadius={3} px={2} pb={2} pt={2} width={550}>
                                    <Grid
                                        display="flex"
                                        justifyContent={'space-between'}
                                    >
                                        <Button style={{ color: "#696969" }} size='medium' onClick={() => { setSelectedModalData(val); setOpenModal(true) }} >
                                            <Grid
                                                display="flex"
                                                alignItems={'center'}
                                            >
                                                <div style={{ alignItems: 'center' }}>
                                                    <span style={{ fontSize: 16, fontWeight: 'bold', marginRight: 10 }}>
                                                        {val?.order}
                                                    </span>
                                                </div>
                                                <div style={{ alignItems: 'center' }}>
                                                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>
                                                        {val?.stageId}
                                                    </span>
                                                </div>
                                                <Grid style={{ color: "#696969", alignItems: 'center' }}>
                                                    {!!val?.week && val?.week > 0 &&
                                                        (
                                                            <Typography ml={1} display={"inline-block"} >
                                                                {`(Week: ${val?.week})`}
                                                            </Typography>
                                                        )
                                                    }
                                                </Grid>


                                                <Grid style={{ color: "#696969", alignItems: 'center' }}>
                                                    {!!val?.day && val?.day>0  && 
                                                        (
                                                            <Typography ml={1} display={"inline-block"} >
                                                                {`(Day: ${val?.day})`}
                                                            </Typography>
                                                        )
                                                    }
                                                </Grid>

                                            </Grid>
                                        </Button>
                                        <div >
                                            <IconButton onClick={() => { removeStage(val) }} style={{ top: -17, right: -15 }}>
                                                <DeleteIcon style={{ color: 'grey' }} fontSize='small' />
                                            </IconButton>
                                        </div>
                                    </Grid>


                                    <Grid style={{ color: "#696969" }}>
                                        {chemicals.map(([chemical, value]: any, index: number) => {
                                            const chemicalKey = `chemical-${chemical}-${index}`;
                                            const units = value?.units;
                                            const percentage = value?.percentage;
                                            return (
                                                <Typography key={chemicalKey} ml={1} display={"inline-block"} >
                                                    {chemical}: {!isNaN(units) ? `${units}` : `${Number(percentage * 100).toFixed(2)}%`}
                                                </Typography>
                                            )
                                        })}
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    })}
                </Draggable>
            </div>

        )
    }
    return (<></>)
}



export default DraggableStage