import { Grid } from "@mui/material";
import { FC } from "react";
import { InfoModalButtonWithIcon, PrintInformation } from ".";
import showChartIcon from 'src/assets/showCharts.png';

interface Props {
  regionsModalInfo: any;
  viewChartForRegion: (...args: any) => void;
}

const RegionInfoModal: FC<Props> = (props) => {
  const { viewChartForRegion, regionsModalInfo } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      height="100%"
      width={'300px'}
    >
      {!!regionsModalInfo.region.village && (
        <PrintInformation label={"Village"} value={regionsModalInfo.region.village} />
      )}
      {!!regionsModalInfo.region.subDistrict && (
        <PrintInformation label={"Sub District"} value={regionsModalInfo.region.subDistrict} />
      )}
      {!!regionsModalInfo.region.district && (
        <PrintInformation label={"District"} value={regionsModalInfo.region.district} />
      )}
      {!!regionsModalInfo.region.state && (
        <PrintInformation label={"State"} value={regionsModalInfo.region.state} />
      )}
      <Grid display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} style={{ marginTop: 15 }} minWidth={280}>
        {!!regionsModalInfo.regionId && (
          <InfoModalButtonWithIcon icon={showChartIcon} text={'Show Charts'} onClick={() => viewChartForRegion(regionsModalInfo)} />
        )}
      </Grid>
    </Grid>
  )
}

export default RegionInfoModal;