import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Button,
} from "@mui/material";
import { TImageAnalysis } from "../types";
import { useRef } from "react";
import {
  updateImageAnalysis,
  deleteImageAnalysis,
} from "src/services/imageAnalysis.service";

type Props = {
  analysis: TImageAnalysis;
  onError: Function;
  onUpdate: (data: any) => void;
  onDelete: () => void;
};

const ImageAnalysisRenderer = (props: Props) => {
  const { analysis, onError, onUpdate, onDelete } = props;
  const { id: analysisId, isCorrect, comment } = analysis;
  const { crop_health, crops, predicted_diagnoses } = analysis.analysis;

  const isValidRef = useRef<any>();
  const commentRef = useRef<any>();

  const handleSubmit = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const isCorrect = !!isValidRef.current;
    const comment = commentRef.current.value;

    const payload: any = {
      isCorrect,
    };

    if (!!comment) {
      payload.comment = comment;
    }

    updateImageAnalysis(analysisId, payload)
      .then((res) => {
        if (!!res.error) {
          onError();
        } else {
          onUpdate(payload);
        }
      })
      .catch((error) => {
        onError();
      });
  };

  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (window.confirm("Are you sure you want to delete")) {
      deleteImageAnalysis(analysisId)
        .then((res) => {
          if (res.message === "Success") {
            onDelete();
          }else{
            onError();
          }
        })
        .catch((error) => {
          onError();
        });
    }
  };

  const setIsValidRef = (event: React.ChangeEvent<HTMLInputElement>) => {
    isValidRef.current = event.target.checked;
  };

  return (
    <Grid
      container
      bgcolor={"white"}
      my={1}
      mx={"auto"}
      width={"95%"}
      borderRadius={2}
    >
      <Grid
        item
        xs={8}
        style={{ height: "98vh", padding: "10px", overflow: "scroll" }}
      >
        <Grid>
          <Grid>
            <Typography variant="h5" align="center" gutterBottom>
              Leaf Report
            </Typography>

            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={6}>
                <DataRenderer title="Crops" data={crops} />
                <DataRenderer title="Health" data={crop_health} />
                <ErrorRenderer data={analysis.analysis?.errors} />
              </Grid>
              <Grid item xs={6}>
                <a href={analysis.image}>
                  <img
                    src={analysis?.image}
                    loading="lazy"
                    alt="analysis"
                    style={{
                      width: "200px",
                      objectFit: "contain",
                      objectPosition: "center",
                    }}
                  />
                </a>
              </Grid>
            </Grid>

            <Grid container direction="column" spacing={2} px={2}>
              {predicted_diagnoses?.map((diagnose, index) => (
                <Diagnose
                  key={`diagnose-${diagnose.common_name}-${index}`}
                  data={diagnose}
                  index={index}
                  total={predicted_diagnoses?.length}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3} mt={5}>
        <Typography variant="h5" gutterBottom>
          Your feedback
        </Typography>
        <Grid>
          Is the analysis correct?{" "}
          <Checkbox defaultChecked={!!isCorrect} onChange={setIsValidRef} />
        </Grid>
        <Grid>
          <textarea
            name="comment"
            id="comment"
            rows={7}
            style={{ width: "95%", padding: "10px" }}
            placeholder="Comment"
            ref={commentRef}
            defaultValue={commentRef?.current?.value ?? comment}
          ></textarea>
        </Grid>
        <Grid my={2}>
          <Button
            onClick={handleSubmit}
            variant="contained"
            fullWidth
            color="success"
            style={{ marginBottom: 5 }}
          >
            Update
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            fullWidth
            color="error"
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ImageAnalysisRenderer;

const Diagnose = ({ data, index, total }: any) => {
  const {
    common_name,
    diagnosis_likelihood,
    pathogen_class,
    preventive_measures,
    symptoms_short,
    treatment_chemical,
    treatment_organic,
    trigger,
  } = data;

  return (
    <Grid sx={{ mt: 2 }}>
      <Grid>
        <Grid container alignItems="baseline" gap={"5px"}>
          <Typography fontSize={13} variant="caption" fontWeight={"600"}>{`${
            index + 1
          }/${total}`}</Typography>
          <Typography variant="h6">{common_name}</Typography>
        </Grid>

        <DataRenderer title="Treatment" data={diagnosis_likelihood} />
        <DataRenderer title="Type" data={pathogen_class} />
        <DataRenderer title="Symptoms" data={symptoms_short} />
        <DataRenderer title="Organic Treatment" data={treatment_organic} />
        <DataRenderer title="Chemical Treatment" data={treatment_chemical} />
        <DataRenderer title="Trigger" data={trigger} />
        <DataRenderer title="Preventive Measures" data={preventive_measures} />
      </Grid>
    </Grid>
  );
};

const DataRenderer = ({ title, data }: any) => {
  return (
    <Grid sx={{ mt: 2 }}>
      <Typography variant="subtitle1" fontWeight={"600"}>
        {title}
      </Typography>
      {Array.isArray(data) ? (
        <List dense>
          {data?.map((item, index) => (
            <ListItem key={index}>
              <ListItemText primary={`${index + 1}. ${item}`} />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography fontSize={14} px={2}>
          {data}
        </Typography>
      )}
    </Grid>
  );
};

const ErrorRenderer = ({ data }: any) => {
  if (data?.length > 0) {
    return (
      <Grid sx={{ mt: 2 }}>
        <Typography variant="subtitle1" fontWeight={"600"} color={"red"}>
          Errors
        </Typography>
        {Array.isArray(data) ? (
          <List dense>
            {data?.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={`${index + 1}. ${item?.message}`} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography fontSize={14} px={2}>
            {data}
          </Typography>
        )}
      </Grid>
    );
  }
  return <></>;
};
