import { Button, Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { Option } from "../constants/interfaces";
import {
  postVideo,
  getVideoWithId,
  updateVideo,
  deleteVideo,
} from "../services/plot.service";
import { TextArea } from "../components/DynamicForm/fields/_fieldStyles";
import DeleteIcon from "@mui/icons-material/Delete";

const AddVideosModel: React.FC = (): JSX.Element => {
  let res: any = {};
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();

  const [title, setTitle] = useState<any>("");
  const [priority, setPriority] = useState<number>(0);
  const [videolink, setVideoLink] = useState<any>("");
  const [thumbnailurl, setThumbnailurl] = useState<any>("");
  const [keywords, setKeywords] = useState<any>("");
  const [description, setDescription] = useState<any>("");
  const [language, setLanguage] = useState<any>("en");
  const [category, setCategory] = useState<any>("app_video");
  const [videoId, setVideoId] = useState<any>("");
  const [isShorts, setIsShorts] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      getVideoWithId(id).then((result: any) => {
        if (result.id) {
          res = result;
          setIsShorts(res?.link?.includes("shorts"))
          setVideoId(res?.link?.split("/").pop()?.split("?")[0] || "");
          setTitle(res?.title || "");
          setLanguage(res?.lang || "");
          setCategory(res?.category || "");
          setDescription(res?.description || "");
          setPriority(res?.priority || 0);
          setKeywords(res?.keywords.join(",") || "");
        }
      });
    }
  }, [id]);

  useEffect(() => {
    setVideoLink(isShorts?`https://www.youtube.com/shorts/${videoId}`: `https://youtu.be/${videoId}`);
    setThumbnailurl(`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`);
  }, [videoId, isShorts]);

  const onSubmit = () => {
    if (window.confirm("Do you want to submit?")) {
      let keywordsArr = keywords.split(",");
      keywordsArr = keywordsArr?.map((i: any) => i.trim());
      const payload = {
        id,
        title,
        priority,
        videolink,
        thumbnailurl,
        keywordsArr,
        description,
        language,
        category,
      };

      if (id && id.length > 0) {
        updateVideo(payload).then((res: any) => {
          if (res.message === "Success") {
            alert("Video Updated Successfully!");
            navigate("/videos-guide");
          } else {
            alert("Some Error Ocuured");
            navigate("/videos-guide");
          }
        });
      } else {
        postVideo(payload).then((res: any) => {
          if (res.id) {
            alert("Video Added Successfully!");
            navigate("/videos-guide");
          } else {
            alert("Some Error Ocuured");
            navigate("/videos-guide");
          }
        });
      }
    }
  };
  const onDelete = () => {
    if (id && id.length > 0) {
      if (window.confirm("Do you want to delete?")) {
        deleteVideo(id).then((res: any) => {
          if (res.message === "Success") {
            alert("Video Deleted");
            navigate("/videos-guide");
          } else alert("Some Error Occured");
          navigate("/videos-guide");
        });
      }
    } else alert("Some Error Occured");
  };
  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid p={2} borderRadius={20}>
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton
            data-testid="back-button"
            onClick={() => navigate("/videos-guide")}
          >
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Video Details
          </Typography>
        </Grid>
      </Grid>

      <div>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 30,
          }}
        >
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Video Id<span style={{ fontSize: 20, fontWeight: "bold", color: "red", marginLeft: 6 }}>*</span>
            </label>
          
            <div style={{display: 'flex', marginBottom: 20, margin: 5}}>
          <label style={{ fontSize: 18, fontWeight: 'bold', marginRight: 11 }}>Shorts</label>
          <input
            type="checkbox"
            name="isShorts"
            onChange={(e) => setIsShorts(e.target.checked)}
            checked={isShorts}
            style={{ width: "22px", height: "22px", marginRight: 30 }}
          ></input>
          <br />
          </div>
          </div>
          <input
            name="videoId"
            onChange={(e) => setVideoId(e.target.value)}
            value={videoId}
            type="text"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
          ></input><br/>

          <div>
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Video Link
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
          </div>
          <input
            name="videolink"
            value={videolink}
            type="text"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
          ></input>
          <br />

          <div>
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Thumbnail url
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
          </div>

          <input
            name="thumbnailurl"
            value={thumbnailurl}
            type="text"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
          ></input>
          <br />

          <div>
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Video Title
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <br></br>
          </div>

          <input
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            type="text"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 15,
              marginTop: 10,
            }}
          ></input>
          <br />

          <div>
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Priority
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
          </div>
          <input
            name="priority"
            onChange={(e: any) => setPriority(Number(e.target.value))}
            value={priority}
            type="number"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
          ></input>
          <br />

          <div>
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Video Category
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
          </div>
          <select
            name="category"
            id="category"
            onChange={(e) => setCategory(e.target.value)}
            value={category}
            style={{
              width: "97%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
          >
            <option value="app_video">App Video</option>
            <option value="product_video">Product Video</option>
            <option value="agronomy_video">Agronomy Video</option>
            <option value="testimonial_video">Testimonial Video</option>
          </select>

          <div>
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Video Keywords
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
          </div>
          <input
            name="fertilizerName"
            value={keywords}
            onChange={(e) => {
              setKeywords(e.target.value);
            }}
            type="text"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
          ></input>
          <br />
          <h3 style={{ margin: 5 }}>Video Description</h3>
          <TextArea
            name="description"
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
            style={{
              width: "97%",
              height: "120px",
              padding: "18px",
              resize: "none",
              fontSize: 18,
              margin: "3px",
              marginBottom: 11,
            }}
          ></TextArea>
          <br />

          <h3 style={{ margin: 5 }}>Video language</h3>
          <select
            name="language"
            id="language"
            onChange={(e) => setLanguage(e.target.value)}
            value={language}
            style={{
              width: "97%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
            }}
          >
            <option value="en">en</option>
            <option value="ma">ma</option>
            <option value="hi">hi</option>
            <option value="ka">ka</option>
            <option value="te">te</option>
            <option value="gu">gu</option>
          </select>
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            style={{ marginTop: 55 }}
          >
            <input
              type="button"
              value="Submit"
              onClick={onSubmit}
              style={{
                border: "1px solid #2e7d32",
                backgroundColor: "white",
                borderRadius: "30px",
                width: "50%",
                padding: "20px",
                fontSize: 19,
                color: "#2e7d32",
                paddingTop: "15px",
                alignSelf: "center",
                margin: "30px",
              }}
            />
            <input
              type="button"
              value="Delete"
              onClick={onDelete}
              style={{
                border: "1px solid red",
                backgroundColor: "white",
                borderRadius: "30px",
                width: "50%",
                padding: "20px",
                fontSize: 19,
                color: "red",
                paddingTop: "15px",
                alignSelf: "center",
                margin: "30px",
              }}
            />
          </Grid>
        </form>
      </div>
    </Grid>
  );
};

export default AddVideosModel;
