import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Typography,
  Grid,
  IconButton,
  Button,
  Dialog,
  Box,
  styled,
  Tabs,
  Tab,
  ButtonGroup,
  TableContainer,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import {
  ArrowBack,
  KeyboardArrowLeftTwoTone,
  KeyboardArrowRightTwoTone,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router";
import * as XLSX from "xlsx";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Select, { MultiValue } from "react-select";
import { Gauge, GaugeProps, gaugeClasses } from "@mui/x-charts/Gauge";
import LinearProgress, {
  LinearProgressProps,
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { generateOptions } from "src/utils/helper";
import {
  getTargetRegionCount,
  getTargetRegions,
} from "../services/region.service";
import {
  getReportByMonth,
  getTargetByRegion,
  getZohoReport,
  updateTarget,
} from "../services/businessDashboard.service";
import TargetDetails from "./TargetDetails";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import { AgGridReact } from "ag-grid-react";
import {
  EstimationRegionReportColDef,
  RegionReportColDef,
  RevenueModalColDef,
} from "../constants/columnDefs";
import {
  ColDef,
  FilterChangedEvent,
  GridOptions,
  GridReadyEvent,
  RowStyle,
  SideBarDef,
  SortChangedEvent,
} from "ag-grid-community";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import dayjs from "dayjs";
import {
  getMonthlyPlans,
  getSalesActivityTypes,
  getSalesActivities,
} from "src/services/sales.service";
interface RegionReportData {
  revenue: any;
  installation: any;
  collections: any;
  weeklyInstallation: any;
}

const PhaseGraph: React.FC<any> = ({ plan, activityTypes }) => {
  const [doneActivities, setDoneActivities] = useState<{
    [key: string]: number[];
  }>({});
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    const formatDoneActivities = (activities: any[]) => {
      const formattedActivities: { [key: string]: number[] } = {};
      activityTypes?.forEach((activity: any) => {
        formattedActivities[activity?.id] = [0, 0, 0];
      });
      activities?.forEach((activity) => {
        const index =
          dayjs(activity?.date).date() < 11
            ? 0
            : dayjs(activity?.date).date() < 21
            ? 1
            : 2;
        formattedActivities[activity?.salesActivityTypeId][index]++;
      });
      setDoneActivities(formattedActivities);
      setDataLoaded(true);
    };

    if (plan?.id?.length > 0) {
      setDataLoaded(false);
      getSalesActivities(plan.id).then(formatDoneActivities);
    }
  }, [plan, activityTypes]);

  const isVisibleMemo = useMemo(() => {
    const isVisibleForActivity = (activityId: string) => {
      return (
        plan?.plans[activityId]?.some((planCount: any) => planCount > 0) ||
        (doneActivities &&
          doneActivities[activityId] &&
          Object?.values(doneActivities[activityId])?.some(
            (actualCount) => actualCount > 0
          ))
      );
    };
    return activityTypes?.reduce((acc: any, activity: any) => {
      acc[activity?.id] = isVisibleForActivity(activity?.id);
      return acc;
    }, {} as { [key: string]: boolean });
  }, [plan, doneActivities, activityTypes]);

  return (
    <Grid item width={"99%"} style={{ marginBottom: "10px" }}>
  <Typography
    mt={1}
    mb={1}
    fontSize={18}
    textAlign={"center"}
    style={{ marginBottom: "5px", fontWeight: "bold" }}
  >
    {` ${
      plan.name
        ? plan.name
        : plan.district
        ? `${plan.district}, ${plan.state}`
        : plan.state
    }`}
  </Typography>

  <Grid
    boxShadow={2}
    p={2}
    borderRadius={4}
    style={{
      backgroundColor: "#fff",
      overflow: "auto",
      display: "flex",
      flexDirection: "row",
    }}
  >
    {dataLoaded ? (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Activity Type</TableCell>
              <TableCell colSpan={2}>1 - 10 Days</TableCell>
              <TableCell colSpan={2}>11 - 20 Days</TableCell>
              <TableCell colSpan={2}>21 - 31 Days</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Target</TableCell>
              <TableCell>Actual</TableCell>
              <TableCell>Target</TableCell>
              <TableCell>Actual</TableCell>
              <TableCell>Target</TableCell>
              <TableCell>Actual</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityTypes?.map((activity: any) => {
              const isVisible = isVisibleMemo[activity.id] || false;
              return isVisible ? (
                <TableRow key={activity.id}>
                  <TableCell>{activity.description}</TableCell>
                  {[0, 1, 2].map((index) => (
                    <React.Fragment key={index}>
                      <TableCell>
                        {plan?.plans?.[activity.id]?.[index]}
                      </TableCell>
                      <TableCell>
                        {doneActivities?.[activity.id]?.[index]}
                      </TableCell>
                    </React.Fragment>
                  ))}
                </TableRow>
              ) : null;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80px",
          width: "100%",
        }}
      >
        <CircularProgress />
      </div>
    )}
  </Grid>
</Grid>

  );
};

const RegionReport = () => {
  const navigate = useNavigate();
  const [isRegionLoading, setIsRegionLoading] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [clickedRegion, setClickedRegion] = useState<any>(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [fetchedRevenueData, setFetchedRevenueData] = useState<any>([]);

  const [tableData, setTableData] = useState<any[]>([]);
  const gridOptions: GridOptions = {
    columnDefs: RegionReportColDef,
    rowData: tableData,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => onCellClicked(e.data),
  };
  const estimationGridOptions: GridOptions = {
    columnDefs: EstimationRegionReportColDef,
    rowData: tableData,
    rowSelection: "single",
    animateRows: true,
    suppressScrollOnNewData: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => onCellClicked(e.data),
  };

  const revenueModalGridOptions: GridOptions = {
    columnDefs: RevenueModalColDef,
    rowData: fetchedRevenueData,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
  };

  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: false,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const gridRef = useRef<AgGridReact>(null);
  const estimationGridRef = useRef<AgGridReact>(null);

  const [skip, setSkip] = useState<number>(0);
  const [columnFilter, setColumnFilters] = useState<any>();
  const [editGrid, setEditGrid] = useState<boolean>(false);
  const [totalTargetRegions, setTotalTargetRegions] = useState<number>(0);
  const [selectedRegionOption, setSelectedRegionOption] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [showModalTarget, setShowModalTarget] = useState<boolean>(false);
  const [showGauge, setShowGauge] = useState<boolean>(false);
  const [noData, setNoData] = useState<boolean>(false);
  const [showEditTarget, setShowEditTarget] = useState<boolean>(false);
  const [region, setRegion] = useState<any>(null);
  const [monthlyData, setMonthlyData] = useState<any[]>([]);
  const months = [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ];

  const currentMonthIndex = new Date().getMonth();
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState<any>(null);
  const [selectedYear, setSelectedYear] = useState<any>(null);
  const [regionDict, setRegionDict] = useState<any>();
  const [activityTypes, setActivityTypes] = useState<any[]>([]);
  const [showFetchRevenueModal, setShowFetchRevenueModal] =
    useState<boolean>(false);
  const initialRegionData: RegionReportData = {
    revenue: {
      target: 0,
      actual: 0,
      estimation: { start: 0, first: 0, second: 0, end: 0 },
    },
    installation: {
      target: 0,
      actual: 0,
      estimation: { start: 0, first: 0, second: 0, end: 0 },
    },
    collections: {
      recievable: { target: 0, actual: 0 },
      freshSales: { target: 0, actual: 0 },
      estimation: { start: 0, first: 0, second: 0, end: 0 },
    },
    weeklyInstallation: { first: 0, second: 0, third: 0, fourth: 0},
  };

  const [regionReport, setRegionReport] = useState<any>(initialRegionData);

  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
  };

  const handleColumnFilter = (event: FilterChangedEvent) => {
    const filterModel = event.api.getFilterModel();
    const whereClause: any = { and: [] };

    if (Object.keys(filterModel).length > 0) {
      Object.entries(filterModel).forEach(([column, filter]) => {
        const { type, filterType, filter: filterValue } = filter;
        let filterCondition: any = {};
        switch (filterType) {
          case "text":
            filterCondition[column] = {
              like: `.*${filterValue}.*`,
              options: "i",
            };
            break;
          case "number":
            switch (type) {
              case "equals":
                filterCondition[column] = filterValue;
                break;
              case "notEqual":
                filterCondition[column] = { neq: filterValue };
                break;
              case "lessThan":
                filterCondition[column] = { lt: filterValue };
                break;
              case "lessThanOrEqual":
                filterCondition[column] = { lte: filterValue };
                break;
              case "greaterThan":
                filterCondition[column] = { gt: filterValue };
                break;
              case "greaterThanOrEqual":
                filterCondition[column] = { gte: filterValue };
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        whereClause.and.push(filterCondition);
      });
    }
    setColumnFilters(whereClause.and);
  };

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  const [regionListData, setRegionListData] = useState<any>();
  useEffect(() => {
    setSelectedMonth(months[currentMonthIndex]);
    setSelectedYear({ label: currentYear, value: currentYear.toString() });
  }, [regionDict]);

  useEffect(() => {
    getTargetRegions().then((res) => {
      res.map((row: any) => {
        if (row.regionName) row.name = row.regionName;
      });
      setRegionListData(res);
      const regionlist = generateOptions(res, "id", "name");
      setRegion(regionlist);
      const regiondict: { [key: string]: string | number } = {};
      regionlist.map((region) => {
        regiondict[region.value] = region.label;
      });
      setRegionDict(regiondict);
      setIsRegionLoading(false);
    });

    getSalesActivityTypes().then((res) => {
      setActivityTypes(res);
    });
  }, []);

  useEffect(() => {
    getTargetRegionCount(columnFilter).then((res: any) => {
      let cnt = Math.round(res.count / 15);
      if (res.count % 15 !== 0) cnt += 1;
      setTotalTargetRegions(cnt);
    });

    if (selectedMonth && selectedYear) {
      getReportByMonth(
        selectedMonth.value,
        selectedYear.value,
        skip,
        columnFilter
      )
        .then((res) => {
          res.map((row: any) => {
            row.name = regionDict[row.region];
          });
          setTableData(res);
        })
        .catch((e) => console.log(e));
    }
  }, [columnFilter, selectedMonth, selectedYear]);

  const handleRegionChange = (value: any) => {
    setSelectedRegion(value);
    setSelectedRegionOption(value);
  };

  const onCellClicked = (data: any) => {
    if (!editGrid) {
      setClickedRegion(data.region);
    }
  };

  useEffect(() => {
    if (region) {
      const regionLabel = {
        value: clickedRegion,
        label: regionDict[clickedRegion],
      };
      setSelectedRegion([regionLabel]);
      setSelectedRegionOption([regionLabel]);
    }
  }, [clickedRegion]);

  const handleDropdownChange = (
    dropdown: string,
    selected: MultiValue<any>
  ) => {
    switch (dropdown) {
      case "region":
        handleRegionChange(selected);
        break;
      case "month":
        setSelectedMonth(selected);
        break;
      case "year":
        setSelectedYear(selected);
        break;
    }
  };

  function addReportData(
    data: RegionReportData,
    regionData: RegionReportData
  ): RegionReportData {
    let result: RegionReportData = { ...regionData };
    result = {
      revenue: {
        target: parseFloat(
          (result.revenue.target + data.revenue.target).toFixed(2)
        ),
        actual: parseFloat(
          (result.revenue.actual + data.revenue.actual).toFixed(2)
        ),
        estimation: {
          start: parseFloat(
            (
              result.revenue.estimation.start + data.revenue.estimation.start
            ).toFixed(2)
          ),
          first: parseFloat(
            (
              result.revenue.estimation.first + data.revenue.estimation.first
            ).toFixed(2)
          ),
          second: parseFloat(
            (
              result.revenue.estimation.second + data.revenue.estimation.second
            ).toFixed(2)
          ),
          end: parseFloat(
            (
              result.revenue.estimation.end + data.revenue.estimation.end
            ).toFixed(2)
          ),
        },
      },
      installation: {
        target: parseFloat(
          (result.installation.target + data.installation.target).toFixed(2)
        ),
        actual: parseFloat(
          (result.installation.actual + data.installation.actual).toFixed(2)
        ),
        estimation: {
          start: parseFloat(
            (
              result.installation.estimation.start +
              data.installation.estimation.start
            ).toFixed(2)
          ),
          first: parseFloat(
            (
              result.installation.estimation.first +
              data.installation.estimation.first
            ).toFixed(2)
          ),
          second: parseFloat(
            (
              result.installation.estimation.second +
              data.installation.estimation.second
            ).toFixed(2)
          ),
          end: parseFloat(
            (
              result.installation.estimation.end +
              data.installation.estimation.end
            ).toFixed(2)
          ),
        },
      },
      collections: {
        recievable: {
          target: parseFloat(
            (
              result.collections.recievable.target +
              data.collections.recievable.target
            ).toFixed(2)
          ),
          actual: parseFloat(
            (
              result.collections.recievable.actual +
              data.collections.recievable.actual
            ).toFixed(2)
          ),
        },
        freshSales: {
          target: parseFloat(
            (
              result.collections.freshSales.target +
              data.collections.freshSales.target
            ).toFixed(2)
          ),
          actual: parseFloat(
            (
              result.collections.freshSales.actual +
              data.collections.freshSales.actual
            ).toFixed(2)
          ),
        },
        estimation: {
          start: parseFloat(
            (
              result.collections.estimation.start +
              data.collections.estimation.start
            ).toFixed(2)
          ),
          first: parseFloat(
            (
              result.collections.estimation.first +
              data.collections.estimation.first
            ).toFixed(2)
          ),
          second: parseFloat(
            (
              result.collections.estimation.second +
              data.collections.estimation.second
            ).toFixed(2)
          ),
          end: parseFloat(
            (
              result.collections.estimation.end +
              data.collections.estimation.end
            ).toFixed(2)
          ),
        },
      },
      weeklyInstallation: {
        first: parseFloat(
          (result?.weeklyInstallation?.first + data?.weeklyInstallation?.first).toFixed(2)
        ),
        second: parseFloat(
          (result?.weeklyInstallation?.second + data?.weeklyInstallation?.second).toFixed(2)
        ),
        third: parseFloat(
          (result?.weeklyInstallation?.third + data?.weeklyInstallation?.third).toFixed(2)
        ),
        fourth: parseFloat(
          (result?.weeklyInstallation?.fourth + data?.weeklyInstallation?.fourth).toFixed(2)
        ),
      }
    };
    return result;
  }

  const updateData = async () => {
    if (selectedRegion.length >= 1) {
      const dataPromises: Promise<any>[] = selectedRegion.map((region: any) =>
        getTargetByRegion(region.value, selectedMonth.value, selectedYear.value)
      );
      Promise.all(dataPromises).then((values) => {
        setData(values[0]);

        let regionReportData = initialRegionData;

        values.map((regionData) => {
          if (regionData.length !== 0) {
            setShowGauge(true);
            setNoData(false);
          }
          try {
            regionReportData = addReportData(regionData[0], regionReportData);
          } catch (e) {
            console.log("error in regiondata fetching: ", e);
          }
          setRegionReport(regionReportData);
        });
      });
      const choosenRegion = selectedRegion?.map((region: any) => region.value);
      let res = await getMonthlyPlans(selectedMonth.value, selectedYear.label);
      res = res?.filter((r: any) => choosenRegion?.includes(r?.region));
      res.map((plan: any) => {
      plan.name = regionDict?.[plan.region] ?? 'Unknown Region';
      });
      setMonthlyData(res);
    } else {
      setRegionReport(initialRegionData);
      setNoData(true);
      setShowGauge(false);
      setMonthlyData([]);
    }
  };

  useEffect(() => {
    if (selectedRegion && selectedMonth && selectedYear) {
      //fetch data on basis of changes
      updateData();
    } else setShowGauge(false);
  }, [selectedRegion, selectedMonth, selectedYear]);

  const onBtNext = () => {
    setSkip(skip + 15);
  };

  const onBtPrevious = () => {
    setSkip(skip - 15);
  };

  const PaginationIconProps = {
    fontSize: "small",
    color: "action",
  };

  const labels = ["Month Start", "1st Review", "2nd Review", "Month End"];

  const generateBarChartOptions = () => ({
    plugins: {
      title: {
        display: true,
      },
      datalabels: {
        display: true,
        color: "black",
        font: {
          size: 28, // Increased font size to 16
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
        beginAtZero: true,
      },
    },
  });

  const revenueCollectionChartData = () => ({
    labels,
    datasets: [
      {
        label: "Revenue",
        data: Object.values(
          data == null ? null : regionReport?.revenue.estimation
        ) ?? [0, 0, 0, 0],

        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Collections",
        data: Object.values(
          data == null ? null : regionReport?.collections.estimation
        ) ?? [0, 0, 0, 0],
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  });

  const installationChartData = () => ({
    labels,
    datasets: [
      {
        label: "Installation",
        data: Object.values(
          data == null ? null : regionReport?.installation.estimation
        ) ?? [0, 0, 0, 0],
        backgroundColor: "rgba(0, 102, 0, 0.5)",
      },
    ],
  });

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#228B22",
    },
  }));

  function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number; target: number }
  ) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <BorderLinearProgress
            variant="determinate"
            value={(props.value / props.target) * 100}
          />
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Typography variant="h5">Installations</Typography>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="h5">{`${props.value}/${props.target}`}</Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
  }

  function ModifiedGauge(props: GaugeProps) {
    return (
      <Gauge
        height={150}
        value={props.value}
        startAngle={-90}
        endAngle={90}
        innerRadius={80}
        valueMax={props.valueMax}
        sx={{
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: 30,
            transform: "translate(3px, -15%)",
          },
        }}
        text={({ value, valueMax }) => `${value} / ${valueMax}`}
      />
    );
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, index, value, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  const [tabValue, setTabValue] = useState(0);

  const sumRegionTotalsInRange = (array: any) => {
    const totalReport: { [key: string]: any } = {
      name: "Total",
      revenue: { actual: 0, target: 0 },
      collections: {
        recievable: { actual: 0, target: 0 },
        freshSales: { actual: 0, target: 0 },
      },
      installation: { actual: 0, target: 0 },
    };

    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      const values: string[] = ["target", "actual"];
      const revenue = item?.revenue;
      const collections = item?.collections;
      const installation = item?.installation;
      for (const key of values) {
        totalReport.revenue[key] += revenue[key];
        totalReport.installation[key] += installation[key];
        totalReport.collections.recievable[key] += collections.recievable[key];
        totalReport.collections.freshSales[key] += collections.freshSales[key];
      }
    }

    for (const key of ["target", "actual"]) {
      totalReport.revenue[key] = parseFloat(
        totalReport.revenue[key].toFixed(2)
      );
      totalReport.installation[key] = parseFloat(
        totalReport.installation[key].toFixed(2)
      );
      totalReport.collections.recievable[key] = parseFloat(
        totalReport.collections.recievable[key]
      ).toFixed(2);
      totalReport.collections.freshSales[key] = parseFloat(
        totalReport.collections.freshSales[key].toFixed(2)
      );
    }

    gridRef?.current?.api?.setPinnedBottomRowData([totalReport]);
  };

  const sumEstimationTotalsInRange = (array: any) => {
    const totalEstimations: { [key: string]: any } = {
      name: "Total",
      revenue: {
        estimation: { start: 0, first: 0, second: 0, end: 0 },
        actual: 0,
      },
      collections: { estimation: { start: 0, first: 0, second: 0, end: 0 } },
      installation: { estimation: { start: 0, first: 0, second: 0, end: 0 } },
      weeklyInstallation: {first: 0, second: 0, third: 0, fourth:0}
    };

    array.map((item: any) => {
      const values: string[] = ["start", "first", "second", "end"];
      const revenue = item?.revenue.estimation;
      const collections = item?.collections.estimation;
      const installation = item?.installation.estimation;

      totalEstimations.revenue.actual += item?.revenue?.actual;

      for (const key of values) {
        totalEstimations.revenue.estimation[key] += revenue[key];
        totalEstimations.collections.estimation[key] += collections[key];
        totalEstimations.installation.estimation[key] += installation[key];
      }

      for (const key of ["first", "second", "third", "fourth"]) {
        totalEstimations.weeklyInstallation[key] += item?.weeklyInstallation?.[key]??0;
      }
    });

    totalEstimations.revenue.actual = parseFloat(
      totalEstimations.revenue.actual
    ).toFixed(2);
    for (const key of ["start", "first", "second", "end"]) {
      totalEstimations.revenue.estimation[key] = parseFloat(
        totalEstimations.revenue.estimation[key]
      ).toFixed(2);
      totalEstimations.collections.estimation[key] = parseFloat(
        totalEstimations.collections.estimation[key]
      ).toFixed(2);
      totalEstimations.installation.estimation[key] = parseFloat(
        totalEstimations.installation.estimation[key]
      ).toFixed(2);
    }
    for (const key of ["first", "second", "third", "fourth"]) {
      totalEstimations.weeklyInstallation[key] = parseFloat(totalEstimations?.weeklyInstallation?.[key]).toFixed(2);
    }

    estimationGridRef?.current?.api?.setPinnedBottomRowData([totalEstimations]);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const onGridCellChanged = (e: any) => {
    const { revenue, installation, collections } = e.data;

    collections.estimation.end = Number(
      parseFloat(
        (
          collections.freshSales.actual + collections.recievable.actual
        ).toString()
      ).toFixed(2)
    );
    revenue.estimation.end = Number(parseFloat(revenue.actual).toFixed(2));
    installation.estimation.end = parseInt(installation.actual);

    const payload = {
      id: e.data.id,
      revenue: revenue,
      installation: installation,
      collections: collections,
      region: e.data.region,
      month: e.data.month,
      year: e.data.year,
    };
    updateTarget(payload).then((res: any) => {
      if (res.error) {
        alert("Error in Target Updation");
      }
    });
  };

  const getRowStyle = (params: any) => {
    if (params.node.rowIndex % 2 === 0) {
      if (params.node.rowPinned) {
        return { fontWeight: "bold", background: "#f0f0f0" } as RowStyle;
      }
      return { background: "#f0f0f0" }; // Light color for even rows
    }

    if (params.node.rowPinned) {
      return { fontWeight: "bold", background: "#d3d3d3" } as RowStyle;
    }

    return { background: "#d3d3d3" }; // Darker color for odd rows
  };

  const onClickFetchRevenue = () => {
    const currMonth = dayjs()
      .month(selectedMonth.value)
      .subtract(1, "month")
      .startOf("month")
      .add(1, "day")
      .toISOString();
    const nextMonth = dayjs()
      .month(selectedMonth.value)
      .startOf("month")
      .add(1, "day")
      .toISOString();

    getZohoReport(currMonth, nextMonth).then((res) => {
      const mergedRegionList = regionListData.map((regionItem: any) => {
        const matchingRes = res.find(
          (item: any) => item.salesperson_id === regionItem.salesPersonId
        );
        return {
          ...regionItem,
          ...matchingRes,
        };
      });
      const newTableData = JSON.parse(JSON.stringify(tableData));
      newTableData.map((row: any) => {
        const region = row.region;
        const matchingRecord = mergedRegionList.find(
          (record: any) => record.id === region
        );
        if (
          matchingRecord &&
          matchingRecord?.sales &&
          matchingRecord?.salesperson_name
        ) {
          const actual_revenue: Number = Number(
            (matchingRecord.sales / 100000).toFixed(2)
          );
          row.revenue.actual = actual_revenue;
          row.revenue.estimation.end = actual_revenue;
          const record = {
            id: row.id,
            revenue: row.revenue,
            installation: row.installation,
            collections: row.collections,
            region: row.region,
            month: row.month,
            year: row.year,
            salesPersonName: matchingRecord.salesperson_name,
          };

          setFetchedRevenueData((prevData: any) => [...prevData, record]);
        }
      });
    });
    setShowFetchRevenueModal(true);
  };

  const confirmRevenueFetch = () => {
    let promises: Promise<any>[] = [];
    fetchedRevenueData.map((item: any) => {
      const payload = {
        id: item.id,
        revenue: item.revenue,
        installation: item.installation,
        collections: item.collections,
        region: item.region,
        month: item.month,
        year: item.year,
      };
      const promise = updateTarget(payload).then();
      promises.push(promise);
    });

    Promise.all(promises).then((res) => {
      alert("revenues fetched");
    });

    const updatedTableData = tableData.map((row: any) => {
      const fetchedRow = fetchedRevenueData.find((fr: any) => fr.id === row.id);
      if (fetchedRow) {
        return { ...row, ...fetchedRow };
      }
      return row;
    });

    setTableData(updatedTableData);

    updateTable();
  };

  const updateTable = () => {
    if (gridRef.current) {
      gridRef.current.api?.setRowData(tableData);
    }
  };

  const DeleteButtonRenderer = (props: any) => {
    const onDelete = () => {
      const rowIndex = props.node.rowIndex;
      props.context.onDeleteRow(rowIndex);
    };

    return <Button onClick={onDelete}>Delete</Button>;
  };

  const onDeleteRow = (rowIndex: number) => {
    setFetchedRevenueData((prevData: any) => {
      const newData = [...prevData];
      newData.splice(rowIndex, 1);
      return newData;
    });
  };

  const frameworkComponents = {
    deleteButtonRenderer: DeleteButtonRenderer,
  };

  const downloadData = () => {
    // Prepare data for 'target' sheet
    const excelData = tableData.map((item) => ({
      Region: item?.name || item?.region,
      Revenue_Target: item.revenue.target,
      Revenue_Actual: item.revenue.actual,
      Collections_Receivables_Target: item.collections.recievable.target,
      Collections_Receivables_Actual: item.collections.recievable.actual,
      Collections_FreshSales_Target: item.collections.freshSales.target,
      Collections_FreshSales_Actual: item.collections.freshSales.actual,
      Installation_Target: item.installation.target,
      Installation_Actual: item.installation.actual,
    }));

    const totalRow: any = {
      Region: "Total",
      ...calculateTotals(excelData),
    };

    excelData.push(totalRow);

    // Prepare data for 'estimation' sheet
    const estimationData = tableData.map((item) => ({
      Region: item?.name || item?.region,
      Revenue_Start: item.revenue.estimation.start,
      Revenue_First: item.revenue.estimation.first,
      Revenue_Second: item.revenue.estimation.second,
      Revenue_Actual: item.revenue.actual,
      Collections_Start: item.collections.estimation.start,
      Collections_First: item.collections.estimation.first,
      Collections_Second: item.collections.estimation.second,
      Collections_Actual: item.collections.estimation.end,
      Installations_Start: item.installation.estimation.start,
      Installations_First: item.installation.estimation.first,
      Installations_Second: item.installation.estimation.second,
      Installations_Actual: item.installation.actual,
      Weekly_Installations_1st_Week: item?.weeklyInstallation?.first??0,
      Weekly_Installations_2nd_Week: item?.weeklyInstallation?.second??0,
      Weekly_Installations_3rd_Week: item?.weeklyInstallation?.third??0,
      Weekly_Installations_4th_Week: item?.weeklyInstallation?.fourth??0,
    }));

    const totalRow2: any = {
      Region: "Total",
      ...calculateTotals(estimationData),
    };

    estimationData.push(totalRow2);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workbook,
      XLSX.utils.json_to_sheet(excelData),
      "target"
    );
    XLSX.utils.book_append_sheet(
      workbook,
      XLSX.utils.json_to_sheet(estimationData),
      "estimation"
    );

    const fileName = `target-estimation-report-${selectedMonth.label}.xlsx`;
    XLSX.writeFile(workbook, fileName);
  };

  const calculateTotals = (data: Array<any>) => {
    const totals: any = {};

    Object.keys(data[0]).forEach((key) => {
      if (typeof data[0][key] === "number") {
        totals[key] = data.reduce((acc, curr) => acc + curr[key], 0);
      }
    });

    return totals;
  };

  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid
        p={2}
        borderRadius={20}
        justifyContent={"space-between"}
        display={"flex"}
      >
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton onClick={() => navigate("/")}>
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Region Report
          </Typography>
        </Grid>

        <Grid display={"flex"}>
          <Button
            style={{ marginRight: 8 }}
            variant="contained"
            color="primary"
            onClick={() => setShowModalTarget(true)}
          >
            Add Report
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickFetchRevenue}
          >
            Fetch Revenues
          </Button>
        </Grid>
        <Dialog
          open={showModalTarget}
          onClose={() => setShowModalTarget(false)}
          maxWidth="md"
          fullWidth={true}
        >
          <TargetDetails
            closeModal={() => setShowModalTarget(false)}
            updateData={updateData}
            region={region}
            months={months}
          />
        </Dialog>

        <Dialog
          open={showFetchRevenueModal}
          onClose={() => {
            setFetchedRevenueData([]);
            setShowFetchRevenueModal(false);
          }}
          maxWidth="md"
          fullWidth={true}
        >
          <Grid>
            <AgGridReact
              ref={useRef<AgGridReact>(null)}
              rowData={fetchedRevenueData}
              columnDefs={RevenueModalColDef}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              animateRows={true}
              gridOptions={revenueModalGridOptions}
              domLayout="autoHeight"
              getRowStyle={getRowStyle}
              frameworkComponents={frameworkComponents}
              context={{ onDeleteRow }}
            ></AgGridReact>

            <Grid
              item
              container
              justifyContent="center"
              spacing={2}
              paddingY={4}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={confirmRevenueFetch}
                >
                  Confirm Addition
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setFetchedRevenueData([]);
                    setShowFetchRevenueModal(false);
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>

      <Grid>
        <Grid display={"flex"} justifyContent={"space-between"}>
          <div
            style={{
              margin: "0px 0 15px 0",
              padding: "5px 10px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Grid width={380} mr={1}>
              <Typography variant="subtitle1" fontWeight={600}>
                Region
              </Typography>
              <Select
                options={region}
                onChange={(selected: MultiValue<any>) =>
                  handleDropdownChange("region", selected)
                }
                isSearchable={true}
                placeholder={"- select -"}
                isLoading={isRegionLoading}
                isDisabled={isRegionLoading}
                value={selectedRegionOption}
                isClearable
                isMulti
              />
            </Grid>

            <Grid width={380} mr={1}>
              <Typography variant="subtitle1" fontWeight={600}>
                Month
              </Typography>
              <Select
                options={months}
                onChange={(selected: MultiValue<any>) =>
                  handleDropdownChange("month", selected)
                }
                isSearchable={true}
                placeholder={"- select -"}
                value={selectedMonth}
                isClearable
              />
            </Grid>

            <Grid width={380} mr={1}>
              <Typography variant="subtitle1" fontWeight={600}>
                Year
              </Typography>
              <Select
                options={Array.from({ length: 51 }, (_, i) => ({
                  value: 1990 + i,
                  label: 1990 + i,
                }))}
                onChange={(selected: MultiValue<any>) =>
                  handleDropdownChange("year", selected)
                }
                isSearchable={true}
                placeholder={"- select -"}
                value={selectedYear}
                isClearable
              />
            </Grid>
          </div>

          {showGauge && (
            <>
              {selectedRegion.length === 1 && (
                <IconButton
                  onClick={() => {
                    setShowEditTarget(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              )}
              <Dialog
                open={showEditTarget}
                onClose={() => setShowEditTarget(false)}
                maxWidth="md"
                fullWidth={true}
              >
                <TargetDetails
                  closeModal={() => setShowEditTarget(false)}
                  updateData={updateData}
                  region={region}
                  months={months}
                  targetDetails={data[0]}
                  edit={true}
                />
              </Dialog>
            </>
          )}
        </Grid>
      </Grid>

      {noData && (
        <Grid container spacing={2} justifyContent="center" padding={2}>
          <Grid item xs={12}>
            <Typography variant="h5" component="div">
              No Data for selected parameters
            </Typography>
          </Grid>
        </Grid>
      )}
      {showGauge && (
        <Grid
          container
          spacing={2}
          justifyContent="center"
          padding={2}
          direction={"column"}
        >
          <Grid container spacing={2} justifyContent={"left"} padding={2}>
            <Grid item xs={12} sm={3}>
              <Card elevation={4} sx={{ height: "100%" }}>
                <CardContent sx={{ height: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Typography variant="h5" component="div">
                      Revenue (in Lakhs)
                    </Typography>
                    <ModifiedGauge
                      value={data == null ? 0 : regionReport?.revenue.actual}
                      valueMax={data == null ? 0 : regionReport?.revenue.target}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Card elevation={4}>
                <CardContent>
                  <Typography variant="h5" component="div">
                    Collections (in Lakhs)
                  </Typography>
                  <Grid
                    container
                    xs={24}
                    display={"flex"}
                    flexDirection={"row"}
                  >
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" textAlign={"center"}>
                        Recievables
                      </Typography>
                      <ModifiedGauge
                        value={
                          data == null
                            ? 0
                            : regionReport?.collections.recievable.actual
                        }
                        valueMax={
                          data == null
                            ? 0
                            : regionReport?.collections.recievable.target
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" textAlign={"center"}>
                        Fresh Sales
                      </Typography>
                      <ModifiedGauge
                        value={
                          data == null
                            ? 0
                            : regionReport?.collections.freshSales.actual
                        }
                        valueMax={
                          data == null
                            ? 0
                            : regionReport?.collections.freshSales.target
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" textAlign={"center"}>
                        Total
                      </Typography>
                      <ModifiedGauge
                        value={
                          data == null
                            ? 0
                            : parseFloat(
                                (
                                  regionReport?.collections.recievable.actual +
                                  regionReport?.collections.freshSales.actual
                                ).toFixed(2)
                              )
                        }
                        valueMax={
                          data == null
                            ? 0
                            : parseFloat(
                                (
                                  regionReport?.collections.recievable.target +
                                  regionReport?.collections.freshSales.target
                                ).toFixed(2)
                              )
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Grid item spacing={2} justifyContent={"center"} padding={2}>
            <Card elevation={4}>
              <CardContent>
                <LinearProgressWithLabel
                  value={regionReport?.installation.actual}
                  target={regionReport?.installation.target}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {showGauge && (
        <Grid item xs={12} sm={3} padding={2}>
          <Card elevation={4}>
            <CardContent>
              <Typography variant="h5" component="div">
                Estimations
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Bar
                    data={revenueCollectionChartData()}
                    plugins={[ChartDataLabels]}
                    options={generateBarChartOptions()}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Bar
                    data={installationChartData()}
                    plugins={[ChartDataLabels]}
                    options={generateBarChartOptions()}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}

      <Grid display={"flex"} justifyContent={"space-between"} paddingX={6}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab key={0} label={"Target Report"} {...a11yProps(0)} />
            <Tab key={1} label={"Estimations"} {...a11yProps(0)} />
          </Tabs>
        </Box>

        <ButtonGroup color="success" variant="contained">
          <Button onClick={downloadData}>Export to excel</Button>
        </ButtonGroup>
      </Grid>

      <TabPanel value={tabValue} index={0}>
        {/* Grid Table without estimation */}
        <div style={gridStyle} className="ag-theme-alpine">
          <Grid p={3}>
            <Grid item display={"flex"} justifyContent={"right"}>
              <FormControlLabel
                control={
                  <Switch
                    inputProps={{ "aria-label": "controlled" }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setEditGrid(event.target.checked)
                    }
                  />
                }
                label="Edit Details"
              />
            </Grid>

            <AgGridReact
              ref={gridRef}
              rowData={tableData}
              columnDefs={RegionReportColDef}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              animateRows={true}
              gridOptions={gridOptions}
              domLayout="autoHeight"
              onPaginationChanged={(e: any) => {
                const rowsToDisplay = e?.api?.rowModel?.rowsToDisplay || [];
                sumRegionTotalsInRange(
                  rowsToDisplay.map((item: any) => item.data)
                );
              }}
              onSortChanged={handleColumnSort}
              onFilterChanged={handleColumnFilter}
              onCellValueChanged={onGridCellChanged}
              getRowStyle={getRowStyle}
            ></AgGridReact>
            <Grid
              container
              py={2}
              justifyContent={"flex-end"}
              alignItems={"center"}
              border={1}
              borderColor={"silver"}
              bottom={0}
              left={0}
              right={0}
            >
              <Button onClick={onBtPrevious} disabled={skip === 0}>
                <KeyboardArrowLeftTwoTone {...(PaginationIconProps as any)} />
              </Button>

              <Typography>
                Page {Math.round(skip / 15) + 1} of {totalTargetRegions}
              </Typography>

              <Button
                onClick={onBtNext}
                disabled={skip === (totalTargetRegions - 1) * 15}
                id="btNext"
              >
                <KeyboardArrowRightTwoTone {...(PaginationIconProps as any)} />
              </Button>
            </Grid>
          </Grid>
        </div>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        {/* Grid Table with estimation */}
        <div style={gridStyle} className="ag-theme-alpine">
          <Grid p={3}>
            <AgGridReact
              ref={estimationGridRef}
              rowData={tableData}
              columnDefs={EstimationRegionReportColDef}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              animateRows={true}
              gridOptions={estimationGridOptions}
              getRowStyle={getRowStyle}
              domLayout="autoHeight"
              onPaginationChanged={(e: any) => {
                const rowsToDisplay = e?.api?.rowModel?.rowsToDisplay || [];
                sumEstimationTotalsInRange(
                  rowsToDisplay.map((item: any) => item.data)
                );
              }}
              onSortChanged={handleColumnSort}
              onFilterChanged={handleColumnFilter}
            ></AgGridReact>
            <Grid
              container
              py={2}
              justifyContent={"flex-end"}
              alignItems={"center"}
              border={1}
              borderColor={"silver"}
              bottom={0}
              left={0}
              right={0}
            >
              {/* <Button onClick={onBtPrevious} disabled={skip === 0}>
                            <KeyboardArrowLeftTwoTone {...(PaginationIconProps as any)} />
                        </Button>

                        <Typography>
                            Page {Math.round(skip / 15) + 1} of {totalTargetRegions}
                        </Typography>

                        <Button
                            onClick={onBtNext}
                            disabled={skip === (totalTargetRegions - 1) * 15}
                            id="btNext"
                        >
                            <KeyboardArrowRightTwoTone {...(PaginationIconProps as any)} />
                        </Button> */}
            </Grid>
          </Grid>
        </div>
      </TabPanel>

      {!!monthlyData.length &&
        monthlyData.map((plan) => (
          <Grid container direction="column" key={plan.id}>
            <PhaseGraph plan={plan} activityTypes={activityTypes} />
          </Grid>
        ))}
    </Grid>
  );
};

export default RegionReport;
