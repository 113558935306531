import { KeyValues } from 'src/constants/interfaces';
import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

const DEFAULT_PAGINATION_SIZE = 20;

const AgGrid: React.FC<Props> = (props) => {

  const { ids, baseUrl, idField, filterUrl, paginationPageSize, pagination, colDef, onRowClicked, ignoreData } = props;
  const paginationSize = paginationPageSize ?? DEFAULT_PAGINATION_SIZE;

  const [ignoredRowData, setIgnoredRowData] = useState<boolean>(false);

  // states for pagination
  // const [disablePaginationPrevButton, setDisablePaginationPrevButton] = useState<boolean>(false);
  // const [disablePaginationNextButton, setDisablePaginationNextButton] = useState<boolean>(false);

  let totalPages = useRef<any>(null);

  const [rowData, setRowData] = useState<any[]>([]);

  const gridRef = useRef<AgGridReact>(null);
  const idsRef = useRef<any>(null);

  /**
   * first api call,
   * for first call, fetch PaginationSize * 2 data
   */
  useEffect(() => {
    idsRef.current = ids;
    initialCall();
  }, [ids, pagination, paginationPageSize, baseUrl, idField, filterUrl]) // eslint-disable-line

  useEffect(() => {
    if (!!ignoreData) setIgnoredRowData(false);
  }, [ignoreData]);

  useEffect(() => {
    if (!rowData) return;
    const observationIds = (rowData ?? [])?.map((data) => data.id);
    const observationIdsSet = [...new Set(observationIds)];
    if (observationIdsSet.length < observationIds.length) {
      console.warn("repeated data", observationIds.length - observationIdsSet.length);
    }

    if (!!ignoreData && !ignoredRowData) {
      setRowData(rowData => rowData.filter(data => !ignoreData.includes(data[idField ?? "id"])));
      setIgnoredRowData(true);
    }
  }, [rowData, ignoredRowData]) // eslint-disable-line

  // api calls
  const initialCall = () => {
    setRowData([]);
    if (!idsRef.current) {
      fetchData(true);
    } else {
      totalPages.current = Math.ceil(idsRef.current.length / paginationSize);
      fetchDataWithIds(true);
    }
  }

  const fetchNextPageData = () => {
    if (!idsRef.current) {
      fetchData();
    } else if ((gridRef.current?.props.rowData?.length ?? 0) < idsRef.current.length) {
      fetchDataWithIds();
    }
  }

  const fetchData = (firstCall = false) => {
    const currentPage = getCurrentPage(gridRef);
    let limit = pagination ? paginationSize * 2 : undefined;

    const skip = currentPage === 0 ? 0 : paginationSize * (currentPage + 1);

    if(!baseUrl) return;

    fetchWrapper({
      baseUrl: baseUrl!,
      limit,
      skip
    }).then((res) => {
      if (firstCall) {
        setRowData(res);
      } else {
        setRowData(rowData => ([...rowData, ...res]));
      }
    }).catch(error => {
      console.error("error", error);
    })
  }

  const fetchDataWithIds = (firstCall = false) => {
    if (!idsRef.current) return;

    const currentPage = getCurrentPage(gridRef);
    const skip = (currentPage === 0 || firstCall) ? 0 : paginationSize * (currentPage + 1);
    const idsToFetch = idsRef.current.slice(skip, skip + paginationSize * 2);

    fetchWithIdsWrapper({
      baseUrl: baseUrl!,
      ids: idsToFetch
    }).then(res => {
      if (firstCall) {
        setRowData(res);
      } else {
        setRowData(rowData => ([...rowData, ...res]));
      }
    })
      .catch(error => {
        console.error("Error", error);
      })
  }

  /**
   * Ag-Grid 
   */
  const gridOptions: GridOptions = {
    pagination,
    paginationPageSize: paginationSize,
    columnDefs: colDef,
    onRowClicked
  };

  const onPaginationChanged = useCallback(() => {
    if (!!gridRef.current?.api) {
      const currentPage = getCurrentPage(gridRef);
      const totalPages = getLastPage(gridRef);
      const isLastPage = currentPage + 1 === totalPages;

      if (isLastPage) {
        fetchNextPageData();
      }

      // setText('#lbCurrentPage', "random text");
      // setText('#lbTotalPages', totalPages);
      // setLastButtonDisabled('#btLast', !gridRef.current!.api.paginationIsLastPageFound());
    }
  }, []); // eslint-disable-line

  return (
    <div className="ag-theme-alpine" style={{ width: "100%", height: "100%" }}>
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        gridOptions={gridOptions}
        onPaginationChanged={onPaginationChanged}
      // onGridReady={onGridReady}
      // onRowDataUpdated={onRowDataUpdated}
      // onSortChanged={handleColumnSort}
      // onFilterChanged={(e) => handleColumnFilter(e)}
      />
      {/* <span> Total Pages: {totalPages.current} </span> */}
    </div>
  )
}

export default AgGrid;

/**
 * ================== TYPES ======================
 */
type Props = {
  pagination?: boolean;
  paginationPageSize?: number;
  colDef: (ColDef | ColGroupDef | KeyValues)[];

  selectRows?: boolean;

  /**
   * represents the columns to be rendered on initial render
   */
  defaultColumns?: string[];

  /**
   * if columnsDropdown is true,
   *  render a multi-select dropdown for displaying/hiding columns in grid
   */
  columnsDropdown?: boolean;

  /**
   * ================== CLIENT-DATA ======================
   */
  data?: any[];

  /**
   * ================== SERVER-DATA ======================
   */

  /**
   * server side data rendering
   * ids: all the ids that need to be rendered
   * if pagination is applied, data will be fetched for only required ids
   * 
   * if ids is null, initial call
   */
  ids?: string[] | null; // for server data

  idField?: string;

  /**
   * api url to fetch data of provided ids
   */
  baseUrl?: string;

  /**
   * api to hit for filtering
   */
  filterUrl?: string;

  ignoreData?: string[];
  onRowClicked?: (...args: any) => void;
  onCellClicked?: (...args: any) => void;
  onRowSelection?: (rows: any[]) => void;
}

/**
 * ================== HELPER FUNCTIONS ======================
 */

const fetchWrapper = async (args: FetchData) => {
  const { baseUrl, limit, skip } = args;
  const filterQuery: any = {
    order: "_id DESC"
  };

  if (skip) {
    filterQuery.skip = skip ?? 0;
  }

  if (limit) {
    filterQuery.limit = limit;
  }

  const url = baseUrl + '?filter=' + JSON.stringify(filterQuery);

  return fetch(url, {
    headers: {
      authorization: "Bearer " + localStorage.getItem('access_token') as string,
    }
  }).then(res => res.json())
}

const fetchWithIdsWrapper = async (args: FetchWithId) => {
  const { baseUrl, ids } = args;
  const filterQuery: any = { order: "_id DESC" };

  filterQuery.where = {};
  filterQuery.where.id = { inq: ids };
  const url = baseUrl + '?filter=' + JSON.stringify(filterQuery);

  return fetch(url, {
    headers: {
      authorization: "Bearer " + localStorage.getItem('access_token') as string,
    }
  }).then(res => res.json())
}

/**
 * ================== TYPES ======================
 */

type FetchData = {
  baseUrl: string;
  limit?: number;
  skip?: number;
}

type FetchWithId = {
  baseUrl: string;
  ids: string[]
}

// function setText(selector: string, text: any) {
//   (document.querySelector(selector) as any).innerHTML = text;
// }

// function setLastButtonDisabled(selector: string, disabled: boolean) {
//   (document.querySelector(selector) as any).disabled = disabled;
// }

const getCurrentPage = (gridRef: React.RefObject<AgGridReact<any>>) => {
  if (isGridInitialized(gridRef)) return gridRef?.current!.api.paginationGetCurrentPage();

  return 0;
}
const getLastPage = (gridRef: React.RefObject<AgGridReact<any>>) => {
  if (isGridInitialized(gridRef))
    return gridRef.current!.api.paginationGetTotalPages();

  return 0;
}

const isGridInitialized = (gridRef: React.RefObject<AgGridReact<any>>) => {
  return !!gridRef && !!gridRef.current && !!gridRef.current.api;
}