import { FC } from "react";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { InfoModalButtonWithIcon } from "./InfoModalComps";
import PrintInformation from "./PrintInformation";

import ndviIcon from 'src/assets/ndviImage.png';
import showChartIcon from 'src/assets/showCharts.png';
import changeLocationIcon from 'src/assets/changeLocation.png';

interface InfoData {
  isLoading?: boolean;
  message?: string;
  name?: string | number;
  plotId?: string;
  area?: number;
  crop?: string;
  address?: string;
  deviceType?: string;
  marker?: any;
}

interface Props {
  infodata: InfoData;
  setDragMarker: (...args: any) => void;
  setGetndviImage: (...args: any) => void;
  setGetndviImageCrop: (...args: any) => void;
  setGetndviImageFarmId: (...args: any) => void;
  setGetndviImagePlotId: (...args: any) => void;
  setIsToastOpen: (...args: any) => void;
  setShowinfo: (...args: any) => void;
  setToastMessage: (...args: any) => void;
  viewChartForPlot: (...args: any) => void;
}

const InfoModalWrapper: FC<Props> = (props) => {
  const {
    infodata, setDragMarker, setGetndviImage,
    setGetndviImageCrop, setGetndviImageFarmId,
    setGetndviImagePlotId, setIsToastOpen,
    setShowinfo, setToastMessage, viewChartForPlot
  } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      height="100%"
      width={'300px'}
    >
      {infodata.isLoading && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height={100}
          width={'100%'}
        >
          <CircularProgress color="success" />
        </Grid>
      )}

      {!!infodata.message && (
        <Typography variant="caption">{infodata.message}</Typography>
      )}

      {!!infodata.name && !!infodata.plotId && (
        <PrintInformation
          label={"Farm Name"}
          value={infodata.name}
          isLink
          plotId={infodata.plotId}
        />
      )}

      {!!infodata.area && (
        <PrintInformation label={"Area"} value={infodata.area} />
      )}

      {!!infodata.crop && (
        <PrintInformation label={"Crop"} value={infodata.crop} />
      )}

      {!!infodata.address && (
        <PrintInformation label={"Location"} value={infodata.address} />
      )}

      {!!infodata.deviceType && (
        <PrintInformation label={"Device"} value={infodata.deviceType} />
      )}

      {!!infodata.message && (
        <Typography variant="caption">{infodata.message}</Typography>
      )}

      <Grid display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} style={{ marginTop: 15 }} minWidth={280}>
        {!!infodata.plotId && (
          <InfoModalButtonWithIcon icon={ndviIcon} text={'Satellite Image'} onClick={() => {
            setGetndviImagePlotId(infodata.plotId);
            setGetndviImageCrop(infodata.crop)
            setGetndviImageFarmId(infodata.name)
            setGetndviImage(true);
            setShowinfo(false);
          }} />
        )}

        {!!infodata.name && (
          <InfoModalButtonWithIcon icon={showChartIcon} text={'Show Charts'} onClick={() => viewChartForPlot(infodata.marker)} />
        )}

        <InfoModalButtonWithIcon
          icon={changeLocationIcon}
          text={'Change Location'}
          onClick={() => {
            setDragMarker(true);
            setShowinfo(false);
            setIsToastOpen(true)
            setToastMessage('now drag the marker')
          }}
        />
      </Grid>
    </Grid>
  )
}

export default InfoModalWrapper;