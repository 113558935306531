import React, { useEffect, useState } from "react";
import { DataTable, Filters } from "./sections";
import { Adherence, EarlyIrrigationAdherence, IrrigationAdherence } from "./types";
import {
  GetAdherenceRequest,
  getIrrigationAdherence,
  getNutrientAdherence,
  getSprayAdherence,
  getIEarlyrrigation
} from "src/services/adherence.service";
import { SetToastArgs, ToastSeverity } from "src/components/ToastAlert/types";
import { TabMenu, ToastAlert } from "src/components";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import { SignalAboutErrorMessage } from "src/services/common.service";
import { Download } from "@mui/icons-material";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { generateExcelData, validateFilterQuery } from "./utils";
import { Bar } from "react-chartjs-2";
import { getPlotsWithNoFertigationReport } from "src/services/plot.service";

export type Tab = "spray" | "nutrient" | "irrigation";
const Tabs: Tab[] = ["irrigation", "nutrient", "spray"];

const AdherenceScreen: React.FC = () => {
  const [filterQuery, setFilterQuery] = useState<GetAdherenceRequest>({
    startTime: dayjs().subtract(10, "days").utc().toString(),
    endTime: dayjs().utc().toString(),
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<Array<Adherence | IrrigationAdherence>>();
  const [earlyIrrigation, setEarlyIrrigation] = useState<Array<EarlyIrrigationAdherence>>();

  const [noFertigationPlots, setNoFertigationPlots] = useState<any[]>();

  const [irrigationData, setIrrigationData] = useState<
    IrrigationAdherence[] | null
  >();
  const [nutrientData, setNutrientData] = useState<Adherence[] | null>();
  const [sprayData, setSprayData] = useState<Adherence[] | null>();

  const [activeTab, setActiveTab] = useState<Tab>("irrigation");
  const [tabValue, setTabValue] = useState<number>(0);

  // toast alert
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastSeverity, setToastSeverity] = useState<ToastSeverity>("info");
  const [graphData, setGraphData] = useState<any>();

  const [abortControllor, setAbortControllor] = useState<AbortController>();

  useEffect(() => {
    handleAdherenceData();
  }, [tabValue]); // eslint-disable-line

  useEffect(() => {
    switch (activeTab) {
      case "irrigation":
        setGraphData(generateChartData(irrigationData));
        break;
      case "nutrient":
        setGraphData(generateChartData(nutrientData));
        break;
      case "spray":
        setGraphData(generateChartData(sprayData));
        break;
      default:
        break;
    }
  }, [irrigationData, sprayData, nutrientData, activeTab]); // eslint-disable-line

  const generateBarChartOptions = () => ({
    plugins: {
      title: {
        display: true,
        text: `${activeTab === "irrigation" ? 'Irrigation' : activeTab === "nutrient" ? 'Nutrient' : "Spray"} Adherence`,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  });

  const generateChartData = (data: any = []): any => {
    let keyName: any;
    const chartData: {
      labels: string[];
      datasets: { label: string; data: number[]; backgroundColor: string, minBarLength: number }[];
    } = {
      labels: [],
      datasets: [
        {
          label: "Count",
          data: [],
          backgroundColor: "#007bff",
          minBarLength: 8
        },
      ],
    };

    const keys: any = {
      "Suggested and Done": "suggestedAndDone",
      "Not Suggested and Done": "notSuggestedAndDone",
      "Not Done": "notDone",
    };

    const groupedData: { [key: string]: number } = {};

    if (activeTab === "irrigation") {
      data?.forEach((item: any, index: number) => {
        keyName = item["result"];
        if (!groupedData[keyName]) {
          groupedData[keyName] = 0;
          chartData.labels.push(keyName);
        }
        groupedData[keyName]++;
      });
    } else {
      Object.entries(keys)?.forEach(([label, key]: [any, any]) => {
        if (!groupedData[key]) {
          groupedData[key] = 0;
          chartData.labels.push(label);
        }
      });
      data?.forEach((item: any, index: number) => {
        Object.entries(keys)?.forEach(([label, key]: [any, any]) => {
          if (item[key] === "true") {
            groupedData[key]++;
          }
        });
      });
    }

    chartData?.labels?.forEach((result) => {
      const count = groupedData[keyName] || 0;
      chartData.datasets[0].data.push(count);
    });

    chartData?.labels?.sort((a, b) => groupedData[keys[b] || b] - groupedData[keys[a] || a]);
    chartData.datasets[0].data = chartData.labels.map(
      (label) => groupedData[keys[label] || label] || 0
    );
    return chartData;
  };

  /**
   * API Calls
   */
  const fetchAdherence = (filtered = true) => {
    if (!validateFilterQuery(filterQuery ?? ({} as any))) {
      return;
    }

    if (!!abortControllor && isLoading) {
      abortControllor.abort();
    }

    setData([]);
    setEarlyIrrigation([]);
    const controllor = new AbortController();
    setAbortControllor(controllor);
    const signal = controllor.signal;

    setIsLoading(true);
    switch (activeTab) {
      case "irrigation":
        getIEarlyrrigation(filterQuery!, signal).then((res: any) => {
          setEarlyIrrigation(res);
        })
        getIrrigationAdherence(filterQuery!, signal)
          .then((res: any) => {
            setData(res);
            setIrrigationData(res);

            if (filtered) {
              setNutrientData(null);
              setSprayData(null);
            }
          })
          .catch((error) => {
            if (error.message !== SignalAboutErrorMessage) {
              setData([]);
              setIrrigationData(null);
              setToast({
                isOpen: true,
                severity: "error",
                message: "Something went wrong!!",
              });
            }
          })
          .finally(() => setIsLoading(false));
        break;
      case "nutrient":
        fetchNoFertigationPlots();
        getNutrientAdherence(filterQuery!, signal)
          .then((res) => {
            setData(res);
            setNutrientData(res);

            if (filtered) {
              setIrrigationData(null);
              setSprayData(null);
            }
          })
          .catch((error) => {
            if (error.message !== SignalAboutErrorMessage) {
              setData([]);
              setNutrientData(null);
              setToast({
                isOpen: true,
                severity: "error",
                message: "Something went wrong!!",
              });
            }
          })
          .finally(() => setIsLoading(false));
        break;
      case "spray":
        getSprayAdherence(filterQuery!, signal)
          .then((res) => {
            setData(res);
            setSprayData(res);

            if (filtered) {
              setIrrigationData(null);
              setNutrientData(null);
            }
          })
          .catch((error) => {
            if (error.message !== SignalAboutErrorMessage) {
              setData([]);
              setSprayData(null);
              setToast({
                isOpen: true,
                severity: "error",
                message: "Something went wrong!!",
              });
            }
          })
          .finally(() => setIsLoading(false));
        break;
      default:
    }
  };

  const downloadData = () => {
    let excelData: any[] = generateExcelData(activeTab, data!);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const plotsWithNoFertigationData = (noFertigationPlots ?? []).map((data, index) => {
      return [index + 1, data?.plotId, data?.name, data?.farmId, data?.farmUserId, data?.device?.deviceType, data?.cropSown?.name];
    });
    const worksheet2 = XLSX.utils.aoa_to_sheet([['', 'Plot Id', 'Plot Name', 'Farm Id', 'Farmuser Id', 'Device Type', 'Crop'] ,...plotsWithNoFertigationData]);
    XLSX.utils.book_append_sheet(workbook, worksheet2, "Plots with no fertigation");

    const fileName = `${activeTab}-${dayjs().format("DD/MM/YYYY")}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const fetchNoFertigationPlots = () => {
    if (!validateFilterQuery(filterQuery ?? ({} as any))) {
      return;
    }

    const { startTime, endTime, ...location } = filterQuery;

    getPlotsWithNoFertigationReport(null, location)
      .then((res: any[]) => {
        setNoFertigationPlots(res);
      })
      .catch(error => {
        setNoFertigationPlots([]);
        setToast({
          severity: 'error',
          message: 'Failed to fetch Plots with no fertigation report',
          isOpen: true
        })
      })
  }

  /**
   * State Handlers
   */

  const handleAdherenceData = () => {
    if (activeTab === "irrigation" && !!irrigationData) {
      setData(irrigationData);
    } else if (activeTab === "nutrient" && !!nutrientData) {
      setData(nutrientData);
    } else if (activeTab === "spray" && !!sprayData) {
      setData(sprayData);
    } else {
      fetchAdherence(false);
    }
  };

  const updateFilterLocation = (newLocation: any) => {
    setFilterQuery((filterQuery) => ({
      startTime: filterQuery?.startTime,
      endTime: filterQuery?.endTime,
      ...newLocation,
    }));
  };

  const updateFilterDate = (newDate: any) => {
    setFilterQuery((filterQuery) => ({
      ...filterQuery,
      ...newDate,
    }));
  };

  const setToast = (args: SetToastArgs) => {
    setIsToastOpen(args.isOpen);
    setToastSeverity(args.severity);
    setToastMessage(args.message);
  };

  const updateTabValue = (newValue: number) => {
    setTabValue(newValue);
    setActiveTab(Tabs[newValue]);
  };

  const noDataToDownload = isLoading || !data || data?.length === 0;
  return (
    <Grid px={2}>
      <Filters
        updateFilterDate={updateFilterDate}
        updateFilterLocation={updateFilterLocation}
        filter={fetchAdherence}
      />

      {isLoading && <CircularProgress size={"small"} />}

      <Grid py={1} px={3}>
        <Grid
          display={"flex"}
          width={"95%"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <TabMenu tabs={Tabs} value={tabValue} setValue={updateTabValue} />
          <IconButton
            size="small"
            onClick={downloadData}
            disabled={noDataToDownload}
          >
            <Download
              fontSize="small"
              color={noDataToDownload ? "disabled" : "success"}
            />
          </IconButton>
        </Grid>

        <div>
          <div style={{ width: "70%", margin: "auto" }}>
            {!!graphData && (
              <Bar data={graphData} options={generateBarChartOptions()} />
            )}
          </div>
        </div>
        <DataTable
          activeTab={activeTab}
          loading={isLoading}
          data={data ?? []}
          earlyIrrigationData={earlyIrrigation}
          noFertigationPlots={noFertigationPlots}
        />
      </Grid>

      <ToastAlert
        message={toastMessage}
        severity={toastSeverity}
        duration={3000}
        setOpen={setIsToastOpen}
        open={isToastOpen}
      />
    </Grid>
  );
};

export default AdherenceScreen;
