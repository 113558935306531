import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Grid, Typography, Button, Modal, IconButton } from "@mui/material";
import {
  ArrowBack,
  KeyboardArrowLeftTwoTone,
  KeyboardArrowRightTwoTone,
} from "@mui/icons-material";
import { farmUsersColDef } from "../constants/columnDefs";
import { AgGridReact } from "ag-grid-react";
import {
  GridReadyEvent,
  GridOptions,
  FilterChangedEvent,
  SortChangedEvent,
  ColDef,
  SideBarDef,
} from "ag-grid-community";
import {
  getFarmUsersCount,
  getFarmUserByPagination,
  updateFarmUser,
} from "src/services/farmUser.service";
import { DatePicker } from "src/components";
import moment from "moment";
import { ToastAlert } from "src/components";
import { ToastSeverity } from "src/components/ToastAlert/types";
import Select, { MultiValue, SingleValue } from "react-select";
import { generateOptions } from "../utils/helper";
import { getAllRegions } from "src/services/region.service";
import { LocationFilter } from "src/components/Filters";

const FarmUser = (): JSX.Element => {
  const [farmUsersData, setFarmUsersData] = useState<any[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);
  const [columnFilter, setColumnFilters] = useState<any>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>();
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastSeverity, setToastSeverity] = useState<ToastSeverity>("success");
  const [region, setRegion] = useState<MultiValue<any>>();
  const [selectedRegion, setSelectedRegion] = useState<any>();
  const [selectedUserType, setSelectedUserType] = useState<any>();

  const gridRef = useRef<AgGridReact>(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridRef?.current?.api?.sizeColumnsToFit();
    // gridRef?.current?.api.setColumnDefs(appNotifsColDef);
    // gridRef?.current?.api.setRowData(appNotifData);
  }, []);

  const DropDownStyles = {
    container: (baseStyles: any, state: any) => ({
      ...baseStyles,
      width: "100%",
      marginLeft: "5px",
      marginTop: "12px",
    }),
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      borderTop: state.isFocused ? "1px" : "0px",
      borderLeft: state.isFocused ? "1px" : "0px",
      borderRight: state.isFocused ? "1px" : "0px",
    }),
    menu: (baseStyles: any, state: any) => ({
      ...baseStyles,
      zIndex: 100,
    }),
  };

  const DropDownProps = {
    styles: DropDownStyles,
    isClearable: true,
    isSearchable: true,
    placeholder: "- select -",
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  const gridOptions: GridOptions = {
    columnDefs: farmUsersColDef,
    rowData: farmUsersData,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      setModalData(e.data);
      setOpenModal(true);
    },
  };

  const onPaginationChanged = useCallback(() => {}, []);

  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
  };

  const handleColumnFilter = (event: FilterChangedEvent) => {
    const filterModel = event.api.getFilterModel();
    const whereClause: any = { and: [] };

    if (Object.keys(filterModel).length > 0) {
      Object.entries(filterModel).forEach(([column, filter]) => {
        const { type, filterType, filter: filterValue } = filter;
        let filterCondition: any = {};
        switch (filterType) {
          case "text":
            filterCondition[column] = {
              like: `.*${filterValue}.*`,
              options: "i",
            };
            break;
          case "number":
            switch (type) {
              case "equals":
                filterCondition[column] = filterValue;
                break;
              case "notEqual":
                filterCondition[column] = { neq: filterValue };
                break;
              case "lessThan":
                filterCondition[column] = { lt: filterValue };
                break;
              case "lessThanOrEqual":
                filterCondition[column] = { lte: filterValue };
                break;
              case "greaterThan":
                filterCondition[column] = { gt: filterValue };
                break;
              case "greaterThanOrEqual":
                filterCondition[column] = { gte: filterValue };
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
        whereClause.and.push(filterCondition);
      });
    }
    setColumnFilters(whereClause.and);
  };

  useEffect(() => {
    setSkip(0);
    getFarmUserByPagination(0, columnFilter).then((res: any) => {
      setFarmUsersData(res);
    });

    getFarmUsersCount(columnFilter).then((res: any) => {
      let cnt = Math.round(res.count / 30);

      if (res.count % 30 !== 0) cnt += 1;
      setTotalUsers(cnt);
    });
  }, [columnFilter]);

  useEffect(() => {
    getFarmUserByPagination(skip, columnFilter).then((res: any) => {
      setFarmUsersData(res);
    });

    getFarmUsersCount(columnFilter).then((res: any) => {
      let cnt = Math.round(res.count / 30);

      if (res.count % 30 !== 0) cnt += 1;
      setTotalUsers(cnt);
    });
  }, [skip, openModal]);

  useEffect(() => {
    setSelectedRegion(null);
    setSelectedUserType(null);
    if (!!openModal && modalData?.regions) {
      const userRegions = generateOptions(modalData?.regions, 'id', 'name');
      setSelectedRegion(userRegions);
    }
    if (!!openModal && modalData?.userTypeId) {
      setSelectedUserType(
        userTypeOptions?.filter((r) => r?.value === modalData?.userTypeId)
      );
    }
  }, [openModal]);

  const getRegions = (payload:any) => {
    getAllRegions(payload).then((res) =>
      setRegion(generateOptions(res, "id", "name")));
  };

  const onBtNext = () => {
    setSkip(skip + 30);
  };

  const onBtPrevious = () => {
    setSkip(skip - 30);
  };

  const PaginationIconProps = {
    fontSize: "small",
    color: "action",
  };

  const onUpdate = () => {
    const {regions, ...payload} = modalData;
    updateFarmUser(payload).then((res) => {
      if (res.message === "Success") {
        setToastMessage("Data updated");
        setToastSeverity("success");
        setOpenToast(true);
        setOpenModal(false);
      } else {
        setToastMessage("Some Error Ocuured");
        setToastSeverity("error");
        setOpenToast(true);
        setOpenModal(false);
      }
    });
  };

  const userTypeOptions = [
    { label: "Region head", value: "regionHead" },
    { label: "Sales", value: "sales" },
    { label: "Maintenance", value: "maintenance" },
    { label: "Agronomist", value: "agronomist" },
    { label: "Installation", value: "installation" },
  ];

  return (
    <Grid my={2} mx={"auto"}>
      <div style={gridStyle} className="ag-theme-alpine">
        <Grid p={3}>
          <Typography m={1} variant="h5">
            Farm Users
          </Typography>
          <AgGridReact
            ref={gridRef}
            rowData={farmUsersData}
            columnDefs={farmUsersColDef}
            defaultColDef={defaultColDef}
            sideBar={sideBar}
            animateRows={true}
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            domLayout="autoHeight"
            onPaginationChanged={onPaginationChanged}
            onSortChanged={handleColumnSort}
            onFilterChanged={handleColumnFilter}
          ></AgGridReact>
          <Grid
            container
            py={2}
            justifyContent={"flex-end"}
            alignItems={"center"}
            border={1}
            borderColor={"silver"}
            bottom={0}
            left={0}
            right={0}
          >
            <Button onClick={onBtPrevious} disabled={skip === 0}>
              <KeyboardArrowLeftTwoTone {...(PaginationIconProps as any)} />
            </Button>

            <Typography>
              Page {Math.round(skip / 30) + 1} of {totalUsers}
            </Typography>

            <Button
              onClick={onBtNext}
              disabled={skip === (totalUsers - 1) * 30}
              id="btNext"
            >
              <KeyboardArrowRightTwoTone {...(PaginationIconProps as any)} />
            </Button>
          </Grid>
        </Grid>
      </div>

      <Modal
        open={openModal}
        style={{ overflow: "scroll" }}
        onClose={() => setOpenModal(false)}
      >
        <Grid
          width={"95%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <Grid
            width="90%"
            my={2}
            mx="auto"
            boxShadow={2}
            borderRadius={4}
            paddingBottom={5}
          >
            <Grid
              p={2}
              borderRadius={25}
              style={{ backgroundColor: "#f5f5f5" }}
            >
              <Grid container alignItems="center">
                <IconButton onClick={() => setOpenModal(false)}>
                  <ArrowBack />
                </IconButton>
                <Typography variant="h5" style={{ marginLeft: 10 }}>
                  User
                </Typography>
              </Grid>
            </Grid>
            <div
              style={{ justifyContent: "center", padding: 20, marginTop: -12 }}
            >
              <form>
                <h3 style={{ margin: 5 }}>Name</h3>
                <input
                  name="name"
                  onChange={(e) =>
                    setModalData({ ...modalData, name: e.target.value })
                  }
                  value={modalData?.name}
                  type="text"
                  style={{
                    width: "93%",
                    padding: "20px",
                    fontSize: 18,
                    paddingTop: "15px",
                    margin: "3px",
                    marginBottom: 11,
                    border: "1px solid #ccc",
                    borderRadius: 5,
                  }}
                />
                <br />
                <h3 style={{ margin: 5 }}>Mobile</h3>
                <input
                  name="mobile"
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      mobile: e.target.value,
                    })
                  }
                  value={modalData?.mobile}
                  type="text"
                  style={{
                    width: "93%",
                    padding: "20px",
                    fontSize: 18,
                    paddingTop: "15px",
                    margin: "3px",
                    marginBottom: 11,
                    border: "1px solid #ccc",
                    borderRadius: 5,
                  }}
                />
                <br />
                <h3 style={{ margin: 5 }}>Email</h3>
                <input
                  name="email"
                  onChange={(e) =>
                    setModalData({ ...modalData, email: e.target.value })
                  }
                  value={modalData?.email}
                  type="text"
                  style={{
                    width: "93%",
                    padding: "20px",
                    fontSize: 18,
                    paddingTop: "15px",
                    margin: "3px",
                    marginBottom: 11,
                    border: "1px solid #ccc",
                    borderRadius: 5,
                  }}
                />
                <br />
                <h3 style={{ margin: 5 }}>User Type</h3>
                <Select
                  options={userTypeOptions}
                  onChange={(selected: SingleValue<any>) => {
                    setSelectedUserType(selected);
                    setModalData({
                      ...modalData,
                      userTypeId: !!selected?.value?.length
                        ? selected?.value
                        : "",
                    });
                  }}
                  value={selectedUserType}
                  {...DropDownProps}
                />
                <h3 style={{ margin: 5 }}>Regions</h3>
                <LocationFilter
                  dropdowns={["district", "sub-district", "village"]}
                  hasSubmitButton={true}
                  onSubmit={getRegions}
                  defaultBackgroundColor={"#efefef"}
                  hideTitle
                ></LocationFilter>
                <Select
                  options={region}
                  onChange={(selected: MultiValue<any>) => {
                    setSelectedRegion(selected);
                    setModalData({
                      ...modalData,
                      regionIds: selected?.map((s) => s?.value),
                    });
                  }}
                  isMulti
                  value={selectedRegion}
                  {...DropDownProps}
                />
                <h3 style={{ margin: 5 }}>Receivables</h3>
                <input
                  name="receivables"
                  onChange={(e) =>
                    setModalData({
                      ...modalData,
                      receivables: Number(e.target.value),
                    })
                  }
                  value={modalData?.receivables}
                  type="number"
                  min={0}
                  style={{
                    width: "93%",
                    padding: "20px",
                    fontSize: 18,
                    paddingTop: "15px",
                    margin: "3px",
                    marginBottom: 11,
                    border: "1px solid #ccc",
                    borderRadius: 5,
                  }}
                />
                <br />
                <h3 style={{ margin: 5 }}>Receivables Date</h3>
                <DatePicker
                  showCalendarIcon
                  label=""
                  maxDate={new Date(Infinity)}
                  labelStyle={{ fontWeight: "bold" }}
                  value={moment(modalData?.receivablesDate).toDate()}
                  onChange={(d: any) =>
                    setModalData({ ...modalData, receivablesDate: d })
                  }
                />

                <Grid
                  container
                  justifyContent="space-between"
                  style={{ marginTop: 55 }}
                >
                  <input
                    type="button"
                    value="Update"
                    onClick={onUpdate}
                    style={{
                      width: "42%",
                      padding: "10px",
                      fontSize: 18,
                      backgroundColor: "#4caf50",
                      color: "#fff",
                      border: "none",
                      borderRadius: 5,
                      cursor: "pointer",
                    }}
                  />
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Modal>
      <ToastAlert
        open={openToast}
        setOpen={setOpenToast}
        severity={toastSeverity}
        message={toastMessage}
      />
    </Grid>
  );
};

export default FarmUser;
