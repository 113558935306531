import React, { useEffect, useState } from "react";
import { Filters, Charts } from "./sections";
import { Location } from "src/components/Filters/location.filter";
import {
  VoucherInsight,
  fetchVoucherInsights,
} from "src/services/payment.service";
import dayjs from "dayjs";
import { CircularProgress, Grid } from "@mui/material";
import DataTable from "./component/DataTable";
import { getExpiredPlotsRegionWise } from "src/services/plot.service";

const Voucher: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(
    dayjs().subtract(7, "days").toDate()
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [location, setLocation] = useState<Location>({} as Location);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [vouchers, setVouchers] = useState<VoucherInsight[]>();
  const [allVouchers, setAllVouchers] = useState<VoucherInsight[]>([]);

  const [allExpiredPlots, setAllExpiredPlots] = useState<any[]>([]);
  const [expiredPlots, setExpiredPlots] = useState<any[]>([]);

  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    getExpiredPlotsRegionWise().then((res) => setAllExpiredPlots(res));
  }, []);

  useEffect(() => {
    filterVouchers();
  }, [startDate, endDate]); // eslint-disable-line

  useEffect(() => {
    setVouchers(allVouchers);
  }, [allVouchers]);

  useEffect(() => {
    setExpiredPlots(allExpiredPlots);
  }, [allExpiredPlots]);

  useEffect(() => {
    setTableData(generateTableData(vouchers ?? [], expiredPlots));
  }, [vouchers, expiredPlots]);

  useEffect(() => {
    if (Object.keys(location).length === 0) {
      setVouchers(allVouchers);
      setExpiredPlots(allExpiredPlots);
    } else {
      const filteredVouchers = allVouchers.filter((voucher) => {
        let flag = voucher.state === location.state;
        if (!!location.district) {
          flag = flag && location.district === voucher.district;
        }

        return flag;
      });

      setVouchers(filteredVouchers);

      const filteredExpiredPlots = allExpiredPlots.filter((plot) => {
        let flag = plot.state === location.state;
        if (!!location.district) {
          flag = flag && location.district === plot.district;
        }

        return flag;
      });

      setExpiredPlots(filteredExpiredPlots);
    }
  }, [location]); // eslint-disable-line

  // api calls
  const filterVouchers = () => {
    if (!startDate) return;
    setIsLoading(true);
    fetchVoucherInsights({
      startDate: dayjs(startDate).toISOString(),
      endDate: dayjs(endDate).toISOString(),
    })
      .then((res) => {
        setAllVouchers(res);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // state handlers
  const updateStartDate = (newValue: Date) => {
    setStartDate(newValue);
  };
  const updateEndDate = (newValue: Date) => {
    setEndDate(newValue);
  };

  const updateLocation = (newValue: Location) => {
    setLocation(newValue);
  };

  return (
    <div>
      <Filters
        startDate={startDate}
        endDate={endDate}
        updateStartDate={updateStartDate}
        updateEndDate={updateEndDate}
        updateLocation={updateLocation}
      />

      {isLoading && (
        <Grid container margin={"auto"} justifyContent={"center"}>
          <CircularProgress color="success" size={20} />
        </Grid>
      )}

      {!isLoading && (
        <Grid container>
          <Grid xs={12} margin={"auto"}>
            <Charts data={vouchers} expiredPlots={expiredPlots} />
          </Grid>
          <Grid
            item
            height={"560px"}
            minWidth={"500px"}
            xs={10}
            margin={"20px auto"}
          >
            <DataTable data={tableData} />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Voucher;

/**
 * ================== Helper Functions ======================
 */

const generateTableData = (vouchers: any[], expiredPlots: any[]) => {
  const formattedData: Record<string, any> = {};

  const plotIdSets: Record<string, Set<string>> = {};

  vouchers.forEach((voucher) => {
    const key = voucher.state + "_" + voucher.district;

    if (!formattedData[key]) {
      formattedData[key] = {
        voucher: new Set(),
        expired: new Set(),
        state: voucher.state,
        district: voucher.district,
      };
      plotIdSets[key] = formattedData[key].voucher;
    }

    plotIdSets[key].add(voucher.plotId);
  });

  expiredPlots.forEach((plot) => {
    const key = plot.state + "_" + plot.district;

    if (!formattedData[key]) {
      formattedData[key] = {
        voucher: new Set(),
        expired: new Set(),
        state: plot.state,
        district: plot.district,
      };
      plotIdSets[key] = formattedData[key].expired;
    }

    formattedData[key].expired.add(plot.plotId);
  });

  Object.keys(formattedData).forEach((key) => {
    formattedData[key].voucher = formattedData[key].voucher.size;
    formattedData[key].expired = formattedData[key].expired.size;
  });

  return Object.values(formattedData).sort(
    (a: any, b: any) => b.expired - a.expired
  );
};
