import { TObservationType } from "src/pages/ObservationType";
import { deleteData, getData, postData } from "./common.service";
import { CORE_API } from "src/constants/api";
import { filterInValidValues } from "src/utils/helper";

export const getObservationTypes = () => {
  const filterQuery = {
    order: "id DESC"
  }

  return getData("/observation-types?filter=" + JSON.stringify(filterQuery), {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
};

export const filterObservationTypes = (filters: ObservationTypeFilters, fields: Fields = {id: true}): Promise<Array<Partial<Record<Filter, any>>>> => {
  const payload = filterInValidValues(filters);
  const filterQuery = {
    where: payload,
    fields,
    order: "observationDate DESC"
  }


  return getData('/observation-types?filter=' + JSON.stringify(filterQuery), {
    sendTokenInHeader: true,
    basePath: CORE_API
  });
}

export const getObservationType = (id: string) => {
  return getData("/observation-types/" + id, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
};

export const postObservationType = (payload: TObservationType) => {
  return postData("/observation-types", {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload
  })
}

export const updateObservationType = (payload: TObservationType) => {
  return postData("/observation-types/" + payload.id, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload,
    usePutMethod: true
  })
}

export const deleteObservationType = (id: string) => {
  return deleteData("/observation-types/" + id, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true
  })
}

/**
 * ================== TYPES ======================
 */
export type Filter = "attachments" |
"category" |
"columns" |
"created_by" |
"created_date" |
"cropId" |
"description" |
"diseasePestId" |
"displayName" |
"id" |
"modified_date" |
"name" |
"scale" |
"subColumns" |
"updated_by" |
"updated_date";

export type Fields = Partial<Record<Filter, boolean>>;
export type ObservationTypeFilters = Partial<Record<Filter, string | number | Date>>;
