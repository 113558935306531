import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { KeyValues } from 'src/constants/interfaces';
import { getPlotsByPlotIds, getPlotsWithPagination } from 'src/services/plot.service';
import { useNavigate, useLocation } from 'react-router';
import { isArrayWithLength, isLoggedIn } from 'src/utils/helper';
import { handleFilters } from '../filters'
import { columnFilteredPlotsAtom, filteredPlotsAtom, plotsAtom } from 'src/recoil/atom/plotsAtom';
import { useRecoilState, useRecoilValue } from 'recoil';
import _ from 'lodash';
import { columnFiltersAtom } from 'src/recoil/atom';
import { DisplayProperty } from 'src/pages/PlotDetails';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import imdicon from 'src/assets/deviceIcons/imdicon.png';
import irriIcon from 'src/assets/deviceIcons/irriIcon.png';
import kairoIcon from 'src/assets/deviceIcons/kairoIcon.png';
import mainIcon from 'src/assets/deviceIcons/mainIcon.png';
import neroIcon from 'src/assets/deviceIcons/neroIcon.png';
import weatherStationIcon from 'src/assets/deviceIcons/weatherStationIcon.png';
import sm1Icon from 'src/assets/soil-moisture-1.png';
import sm2Icon from 'src/assets/soil-moisture-2.png';
import humidityIcon from 'src/assets/humidity.png';
import { Thermostat } from '@mui/icons-material';
import InsertChartIcon from '@mui/icons-material/InsertChart';

interface Props {
    plotIds: string[] | undefined;
}

const apiPageSize = 15;
const gridPageSize = 15;



const UserPlotTable: React.FC<Props> = (props: Props): JSX.Element => {

    let { plotIds } = props

    const containerRef = useRef<HTMLDivElement>(null);
    const containerStyle = useMemo(() => ({ width: '100%', height: '792px' }), []);
    const [rowData, setRowData] = useState<KeyValues[]>([]);

    const navigate = useNavigate()
    let { state } = useLocation();

    // loading
    const [isLoading, setIsLoading] = useState<boolean>(false);


    // filters (column filters)
    const [columnFilters, setColumnFilters] = useRecoilState<any>(columnFiltersAtom)

    // recoil
    const [columnFilteredPlots, updateFilteredPlots] = useRecoilState(columnFilteredPlotsAtom)
    const recoilPlots = useRecoilValue(plotsAtom) // for table filters
    const filteredPlots = useRecoilValue(filteredPlotsAtom) // for dashboard filters

    const [currentPage, setCurrentPage] = useState(1);

    let selectedPlotsRef = useRef<any>()
    selectedPlotsRef.current = recoilPlots

    let filteredPlotsRef = useRef<any>()
    filteredPlotsRef.current = filteredPlots

    let columnFilteredPlotsRef = useRef<any>()
    columnFilteredPlotsRef.current = columnFilteredPlots

    let commonFilteredSelectedRef = useRef<any>()

    let plotIdsRef = useRef<any>();
    plotIdsRef.current = plotIds;

    useEffect(() => {
        const filteredPlots = filteredPlotsRef.current || {}
        const selectedPlots = selectedPlotsRef.current.ids || []
        if (filteredPlots.length > 0 && selectedPlots.length > 0) {
            commonFilteredSelectedRef.current = _.intersection(filteredPlots, selectedPlots)
        } else if (filteredPlots.length > 0) {
            commonFilteredSelectedRef.current = filteredPlots
        } else if (selectedPlots.length > 0) {
            commonFilteredSelectedRef.current = selectedPlots
        } else {
            commonFilteredSelectedRef.current = []
        }
        setCurrentPage(1);
    }, [filteredPlots, recoilPlots])

    useEffect(() => {
        if (!_.isEmpty(columnFilteredPlotsRef.current.columnFilters) || (!_.isEmpty(columnFilters.cropSown))) {
            setIsLoading(true);
            if (!!columnFilters.cropSown) {
                columnFilteredPlotsRef.current = {
                    ...columnFilteredPlotsRef.current,
                    columnFilters: {
                        ...columnFilteredPlotsRef.current.columnFilters,
                        cropSown: columnFilters.cropSown,
                    }
                }
            }

            handleFiltersWrapper(0, false)
                .then((res: any[]) => {
                    if (res.length === 0) {
                        setRowData([])
                    }

                    updateFilteredPlots({
                        ...columnFilteredPlotsRef.current,
                        ids: res
                    })
                })
                .catch(err => console.error(err))
                .finally(() => setIsLoading(false));
        } else {
            handleFiltersWrapper(0, false)
                .then((res: any[]) => {

                    if (res.length === 0) {
                        setRowData([])
                    }

                    updateFilteredPlots({
                        ...columnFilteredPlotsRef.current,
                        ids: res
                    })
                })
                .catch(err => console.error(err))
                .finally(() => setIsLoading(false));
        }
    }, [columnFilters])

    // checking for duplicate data, till now no duplicate data
    useEffect(() => {
        const valueArr = rowData.map(function (item) { return item.plotId });
        const isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) !== idx
        });
        if (isDuplicate) {
            console.log("%c Duplicate found", 'background: #222; color: orange');
        }
    }, [rowData])


    useEffect(() => {
        setIsLoading(true);
        initialApiCall();
    }, [plotIds])

    useEffect(() => {
        setIsLoading(true);
        const hasPlotIds = (!!selectedPlotsRef?.current?.text?.length) || (!!columnFilteredPlotsRef?.current?.columnFilters && !_.isEmpty(columnFilteredPlotsRef?.current?.columnFilters));
        const hasFiltered = (filteredPlotsRef.current.text || '').length > 0;
        if (hasPlotIds || hasFiltered) {
            getPlotsByPlotIdsWrapper((currentPage - 1) * gridPageSize, (currentPage - 1) * gridPageSize + apiPageSize)
        }
        else {
            fetchMoreData(currentPage);
        }
    }, [currentPage])

    const initialApiCall = () => {
        const dashboardFilterApplied = filteredPlotsRef.current?.text?.length > 0;
        const hasPlotIds = (!!plotIdsRef.current && isArrayWithLength(plotIdsRef.current))

        if (hasPlotIds || dashboardFilterApplied) {
            getPlotsByPlotIdsWrapper(0, apiPageSize);
        } else {
            fetchMoreData(0);
        }
    }

    const getPlotsByPlotIdsWrapper = (startIndex: number, endIndex: number) => {
        getPlotsByPlotIds((plotIdsRef.current || []).slice(startIndex, endIndex), ['farm', 'farmUser', 'cropSown', 'device', 'latestFieldData'])
            .then(res => {
                if (startIndex === 0) {
                    setRowData(res);
                }
                else setRowData(prev => [...prev, ...res])
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const handleFiltersWrapper = (page: number, prePlotIds = true) => {
        return handleFilters(columnFilteredPlots.columnFilters, Math.max(page, 0) * gridPageSize, apiPageSize, prePlotIds ? plotIdsRef.current || [] : [])
    }

    const fetchMoreData = (page: number) => {
        if (!isLoggedIn() || isLoading) {
            return;
        }
        getPlotsWithPagination('', Math.max(page, 0) * gridPageSize, apiPageSize, ['cropSown', 'device', 'farm', 'farmUser', 'plotQualityPredictions', 'plotQuantityPredictions', 'plotStatus', "plotLocation", "latestFieldData"])
            .then((res: any[]) => {
                setRowData((prev: any) => page === 0 ? [...res] : [...prev, ...res]);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }


    const truncate = (str: string) => {
        return str?.length > 25 ? str.substring(0, 25) + '...' : str;
    }

    const getDeviceIcon = (device: string) => {
        let deviceIcon;
        switch (device) {
            case 'IMD':
                deviceIcon = imdicon;
                break;
            case 'KAIRO_UNIT':
                deviceIcon = kairoIcon
                break;
            case 'WEATHER_UNIT':
                deviceIcon = weatherStationIcon
                break;
            case 'MASTER_UNIT':
                deviceIcon = mainIcon
                break;
            case 'IRRIGATION_UNIT':
                deviceIcon = irriIcon
                break;
            case 'NERO_UNIT':
                deviceIcon = neroIcon
                break;

            default:
                break;
        }
        return deviceIcon;

    }

    return (
        <Box px={2}>
            <div style={containerStyle}>
                <Grid>
                    <Typography variant='h5'>Plots</Typography>
                    <InfiniteScroll
                        dataLength={rowData.length}
                        next={() => setCurrentPage((prevState) => prevState + 1)}
                        hasMore={true}
                        loader={<h4>{isLoading ? 'Loading...' : ''}</h4>}
                    >
                        <div ref={containerRef} style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                            {rowData?.map((plot: any) => {
                                const sm1exists: boolean = plot?.latestFieldData?.soilMoisture1 !== undefined && plot?.latestFieldData?.soilMoisture1 !== null;
                                const sm2exists: boolean = plot?.latestFieldData?.soilMoisture2 !== undefined && plot?.latestFieldData?.soilMoisture2 !== null;
                                const isairTemp: boolean = plot?.latestFieldData?.airTemp !== undefined && plot?.latestFieldData?.airTemp !== null;
                                const isairHumidity: boolean = plot?.latestFieldData?.airHumidity !== undefined && plot?.latestFieldData?.airHumidity !== null;
                                return (
                                    <div key={plot?.plotId} onClick={() => navigate(`plots/${plot?.plotId}`, { replace: true, state })} style={{ cursor: 'pointer', width: '700px', display: 'flex', flexWrap: 'wrap' }}>
                                        <Grid item key={plot?.plotId} p={2} my={2} mx={'auto'} boxShadow={2} borderRadius={4} width={'100%'}>
                                            {plot?.device?.deviceType?.length && (
                                                <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <img
                                                        src={getDeviceIcon(plot?.device?.deviceType)}
                                                        alt={plot?.device?.deviceType}
                                                        style={{ height: 30, width: 40 }}
                                                    />
                                                    <IconButton onClick={(e: any) => {
                                                        e.stopPropagation();
                                                        navigate('/plot-data-historical/' + plot?.plotId)
                                                    }}>
                                                        <InsertChartIcon />
                                                    </IconButton>
                                                </Grid>
                                            )}

                                            <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', width: '100%' }}>
                                                {plot?.name && (
                                                    <DisplayProperty
                                                        property='Plot'
                                                        width={60}
                                                        value={truncate(plot?.name)}
                                                    />
                                                )}
                                                {(plot?.farm?.name || plot?.farm?.id) && (
                                                    <DisplayProperty
                                                        property='Farm'
                                                        width={65}
                                                        value={truncate(plot?.farm?.name || plot?.farm?.id)}
                                                    />
                                                )}
                                                {plot?.farmUser?.name && (

                                                    <DisplayProperty
                                                        property='Farmer'
                                                        width={65}
                                                        value={truncate(plot?.farmUser?.name)}
                                                    />
                                                )}
                                                {plot?.farmUser?.mobile && (

                                                    <DisplayProperty
                                                        property='Mobile'
                                                        width={65}
                                                        value={truncate(plot?.farmUser?.mobile)}
                                                    />
                                                )}
                                                {plot?.cropSown?.name && (
                                                    <DisplayProperty
                                                        property='Crop'
                                                        width={65}
                                                        value={truncate(plot?.cropSown?.name)}
                                                    />
                                                )}
                                            </div>
                                            {(sm1exists || sm2exists || isairTemp || isairHumidity) ? (
                                                <Grid key={plot?.plotId} p={1} borderRadius={2}>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', width: '100%' }}>
                                                        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <div style={{ borderBottom: '1px dotted black', flex: '1', margin: '0 10px' }}></div>
                                                                <span style={{ fontSize: '16px', backgroundColor: '#fff', padding: '0 5px' }}>{dayjs(plot?.latestFieldData?.timestamp).format("LLL")}</span>
                                                                <div style={{ borderBottom: '1px dotted black', flex: '1', margin: '0 10px' }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {(sm1exists || sm2exists) && (
                                                        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row' }}>

                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={sm1Icon}
                                                                    alt='Soil Moisture1'
                                                                    style={{ height: 40, width: 40, marginRight: 1 }}
                                                                />
                                                                <Typography>{sm1exists ? `${Number(plot?.latestFieldData?.soilMoisture1).toFixed(2)} kpa` : '—'}
                                                                </Typography>
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={sm2Icon}
                                                                    alt='Soil Moisture2'
                                                                    style={{ height: 40, width: 40, marginRight: 1 }}
                                                                />
                                                                <Typography>{sm2exists ? `${Number(plot?.latestFieldData?.soilMoisture2).toFixed(2)} kpa` : '—'}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {(isairTemp || isairHumidity) && (
                                                        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexDirection: 'row' }}>

                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <Thermostat style={{ height: 30, width: 30, marginRight: 2 }}
                                                                />
                                                                <Typography>{isairTemp ? `${Number(plot?.latestFieldData?.airTemp).toFixed(2)} ℃` : '—'}</Typography>
                                                            </div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img
                                                                    src={humidityIcon}
                                                                    alt='Air Humidity'
                                                                    style={{ height: 35, width: 35, marginRight: 1 }}
                                                                />
                                                                <Typography>{isairHumidity ? `${Number(plot?.latestFieldData?.airHumidity).toFixed(2)} %` : '—'}</Typography>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Grid>
                                            ) : (
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "40px" }}>
                                                    <Typography fontSize={20} color={'red'}>{plot?.device?.deviceType?.length ? 'Data not received' : 'No Device'}</Typography>
                                                </div>
                                            )}
                                        </Grid>
                                    </div>

                                )
                            })}
                        </div>
                    </InfiniteScroll>
                </Grid>

            </div>
        </Box>
    )
}

export default memo(UserPlotTable)