import React, { useState, useEffect } from "react";
import { Grid, Typography, TextField, Box } from "@mui/material";
import InformationText from "./InformationText"; // Adjust the import path if needed

interface Props {
  data: Record<string, string[]>;
  text: string;
  lastUpdated?: boolean;
}

const ScrollableSearchableCard: React.FC<Props> = ({
  data,
  text,
  lastUpdated,
}: Props): JSX.Element => {
  const [plotIds, setPlotIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredKeys, setFilteredKeys] = useState<string[]>(Object.keys(data));

  useEffect(() => {
    // Flatten the plotIds arrays
    const allPlotIds: string[] = [];
    Object.keys(data).forEach((key) => {
      allPlotIds.push(...data[key]);
    });
    setPlotIds(allPlotIds);
  }, [data]);

  useEffect(() => {
    // Filter keys based on search term
    const keys = Object.keys(data).filter((key) =>
      key.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredKeys(keys);
  }, [searchTerm, data]);

  return (
    <Grid
      boxShadow={3}
      px={2}
      pb={2}
      mb={2}
      borderRadius={3}
      direction="column"
      style={{ height: "400px", overflowY: "auto" }}
      spacing={2}
    >
      <Grid container item alignItems="center" spacing={2}>
        <Grid item>
          <Typography
            letterSpacing={0.8}
            variant="overline"
            textTransform="capitalize"
            fontSize={20}
            color="textPrimary"
          >
            {text}
          </Typography>
        </Grid>
        <Grid item xs>
          <TextField
            label="Search"
            variant="outlined"
            fullWidth
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: 5, 
              },
              "& .MuiInputLabel-root": {
                fontSize: 14,
              },
              "& .MuiInputBase-input": {
                fontSize: 14,
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid item container direction="column" style={{ flex: 1 }}>
        <Box style={{ flexGrow: 1 }}>
          {filteredKeys.map((key) => (
            <InformationText data={data[key]} text={key} key={key} />
          ))}
        </Box>
      </Grid>

      {lastUpdated && (
        <Grid item>
          <Typography fontSize={12} color={"gray"}>
            * Since last 7 days
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ScrollableSearchableCard;
