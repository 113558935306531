import React, { createContext, useState } from "react";

interface Props {
  children: React.ReactNode
}

interface AuthContextData {
  roles?: string[];
  access_token?: string;
}
const AuthContext: any = createContext({});

export const AuthProvider: React.FC<Props> = (props: Props): JSX.Element => {
  const { children } = props;
  const [auth, setAuth] = useState<AuthContextData>();

  const handleSetAuth = (newAuthData: AuthContextData) => {
    setAuth(newAuthData);
  }

  return (
      <AuthContext.Provider value={{ auth: auth, setAuth: handleSetAuth }} >
        {children}
      </AuthContext.Provider>
    )
}

export default AuthContext;