import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { memo, useEffect, useRef, useState } from "react";
import { Collapsibles, getFieldColor } from "../sections/FilterSection";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import dayjs from "dayjs";
import { GraphType } from "src/pages/BusinessDashboard";
import { WeatherIconRanges, WindDirectionanges, fieldLegends } from "src/pages/BusinessDashboard/constant";
import { getDailyRegionData, getHourlyRegionData } from "src/services/region.service";
import { getFuturePredictionData, getHourlyWeatherPrediction } from "src/services/crop.service";
import { WeatherIcon, WindDirectionIconMapping } from "src/constants/WordMapping";
import UnknownIcon from "src/assets/weatherIcons/unknown.png";
import { getPropertyAndType } from "src/utils/helper";

type DateType = 'today' | 'past' | 'future';

type Props = {
  checkboxOptions: any;
  isVisible: boolean;
  closeRestCollapsiblesExcept: (x: Collapsibles) => void;
  handleFilterUpdate: (x: any) => void;
  setIndexData: (x: any) => void;
  setCropData: (x: any) => void;
  setGraphType: (x: GraphType) => void;
  setGraphSubType: (x: string) => void;
  setIsFilterLoading: (x: boolean) => void;
  mapsDate: any
};

const SensorData: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    checkboxOptions,
    isVisible,
    closeRestCollapsiblesExcept,
    handleFilterUpdate,
    setIndexData,
    setCropData,
    setGraphType,
    setGraphSubType,
    setIsFilterLoading,
    mapsDate
  } = props;
  const [selectedSensors, setSelectedSensors] = useState<string>("");

  // default selected airTemp
  useEffect(() => {
    setSelectedSensors('airTemp')
  }, [])

  const todaysDate = new Date();
  const [sensorDate, setSensorDataDate] = useState<Date | null>(
    dayjs(todaysDate).add(-1, "day").toDate()
  );

  const handleSensorsChange = (updatedSensors: string) => {
    setSelectedSensors(updatedSensors);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    label: string,
    index: number,
    i: number
  ) => {
    checkboxOptions.map((checkboxLabel: any, index: number) => {
      checkboxLabel.field.map((value: any) => {
        value.isChecked = false;
      });
      checkboxLabel.isChecked = false;
    });
    if (event.target.checked) {
      handleSensorsChange(label);
      setGraphSubType(checkboxOptions[index].value)
      setGraphType('environment')
      if (i === -1) {
        checkboxOptions[index].isChecked = true;
      } else {
        checkboxOptions[index].field[i].isChecked = true;
      }
    } else {
      handleSensorsChange("");
    }
  };

  const showResults = () => {
    if (selectedSensors.length > 0 && !!sensorDate) {

      const isFuture = dayjs(sensorDate).isAfter(dayjs())
      const formattedRegionDate = dayjs(sensorDate).format('DD/MM/YYYY')
      const todaysFormattedDate = dayjs().format('DD/MM/YYYY')
      const isToday = formattedRegionDate === todaysFormattedDate
      const dateType: DateType = isFuture ? 'future' : (isToday ? 'today' : 'past');

      setIsFilterLoading(true)
      setGraphType('environment')
      handleFilterUpdate(selectedSensors);
      setIndexData([]);
      setGraphSubType(selectedSensors)

      switch(dateType){
        case 'past':
          getDailyRegionData({
            timestamp: dayjs(sensorDate).format('YYYY-MM-DD').toString(),
            fields: [selectedSensors, "regionId"],
          }).then((res) => {
            let filterData = res
              ?.filter((val: any) => val[selectedSensors] && !!val.region)
              .map((val: any) => ({
                ...val,
                color: getFieldColor(val[selectedSensors], selectedSensors),
                value: val[selectedSensors],
                location: {
                  lat: val.region.geoLocation.coordinates[1],
                  lng: val.region.geoLocation.coordinates[0]
                }
              }));
    
            if (res.length > 0) setCropData(filterData);
            else setCropData([]);
            if (fieldLegends[selectedSensors]) {
              setIndexData(fieldLegends[selectedSensors]);
            }
            setIsFilterLoading(false)
          });
          break;

        case 'future': 
          const [sensor, aggregationType] = getPropertyAndType(selectedSensors)

          getFuturePredictionData(
            sensor,
            dayjs(sensorDate).format('YYYY-MM-DD').toString(),
            "fyllo"
          ).then(res => {
            const noMinMax: string[] = ["rainFall", "dayLength", "cloudCover", "weatherIcon"];

            let filterData = res
              .filter((val: any) => val[sensor] && val["location"])
              .map((val: any) => {
                const temp: any = {
                  color: getFieldColor(val[sensor].value, sensor),
                  value: val[sensor].value,
                  location: {
                    lat: val.location.geolocation.coordinates[1],
                    lng: val.location.geolocation.coordinates[0],
                  },
                  plotId: val.location.plotId || "",
                };
            
                if (noMinMax.includes(sensor)) {
                  if (sensor === "dayLength") {
                    temp.value = Math.round(val[sensor].value / 3600);
                  }
                } else {
                  temp.color = getFieldColor(
                    val[sensor][aggregationType].value,
                    sensor
                  );
                  temp.value = val[sensor][aggregationType].value;
                }
            
                if (sensor === "weatherIcon") {
                  temp.name = WeatherIcon[val.value] || "Unknown";
                  temp.icon =
                    WeatherIconRanges.find((range) => range.name === WeatherIcon[val.value])?.icon ||
                    UnknownIcon;
                }
            
                if (sensor === "windDirection") {
                  temp.name = WindDirectionIconMapping[val.value];
                  temp.icon =
                    WindDirectionanges.find((range) => range.name === WindDirectionIconMapping[val.value])
                      ?.icon;
                }
            
                if (!!val.plotId && val.plotId.length > 0) {
                  temp.plotId = val.plotId;
                }
            
                return temp;
              });
            
            if (filterData.length > 0) {
              setCropData(filterData);
              if(fieldLegends[selectedSensors]) {
                setIndexData(fieldLegends[selectedSensors]);
              }
            } else {
              setCropData([]);
            }
            setIsFilterLoading(false)
          })

          break;

        case 'today': 
          const maxTime = dayjs().add(-1, 'hours').minute(30).format('YYYY-MM-DDTHH:mm')
          const TIMEZONE_OFFSET = 5; // timezone offset in hours
          const showHourlyPrediction =  dayjs(sensorDate).tz('Asia/Kolkata').add(TIMEZONE_OFFSET + 1, 'hour').hour() >= dayjs(maxTime).hour()
          if(showHourlyPrediction) {
            const [property, sensorType] = getPropertyAndType(selectedSensors)
            getHourlyWeatherPrediction(dayjs(sensorDate).add(5, 'hours').toString(), property)
              .then(res => {
                const filterData = res
                  .filter((val: any) => !!val[property] && !!val.location.geolocation )
                  .map((val: any) => ({
                    ...val,
                    color: getFieldColor(val[property].value, property),
                    value: val[property].value,
                    location: {
                      lat: val.location.geolocation.coordinates[1],
                      lng: val.location.geolocation.coordinates[0],
                    },
                    plotId: val?.plotId || val?.location?.plotId
                  }))
                
                if (res.length > 0) setCropData(filterData);
                else setCropData([]);
                if (fieldLegends[property]) {
                  setIndexData(fieldLegends[property]);
                }
              })
              .finally(() => {
                setIsFilterLoading(false)
              })
            return;
          }
          getHourlyRegionData({
            timestamp: dayjs(sensorDate).format('YYYY-MM-DDTHH:mm').toString(),
            fields: [selectedSensors, 'regionId', 'timestamp']
          }).then(res => {
            let filterData = res
              ?.filter((val: any) => val[selectedSensors] && !!val.region)
              .map((val: any) => ({
                ...val,
                color: getFieldColor(val[selectedSensors], selectedSensors),
                value: val[selectedSensors],
                location: {
                  lat: val.region.geoLocation.coordinates[1],
                  lng: val.region.geoLocation.coordinates[0]
                }
              }));
    
            if (res.length > 0) setCropData(filterData);
            else setCropData([]);
            if (fieldLegends[selectedSensors]) {
              setIndexData(fieldLegends[selectedSensors]);
            }
            setIsFilterLoading(false)
          })

          break;

        default:
          return;
      }
    }
  };

  useEffect(() => {
    setSensorDataDate(mapsDate)
  }, [mapsDate])

  useEffect(() => {
    if(!!sensorDate && isVisible ){
      showResults()
    }
  }, [sensorDate]);

  return (
    <Grid mb={3} boxShadow={2} borderRadius={3} px={2}>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          letterSpacing={0.8}
          variant="overline"
          textTransform={"capitalize"}
          fontSize={20}
          data-testid="dashboard-environment"
        >
          Environment
        </Typography>
        <IconButton
          onClick={() => closeRestCollapsiblesExcept(Collapsibles.sensor)}
        >
          {isVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Grid>
      {isVisible && (
        <Grid pb={2}>
          <FormGroup>
            {checkboxOptions.map((checkboxLabel: any, index: number) => (
              <Grid
                flexDirection={"row"}
                display={"flex"}
                justifyContent={"space-between"}
                key={`environment-checkbox-${index}`}
              >
                <FormControlLabel
                  style={{ width: 200 }}
                  key={`sensor-${checkboxLabel.value}-${index}`}
                  control={
                    <Checkbox
                      checked={checkboxLabel.isChecked}
                      onChange={(event) =>
                        handleChange(event, checkboxLabel.value, index, -1)
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                    />
                  }
                  label={
                    <Typography fontSize={13}>{checkboxLabel.label}</Typography>
                  }
                />
                {checkboxLabel.field.map((checkboxValue: any, i: number) => (
                  <FormControlLabel
                    key={`sensor-${checkboxValue.value}-${index}-${i}`}
                    control={
                      <Checkbox
                        checked={checkboxValue.isChecked}
                        onChange={(event) =>
                          handleChange(event, checkboxValue.value, index, i)
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                      />
                    }
                    label={
                      <Typography fontSize={13}>
                        {checkboxValue.label}
                      </Typography>
                    }
                  />
                ))}
              </Grid>
            ))}
          </FormGroup>

          <Grid mt={2}>
            <Button
              variant="outlined"
              fullWidth
              style={{ borderRadius: "20px" }}
              size="large"
              color="success"
              onClick={showResults}
            >
              Show Results
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(SensorData);
