import React, { useEffect } from "react";
import { DateRangeFilter, LocationFilter } from "src/components/Filters";
import { Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { compareDates } from "src/utils/helper";

type Props = {
  updateFilterLocation: (args: any) => void;
  updateFilterDate: (args: any) => void;
  filter: () => void;
};

const Filters: React.FC<Props> = (props) => {
  const { updateFilterLocation, updateFilterDate, filter } = props;

  const handleLocation = (payload: Record<string, any>, filterId?: string) => {
    updateFilterLocation(payload);
  };

  const handleDateRange = (payload: Record<string, any>, filterId?: string) => {
    if (compareDates(payload.startDate, payload.endDate) >= 0) {
      updateFilterDate({
        startTime: dayjs(payload.startDate).toISOString(),
        endTime: dayjs(payload.endDate).toISOString(),
      });
    }
  };

  return (
    <Grid container alignItems={"center"} py={2} px={2}>
      <Grid item xs={7}>
        <LocationFilter
          dropdowns={["state", "district", "sub-district", "village"]}
          onChange={handleLocation}
          defaultBackgroundColor="white"
          hideTitle
        />
      </Grid>
      <Grid item xs={4} position={"relative"} top={10.5}>
        <DateRangeFilter
          defaultValue={{
            startDate: dayjs().subtract(10, "days").utc(),
            endDate: dayjs().utc(),
          }}
          onChange={handleDateRange}
          defaultBackgroundColor="white"
          hideTitle
        />
      </Grid>

      <Grid item mt={1}>
        <Button onClick={filter} variant="contained" color="success">
          <Typography textTransform={"capitalize"}>Filter</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default Filters;
