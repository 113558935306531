import { atom } from "recoil";

type GraphData = {
  timestamp: any;
  [key: string]: number;
}

type TGraphDataAtom = {
  data: GraphData[];
  type: 'pest' | 'disease' | 'environment' | 'future';
  plotId: string | string[];
  sensor: string
}

export const GraphDataAtom = atom({
  key: "graphDataAtom",
  default: {} as TGraphDataAtom,
})