import React, { useEffect, useState } from 'react'
import { FilterProps } from '../types'
import { Grid } from '@mui/material';
import Header from '../Header';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { deleteButton, resetButton, submitButton } from '../action-buttons';
import dayjs from 'dayjs';


const Date: React.FC<FilterProps> = (props) => {
  const {
    actionButtonType,
    defaultBackgroundColor,
    defaultValue,
    errorBackgroundColor,
    filterId,
    hasDeleteButton,
    hasResetButton,
    hasSubmitButton,
    hideTitle,
    label,
    maxDate,
    minDate,
    submittedBackgroundColor,
    onChange,
    onDelete,
    onReset,
    onSubmit,
  } = props;

  const [selectedDate, setSelectedDate] = useState<any>(
    defaultValue ? dayjs(defaultValue).utc() : null
  );

  const [isApplied, setIsApplied] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>();

  useEffect(() => {
    if (!!onChange) {
      const payload = {
        date: selectedDate
      }
      onChange(payload, filterId ?? "");
    }
  }, [selectedDate]) // eslint-disable-line

  // action button handlers

  const onFilterSubmit = () => {
    if (!!onSubmit) {
      const payload = {
        date: selectedDate
      }
      onSubmit(payload, filterId ?? "")
    }
  }

  const onFilterReset = () => {
    if (!!onReset) {
      onReset(filterId ?? "");
    }

    // reset isApplied and error states
    setIsApplied(false);
    setHasError("");
  }

  const onFilterDelete = () => {
    setIsApplied(false);
    if (!!onDelete) {
      onDelete(filterId ?? "");
    }
  }

  /**
   * background colors for the filter
   */
  let backgroundColor = "#D6D6D6";
  if (defaultBackgroundColor) {
    backgroundColor = defaultBackgroundColor;
  }

  if (isApplied && submittedBackgroundColor) {
    backgroundColor = submittedBackgroundColor;
  }

  if (isApplied && !!hasError && errorBackgroundColor) {
    backgroundColor = errorBackgroundColor;
  }

  return (
    <Grid>
      {!hideTitle && <Header title={label ?? 'Date'} />}

      <Grid container justifyContent={"space-between"} alignItems={"center"} borderRadius={2} gap={1} style={{ backgroundColor }} >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            defaultValue={defaultValue}
            value={selectedDate}
            onChange={date => setSelectedDate(date)}
            sx={{
              padding: 0,
              margin: 0,
              "& .MuiInputBase-input": { // input element
                fontSize: "0.95rem",
                padding: "10px 10px",
                height: "auto",
                lineHeight: "1.2",
                margin: "0px "
              },
            }}
            minDate={ minDate ? dayjs(minDate).utc() : null}
            maxDate={ maxDate ? dayjs(maxDate).utc() : null}
          />
        </LocalizationProvider>

        {/* action buttons */}
        <Grid item xs={2} mt={1} display={"flex"} justifyContent={"flex-end"} gap={"5px"} >
          {submitButton({ show: hasSubmitButton, onClick: onFilterSubmit, actionButtonType })}
          {resetButton({ show: hasResetButton, onClick: onFilterReset, actionButtonType })}
          {deleteButton({ show: hasDeleteButton, onClick: onFilterDelete, actionButtonType })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Date