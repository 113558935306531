import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  SortChangedEvent,
  FilterChangedEvent,
} from 'ag-grid-community';
import { Box, Grid, Modal, Switch, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { KeyValues } from '../../constants/interfaces';
import { exporterColDef } from '../../constants/columnDefs'
import { getPlots, getPlotsByPlotIds, postQuantityPrediction } from '../../services/plot.service';
import { useNavigate, useLocation } from 'react-router';
import { isArrayWithLength } from '../../utils/helper';
import FormikApp from '../DynamicForm'
import AddNotesModal from './AddNotesModal';

interface Props {
  mapLoaded: boolean,
  plotIds: String[] | undefined;
  setMapLocations: any;
  setMapLoaded: any;
  toggleMap: any;
}

const fields = [
  {
    id: "prediction",
    label: "Quantity Prediction",
    placeholder: "Quantity Prediction",
    type: "text",
    validationType: "",
    value: '',
    validations: [],
  },
  {
    id: "note",
    label: "Notes",
    placeholder: "Notes",
    type: "textarea",
    validationType: "",
    value: '',
    validations: [],
  },
]

const ExporterTable: React.FC<Props> = (props: Props): JSX.Element => {

  let { plotIds, setMapLocations, setMapLoaded, mapLoaded, toggleMap } = props
  const gridRef = useRef<AgGridReact>(null);
  const containerStyle = useMemo(() => ({ width: '100%', height: '780px' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState<KeyValues[]>();
  const [columnDefs, setColumnDefs] = useState<(ColDef | ColGroupDef)[]>(exporterColDef);
  const [openModal, setOpenModal] = useState<boolean>(false); // if true: modal is opened
  const [plotIdClicked, setPlotIdClicked] = useState<string>('')
  const [dataLoad, setdataLoad] = useState<Boolean>(false);
  const navigate = useNavigate()
  const { isLoading, data, isError, error } = useQuery('',
    () => getPlots('', ['cropSown', 'device', 'farm', 'farmUser', 'plotQualityPredictions', 'plotQuantityPredictions', 'plotStatus']),
    {
      cacheTime: 60 * 60 * 1000, // in ms
      staleTime: 60 * 60 * 1000, // in ms
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )

  useEffect(() => {
    let actualPlots: KeyValues[] = [];
    if(isArrayWithLength(data)){
      actualPlots = data?.filter((plot: any) => plot.hasOwnProperty('plotStatus'))
    }

    setRowData(actualPlots)
    setdataLoad(true)
  }, [data])

  const startLoading = () => {
    gridRef?.current?.api?.showLoadingOverlay();
  }

  const stopLoading = () => {
    gridRef?.current?.api?.hideOverlay();
  }

  const autoSizeAll = useCallback((skipHeader: boolean) => {
    // const allColumnIds: string[] = [];
    // gridRef.current!.columnApi.getColumns()!.forEach((column) => {
    //   const colId = column.getId();
    //   if(colId !== 'plot')
    //     allColumnIds.push(column.getId());
    // });
    // gridRef.current!.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  useEffect(() => {
    if (plotIds !== undefined) {
      getPlotsByPlotIds(plotIds!, ['farmUser', 'plotQualityPredictions', 'plotQuantityPredictions'])
        .then(res => {
          setRowData(res)
        })
        .finally(() => {
          stopLoading();
        })
    }
  }, [])

  const mapLocationsHandler = () => {
    // set map
    if (gridRef.current!.api!) {
      setMapLocations([]);
      setMapLoaded(false)
      const renderedNodes = gridRef.current!.api;
      const locationsOfRenderedNodes: KeyValues[] = []
      renderedNodes.forEachNodeAfterFilter((node, ind) => {
        const nodeData = node.data;
        const hasRequiredFields: boolean = nodeData?.location && nodeData?.place && nodeData?.plotId
        if (hasRequiredFields) {
          locationsOfRenderedNodes.push({
            location: nodeData.location,
            place: nodeData.place,
            plotId: nodeData.plotId
          })
        }
      })

      setMapLocations(locationsOfRenderedNodes)
      setMapLoaded(true)
    }
  }

  const gridOptions: GridOptions = {
    columnDefs: columnDefs,
    rowData: rowData,
    rowSelection: 'single',
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      const colId = e.column.getColId();
      if (colId === 'quantity') {
        const hasQualityPrediction = e.data?.plotQuanityPredictions || [];
        if (!isArrayWithLength(hasQualityPrediction)) {
          setOpenModal(true);
          setPlotIdClicked(e.data.plotId)
        }
      } else {
        navigate(`/plots/${e.data.plotId}`, { state: { pathname: '/exporter'} })
      }
    }
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ['filterMenuTab'],
    };
  }, []);

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return {
      toolPanels: ['filters'],
    };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    autoSizeAll(false);
  }, []);

  // function to perform server side sorting
  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId
  }

  // function to perform server side filtering
  const handleColumnFilter = (event: FilterChangedEvent) => {
    if (event.afterFloatingFilter) { }
  }

  const onPaginationChanged = useCallback(() => { }, []);

  const onRowDataUpdated = useCallback(() => {
    mapLocationsHandler();
  }, []);

  const handleModalClose = () => setOpenModal(false);

  const appendNote = (values: any) => {
    let tempRowData: any = rowData;
    if(!tempRowData) tempRowData = [];
    for(let row of tempRowData){
      if(row.plotId === values.plotId){
        if(!row.hasOwnProperty('plotQuantityPredictions')){
          row.plotQuantityPredictions = [ values ]
        } else {
          row.plotQuantityPredictions.push(values);
        }
        break;
      }
    }
    setRowData(tempRowData)
    gridRef?.current?.api?.setRowData(tempRowData);
  }

  const label = { inputProps: { 'aria-label': 'Map Toggle Switch' } };

  return (
    <Box px={2}>
      <AddNotesModal plotId={plotIdClicked} openModal={openModal} handleModalClose = {handleModalClose} appendNote = {appendNote} />

      {/* column visibility and table */}
      <div style={containerStyle}>
        <Grid display={'flex'} justifyContent={'flex-end'} alignItems={'center'} >
          <Typography variant='subtitle2'>Map</Typography>
          <Switch {...label} onChange={toggleMap} />
        </Grid>
        <div style={{ height: '100%', boxSizing: 'border-box' }}>
          {dataLoad ? (
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              animateRows={true}
              pagination={true}
              paginationPageSize={15}
              gridOptions={gridOptions}
              // domLayout="autoHeight"
              onRowDataUpdated={onRowDataUpdated}
              onPaginationChanged={onPaginationChanged}
              onSortChanged={handleColumnSort}
              onFilterChanged={handleColumnFilter}
              // immutableData={true}
            ></AgGridReact>
          </div>
          ):(
            <Typography>Loading Data...</Typography>
          )}
        </div>
      </div>
    </Box>
  )
}

export default ExporterTable