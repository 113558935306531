import { CORE_API } from "src/constants/api";
import { isArrayWithLength } from "../utils/helper";
import { getData, postData } from "./common.service";

interface Query {
  include?: any[];
  where?: any;
  fields?: string[];
}

export async function getFarmAnalytics(payload: any, query: Query = {}) {
  const { where, include, fields } = query;

  let qs: any = {
    where: {
      or: [],
    },
  };

  if (payload.fromDate) {
    // can be used for further filtering
    if (isArrayWithLength(where)) {
      qs.where.or = [...where];
    }

    const getPendingActivitiesQuery = {
      and: [
        { suggestedDate: { gt: payload.fromDate } },
        { suggestedDate: { lt: payload.toDate } },
        { or: [{ done: { $exists: false } }, { done: false }] },
      ],
    };

    const getCompletedActivitesQuery = {
      and: [
        { doneDate: { gt: payload.fromDate } },
        { doneDate: { lt: payload.toDate } },
        { done: true },
      ],
    };

    qs.where.or = [getPendingActivitiesQuery, getCompletedActivitesQuery];
  }

  if (include) {
    qs.include = include;
  }

  if (fields) {
    qs.fields = fields;
  }

  const url = "/farm-activities" + "?filter=" + JSON.stringify(qs);
  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getConversations(where: any[] = []) {
  let qs: any = {
    limit: 5,
    skip: 0,
    order: "created_date DESC",
    where: {
      and: [
        { farmUserId: where[3] },
        // { "plotId": where[2] },
        { autoGenerated: false },
      ],
    },
  };
  const url = "/interactions" + "?filter=" + JSON.stringify(qs);
  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function fetchMaintenanceTypes() {
  const url = "/maintenances/config";
  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getVideos() {
  return getData(`/video-guides`, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getAllConsultants() {
  return getData(`/region-crop-subscriptions`, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getAllNotifications(payload: any) {
  let qs: any = {
    where: {
      date: {},
    },
    fields: {
      notifTypeId: true,
      plotId: true,
    },
  };
  qs.where.date = { gte: payload.date };
  const url = "/app-notifs" + "?filter=" + JSON.stringify(qs);
  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getSearchList(val: string, role: string | undefined) {
  let qs: any = {
    where: {
      or: [
        { farmUserId: { like: `.*${val}.*`, options: "i" } },
        { name: { like: `.*${val}.*`, options: "i" } },
      ],
    },
    limit: 20,
    skip: 0,
    fields: { farmUserId: true, name: true },
  };

  const url = "/farm-users?filter=" + JSON.stringify(qs) + (!!role ? `&role=${role}` : '');
  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getFarmIds(val: string) {
  const url = `/farms/farmuserid/${val}`;
  return getData(url, {
    sendTokenInUrl: true,
  });
}

export async function fetchSensorsTypes() {
  const url = `/devices/getSensors/WEATHER_UNIT`;
  return getData(url, {
    sendTokenInUrl: true,
  });
}

export async function getPlotIds(val: string) {
  const url = `/farms/${val}/plots`;
  return getData(url, {
    sendTokenInUrl: true,
  });
}

export async function getFarmUserIds() {
  return getData("/farmusers/farmuserids", {
    sendTokenInUrl: true,
  });
}

export async function getFarms(farmId: string, name: string) {
  const qs: any = {
    where: {
      and: [
        { farmId: { like: `.*${farmId}.*`, options: "i" } },
        { name: { like: `.*${name}.*`, options: "i" } },
      ],
    },
    include: ["plots"],
    order: "created_date DESC",
  };

  return getData("/farms?filter=" + JSON.stringify(qs), {
    sendTokenInUrl: true,
  });
}

export async function getFarm(farmId: string) {
  return getData(`/farms/${farmId}`, {
    sendTokenInUrl: true,
  });
}

export async function getFarmIdsOfUser(farmUserId: string) {
  return getData(`/farms/farmuserid/${farmUserId}`, {
    sendTokenInUrl: true,
  });
}

export async function getPlotIdsOfFarm(farmId: string) {
  return getData(`/farms/${farmId}/plots`, {
    sendTokenInUrl: true,
  });
}

export async function getFertilizers() {
  return getData(`/fertilizers`, {
    sendTokenInUrl: true,
  });
}

export async function registerFarm(farmData: any) {
  return postData("/farms/register", {
    sendTokenInUrl: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: farmData,
  });
}
