import { fieldLegends } from "src/pages/BusinessDashboard/constant"
import { removeUnderScores } from "src/utils/helper"
import { Typography, Grid } from "@mui/material"
import { FC } from "react"
import { Link } from "react-router-dom"

type Marker = {
	deviceType: string;
}

interface Props {
	label: string;
	value: number | string;
	isLink?: boolean;
	plotId?: string;
	filter?: string;
	marker?: Marker
}

const PrintInformation: FC<Props> = ({ label, value, isLink = false, plotId, filter = '', marker }) => {
	if (!!filter && filter !== 'crop') {
		label = filter
	}

	let unit = ''
	if (label === 'Area') {
		unit = 'Acres'
	}
	if (fieldLegends[filter]) {
		unit = fieldLegends[filter][0]?.unit || ''
		if (!!fieldLegends[label]) {
			label = fieldLegends[label][0].label || 'Value'
		}
	}

	if (label === 'Value') {
		label = 'Probability'
		unit = '%'
	}

	let isNotCropWithProbability = (!!marker && !!marker.deviceType)

	return (
		<Grid container alignItems="center" mb={1} display={isNotCropWithProbability ? 'none' : 'flex'} flexDirection="row">
			<Typography variant="caption" fontWeight={500} color="white" mr={0.5} fontSize={11} width={70} display="inline-block">
				{label}:
			</Typography>
			<Typography variant="caption" style={{ flex: 1 }}>
				{isLink ? (
					<Link to={`/plots/${plotId}`} target="_blank" rel="noopener noreferrer" style={{ color: 'white' }}>
						{removeUnderScores(value, 'capitalize')}
					</Link>
				) : (
					<span>{removeUnderScores(value, 'capitalize') + ' ' + unit}</span>
				)}
			</Typography>
		</Grid>

	)
}

export default PrintInformation;