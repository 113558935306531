import {
  Typography,
  Grid, IconButton,
  CircularProgress,
  Skeleton,
  Tabs,
  Tab
} from "@mui/material";
import { useState, useEffect } from "react";
import { NDVI_IMAGES_LIMIT, getNdviImageBatch } from "src/services/plot.service";
import CloseIcon from "@mui/icons-material/Close";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

type Props = {
  isOpen: any;
  setIsOpen: (value: boolean) => void;
  plotId: any;
  crop: any;
  farmname: any;
};

const Loader = () => (
  <Grid
    top={0}
    left={0}
    right={0}
    bottom={-50}
    bgcolor={"transparent"}
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <CircularProgress color="success" />
  </Grid>
);

const GetNdviImageModal: React.FC<Props> = (props) => {
  const { isOpen, setIsOpen, plotId, crop, farmname } = props;
  const [isLoading, setisLoading] = useState<Boolean>(false);
  const [images, setImages] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [skip, setSkip] = useState<number>(0);
  const [value, setValue] = useState(0);

  useEffect(() => {
    setSkip(0);
    setImages(null);
    setErrorMessage(null);
    showImage();
  }, [isOpen, plotId]);

  useEffect(() => {
    setImages(null);
    setErrorMessage(null);
    showImage();
  }, [skip]);

  const showImage = async () => {
    setisLoading(true);
    setErrorMessage(null);
    try {
      const images = await getNdviImageBatch(plotId, skip);
      if (images.length > 0) {
        setImages(images.reverse());
      } else {
        setImages(null);
        setErrorMessage("No images found");
      }
    } catch (err) {
      console.error(err);
      setImages(null);
      setErrorMessage("Some error occurred");
    } finally {
      setisLoading(false);
    }
  };

  return (
    <Grid
      p={3}
      height={!!images ? "55%" : "25%"}
      width={"100%"}
      bgcolor={"rgba(255, 255, 255, 0.95)"}
      mx={"auto"}
      alignSelf={"center"}
      borderRadius={5}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      id={"GetNdviImageModal"}
      boxShadow={2}
      mt={2}
      position={'relative'}
    >
      <Grid
        display={"flex"}
        flexDirection={"row"}
        width={"55%"}
        alignSelf={"flex-end"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Tabs
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered={false}
          style={{ marginRight: 'auto' }}
        >
          <Tab label="NDVI"/>
          <Tab label="LAI" />
        </Tabs>

        <IconButton onClick={() => setIsOpen(false)} style={{ marginTop: -45 }}>
          <CloseIcon />
        </IconButton>
      </Grid>

      {isLoading && <Grid position={'absolute'} top={0} bottom={0} left={0} right={0} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Loader />
      </Grid>}

      {errorMessage && (
        <Typography variant="body1" color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
      
      <Grid display={'flex'} width={'100%'} justifyContent={'center'} alignItems={'center'}>
        <IconButton disabled={!!isLoading} onClick={() => setSkip(skip + NDVI_IMAGES_LIMIT)}>
          <ArrowLeftIcon fontSize='large' />
        </IconButton>
        <Grid display={'flex'}>
          {images?.length > 0 ? (
            images?.map((image: any) => (
              <Grid
                key={image.id}
                mx={3}
                mt={3}
                borderRadius={2}
                style={{
                  boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.25)',
                  border: '1px solid rgba(0, 0, 0, 0.25)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '15px 5px',
                }}
              >
                <img
                  src={value===0?`data:image/png;base64,${image?.ndviImage}`:`data:image/png;base64,${image?.laiImage}`}
                  alt="fyllo"
                  style={{
                    width: '90%',
                    height: 'auto',
                    borderRadius: '10px',
                    marginBottom: '10px'
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    textAlign: "left",
                    fontSize: "13px",
                    lineHeight: "1.5",
                    padding: '10px',
                    width: '90%',
                  }}
                >
                  <div style={{ marginBottom: "8px" }}>
                    <span style={{ fontWeight: 'bold' }}>
                      Farm Name:
                    </span> 
                    {farmname}
                  </div>
                  <div style={{ marginBottom: "8px" }}><span style={{ fontWeight: 'bold' }}>Crop:</span> {crop}</div>
                  <div style={{ marginBottom: "8px" }}><span style={{ fontWeight: 'bold' }}>Date:</span> {image?.date}</div>
                  <div style={{ marginBottom: "8px" }}><span style={{ fontWeight: 'bold' }}>
                    Time:{" "}</span>
                    {new Date(`1970-01-01T${image?.time}`).toLocaleTimeString([], {
                      hour12: false,
                      hour: "2-digit",
                      minute: "2-digit"
                    })}
                  </div>
                  <div style={{ marginBottom: "8px" }}>
                    <span style={{ fontWeight: 'bolder' }}>Cloud Coverage:</span> {image?.cloudCoverPercentage}%
                  </div>
                </div>
              </Grid>
            ))
          ) : (
            <>
              {[...Array(NDVI_IMAGES_LIMIT)].map((_, index) => (
                <Grid
                  key={index}
                  mx={3}
                  mt={3}
                  borderRadius={2}
                  style={{
                    boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.25)',
                    backgroundColor: '#fcfcfc',
                    border: '1px solid rgba(0, 0, 0, 0.25)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '10px'
                  }}
                >
                  <Skeleton variant='rectangular' width={190} height={140} />
                  <br />
                  <Skeleton variant='rectangular' width={190} height={160} />
                </Grid>
              ))}
            </>
          )}
        </Grid>

        <IconButton disabled={skip === 0 || !!isLoading} onClick={() => setSkip(skip - 3)}>
          <ArrowRightIcon fontSize='large' />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default GetNdviImageModal;
