import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

interface AuthContextInterface {
  auth: any;
  setAuth: Function;
}

const useAuth = (): any => {
  return useContext(AuthContext) as any;
}

export default useAuth;