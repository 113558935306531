import { Grid, Typography } from '@mui/material';
import { ChartData } from 'chart.js';
import _ from 'lodash';
import { FC, memo, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Skeleton } from 'src/components';
import { fetchPopularActivities } from 'src/services/userEvent.service';
import { TPopularActivities, TPopularActivitiesData, TTimeRange } from '../types';

type Props = TTimeRange & {
  handleChartClick: (activityId: string) => void;
};

const PopularActivities: FC<Props> = (props) => {
  const { startTime, endTime, handleChartClick } = props;

  const [popularActivities, setPopularActivities] = useState<TPopularActivitiesData>({
    loading: true,
    data: {}
  });

  const [chartData, setChartData] = useState<ChartData<"doughnut", number[], unknown>>();

  /**
   * @function
   * called on time range updates
   * calls getPopularActivities 
   * */
  useEffect(() => {
    if (!!startTime && !!endTime) {
      getPopularActivities();
    }
  }, [startTime, endTime])

  /**
   * @function
   * called on popularActivities update
   * generates graph data for popular activities
   * */
  useEffect(() => {
    const { loading, data } = popularActivities;
    if (!loading && !_.isEmpty(data)) {
      const cData = Object.values(data).map((activityCount) => activityCount);

      setChartData({
        labels: Object.keys(data),
        datasets: [
          {
            label: 'Activities Count',
            data: cData
          }
        ]
      })
    }
  }, [popularActivities])

  /**
   * @function
   * fetches popular activities for selected time range
   * updates popularActivities state
   * */
  const getPopularActivities = async () => {
    let data: TPopularActivities = {};
    setPopularActivities((popularActivities) => ({ ...popularActivities, loading: true }));
    try {

      /**
       * TODO: count variable leke chal rahe for top 5, iska kuch karna padega
       * */
      const apiPopularActivities: any = await fetchPopularActivities(startTime, endTime);
      let cnt = 0;
      for (const activity of apiPopularActivities) {
        if (cnt === 5) break;
        data[activity._id] = activity.count;
        cnt++;
      }
    } catch (err) {
      console.log('inside catch')
      data = {};
    } finally {
      setPopularActivities({
        loading: false,
        data: data
      })
    }
  }

  /**
   * Chart options
   * handles the click event on chart
   * */
  const DoughnutChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: true,
      }
    },
    onClick: (_: any, element: any) => {
      const { index } = element[0];
      const clickedActivity = chartData!.labels![index] as string;
      handleChartClick(clickedActivity);
    }
  }

  return (
    <Grid width={'80%'} mx={'auto'}>
      {
        !!chartData ?
          <Doughnut
            data={chartData!}
            options={DoughnutChartOptions}
          />
          :
          <Grid position={'relative'} width={340} height={340}>
            <Skeleton.PieChart width={170} height={170} />
            {
              !popularActivities.loading &&
              <Grid position={'absolute'} top={0} left={0} right={0} bottom={0} bgcolor={'rgba(255, 255, 255, 0.3)'} display={'flex'} justifyContent={'center'} alignItems={'center'} borderRadius={'50%'}>
                  <Typography color={'red'} variant='subtitle1' fontWeight={500} fontSize={20}>Something went wrong!</Typography>
                </Grid>
            }
          </Grid>
      }
    </Grid>
  )
}

export default memo(PopularActivities)