import { getData, postData, deleteData } from "./common.service";
import { createFitlerPlotIdIn, isArrayWithLength } from "../utils/helper";
import { APP_API } from "../constants/api";
import { SensorsData } from "../components/Filters/sensors.filter";
import { TGetRegionsPayload as Location } from "./region.service";

const CORE_API = process.env.REACT_APP_CORE_API;
const LOCATION_API = process.env.REACT_APP_LOCATION_API;
const API_BASEPATH = process.env.REACT_APP_API_BASEPATH;

export const NDVI_IMAGES_LIMIT = 4;

export async function getLatestDataForMap(plotId: string) {
  const url = "/plots/getLatestDataForMap?plotId=" + plotId;

  return getData(url, {
    basePath: API_BASEPATH,
    sendTokenInUrl: true,
  });
}

export async function getPlotInfo(plotId: any) {
  const url = `/plots/${plotId}/info`;

  return getData(url, {
    basePath: CORE_API,
  });
}

export async function ReAnalzeActivity(id: any) {
  const url = `/farm-activities/${id}/analyze`;

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function deleteNutrient(id: any) {
  const url = `/nutrient-configs/${id}`;

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true,
  });
}

export async function updateNutrient(payload: any) {
  const { id } = payload;
  const url = `/nutrient-configs/${id}`;

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    usePutMethod: true,
    body: payload,
  });
}

export async function updateReachOut(payload: any) {
  const { farmUserId } = payload;
  const url = `/farm-users/${farmUserId}`;

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    usePatchMethod: true,
    body: payload,
  });
}

export async function postNutrient(payload: any) {
  const { cropId } = payload;
  const url = `/crops/${cropId}/nutrient-configs`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload,
  });
}

export async function getNutrient(id: any) {
  const url = `/crops/${id}/nutrient-configs`;

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getAllFollowUps() {
  const moment = require("moment");
  const today = moment().toISOString();

  let qs: any = {
    offset: 0,
    limit: 100,
    skip: 0,
    order: "followupDate ASC",
    where: {
      followupDate: { gte: today },
    },
  };
  const url = "/interactions" + "?filter=" + JSON.stringify(qs);
  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getAllMaintenanceRecords() {
  let qs: any = {
    offset: 0,
    // "limit": 100,
    skip: 0,
    order: "created_date DESC",
  };
  const url = "/maintenances" + "?filter=" + JSON.stringify(qs);

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getNutrientPetioleStage(id: any) {
  const url = `/crops/${id}/petiole-analysis-stages`;

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getNutrientFertigationSchedule(id: any) {
  const url = `/crops/${id}/fertigation-schedules`;

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function postFertigationConfig(payload: any) {
  const { cropId } = payload;
  const url = `/crops/${cropId}/fertigation-schedules`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload,
  });
}

export async function deleteFertigationConfig(id: any) {
  const url = `/fertigation-schedules/${id}`;

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true,
  });
}

export async function updateFertigationConfig(payload: any) {
  const { id } = payload;
  const url = `/fertigation-schedules/${id}`;

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    usePutMethod: true,
    body: payload,
  });
}

export async function postFoliarConfig(payload: any) {
  const { cropId } = payload;
  const url = `/crops/${cropId}/foliar-schedules`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload,
  });
}

export async function deleteFoliarConfig(id: any) {
  const url = `/foliar-schedules/${id}`;

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true,
  });
}

export async function updateFoliarConfig(payload: any) {
  const { id } = payload;
  const url = `/foliar-schedules/${id}`;

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    usePutMethod: true,
    body: payload,
  });
}

export async function postsoilApplicationConfig(payload: any) {
  const { cropId } = payload;
  const url = `/crops/${cropId}/soil-application-schedules`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload,
  });
}

export async function deletesoilApplicationConfig(id: any) {
  const url = `/soil-application-schedules/${id}`;

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true,
  });
}

export async function updatesoilApplicationConfig(payload: any) {
  const { id } = payload;
  const url = `/soil-application-schedules/${id}`;

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    usePutMethod: true,
    body: payload,
  });
}

export async function postpetioleAnalysisConfig(payload: any) {
  const { cropId } = payload;
  const url = `/crops/${cropId}/petiole-analysis-stages`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload,
  });
}

export async function deletepetioleAnalysisConfig(payload: any) {
  const { cropId, id } = payload;
  const url = `/crops/${cropId}/petiole-analysis-stages?where=` + JSON.stringify({
    id: id
  });

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true,
  });
}

export async function updatepetioleAnalysisConfig(payload: any) {
  const { cropId, id } = payload;
  const url = `/crops/${cropId}/petiole-analysis-stages?where=` + JSON.stringify({
      id: id
  });

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    usePatchMethod: true,
    body: payload,
  });
}

export async function getNutrientFoliarSchedule(id: any) {
  const url = `/crops/${id}/foliar-schedules`;

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getNutrientSoilSchedule(id: any) {
  const url = `/crops/${id}/soil-application-schedules`;

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function deleteFertilizer(id: any) {
  const url = `/fertilizers/${id}`;

  return deleteData(url, {
    sendTokenInUrl: true,
    useDeleteMethod: true,
  });
}

export async function updateFertilizer(payload: any) {
  const {
    Id,
    name,
    description,
    unit,
    content,
    soilApplication,
    fertigation,
    foliar,
    group,
    aliasArr,
    type,
    applicableTo
  } = payload;
  const url = `/fertilizers/${Id}`;

  return postData(url, {
    sendTokenInUrl: true,
    usePutMethod: true,
    body: {
      id: Id,
      name: name,
      description: description,
      unit: unit,
      content: content,
      usedInSoilApplication: soilApplication,
      usedInFertigation: fertigation,
      usedInFoliation: foliar,
      group: group,
      alias: aliasArr,
      type: type,
      applicableTo
    },
  });
}

export async function postFertilizer(payload: any) {
  const {
    Id,
    name,
    description,
    unit,
    content,
    soilApplication,
    fertigation,
    foliar,
    group,
    aliasArr,
    type,
    applicableTo
  } = payload;
  const url = `/fertilizers`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: {
      id: Id,
      name: name,
      description: description,
      unit: unit,
      content: content,
      usedInSoilApplication: soilApplication,
      usedInFertigation: fertigation,
      usedInFoliation: foliar,
      group: group,
      alias: aliasArr,
      type: type,
      applicableTo
    },
  });
}

export async function postMaintenance(payload: any) {
  const url = `/maintenances`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload,
  });
}

export async function updateMaintenance(payload: any) {
  const { id } = payload;
  const url = `/maintenances/${id}`;

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    usePatchMethod: true,
    body: payload,
  });
}

export async function getFertilizerDetails(id: any) {
  return getData(`/fertilizers/${id}`, {
    sendTokenInUrl: true,
  });
}

export async function getAnalysisFilterResult(data: any, district: string[]) {
  const url = "/plot/insights";
  return postData(url, {
    basePath: LOCATION_API,
    sendTokenInUrl: true,
    body: {
      from: data.fromDate,
      to: data.toDate,
      location: {
        district: district,
      },
      fields: data.field,
    },
  });
}

export async function getSensorsFilterResult(payload: SensorsData) {
  const url = "/plots/search-by-data";

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    body: payload,
  });
}

export async function getOutlierPlots(hours: string) {
  const url = "/get-anomalous-plots?sinceHours=" + hours;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
}

export async function getInactivePlots(hours: string) {
  const url = "/inactive-devices?sinceHours=" + hours;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
}

export async function getTagPlots(tag: string | null, servicedBy: string | null) {
  let filter: any = {};
  if (tag) {

    filter.tags = { like: `.*${tag}.*`, options: "i" }
  }
  if (servicedBy) {
    filter.servicedBy = { like: `.*${servicedBy}.*`, options: "i" };
  }

  const url = "/plots?filter=" + JSON.stringify({ where: filter });

  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
}

export async function downladAllFarmDataBetweenDates(payload: any) {
  const url = "/hourlyAverageFieldData/getAllFarmDataBetweenDatesDownload";
  return postData(url, {
    sendTokenInUrl: true,
    body: payload,
  });
}

export async function getHistoricalDiseasePrediction(
  plotId: string,
  startTime: string | Date,
  endTime: string | Date
) {
  const url = `/plots/${plotId}/historical-disease-predictions?startTime=${startTime}&endTime=${endTime}`;
  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getWindDirectionPrediction(
  plotId: string,
  startTime: string | Date,
  endTime: string | Date
) {
  const url = `/plots/${plotId}/wind-directions?startTime=${startTime}&endTime=${endTime}`;
  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getHistoricalPestPrediction(
  plotId: string,
  startTime: string | Date,
  endTime: string | Date
) {
  const url = `/plots/${plotId}/historical-pest-predictions?startTime=${startTime}&endTime=${endTime}`;
  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getPlotsList() {
  const url = "/plots/getPlotList";
  return postData(url, {
    sendTokenInUrl: true,
  });
}

export async function getLatestFieldData(plotId: string) {
  const url = "/FinalFieldData/getLatestFieldData";
  const body = { plotId: plotId };
  return postData(url, {
    sendTokenInUrl: true,
    body: body,
  });
}

export async function getDailyReport(plotId: string) {
  const url = "/dailyReports/getDailyReport";
  const body = { plotId: plotId };
  return postData(url, {
    sendTokenInUrl: true,
    body: body,
  });
}

export async function getPlotsByPlotIds(
  plotIds: String[],
  include = ["farm", "farmUser"]
) {
  const apiFilter = createFitlerPlotIdIn(plotIds, include);

  return postData("/plots/filter", {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: apiFilter,
  });
}

export async function getPlotsCount() {

  return postData("/plots/filter?" + JSON.stringify({
    "fields": { "plotId": true }
  }), {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
}

export async function downloadPlotDetails(
  plotIds: string[],
  include = ["farm", "farmUser"]
) {
  const qs: any = {
    offset: 0,
    include,
  };

  const apiFilter: any =
    plotIds.length > 0 ? createFitlerPlotIdIn(plotIds, include) : qs;

  return postData("/plots/filter", {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: apiFilter,
  });
}

export async function getPlotsWithPagination(
  text: string = "",
  offset: number,
  limit: number = 15,
  include = ["farm", "farmUser"],
  whereClause: any[] = []
) {
  const qs: any = {
    offset,
    limit,
    include: include,
    order: "created_date DESC",
    where: {
      and: [
        {
          or: [
            { plotId: { like: `.*${text}.*`, options: "i" } },
            { farmId: { like: `.*${text}.*`, options: "i" } },
            { name: { like: `.*${text}.*`, options: "i" } },
            { place: { like: `.*${text}.*`, options: "i" } },
            { farmUserId: { like: `.*${text}.*`, options: "i" } },
          ],
        },
      ],
    },
  };

  if (isArrayWithLength(whereClause)) {
    qs.where = { and: whereClause };
  }

  return postData("/plots/filter", {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: qs,
  });
}

export async function getPlots(
  text: string = "",
  include = ["farm", "farmUser"],
  baseAPI = false
) {
  const qs = {
    where: {
      and: [
        {
          or: [
            { plotId: { like: `.*${text}.*`, options: "i" } },
            { farmId: { like: `.*${text}.*`, options: "i" } },
            { name: { like: `.*${text}.*`, options: "i" } },
            { place: { like: `.*${text}.*`, options: "i" } },
            { farmUserId: { like: `.*${text}.*`, options: "i" } },
          ],
        },
      ],
    },
    include: include,
    order: "created_date DESC",
  };

  if (baseAPI) {
    return getData("/plots?filter=" + JSON.stringify(qs), {
      sendTokenInUrl: true,
      basePath: APP_API,
    });
  }

  return postData("/plots/filter", {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: qs,
  });
}

export async function getPlotsCustom(where: any) {
  return getData("/plots?filter=" + JSON.stringify(where), {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
}

export async function getPlot(
  plotId: string,
  include = [
    "device",
    "cropSown",
    "farmUser",
    "plotQuantityPredictions",
    "plotQualityPredictions",
    "farm"
  ]
) {
  const qs = {
    include,
  };

  return getData(`/plots/${plotId}` + "?filter=" + JSON.stringify(qs), {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
}

export async function getPlotTypes() {
  return getData("/plots/plottypes", {
    sendTokenInUrl: true,
  });
}

export async function getAvailableDevices() {
  return getData("/devices/availabledevices", {
    sendTokenInUrl: true,
  });
}

export async function getSupportedCrops() {
  return getData("/crops/supportedcrops", {
    sendTokenInUrl: true,
  });
}

export async function getSupportedRootStocks(cropId: string) {
  return getData(`/crops/${cropId}/root-stocks`, {
    sendTokenInHeader: true,
    basePath: CORE_API
  });
}

export async function getSoilChars() {
  return getData("/SoilChars", {
    sendTokenInUrl: true,
  });
}

export async function getDeviceTypes() {
  return getData("/devices/devicetypes", {
    sendTokenInUrl: true,
  });
}

export async function getSensors(deviceType: string) {
  return getData(`/devices/getSensors/${deviceType}`, {
    sendTokenInUrl: true,
  });
}

export async function getPlaces() {
  return getData("/farms/places", {
    sendTokenInUrl: true,
  });
}

export async function getFarmsAtPlace(place: string) {
  return getData(`/farms/places/${place}`, {
    sendTokenInUrl: true,
  });
}

export async function getMasterPlots(farmId: string) {
  return getData(`/plots/${farmId}/masterplots`, {
    sendTokenInUrl: true,
  });
}

export async function registerPlot(plotData: any) {
  return postData("/plots/register", {
    sendTokenInUrl: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: plotData,
  });
}

export async function updatePlot(plotData: any) {
  return postData("/plots/updatePlot", {
    sendTokenInUrl: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: plotData,
  });
}

export async function postQuantityPrediction(payload: any) {
  const { plotId, value, note } = payload;
  const url = `/plots/${plotId}/plot-quantity-predictions`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: { value, note },
  });
}

export async function getQuantityPrediction(payload: any) {
  const { plotId } = payload;
  const url = `/plots/${plotId}/plot-quantity-predictions`;

  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
}

export async function getQualityPrediction(payload: any) {
  const { plotId } = payload;
  const url = `/plots/${plotId}/plot-quality-predictions`;

  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
}

export async function updateGeoBoundary(plotId: string, payload: any) {
  const url = `/plots/${plotId}`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload,
    usePatchMethod: true,
  });
}

export async function getNdviImage(plotId: string, date: any) {
  const url = `/plots/${plotId}/ndvi?date=${date}`;

  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
}

export async function getNdviImageBatch(
  plotId: string,
  skip: any,
  todate?: any,
  enddate?: any
) {
  let whereClause: any = {
    plotId: plotId,
  };

  if (todate && enddate) {
    whereClause["date"] = {
      between: [todate, enddate],
    };
  }

  const url =
    `/remote-sensing-data?filter=` +
    JSON.stringify({
      limit: NDVI_IMAGES_LIMIT,
      skip: skip,
      order: "date DESC",
      where: whereClause,
      fields: [
        "satellite",
        "plotId",
        "date",
        "time",
        "cloudCoverPercentage",
        "ndviImage",
        "laiImage",
      ],
    });

  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  });
}

export async function deleteVideo(Id: any) {
  const url = `/video-guides/${Id}`;

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true,
  });
}
export async function updateVideo(payload: any) {
  const {
    id,
    title,
    priority,
    videolink,
    thumbnailurl,
    keywordsArr,
    description,
    language,
    category,
  } = payload;
  const url = `/video-guides/${id}`;

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    usePutMethod: true,
    body: {
      displayName: title,
      link: videolink,
      title: title,
      lang: language,
      thumbnailUrl: thumbnailurl,
      description: description,
      priority: priority,
      date: new Date(),
      keywords: keywordsArr,
      category: category
    },
  });
}
export async function updateConsultants(payload: any) {
  const { Id, mobile, cropValue, allLocation } = payload;
  const url = `/region-crop-subscriptions/${Id}`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    usePatchMethod: true,
    body: {
      id: Id,
      mobile: mobile,
      regionId: allLocation,
      cropId: cropValue,
    },
  });
}

export async function deleteConsultants(Id: any) {
  const url = `/region-crop-subscriptions/${Id}`;

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true,
  });
}
export async function postVideo(payload: any) {
  const {
    title,
    priority,
    videolink,
    thumbnailurl,
    keywordsArr,
    description,
    language,
    category
  } = payload;
  const url = `/video-guides`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: {
      link: videolink,
      title: title,
      lang: language,
      thumbnailUrl: thumbnailurl,
      description: description,
      priority: priority,
      date: new Date(),
      keywords: keywordsArr,
      category: category
    },
  });
}

export async function postConsultant(payload: any) {
  const url = `/region-crop-subscriptions`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload,
  });
}

export async function postConversation(payload: any) {
  let {
    farmUserId,
    supportUserId,
    plotId,
    farmId,
    fyllo,
    farmer,
    date,
    followupDate,
  } = payload;
  const url = `/interactions`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: {
      farmUserId: farmUserId,
      supportUserId: supportUserId,
      // "plotId": plotId,
      // "farmId": farmId,
      lastInteractionDate: date,
      followupDate: followupDate,
      conversations: [
        {
          date: date,
          fyllo: fyllo,
          farmer: farmer,
        },
      ],
    },
  });
}

export async function updateConversations(payload: any) {
  const { id } = payload;
  const url = `/interactions/${id}`

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    usePatchMethod: true,
    body: payload
  })
}

export async function getVideoWithId(id: any) {
  const url = `/video-guides/${id}`;

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getConsultantWithId(id: any) {
  const url = `/region-crop-subscriptions/${id}`;

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getRegionDetailsWithId(id: any) {
  const url = `/regions/${id}`;

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getAcitivitySummary(plotId: any, stageStartDate: any, stageEndDate: any) {
  const url = `/farm-activities/${plotId}/insights?startTime=${stageStartDate}&endTime=${stageEndDate}`;

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getWeatherSummary(plotId: any, stageStartDate: any, stageEndDate: any) {
  const url = `/weather-summary?plotId=${plotId}&startTime=${stageStartDate}&endTime=${stageEndDate}`;

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}

export async function getExpiredPlotsRegionWise() {
  return getData('/expired-plots-district-wise', {
    basePath: CORE_API,
    sendTokenInHeader: true
  })
}

export async function getPlotsWithNoFertigationReport(filter: any = null, location: Location | null = null) {
  let url = '/plots/no-fertigation-report';

  if(!!location) {
    const hasState = !!location.state;
    if(hasState) {
      url += `?state=${location.state}`;
    }

    const hasDistrict = hasState && !!location.district;
    if(hasDistrict) {
      url += `&district=${location.district}`;
    }

    const hasSubDistrict = hasDistrict && !!location.subDistrict;
    if(hasSubDistrict) {
      url += `&subDistrict=${location.subDistrict}`;
    }

    const hasArea = hasSubDistrict && !!location.village;
    if(hasArea) {
      url += `&area=${location.village}`;
    }
  }

  if(!!filter) {
    const filterQuery = `filter=${JSON.stringify(filter)}`;
    if(url.includes('?')) {
      url += `&${filterQuery}`;
    } else {
      url += `?${filterQuery}`;
    }
  }

  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true
  });
}


export async function getHistoricalSensorData(
  plotId: string,
  startTime: string | Date,
  endTime: string | Date,
  dataType: string
) {
  const url = `/sensor-data?plotId=${plotId}&startTime=${startTime}&endTime=${endTime}&dataType=${dataType}`;
  return getData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
  });
}