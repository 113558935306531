import React, { useEffect, useState } from 'react';
import { FilterProps } from '../types';
import { Grid } from '@mui/material';
import Header from '../Header';
import { deleteButton, resetButton, submitButton } from '../action-buttons';
import dayjs from 'dayjs';
import Date from '../date';


const DateRangeFilter: React.FC<FilterProps> = (props) => {
  const {
    actionButtonType,
    defaultBackgroundColor,
    defaultValue,
    errorBackgroundColor,
    filterId,
    hasDeleteButton,
    hasResetButton,
    hasSubmitButton,
    hideTitle,
    label,
    maxDate,
    minDate,
    submittedBackgroundColor,
    onChange,
    onDelete,
    onReset,
    onSubmit,
  } = props;

  const [selectedStartDate, setSelectedStartDate] = useState<any>(
		!!defaultValue && !!defaultValue?.startDate ? dayjs(defaultValue?.startDate).utc() : null
  );

  const [selectedEndDate, setSelectedEndDate] = useState<any>(
    !!defaultValue && !!defaultValue?.endDate ? dayjs(defaultValue?.endDate).utc() : null
  );

  const [isApplied, setIsApplied] = useState<boolean>(false);
  const [hasError, setHasError] = useState<string>();

  useEffect(() => {
    if (!!onChange) {
      const payload = {
        startDate: selectedStartDate,
				endDate: selectedEndDate
      }
      onChange(payload, filterId ?? "");
    }
  }, [selectedStartDate, selectedEndDate]) // eslint-disable-line

	const handleDateChange = (data: Record<string, any>, filterId: string | undefined) => {
		const { date } = data;
		if(filterId === "startDate") {
			setSelectedStartDate(dayjs(date).startOf("date"));
		} else if(filterId === "endDate"){
			setSelectedEndDate(dayjs(date).endOf("date"));
		}
	}

  // action button handlers
  const onFilterSubmit = () => {
    if (!!onSubmit) {
      const payload = {
        startDate: selectedStartDate,
				endDate: selectedEndDate
      }
      onSubmit(payload, filterId ?? "")
    }
  }

  const onFilterReset = () => {
    if (!!onReset) {
      onReset(filterId ?? "");
    }

    // reset isApplied and error states
    setIsApplied(false);
    setHasError("");
  }

  const onFilterDelete = () => {
    setIsApplied(false);
    if (!!onDelete) {
      onDelete(filterId ?? "");
    }
  }

  /**
   * background colors for the filter
   */
  let backgroundColor = "#D6D6D6";
  if (defaultBackgroundColor) {
    backgroundColor = defaultBackgroundColor;
  }

  if (isApplied && submittedBackgroundColor) {
    backgroundColor = submittedBackgroundColor;
  }

  if (isApplied && !!hasError && errorBackgroundColor) {
    backgroundColor = errorBackgroundColor;
  }

  return (
    <Grid width={"100%"}>
      {!hideTitle && <Header title={label ?? 'Date'} />}

      <Grid container justifyContent={"space-between"} alignItems={"center"} borderRadius={2} gap={1} p={1} style={{ backgroundColor }} >
        <Grid display={"flex"}>
					<Date
						label='Start Date'
						defaultValue={selectedStartDate}
						onChange={handleDateChange}
						filterId='startDate'
						defaultBackgroundColor={"inherit"}
					/>
					<Date
						label='End Date'
						filterId='endDate'
						defaultValue={selectedEndDate}
						onChange={handleDateChange}
						defaultBackgroundColor={"inherit"}
					/>
				</Grid>

        {/* action buttons */}
        <Grid item xs={2} mt={1} display={"flex"} justifyContent={"flex-end"} gap={"5px"} >
          {submitButton({ show: hasSubmitButton, onClick: onFilterSubmit, actionButtonType })}
          {resetButton({ show: hasResetButton, onClick: onFilterReset, actionButtonType })}
          {deleteButton({ show: hasDeleteButton, onClick: onFilterDelete, actionButtonType })}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DateRangeFilter;