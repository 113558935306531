import {
  ICellRendererComp,
  ICellRendererParams,
} from 'ag-grid-community';
import { TDevice, TDeviceDetails } from '../types';

const currentDevice: TDevice = 'nero_single';

export class NeroSingleInventoryRenderer implements ICellRendererComp {
  eGui!: HTMLSpanElement;

  init(params: ICellRendererParams) {
    this.eGui = document.createElement('span');
    this.eGui.style.display = "flex";
    this.eGui.style.alignItems = "center";
    this.eGui.style.padding = "0px";

    const { data } = params;
    const devices: TDeviceDetails[] = data.device;

    const kairoInventory = (devices || []).filter((device: TDeviceDetails) => device.deviceName === currentDevice);
    const count: number = kairoInventory[0]?.stockQuantity ?? 0;

    this.eGui.innerHTML = `<span>${count}</span>`
  }

  getGui() {
    return this.eGui;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}