import { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';

const AUTO_PLAY_MAX_LIMIT = 15
const AUTO_PLAY_INTERVAL = 8000

const useDatepicker = (initialDate = dayjs().add(-1, 'day')) => {
  const [currentDate, setCurrentDate] = useState(initialDate);
  const [isAutoPlay, setIsAutoPlay] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const lastDate = dayjs(new Date).add(-1, 'day').format('DD/MM/YYYY')
  const dateRef = useRef<any>(null)
  dateRef.current = currentDate
  const dateHandler = (date: Date | null) => setCurrentDate(dayjs(date));
  const [autoplayCount, setAutoplayCount] = useState<number>(1)

  useEffect(() => {
    if(dayjs(currentDate).format('DD/MM/YYYY') === initialDate.format('DD/MM/YYYY') || autoplayCount === AUTO_PLAY_MAX_LIMIT){
      setIsAutoPlay(false)
    }
  }, [currentDate])

  const updateDate = (value: number) => {
    // dateRef.current = dateRef.current.add(value, 'day');
    setCurrentDate(currentDate.add(value, 'day'));
  };
  const toggleAutoPlay = () => {
    setIsAutoPlay(!isAutoPlay);
  };

  useEffect(() => {
    if (isAutoPlay) {
      setButtonDisabled(true);
      const intervalId = setInterval(() => {
        dateRef.current = dateRef.current.add(1, 'day')
        setAutoplayCount(count => count + 1)
        // console.log('dateRef.current', dateRef.current.format('DD/MM/YYYY'))
        dateHandler(dateRef.current)
      }, AUTO_PLAY_INTERVAL);
      return () => clearInterval(intervalId);
    } else {
      setAutoplayCount(1);
      setButtonDisabled(false);
    }
  }, [isAutoPlay]);

  return { currentDate, updateDate, isAutoPlay, toggleAutoPlay, dateHandler, buttonDisabled, lastDate, dateRef };
};

export default useDatepicker;
