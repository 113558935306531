import { Dialog, DialogProps, DialogTitle, Grid, Typography, debounce } from '@mui/material';
// import { ReactSpreadsheetImport } from "react-spreadsheet-import";


import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Option } from '../constants/interfaces';
import { deleteLead, getLeadDetails, postLead, updateLead } from '../services/businessDashboard.service';
import Select, { SingleValue } from "react-select";
import { DropDownProps } from 'src/pages/BusinessDashboard/components/CropFilterBusinessDashboard';
import { getUser, getUsers } from '../services/user.service';
import { generateOptions } from '../utils/helper';
// include the widget CSS file whichever way your bundler supports it
import { LeadColDef } from '../constants/columnDefs';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';
import { TextArea } from '../components/DynamicForm/fields/_fieldStyles';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';
import { E164Number } from 'libphonenumber-js';



interface LeadDetailsProps {
    selectedId?: string | null;
    edit?: boolean;
    updateData: () => void;
    showAddModal: any;
  }

  const LeadDetails: React.FC<LeadDetailsProps> = ({ selectedId, edit, updateData, showAddModal}): JSX.Element => {

    const DEFAULT_UPLOADER = 'shreyaphadtare';

    let res: any = {};
    const navigate = useNavigate()
    const id = selectedId ?? "";
    const editFlag = edit ?? false

    //status dropdown
    const statusOptions: Option[] = [
        {
            label: 'open',
            value: 'Open'
        },
        {
            label: 'converted',
            value: 'Converted'
        },
        {
            label: 'rejected',
            value: 'Rejected'
        },

    ]

    //users dropdown
    const [userOptions, setUserOptions] = useState<Option[]>();
    const [selectedUser, setSelectedUser] = useState<SingleValue<any>>(null);
    const [initialUser, setInitialUser] = useState<SingleValue<any>>(null);
    const [usersLoading, setUsersLoading] = useState<boolean>(false);

    const [Id, setId] = useState<any>("");
    const [name, setName] = useState<string>("");
    const [contact, setContact] = useState<string>("");
    const [servicedBy, setServicedBy] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [comment, setComment] = useState<string>("");
    const [openUploadModal, setOpenUploadModal] = useState(false);

    
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('xl');
    const [uploadedData, setUploadedData] = useState<any>([]);

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const [followUpDate, setFollowUpDate] = useState<Date>(tomorrow);
    const [status, setStatus] = useState<string>("")
    useEffect(() => {
        getLeadDetails(id!)
            .then((result: any) => {
                if (result.id) {
                    res = result;
                    setId(res?.id || "");
                    setName(res?.contactName || "");
                    setContact(res?.contactPhoneNumber || "");
                    setLocation(res?.location || "");
                    setServicedBy(res?.servicedBy || "");
                    setComment(res?.comment || "");
                    setFollowUpDate(res?.followUpDate || "");
                    setStatus(res?.status || "");
                }
            })
    }, [id]);

    useEffect(() => {
        if(!initialUser){
            if(servicedBy!=="")
            getUser(servicedBy).then((res: any) => {
                setInitialUser({value: res.farmUserId, label: res.name});
            })
        }
    }, [servicedBy]);


    const onSubmit = () => {
        
            if (window.confirm("Do you want to submit?")) {
                const payload = { Id, name, contact, location, servicedBy, followUpDate, comment, status: !!status ? status : "new" }

                getLeadDetails(Id)
                    .then((res: any) => {

                        if (res.id) {
                            updateLead(payload)
                                .then((res: any) => {
                                    alert("Lead updated");
                                    updateData();
                                    showAddModal(false);
                                })
                        }

                        else {
                            postLead(payload)
                                .then((res: any) => {
                                    alert('Lead Added');
                                    updateData();
                                    showAddModal(false);
                                })
                        }
                    })
            }
        }
    

    const onDelete = () => {
        if (Id) {
            if (window.confirm("Do you want to delete?")) {
                getLeadDetails(Id)
                    .then((res: any) => {

                        if (res.id) {
                            deleteLead(Id)
                                .then((res: any) => {
                                    if(res.message === 'Success'){
                                        alert("Lead Deleted");
                                        updateData();
                                    }
                                    else alert('Error in Lead Deletion')
                                    showAddModal(false);
                                })
                        }
                        else
                            alert("Some Error Occured");
                    })
            }
        }
        else
            alert("No such Lead");
    }

    const fetchUserOptions = useCallback(
        debounce(async (query: string) => {
            try {
                setUsersLoading(true);
                const response = await getUsers(query);
                const newUserOptions = generateOptions(response, 'farmUserId', 'name');
                setUserOptions(newUserOptions);
            } catch (error) {
                console.error('Error fetching options:', error);
            } finally {
                setUsersLoading(false);
            }
        }, 300),
        []
    );

    const handleUserSelect = (selectedUser: SingleValue<any>) => {
        setSelectedUser(selectedUser);
        if (!!selectedUser)
            setServicedBy(selectedUser.value);
    }

    const handleStatusSelect = (selectedStatus: SingleValue<any>) => {
        setStatus(selectedStatus.label);
    }
    const handleInputChange = (newValue: string) => {
        fetchUserOptions(newValue);
    };

    
    const fields:any = []

    LeadColDef.map((item:any) => (
        fields.push({
            label: item.field.toString(),
            key: item.field.toString(),
            fieldType: {
                type: "input",
              },

        })
    ));
    
    const onUpload = (rows:any) => {
        rows = rows.validData;
        
        rows.map((item:any) => {            
            item.followUpDate = new Date(item.followUpDate);
            if (item.servicedBy === undefined) {
                item.servicedBy = DEFAULT_UPLOADER;
            }
        })
        postLead(rows, true).then((res: any) => {
            if (res.id)
                alert('Lead Added');
     
        }).catch((err) => console.log("error: ", err));
        
        
        
    }
    const onClose = () => {
        setOpenUploadModal(false);
        showAddModal(false);
        updateData();
    }
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const onContactNumberChange = (value: any) => {
        if(value!==undefined && isValidPhoneNumber(value)){
            setPhoneNumberError(false);
            setContact(value);
        }
        else setPhoneNumberError(true);

    }
    
    return (
        <Grid width={'100%'} my={2} mx={'auto'} boxShadow={2} borderRadius={8} paddingBottom={5}>

            <Dialog onClose={() => setOpenUploadModal(false)} open={openUploadModal} maxWidth={maxWidth} fullWidth={true}>
                <DialogTitle>Upload CSV/Excel File</DialogTitle>
                <Importer 
                    dataHandler={async (rows:any) => {

                        rows.map((item:any) => {
                            if (item.servicedBy === "") {
                                item.servicedBy = DEFAULT_UPLOADER;
                            }
                            item.followUpDate = new Date(item.followUpDate);
                            item.created_date = dayjs(item.created_date).add(5, "hours").add(30, "minutes").toISOString();
                            if (!item.status || item.status === "") {
                                item.status = "new";
                            }
                        })

                        setUploadedData((uploadedData:any) => [...uploadedData, ...rows]);
                        await new Promise((resolve) => setTimeout(resolve, 1000));
                    }}
                    defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
                    restartable={false} // optional, lets user choose to upload another file when import is complete
                    onComplete={({  }) => {    
                        postLead(uploadedData, true)
                            .then((res: any) => {
                                if(res.error)
                                    alert('Error in Lead Uploading');
                                else
                                    alert('Lead Added');

                            });

                    }}
                    onClose={({ }) => {    
                        updateData();
                        setUploadedData([]);
                        setOpenUploadModal(false);
                    }}

                    // CSV options passed directly to PapaParse if specified:
                    // delimiter={...}
                    // newline={...}
                    // quoteChar={...}
                    // escapeChar={...}
                    // comments={...}
                    // skipEmptyLines={...}
                    // delimitersToGuess={...}
                    // chunkSize={...} // defaults to 10000
                    // encoding={...} // defaults to utf-8, see FileReader API
                >   

                    {LeadColDef.map((item:any) => (
                        <ImporterField name={item.field.toString()} label={item.headerName.toString()} /> 
                    ))}

                </Importer>
            </Dialog>
            <Grid p={2} borderRadius={20}  >
                <Grid display={'flex'} item alignItems={'center'}>
                    <Typography m={1} variant='h5'>Lead Details</Typography>
                </Grid>

            </Grid>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: 30,
                }}
            >
                <form>
                    <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }} >Contact Name</label>
                    <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red' }}>*</label>
                    <input name="name" onChange={(e) => setName(e.target.value)} value={name} type="text" style={{ width: '93%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11, marginTop: 10 }} ></input><br />

                    <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }} >Contact Number</label>
                    <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red' }}>*</label>
                    <PhoneInput placeholder="Enter phone number" defaultCountry="IN" value={contact as E164Number} 
                                style={{ width: '93%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11, marginTop: 10 }} 
                                onChange={(value:any) => onContactNumberChange(value)}
                                
                                />
                    {phoneNumberError && <div className="error" style={{color: "#ff0000"}}>Enter a valid phone number</div>}
                    <h3 style={{ margin: 5 }}>Location</h3>
                    <input name="location" onChange={(e) => setLocation(e.target.value)} value={location} type="text" style={{ width: '93%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11, marginTop: 10 }} ></input><br />

                    <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }} >Follow Up Date</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                            sx={{ width: 60, marginLeft: 1, marginTop: -1 }}
                            onChange={(e) => {setFollowUpDate(new Date(dayjs(e).toString()));}}
                            value={dayjs(followUpDate)}
                            format='DD/MM/YYYY'
                            />
                        </DemoContainer>
                    </LocalizationProvider>

                    <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }} >Serviced By</label>
                    <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red' }}>*</label>
                    <Grid display={'flex'} flexDirection={'row'} alignItems={'center'} mb={3}>
                        <Select
                            options={userOptions}
                            onChange={handleUserSelect}
                            isLoading={usersLoading}
                            value={selectedUser ?? initialUser}
                            onInputChange={handleInputChange}
                            filterOption={(option:any, value:any) => true} // to remove react-select's filtering
                            {...DropDownProps}
                        />
                    </Grid>

                    <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }} >Status</label>
                    <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red' }}>*</label>
                    <Grid display={'flex'} flexDirection={'row'} alignItems={'center'} mb={3}>
                        <Select
                            value={{value: status, label: status} as Option}
                            options={statusOptions}
                            onChange={handleStatusSelect}
                            isLoading={false}
                            filterOption={(option:any, value:any) => true} // to remove react-select's filtering
                            {...DropDownProps}
                        />
                    </Grid>

                    <h3 style={{ margin: 5 }}>Comment</h3>
                    {/* <TextArea name="description" value={description} onChange={(e: any) => setDescription(e.target.value)} style={{ width: '700px', height: '120px', padding: '18px', resize: 'none', fontSize: 18, margin: '3px', marginBottom: 11 }} ></TextArea><br /> */}
                    <TextArea name="comment" onChange={(e:any) => setComment(e.target.value)} value={comment} type="text" style={{ width: '93%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11, marginTop: 10 }} ></TextArea><br />

                    <Grid display={"flex"} justifyContent={"space-between"} style={{ marginTop: 55 }} >
                        <input type="button" value="Submit" onClick={onSubmit}  style={{ width: '60%', padding: '20px', fontSize: 18, paddingTop: '15px', alignSelf: 'center', margin: '30px' }} />
                        <input type="button" value="Delete" onClick={onDelete} style={{ width: '60%', padding: '20px', fontSize: 18, paddingTop: '15px', alignSelf: 'center', margin: '30px' }} />
                    {/* ADD ONCLICK FUNCTIONS TO ABOVE BUTTONS */}
                    </Grid>
                </form>
            </div>
            {!editFlag && <Grid display={"flex"}  style={{ marginTop: 20 }} >
                <Typography m={1} alignContent="center" variant='h5' >Got a Excel file with you? Upload that in CSV/XSLS format: </Typography>
                <input type="button" value="Upload" onClick={() => setOpenUploadModal(true)} style={{ width: '30%', padding: '20px', fontSize: 18, paddingTop: '15px', alignSelf: 'center', margin: '30px' }} />
            </Grid>}
        </Grid>
    )
}

export default LeadDetails