import { Checkbox, Grid, Typography } from '@mui/material';
import { intersection } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FilterData, PlotFiltersProps } from '../../constants/interfaces';
import { Location, Crop, LiveData as ClimateData, Harvesting } from '../Filters/'

interface CheckboxProps {
  label: string;
  handleChange: () => void;
  checked?: boolean;
}

export enum FilterIds {
  HARVESTING = 'HARVESTING',
  LOCATION = 'LOCATION',
  CLIMATE = 'CLIMATE',
  CROP = 'CROP'
}

const CheckboxComponent = (props: CheckboxProps): JSX.Element => {
  const { handleChange, label, checked } = props;
  return (
    <span>
      <Checkbox checked={checked} onChange={handleChange} />
      <Typography variant='overline' > {label} </Typography>
    </span>
  )
}

const ExporterFilters: React.FC<PlotFiltersProps> = (props: PlotFiltersProps): JSX.Element => {
  const { handlePlotIdChange } = props;

  // filters
  const [filters, setFilters] = useState<any[]>([])
  const [filterData, setFilterData] = useState<FilterData>({});

  const [showHarvesting, setShowHarvesting] = useState<boolean>(false);
  const [showLocation, setShowLocation] = useState<boolean>(false);
  const [showCrop, setShowVariety] = useState<boolean>(false);
  const [showClimate, setShowClimate] = useState<boolean>(false);

  useEffect(() => {
    doStuff()
    sessionStorage.setItem('exporter_applied_filters', JSON.stringify(filterData))
    sessionStorage.setItem('exporter_filters', JSON.stringify(filters))
  }, [filterData])

  const doStuff = () => {
    const commonPlots = getCommonPlots(filterData as FilterData)
    handlePlotIdChange(commonPlots)
  }

  const handleCheckbox = (checkbox: string) => {
    switch (checkbox) {
      case FilterIds.HARVESTING:
        if (!showHarvesting) {
          handleFilterSelectChange(checkbox)
        }
        setShowHarvesting(!showHarvesting);
        break;

      case FilterIds.LOCATION:
        if (!showLocation) {
          handleFilterSelectChange(checkbox)
        }
        setShowLocation(!showLocation);
        break;

      case FilterIds.CROP:
        if (!showCrop) {
          handleFilterSelectChange(checkbox)
        }
        setShowVariety(!showCrop);
        break;

      case FilterIds.CLIMATE:
        if (!showClimate) {
          handleFilterSelectChange(checkbox)
        }
        setShowClimate(!showClimate);
        break;

      default:
        break;
    }
  }

  const getCommonPlots = (filterData: FilterData): (undefined | String[]) => {
    let commonPlotIds: string[] = []
    let cnt = 0;

    for (const filter in filterData) {
      if (filterData[filter] !== undefined) {
        // commonPlotIds <--- firstResponse (varna empty array se intersection hota rahega)
        if (cnt < 1) {
          commonPlotIds = filterData[filter] as string[]
        } else {
          commonPlotIds = intersection(commonPlotIds, filterData[filter])
        }
        cnt++;
      }
    }

    if (cnt === 0) {
      return undefined
    }
    return commonPlotIds
  }

  const handleFilterSelectChange = (filterId: string): void => {
    setFilters(filters.concat({ filterId }))
  }

  const handleFilterDataChange = (filterId: string, appliedStatus: boolean, newFilterData: FilterData) => {
    setFilterData(Object.assign({}, filterData, { [filterId]: newFilterData }))
  }

  const handleRemove = (filterId: string) => {
    handleCheckbox(filterId)
    setFilters(filters.filter(obj => obj.filterId !== filterId))
    let clone = Object.assign({}, filterData);
    delete clone[filterId];
    setFilterData(clone)
  }

  const childProps: any = {
    handleFilterDataChange,
    handleRemove
  }

  return (
    <Grid px={4}>
      <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <span>
          <Typography variant='subtitle2' >Filter by</Typography>
          <CheckboxComponent checked={showHarvesting} handleChange={() => handleCheckbox(FilterIds.HARVESTING)} label='Harvesting Window' />
          <CheckboxComponent checked={showClimate} handleChange={() => handleCheckbox(FilterIds.CLIMATE)} label='Climate Data' />
          <CheckboxComponent checked={showLocation} handleChange={() => handleCheckbox(FilterIds.LOCATION)} label='Location' />
          <CheckboxComponent checked={showCrop} handleChange={() => handleCheckbox(FilterIds.CROP)} label='Crop' />
        </span>
      </Grid>
      <Grid mt={2}>
        {showHarvesting && <Harvesting key={FilterIds.HARVESTING} filterId={FilterIds.HARVESTING} {...childProps} />}
        {showClimate && <ClimateData key={FilterIds.CLIMATE} filterId={FilterIds.CLIMATE} {...childProps} />}
        {showCrop && <Crop key={FilterIds.CROP} filterId={FilterIds.CROP} {...childProps} />}
        {showLocation && <Location key={FilterIds.LOCATION} filterId={FilterIds.LOCATION} {...childProps} />}
      </Grid>
    </Grid>
  )
}

export default ExporterFilters;