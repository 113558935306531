import { Delete } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { CustomIconButton, InputFieldStyles } from "./AddObservationType";

type InputChipProps = {
  title: string;
  inputRef: React.RefObject<HTMLInputElement>;
  handleKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onAdd: () => void;
  chips: string[];
  removeChip: (index: number) => void;
};

export const InputChip: React.FC<InputChipProps> = ({
  title,
  inputRef,
  handleKeyUp,
  onAdd,
  chips,
  removeChip,
}) => {
  return (
    <Grid container spacing={2} my={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{title}</Typography>
        <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <input
            ref={inputRef}
            onKeyUp={handleKeyUp}
            style={{ ...InputFieldStyles, width: "85%", marginRight: "10px" }}
          />

          <CustomIconButton onClick={onAdd} />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        display={"flex"}
        flexWrap={"wrap"}
        alignItems={"center"}
        gap={"5px"}
      >
        {chips.map((chip, index) => (
          <span
            key={`${chip}-${index}`}
            style={{
              display: "flex",
              alignItems: "center",
              border: "0.5px solid #303234",
              padding: "2px 4px",
              borderRadius: "4px",
            }}
          >
            <Typography fontSize={12}> {chip} </Typography>
            <IconButton size="small" onClick={() => removeChip(index)}>
              <Delete fontSize="small" style={{ fontSize: "12px" }} />
            </IconButton>
          </span>
        ))}
      </Grid>
    </Grid>
  );
};