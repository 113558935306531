import { Grid, Typography } from "@mui/material";
import { FC, ReactNode, useEffect, useState } from "react";
import { IconType } from "react-icons";
import { FaCloudRain, FaSun, FaThermometerHalf, FaWind } from "react-icons/fa";
import { GiDrop, GiWindTurbine } from "react-icons/gi";
import { PlotSensorData } from "../utils";

const KeysToRender: (keyof PlotSensorData)[] = ['airTemp', 'airHumidity', 'rainFall', 'lightIntensity', 'windSpeed', 'windDirection'];

/**
 * 
 * Component to render the information with it's icon
 *
 * */ 

interface InfoRendererProps {
  icon: ReactNode;
  text: string;
  showSeparator?: boolean;
}

const InfoRenderer: FC<InfoRendererProps> = (props) => {
  const { text, icon, showSeparator = true } = props;

  return (
    <Grid container spacing={1} borderBottom={showSeparator ? 0.1 : 0} borderColor={'#c1c1c1'} >
      <Grid item xs={4} my={'auto'} > {icon} </Grid>
      <Grid item xs={8}>
        <Typography fontSize={14} variant="caption">{text}</Typography>
      </Grid>
    </Grid>
  )
}


/**
 * 
 * Sensor Info Modal Component
 * 
 */

interface Props {
  plotSensorData: PlotSensorData
}

interface SensorIconMap {
  [key: string]: IconType;
}

const SensorUnit = {
  airTemp: '°C',
  airHumidity: '%',
  rainFall: 'mm',
  lightIntensity: 'lux',
  windSpeed: 'kmph'
}

const SensorIcon: SensorIconMap = {
  airTemp: FaThermometerHalf,
  airHumidity: GiDrop,
  rainFall: FaCloudRain,
  lightIntensity: FaSun,
  windSpeed: FaWind,
  windDirection: GiWindTurbine
}

const PlotSensorDataInfoModal: FC<Props> = (props) => {
  const { plotSensorData } = props;

  const [sensors, setSensors] = useState<any[]>([]);

  useEffect(() => {
    /*
      * Logic to render the separator, as the data in plotSensorData is not consistent, we can't tell which key will have value, which will not
      * so, storing all the valid values in an array, iterating over it to render the information
      * checking if the info is not the last info, to render separator
    */
    let toRender: any = [];
    for(const objKey in plotSensorData) {
      const key = objKey as keyof PlotSensorData;
      if(KeysToRender.includes(key) && plotSensorData[key] !== undefined) {
        if(key === 'place' || key === 'location') continue;

        const value = (key === 'windDirection') ? 
          plotSensorData[key] : 
          plotSensorData[key].toFixed(0) + ' ' + SensorUnit[key];
        
        const icon = SensorIcon[key];
        toRender.push({ label: key, value, icon });
      }
    }
    setSensors(toRender);
  }, [])

  return (
    <Grid width={130}>
      <Grid width={'100%'}>
        <Typography textAlign={'center'} textTransform={'uppercase'} variant="subtitle1" fontSize={16} letterSpacing={1.2} fontWeight={500} >{plotSensorData.place}</Typography>
      </Grid>

      {
        sensors.map((sensor: any, index: number) => {
          const IconComponent = SensorIcon[sensor.label];
          const showSeparator = index + 1 < sensors.length;
          return (
            <InfoRenderer key={`info-renderer-${sensor.label}-${Math.random()}`} icon={<IconComponent size={16} />} text={sensor.value} showSeparator={showSeparator} />
          )
        })
      }
    </Grid>
  )
}

export default PlotSensorDataInfoModal