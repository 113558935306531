import { useEffect, useState, useMemo } from 'react';
import { Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router';
import dayjs from 'dayjs';
import MonthYearPicker from 'src/components/MonthYearPicker';
import { getMonthlyPlans, getSalesActivityTypes, getSalesActivities } from 'src/services/sales.service';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getTargetRegions } from '../services/region.service';
import { generateOptions } from '../utils/helper';

interface Activity {
    id: string;
    description: string;
}

interface Plan {
    id: string;
    district: string;
    state: string;
    name: string;
    plans: { [key: string]: number[] };
}

interface SalesActivity {
    date: string;
    salesActivityTypeId: string;
}

interface PhaseGraphProps {
    plan: Plan;
    activityTypes: Activity[];
}

const PhaseGraph: React.FC<PhaseGraphProps> = ({ plan, activityTypes }) => {
    const [doneActivities, setDoneActivities] = useState<{ [key: string]: number[] }>({});
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    useEffect(() => {
        const formatDoneActivities = (activities: SalesActivity[]) => {
            const formattedActivities: { [key: string]: number[] } = {};
            activityTypes?.forEach(activity => {
                formattedActivities[activity?.id] = [0, 0, 0];
            });
            activities?.forEach(activity => {
                const index = dayjs(activity?.date).date() < 11 ? 0 : dayjs(activity?.date).date() < 21 ? 1 : 2;
                formattedActivities[activity?.salesActivityTypeId][index]++;
            });
            setDoneActivities(formattedActivities);
            setDataLoaded(true);
        };

        if (plan?.id?.length > 0) {
            setDataLoaded(false);
            getSalesActivities(plan.id).then(formatDoneActivities);
        }
    }, [plan, activityTypes]);

    const isVisibleMemo = useMemo(() => {
        const isVisibleForActivity = (activityId: string) => {
            return plan?.plans[activityId]?.some(planCount => planCount > 0) ||
                (doneActivities && doneActivities[activityId] && Object?.values(doneActivities[activityId])?.some(actualCount => actualCount > 0));
        };
        return activityTypes?.reduce((acc, activity) => {
            acc[activity?.id] = isVisibleForActivity(activity?.id);
            return acc;
        }, {} as { [key: string]: boolean });
    }, [plan, doneActivities, activityTypes]);

    return (
        <Grid item width={'99%'} style={{ marginBottom: '20px' }}>
            <Typography mt={1} mb={2} fontSize={18} textAlign={'center'} style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                {` ${plan.name ? plan.name : plan.district ? `${plan.district}, ${plan.state}` : plan.state}`}
            </Typography>

            <Grid boxShadow={2} p={2} borderRadius={4} style={{ backgroundColor: '#fff', overflow: 'auto', display: 'flex', flexDirection: 'row' }}>
                {dataLoaded ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Phase/Activity</TableCell>
                                    {activityTypes?.map(activity => {
                                        const isVisible = isVisibleMemo[activity.id] || false;
                                        return isVisible ? <TableCell key={activity?.id}>{activity?.description}</TableCell> : null;
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[...Array(3)]?.map((_, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{`${index * 10 + 1} - ${index === 2 ? 31 : (index + 1) * 10} Days`}</TableCell>
                                        {activityTypes?.map(activity => {
                                            const isVisible = isVisibleMemo[activity?.id] || false;
                                            return isVisible ? (
                                                <TableCell key={activity?.id}>
                                                    {`${doneActivities?.[activity?.id]?.[index]} / ${plan?.plans?.[activity?.id]?.[index]}`}
                                                </TableCell>
                                            ) : null;
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px', width: '100%' }}>
                        <CircularProgress />
                    </div>
                )}
            </Grid>
        </Grid>
    );
};

const Sales: React.FC = (): JSX.Element => {
    const [isApplied, setIsApplied] = useState(false);
    const [isError, setIsError] = useState(false);
    const [selectedDate, setSelectedDate] = useState<{ month: number; year: number }>({ month: dayjs().month() + 1, year: dayjs().year() });
    const [monthlyData, setMonthlyData] = useState<Plan[]>([]);
    const [activityTypes, setActivityTypes] = useState<Activity[]>([]);
    const [regionDict, setRegionDict] = useState<any>();

    useEffect(() => {
        getSalesActivityTypes().then(res => {
            setActivityTypes(res);
        });

        getTargetRegions().then(res => {
            res.map((row:any) => {
                if(row.regionName)
                    row.name = row.regionName;
            });
            const regionlist = generateOptions(res, "id", "name");
            
            const regiondict: {[key: string]: string | number} = {};
            regionlist.map((region) => {
              regiondict[region.value] = region.label;
            })
            setRegionDict(regiondict);
        })
    }, []);

    useEffect(() => {
        onSubmit();
    }, [selectedDate, regionDict]);

    const onReset = () => {
        setIsApplied(false);
        setIsError(false);
        setMonthlyData([]);
    };

    const handleDateChange = (date: any) => {
        setSelectedDate(date);
    };

    const onSubmit = async () => {
        onReset();
        try {
            const res = await getMonthlyPlans(selectedDate.month, selectedDate.year);
            
            if (res.length > 0) {
                res.map((plan: any) => {
                    plan.name = regionDict[plan.region];
                })
                console.log(res);
                setMonthlyData(res);
                setIsApplied(true);
            } else {
                setIsError(true);
            }
        } catch (error) {
            console.error('Error fetching monthly plans:', error);
            setIsError(true);
        }
    };

    const navigate = useNavigate();

    return (
        <Grid width={'90%'} my={2} mx={'auto'} boxShadow={2} borderRadius={4} paddingBottom={5} style={{ marginBottom: '20px' }}>
            <Grid p={2} borderRadius={20}>
                <Grid display={'flex'} item alignItems={'center'}>
                    <IconButton onClick={() => navigate('/')}><ArrowBack /></IconButton>
                    <Typography m={1} variant='h5'>Sales</Typography>
                </Grid>
            </Grid>

            <Grid>
                <div style={{ backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6', margin: '0px 0 15px 0', padding: '5px 10px', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" style={{ marginRight: '10px' }}>Select Month and Year:</Typography>
                    <MonthYearPicker value={selectedDate} onChange={handleDateChange} />
                </div>
            </Grid>
            {monthlyData.map(plan => (
                <Grid container direction="column" key={plan.id}>
                    <PhaseGraph plan={plan} activityTypes={activityTypes} />
                </Grid>
            ))}

            {isError && <Typography fontSize={17} color={'red'} margin={3}>No Data Found. Please try again!</Typography>}
        </Grid>
    );
};

export default Sales;
