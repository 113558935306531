import { Grid, IconButton, Typography, Modal } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router';
import { getLAIAdvisory, putLAIAdvisory, postLAIAdvisory, deleteLAIAdvisory } from 'src/services/laiAdvisory.service';
import Select, { SingleValue, MultiValue } from "react-select";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { generateOptions, isArrayWithLength } from '../utils/helper';
import { getCropOptions, getCropVarieties, getcropsStageBasedOnVariety } from "src/services/crop.service";
import { getFertilizers } from 'src/services/farm.service';
import { laiAdvisoryColDef } from '../constants/columnDefs';
import AddBox from '@mui/icons-material/AddBox';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ToastAlert } from 'src/components';
import { Link } from 'react-router-dom';
import LocaleDropdown from 'src/components/LocaleDropdown';


import {
    ColDef,
    GridOptions,
    GridReadyEvent,
    SideBarDef,
    SortChangedEvent,
    FilterChangedEvent,
} from 'ag-grid-community';

const LaiAdvisory: React.FC = (): JSX.Element => {
    const gridRef = useRef<AgGridReact>(null);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [enableCopy, setEnableCopy] = useState<boolean>(false);
    const [isApplied, setIsApplied] = useState(false)
    const [isError, setisError] = useState(false)
    const [isAdvisoryData, setisAdvisoryData] = useState(false)
    const [AdvisoryData, setadvisoryData] = useState([])
    const [crops, setCrops] = useState<SingleValue<any>>([])
    const [isCropLoading, setIsCropLoading] = useState<boolean>(true)
    const [selectedCropOption, setSelectedCropOption] = useState<any>(null)
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [cropOptions, setcropOptions] = useState<any>();
    const [chemicalOptions, setChemicalOptions] = useState<any>();
    const [selectedCropVarietyOption, setSelectedCropVarietyOption] = useState<MultiValue<any>>([]);
    const [cropVariety, setCropVariety] = useState<MultiValue<any>>([])
    const [advisoryModal, setAdvisoryModal] = useState<boolean>(false);
    const [contentModal, setContentModal] = useState<boolean>(false);
    const [parametersModal, setparametersModal] = useState<boolean>(false);
    const [openToast, setOpenToast] = useState<boolean>(false);
    const [selectedActivityOption, setSelectedActivityOption] = useState<any>(null)
    const [baseStageOptions, setbaseStageOptions] = useState<any>();
    const [selectedContentOption, setSelectedContentOption] = useState<any>(null)
    const [selectedModalData, setSelectedModalData] = useState<any>({
        cropId: String,
        cropVarietyId: [],
        stageId: String,
        week: [],
        minLai: 0,
        maxLai: 0,
        minOptimalLai: 0,
        maxOptimalLai: 0,
        advisory: []
    })
    const [advisoryItemData, setAdvisoryItemData] = useState<any>({
        type: String,
        content: [],
        activityId: String,
        parameters: {}

    })
    const [contentData, setContentData] = useState<any>({
        item: String,
        amount: Number,
        unit: String
    })
    const [parameterData, setParameterData] = useState<any>({
        key: '',
        value: ''
    })
    const [updatedContentIndex, setUpdatedContentIndex] = useState<number>(-1);
    const [updatedAdvisoryIndex, setUpdatedAdvisoryIndex] = useState<number>(-1);

    const navigate = useNavigate();

    const gridOptions: GridOptions = {
        columnDefs: laiAdvisoryColDef,
        rowData: AdvisoryData,
        rowSelection: 'single',
        animateRows: true,
        overlayLoadingTemplate:
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
        onCellClicked: (e) => {
            setSelectedCropVarietyOption(e.data?.cropVarietyId?.map((v: any) => ({ label: v, value: v })));
            setSelectedModalData(e.data);
            setOpenModal(true);
        }
    };

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            resizable: true,
            sortable: true,
            floatingFilter: true,
            menuTabs: ['filterMenuTab'],
        };
    }, []);

    const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
        return {
            toolPanels: ['filters'],
        };
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        // gridRef?.current?.api.setColumnDefs(farmActivitiesColDef);
        // gridRef?.current?.api.setRowData(tableData);
    }, []);

    // function to perform server side sorting
    const handleColumnSort = (event: SortChangedEvent) => {
        const columns = event.columnApi.getColumnState();
        let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId
    }

    // function to perform server side filtering
    const handleColumnFilter = (event: FilterChangedEvent) => {
        if (event.afterFloatingFilter) { }
    }

    const onPaginationChanged = useCallback(() => { }, []);

    const onReset = () => {
        setIsApplied(false);
        setisError(false);
        setisAdvisoryData(false);
        setadvisoryData([]);
    }

    const onSubmit = async (crop: string) => {
        setisError(false);
        setisAdvisoryData(false);

        if (crop.length === 0) {
            alert('Choose one crop');
            return;
        }

        setIsApplied(true);

        try {
            let res = await getLAIAdvisory(crop);

            if (res.length > 0) {
                setisAdvisoryData(true);
                setadvisoryData(res);
            } else {
                setisError(true);
                setisAdvisoryData(true);
                setadvisoryData([]);
            }
        } catch (err) {
            console.log(err);
            setisError(true);
            setisAdvisoryData(false);
            setadvisoryData([]);
        } finally {
            setIsApplied(false);
        }
    };

    const typeOptions = [
        { value: '--select--', label: '--select--' },
        { value: 'spray', label: 'Spray' },
        { value: 'activity', label: 'Activity' },
        { value: 'irrigation', label: 'Irrigation' },
        { value: 'fertigation', label: 'Fertigation' },
    ];
    const unitOptions = [
        { value: '-select-', label: '-select-' },
        { value: 'gm', label: 'gm' },
        { value: 'kg', label: 'kg' },
        { value: 'lt', label: 'lt' },
        { value: 'ml', label: 'ml' },
        { value: 'ppm', label: 'ppm' },
        { value: '%', label: '%' },
        { value: 'gm/acre', label: 'gm/acre' },
        { value: 'kg/acre', label: 'kg/acre' },
        { value: 'lt/acre', label: 'lt/acre' },
        { value: 'ml/acre', label: 'ml/acre' },
    ];


    useEffect(() => {
        getCropOptions()
            .then(res => {
                setCrops(generateOptions(res, 'id', 'cropName'))
                setcropOptions(
                    [{
                        label: '--select--',
                        value: '--select--'
                    }].concat(generateOptions(res, 'id', 'cropName') as any))
                setIsCropLoading(false)
            })
        getFertilizers()
            .then(res => {
                setChemicalOptions(generateOptions(res, 'id', 'name') as any)
            })
    }, [])

    useEffect(() => {
        if (selectedModalData?.cropId.length > 0) {
            getCropVarieties(selectedModalData?.cropId)
                .then(res => {
                    setCropVariety(generateOptions(res, 'id', 'name') as any)
                })
        }
    }, [selectedModalData?.cropId])

    useEffect(() => {
        getcropsStageBasedOnVariety(selectedModalData?.cropId, selectedModalData?.cropVarietyId?.map((cropVariety: any) => cropVariety))
            .then((res: any) => {
                setbaseStageOptions([{
                    label: '--select--',
                    value: '--select--'
                }].concat(generateOptions(res, 'stageId', 'name') as any));
            })
            setEnableCopy(true);
    }, [selectedModalData?.cropVarietyId])

    const finalSubmit = () => {
        if (window.confirm("Do you want to submit?")) {
            if (selectedModalData?.id) {
                putLAIAdvisory({
                    ...selectedModalData, week: selectedModalData?.week?.map((week: any) => Number(week))
                }, selectedModalData.id)
                    .then((res: any) => {
                        if (res.message === 'Success') {
                            alert("Item updated");
                            setOpenModal(false)
                            onSubmit(selectedCropOption?.value);
                        }
                        else
                            alert("Some Error Ocuured");
                    })
            }
            else {
                postLAIAdvisory({
                    ...selectedModalData, week: selectedModalData?.week?.map((week: any) => Number(week))
                })
                    .then((res: any) => {
                        if (res.id) {
                            alert("Item added");
                            setOpenModal(false);
                            onSubmit(selectedCropOption?.value);
                        }
                        else
                            alert("Some Error Ocuured");
                    })
            }
        }

    }

    const onDelete = async () => {
        if (selectedModalData?.id) {
            if (window.confirm("Do you want to delete?")) {
                deleteLAIAdvisory(selectedModalData.id)
                    .then((res: any) => {
                        if (res.message === 'Success') {
                            alert("Item deleted");
                            setOpenModal(false);
                            onSubmit(selectedCropOption?.value);
                        }
                        else
                            alert("Some Error Ocuured");
                    })
            }
        }
        else
            alert("Some Error Occured");
    }

    return (
        <Grid width={'90%'} my={2} mx={'auto'} boxShadow={2} borderRadius={4} paddingBottom={5}>
            <Grid p={2} borderRadius={20} display={'flex'} justifyContent={'space-between'} marginBottom={3}>
                <Grid display={'flex'} item alignItems={'center'}>
                    <IconButton onClick={() => navigate('/')} ><ArrowBack /></IconButton>
                    <Typography m={1} variant='h5'>LAI Advisory</Typography>
                </Grid>
                {selectedCropOption && (
                    <input onClick={() => {
                        setSelectedModalData({
                            cropId: selectedModalData?.cropId,
                            cropVarietyId: [],
                            stageId: String,
                            week: [],
                            minLai: 0,
                            maxLai: 0,
                            minOptimalLai: 0,
                            maxOptimalLai: 0,
                            advisory: []
                        });
                        setSelectedCropVarietyOption([]);
                        setUpdatedAdvisoryIndex(-1);
                        setUpdatedContentIndex(-1);
                        setOpenModal(true)
                    }} type="button" value="Add LAI Advisory" style={{ width: '15%', padding: '10px', fontSize: 18, paddingTop: '10px', alignSelf: 'center' }}></input>
                )}
            </Grid>
            <Grid>
                <div style={{
                    backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
                    margin: '0px 0 15px 0',
                    padding: '5px',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column'
                }} >
                    <Typography variant='h6' ml={1} fontWeight='500' my={1}>Crop</Typography>
                    <Select
                        options={crops}
                        onChange={(selected: SingleValue<any>) => {
                            setSelectedCropOption(selected);
                            if (selected?.value && selected?.value?.length > 0) {
                                setSelectedModalData({ ...selectedModalData, cropId: selected.value });
                                onSubmit(selected.value);
                            }
                            else {
                                onReset()
                            }
                        }}
                        isLoading={isCropLoading}
                        isDisabled={isCropLoading}
                        value={selectedCropOption}
                        isClearable={true}
                        isSearchable={true}
                        placeholder={'- select -'}
                        styles={{
                            container: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                width: '25%',
                                marginLeft: '5px',
                            }),
                            control: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                borderTop: (state.isFocused ? '1px' : '0px'),
                                borderLeft: (state.isFocused ? '1px' : '0px'),
                                borderRight: (state.isFocused ? '1px' : '0px'),
                            }),
                            menu: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                zIndex: 100
                            })
                        }}
                    />
                </div>
            </Grid>

            {isAdvisoryData && (
                <div style={gridStyle} className="ag-theme-alpine">
                    {
                        isArrayWithLength(AdvisoryData) &&
                        <Grid p={3}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={AdvisoryData}
                                columnDefs={laiAdvisoryColDef}
                                onGridReady={onGridReady}
                                defaultColDef={defaultColDef}
                                sideBar={sideBar}
                                animateRows={true}
                                pagination={true}
                                paginationPageSize={15}
                                gridOptions={gridOptions}
                                domLayout="autoHeight"
                                onPaginationChanged={onPaginationChanged}
                                onSortChanged={handleColumnSort}
                                onFilterChanged={handleColumnFilter}
                            ></AgGridReact>
                        </Grid>
                    }
                </div>

            )}

            {isError && (
                <Typography
                    fontSize={17}
                    color={'red'}
                    margin={3}
                >There seems to be an error. Please try again!</Typography>
            )}

            <Modal open={openModal} style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start'
            }}
                onClose={() => { setOpenModal(false) }}
            >
                <Grid style={{
                    backgroundColor: '#fff',
                    padding: 12,
                    borderRadius: 12,
                    margin: 5,
                    alignSelf: 'center',
                    width: '70%',
                    maxHeight: '95%',
                    overflowY: 'auto'
                }}>
                    <Grid display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} mb={3}>
                        <Grid display={'flex'} flexDirection={'row'} alignItems={'center'}>
                            <IconButton onClick={() => setOpenModal(false)} ><ArrowBack /></IconButton>
                            <Typography variant={'h5'}>Advisory Details</Typography>
                        </Grid>
                        {selectedModalData?.id && enableCopy &&(
                            <IconButton
                                style={{ marginRight: 25 }}
                                onClick={() => {
                                    const updatedSelectedModalData = { ...selectedModalData };
                                    delete updatedSelectedModalData.id;
                                    setSelectedModalData(updatedSelectedModalData);
                                    setOpenToast(true);

                                }} ><ContentCopyIcon /><Typography marginLeft={0.8} fontSize={18}>Copy</Typography></IconButton>
                        )}
                    </Grid>
                    <ToastAlert
                        open={openToast}
                        setOpen={setOpenToast}
                        severity={'info'}
                        message={"Content Copied"}
                    />
                    <form>
                        <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }} >Crop</label>
                        <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red' }}>*</label>
                        <select onChange={(e) => setSelectedModalData({ ...selectedModalData, cropId: e.target.value })} value={selectedModalData.cropId}
                            style={{ width: '97%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11, marginTop: 10 }}
                        >
                            {cropOptions?.map(({ value, label }: any, index: any) => <option value={value} >{label}</option>)}
                        </select><br />

                        <h3 style={{ margin: 5 }} >Crop Variety</h3>
                        <Select
                            options={cropVariety}
                            isMulti
                            onChange={(selected: MultiValue<any>) => {
                                setSelectedCropVarietyOption(selected);
                                setSelectedModalData({ ...selectedModalData, cropVarietyId: selected?.map((v) => v?.value) })
                            }}
                            value={selectedCropVarietyOption}
                            isClearable={true}
                            isSearchable={true}
                            placeholder={'- select -'}
                            styles={{
                                container: (baseStyles: any, state: any) => ({
                                    ...baseStyles,
                                    width: '97%',
                                    marginLeft: '5px',
                                    borderColor: '#000',
                                    borderWidth: 1
                                }),
                                control: (baseStyles: any, state: any) => ({
                                    ...baseStyles,
                                    borderColor: '#000',
                                    height: 53
                                }),
                                menu: (baseStyles: any, state: any) => ({
                                    ...baseStyles,
                                    zIndex: 100
                                })
                            }}
                        />

                        <h3 style={{ margin: 5 }} >Stage</h3>
                        <select onChange={(e) => { setSelectedModalData({ ...selectedModalData, stageId: e.target.value }) }}
                            style={{ width: '96%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11 }}
                            value={selectedModalData?.stageId}
                        >
                            {baseStageOptions?.map(({ value, label }: any, index: any) => <option value={value} >{label}</option>)}
                        </select><br />

                        <h3 style={{ margin: 5 }} >Week</h3>
                        <input onChange={(e) => setSelectedModalData({ ...selectedModalData, week: e.target.value.split(",")?.map((val: any) => val.trim()) })} value={String(selectedModalData?.week)} type="text" style={{ width: '93%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11 }} ></input><br />

                        <Grid display={'flex'} flexDirection={'row'} width={'99%'} justifyContent={'space-evenly'}>
                            <Grid>
                                <h3 style={{ margin: 5 }} >Min LAI</h3>
                                <input onChange={(e) => setSelectedModalData({ ...selectedModalData, minLai: Number(e.target.value) })} value={selectedModalData?.minLai} type='number' style={{ width: '75%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11 }} ></input><br />
                            </Grid>
                            <Grid>
                                <h3 style={{ margin: 5 }} >Max LAI</h3>
                                <input onChange={(e) => setSelectedModalData({ ...selectedModalData, maxLai: Number(e.target.value) })} value={selectedModalData?.maxLai} type='number' style={{ width: '75%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11 }} ></input><br />
                            </Grid>
                            <Grid>
                                <h3 style={{ margin: 5 }} >Min Optimal LAI</h3>
                                <input onChange={(e) => setSelectedModalData({ ...selectedModalData, minOptimalLai: Number(e.target.value) })} value={selectedModalData?.minOptimalLai} type='number' style={{ width: '75%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11 }} ></input><br />
                            </Grid>
                            <Grid>
                                <h3 style={{ margin: 5 }} >Max Optimal LAI</h3>
                                <input onChange={(e) => setSelectedModalData({ ...selectedModalData, maxOptimalLai: Number(e.target.value) })} value={selectedModalData?.maxOptimalLai} type='number' style={{ width: '75%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11 }} ></input><br />
                            </Grid>
                        </Grid>

                        <Grid display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                            <h3 style={{ margin: 5 }} >Advisory</h3>
                            <IconButton onClick={() => {
                                setUpdatedAdvisoryIndex(-1);
                                setAdvisoryItemData({
                                    type: String,
                                    content: [],
                                    activityId: String
                                });
                                setSelectedActivityOption(null)
                                setAdvisoryModal(true)
                            }}>
                                <AddBox />
                            </IconButton>
                        </Grid>

                        <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                            {selectedModalData?.advisory?.length > 0 && selectedModalData?.advisory?.map((val: any, index: number) => (
                                <Grid my={2} boxShadow={2} borderRadius={3} px={2} pb={2} pt={2} display={'flex'} justifyContent={'space-between'}>

                                    <IconButton style={{ backgroundColor: 'transparent' }} size='medium'
                                        onClick={() => {
                                            setAdvisoryItemData(val);
                                            if (val?.activityId) {
                                                setSelectedActivityOption(val?.activityId)
                                            }
                                            else {
                                                setSelectedActivityOption(null);
                                            }
                                            setUpdatedAdvisoryIndex(index);
                                            setAdvisoryModal(true);
                                        }}
                                    >
                                        <Grid
                                            display="flex"
                                            alignItems={'center'}
                                        >
                                            <label style={{ fontSize: 16, fontWeight: 'bold', marginRight: 10 }}>
                                                {val?.activityId || val?.content?.map((c: any) => c?.item + '(' + c?.amount + ' ' + c?.unit + ')').join(' + ')}
                                            </label>
                                        </Grid>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        let newAdvisoryData: [] = selectedModalData?.advisory;
                                        newAdvisoryData.splice(index, 1);
                                        setSelectedModalData({ ...selectedModalData, advisory: newAdvisoryData })
                                    }} style={{ top: -17, right: -15 }}>
                                        <DeleteIcon style={{ color: 'grey' }} fontSize='small' />
                                    </IconButton>
                                </Grid>
                            ))}
                        </div>

                        <Grid display={"flex"} justifyContent={"space-between"} my={5}>
                            <input type="button" value="Submit" style={{ width: '35%', padding: '20px', fontSize: 18, paddingTop: '15px', marginLeft: 15 }}
                                onClick={finalSubmit}
                            />
                            <input type="button" value="Delete" style={{ width: '35%', padding: '20px', fontSize: 18, paddingTop: '15px', marginRight: 15 }} onClick={onDelete} />
                        </Grid>
                    </form>

                </Grid>
            </Modal >

            <Modal open={advisoryModal} style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
            }}
                onClose={() => { setAdvisoryModal(false) }}
            >
                <Grid style={{
                    backgroundColor: '#fff',
                    padding: 12,
                    borderRadius: 12,
                    margin: 5,
                    alignSelf: 'center',
                    overflowY: 'auto',
                    width: '45%',
                    height: '60%',
                }}>
                    <Grid display={'flex'} item alignItems={'center'}>
                        <IconButton onClick={() => setAdvisoryModal(false)} ><ArrowBack /></IconButton>
                        <Typography m={1} variant='h5'>Add Advisory</Typography>
                    </Grid>

                    <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'} my={2}>
                        <label style={{ fontSize: 20, fontWeight: 'bold', marginRight: 15, marginBottom: 5, marginTop: 5, marginLeft: 2, }} >Type</label>
                        <select
                            onChange={(e) => {
                                if (e.target.value !== 'activity' && e.target.value !== 'irrigation') {
                                    setSelectedActivityOption(null)
                                    setAdvisoryItemData({ ...advisoryItemData, type: e.target.value, activityId: '' })
                                }
                                else
                                    setAdvisoryItemData({ ...advisoryItemData, type: e.target.value, content: [] })
                            }}
                            value={advisoryItemData?.type}
                            style={{
                                marginRight: 15, marginBottom: 5, marginTop: 5, height: 53, width: 500, fontSize: 21, padding: 2
                            }}
                        >
                            {typeOptions.map(({ value, label }, index) => <option value={value} >{label}</option>)}
                        </select>
                    </Grid>
                    <Grid my={4}>
                        <LocaleDropdown
                            value={selectedActivityOption}
                            title='Activity'
                            titleStyle={{ fontSize: 19, fontWeight: 'bold', width: '15%' }}
                            onChange={(selected: SingleValue<any>) => {
                                setSelectedActivityOption(selected)
                                setAdvisoryItemData({ ...advisoryItemData, activityId: selected.value, content: [] })
                            }}
                            isDisabled={advisoryItemData?.type !== 'activity' && advisoryItemData?.type !== 'irrigation'}
                            containerStyle={{ width: '99%' }}
                        />
                    </Grid>
                    <input
                        disabled={!selectedActivityOption}
                        type='button'
                        value="Add Parameters"
                        onClick={() => { setParameterData({ key: '', value: '' }); setparametersModal(true) }}
                        style={{
                            padding: '10px',
                            fontSize: 15,
                            marginTop: -10,
                            backgroundColor: 'grey',
                            color: '#fff',
                            border: 'none',
                            borderRadius: 5,
                            cursor: 'pointer',
                        }}
                    />

                    <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                        {advisoryItemData?.parameters && Object.keys(advisoryItemData?.parameters)?.map((key: any) => (
                            <Grid my={2} boxShadow={2} borderRadius={3} px={2} pb={2} pt={2} display={'flex'} justifyContent={'space-between'}>

                                <IconButton style={{ backgroundColor: 'transparent' }} size='medium'
                                    onClick={() => {
                                        setParameterData({ key: key, value: advisoryItemData?.parameters[key] })
                                        setparametersModal(true);
                                    }}
                                >
                                    <Grid
                                        display="flex"
                                        alignItems={'center'}
                                    >
                                        <label style={{ fontSize: 16, fontWeight: 'bold', marginRight: 10 }}>
                                            {key}: {advisoryItemData?.parameters[key]}
                                        </label>
                                    </Grid>
                                </IconButton>
                                <IconButton onClick={() => {
                                    const parameters = { ...advisoryItemData?.parameters }
                                    delete parameters[key]
                                    setAdvisoryItemData({ ...advisoryItemData, parameters: parameters });
                                }} style={{ top: -17, right: -15 }}>
                                    <DeleteIcon style={{ color: 'grey' }} fontSize='small' />
                                </IconButton>
                            </Grid>
                        ))}
                    </div>

                    <Grid mx={'auto'} width={30}>
                        <h3>OR</h3>
                    </Grid>

                    <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <label style={{ fontSize: 20, fontWeight: 'bold', marginRight: 15, marginBottom: 5, marginTop: 5, marginLeft: 2, }} >Content</label>
                        <IconButton onClick={() => {
                            setUpdatedContentIndex(-1);
                            setContentData({
                                item: '',
                                amount: 0,
                                unit: ''
                            });
                            setSelectedContentOption(null);
                            setContentModal(true)
                        }}>
                            <AddBox />
                        </IconButton>
                    </Grid>

                    <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap', }}>
                        {advisoryItemData?.content?.length > 0 && advisoryItemData?.content?.map((val: any, index: number) => (
                            <Grid my={2} boxShadow={2} borderRadius={3} px={2} pb={2} pt={2} display={'flex'} justifyContent={'space-between'}>
                                <IconButton style={{ backgroundColor: 'transparent', }} size='medium' onClick={() => {
                                    setContentData(val);
                                    setSelectedContentOption({ label: val?.item, value: val?.item })
                                    setUpdatedContentIndex(index);
                                    setContentModal(true);
                                }}>
                                    <Grid
                                        alignItems={'center'}
                                        display={'flex'}
                                    >
                                        <label style={{ fontSize: 16, fontWeight: 'bold', marginRight: 10 }}>
                                            {val?.item} ({val?.amount} {val?.unit})
                                        </label>
                                    </Grid>
                                </IconButton>
                                <IconButton onClick={() => {
                                    let newContentData: [] = advisoryItemData?.content;
                                    newContentData.splice(index, 1);
                                    setAdvisoryItemData({ ...advisoryItemData, content: newContentData })
                                }} style={{ top: -17, right: -15 }}>
                                    <DeleteIcon style={{ color: 'grey' }} fontSize='small' />
                                </IconButton>
                            </Grid>
                        ))}
                    </div>

                    <Grid container justifyContent="space-between" style={{ marginTop: 55 }}>
                        <input
                            type="button"
                            value="Submit"
                            onClick={() => {
                                let newAdvisoryData = selectedModalData?.advisory || [];
                                if (updatedAdvisoryIndex > -1) {
                                    newAdvisoryData[updatedAdvisoryIndex] = advisoryItemData;
                                    setUpdatedAdvisoryIndex(-1);
                                }
                                else {
                                    newAdvisoryData.push(advisoryItemData);
                                }
                                setSelectedModalData({ ...selectedModalData, advisory: newAdvisoryData });
                                setAdvisoryModal(false)
                            }}
                            style={{
                                width: '42%',
                                padding: '10px',
                                fontSize: 18,
                                backgroundColor: '#4caf50',
                                color: '#fff',
                                border: 'none',
                                borderRadius: 5,
                                cursor: 'pointer',
                            }}
                        />
                        <input
                            type="button"
                            value="Delete"
                            onClick={() => setAdvisoryModal(false)}
                            style={{
                                width: '42%',
                                padding: '10px',
                                fontSize: 18,
                                backgroundColor: '#f44336',
                                color: '#fff',
                                border: 'none',
                                borderRadius: 5,
                                cursor: 'pointer',
                            }}
                        />
                    </Grid>

                </Grid>
            </Modal >

            <Modal open={contentModal} style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
            }}
                onClose={() => { setContentModal(false) }}
            >
                <Grid style={{
                    backgroundColor: '#fff',
                    padding: 12,
                    borderRadius: 12,
                    margin: 5,
                    alignSelf: 'center',
                    overflowY: 'auto',
                    width: '45%',
                    height: '52%'
                }}>
                    <Grid display={'flex'} item alignItems={'center'} mb={3}>
                        <IconButton onClick={() => setContentModal(false)} ><ArrowBack /></IconButton>
                        <Typography m={1} variant='h5'>Add Content</Typography>
                    </Grid>
                    <h3 style={{ margin: 5 }} >Item</h3>
                    <Select
                        options={chemicalOptions}
                        onChange={(selected: SingleValue<any>) => {
                            setSelectedContentOption(selected);
                            setContentData({ ...contentData, item: selected.value })
                        }}
                        value={selectedContentOption}
                        isClearable={true}
                        isSearchable={true}
                        placeholder={'- select -'}
                        styles={{
                            container: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                width: '97%',
                                marginLeft: '5px',
                            }),
                            control: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                height: 53,
                                borderColor: '#000'
                            }),
                            menu: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                zIndex: 100
                            })
                        }}
                    />

                    <Grid display={'flex'} justifyContent={'space-between'} my={4}>
                        <Grid>
                            <h3 style={{ margin: 5 }} >Amount</h3>
                            <input onChange={(e) => setContentData({ ...contentData, amount: Number(e.target.value) })} value={contentData?.amount} type="number" min={0} style={{ width: '80%', padding: '20px', fontSize: 18, paddingTop: '15px', margin: '3px', marginBottom: 11 }} ></input><br />
                        </Grid>
                        <Grid>
                            <h3 style={{ margin: 5 }} >Unit</h3>

                            <select onChange={(e) => setContentData({ ...contentData, unit: e.target.value })} value={contentData?.unit}
                                style={{
                                    marginRight: 15, marginBottom: 5, marginTop: 5, height: 53, width: 300, fontSize: 21, padding: 2
                                }}
                            >
                                {unitOptions.map(({ value, label }, index) => <option value={value} >{label}</option>)}
                            </select>

                        </Grid>
                    </Grid>

                    <Grid display={'flex'} justifyContent={'center'}>
                        <IconButton onClick={() => {
                            let newContentData = advisoryItemData?.content || [];
                            if (updatedContentIndex > -1) {
                                newContentData[updatedContentIndex] = contentData;
                                setUpdatedContentIndex(-1);
                            }
                            else {
                                newContentData.push(contentData);
                            }
                            setAdvisoryItemData({ ...advisoryItemData, content: newContentData, activityId: '' });
                            setSelectedActivityOption(null);
                            setContentModal(false)
                        }}>
                            <CheckCircleOutlineIcon style={{ color: 'green' }} fontSize='medium' />
                        </IconButton>
                        <IconButton onClick={() => setContentModal(false)}>
                            <CancelIcon style={{ color: 'red' }} fontSize='medium' />
                        </IconButton>
                    </Grid>

                </Grid>
            </Modal>

            <Modal open={parametersModal} style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
            }}
                onClose={() => { setparametersModal(false) }}
            >
                <Grid style={{
                    backgroundColor: '#fff',
                    padding: 12,
                    borderRadius: 12,
                    margin: 5,
                    alignSelf: 'center',
                    overflowY: 'auto',
                    width: '45%',
                    height: '52%'
                }}>
                    <Grid display={'flex'} item alignItems={'center'} mb={3}>
                        <IconButton onClick={() => setparametersModal(false)} ><ArrowBack /></IconButton>
                        <Typography m={1} variant='h5'>Add Parameter</Typography>
                    </Grid>


                    <Grid display={'flex'} flexDirection={'row'} justifyContent={'center'} mx={'auto'} width={'90%'} mb={3}>
                        <Typography fontSize={18} fontWeight={'800'} marginRight={1}>Activity:</Typography>
                        <Typography fontSize={18} fontWeight={'800'}>{selectedActivityOption?.label}</Typography>

                    </Grid>

                    <Grid display={'flex'} flexDirection={'row'} justifyContent={'space-between'} width={'95%'} mx={'auto'}>
                        <Grid>
                            <Typography>Key</Typography>
                            <input value={parameterData.key} onChange={(e) => setParameterData({ ...parameterData, key: e.target.value })} type='text' style={{ fontSize: 18, padding: 8 }}></input>
                        </Grid>
                        <Grid>
                            <Typography>Value</Typography>
                            <input value={parameterData.value} onChange={(e) => setParameterData({ ...parameterData, value: e.target.value })} type='text' style={{ fontSize: 18, padding: 8 }} ></input>
                            <IconButton
                                style={{ marginLeft: 14 }}
                                onClick={() => {
                                    if (parameterData.key.length > 0 && parameterData.value.length > 0) {
                                        const parameters = { ...advisoryItemData.parameters, [parameterData.key]: parameterData.value }
                                        setAdvisoryItemData({ ...advisoryItemData, parameters: parameters })
                                        setParameterData({ key: '', value: '' })
                                    }
                                }}>
                                <AddCircleOutlineIcon color='success' />
                            </IconButton>
                        </Grid>

                    </Grid>

                    <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
                        {advisoryItemData?.parameters && Object.keys(advisoryItemData?.parameters)?.map((key: any) => (
                            <Grid my={2} boxShadow={2} borderRadius={3} px={2} pb={2} pt={2} display={'flex'} justifyContent={'space-between'}>

                                <IconButton style={{ backgroundColor: 'transparent' }} size='medium'
                                    onClick={() => {
                                        setParameterData({ key: key, value: advisoryItemData?.parameters[key] })
                                    }}
                                >
                                    <Grid
                                        display="flex"
                                        alignItems={'center'}
                                    >
                                        <label style={{ fontSize: 16, fontWeight: 'bold', marginRight: 10 }}>
                                            {key}: {advisoryItemData?.parameters[key]}
                                        </label>
                                    </Grid>
                                </IconButton>
                                <IconButton onClick={() => {
                                    const parameters = { ...advisoryItemData?.parameters }
                                    delete parameters[key]
                                    setAdvisoryItemData({ ...advisoryItemData, parameters: parameters });
                                }} style={{ top: -17, right: -15 }}>
                                    <DeleteIcon style={{ color: 'grey' }} fontSize='small' />
                                </IconButton>
                            </Grid>
                        ))}
                    </div>

                    <Grid display={'flex'} justifyContent={'center'}>
                        <IconButton onClick={() => setparametersModal(false)}>
                            <CheckCircleOutlineIcon style={{ color: 'green' }} fontSize='medium' />
                        </IconButton>
                        <IconButton onClick={() => setparametersModal(false)}>
                            <CancelIcon style={{ color: 'red' }} fontSize='medium' />
                        </IconButton>
                    </Grid>

                </Grid>
            </Modal>

        </Grid >
    )


}

export default LaiAdvisory