import { Collapse, Alert, IconButton, AlertColor } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React from "react";

interface Props{
  isOpen: boolean;
  close: any;
  message: string;
  type: AlertColor;
  messageFor: string;
}
const AlertMessage: React.FC<Props> = (props: Props): JSX.Element => {
  const { isOpen, close, message, type, messageFor } = props
  return (
    <Collapse in={isOpen}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => close(messageFor)}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ my: 2 }}
        severity={type}
      >
        {message}
      </Alert>
    </Collapse>
  )
}

export default AlertMessage