import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { DialogConfirmProps } from './types';

const DialogConfirm: React.FC<DialogConfirmProps> = (props) => {
  const { title, content, onCancel, onConfirm, open, setOpen } = props;
  
  const handleClose = () => {
    setOpen(false);
  }

  const handleCancel = () => {
    onCancel();
    handleClose();
  }

  const handleConfirm = () => {
    handleClose();
    onConfirm();
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button variant='outlined' style={{ borderColor: 'red', color: 'red' }} onClick={handleCancel}>Cancel</Button>
          <Button variant='contained' style={{ backgroundColor: 'green', color: 'white' }} onClick={handleConfirm}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DialogConfirm;