import { getDate } from "src/utils/helper";
import moment from "moment";
import { ActivityTypes, ActivityTypesState } from "./types";
import { Chart } from "chart.js";

export const getRecentMonth = (): { toDate: string; fromDate: string } => {
  const toDate = getDate(new Date(), "MM/DD/YYYY");
  const fromDate = moment(toDate)
    .subtract(1, "months")
    .startOf("month")
    .format("MM/DD/YYYY");
  return {
    toDate,
    fromDate,
  };
};

export const getActivityData = (farmActivities: any[]) => {
  const updatedActivityTypes: ActivityTypesState = {
    suggestedDone: [],
    notSuggestedDone: [],
    suggestedPending: [],
    all: [],
  };

  // categorize the activities
  farmActivities.forEach((activity: any) => {
    if (activity.suggested && activity.done) {
      updatedActivityTypes.suggestedDone.push(activity);
    } else if (!activity.suggested && activity.done) {
      updatedActivityTypes.notSuggestedDone.push(activity);
    } else {
      updatedActivityTypes.suggestedPending.push(activity);
    }
  });

  // take count of each activity category: displayed on outer pie chart
  const outerChartData = Object.keys(updatedActivityTypes).map(
    (label: any) => (updatedActivityTypes as any)[label].length
  );

  // take summation of all activities: displayed in inner pie chart
  const innerChartData = [outerChartData.reduce((acc, curr) => acc + curr, 0)];

  const pieChartData = {
    labels: [...Object.keys(updatedActivityTypes)],
    datasets: [
      {
        data: outerChartData,
        backgroundColor: [
          "#E4F1FF", // suggestedDone color
          "#C7E2FF", // notSuggestedDone color
          "#A9D4FF", // suggestedPending color
        ],
      },
      {
        data: innerChartData,
        backgroundColor: ["#8BC6FF"],
      },
    ],
  };

  return { updatedActivityTypes, pieChartData };
};

export const generateBarChartOptions = () => {
  let delayed: boolean;
  return {
    responsive: true,
    // tooltip for all datasets
    // not using because onClick works only for months, not for each activity type of that month
    // interaction: {
    //   intersect: false,
    //   mode: 'index',
    // },

    // delayed animation for renering chart
    animation: {
      onComplete: () => {
        delayed = true;
      },
      delay: (context: any) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default" && !delayed) {
          delay = context.dataIndex * 300 + context.datasetIndex * 100;
        }
        return delay;
      },
    },
    plugins: {
      legend: {
        position: "bottom" as const,
      },
      title: {
        display: true,
      },

      // tooltips bottom: total count
      tooltip: {
        padding: 10,
        callbacks: {
          // footer: (tooltipItems: any) => {
          //   const sum = tooltipItems.reduce((accumulator: number, tooltipItem: any) => accumulator + tooltipItem.parsed.y, 0);
          //   return 'Total: ' + sum;
          // },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 5,
          minRotation: 0,
          maxRotation: 0,
        },
        stacked: true,
      },
      y: {
        title: {
          color: "green",
          display: true,
        },
        gridLines: {
          display: false,
          color: "rgba(0, 0, 0, 0)",
        },
        stacked: true,
      },
    },
  };
};

export const formatResponse = (
  data: any[]
): { barChartData: any[]; multiSeriesPieChartData: any[] } => {
  /*
    bar chart data
    counts activites frequency in each month
    06/2023: { irrigation: 10, spray: 60, ...other activites }
  */
  const barChartData = data.reduce((acc, current) => {
    if(!current.doneDate) {
      return acc;
    }
    let date = getDate(current.doneDate, 'MM/YYYY');
    const activityType = current.farmActivityTypeId;
    if (acc.hasOwnProperty(date) && acc[date].hasOwnProperty(activityType)) {
      acc[date][activityType] += 1;
    } else if (acc.hasOwnProperty(date)) {
      acc[date][activityType] = 1;
    } else {
      acc[date] = {};
      acc[date][activityType] = 1;
    }
    return acc;
  }, {});

  // multi series pie chart data
  const requiredRegions: string[] = ["AdministrativeArea", "Country", "Region"];
  const multiSeriesPieChartData = data.reduce((acc, current) => {
    const location = current?.plot?.plotLocation?.location;
    if (!acc.regionDistribution) {
      acc.regionDistribution = {
        state: [],
        country: [],
        continent: [],
      };
    }
    for (const regionType in location) {
      if (requiredRegions.includes(regionType)) {
        const region = location[regionType];
        // continent, country and state
        const name = region.EnglishName;
        acc = incrementCount(acc, name);

        if (regionType === "AdministrativeArea") {
          acc.regionDistribution.state.push(name);
        } else if (regionType === "Country") {
          acc.regionDistribution.country.push(name);
        } else {
          acc.regionDistribution.continent.push(name);
        }
      }
    }

    return acc;
  }, {});

  return {
    barChartData,
    multiSeriesPieChartData,
  };
};

const incrementCount = (data: any, key: string) => {
  if (data.hasOwnProperty(key)) {
    data[key] += 1;
  } else {
    data[key] = 1;
  }
  return data;
};

export const formatInKeyValue = (data: any) => {
  const result = [];
  for (const key in data) {
    result.push({
      key,
      value: data[key],
    });
  }

  return result;
};

export const generatePieChartOptions = (handlePieChartClick: any) => {
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    onClick: handlePieChartClick,
    plugins: {
      legend: {
        position: "bottom" as const,
        display: true,
        labels: {
          generateLabels: function (chart: any) {
            // Get the default label list
            const original =
              Chart.overrides.pie.plugins.legend.labels.generateLabels;
            const labelsOriginal = original.call(this, chart);

            // Build an array of colors used in the datasets of the chart
            let datasetColors = chart.data.datasets.map(function (e: any) {
              return e.backgroundColor;
            });

            datasetColors = datasetColors.flat();

            // Modify the color and hide state of each label
            labelsOriginal.forEach((label: any) => {
              // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
              label.datasetIndex = (label.index - (label.index % 2)) / 2;

              // The hidden state must match the dataset's hidden state
              label.hidden = !chart.isDatasetVisible(label.datasetIndex);

              label.text = beautifyMultiseriesPieChartLabel(label.text);

              // Change the color to match the dataset
              label.fillStyle = datasetColors[label.index];
            });

            return labelsOriginal;
          },
        },
        onClick: function (_: any, legendItem: any, legend: any) {
          // toggle the visibility of the dataset from what it currently is
          // legend.chart.getDatasetMeta(
          //   legendItem.datasetIndex
          // ).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
          // legend.chart.update();
        },
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const labelIndex = context.dataIndex;
            const layer = context.datasetIndex;
            const label =
              layer === 1
                ? ActivityTypes.all
                : Object.keys(ActivityTypes)[labelIndex];

            return (
              beautifyMultiseriesPieChartLabel(label as any) +
              ": " +
              context.formattedValue
            );
          },
        },
      },
    },
    tooltips: {
      enabled: true,
      mode: "index",
    },
  };

  return chartOptions;
};

export const beautifyMultiseriesPieChartLabel = (label: ActivityTypes) => {
  switch (label) {
    case ActivityTypes.all:
      return "All";
    case ActivityTypes.notSuggestedDone:
      return "Not Suggested Done";
    case ActivityTypes.suggestedDone:
      return "Suggested Done";
    case ActivityTypes.suggestedPending:
      return "Suggested Pending";
    default:
      return "NA";
  }
};
