import { clearAccessToken, deleteData, getData, postData } from "./common.service";
import { APP_API, CORE_API, PAYMENT_API } from "../constants/api";

export async function getPlotInsights() {
  return getData(`/plots/insights`, {
    basePath: CORE_API,
    sendTokenInHeader: true
  })
}

export async function getAllLeads(){
  const qs = {
    fields: ['id', 'contactName', 'contactPhoneNumber','location', 'servicedBy', 'followUpDate', 'comment', 'status', 'date'],
    order: 'created_date DESC'
  }
  const url = "/leads?filter=" + JSON.stringify(qs);
  // const url = "/leads";
  return getData(url, {
    sendTokenInUrl: true,
    basePath: CORE_API,
  });
}

export async function getLeadsByPagination(
  skip: number,
  filters: any[] = []
) {

  const qs:any = {
    fields: ['id', 'contactName', 'contactPhoneNumber','location', 'servicedBy', 'followUpDate', 'comment', 'status', 'date', 'created_date'],
    order: 'created_date DESC',
    limit: 15,
    skip: skip,
  }
  if (filters.length) {
    qs.where = { and: filters }
  }

  return getData('/leads?filter=' + JSON.stringify(qs), {
    basePath: CORE_API,
    sendTokenInHeader: true
  })
}

export async function getLeadsCount(filters: any[] = []
) {
  let url = `/leads/count`;
  if (filters.length) {
    url += `?where=` + JSON.stringify({ and: filters })
  }
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export async function getOpenLeads(){
  const qs = {
    fields: ['servicedBy'],
    where: { 
      status: 'open'
    },
    order: "created_date DESC"
  }
  const url = "/leads?filter=" + JSON.stringify(qs);
  return getData(url, {
    sendTokenInUrl: true,
    basePath: CORE_API,
  });
}

export async function getLeadDetails(id: string){
  return getData(`/leads/${id}`, {
    sendTokenInUrl: true,
    basePath: CORE_API,
  });
}

export async function updateLead(payload: any) {
  const {
    Id,
    name,
    contact,
    location,
    servicedBy,
    comment,
    status,
    followUpDate
  } = payload;
  const url = `/leads/${Id}`;

  return postData(url, {
    sendTokenInUrl: true,
    usePutMethod: true,
    basePath: CORE_API,
    body: {
      id: Id,
      contactName: name,
      contactPhoneNumber: contact, 
      location: location, 
      servicedBy: servicedBy,
      comment: comment, 
      status: status ?? "new", 
      followUpDate: followUpDate, 
    },
  });
}

export async function postLead(payload: any, bulkUpload:boolean = false) {

  const url = `/leads`;
  if(bulkUpload){
    return postData(url, {
      sendTokenInUrl: true,
      basePath: CORE_API,
      body: payload,
      });
    }


    const {
      id, 
      followUpDate,
      name,
      contact,
      location,
      servicedBy,
      comment,  
      status
    } = payload;

  return postData(url, {
    sendTokenInUrl: true,
    basePath: CORE_API,
    body: [{
      id: id,
      followUpDate:followUpDate,
      contactName: name,
      contactPhoneNumber: contact, 
      location: location, 
      servicedBy: servicedBy,
      comment: comment, 
      status: status, 
    }],
  });
}

export async function deleteLead(id: any) {
  const url = `/leads/${id}`;

  return deleteData(url, {
    sendTokenInUrl: true,
    useDeleteMethod: true,
    basePath: CORE_API,
  });
}


export async function getTargetByRegion(regionId: string, month: string, year: string) {
  const qs = {
    where: {
      region: regionId,
      month: month,
      year: year
    }
  }
  const url = `/targets?filter=${JSON.stringify(qs)}`;

  return getData(url, {
    sendTokenInUrl: true,
    basePath: CORE_API,
  });
}

export async function postTarget(payload: any) {
  const url = '/targets';

  const {
    month,
    year,
    region,
    revenue, 
    installation, 
    collections
  } = payload;
  return postData(url, {
    sendTokenInUrl: true,
    basePath: CORE_API,
    
    body: {
      month: month,
      year: year,
      region: region,
      revenue: revenue, 
      installation: installation, 
      collections: collections
    },
  });
}

export async function updateTarget(payload: any) {
  
  const {
    id,
    month,
    year,
    region,
    revenue, 
    installation, 
    collections
  } = payload;

  const url = `/targets/${id}`;

  return postData(url, {
    sendTokenInUrl: true,
    basePath: CORE_API,
    usePatchMethod: true,
    body: {
      id: id,
      month: month,
      year: year,
      region: region,
      revenue: revenue, 
      installation: installation, 
      collections: collections
    },
  });
}

export async function getReportByMonth(month: string, year: string, skip: number, filters: any[] = []) {
  const qs:any = {
    where: {
      month: month,
      year: year
    },
    limit: 15,
    skip: skip,
  }
  if (filters.length) {
    qs.where = { and: filters }
  }
  const url = `/targets?filter=${JSON.stringify(qs)}`;
  return getData(url, {
    sendTokenInUrl: true,
    basePath: CORE_API,
  });
}

export async function getZohoReport(fromDate: string, toDate: string){
  

  const url= `/zoho-sales-report?fromDate=${new Date(fromDate).toISOString()}&toDate=${new Date(toDate).toISOString()}`;
  return getData(url, {
    sendTokenInHeader: true, 
    basePath: PAYMENT_API,
  })
}



