import { CORE_API } from "src/constants/api";
import { TAPIUniqueActivity, TActiveUsers, TUserActivityDetails } from "src/pages/UserEventsAnalysis/types";
import { getData } from "./common.service";

interface TActivityCount {
  _id: string;
  count: number;
}

export const fetchActiveUsers = (startTime: string = '', endTime: string = '', groupType: string = 'userId'): Promise<TAPIUniqueActivity[]> => {
  const url = `/user-events/unique-users?fromDate=${startTime}&endDate=${endTime}&groupType=${groupType}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  });
}

export const fetchDailyActiveUsers = (startTime: string = '', endTime: string = '', groupType: string = 'userId'): Promise<TActiveUsers> => {
  const url = `/user-events/active-users-count?fromDate=${startTime}&endDate=${endTime}&groupType=${groupType}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  });
}

export const fetchPopularActivities = (startTime: string = '', endTime: string = ''): Promise<TActivityCount[]> => {
  const url = `/user-events/range-count?fromDate=${startTime}&endDate=${endTime}`;

  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  });
}

export const fetchActivityDetailsByActivityId = (startTime: string, endTime: string, eventId: string, groupType: string = 'userId'): Promise<TUserActivityDetails> => {
  const url = `/user-events/user-data?fromDate=${startTime}&endDate=${endTime}&eventType=${eventId}&groupType=${groupType}`;

  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}