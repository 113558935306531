
export interface FormResponse {
  fromDate: string;
  toDate: string;
}

export interface ChartClickProps {
  dataOf: string,
  month: string,
  isRightClick?: boolean;
}

export enum ActivityTypes {
  suggestedDone = 'suggestedDone',
  notSuggestedDone = 'notSuggestedDone',
  suggestedPending = 'suggestedPending',
  all = 'all'
}

export type ActivityTypesState = {
  [key in ActivityTypes]: any[];
}

export interface BarChartActivities {
  key: string; // date
  value: { [key: string]: number }; // object with activity as key and its freq as value
}

export interface ActivityTypesChartData {
  labels: string[];
  datasets: {
    data: number[];
    backgroundColor: string[];
  }[]
}

export type RegionT = 'state' | 'country' | 'continent';