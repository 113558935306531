import { Modal, Box } from "@mui/material"
import { KeyValues } from "../../constants/interfaces"
import { postQuantityPrediction } from "../../services/plot.service"
import FormikApp from "../DynamicForm/"

interface Props {
	plotId: string,
	openModal: boolean;
	handleModalClose: () => void;
	appendNote: (values: any) => void;
}

const fields = [
	{
		id: "prediction",
		label: "Quantity Prediction",
		placeholder: "Quantity Prediction",
		type: "text",
		validationType: "",
		value: '',
		validations: [],
	},
	{
		id: "note",
		label: "Notes",
		placeholder: "Notes",
		type: "textarea",
		validationType: "",
		value: '',
		validations: [],
	},
]


const AddNotesModal = (props: Props): JSX.Element => {
	const { plotId, openModal, handleModalClose, appendNote } = props;

	const addQuantityPrediction = (values: any) => {
		const { prediction, note } = values
		const payload = {
			plotId,
			value: Number(prediction),
			note
		}
		postQuantityPrediction(payload)
			.then((res: any) => {
				appendNote(res)
				handleModalClose()
			})
	}


	return (
		<Modal
			open={openModal}
			onClose={handleModalClose}
			style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
		>
			<Box bgcolor={'white'} p={3} width={700}>
				<>
					<div style={{
						margin: '0px 0 15px 0',
						padding: '5px',
						borderRadius: '10px',
						display: 'flex',
						alignItems: 'center'
					}} >

						<FormikApp
							formFields={fields}
							onSubmit={(values: KeyValues) => addQuantityPrediction(values)}
							onReset={() => { }}
							filter={false}
							showReset={false}
							removeField={() => { }}
						/>
					</div>
				</>
			</Box>
		</Modal>
	)
}

export default AddNotesModal