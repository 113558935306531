import { Grid, IconButton, Modal, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select, { SingleValue } from "react-select";
import { getUserRegionCrop, updateUserRegionCrop } from '../services/region.service';
import { generateOptions, isArrayWithLength } from '../utils/helper';
import { AgGridReact } from 'ag-grid-react';
import { UserRegionCropColDef } from '../constants/columnDefs';
import AddBoxIcon from '@mui/icons-material/AddBox';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import {
    ColDef,
    GridOptions,
    GridReadyEvent,
    SideBarDef,
    SortChangedEvent,
    FilterChangedEvent,
} from 'ag-grid-community';
import RegionCrop from 'src/pages/RegionCrop';
import { Option } from 'src/constants/interfaces';
import { debounce } from 'lodash';
import { getUsers } from 'src/services/user.service';
import { DropDownProps } from 'src/pages/BusinessDashboard/components/CropFilterBusinessDashboard';

const UserCropRegion: React.FC = (): JSX.Element => {
    const gridRef = useRef<AgGridReact>(null);
    const gridStyle = useMemo(() => ({ height: '100%', width: '95%' }), []);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>("")
    const [userCrop, setUserCrop] = useState<any>();
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [regionData, setRegionData] = useState<any[]>([]);

    // users dropDown
    const [userOptions, setUserOptions] = useState<Option[]>();
    const [selectedUser, setSelectedUser] = useState<SingleValue<any>>(null);
    const [usersLoading, setUsersLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!!userId) {
            getUserCrop();
        }
    }, [userId])

    const gridOptions: GridOptions = {
        columnDefs: UserRegionCropColDef,
        rowData: userCrop?.regionCropMappings,
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true,
        animateRows: true,
        overlayLoadingTemplate:
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
        onSelectionChanged: (e) => {
            const selectedNodes = gridRef.current?.api.getSelectedNodes();
            if (selectedNodes) {
                const idArray = selectedNodes.map((data) => data?.data?.id);
                let regionCropMappingIds = userCrop?.regionCropMappingIds
                regionCropMappingIds = regionCropMappingIds?.filter((item: any) => !idArray?.includes(item))
                setRegionData(regionCropMappingIds);
                setDeleteModal(true);
            }
        },
    };

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            resizable: true,
            sortable: true,
            floatingFilter: true,
            menuTabs: ['filterMenuTab'],
        };
    }, []);

    const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
        return {
            toolPanels: ['filters'],
        };
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
    }, []);

    const handleColumnSort = (event: SortChangedEvent) => {
        // const columns = event.columnApi.getColumnState();
        // let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId
    }

    const handleColumnFilter = (event: FilterChangedEvent) => {
        if (event.afterFloatingFilter) { }
    }

    const onPaginationChanged = useCallback(() => { }, []);

    /**
     * @function
     * fetches crops for the current userId
     * */
    const getUserCrop = () => {
        getUserRegionCrop(userId)
            .then((res) => {
                setUserCrop(res[0])
            })
        setDeleteModal(false);
    }

    const onDelete = () => {
        updateUserRegionCrop(userCrop?.id, regionData)
            .then((res) => {
                if (res.message === 'Success') {
                    getUserCrop();
                }
                else
                    alert('some error occurred')
            });
        setDeleteModal(false);
    }

    /**
     * @function
     * triggered on selection of User in UserId dropdown
     * updates current userId
     * */
    const handleUserSelect = (selectedUser: SingleValue<any>) => {
        setSelectedUser(selectedUser);
        if (!!selectedUser)
            setUserId(selectedUser.value);
    }

    /**
     * @function
     * generates user options based on search query
     * */
    const fetchUserOptions = useCallback(
        debounce(async (query: string) => {
            try {
                setUsersLoading(true);
                const response = await getUsers(query);
                const newUserOptions = generateOptions(response, 'farmUserId', 'name');
                setUserOptions(newUserOptions);
            } catch (error) {
                console.error('Error fetching options:', error);
            } finally {
                setUsersLoading(false);
            }
        }, 300),
        []
    );

    /**
     * @function
     * triggered on input text change of userId dropdown
     * */
    const handleInputChange = (newValue: string) => {
        fetchUserOptions(newValue);
    };

    return (
        <Grid width="100%" my={2} mx="auto" paddingBottom={5}>
            <Grid p={2} borderRadius={2} style={{ backgroundColor: '#f5f5f5' }}>
                <Grid container alignItems="center">
                    <Typography variant="h6" style={{ marginLeft: 10 }}>User Crop Region</Typography>
                </Grid>
            </Grid>
            <div style={{ justifyContent: "center", padding: 20 }}>
                <Typography>User Id <span style={{ fontSize: 16, color: 'red' }} >*</span> </Typography>
                <Grid display={'flex'} flexDirection={'row'} alignItems={'center'} mb={3}>
                    <Select
                        options={userOptions}
                        onChange={handleUserSelect}
                        isLoading={usersLoading}
                        value={selectedUser}
                        onInputChange={handleInputChange}
                        filterOption={(option, value) => true} // to remove react-select's filtering
                        {...DropDownProps}
                    />
                    <IconButton onClick={() => setOpenModal(true)}>
                        <AddBoxIcon style={{ color: 'grey' }} fontSize='medium' />
                    </IconButton>
                </Grid>
                <div style={gridStyle} className="ag-theme-alpine">
                    {
                        isArrayWithLength(userCrop?.regionCropMappings) ?
                            <Grid>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={userCrop?.regionCropMappings}
                                    columnDefs={UserRegionCropColDef}
                                    onGridReady={onGridReady}
                                    defaultColDef={defaultColDef}
                                    sideBar={sideBar}
                                    animateRows={true}
                                    pagination={true}
                                    paginationPageSize={15}
                                    gridOptions={gridOptions}
                                    domLayout="autoHeight"
                                    onPaginationChanged={onPaginationChanged}
                                    onSortChanged={handleColumnSort}
                                    onFilterChanged={handleColumnFilter}
                                ></AgGridReact>
                            </Grid> :
                            <Grid>
                                <Typography variant='h6' textAlign={'center'} >No Data Found!</Typography>
                            </Grid>
                    }
                </div>
            </div>
            <Modal
                open={openModal}
                style={{ overflow: 'scroll' }}
                onClose={() => setOpenModal(false)}
            >
                <Grid width={'95%'} my={1} mx={'auto'} boxShadow={2} borderRadius={4} padding={2} style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }} >
                    <RegionCrop openModal={true} userCropData={userCrop} setuserCropData={setUserCrop} getUserCrop={getUserCrop} userId={userId} />
                </Grid>
            </Modal>
            {deleteModal && (
                <div style={{ justifyContent: "center", padding: 20 }}>
                    <input
                        type="button"
                        value="Delete"
                        onClick={onDelete}
                        style={{
                            width: '93%',
                            padding: '10px',
                            fontSize: 18,
                            backgroundColor: '#f44336',
                            color: '#fff',
                            border: 'none',
                            borderRadius: 5,
                            cursor: 'pointer',
                            alignSelf: 'center'
                        }}
                    />
                </div>
            )}
        </Grid>


    )
}


export default UserCropRegion