import { LOCATION_API, CORE_API } from "../constants/api";
import { getData, postData } from "./common.service";

export const getCountries = async() => {
  const url = '/regions/countries'
  return getData(url, {
    sendTokenInHeader: true, 
    basePath: CORE_API
  })
}

export const getStates = async (country: string = 'India') => {
  const url = `/regions/states?country=${country}`
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getDistricts = async (state: string) => {
  const url = `/regions/districts?state=${state}`
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getSubDistricts = async (state: string, district: string) => {
  const url = `/regions/sub-districts?state=${state}&district=${district}`
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getAreas = async (state: string, district: string, subDistrict: string) => {
  const url = `/regions/areas?state=${state}&district=${district}&subDistrict=${subDistrict}`
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const getLocation = async (payload: any) => {
  const url = '/plot/geography';
  return postData(url, {
    sendTokenInUrl: true,
    basePath: LOCATION_API,
    body: payload
  })
  
}

export const getPlots = async (payload: any) => {
  let url = '/plots/by-location';
  if(payload.country){
    url+=`?country=${payload.country}`
  }
  if(payload.state) {
    url+=`&state=${payload.state}`
  }
  if(payload.district) {
    url+=`&district=${payload.district}`
  }
  if(payload.subDistrict) {
    url+=`&subDistrict=${payload.subDistrict}`
  }
  if(payload.area) {
    url+=`&area=${payload.area}`
  }
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
  
}