import {
  Button, Chip,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import { ChartData } from "chart.js";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import Select, { SingleValue } from "react-select";
import { DatePicker } from "src/components";
import {
  getCropDiseases,
  getCropDiseasesPrediction,
  getCropOptions,
  getCropPestPrediction,
  getCropPests,
} from "src/services/crop.service";
import { getAllFarmDataBetweenDates } from "src/services/farmData.service";
import {
  getHistoricalDiseasePrediction,
  getHistoricalPestPrediction,
} from "src/services/plot.service";
import { downloadDailyRegionDataForCharts, getDailyRegionDataForCharts, getHourlyRegionDataForCharts, getPestDiseasePredictionForCharts, getRegionDataFromRegionId } from "src/services/region.service";
import { getLatestWeatherForecast } from "src/services/weather.service";
import { generateOptions, removeUnderScores } from "src/utils/helper";
import { GraphPlots, GraphPlotsData, GraphType } from "..";
import { MapSensorFilter } from "../components";

type Props = {
  graphPlots: GraphPlots;
  graphType: GraphType;
  graphSubType: string;
  mapsDate: any;
  getPredictionData: any;
  showgetPrediction: any;
  setGetPredictionData: any;
  isRegion?: boolean;
  cropId?: string;
  hotspot?: boolean;
};

const colors = [
  "#006d77",
  "#e29578",
  "#ff99c8",
  "#a9def9",
  "#e4c1f9",
  "#95d5b2",
  "#74c69d",
  "#52b788",
  "#40916c",
  "#2d6a4f",
  "#1b4332",
  "#081c15",
];
const totalColors = colors.length;

type TimeFrame = "day" | "week" | "month" | "custom" | 'default';

const getChartOptions = (): any => {
  return {
    plugins: {
      legend: {
        position: "left",
        align: "start",
        labels: {
          usePointStyle: true,
          padding: 15,
          pointStyle: "rectRounded",
          font: {
            size: 9,
          },
        },
      },
    },
    elements: {
      point: {
        borderWidth: 10,
        hoverRadius: 6,
        hitRadius: 6,
        hoverBorderWidth: 1,
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          color: "green",
          display: true,
          text: "TIMESTAMP",
          font: { size: 14 },
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          minRotation: 90,
          maxRotation: 90,
          align: "center",
        },
        beginAtZero: false,
        offset: true,
      },
      // y: { offset: true },
    },
  };
};
const Loader = () => (
  <Grid
    position={"absolute"}
    top={0}
    left={0}
    right={0}
    bottom={-50}
    bgcolor={"rgba(255, 255, 255, 0.7)"}
    display={"flex"}
    justifyContent={"center"}
    alignItems={"center"}
  >
    <CircularProgress color="success" />
  </Grid>
);

const MapCharts: React.FC<Props> = (props) => {
  const {
    graphPlots,
    graphType,
    mapsDate,
    graphSubType,
    setGetPredictionData,
    showgetPrediction,
    getPredictionData,
    isRegion,
    cropId,
    hotspot
  } = props;

  const [timeFrame, setTimeFrame] = useState<TimeFrame>("default");

  const [crops, setCrops] = useState<SingleValue<any>>([]);
  const [isCropLoading, setIsCropLoading] = useState<boolean>(true);
  const [selectedCropOption, setSelectedCropOption] = useState<any>({
    label: "Apple",
    value: "apple",
  });
  const [diseases, setDiseases] = useState<SingleValue<any>>([]);
  const [isDiseasesLoading, setIsDiseasesLoading] = useState<boolean>(false);
  const [selectedDiseasesOption, setSelectedDiseasesOption] = useState<any>({
    label: "Marssonina blotch",
    value: "marssonina_blotch",
  });
  const [pest, setPest] = useState<SingleValue<any>>([]);
  const [isPestLoading, setIsPestLoading] = useState<boolean>(false);
  const [selectedPestOption, setSelectedPestOption] = useState<any>(null);

  const [graphData, setgraphData] = useState<any>();
  const [graphSensorData, setgraphSensorData] = useState<any>();
  const [GRAPH_DATA_ENV, SET_GRAPH_DATA_ENV] = useState<any>();
  const [fromDate, setFromDate] = useState<any>(
    moment(mapsDate.current, "DD/MM/YYYY").add(-30, "day").toDate()
  );
  const [toDate, setToDate] = useState<any>(
    moment(mapsDate.current, "DD/MM/YYYY").add(1, "day").toDate()
  );

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSensorChartLoading, setIsSensorChartLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!toDate || !fromDate) {
      setErrorMessage("Please select required dates");
      return;
    }

    if (moment(toDate) < moment(fromDate)) {
      setErrorMessage("From date must be before To date");
      return;
    }

    setErrorMessage("");
    showResults();
  }, [toDate, fromDate, selectedDiseasesOption, selectedPestOption]);

  useEffect(() => {
    setToDate(moment(mapsDate.current, "DD/MM/YYYY").add(1, "day").toDate());
    switch (timeFrame) {
      case "day":
        setFromDate(
          moment(mapsDate.current, "DD/MM/YYYY").add(0, "day").toDate()
        );
        break;
      case "week":
        setFromDate(
          moment(mapsDate.current, "DD/MM/YYYY").add(-6, "day").toDate()
        );
        break;
      case "month":
        setFromDate(
          moment(mapsDate.current, "DD/MM/YYYY").add(-30, "day").toDate()
        );
        break;
      default:
        return;
    }
  }, [timeFrame]);

  useEffect(() => {
    if (!graphType) {
      console.log("no graph type specified");
    }
    if (showgetPrediction) {
      handleDropdownChange("crop", { label: "Apple", value: "apple" });
      setSelectedDiseasesOption({ label: "Scab", value: "apple_scab" });
    }
    showResults();
  }, [graphPlots]);

  useEffect(() => {
    if (!!graphSensorData) {
      SET_GRAPH_DATA_ENV(generateGraphData(graphSensorData));
    }
  }, [graphSensorData]);

  const generateDataForPlot = (
    graphData: any[],
    targetPlotId: string
  ): number[] => {
    const data = graphData.reduce((acc: number[], data: any) => {
      const { plotId, value } = data;
      if (plotId === targetPlotId && typeof value === "number") {
        acc.push(value);
      }
      return acc;
    }, []);

    return data;
  };

  const generateGraphData = (graphData: any): any => {
    const isTodaysChart = moment(toDate).diff(fromDate) <= 1;
    const timestamps = [
      ...new Set(
        graphData.map((obj: any) => moment(obj.timestamp).format(isTodaysChart ? "LLL" : 'll'))
      ),
    ];

    let datasets: {
      label: string;
      data: number[];
      borderColor: string;
      backgroundColor: string;
      borderWidth: number;
      pointBackgroundColor: string;
    }[] = [];

    // to check whether current plotId is used or not
    let covered: {
      [key: string]: boolean;
    } = {};

    let index = 0;
    for (const data of graphData) {
      const { plotId } = data;
      if (!covered[plotId]) {
        const dataset = {
          label: removeUnderScores(plotId, 'capitalize') as string,
          data: generateDataForPlot(graphData, plotId),
          borderColor: colors[index % totalColors],
          backgroundColor: colors[index % totalColors],
          borderWidth: 3,
          pointBackgroundColor: colors[index % totalColors],
        };
        index++;
        covered[plotId] = true;
        datasets.push(dataset);
      }
    }

    return {
      labels: timestamps,
      datasets,
    };
  };

  const getRegionsDataForChart = async (graphSensor: string = '') => {
    const { regionId }: any = graphPlots.data[0]

    const to = dayjs(toDate)
    const today = dayjs()
    // const { plotId } = (graphPlots as any)

    let plotId: string;

    if (!!(graphPlots as any).plotId) {
      plotId = (graphPlots as any).plotId;
    } else {
      const regionData = await getRegionDataFromRegionId((graphPlots.data[0] as any).regionId)
      plotId = regionData[0].plotIds[0];
    }

    if (to.isAfter(today)) {
      const result: any[] = []
      const response: any = await getLatestWeatherForecast(plotId);
      if (!!response.dailyWeatherForecastData && response.dailyWeatherForecastData.length > 0) {
        let newData = response.dailyWeatherForecastData.map((val: any) => ({
          timestamp: val.timestamp,
          sensor: graphSensor,
          value: val[graphSensor]?.value ?? val[graphSensor]?.avg?.value,
          plotId,
        }));
        result.push(...newData);
      }

      return result;
    }

    const regionsData: any = timeFrame === 'day' ? await getHourlyRegionDataForCharts({
      ids: [regionId],
      fromDate: dayjs(fromDate).format('YYYY-MM-DDT00:30'),
      toDate: to.format('YYYY-MM-DDT17:30'), // to date is set to 17:30 utc, to balance out diff of +5:40 hrs
      fields: [graphSensor, 'timestamp'],
    }) : await getDailyRegionDataForCharts({
      ids: [regionId],
      fromDate: dayjs(fromDate).add(timeFrame === 'custom' ? -1 : 0, 'day').format('YYYY-MM-DDT00:30'),
      toDate: to.format('YYYY-MM-DDT17:30'), // to date is set to 17:30 utc, to balance out diff of +5:40 hrs
      fields: [graphSensor, 'timestamp'],
    })

    const regionsDataWithValue = regionsData.map((regionData: any) => ({
      ...regionData,
      plotId: graphSensor,
      value: regionData[graphSensor]
    }))

    return regionsDataWithValue;
  }

  const downloadRegionsDataChart = () => {
    const { regionId }: any = graphPlots.data[0]

    const to = dayjs(toDate)

    downloadDailyRegionDataForCharts({
      ids: [regionId],
      fromDate: dayjs(fromDate).format('YYYY-MM-DDT00:30'),
      toDate: to.format('YYYY-MM-DDT17:30'),
      fields: ["airTemp", "airHumidity", "soilTemp", "soilMoisture1", "soilMoisture2", "airPressure", "baroPressure", "lightIntensity", "leafWetness", "windSpeed", "rainFall", "MinSoilTemp", "MaxSoilTemp", "MinAirHumidity", "MaxAirHumidity", "MinAirTemp", "MaxAirTemp", "MinAirPressure", "MaxAirPressure", "MinBaroPressure", "MaxBaroPressure", "MinLux", "MaxLux", "MinWindSpeed", "MaxWindSpeed", "sunshineHours", "dayLength", "leafWetnessHours", 'timestamp'],
    }).then(res => {
      const reader: any = new FileReader();
      reader.readAsText(res);
      reader.onload = () => {
        const decoded = window.atob(reader.result.split(',')[1]);
        const blob = new Blob([decoded], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', `${regionId}_${dayjs(fromDate).format('MMM/DD/YYYY')}-${dayjs(toDate).format('MMM/DD/YYYY')}_Data.csv`);
        a.click();
      };
    })
  }

  const getPlotsDataForChart = async (graphSensor: string = '') => {
    const reqFromDate = moment(fromDate, "DD/MM/YYYY").toDate();
    const reqToDate = moment(toDate, "DD/MM/YYYY").add(1, "day").toDate();

    const result: any = []

    for (const plot of graphPlots?.data) {
      const { plotId } = plot as GraphPlotsData;
      const response: any = await getAllFarmDataBetweenDates({
        plotId,
        fromDate: reqFromDate,
        toDate: reqToDate,
      });

      if (!!response.data && response.data.length > 0) {
        let newData = response.data.map((val: any) => ({
          timestamp: val.timestamp,
          sensor: graphSensor,
          value: val[graphSensor],
          plotId,
        }));
        result.push(...newData);
      }
    }

    return result;
  }

  const getRegionsPestDataForChart = async (pestId: string) => {
    if (!!cropId) {
      const regionId = graphPlots.data.map((data: any) => data.regionId);
      const pestDatas: any[] = await getPestDiseasePredictionForCharts({
        regionId,
        cropId,
        type: 'pest',
        typeId: pestId,
        date: dayjs(fromDate).add(timeFrame === 'custom' ? -1 : 0, 'day').format('YYYY-MM-DDT00:30'),
        toDate: dayjs(toDate).add(1, 'days').format('YYYY-MM-DDT17:30')
      })

      const data = pestDatas.map(pestData => hotspot ?
        ({
          plotId: pestData.regionId,
          [pestId]: pestData.probability,
          value: pestData.probability,
          timestamp: pestData.date
        }) : ({
          plotId: pestId,
          [pestId]: pestData.probability,
          value: pestData.probability,
          timestamp: pestData.date
        }))

      return data
    }
  }

  const getRegionsDiseaseDataForChart = async (diseaseId: string) => {
    if (!!cropId) {
      const regionId = graphPlots.data.map((data: any) => data.regionId);
      const diseaseDatas: any[] = await getPestDiseasePredictionForCharts({
        regionId,
        cropId,
        type: 'disease',
        typeId: diseaseId,
        date: fromDate,
        toDate: toDate
      })

      const data = diseaseDatas.map(diseaseData => hotspot ?
        ({
          plotId: diseaseData.regionId,
          [diseaseId]: diseaseData.probability,
          value: diseaseData.probability,
          timestamp: diseaseData.date
        }) : ({
          plotId: diseaseId,
          [diseaseId]: diseaseData.probability,
          value: diseaseData.probability,
          timestamp: diseaseData.date
        }))

      return data
    }
  }

  const showResults = async () => {
    if (isRegion) {
      setIsLoading(true)
      let regionsData: any;

      switch (graphType) {
        case 'environment':
          regionsData = await getRegionsDataForChart(graphSubType)
          setgraphSensorData(regionsData)
          break;

        case 'pest':
          regionsData = await getRegionsPestDataForChart(graphSubType)
          setgraphData(regionsData)
          break;

        case 'disease':
          regionsData = await getRegionsDiseaseDataForChart(graphSubType)
          setgraphData(regionsData)
          break;

        default:
          return;
      }

      setIsLoading(false)
      return;
    }

    setIsLoading(true);
    const reqFromDate = moment(fromDate, "DD/MM/YYYY").toDate();
    const reqToDate = moment(toDate, "DD/MM/YYYY").add(1, "day").toDate();

    const graphSensor = graphSubType;
    const result: any = [];

    if (showgetPrediction) {
      let response: any;
      if (selectedDiseasesOption) {
        response = await getCropDiseasesPrediction(
          selectedCropOption?.value,
          selectedDiseasesOption?.value,
          reqFromDate,
          reqToDate,
          graphPlots.data
        );
      } else if (selectedPestOption) {
        response = await getCropPestPrediction(
          selectedCropOption?.value,
          selectedPestOption?.value,
          reqFromDate,
          reqToDate,
          graphPlots.data
        );
      }
      if (!!response) {
        let newData = Object.entries(response).map(
          ([key, val]: [any, any]) => ({
            timestamp: key,
            value: val.predictedIndex,
            sensor: selectedDiseasesOption?.label || selectedPestOption?.label,
            plotId: selectedDiseasesOption?.label || selectedPestOption?.label,
          })
        );
        result.push(...newData);
      }
      setGetPredictionData(result);
    } else {
      switch (graphType) {
        case "disease":
          for (const plot of graphPlots?.data) {
            const { plotId } = plot as GraphPlotsData;
            const response: any[] = await getHistoricalDiseasePrediction(
              plotId,
              reqFromDate,
              reqToDate
            );
            if (!!response && response.length > 0) {
              let newData = response?.find(
                (item: any) => item.id === graphSensor
              ).data;
              newData = newData.map((val: any) => ({
                timestamp: val.calculatedDate,
                sensor: graphSensor,
                value: val.probability,
                plotId,
              }));
              result.push(...newData);
            }
          }
          setgraphData(result);
          break;

        case "pest":
          for (const plot of graphPlots?.data) {
            const { plotId } = plot as GraphPlotsData;
            const response: any[] = await getHistoricalPestPrediction(
              plotId,
              reqFromDate,
              reqToDate
            );
            if (!!response && response.length > 0) {
              let newData = response?.find(
                (item: any) => item.id === graphSensor
              ).data;
              newData = newData.map((val: any) => ({
                timestamp: val.calculatedDate,
                sensor: graphSensor,
                value: val.probability,
                plotId,
              }));
              result.push(...newData);
            }
          }
          setgraphData(result);
          break;

        case "environment":
        case "crop":
          const resultSensor = await getPlotsDataForChart(graphSubType)
          setgraphData(resultSensor);
          break;

        case "future":
          for (const plot of graphPlots?.data) {
            const { plotId } = plot as GraphPlotsData;
            const response: any = await getLatestWeatherForecast(plotId);
            if (!!response.dailyWeatherForecastData && response.dailyWeatherForecastData.length > 0) {
              let newData = response.dailyWeatherForecastData.map((val: any) => ({
                timestamp: val.timestamp,
                sensor: graphSensor,
                value: val[graphSensor]?.value ?? val[graphSensor]?.avg?.value,
                plotId,
              }));
              result.push(...newData);
            }
          }

          setgraphData(result);
          break;

        default:
      }
    }
    setIsLoading(false);
  };

  const handleDropdownChange = (
    dropdown: string,
    selected: SingleValue<any>
  ) => {
    const value = selected?.value;

    switch (dropdown) {
      case "crop":
        if (value) {
          setSelectedCropOption(selected);
          getCropDiseases(value).then((res) => {
            setDiseases(generateOptions(res, "diseaseId", "name"));
            setIsDiseasesLoading(false);
          });
          getCropPests(value).then((res) => {
            setPest(generateOptions(res, "pestId", "name"));
            setIsPestLoading(false);
          });
        } else {
          setSelectedCropOption(null);
          setSelectedDiseasesOption(null);
          setSelectedPestOption(null);
        }
        break;
      case "disease":
        if (value) {
          setSelectedDiseasesOption(selected);
          setSelectedPestOption(null);
        } else {
          setSelectedDiseasesOption(null);
        }
        break;
      case "pest":
        if (value) {
          setSelectedPestOption(selected);
          setSelectedDiseasesOption(null);
        } else {
          setSelectedPestOption(null);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getCropOptions().then((res) => {
      setCrops(generateOptions(res, "id", "cropName"));
      setIsCropLoading(false);
    });
  }, []);

  const margin: any = {
    margin: "10px",
  };

  const hasGraphData = !!graphData && graphData.length > 0;
  const isDiseasePest = (graphType === 'disease' || graphType === 'pest')

  const showUpperGraph: boolean = hasGraphData && isDiseasePest


  return (
    <Grid my={3} px={2}>
      <Grid
        container
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        id={"MapCharts"}
        display={graphType === 'future' ? "none" : 'flex'}
      >
        <Grid container justifyContent={"center"}>
          <Button
            onClick={() => setTimeFrame("day")}
            variant={timeFrame === "day" ? "contained" : "text"}
            style={
              timeFrame !== "day"
                ? { color: "#888888", ...margin }
                : { backgroundColor: "green", ...margin }
            }
          >
            One Day
          </Button>
          <Button
            onClick={() => setTimeFrame("week")}
            variant={timeFrame === "week" ? "contained" : "text"}
            style={
              timeFrame !== "week"
                ? { color: "#888888", ...margin }
                : { backgroundColor: "green", ...margin }
            }
          >
            One Week
          </Button>
          <Button
            onClick={() => setTimeFrame("month")}
            variant={timeFrame === "month" ? "contained" : "text"}
            style={
              timeFrame !== "month"
                ? { color: "#888888", ...margin }
                : { backgroundColor: "green", ...margin }
            }
          >
            One Month
          </Button>
        </Grid>
        <Divider style={{ width: "200px", fontSize: "12px" }}>OR</Divider>
        <Grid>
          <Grid item mr={3} display={"flex"}>
            <DatePicker
              value={fromDate}
              onChange={(date) => {
                if (timeFrame === 'default') {
                  setTimeFrame('month')
                  return;
                }
                setFromDate(date);
                setTimeFrame("custom");
              }}
              label="From: "
              labelStyle={{ fontWeight: "bold" }}
              showCalendarIcon
              futurePred={graphType === 'future' || isRegion}
            />
            <DatePicker
              value={toDate}
              onChange={(date) => {
                if (timeFrame === 'default') {
                  setTimeFrame('month')
                  return;
                }
                setToDate(date);
                setTimeFrame("custom");
              }}
              label="To: "
              labelStyle={{
                fontWeight: "bold",
              }}
              showCalendarIcon
              futurePred={graphType === 'future' || isRegion}
            />
          </Grid>
          {!!errorMessage && (
            <Typography fontSize={"small"} color={"red"}>
              *{errorMessage}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container>
        {showgetPrediction && !!getPredictionData && (
          <Grid
            px={2}
            mr={10}
            boxShadow={4}
            width={350}
            height={250}
            borderRadius={6}
            marginTop={10}
          >
            <Grid
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginBottom: "1px",
              }}
            >
              <Typography variant="subtitle1" mr={3}>
                Crop
              </Typography>
              <Select
                options={crops}
                onChange={(selected: SingleValue<any>) =>
                  handleDropdownChange("crop", selected)
                }
                isLoading={isCropLoading}
                isDisabled={isCropLoading}
                value={selectedCropOption}
                isClearable={true}
                isSearchable={true}
                placeholder="- Select crop -"
                styles={{
                  container: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    width: "100%",
                    marginLeft: "5px",
                    marginTop: 16,
                    marginBottom: 16,
                  }),
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderTop: state.isFocused ? "1px solid #ccc" : "none",
                    borderLeft: state.isFocused ? "1px solid #ccc" : "none",
                    borderRight: state.isFocused ? "1px solid #ccc" : "none",
                    borderRadius: "5px",
                  }),
                  menu: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    zIndex: 100,
                  }),
                }}
              />
            </Grid>

            <Grid
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginBottom: "1px",
              }}
            >
              <Typography variant="subtitle1">Disease</Typography>
              <Select
                options={diseases}
                onChange={(selected: SingleValue<any>) =>
                  handleDropdownChange("disease", selected)
                }
                isLoading={isDiseasesLoading}
                isDisabled={!selectedCropOption || isDiseasesLoading}
                value={selectedDiseasesOption}
                isClearable={true}
                isSearchable={true}
                placeholder="- Select disease -"
                styles={{
                  container: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    width: "100%",
                    marginLeft: "5px",
                    marginTop: 16,
                    marginBottom: 16,
                  }),
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderTop: state.isFocused ? "1px solid #ccc" : "none",
                    borderLeft: state.isFocused ? "1px solid #ccc" : "none",
                    borderRight: state.isFocused ? "1px solid #ccc" : "none",
                    borderRadius: "5px",
                  }),
                  menu: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    zIndex: 100,
                  }),
                }}
              />
            </Grid>

            <Grid
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginBottom: "1px",
              }}
            >
              <Typography variant="subtitle1" mr={3.23}>
                Pest
              </Typography>
              <Select
                options={pest}
                onChange={(selected: SingleValue<any>) =>
                  handleDropdownChange("pest", selected)
                }
                isLoading={isPestLoading}
                isDisabled={!selectedCropOption || isPestLoading}
                value={selectedPestOption}
                isClearable={true}
                isSearchable={true}
                placeholder="- Select pest -"
                styles={{
                  container: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    width: "100%",
                    marginLeft: "5px",
                    marginTop: 16,
                    marginBottom: 16,
                  }),
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderTop: state.isFocused ? "1px solid #ccc" : "none",
                    borderLeft: state.isFocused ? "1px solid #ccc" : "none",
                    borderRight: state.isFocused ? "1px solid #ccc" : "none",
                    borderRadius: "5px",
                  }),
                  menu: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    zIndex: 100,
                  }),
                }}
              />
            </Grid>
          </Grid>
        )}
        {!!getPredictionData && showgetPrediction && (
          <Grid height={700} my={0} position={"relative"} display="flex">
            <Line
              data={
                generateGraphData(getPredictionData) as ChartData<
                  "line",
                  any,
                  unknown
                >
              }
              options={getChartOptions()}
              style={{
                height: "100%",
                width: "80%",
                margin: "auto",
              }}
            />

            {isLoading && <Loader />}
          </Grid>
        )}
      </Grid>

      {showUpperGraph &&
        !errorMessage && !showgetPrediction && (
          <Grid>
            {hotspot && <Grid container justifyContent={'center'} alignItems={'flex-end'} position={'relative'} top={15}>
              <Chip variant="outlined" size="small" color="success" label={removeUnderScores(graphType + ': ' + graphSubType, 'capitalize')} />
            </Grid>}
            <Grid height={500} my={4} position={"relative"} display="flex">
              <Line
                data={
                  generateGraphData(graphData) as ChartData<"line", any, unknown>
                }
                options={getChartOptions()}
                style={{
                  height: "100%",
                  width: "80%",
                  margin: "auto",
                }}
              />
              {isLoading && <Loader />}
            </Grid>

          </Grid>
        )}

      {!showgetPrediction && (
        <Grid m={2} pt={5}>
          <Grid display={'flex'} justifyContent={'space-between'}>
            <MapSensorFilter
              setGraphData={setgraphSensorData}
              fromDate={fromDate}
              toDate={toDate}
              plotIds={graphPlots.data?.map((plot: any) => plot.plotId)}
              setIsLoading={setIsSensorChartLoading}
              defaultSensor={(graphType === 'disease' || graphType === 'pest') ? 'airTemp' : (graphSubType || 'airTemp')}
              onSensorChange={isRegion ? getRegionsDataForChart : getPlotsDataForChart}
            />
            {isRegion && (
              <Button
                style={{
                  borderRadius: "20px",
                  border: "2px solid green",
                  backgroundColor: "white",
                  color: "green",
                  padding: "10px 20px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
                size="large"
                onClick={downloadRegionsDataChart}
              >
                Download
              </Button>
            )}
          </Grid>
          {!!GRAPH_DATA_ENV && (
            <Grid height={500} my={4} position={"relative"}>
              <Line
                data={
                  GRAPH_DATA_ENV as ChartData<
                    "line",
                    any,
                    unknown
                  >
                }
                options={getChartOptions()}
                style={{
                  height: "100%",
                  width: "80%",
                  margin: "auto",
                }}
              />

              {isSensorChartLoading && <Loader />}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default MapCharts;
