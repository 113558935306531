import { Grid, IconButton, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Mappings } from '../constants/WordMapping'
import FormikApp from '../components/DynamicForm'
import { Option } from '../constants/interfaces'
import { getAnalysisFilterResult } from '../services/plot.service'
import AnalysisComp from '../components/AnalysisComp'
import Select, { MultiValue } from "react-select";
import { getLocation } from "../services/location.service";
import { generateOptionsFromArray as generateOptions } from 'src/utils/helper'

const Analysis: React.FC = (): JSX.Element => {

  const [isApplied, setIsApplied] = useState(false)
  const [isError, setisError] = useState(false)


  // dropdown options
  const [states, setStates] = useState<MultiValue<any>>([])
  const [districts, setDistricts] = useState<MultiValue<any>>([])
  const [subDistricts, setSubdistricts] = useState<MultiValue<any>>([])
  const [area, setArea] = useState<MultiValue<any>>([])

  // selected locations
  const [selectedState, setSelectedState] = useState<string[]>([])
  const [selectedDistrict, setSelectedDistrict] = useState<string[]>([])
  const [selectedSubDistrict, setSelectedSubDistrict] = useState<string[]>([])
  const [selectedArea, setSelectedArea] = useState<string[]>([])

  // select options
  const [selectedStateOption, setSelectedStateOption] = useState<any>(null)
  const [selectedDistrictOption, setSelectedDistrictOption] = useState<any>(null)
  const [selectedSubDistrictOption, setSelectedSubDistrictOption] = useState<any>(null)
  const [selectedAreaOption, setSelectedAreaOption] = useState<any>(null)

  // loading states
  const [isStateLoading, setIsStateLoading] = useState<boolean>(true)
  const [isDistrictLoading, setIsDistrictLoading] = useState<boolean>(false)
  const [isSubDistrictLoading, setIsSubDistrictLoading] = useState<boolean>(false)
  const [isAreaLoading, setIsAreaLoading] = useState<boolean>(false)

  const [analysisData, setanalysisData] = useState<any>()
  const [isanalysisData, setisanalysisData] = useState<boolean>(false)

  const [sensorField, setsensorField] = useState<string[]>([])

  const [areaSensor, setareaSensor] = useState<string[]>([])
  const [subDistSensor, setsubDistSensor] = useState<string[]>([])
  const [districtSensor, setdistrictSensor] = useState<string[]>([])
  const [stateSensor, setstateSensor] = useState<string[]>([])

  const [areaName, setareaName] = useState<string[]>([])
  const [subDistrictName, setsubDistrictName] = useState<string[]>([])
  const [districtName, setdistrictName] = useState<string[]>([])
  const [stateName, setstateName] = useState<string[]>([])


  let funct: string[] = []
  let areasens: string[] = []
  let subdistrictsens: string[] = []
  let districtsens: string[] = []
  let statesens: string[] = []
  let arealoc: string[] = []
  let subdistrictloc: string[] = []
  let districtloc: string[] = []
  let stateloc: string[] = []

  useEffect(() => {
    getLocation({
      "region": "state"
    }).then(res => {
      const { state } = res
      setStates(generateOptions(state))
      setIsStateLoading(false)
    })
  }, [])

  const handleStateChange = (selected: string[]) => {
    setSelectedState(selected)

    setSelectedStateOption(generateOptions(selected))
    setIsDistrictLoading(true)
    getLocation({
      "region": "district",
      "location": {
        "state": selected
      }
    }).then(res => {
      const { district } = res
      // unselect others
      unselectOptions(['district', 'subDistrict', 'area'])
      setDistricts(generateOptions(district))
      setIsDistrictLoading(false)
    })
  }

  const handleDistrictChange = (selected: string[]) => {
    setSelectedDistrictOption(generateOptions(selected))
    setSelectedDistrict(selected)
    setIsSubDistrictLoading(true)
    getLocation({
      "region": "subDistrict",
      "location": {
        "state": selectedState,
        "district": selected
      }
    }).then(res => {
      const { subDistrict } = res
      unselectOptions(['subDistrict', 'area'])
      setSubdistricts(generateOptions(subDistrict))
      setIsSubDistrictLoading(false)
    })
  }
  const locate = ["area", "district", "subDistrict", "state"];

  const handleSubDistrictChange = (selected: string[]) => {
    setSelectedSubDistrictOption(generateOptions(selected))
    setSelectedSubDistrict(selected)
    setIsAreaLoading(true)
    getLocation({
      "region": "area",
      "location": {
        "state": selectedState,
        "district": selectedDistrict,
        "subDistrict": selected
      }
    }).then(res => {
      const { area } = res
      unselectOptions(['area'])
      setArea(generateOptions(area))
      setIsAreaLoading(false)
    })
  }

  const handleAreaChange = (selected: string[]) => {
    setSelectedArea(selected)
    setSelectedAreaOption(generateOptions(selected))
  }

  const handleDropdownChange = (dropdown: string, selected: MultiValue<any>) => {
    const value = selected?.map((i) => i.value);
    switch (dropdown) {
      case 'state':
        if (value) handleStateChange(value)
        else {
          unselectOptions(['state', 'district', 'subDistrict', 'area'])
          setDistricts([])
          setIsDistrictLoading(false)
        }
        break;
      case 'district':
        if (value) handleDistrictChange(value)
        else {
          unselectOptions(['district', 'subDistrict', 'area'])
          setSubdistricts([])
          setIsSubDistrictLoading(false)
        }
        break;
      case 'subDistrict':
        if (value) handleSubDistrictChange(value)
        else {
          setSelectedSubDistrict([])
          unselectOptions(['subDistrict', 'area'])
          setArea([])
          setIsAreaLoading(false)
        }
        break;
      case 'area':
        if (value) handleAreaChange(value)
        else {
          unselectOptions(['area'])
        }
        break;
      default:
        return;
    }
  }

  const unselectOptions = (toUnselect: string[]) => {
    let cnt = 0;
    for (const field of toUnselect) {
      if (field === 'state') {
        setSelectedState([])
        setSelectedStateOption(null)
        cnt++;
      } else if (field === 'district') {
        setSelectedDistrict([])
        setSelectedDistrictOption(null)
        if (cnt++ > 0) setDistricts([])
      } else if (field === 'subDistrict') {
        setSelectedSubDistrict([])
        setSelectedSubDistrictOption(null)
        if (cnt++ > 0) setSubdistricts([])
      } else {
        setSelectedArea([])
        setSelectedAreaOption(null)
        if (cnt++ > 0) setArea([])
      }
    }
  }

  const onReset = () => {
    setisError(false)
    setanalysisData({});
    setisanalysisData(false);

    setsensorField([]);

    setareaSensor([]);
    setsubDistSensor([]);
    setdistrictSensor([]);
    setstateSensor([]);


    setareaName([]);
    setsubDistrictName([]);
    setdistrictName([]);
    setstateName([]);
    setIsApplied(false);
    unselectOptions(['state', 'district', 'subDistrict', 'area'])

  }


  const onSubmit = (values: FormResponse) => {
    setisError(false)
    let { field, fromDate, toDate } = values;

    if (selectedDistrict.length === 0) {
      alert('Select District')
    }
    else if ((field as any[]).length === 0) {
      alert('Select a field')
    }
    else if ((fromDate as string).length === 0) {
      alert('Select a starting date')
    }
    else if ((toDate as string).length === 0) {
      alert('Select a end date')
    }

    field = (field as any[])?.map((i) => i.value);

    let payload = {
      "field": field,
      "fromDate": fromDate,
      "toDate": toDate
    }
    if (selectedDistrict.length > 0 && (field as any[]).length > 0 && (fromDate as string).length > 0 && (toDate as string).length > 0) {
      setIsApplied(true);
      getAnalysisFilterResult(payload, selectedDistrict)
        .then((res: any) => {
          if(res.maximum !== undefined)  {
            setanalysisData(res);
            setisanalysisData(true);

            funct = Object.keys(res)?.filter((i) => (i !== 'median' && i !== 'std'));
            setsensorField(funct);

            areasens = Object.keys(res?.maximum?.area)?.map((i) => i);
            setareaSensor(areasens);
            subdistrictsens = Object.keys(res?.maximum?.subDistrict)?.map((i) => i);
            setsubDistSensor(subdistrictsens);
            districtsens = Object.keys(res?.maximum?.district)?.map((i) => i);
            setdistrictSensor(districtsens);
            statesens = Object.keys(res?.maximum?.state)?.map((i) => i);
            setstateSensor(statesens);


            arealoc = Object.keys(res?.maximum?.area[areasens[0]])?.map((i) => i);
            setareaName(arealoc);
            subdistrictloc = Object.keys(res?.maximum?.subDistrict[subdistrictsens[0]])?.map((i) => i);
            setsubDistrictName(subdistrictloc);
            districtloc = Object.keys(res?.maximum?.district[districtsens[0]])?.map((i) => i);
            setdistrictName(districtloc);
            stateloc = Object.keys(res?.maximum?.state[statesens[0]])?.map((i) => i);
            setstateName(stateloc);
          }
          else{
            setIsApplied(false);
            setisError(true);
            setisanalysisData(false);

            setanalysisData({});
            setisanalysisData(false);

            setsensorField([]);

            setareaSensor([]);
            setsubDistSensor([]);
            setdistrictSensor([]);
            setstateSensor([]);


            setareaName([]);
            setsubDistrictName([]);
            setdistrictName([]);
            setstateName([]);
          } 
          // const plotIds = res.map((data: Response) => data.plotId);
          // handleFitlerChange(true, plotIds)
        })
        .catch((err: any) => {
          console.log(err);
          setIsApplied(false);
          setisError(true);
          setisanalysisData(false);
        })
    }
  }

  const filterOptions: Option[] = [
    {
      label: Mappings.airHumidity,
      value: 'airHumidity'
    },
    {
      label: Mappings.airPressure,
      value: 'airPressure'
    },
    {
      label: Mappings.airTemp,
      value: 'airTemp'
    },
    {
      label: Mappings.battery,
      value: 'battery'
    },
    {
      label: Mappings.rainFall,
      value: 'rainFall'
    },
    {
      label: Mappings.leafWetness,
      value: 'leafWetness'
    },
    {
      label: Mappings.lightIntensity,
      value: 'lightIntensity'
    },
    {
      label: Mappings.soilMoisture1,
      value: 'soilMoisture1'
    },
    {
      label: Mappings.soilMoisture2,
      value: 'soilMoisture2'
    },
    {
      label: Mappings.soilTemp,
      value: 'soilTemp'
    },
    {
      label: Mappings.windSpeed,
      value: 'windSpeed'
    }
  ]

  interface FormResponse {
    field: object;
    fromDate: Date | string;
    toDate: Date | string;
  }

  const fields = [
    {
      id: "field",
      label: "Field",
      placeholder: "",
      type: "multiselect",
      validationType: "",
      value: '',
      options: filterOptions,
      validations: [],
    },
    {
      id: "fromDate",
      label: "From Date",
      placeholder: "",
      type: "date",
      validationType: "",
      value: '',
      validations: [],
    },
    {
      id: "toDate",
      label: "To Date",
      placeholder: "",
      type: "date",
      validationType: "",
      value: '',
      validations: [],
    },
  ]



  const navigate = useNavigate()

  return (
    <Grid width={'90%'} my={2} mx={'auto'} boxShadow={2} borderRadius={4} paddingBottom={5}>
      <Grid p={2} borderRadius={20}  >
        <Grid display={'flex'} item alignItems={'center'}>
          <IconButton onClick={() => navigate('/')} ><ArrowBack /></IconButton>
          <Typography m={1} variant='h5'>Analysis</Typography>
        </Grid>

      </Grid>

      <Grid>
        <Typography variant="h6" mt={1}>Data</Typography>
        <>
          <div style={{
            backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
            margin: '0px 0 15px 0',
            padding: '5px 10px',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center'
          }} >

            <Grid width={380} mr={1}>
              <Typography variant="subtitle1" fontWeight={600}>State</Typography>
              <Select
                options={states}
                onChange={(selected: MultiValue<any>) => handleDropdownChange('state', selected)}
                isSearchable={true}
                placeholder={'- select -'}
                isLoading={isStateLoading}
                isDisabled={isStateLoading}
                value={selectedStateOption}
                isClearable
                isMulti
              />
            </Grid>

            <Grid width={380} mr={1}>
              <Typography variant="subtitle1" fontWeight={600}>District</Typography>
              <Select
                options={districts}
                onChange={(selected: MultiValue<any>) => handleDropdownChange('district', selected)}
                isSearchable={true}
                placeholder={'- select -'}
                isLoading={isDistrictLoading}
                isDisabled={selectedState?.length === 0 || isDistrictLoading}
                value={selectedDistrictOption}
                isClearable
                isMulti
              />
            </Grid>

            <Grid width={380} mr={1}>
              <Typography variant="subtitle1" fontWeight={600}>Sub District</Typography>
              <Select
                options={subDistricts}
                onChange={(selected: MultiValue<any>) => handleDropdownChange('subDistrict', selected)}
                isSearchable={true}
                placeholder={'- select -'}
                isLoading={isSubDistrictLoading}
                isDisabled={selectedDistrict?.length === 0 || isSubDistrictLoading}
                value={selectedSubDistrictOption}
                isClearable
                isMulti
              />

            </Grid>

            <Grid width={380} mr={1}>
              <Typography variant="subtitle1" fontWeight={600}>Area</Typography>
              <Select
                options={area}
                onChange={(selected: MultiValue<any>) => handleDropdownChange('area', selected)}
                isSearchable={true}
                placeholder={'- select -'}
                isLoading={isAreaLoading}
                isDisabled={selectedSubDistrict?.length === 0 || isAreaLoading}
                value={selectedAreaOption}
                isClearable
                isMulti
              />
            </Grid>
          </div>
        </>
        <div style={{
          backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
          margin: '0px 0 15px 0',
          padding: '5px',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center'
        }} >
          <FormikApp
            formFields={fields}
            showFormikReset={true}
            onSubmit={(values: FormResponse) => { onSubmit(values) }}
            onReset={onReset}
            filter={true}
            showReset={false}
            removeField={() => { }}
          />
        </div>
      </Grid>
      {isanalysisData && (
        <div
          style={{
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
        </div>

      )}
      <AnalysisComp
        location={stateName}
        func={sensorField}
        sensor={stateSensor}
        analysisData={analysisData}
        place={"state"}
      />

      <AnalysisComp
        location={districtName}
        func={sensorField}
        sensor={districtSensor}
        analysisData={analysisData}
        place={"district"}
      />
      <AnalysisComp
        location={subDistrictName}
        func={sensorField}
        sensor={subDistSensor}
        analysisData={analysisData}
        place={"subDistrict"}
      />
      <AnalysisComp
        location={areaName}
        func={sensorField}
        sensor={areaSensor}
        analysisData={analysisData}
        place={"area"}
      />
      {isError && (
        <Typography
          fontSize={17}
          color={'red'}
          margin={3}
        >There seems to be an error. Please try again!</Typography>
      )}


    </Grid>
  )
}



export default Analysis