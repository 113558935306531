import { Grid, IconButton, Typography } from '@mui/material'
import Select, { MultiValue } from 'react-select'
import { ArrowBack } from '@mui/icons-material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router';
import { deleteInventoryLocation } from '../services/inventory.service';

import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { RegionAdress, addRegion, getRegionAdress } from 'src/services/region.service';
import { SingleValue } from 'react-select';



interface Props {
    selectedModalData?: any;
    setOpenModal?: any;
    setSelectedModalData?: any
}

const RegionDetails: React.FC<Props> = (props: Props): JSX.Element => {
    const res: any = props?.selectedModalData;
    const [name, setName] = useState<any>(res?.name || "")
    const navigate = useNavigate();
    const [isTargetRegion, setIsTargetRegion] = useState<boolean>(res?.isTargetRegion || false)
    const [selectedRegionType, setSelectedRegionType] = useState<any>(null);
    const regionType = [
        { label: 'District', value: 'district' },
        { label: 'State', value: 'state' },
        { label: 'Sub District', value: 'subdistrict'},
        { label: 'Village', value: 'village' }
    ]


    const [regionAddress, setRegionAddress] = useState<
        RegionAdress & {
            geoLocation?: { type: string, coordinates: number[] },
            address?: string
        }>({
            village: res?.village ?? "",
            subDistrict: res?.subDistrict ?? "",
            district: res?.district ?? "",
            state: res?.state ?? "",
            country: res?.country ?? "",
        })

        const clearRegionAddress = () => {
            setRegionAddress({
                village: "",
                subDistrict: "",
                district: "",
                state: "",
                country: "",
                geoLocation: undefined,
                address: undefined
            })
        }

    
        const onGoogleAutoCompletePropChange = (te: SingleValue<any>) => {
            clearRegionAddress();
            if (te?.value?.place_id) {
                geocodeByPlaceId(te?.value?.place_id)
                    .then(res => {
                        if (!!res && Array.isArray(res) && res.length > 0) {
                            const { geometry } = res[0];
    
                            const { lat, lng } = geometry.location;
                            const latlon = {
                                lat: lat(),
                                lon: lng()
                            }
    
                            const geoLocation = {
                                type: "Point",
                                coordinates: [latlon.lon, latlon.lat]
                            }
    
                            const address = te.label;
    
                            getRegionAdress(latlon)
                                .then(res => {
                                    setRegionAddress({
                                        ...res,
                                        address,
                                        geoLocation
                                    });
                                })
                                .catch(err => {
                                    console.error("Failed: region address", err);
                                })
                        }
                    })
            }
        }

        const onSubmit = () => {
            
        if(regionAddress.address && selectedRegionType.value){
            let finalData = {
                id: regionAddress.address!.replace(/,/g, '_'),
                regionName: name===""? regionAddress.address: name,
                name: regionAddress.address,
                targetRegion: isTargetRegion,
                type: selectedRegionType.value,
                fylloWeatherPlotCount: 1,
                ...regionAddress,
            }
            if (window.confirm("Do you want to submit?")) {
                addRegion(finalData)
                    .then((res: any) => {
                        if (res.id) {
                            alert("Region Added");
                            props?.setOpenModal(false);
                        }
                        else
                            alert("Some error occured");
                    })
            }
        }
        
        else alert("Please add location or select location type!")
            
        }
    
        const onDelete = () => {
            if (res?.id) {
                if (window.confirm("Do you want to delete?")) {
                    deleteInventoryLocation(res.id)
                        .then((res: any) => {
                            if (res.message === 'Success') {
                                alert("Location deleted");
                                props?.setOpenModal(false);
                            }
                            else
                                alert("Some Error Ocuured");
                        })
                }
            }
            else
                alert("Some Error Occured");
        }

        const handleTypeSelect = (selected: MultiValue<any>) => {
            setSelectedRegionType(selected);
        }
    
    return(
        <Grid width="90%" my={2} mx="auto" boxShadow={2} borderRadius={4} paddingBottom={5}>
            <Grid p={2} borderRadius={25} style={{ backgroundColor: '#f5f5f5' }}>
                <Grid container alignItems="center">
                    <IconButton onClick={() => props?.setOpenModal ? props.setOpenModal(false) : navigate('/inventory')}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h5" style={{ marginLeft: 10 }}>Location</Typography>
                </Grid>
            </Grid>

            <div style={{ justifyContent: "center", padding: 20 }}>
                <form>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ fontSize: 19, fontWeight: 'bold', marginRight: 5, margin: 5 }}>Region Name</label>
                            
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <label style={{ fontSize: 18, fontWeight: 'bold', marginRight: 11 }}>Target Region</label>
                            <input type="checkbox" name="targetRegion" onChange={(e) => setIsTargetRegion(!isTargetRegion)} checked={isTargetRegion} style={{ width: '22px', height: '22px', marginRight: 30 }}></input>
                        </div>
                    </div>

                    <input
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        type="text"
                        style={{
                            width: '93%',
                            padding: '10px',
                            fontSize: 18,
                            margin: '3px',
                            marginBottom: 11,
                            marginTop: 10,
                            border: '1px solid #ccc',
                            borderRadius: 5,
                        }}
                    />
                    <br />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h3 style={{ margin: 5 }}>Search Location</h3>
                        <label style={{ fontSize: 20, fontWeight: 'bold', color: 'red' }}>*</label>
                    </div>
                    <GooglePlacesAutocomplete
                        apiKey={process.env.REACT_APP_MAP_KEY}
                        selectProps={{
                            onChange: onGoogleAutoCompletePropChange,
                            styles: {
                                container: () => ({
                                    width: '96%',
                                    margin: '3px',
                                    marginBottom: 11,
                                    label: 'container',
                                    boxSizing: 'border-box',
                                    position: 'relative'
                                })
                            }
                        }}
                    />

                    <Grid container p={1} justifyContent="space-between">
                        <Grid item>
                            <h3>Country</h3>
                            <p>{regionAddress.country}</p>
                        </Grid>
                        <Grid item>
                            <h3>State</h3>
                            <p>{regionAddress.state}</p>
                        </Grid>
                        <Grid item>
                            <h3>District</h3>
                            <p>{regionAddress.district}</p>
                        </Grid>
                        <Grid item>
                            <h3>Sub District</h3>
                            <p>{regionAddress.subDistrict}</p>
                        </Grid>
                        <Grid item>
                            <h3>Village</h3>
                            <p>{regionAddress.village}</p>
                        </Grid>
                    </Grid>

                    
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Region Type</Typography>
                        <Select
                            options={regionType}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            onChange={(selected: MultiValue<any>) => handleTypeSelect(selected)}
                            placeholder="Select Region Type"
                            value={selectedRegionType}
                            isClearable
                            
                        />
                    </Grid>
                    <Grid container justifyContent="space-between" style={{ marginTop: 55 }}>
                        <input
                            type="button"
                            value="Submit"
                            onClick={onSubmit}
                            style={{
                                width: '42%',
                                padding: '10px',
                                fontSize: 18,
                                backgroundColor: '#4caf50',
                                color: '#fff',
                                border: 'none',
                                borderRadius: 5,
                                cursor: 'pointer',
                            }}
                        />
                        {res?.id && (
                            <input
                                type="button"
                                value="Delete"
                                onClick={onDelete}
                                style={{
                                    width: '42%',
                                    padding: '10px',
                                    fontSize: 18,
                                    backgroundColor: '#f44336',
                                    color: '#fff',
                                    border: 'none',
                                    borderRadius: 5,
                                    cursor: 'pointer',
                                }}
                            />
                        )}
                    </Grid>
                </form>
            </div>
        </Grid>

    );
}

export default RegionDetails