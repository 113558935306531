import { filterObservation, Filter as ObservationField } from 'src/services/observation.service';
import { Filter as ObservationTypeField } from 'src/services/observationType.service';
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { ObservationCard } from '../components';
import { filterObservationTypes } from 'src/services/observationType.service';
import { Grid, Skeleton } from '@mui/material';

type Props = {
  onClick?: (id: string) => void;
  onDoubleClick?: (id: string) => void;
}

export type RecentObservation = Partial<Record<ObservationField | ObservationTypeField, any>>;

const CardSection: React.FC<Props> = (props) => {
  const { onClick, onDoubleClick } = props;
  const location = useLocation();

  const { cropId, plotId } = location.state as any;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [recentObservations, setRecentObservations] = useState<RecentObservation[]>([]);
  const [observationTypes, setObservationTypes] = useState<any[]>();

  useEffect(() => {
    fetchLastObservationsFor(plotId, cropId);
  }, [cropId, plotId])

  useEffect(() => {
    if (recentObservations.length > 0 && !observationTypes) {
      fetchObservationTypes();
    }
  }, [recentObservations]) // eslint-disable-line

  // api calls
  const fetchLastObservationsFor = (plotId: string, cropId: string) => {
    const limit = 10;
    setIsLoading(true);
    filterObservation(
      { plotId, cropId },
      {
        id: true,
        probability: true,
        riskLevel: true,
        observationTypeId: true,
        observationDate: true,
        diseasePestId: true,
      },
      limit
    )
      .then(res => {
        setRecentObservations(res);
      })
      .catch(error => {
        console.error("failed to filter observations", error);
      })
      .finally(() => setIsLoading(false));
  }

  const fetchObservationTypes = () => {
    const observationTypeIds = [...new Set(recentObservations.map(observation => observation.observationTypeId))];

    setIsLoading(true);

    filterObservationTypes(
      { id: { inq: observationTypeIds } as any, },
      { id: true, name: true }
    ).then(res => {
      const observationType = {} as any;

      res.forEach((data) => {
        observationType[data.id] = data.name;
      })

      setObservationTypes(observationType);

      setRecentObservations(
        recentObservations =>
          recentObservations.map(
            observation => (
              {
                ...observation,
                name: observationType[observation.observationTypeId]
              }
            )
          )
      );
    })
      .catch(error => {
        console.error("failed to filter observation types", error);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <div>
      <div>
        <p>Recent Observations</p>
        {
            isLoading && <Grid container gap={2}>
              {Array.from({ length: 4 }).map((_, index) => <Skeleton key={`skeleton-${index}`} width={250} height={300} />)}
            </Grid>
          }
        <CardsContainer>
          {recentObservations.map((observation => 
            <ObservationCard 
              key={observation.id} 
              onClick={onClick} 
              onDoubleClick={onDoubleClick}  
              observation={observation} 
            />
          ))}
        </CardsContainer>
      </div>
    </div>
  )
}

export default CardSection;

const CardsContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const style = useMemo((): React.CSSProperties => ({
    margin: "auto",
    padding: "20px 10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0px 20px",
    overflow: "auto",
    width: "95%",
  }), []);

  return <div style={style}>{children}</div>;
}