import { Grid } from "@mui/material";
import { SENSORS } from "../constant";
import { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { DropDownProps } from "./CropFilterBusinessDashboard";

type Props = {
  fromDate: any;
  toDate: any;
  plotIds: string[];
  defaultSensor: string;
  setGraphData: (x: any) => void;
  setIsLoading: (x: boolean) => void;
  onSensorChange: (x: string) => any;
};

const MapSensorFilter: React.FC<Props> = (props) => {
  const { fromDate, toDate, plotIds, setGraphData, setIsLoading, defaultSensor, onSensorChange } = props;
  const [sensor, setSensor] = useState<string>(defaultSensor);
  const [selectedDropdown, setSelectedDropdown] = useState<SingleValue<string>>(
    SENSORS.filter((sensor: any) => sensor.value === defaultSensor)[0]
  );

  useEffect(() => {
    if (!!sensor) showResults();
  }, [sensor, fromDate, toDate]);

  const showResults = async () => {
    if (!!sensor) {
      setIsLoading(true);
      try {
        const result = await onSensorChange(sensor)
        setGraphData(result)
      } catch(err) {
        console.log('err', err)
      } finally{
        setIsLoading(false);
      }
    }
  };

  const handleFilterSelectChange = (newValue: SingleValue<any>): void => {
    setSelectedDropdown(newValue);
    setSensor(newValue.value);
  };

  return (
    <Grid width={250}>
      <Select
        options={SENSORS}
        defaultValue={SENSORS[2]}
        value={selectedDropdown}
        onChange={handleFilterSelectChange}
        {...DropDownProps}
      />
    </Grid>
  );
};

export default MapSensorFilter;
