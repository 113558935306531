import { getClientName } from "src/utils/helper";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Route, Routes } from 'react-router-dom';
import { Layout, RequireAuth } from "src/components";
import { Roles } from "src/constants/interfaces";
import {
  Activities, Analysis, BusinessDashboard, FarmActivities, Fertilizer,
  FertilizerDetails, AddVideosModel, Login, Nutrient,
  NutrientDetails, PlotData, PlotDetails, Unauthorized, PlotInfo,
  RegionPage, Pesticide, VideoGuides, Consultants, AddConsultants, Inventory, InventoryItem, InventoryLocation, InventoryMovement, Notifications, ConversationPage, FollowUpsPage, MaintenancePage, RegionCrop, UserEventAnalysis, FoliarSchedule, FertigationSchedule, SoilApplicationSchedule, PetioleAnalysis, Locale, LaiAdvisory, Idpm, CropCharacteristics, CropCharacteristicsDetails, Payments, ObservationType, Sales, Observations,
  ObservationInsights, FarmUser, Voucher, SprayConfig, LeadPage, RegionReport, Adherence,Region, Analytics, ImageAnalysis, SoilStandard, FarmerProfile,
  DynamicScreen
} from "src/pages";
import LocaleConverter from "src/pages/LocaleConverter";

const AppRouter: React.FC = (): JSX.Element => {

  const location = useLocation();

  useEffect(() => {
    getClientName(location);
  }, [location]); // eslint-disable-line

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="info/:plotId" element={<PlotInfo />} />
        <Route element={
          <RequireAuth allowedRoles={[Roles.ADMIN, Roles.SUPPORT, Roles.FARM_USER, Roles.FARM_MANAGER]} />
        } >
          <Route path="/" element={<BusinessDashboard />} />
          <Route path="/home" element={<></>} />
          <Route path="plots/:plotId" element={<PlotDetails />} />
          <Route path="farm-activity/:plotId/:cropId" element={<Activities />} />
          <Route path="plot-data/:plotId" element={<PlotData />} />
          <Route path="plot-data-historical/:plotId" element={<PlotData tabValue={1} />} />

        </Route>

        <Route element={
          <RequireAuth allowedRoles={[Roles.EXPORTER, Roles.ADMIN, Roles.SUPPORT]} />
        } >
          <Route path="/exporter" element={<></>} />
        </Route>

        <Route element={
          <RequireAuth allowedRoles={[Roles.PLANTPROTECTION, Roles.ADMIN, Roles.SUPPORT, Roles.FARM_MANAGER]} />
        }>
          <Route path="/region" element={<Region />} />
        </Route>

        <Route element={
          <RequireAuth allowedRoles={[Roles.ADMIN, Roles.SUPPORT]} />
        } >
          <Route path="locale-converter" element={<LocaleConverter /> } />
          <Route path="analysis" element={<Analysis />} /> 
          <Route path="adherence" element={<Adherence />} />
          <Route path="consultants" element={<Consultants />} />
          <Route path="consultants/consultants" element={<AddConsultants />} />
          <Route path="consultants/consultants/:id" element={<AddConsultants />} />
          <Route path="conversations" element={<ConversationPage />} />
          <Route path="farm-activities" element={<FarmActivities />} />
          <Route path="fertilizer" element={<Fertilizer />} />
          <Route path="fertilizer/fertilizer-details" element={<FertilizerDetails />} />
          <Route path="fertilizer/fertilizer-details/:id" element={<FertilizerDetails />} />
          <Route path="followups" element={<FollowUpsPage />} />
          <Route path="leads" element={<LeadPage />} />
          <Route path="region-report" element={<RegionReport />} />
          <Route path="idpm" element={<Idpm />} />
          <Route path="inventory" element={<Inventory />} />
          <Route path="inventory/item" element={<InventoryItem />} />
          <Route path="inventory/location" element={<InventoryLocation />} />
          <Route path="inventory/movement" element={<InventoryMovement />} />
          <Route path="lai-advisory" element={<LaiAdvisory />} />
          <Route path="locale" element={<Locale />} />
          <Route path="maintenance" element={<MaintenancePage />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="nutrient" element={<Nutrient />} />
          <Route path="nutrient/fertigation-schedule" element={<FertigationSchedule />} />
          <Route path="nutrient/foliar-schedule" element={<FoliarSchedule />} />
          <Route path="nutrient/nutrient-details" element={<NutrientDetails />} />
          <Route path="nutrient/petiole-analysis" element={<PetioleAnalysis />} />
          <Route path="nutrient/soilApplication-schedule" element={<SoilApplicationSchedule />} />
          <Route path="observations" element={<Observations />} />
          <Route path="observation-type" element={<ObservationType />} />
          <Route path="observation-insight" element={<ObservationInsights />} />
          <Route path="pesticide" element={<Pesticide />} />
          <Route path="region-crop" element={<RegionCrop />} />
          <Route path="user-events-analysis" element={<UserEventAnalysis />} />
          <Route path="videos-guide" element={<VideoGuides />} />
          <Route path="videos-guide/videos-guide" element={<AddVideosModel />} />
          <Route path="videos-guide/videos-guide/:id" element={<AddVideosModel />} />
          <Route path="locale" element={<Locale />} />
          <Route path="lai-advisory" element={<LaiAdvisory />} />
          <Route path="idpm" element={<Idpm />} />
          <Route path="cropCharacteristics" element={<CropCharacteristics />} />
          <Route path="cropCharacteristics/cropCharacteristics-details" element={<CropCharacteristicsDetails />} />
          <Route path="payments" element={<Payments />} />
          <Route path="sales" element={<Sales />} />
          <Route path="farmUser" element={<FarmUser />} />
          <Route path="voucher" element={<Voucher />} />
          <Route path="spray-config" element={<SprayConfig />} />
          <Route path="leads" element={<LeadPage />} />
          <Route path="regions" element={<RegionPage />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="image-analysis" element={<ImageAnalysis />} />
          <Route path="soil-standard" element={<SoilStandard />} />
          <Route path="farmer-profile" element={<FarmerProfile />} />
          <Route path="screen" element={<DynamicScreen />} />
        </Route >

      </Route >
    </Routes >
  )
}

export default AppRouter