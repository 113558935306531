import {
  ICellRendererComp,
  ICellRendererParams,
} from 'ag-grid-community';
import { KeyValues } from 'src/constants/interfaces';
import { isArrayWithLength } from '../helper';

export const getQualityStyle = (plotQualityPredictions: KeyValues[]) => {
  if (isArrayWithLength(plotQualityPredictions)) {
    const latestData = plotQualityPredictions.slice(-1)[0].value; // returns last element
    const secondLastData = plotQualityPredictions.slice(-2)[0].value; // returns last element if has only 1 element
    let isGreen = false;
    let isOrange = false;
    let color = ''
    // check color conditions & assign color + icon
    isGreen = latestData > secondLastData;
    isOrange = latestData === secondLastData;
    color = isGreen ? 'green' : (isOrange ? 'orange' : 'red');
    const hasIncreased = isGreen || isOrange;
    const quality = Number(latestData).toFixed(2);

    return {
      hasIncreased, color, quality
    }

  }

  return {
    hasIncreased: '',
    color: '',
    quality: ''
  }

}

export class QualityRenderer implements ICellRendererComp {
  eGui!: HTMLSpanElement;

  init(params: ICellRendererParams) {
    this.eGui = document.createElement('span');
    this.eGui.style.display = "flex";
    this.eGui.style.alignItems = "center";
    this.eGui.style.padding = "0px";
    const { plotQualityPredictions = [] } = params.data;

    if (isArrayWithLength(plotQualityPredictions)) {

      const { color, hasIncreased, quality } = getQualityStyle(plotQualityPredictions)

      this.eGui.innerHTML += `
      <i style="color: ${color}; font-size: 20px; margin-right: 5px"> 
        ${(hasIncreased) ? '&#9650;' : '&#9660;'}
      </i>
      <span style="font-size: 12px;"> ${quality}%</span>`;
    } else {
      this.eGui.innerHTML += 'NA';
    }
  }

  getGui() {
    return this.eGui;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}