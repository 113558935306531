import React from 'react';
import { Grid, Skeleton } from '@mui/material';

type PieChartProps = {
  width: number;
  height: number;
}

type BarChartProps = PieChartProps & {
  noOfBars: number;
  barsWidth: number;
}

type GridProps = PieChartProps & {
  noOfRows: number;
}

type LineChartProps = PieChartProps & {
  data: Array<Array<number>>;
}

export const PieChart: React.FC<PieChartProps> = (props) => {
  const { width, height } = props;
  return (
    <Grid position={'relative'} width={width * 2 + 10} height={height * 2 + 10} >
      <Skeleton variant="circular" width={width * 2} height={height * 2} animation="wave"
        style={{ position: 'absolute' }}
      />
      <Skeleton variant="circular" width={width} height={height} animation="wave"
        style={{ position: 'absolute', top: height - height / 2, left: width - width / 2, opacity: 0.5 }}
      />
    </Grid>
  )
}

export const BarChart: React.FC<BarChartProps> = (props) => {
  const { width, barsWidth, height, noOfBars } = props;
  return (
    <Grid container spacing={0.7} width={width} alignItems={'flex-end'} mb={0} >
      <Skeleton style={{
        position: 'relative',
        top: 0
      }} variant='rectangular' width={2} height={height + 20} />
      {
        Array.from({ length: noOfBars }).map((_, ind) => (
          <Grid key={`bar-chart-skeleton-${ind}`} xs display="flex" justifyContent="center" alignItems="flex-end">
            <Skeleton variant="rectangular" width={barsWidth} height={Math.random() * (height - 130) + 130} animation="wave" />
          </Grid>
        ))
      }
      <Skeleton variant='rectangular' width={width + 20} height={2} />
    </Grid>
  )
}

export const Table: React.FC<GridProps> = (props) => {
  const { width, height, noOfRows } = props;
  return (
    <Grid item xs={width} height={height}>
      <Skeleton variant='rectangular' height={2} />
      {
        Array.from({ length: noOfRows }).map((_, ind) => (
          <Grid key={`grid-skeleton-${ind}`}>
            <Skeleton variant="rectangular" height={45} animation="wave" style={{ margin: '7px 5px' }} />
            <Skeleton variant='rectangular' height={2} />
          </Grid>
        ))
      }

    </Grid>
  )
}

export const BoxWithText: React.FC<PieChartProps> = (props) => {
  const { width } = props;
  return (
    <Grid item width={width} boxShadow={1} borderRadius={2} p={2} >
      <Skeleton variant='rectangular' height={10} width={width / 3} />
      <Skeleton variant="rectangular" height={35} width={4 * width / 5} animation="wave" style={{ margin: '7px 0' }} />
      <Skeleton variant='rectangular' height={10} width={2 * width / 3} />
    </Grid>
  )
}

export const LineChart: React.FC<LineChartProps> = (props) => {
  const { width, height, data } = props;

  return (
    <Grid container spacing={0.7} width={width} alignItems={'center'} mb={0} position={'relative'} >
      <Skeleton
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0
        }}
        variant='rectangular' width={2}
        height={height + 12}
      />
      {
        data.map((lineData, lineIndex) => (
          <Grid p={1} key={`line-chart-skeleton-${lineIndex}`} xs display="flex" justifyContent="center" alignItems="flex-end">
            <svg width={width - 20} height={height}>
              {lineData.map((value, dataIndex) => (
                <line
                  key={`line-${lineIndex}-${dataIndex}`}
                  x1={dataIndex * (width / (lineData.length - 1))}
                  y1={height - value}
                  x2={(dataIndex + 1) * (width / (lineData.length - 1))}
                  y2={height - lineData[dataIndex + 1]}
                  stroke="#f4f4f4" // You can set the color you want for each line
                  strokeWidth={3} // Set the width of the line
                />
              ))}
            </svg>
          </Grid>
        ))
      }
      <Skeleton 
        style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} 
        variant='rectangular' 
        height={2}
      />
    </Grid>
  )
}