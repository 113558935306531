import { Grid } from "@mui/material";

interface TabPanelProps {
  panelFor: string;
  children?: React.ReactNode;
  index: number;
  value: number;
}

const InfoTabPanel = (props: TabPanelProps) => {
  const { panelFor, children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${panelFor}-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      { value === index && <Grid py={2}> {children} </Grid> }
    </div>
  );
}

export default InfoTabPanel;