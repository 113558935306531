import React, { memo, useEffect, useState } from "react";
import { KeyValues } from "src/constants/interfaces";
import { Grid } from "@mui/material";
import { States } from "src/constants/geojson/states";
import { GraphPlots, hideNoDataOverlay, showNoDataOverlay } from "..";
import FilterMap from "src/components/Map";

interface Props {
  cropData: any,
  indexData: any,
  setCropData: any,
  setIndexData: any,
  buttonDisabled: any,
  setButtonDisabled: any,
  filterApplied?: string,
  mapsDate: any;
  setGraphPlots: (x: GraphPlots) => void;
  setshowgetPrediction: any;
  setGetndviImagePlotId: any;
  setGetndviImage: any;
  setGetndviImageCrop?: any;
  setGetndviImageFarmId?: any;
  xs: number;
  isRegion?: boolean;
  setMapsDate?: (date: Date | null) => void;
  hotspot?: boolean;
}

const MapSection: React.FC<Props> = (props: Props): JSX.Element => {

  const { cropData, buttonDisabled, setButtonDisabled, filterApplied, indexData, mapsDate, setGraphPlots, setshowgetPrediction, setGetndviImage, setGetndviImagePlotId, xs, isRegion, setMapsDate, setGetndviImageCrop, setGetndviImageFarmId, hotspot } = props;

  const [mapLoaded, setMapLoaded] = useState<boolean>(false)
  const [mapLocations, setMapLocations] = useState<KeyValues[]>()

  useEffect(() => {
    setMapLocations(cropData)
    setMapLoaded(true)
  }, [cropData])

  useEffect(() => {
    if (cropData.length === 0) {
      showNoDataOverlay()
    } else {
      hideNoDataOverlay()
    }
  }, [cropData])

  // code for polygon on map
  const [polygonRegion, setPolygonRegion] = useState<any>(States)


  const handlePolygonClick = (corrdArr: any[], clickedRegion: string) => {
    // const bounds = new window.google.maps.LatLngBounds();
    // for (let i = 0; i < corrdArr.length; i++) {
    //   bounds.extend(corrdArr[i]);
    // }
    // alert(clickedRegion)
    // map.fitBounds(bounds);
    // if (!!polygonRegion[clickedRegion].children) setPolygonRegion(polygonRegion[clickedRegion].children)
    // else {
    //   setPolygonRegion(States)
    // }
  }

  return (
    <Grid item xs={xs} position='relative'>
      {mapLoaded ? <>
        <FilterMap
          mapLocations={mapLocations as KeyValues[]}
          height={'85vh'}
          polygonRegion={{}}
          handlePolygonClick={handlePolygonClick}
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
          filterApplied={filterApplied}
          legends={indexData}
          mapsDate={mapsDate}
          setMapsDate={setMapsDate}
          setGraphPlots={setGraphPlots}
          setshowgetPrediction={setshowgetPrediction}
          setMapLocations={setMapLocations}
          setGetndviImagePlotId={setGetndviImagePlotId}
          setGetndviImage={setGetndviImage}
          setGetndviImageCrop={setGetndviImageCrop}
          setGetndviImageFarmId={setGetndviImageFarmId}
          isRegion={isRegion}
          plotsMap={false}
          hotspot={hotspot}
        />
      </> : <></>}
    </Grid>
  )
}

export default memo(MapSection);