import { Grid, Typography } from '@mui/material'
import { ChartData } from 'chart.js';
import React from 'react'
import { Line } from 'react-chartjs-2';
import { validateLocaleAndSetLanguage } from 'typescript';
import { Mappings, UnitMapping } from '../../constants/WordMapping'
import { getDate } from '../../utils/helper';

interface ChartsProps {
  datafields: any[];
  skipFields?: string[];
  chartData: any[];
  chartWidth?: string | number;
}

const WindDirectionCharts: React.FC<ChartsProps> = (props: ChartsProps): JSX.Element => {
  const { datafields, skipFields, chartData, chartWidth = '45%' } = props

  const footer = (tooltipItems: any) => {
    const { dataIndex } = tooltipItems[0]
    const time = chartData[dataIndex]?.timestamp
    return 'Time: ' + getDate(time, 'lll');
  };

  return (
    <Grid container justifyContent={'space-around'}>
      {
        datafields.map((field) => {
          if (skipFields?.includes(field)) {
            return <span key={field}></span>
          }

          const graphData = generateGraphData(field, chartData)
          const options:any = {
            responsive: true,
            plugins: {
              legend: {
                position: 'top' as const,
              },
              title: {
                display: false,
                text: Mappings[field] ?? field,
              },
              tooltip: {
                // callbacks: {
                //   footer: footer,
                //   // label: function(tooltipItem: any){
                //   //   return 'custom tool tip'
                //   // },
                // },
                enabled: false
              },
              zoom: {
                zoom: {
                  wheel: {
                    enabled: false,
                  },
                  pinch: {
                    enabled: false,
                  },
                  drag:{
                    modifierKey: 'shift',
                    enabled: false,
                  },
                  limits:{
                    // x:{
                    //   min:20,
                    //   max: 25
                    // },
                  },
                  mode: 'x',
                },
                pan: {
                  enabled: true,
                  mode: 'x'
                }
              },
              // limits:{
              //   x:{
              //     min: 20,
              //     max: 25
              //   }
              // }
            },       
            scales: {
              x: {
                ticks: {
                  autoSkip: true,
                  maxTicksLimit: 10,
                  minRotation: 0,
                  maxRotation: 0,
                },
                max: chartData.length % 50
              },
              y: {
                title: {
                  color: 'green',
                  display: false,
                  text: UnitMapping[field] ?? ''
                },
                gridLines: {
                  display: false,
                  color: "rgba(0, 0, 0, 0)",
                },
                ticks: {
                    display: false,
                }
              }
            }
          };
          return (
            <Grid key={field} width={'90%'}>
              <Line options={options} data={graphData as ChartData<"line", any, unknown>} />
            </Grid>
          )
        })
      }
    </Grid>
  )
}

const generateGraphData = (field: string, data: any[]) => {

  let stylee: any = [];
  const labels = data?.map((data: any) => getDate(data.timestamp, 'DD/MM'));
  const downArrow = new Image();
  downArrow.src = require('../../assets/down.png');
  downArrow.width = 40;
  downArrow.height = 50;
  const upArrow = new Image();
  upArrow.src = require('../../assets/up.png');
  upArrow.width = 40;
  upArrow.height = 50;
  const westArrow = new Image();
  westArrow.src = require('../../assets/left.png');
  westArrow.width = 40;
  westArrow.height = 50;
  const eastArrow = new Image();
  eastArrow.src = require('../../assets/right.png');
  eastArrow.width = 40;
  eastArrow.height = 50;

    data?.map((val) => {
      if (val.windDirection === "N")
        stylee.push(upArrow);
      else if (val.windDirection === "S")
        stylee.push(downArrow);
      else if (val.windDirection === "E")
        stylee.push(eastArrow);
      else if (val.windDirection === "W")
        stylee.push(westArrow);

    })

  return {
    labels,
    datasets: [
      {
        label: Mappings['windDirection'],
        data: data?.map((data: any) => data[field]),
        borderColor: 'green',
        borderWidth: 0,
        backgroundColor: 'green',
        pointBackgroundColor: 'transparent',
        pointRadius: 3,
        pointStyle: stylee
        //  && {
        //   data?.map((val:any)=>{
        //     if([val].windDirection==='N')
        //     return [upArrow]
        //   })
        // } 
        //[downArrow, upArrow, westArrow, eastArrow]: []
      }
    ]
  }
}


export default WindDirectionCharts