import React, { memo, useEffect, useState } from "react";
import { Typography } from '@mui/material'
import { KeyValues, FilterDataValue, FilterProps } from '../../constants/interfaces'
import { getCrops } from "../../services/crop.service";
import FormikApp from '../DynamicForm'
import { getDate } from "../../utils/helper";

const HarvestingFilter: React.FC<FilterProps> = (props: FilterProps): JSX.Element => {
  const { handleFilterDataChange, handleRemove, filterId } = props

  const harvestingFields = [
    {
      id: "expectedHarvestStart",
      label: "From Date",
      placeholder: "",
      type: "date",
      validationType: "",
      value: '',
      validations: [],
    },
    {
      id: "expectedHarvestEnd",
      label: "To Date",
      placeholder: "",
      type: "date",
      validationType: "",
      value: '',
      validations: [],
    }
  ]

  const [isApplied, setIsApplied] = useState(false)

  const handleFitlerChange = (status: boolean, plotIds: FilterDataValue) => {
    handleFilterDataChange(filterId, status, plotIds)
    setIsApplied(status)
  }

  const onSubmit = (values: KeyValues) => {
    let payload = {
      expectedHarvestStart: getDate(values.expectedHarvestStart as string, "MM/DD/YYYY"),
      expectedHarvestEnd: getDate(values.expectedHarvestEnd as string, "MM/DD/YYYY")
    }
    getCrops(payload)
      .then((res: KeyValues[]) => {
        const plotIds = res.map((plotId) => plotId.plotId) as string[];
        handleFitlerChange(true, plotIds)
      })
  }

  const onReset = () => {
    handleFitlerChange(false, undefined)
  }

  return (
    <>
      <Typography variant="subtitle2" mt={1} >Harvesting</Typography>
      <div style={{
        backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
        margin: '0px 0 15px 0',
        padding: '5px 10px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center'
      }} >
        <FormikApp
          formFields={harvestingFields}
          onSubmit={onSubmit}
          filter
          showReset
          onReset={() => { }}
          removeField={() => handleRemove(filterId)}
        />
      </div>
    </>
  )
}

export default memo(HarvestingFilter);