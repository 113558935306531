import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getAllNotifications } from '../services/farm.service';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router';
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {
    ColDef,
    GridOptions,
    GridReadyEvent,
    SideBarDef,
    SortChangedEvent,
    FilterChangedEvent,
} from 'ag-grid-community';
import { FollowUpCols } from '../constants/columnDefs';
import { getPlotsByPlotIds, getAllFollowUps } from '../services/plot.service';
import { PlotDetails, PlotData, Activities } from 'src/pages';
import { Loader } from '../components';
import ListItem from '@mui/material/ListItem';

const FollowUpsPage = (): JSX.Element => {


    const [cellPlotId, setCellPlotId] = useState<string>("");
    const [farmUserId, setFarmUserId] = useState<string>("");
    const [doNotContact, setDoNotContact] = useState<Boolean>(false);

    const [showGrid, setShowGrid] = useState<Boolean>(true);
    const [showPlot, setShowPlot] = useState<Boolean>(false);
    const [showPlotData, setShowPlotData] = useState<Boolean>(false);
    const [showFarm, setShowFarm] = useState<Boolean>(false);

    const [showLoader, setShowLoader] = useState<Boolean>(false);
    const [tableData, setTableData] = useState<any[]>([]);
    const gridRef = useRef<AgGridReact>(null);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const gridStyleTwo = useMemo(() => ({ height: '100%', width: '100%', display: 'none' }), []);
    const navigate = useNavigate();

    const user = window.localStorage.getItem('userId');
    const openTheWidnow = (id:string) => {
        const data = ['', user, '', id]; // url params as array
        const windowFeatures = "left=650,top=25,width=650,height=650";
    
        // finding base URL
        let splitURL = window.location.href.split('://');
        const baseURL = splitURL[1].split('/')[0];
        window.open(`${splitURL[0]}://${baseURL}/conversations?data=${data}`, ' ', windowFeatures);
      }

    const backToPlot = () => {
        setShowGrid(false);
        setShowPlot(true);
        setShowPlotData(false);
        setShowFarm(false);
    }

   const getAllFollowUpsFunction = async ()=>{
    const res = await getAllFollowUps();
    setTableData(res);
   }

    useEffect(()=>{
        getAllFollowUpsFunction();
    },[])

    const gridOptions: GridOptions = {
        columnDefs: FollowUpCols,
        rowData: tableData,
        rowSelection: 'single',
        animateRows: true,
        overlayLoadingTemplate:
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
        onCellClicked: (e) => {
            // do some action
            const {farmUser} = e.data;
            if(!farmUser){  // means there is no doNotContact property exists.
                setDoNotContact(false);
            }
            else{
                setDoNotContact(e.data.farmUser.doNotContact);
            }
            setCellPlotId(e.data.plotId)

            // setShowGrid(false);
            // setShowPlot(true);
            // setShowPlotData(false);
            // setShowFarm(false);
            setFarmUserId(e.data.farmUserId);
            openTheWidnow(e.data.farmUserId)
            return;
        }
    };

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            resizable: true,
            sortable: true,
            floatingFilter: true,
            menuTabs: ['filterMenuTab'],
        };
    }, []);

    const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
        return {
            toolPanels: ['filters'],
        };
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
    }, []);

    const handleColumnSort = (event: SortChangedEvent) => {
        const columns = event.columnApi.getColumnState();
        let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId
    }

    const handleColumnFilter = (event: FilterChangedEvent) => {
        if (event.afterFloatingFilter) { }
    }

    const onPaginationChanged = useCallback(() => { }, []);

    return (
        <div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', textDecoration: 'underline' }}>
                <Typography m={1} variant='h5'>Follow Ups</Typography>
            </div>

            {(showLoader == true) ?
                <div style={{ marginTop: '20vh', width: '95%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <Loader />
                </div> :
                    <div data-testid="ag-grid-noti" style={(showGrid) ? gridStyle : gridStyleTwo} className="ag-theme-alpine">
                            <Grid p={3}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={tableData}
                                    columnDefs={FollowUpCols}
                                    onGridReady={onGridReady}
                                    defaultColDef={defaultColDef}
                                    sideBar={sideBar}
                                    animateRows={true}
                                    pagination={true}
                                    paginationPageSize={15}
                                    gridOptions={gridOptions}
                                    domLayout="autoHeight"
                                    onPaginationChanged={onPaginationChanged}
                                    onSortChanged={handleColumnSort}
                                    onFilterChanged={handleColumnFilter}
                                ></AgGridReact>
                            </Grid>
                    </div>
            }
          {showPlot && <PlotDetails cellPlotId={cellPlotId} setShowGrid={setShowGrid} setShowPlot={setShowPlot} setShowPlotData={setShowPlotData} setShowFarm={setShowFarm} />}
          {showPlotData && <PlotData backToPlot={backToPlot} propsPlotId={cellPlotId} />}
        {showFarm && <Activities backToPlot={backToPlot} propsPlotId={cellPlotId} />}
                
        </div>
    )
}
export default FollowUpsPage