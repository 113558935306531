import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
const PhoneRenderer:any = (props:any): JSX.Element => {
    return (
           <div style={{display:'flex', flexDirection:'row', justifyContent:'left', gap:'5px'}}>
            {(props.data.farmUser?.doNotContact==true)?  <PhoneDisabledIcon color="error" style={{marginTop:'8px'}}/>:null}
            <p style={{marginTop:'-0.5px'}}>{props.data.plotId}</p>
           
           </div>
    )
}

export default PhoneRenderer