import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Skeleton } from 'src/components';
import FormikApp from 'src/components/DynamicForm';
import { getFarmAnalytics } from 'src/services/farm.service';
import { getDate, sortByKey } from 'src/utils/helper';
import { ActivitiesTable, BarChart, Chip, RegionPieChart } from './components';
import { ActivityTypes, ActivityTypesChartData, ActivityTypesState, BarChartActivities, ChartClickProps, FormResponse, RegionT } from './types';
import { formatInKeyValue, formatResponse, generatePieChartOptions, getActivityData, getRecentMonth } from './utils';

interface RenderingData {
  state: string;
  country: string;
  date: string;
}

const FarmActivities = (): JSX.Element => {
  const [farmActivities, setFarmActivities] = useState<any>();
  const [showFarmActivity, setshowFarmActivity] = useState<boolean>(true);

  const tablesRef = useRef<HTMLDivElement>(null);
  const barChartRef = useRef<HTMLDivElement>(null);

  // activites by activity type
  // combination of suggested: (true/false) done: (true/false) **except false-false
  const [activityTypes, setActivityTypes] = useState<ActivityTypesState>({
    suggestedDone: [],
    notSuggestedDone: [],
    suggestedPending: [],
    all: []
  });

  const [activityTypesChartData, setActivityTypesChartData] = useState<ActivityTypesChartData>()
  const [barChartActivities, setBarChartActivities] = useState<BarChartActivities[]>([]);
  const [regionPieChartActivities, setRegionPieChartActivities] = useState<any[]>([]);
  const [barChartData, setBarChartData] = useState<any>(); // to be displayed on bar chart

  const [showTable, setShowTable] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any[]>([]); // activities to be displayed on grid

  // rendering data: to be displayed on chips
  const [renderingData, setRenderingData] = useState<RenderingData>({
    state: 'All',
    country: 'India',
    date: ''
  })

  // loading state of farm-activites api call
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // filter dates
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');

  const [cnt, setCnt] = useState<number>(0)
  const fields = [
    {
      id: "fromDate",
      label: "From Date",
      placeholder: "",
      type: "date",
      validationType: "",
      value: '',
      validations: [],
    },
    {
      id: "toDate",
      label: "To Date",
      placeholder: "",
      type: "date",
      validationType: "",
      value: '',
      validations: [],
    },
  ]

  // initial api call
  useEffect(() => {
    const { fromDate, toDate } = getRecentMonth();
    callFarmAnalyticsApi(fromDate, toDate);
  }, [])

  useEffect(() => {
    updateRenderingDataTitle('country', 'India')
    updateRenderingDataTitle('state', 'All')
    updateRenderingDataTitle('date', getSelectedDate())
  }, [fromDate, toDate])

  // update farm activity type whenever farmActivities updates
  const updateCharts = (updatedData: any[]) => {
    if (!!updatedData && updatedData.length > 0) {
      const { pieChartData, updatedActivityTypes } = getActivityData(updatedData)
      setActivityTypesChartData(pieChartData);
      setActivityTypes(updatedActivityTypes);
      setBarChartData(updatedData);
      updateBarChart(updatedData);
    }
  }

  useEffect(() => {
    setCnt(cnt + 1);
  }, [barChartActivities])

  // update grid whenever barchart updates
  useEffect(() => {
    setTableData(barChartData)
  }, [barChartData])

  const scrollToTable = () => {
    if (tablesRef.current) {
      const { top } = tablesRef.current.getBoundingClientRect();
      window.scrollTo({ top: window.pageYOffset + top, behavior: 'smooth' });
    }
  }

  const scrollToBarchart = () => {
    if (barChartRef.current) {
      const { top } = barChartRef.current.getBoundingClientRect();
      window.scrollTo({ top: window.pageYOffset + top, behavior: 'smooth' });
    }
  }

  const callFarmAnalyticsApi = (fromDate: string, toDate: string): void => {
    setFromDate(fromDate);
    setToDate(toDate);

    const payload = { fromDate, toDate }
    const include = [
      {
        "relation": "plot",
        "scope": {
          "include": [{ "relation": "plotLocation" }],
          "fields": ["plotLocation", "plotId", "name", "place"]
        }
      }
    ];

    const fields = ["done", "suggested", "plotId", "farmActivityTypeId", "doneDate", "suggestedDate", "notes"]

    setIsLoading(true);
    getFarmAnalytics(payload, {
      include, fields
    })
      .then(res => {
        setFarmActivities(res);
        updateRegionPieChart(res);
      })
      .finally(() => setIsLoading(false))
  }

  // filter by date
  const onSubmit = (values: FormResponse): void => {
    const fromDate = getDate(moment().format(values.fromDate), 'MM/DD/YYYY');
    const toDate = getDate(moment().format(values.toDate), 'MM/DD/YYYY');
    callFarmAnalyticsApi(fromDate, toDate)
  }

  const updateRegionPieChart = (res: any) => {
    const { multiSeriesPieChartData } = formatResponse(res);
    const regionPieChartActivitiesKeyValueArray = formatInKeyValue(multiSeriesPieChartData);
    setRegionPieChartActivities(regionPieChartActivitiesKeyValueArray);
    updateCharts(res);
  }

  const updateBarChart = (res: any, apiCall: boolean = false) => {
    // if calling after api call, then update farmactivites too
    if (apiCall) {
      setFarmActivities(res)
    }

    const { barChartData } = formatResponse(res);
    const barChartActivitiesKeyValueArray = formatInKeyValue(barChartData);
    setBarChartActivities(sortByKey(barChartActivitiesKeyValueArray, 'key'))
  }

  // clear function for date filtering
  const handleClear = (): void => {
    const { fromDate, toDate } = getRecentMonth()
    callFarmAnalyticsApi(fromDate, toDate);
  }

  // function to handle onClick event on PieChart
  const handlePieChartClick = (_: any, chartElements: any) => {
    if (chartElements && chartElements.length > 0) {
      const datasetIndex = chartElements[0].datasetIndex;
      const sIndex = chartElements[0].index;
      const sLabel: ActivityTypes = activityTypesChartData!.labels[sIndex] as any;

      /*
        datasetIndex 1 -> 'ALL', take all activities, else take only selected category
      */
      const newData = datasetIndex === 1 ? [
        ...activityTypes['notSuggestedDone'],
        ...activityTypes['suggestedDone'],
        ...activityTypes['suggestedPending'],
      ] : activityTypes[sLabel]

      updateBarChart(newData)
      setBarChartData(newData);
      scrollToBarchart();
    }
  };

  // function to handle onClick event on BarChart
  const handleBarChartClicked = (data: ChartClickProps): void => {
    const { dataOf, month, isRightClick } = data as any;

    // filter based on farmActivityType and clicked month
    // if its right click, just get data of clicked month
    // TODO: further, we can check which labels are disabled by the user to filter accordingly
    const filteredData = barChartData.filter((data: any) => (data.farmActivityTypeId === dataOf || isRightClick) && month === moment(data.doneDate).format('MM/YYYY'))

    setTableData(filteredData);
    scrollToTable();
  }

  const chartOptions = generatePieChartOptions(handlePieChartClick);

  const handleRegionChartClick = (region: string, regionType: RegionT) => {
    const filteredData = farmActivities.filter((activity: any) => {
      const location = activity.plot?.plotLocation?.location
      if (regionType === 'continent')
        return location?.Region?.EnglishName === region;
      if (regionType === 'country')
        return location?.Country?.EnglishName === region;

      return location?.AdministrativeArea?.EnglishName === region;
    })

    updateRenderingDataTitle(regionType, region);
    updateCharts(filteredData);
    if (regionType === 'country' || regionType === 'continent')
      updateRenderingDataTitle('state', 'All');
  }

  const getSelectedDate = (): string => {
    return `${getDate(fromDate, 'MMM YYYY')} - ${getDate(toDate, 'MMM YYYY')}`;
  }

  const updateRenderingDataTitle = (key: string, value: string) => {
    setRenderingData({
      ...renderingData,
      [key]: value
    })
  }

  return (
    <Grid p={2} >
      <div style={{
        margin: '0px 0 15px 0',
        padding: '5px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }} >
        <FormikApp
          formFields={fields}
          onSubmit={(values: FormResponse) => onSubmit(values)}
          onReset={() => { }}
          filter={true}
          showReset={true}
          removeField={handleClear}
        />
      </div>

      <Grid container justifyContent={'center'} alignItems={'center'} mb={3} >
        <Chip label='Country' value={renderingData.country} />
        <Chip label='State' value={renderingData.state} />
        <Chip label='Date' value={renderingData.date} />
        <Typography></Typography>
      </Grid>

      {
        (!!activityTypesChartData && !isLoading) ?
          <>
            <Grid container display={'flex'} justifyContent={'space-evenly'}  >
              <Grid xs={3}>
                <RegionPieChart data={regionPieChartActivities} onPieClick={handleRegionChartClick} />
              </Grid>
              <Grid xs={3}>
                <Pie options={chartOptions} data={activityTypesChartData!} />
              </Grid>
            </Grid>
            <Grid ref={barChartRef} display={'flex'} justifyContent={'center'} >
              <BarChart key={`bar-chart-${cnt}`} data={barChartActivities as any[]} handleChartClick={handleBarChartClicked} />
            </Grid>
          </> :
          <Grid>
            <Grid mt={2} height={350} container alignItems={'center'} justifyContent={'space-evenly'} >
              <Skeleton.PieChart width={165} height={165} />
              <Skeleton.PieChart width={150} height={150} />
            </Grid>
            <Grid container justifyContent={'center'} mt={2}>
              <Skeleton.BarChart width={600} height={280} noOfBars={4} barsWidth={40} />
            </Grid>
          </Grid>
      }

      {
        <Grid ref={tablesRef} p={3} container display={showTable ? 'block' : 'none'} >
          {(!!tableData && !isLoading) ?
            <ActivitiesTable tableData={tableData} fromDate={fromDate} toDate={toDate} setshowFarmActivity={setshowFarmActivity} showFarmActivity={showFarmActivity} /> :
            <Grid mt={16} xs={12}> <Skeleton.Table width={12} height={500} noOfRows={8} /> </Grid>
          }
        </Grid>
      }
    </Grid >
  )
}

export default FarmActivities