import React, { useEffect, useState } from "react";
import { Grid, Typography, Skeleton, Tabs, Tab } from "@mui/material";
import { getPlotInfo } from "src/services/plot.service";
import { useParams } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { DisplayProperty } from "./PlotDetails";
import moment from "moment";
import { Link } from "react-router-dom";
import { date } from "yup";

const RectangleBox = ({
  name = "",
  val = "",
  islast = false,
  width1 = "70%",
  width2 = "30%",
  isCentre = false,
  isThird = false,
  third = "",
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "96%",
        margin: "auto",
        borderWidth: 1,
        borderBottomWidth: islast ? 1 : 0.5,
        borderStyle: "dotted",
        borderColor: "#afafaf",
      }}
    >
      <Typography
        style={{
          width: isThird ? "33%" : width1,
          alignSelf: "center",
          padding: "0 5px",
          textAlign: isCentre || isThird ? "center" : "left",
        }}
      >
        {String(name)}
      </Typography>
      <div
        style={{
          borderLeft: "1px dotted #afafaf",
          alignSelf: "center",
          height: 20,
        }}
      />
      <Typography
        style={{
          width: isThird ? "33%" : width2,
          alignSelf: "center",
          padding: "0 5px",
          textAlign: isCentre || isThird ? "center" : "left",
        }}
      >
        {String(val)}
      </Typography>
      {isThird && (
        <>
          <div
            style={{
              borderLeft: "1px dotted #afafaf",
              alignSelf: "center",
              height: 20,
            }}
          />
          <Typography
            style={{
              width: "33%",
              alignSelf: "center",
              padding: "0 5px",
              textAlign: isCentre || isThird ? "center" : "left",
            }}
          >
            {String(third)}
          </Typography>
        </>
      )}
    </div>
  );
};
const DataSkeleton = () => (
  <Grid>
    <Grid width={"90%"} mx={"auto"} my={2}>
      <Skeleton
        variant="rectangular"
        height={450}
        style={{ borderRadius: "15px", margin: "15px 0" }}
      />
    </Grid>
  </Grid>
);

const PlotInfo: React.FC = () => {
  const { plotId } = useParams();

  const [data, setData] = useState<any>();
  const [noFarm, setNoFarm] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [activities, setActivities] = useState<any>({});
  const [sprayArray, setSprayArray] = useState([]);
  const [nutrientsArray, setNutrientsArray] = useState([]);
  const [weatherSummary, setWeatherSummary] = useState<any>({});
  const [waterUsed, setWaterUsed] = useState<any>("—");
  const [irrigationHour, setIrrigationHour] = useState<any>(null);
  const [irrigationInterval, setIrrigationInterval] = useState<any>(null);
  const [savedWater, setSavedWater] = useState<any>("—");
  const [savedWaterPercent, setSavedWaterPercent] = useState<any>("—");
  const [irrigationCostSaved, setIrrigationCostSaved] = useState<any>("—");
  const [overallCostSaved, setOverallCostSaved] = useState<any>(30000);
  const [savedCarbonEmission, setSavedCarbonEmission] = useState<any>("—");

  const [value, setValue] = useState(0);

  const sprayData = (l: any) => {
    let spraySet: any = {};
    l?.forEach((spray: any) => {
      spray.analysisResult?.CHEM?.forEach((chem: any, i: any) => {
        let ch = typeof chem === "object" ? chem.chemicalName : chem;
        if (spraySet[ch]) spraySet[ch]++;
        else spraySet[ch] = 1;
      });
    });
    let sprayArray: any = [];
    Object.keys(spraySet).forEach((nutrient) => {
      sprayArray.push({
        x: nutrient,
        y: spraySet[nutrient],
        label: nutrient,
      });
    });
    setSprayArray(sprayArray);
  };

  const nutrientsData = (l: any) => {
    let nutrientSet: any = {};
    let nutrientUnit: any = {};
    l?.forEach((nutrient: any) => {
      nutrient.analysisResult?.CHEM?.forEach((chem: any, i: any) => {
        let ch = (chem.chemicalName || chem.chemicalId || "").toLowerCase();
        let unit = chem.unit || "";
        let nVal = chem.quantity || 0;
        switch (unit) {
          case "ml":
            nVal /= 1000;
            unit = "litre";
            break;
          case "gm":
          case "g":
            nVal /= 1000;
            unit = "kg";
            break;
          case "liter":
          case "litre":
          case "litres":
          case "l":
          case "lit":
            if (nVal <= 1) unit = "litre";
            else unit = "litres";
            break;
        }

        if (nutrientSet[ch]) {
          nutrientSet[ch] += nVal;
          if (unit !== "") {
            nutrientUnit[ch] = unit;
          }
        } else {
          nutrientSet[ch] = nVal;
          nutrientUnit[ch] = unit;
        }
      });
    });
    let nutrientsArray: any = [];
    Object.keys(nutrientSet).forEach((nutrient) => {
      nutrientUnit[nutrient] || nutrientUnit[nutrient] !== ""
        ? nutrientsArray.push({
            nutrient,
            quantity: `${nutrientSet[nutrient]} ${nutrientUnit[nutrient]}`,
          })
        : nutrientsArray.push({ nutrient, quantity: `—` });
    });
    setNutrientsArray(nutrientsArray);
  };

  const getWaterUsed = () => {
    if (
      activities?.plot &&
      activities?.plot?.rowDistance &&
      activities?.plot?.plantDistance &&
      activities?.plot?.perPlantDripper &&
      activities?.plot?.dripperDischarge &&
      activities?.plot?.area
    ) {
      let hoursIrrigated =
        activities?.farmActivities?.irrigation?.reduce(
          (sum: any, irrigation: any) =>
            sum + (parseInt(irrigation.notes) || 0),
          0
        ) / 60;
      let dripperDischarge = activities.plot?.dripperDischarge;
      let plotArea = activities?.plot?.area;
      let rowDistance = activities?.plot?.rowDistance;
      let plantDistance = activities?.plot?.plantDistance;
      let perPlantDripper = activities?.plot?.perPlantDripper;

      let dipperNumber = plotArea * 43560 * perPlantDripper;

      dipperNumber /= rowDistance * plantDistance;
      let waterUsage = dipperNumber * dripperDischarge * hoursIrrigated;
      if (waterUsage) {
        setWaterUsed(`${waterUsage.toFixed(2)} Litres`);
        let dayDiff = moment(new Date()).diff(data.sowingDate, "day");
        dayDiff *= 100000;
        dayDiff -= waterUsage;
        setSavedWater(dayDiff + " Litres");
        setSavedWaterPercent(
          `${((dayDiff / (dayDiff + waterUsage)) * 100).toFixed(2)} %`
        );
        setIrrigationCostSaved("₹ " + ((dayDiff * 0.5) / 1000).toFixed(0));
        setOverallCostSaved(((dayDiff * 0.5) / 1000 + 30000).toFixed(0));
        setSavedCarbonEmission(
          `${((300 * dayDiff) / 10000 / 1000).toFixed(2)} Kg`
        );
      }
    }
  };

  const getIrrigationHour = () => {
    let irrigationTime: any =
      activities?.farmActivities?.irrigation?.reduce(
        (sum: any, irrigation: any) => sum + (parseInt(irrigation.notes) || 0),
        0
      ) / 60 || 0;
    irrigationTime?.toFixed(2);

    let irrigationHour = Math.floor(irrigationTime);
    irrigationTime = irrigationTime - irrigationHour;
    irrigationTime = Math.floor(irrigationTime * 60);
    irrigationTime = irrigationHour + " Hrs : " + irrigationTime + " Min";
    return setIrrigationHour(irrigationTime);
  };

  const getIrrigationInterval = () => {
    if (activities?.farmActivities?.irrigation?.length > 1) {
      let irrigationData = activities?.farmActivities?.irrigation.sort(
        (a: any, b: any) => {
          const dateA: any = new Date(a.doneDate);
          const dateB: any = new Date(b.doneDate);
          return dateB - dateA;
        }
      );
      if (activities?.farmActivities?.irrigation?.length === 2) {
        setIrrigationInterval(
          `${moment(irrigationData[0]?.doneDate)
            .diff(irrigationData[1]?.doneDate, "days")
            .toFixed(0)} days`
        );
      } else {
        let days = 0;
        for (let i = 0; i < irrigationData?.length - 1; i++)
          days += moment(irrigationData[i]?.doneDate).diff(
            irrigationData[i + 1]?.doneDate,
            "days"
          );
        days /= irrigationData?.length - 1;
        setIrrigationInterval(`${days.toFixed(0)} days`);
      }
    } else return setIrrigationInterval("—");
  };

  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" + data?.latitude + "," + data?.longitude
    );
  };

  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getPlotInfo(plotId).then((res) => {
      setData(res);
      if (res?.farmerName || res?.farmName) {
        setNoFarm(true);
      }
      setIsReady(true);
    });
  }, []);

  useEffect(() => {
    if (data?.insights && data?.weatherSummary) {
      setActivities(data.insights);
      setWeatherSummary(data.weatherSummary);
    }
  }, [data]);

  useEffect(() => {
    if (activities) {
      sprayData(activities?.farmActivities?.spray);
      nutrientsData(activities?.farmActivities?.nutrient);
      getWaterUsed();
      getIrrigationHour();
      getIrrigationInterval();
    }
  }, [activities]);

  return (
    <Grid width={"90%"} mx={"auto"}>
      {isReady ? (
        <Grid
          width={"90%"}
          mx={"auto"}
          py={3}
          my={2}
          boxShadow={2}
          borderRadius={4}
        >
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid display={"flex"} item alignItems={"center"}>
              <Typography m={1} ml={3} variant="h5">
                Farm Information
              </Typography>
            </Grid>
          </Grid>
          {noFarm ? (
            <Grid>
              <Grid item px={2} py={1} m={1}>
                <DisplayProperty
                  property={"Farm Name"}
                  value={data?.farmName}
                />
                <DisplayProperty
                  property={"Farmer Name"}
                  value={data?.farmerName}
                />
                <Grid display={"flex"} my={1}>
                  <Typography sx={{ fontWeight: "600" }} width={300}>
                    Location:
                  </Typography>
                  <Grid container>
                    <Typography textTransform={"none"}>
                      {data?.location}
                    </Typography>
                    <Link
                      to=""
                      onClick={showInMapClicked}
                      style={{ marginLeft: 3 }}
                    >
                      <LocationOnIcon color="info" />
                    </Link>
                  </Grid>
                </Grid>

                <DisplayProperty
                  property={"Crop Name"}
                  value={data?.cropName}
                />
                <DisplayProperty
                  property={"Crop Variety"}
                  value={data?.cropvariety}
                />
                <DisplayProperty
                  property={"Sowing Date"}
                  value={moment(data?.sowingDate).format("LL")}
                />
                <DisplayProperty
                  property={"Harvesting Date"}
                  value={moment(data?.harvestingDate).format("LL")}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid item px={2} py={1} m={1}>
              <Typography
                color={"red"}
                style={{
                  fontSize: 25,
                  textAlign: "center",
                }}
              >
                No Data Found!
              </Typography>
            </Grid>
          )}

          {noFarm ?
            <>
              <Tabs value={value} variant="fullWidth">
                <Tab label="Impact Summary" onClick={() => handleChange(0)} />
                <Tab label="Weather Summary" onClick={() => handleChange(1)} />
              </Tabs>
              <Grid item px={2} py={1} m={1}>
                {value === 0 ? (
                  <Grid>
                    <Grid my={1.2}>
                      <Typography
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                      >
                        Irrigation
                      </Typography>
                      <RectangleBox name={"Total Water Used"} val={waterUsed} />
                      <RectangleBox
                        name={"Total Irrigation Events"}
                        val={activities?.irrigationEvents?.length || 0}
                      />
                      <RectangleBox
                        name={"Total Irrigation Hours"}
                        val={irrigationHour}
                      />
                      <RectangleBox
                        name={"Average Interval between Irrigations"}
                        val={irrigationInterval}
                      />
                    </Grid>
                    <Grid my={1.2}>
                      <Typography width={"96%"} mx={"auto"} fontWeight={550}>
                        Impact on Environment
                      </Typography>
                      <Typography
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                      >
                        Water Saved
                      </Typography>
                      <RectangleBox
                        name={"Amount"}
                        val={"Percentage"}
                        width1={"50%"}
                        width2={"50%"}
                        isCentre
                      />
                      <RectangleBox
                        name={savedWater}
                        val={savedWaterPercent}
                        width1={"50%"}
                        width2={"50%"}
                        islast
                        isCentre
                      />
                      <Typography
                        mt={1.2}
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                      >
                        Carbon Emission
                      </Typography>
                      <RectangleBox
                        name={"Carbon Emission Saved"}
                        val={savedCarbonEmission}
                        islast
                      />
                      <Typography
                        mt={1.2}
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                      >
                        Residues
                      </Typography>
                      <RectangleBox
                        name={"Residues against mandatory MRL's"}
                        val={"—"}
                        islast
                      />
                    </Grid>
                    <Grid my={1.2}>
                      <Typography
                        width={"96%"}
                        mt={0.4}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                        marginBottom={1.2}
                      >
                        Impact on Farmer
                      </Typography>
                      <RectangleBox name={"Input Cost Saved"} val={"₹ 30000"} />
                      <RectangleBox
                        name={"Irrigation Cost Saved"}
                        val={irrigationCostSaved}
                      />
                      <RectangleBox
                        name={"Overall reduction in cost of cultivation YOY"}
                        val={"₹ " + overallCostSaved}
                      />
                      <RectangleBox name={"Yield Increase YOY"} val={"—"} />
                      <RectangleBox name={"Price realization YOY"} val={"—"} />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid>
                    <Grid>
                      <Typography
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                      >
                        Air Tempeature(℃)
                      </Typography>
                      <RectangleBox
                        name={"Minimum"}
                        val={"Maximum"}
                        third={"Average"}
                        isThird
                      />
                      <RectangleBox
                        name={weatherSummary?.sensor?.minAirTemp?.toFixed(2) || "—"}
                        val={weatherSummary?.sensor?.maxAirTemp?.toFixed(2) || "—"}
                        third={weatherSummary?.sensor?.airTemp?.toFixed(2) || "—"}
                        isThird
                        islast
                      />
                      <Typography
                        mt={1.2}
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                      >
                        Air Humidity(%)
                      </Typography>
                      <RectangleBox
                        name={"Minimum"}
                        val={"Maximum"}
                        third={"Average"}
                        isThird
                      />
                      <RectangleBox
                        name={
                          weatherSummary?.sensor?.minairHumidity?.toFixed(2) || "—"
                        }
                        val={
                          weatherSummary?.sensor?.maxairHumidity?.toFixed(2) || "—"
                        }
                        third={
                          weatherSummary?.sensor?.airHumidity?.toFixed(2) || "—"
                        }
                        isThird
                        islast
                      />
                      <Typography
                        mt={1.2}
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                      >
                        Rainfall(mm)
                      </Typography>
                      <RectangleBox
                        name={"Total RainFall"}
                        val={weatherSummary?.sensor?.rainFall?.toFixed(2) || "—"}
                        islast
                      />
                      <Typography
                        mt={1.2}
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                      >
                        Leaf Wetness Hours
                      </Typography>
                      <RectangleBox
                        name={"Total"}
                        val={"Average"}
                        width1={"50%"}
                        width2={"50%"}
                        isCentre
                      />
                      <RectangleBox
                        name={
                          weatherSummary?.sensor?.leafWetnessHours?.toFixed(0) ||
                          "—"
                        }
                        val={
                          weatherSummary?.sensor?.avgleafWetnessHours?.toFixed(0) ||
                          "—"
                        }
                        width1={"50%"}
                        width2={"50%"}
                        islast
                        isCentre
                      />
                      <Typography
                        mt={1.2}
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                      >
                        Evapotranspiration
                      </Typography>
                      <RectangleBox
                        name={"Total"}
                        val={"Average"}
                        width1={"50%"}
                        width2={"50%"}
                        isCentre
                      />
                      <RectangleBox
                        name={weatherSummary?.eto?.sum?.toFixed(2) || "—"}
                        val={weatherSummary?.eto?.avg?.toFixed(2) || "—"}
                        width1={"50%"}
                        width2={"50%"}
                        islast
                        isCentre
                      />
                      <Typography
                        mt={1.2}
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                      >
                        Soil Tempeature(℃)
                      </Typography>
                      <RectangleBox
                        name={"Minimum"}
                        val={"Maximum"}
                        third={"Average"}
                        isThird
                      />
                      <RectangleBox
                        name={
                          weatherSummary?.sensor?.minsoilTemp?.toFixed(2) || "—"
                        }
                        val={weatherSummary?.sensor?.maxsoilTemp?.toFixed(2) || "—"}
                        third={weatherSummary?.sensor?.soilTemp?.toFixed(2) || "—"}
                        isThird
                        islast
                      />
                      <Typography
                        mt={1.2}
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                        display={"flex"}
                        flexDirection={"row"}
                      >
                        Average Sunlight:{" "}
                        <Typography ml={1}>
                          {(weatherSummary?.sensor?.lightIntensity?.toFixed(2) ||
                            0) + ` Lux` || "—"}{" "}
                        </Typography>
                      </Typography>
                      <Typography
                        mt={1.2}
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                        display={"flex"}
                        flexDirection={"row"}
                      >
                        Average Sunshine Hours:{" "}
                        <Typography ml={1}>
                          {weatherSummary?.sensor?.sunshineHours?.toFixed(0) ||
                            0 ||
                            "—"}
                        </Typography>
                      </Typography>
                      <Typography
                        mt={1.2}
                        width={"96%"}
                        mb={0.5}
                        mx={"auto"}
                        fontWeight={550}
                        display={"flex"}
                        flexDirection={"row"}
                      >
                        Accumulated GDD:{" "}
                        <Typography ml={1}>
                          {weatherSummary?.gdd?.sum?.toFixed(2) || "—"}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </>
            :
            <></>
          }

          
        </Grid>
      ) : (
        <DataSkeleton />
      )}
    </Grid>
  );
};

export default PlotInfo;
