import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Mappings } from '../../constants/WordMapping'
import { getDate, getValue } from '../../utils/helper'

interface WeatherCardProps {
  data: any
}

const WeatherCard: React.FC<WeatherCardProps> = (props: WeatherCardProps): JSX.Element => {
  const { data } = props

  return (
    <Grid m={3} border={1} borderRadius={4} p={3} container >
      {
        Object.keys(data).map((property) => {
          const toIgnore = ['sourceId', 'id', 'locationId', 'modifiedAt', 'createdAt', 'precipitationType', 'hasPrecipitation', 'iconPhrase', 'precipitationAccumulation', 'precipitationIntensity', 'weatherIcon']
          if (toIgnore.includes(property)) {
            return <></>
          }
          const Dates = ['created_at', 'timestamp'];
          const value = Dates.includes(property) ? getDate(data[property]) : getValue(data[property])
          return (
            <Grid item width={400} xs={6} mb={1}>
              <Typography variant='subtitle2' color={'#545454'} >{Mappings[property] ?? property}</Typography>
              <Typography >{value}</Typography>
            </Grid>
          )
        })
      }
    </Grid>
  )
}

export default WeatherCard