import { Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { InteractionItem } from 'chart.js'
import { getElementAtEvent } from 'react-chartjs-2';
import { ChartClickProps } from 'src/pages/FarmActivities/types';
import { generateBarChartOptions } from 'src/pages/FarmActivities/utils';
import BarChartOptions from './BarChartOptions';

export interface ClickPosition {
  x: number;
  y: number;
}

interface Props {
  data: any;
  filterType?: string;
  handleChartClick?: (arg: ChartClickProps) => void;
}

export interface Dataset {
  label: string;
  data: number[];
  backgroundColor: string;
  barThickness?: number;
  barPercentage?: number;
};

const COLORS = ['#B5D3B7', '#8BCD8F', '#58C96B', '#30C24F', '#1FAA3D', '#188E33', '#137129', '#0C571D'];

const TOTAL_COLORS = COLORS.length;

const BarChart = (props: Props): JSX.Element => {
  const { data, handleChartClick } = props;
  const [labels, setLabels] = useState<any[]>([]);
  const [datasetData, setDatasetData] = useState<any>([]);
  const [chartData, setChartData] = useState<any>({});

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [clickedPosition, setClickedPosition] = useState<ClickPosition | null>();

  const [clickedData, setClickedData] = useState<ChartClickProps>()

  useEffect(() => {
    setLabels((data)?.map((dt: any) => dt.key));
    setDatasetData((data)?.map((dt: any) => dt.value));
  }, []);

  useEffect(() => {
    setChartData({
      labels,
      datasets: generateDataset(datasetData),
    });
  }, [labels])

  // bar chart option
  const options: any = generateBarChartOptions();

  const chartRef = useRef<any>(null);

  const onBarChartClicked = (element: InteractionItem[], eventPosition: ClickPosition) => {
    if (!element.length || !handleChartClick) {
      setOpenModal(false);
      return;
    }

    const { datasetIndex, index } = element[0];
    const { labels, datasets } = chartData;

    setClickedPosition({
      x: eventPosition.x,
      y: eventPosition.y + window.scrollY //handle screen scrolling
    });

    setOpenModal(true);

    setClickedData({
      dataOf: datasets[datasetIndex].label,
      month: labels[index]
    })
  };

  const onClick = (event: any) => {
    const { current: chart } = chartRef;

    if (!chart) return;

    onBarChartClicked(
      getElementAtEvent(chart, event),
      { x: event.clientX, y: event.clientY }
    );
  };

  const viewSingleActivity = () => {
    if (!clickedData || !handleChartClick) return;
    handleChartClick({
      dataOf: clickedData.dataOf,
      month: clickedData.month,
      isRightClick: false
    })
    closeModal();
  }

  const viewMonthsActivities = () => {
    if (!clickedData || !handleChartClick) return;
    handleChartClick({
      dataOf: clickedData.dataOf,
      month: clickedData.month,
      isRightClick: true
    })
    closeModal();
  }

  const closeModal = () => {
    setOpenModal(false);
    setClickedPosition(null);
  }


  return (
    <div style={{
      width: '70%',
    }}>
      {
        (labels.length > 0) &&
        <div>
          {(openModal && !!clickedPosition) &&
            <BarChartOptions clickedPosition={clickedPosition} viewMonthsActivities={viewMonthsActivities} viewSingleActivity={viewSingleActivity} closeModal={closeModal} clickedData={clickedData!} />
          }
          <Bar ref={chartRef} onClick={onClick} options={options} data={chartData} />
          <Typography textAlign={'center'} variant={'subtitle1'} mt={1} >Month wise Activity count</Typography>
        </div>
      }
    </div >
  )
}

const generateDataset = (data: any[]): Dataset[] => {

  /*data:
    [
      {irrigation: 121, spray: 150, culturalPractice: 9, nutrient: 44, other: 10}
      {culturalPractice: 7, irrigation: 160, spray: 194, nutrient: 75, scouting: 4, …}
      {irrigation: 44, spray: 104, nutrient: 24, culturalPractice: 3, other: 3, …}
      {spray: 15, other: 3, nutrient: 1, irrigation: 3, culturalPractice: 3}
    ]

    here, labels count: 4 (size of array)

    we want:
    result = [
      {
        label: irrigation
        data: [121, 160, 44, 3],
        backgroundColor: something
      },
      ... for every activity
    ]
  */

  const mediator: { [key: string]: number[] } = {}
  for (const activityObject of data) {
    for (const activity in activityObject) {
      if (mediator.hasOwnProperty(activity))
        mediator[activity].push(activityObject[activity])
      else
        mediator[activity] = [activityObject[activity]]
    }
  }

  /* mediator: {
    culturalPractice: (4) [9, 7, 3, 3]
    irrigation: (4) [121, 160, 44, 3]
    ...
  } */

  let cnt = 0;
  let result: Dataset[] = [];
  for (const activity in mediator) {
    result.push({
      label: activity,
      data: mediator[activity],
      backgroundColor: COLORS[cnt % TOTAL_COLORS],
      barThickness: 40,
    })
    cnt++;
  }

  return result;
}

export default BarChart
