import { Upload } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import React from 'react'
import moment from 'moment';

type Props = {
	onUpload: (attachments: UploadedImage[]) => void;
	buttonType?: 'icon' | 'button',
	primaryPath?: string;
	secondaryPath?: string;
	disabled?: boolean;
}

export type UploadedImage = {
	image: string, 
	thumbnail: string,
	file?: any;
};

export async function dataUrlToFile(dataUrl: string, fileName?: string): Promise<File> {
	fileName = moment().toISOString();
	const res: Response = await fetch(dataUrl);
	const blob: Blob = await res.blob();
	return new File([blob], fileName, { type: 'image/png' });
}

const UploadAttachments: React.FC<Props> = (props) => {
	const { onUpload, buttonType, disabled } = props;

	const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;
	
		const file = event.target.files[0];
		const attachments: UploadedImage[] = [];

		const reader = new FileReader();
	
		reader.onload = (e: any) => {
			const base64 = e.target.result as string;
			attachments.push({
				image: base64,
				thumbnail: "",
				file: URL.createObjectURL(file)
			});

			onUpload(attachments);
		};

		reader.readAsDataURL(file);
	};
	

	if(buttonType === 'icon') {
		return (
			<IconButton component="label" disabled={disabled}>
				<Upload />
				<input
					type="file"
					hidden
					onChange={onFileChange}
					disabled={disabled}
				/>
			</IconButton>
		)
	}

  return (
    <Button
			variant="contained"
			component="label"
			disabled={disabled}
		>
			Upload File
			<input
				type="file"
				hidden
				onChange={onFileChange}
				disabled={disabled}
				multiple={true}
			/>
		</Button>
  )
}

export default UploadAttachments