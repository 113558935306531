import { Grid } from "@mui/material";
import React, { useState } from "react";
import { GraphType } from "src/pages/BusinessDashboard";
import { fieldLegends, SENSORS } from "src/pages/BusinessDashboard/constant";
import { PestFilter, SensorFilter } from "../components";

const DEFAULT_CHECKED_SENSOR = 'airTemp'

export function getFieldColor(value: number, field: string): string {
  const match = fieldLegends[field]?.find((i: any) => (value >= i.min || -1 * Infinity) && value <= (i.max || Infinity));
  return match?.color || 'grey';
}

export function getDiseasePestColor(riskLevel: string): string {
  if (riskLevel === 'LOW') return 'green';
  if (riskLevel === 'MEDIUM') return 'yellow';
  if (riskLevel === 'HIGH') return 'red';
  return 'grey';
}

export type CropDetails = {
  name: string;
  disease: string;
  pest: string;
}

export enum Collapsibles {
  disease = 'disease',
  futurePred = 'futurePred',
  sensor = 'sensor',
  variety = 'variety',
  geoBoundary = 'geoBoundary'
}

type IC = {
  [key in Collapsibles]: boolean
}

interface Props {
  buttonDisabled: any,
  cropData: any,
  handleFilterUpdate: (filter: string) => void;
  indexData: any,
  setButtonDisabled: any,
  setCropData: any,
  setIndexData: any,
  setMapsDate: any,
  setGraphType: (x: GraphType) => void;
  setGraphSubType: (x: string) => void;
  setIsFilterLoading: (x: boolean) => void;
  mapsDate: any;
  selectedCropOption: any;
  setSelectedCropOption: (x: any) => void;
}

const FilterSection: React.FC<Props> = (props: Props): JSX.Element => {
  const { setCropData, handleFilterUpdate, setGraphType, setGraphSubType, setIndexData, setIsFilterLoading, mapsDate, selectedCropOption, setSelectedCropOption } = props;

  //Collapsible
  const [isCollapsibleVisible, setCollapsibles] = useState<IC>({
    disease: false,
    variety: false,
    sensor: true,
    futurePred: false,
    geoBoundary: false
  })

  // sensors
  const [sensorsList] = useState<any[]>(
    SENSORS
      .filter((sensor: any) => sensor.value !== 'signalStrength')
      .map((sensor: any) => (sensor.value === DEFAULT_CHECKED_SENSOR) ? { ...sensor, isChecked: true } : sensor)
  )

  const closeRestCollapsiblesExcept = (toOpen: Collapsibles) => {
    const clone = { ...isCollapsibleVisible }
    Object.keys(clone).map((o) => {
      if (o === toOpen) {
        return clone[o] = !clone[o]
      } else {
        return clone[o as Collapsibles] = false
      }
    });
    setCollapsibles(clone)
  }

  return (
    <Grid item xs={3.5} p={2} pt={0} height="90vh" display={'flex'} flexDirection={'column'} style={{ overflowY: 'scroll' }} className="informationOverflow"  >
      <Grid>
        <SensorFilter
          checkboxOptions={sensorsList}
          closeRestCollapsiblesExcept={closeRestCollapsiblesExcept}
          handleFilterUpdate={handleFilterUpdate}
          isVisible={isCollapsibleVisible.sensor}
          setCropData={setCropData}
          setIndexData={setIndexData}
          setGraphType={setGraphType}
          setGraphSubType={setGraphSubType}
          setIsFilterLoading={setIsFilterLoading}
          mapsDate={mapsDate}
        />
        <PestFilter
          isVisible={isCollapsibleVisible.disease}
          closeRestCollapsiblesExcept={closeRestCollapsiblesExcept}
          setIndexData={setIndexData}
          setCropData={setCropData}
          handleFilterUpdate={handleFilterUpdate}
          setGraphType={setGraphType}
          setGraphSubType={setGraphSubType}
          setIsFilterLoading={setIsFilterLoading}
          mapsDate={mapsDate}
          selectedCropOption={selectedCropOption}
          setSelectedCropOption={setSelectedCropOption}
        />
      </Grid>
    </Grid >
  )
}

export default FilterSection;