import { Tab, Tabs } from '@mui/material';
import { FC } from 'react';
import { tabStyles } from '../utils/constant';

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Props {
  tabsFor: string;
  tabs: string[];
  currentTabIndex: number;
  handleTabChange: (event: React.SyntheticEvent, newIndex: number) => void;
}

const UserInfoTabs: FC<Props> = (props) => {

  const { currentTabIndex, handleTabChange, tabs, tabsFor } = props;

  return (
    <>
      {/* header data */}
      <Tabs value={currentTabIndex} onChange={handleTabChange} aria-label="user tabs">
        {
          tabs.map((usertab, index) => (
            <Tab key={`${tabsFor}-tab-${usertab}-${index}`} sx={{...tabStyles, marginRight: '10px', padding: '0px 5px'}} label={usertab} {...a11yProps(index)} />
          ))
        }
      </Tabs>
    </>
  )
}

export default UserInfoTabs