import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { useState } from "react";
import {
  postConversation,
  updateConversations,
} from "src/services/plot.service";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import EditIcon from "@mui/icons-material/Edit";

interface Props {
  showAddNewConv?: any;
  setShowAddNewConv?: any;
  conversations?: any;
  setConversations?: any;
  index?: any;
  plotID?: any;
  payload?: any;
  getAllConversations?: any;
}

const normalCardStyle: any = {
  borderRadius: "20px",
  left: "2.5px",
  right: "5px",
};
const addCardStyle: any = {
  borderRadius: "20px",
  height: "60vh",
  position: "sticky",
  bottom: "25px",
  left: "70%",
  minWidth: "600px",
  maxWidth: "600px",
};
const displayFlex: any = { display: "flex", flexDirection: "row", gap: "5%" };

const ConversationCard = (props: Props): JSX.Element => {
  const {
    showAddNewConv,
    setShowAddNewConv,
    payload,
    getAllConversations,
    conversations,
    setConversations,
    index,
    plotID,
  } = props;
  const [date, setDate] = useState<any>("");
  const [followupDate, setFollowUpDate] = useState<any>("");
  const [plotId, setPlotId] = useState<any>("");
  const [farmer, setFarmer] = useState<any>("");
  const [fyllo, setFyllo] = useState<any>("");

  // edit states
  const [editFarmer, setEditFarmer] = useState<any>("");
  const [editFyllo, setEditFyllo] = useState<any>("");
  const [edit, setEdit] = useState<Boolean>(false);

  const addConversation = async () => {
    let dateobj = new Date(date);
    const isoDate = dateobj.toISOString();
    let dateobjFollowup = new Date(followupDate);
    const isoFollowupDate = dateobjFollowup.toISOString();

    payload["fyllo"] = fyllo;
    payload["farmer"] = farmer;
    payload["date"] = isoDate;
    payload["followupDate"] = isoFollowupDate;
    const res = await postConversation(payload);
    setShowAddNewConv(false);
    getAllConversations();
  };

  const enableEdit = () => {
    setEdit(!edit);
  };

  const handleEdit = async () => {
    let dateobj = new Date(date);
    const isoDate = dateobj.toISOString();

    const payload2 = {
      id: conversations.id,
      followupDate: isoDate,
      conversations: [
        {
          farmer: conversations.conversations[0].farmer,
          fyllo: conversations.conversations[0].fyllo,
          date: conversations.conversations[0].date,
        },
      ],
    };
    if (editFarmer != "") {
      payload2.conversations[0].farmer = editFarmer;
    }
    if (editFyllo != "") payload2.conversations[0].fyllo = editFyllo;
    const res = await updateConversations(payload2);
    setEdit(false);

    // const res
  };

  return (
    <Card
      sx={{ m: 1, width: "99%" }}
      style={showAddNewConv ? addCardStyle : normalCardStyle}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            cursor: "pointer",
          }}
        >
          <EditIcon
            onClick={() => enableEdit()}
            style={{ width: "30px", height: "30px" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {showAddNewConv ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "20px",
                width: "250px",
              }}
            >
              <Typography
                sx={{ fontSize: 20, fontWeight: "800", fontColor: "black" }}
                gutterBottom
              >
                New Conversation
              </Typography>
            </div>
          ) : (
            <Typography
              sx={{ fontSize: 13 }}
              color="text.secondary"
              gutterBottom
            >
              {index && conversations.date}
            </Typography>
          )}
          {showAddNewConv ? (
            <HighlightOffIcon
              style={{ marginTop: "5px" }}
              onClick={() => setShowAddNewConv(false)}
            />
          ) : (
            <Typography
              style={{ fontWeight: "700" }}
              sx={{ fontSize: 12 }}
              color="text.secondary"
              gutterBottom
            ></Typography>
          )}
        </div>

        {showAddNewConv && (
          <div style={{ display: "flex", flexDirection: "row", gap: "0.1%" }}>
            <Typography
              style={{
                fontSize: "95%",
                fontWeight: "600",
                margin: "1%",
                paddingTop: "10px",
              }}
              component="div"
            >
              Date:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  sx={{ width: 60, marginLeft: 1, marginTop: -1 }}
                  onChange={(e) => setDate(e)}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Typography
              style={{
                fontSize: "95%",
                fontWeight: "600",
                margin: "1%",
                paddingTop: "10px",
                paddingLeft: "10px",
              }}
              component="div"
            >
              Followup:
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  sx={{ width: 60, marginLeft: 1, marginTop: -1 }}
                  onChange={(e) => setFollowUpDate(e)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        )}

        {!showAddNewConv && (
          <div style={displayFlex}>
            <Typography
              style={{
                fontSize: "95%",
                fontWeight: "600",
                margin: "1%",
                width: "50px",
              }}
              component="div"
            >
              Date:
            </Typography>
            <TextField
              id="standard-error-helper-text"
              style={{ marginLeft: "0.7%", width: "85%" }}
              defaultValue={`${
                index && conversations["created_date"].split("T")[0]
              }, ${
                index &&
                conversations["created_date"].split("T")[1].split(":")[0]
              }:${
                index &&
                conversations["created_date"].split("T")[1].split(":")[1]
              }`}
              variant="standard"
              size="small"
              color="success"
              focused
              disabled={true}
            />
          </div>
        )}
        <div style={displayFlex}>
          {showAddNewConv ? (
            <Typography
              style={{
                fontSize: "95%",
                fontWeight: "600",
                margin: "1%",
                paddingTop: "8%",
              }}
              component="div"
            >
              Farmer:
            </Typography>
          ) : (
            <Typography
              style={{ fontSize: "95%", fontWeight: "600", margin: "1%" }}
              component="div"
            >
              Farmer:
            </Typography>
          )}

          {showAddNewConv ? (
            <TextField
              id="standard-error-helper-text"
              defaultValue=""
              multiline
              style={{ marginTop: "5%", width: "47%" }}
              rows={2}
              variant="standard"
              size="small"
              color="success"
              focused
              onChange={(e) => setFarmer(e.target.value)}
              required
            />
          ) : (
            <TextField
              id="standard-error-helper-text"
              defaultValue={index && conversations.conversations[0].farmer}
              variant="standard"
              size="small"
              color="success"
              focused
              onChange={(e) => setEditFarmer(e.target.value)}
              style={{ width: "85%" }}
              disabled={edit == true ? false : true}
            />
          )}
        </div>
        <div style={displayFlex}>
          {showAddNewConv ? (
            <Typography
              style={{
                fontSize: "95%",
                fontWeight: "600",
                margin: "1%",
                paddingTop: "8%",
              }}
              component="div"
            >
              Fyllo:
            </Typography>
          ) : (
            <Typography
              style={{ fontSize: "95%", fontWeight: "600", margin: "1%" }}
              component="div"
            >
              Fyllo:
            </Typography>
          )}

          {showAddNewConv ? (
            <TextField
              id="standard-multiline-static"
              multiline
              style={{ marginTop: "5%", marginLeft: "3%", width: "47%" }}
              rows={2}
              color="success"
              focused
              size="small"
              required
              variant="standard"
              onChange={(e) => setFyllo(e.target.value)}
            />
          ) : (
            <TextField
              id="standard-error-helper-text"
              defaultValue={index && conversations.conversations[0].fyllo}
              variant="standard"
              size="small"
              color="success"
              focused
              onChange={(e) => setEditFyllo(e.target.value)}
              style={{ width: "85%", marginLeft: "14px" }}
              disabled={edit == true ? false : true}
            />
          )}
        </div>
        {edit == true ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {/* take follow up */}
            <div
              style={{
                display: "flex",
                width:'345px',
                flexDirection: "row",
                // justifyContent: "space-between",
                gap:'16%',
                // backgroundColor:'red',
                marginLeft:'1%'
              }}
            >
              <Typography
                style={{ fontSize: "95%", fontWeight: "600",paddingTop:'10px' }}
                component="div"
              >
                Follow Up:
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    sx={{ width: 60, marginLeft: 1, marginTop: -1 }}
                    onChange={(e) => setDate(e)}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>

            <button
              data-testid="edit"
              onClick={(e) => handleEdit()}
              style={{
                backgroundColor: "green",
                height: "5%",
                width: "10%",
                borderRadius: "20px",
                padding: "0px 2px",
                border: "1px soild #2e7d32",
                cursor: "pointer",
              }}
            >
              <Typography
                variant="overline"
                textTransform={"capitalize"}
                style={{ color: "#fff", fontWeight: "600" }}
              >
                Save
              </Typography>
            </button>
          </div>
        ) : null}
        {showAddNewConv && (
          <button
            data-testid="submit"
            onClick={() => addConversation()}
            style={{
              backgroundColor: "green",
              height: "5%",
              marginLeft: "70%",
              marginTop: "-105px",
              width: "30%",
              borderRadius: "20px",
              padding: "0px 2px",
              border: "1px soild #2e7d32",
            }}
          >
            <Typography
              variant="overline"
              textTransform={"capitalize"}
              style={{ color: "#fff", fontWeight: "600" }}
            >
              Submit
            </Typography>
          </button>
        )}
      </CardContent>
    </Card>
  );
};

export default ConversationCard;
