import { DeleteOutline } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { KeyValues, FilterDataValue, FilterProps } from '../../constants/interfaces'
import { getInactivePlots } from "../../services/plot.service";
import { Alert } from "../../components";
import FormikApp from '../DynamicForm'

const InactivePlotsFilter: React.FC<FilterProps> = (props: FilterProps): JSX.Element => {
  const { handleFilterDataChange, handleRemove, filterId } = props

  const fields = [
    {
      id: "hours",
      label: "Hours",
      placeholder: "1 to 72",
      type: "text",
      validationType: "string",
      value: '',
      validations: [],
    },
  ]

  const [isApplied, setIsApplied] = useState<boolean>(false)
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)

  const handleFitlerChange = (status: boolean, plotIds: FilterDataValue) => {
    handleFilterDataChange(filterId, status, plotIds)
    setIsApplied(status)
  }

  const onSubmit = (values: KeyValues) => {
    const { hours } = values;

    if (Number(hours) < 1 || Number(hours) > 72) {
      setIsAlertOpen(true)
      return
    }

    getInactivePlots(hours as string)
      .then((plots) => {
        const plotIds = plots.map((plot: any) => plot.plotId) as FilterDataValue
        handleFitlerChange(true, plotIds)
      })
  }

  const onReset = () => {
    handleFitlerChange(false, undefined)
  }

  return (
    <>
      <Typography variant="subtitle2" mt={1}>Inactive Plots</Typography>
      <div style={{
        backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
        margin: '0px 0 15px 0',
        padding: '5px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center'
      }} >
        <FormikApp
          formFields={fields}
          onSubmit={(values: KeyValues) => onSubmit(values)}
          onReset={() => {}}
          filter={true}
          showReset
          removeField = {() => handleRemove(filterId)}
        />
        {/* <IconButton
          style={{ position: 'relative', top: '9px' }}
          onClick={() => handleRemove(filterId)}
        >
          <DeleteOutline style={{ border: '1px solid red', borderRadius: '100%', padding: '1px' }} color="error" />
        </IconButton> */}
      </div>
      <Alert isOpen={isAlertOpen} close={() => setIsAlertOpen(false)} messageFor={'Hours'} type="error" message="Hours should be in range 0-72" />
    </>
  )
}

export default memo(InactivePlotsFilter);