import { Button, IconButton } from '@mui/material';
import React from 'react';
// icons
import DeleteIcon from '@mui/icons-material/Delete';
import ResetIcon from '@mui/icons-material/RestartAlt';
import SubmitIcon from '@mui/icons-material/Done';
import { ButtonType } from './types';

type ActionButtonProps = {
  show?: boolean;
  actionButtonType?: ButtonType;
  onClick?: (...args: any) => void;
}

const SX = {
  border: '1px solid #ccc',
  borderRadius: '50%',
  padding: '8px',
}

export const submitButton: React.FC<ActionButtonProps> = (props) => {
  const { show, actionButtonType, onClick } = props;
  if (!show) return null;

  if (actionButtonType === "icon") {
    return (
      <IconButton color='success' sx={SX} onClick={onClick}>
        <SubmitIcon fontSize='small' />
      </IconButton>
    )
  }

  return (
    <Button onClick={onClick} color='success' >
      Submit
    </Button>
  )
}

export const resetButton: React.FC<ActionButtonProps> = (props) => {
  const { show, actionButtonType, onClick } = props;

  if (!show) return null;

  if (actionButtonType === "icon") {
    return (
      <IconButton color='warning' sx={SX} onClick={onClick}>
        <ResetIcon fontSize='small' />
      </IconButton>
    )
  }

  return (
    <Button onClick={onClick} color='warning' >
      Reset
    </Button>
  )
}

export const deleteButton: React.FC<ActionButtonProps> = (props) => {
  const { show, actionButtonType, onClick } = props;

  if (!show) return null;

  if (actionButtonType === "icon") {
    return (
      <IconButton color='error' sx={SX} onClick={onClick}>
        <DeleteIcon fontSize='small' />
      </IconButton>
    )
  }

  return (
    <Button onClick={onClick} color='error' >
      Delete
    </Button>
  )
}