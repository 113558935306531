import { Button, Typography } from "@mui/material"
import { FC } from "react";

type Variant = 'outlined' | 'text' | 'contained'

interface Props {
  onClick: () => void;
  label: string;
  disabled?: boolean;
  variant?: Variant
}

const RegionDatePickerButton: FC<Props> = ({ onClick, label, disabled, variant = 'outlined' }) => {
  return (
    <Button size='small' style={{ margin: '0px 1px' }} variant={variant} color='success' onClick={onClick} disabled={disabled} >
      <Typography fontSize={10}>{label}</Typography>
    </Button>
  )
}

export default RegionDatePickerButton;