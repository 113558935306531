import { generateOptions, isArrayWithLength } from 'src/utils/helper';
import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Select, { MultiValue, SingleValue } from "react-select";
import { getLocale } from 'src/services/locale.service';
import { Option } from 'src/constants/interfaces';

type Props = {
  xs?: number;
  title: string;
  isMulti?: boolean;
  onChange: (selected: (MultiValue<any> | SingleValue<any>)) => void;
  value?: MultiValue<any> | SingleValue<any>;
  titleStyle?: any;
  containerStyle?: any;
  isDisabled?: boolean;
  isImportant?:boolean
}

export const DropdownProps = {
  isClearable: true,
  isSearchable: true,
  placeholder: '- select -',
  styles: {
    container: (baseStyles: any) => ({
      ...baseStyles,
      width: '100%',
      padding: 2,
      fontSize: 16,
      margin: '3px 0',
      marginBottom: 11,
      marginTop: 6
    }),
  }
}

const LocaleDropdown = (props: Props) => {
  const { xs, title, isMulti, onChange, value, isDisabled, titleStyle = {}, containerStyle = {}, isImportant=false } = props;
  const [selectedLocale, setSelectedLocale] = useState<SingleValue<any> | MultiValue<any>>();
  const [localeOptions, setLocaleOptions] = useState<Option[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getLocaleOption();
  }, [])

  // api calls
  const getLocaleOption = () => {
    setIsLoading(true);
    getLocale()
      .then(res => {
        setLocaleOptions(generateOptions(res, 'id', 'en'))
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    if (localeOptions?.length && value) {
      const selectedNotes = value;
      if (isArrayWithLength(selectedNotes))
        setSelectedLocale(localeOptions?.filter((k: any) => {
          return selectedNotes?.some((v: any) => k.value === v || k.value === v?.value);
        }));
      else
        setSelectedLocale(localeOptions?.filter((k: any) => k.value === selectedNotes)[0]);
    }
  }, [localeOptions])

  return (
    <Grid item xs={xs ?? 12} style={containerStyle}>
      <Grid style={{alignItems: "center", margin: 0, padding: 0 }} display={'flex'} flexDirection={'row'}>
        <Typography style={titleStyle}>{title}</Typography>
        {isImportant && (
        <label style={{ fontSize: 20, fontWeight: "bold", color: "red", marginLeft: 4 }}>
        *
      </label>
      )}
      </Grid>

      <Grid>
        <Grid style={{marginLeft: '91%'}}>
        <Link
          to='/locale'
          target='_blank'
        >
          <Typography fontSize={12}>+ Locale</Typography>
        </Link>
        </Grid>
        <Select
          options={localeOptions}
          onFocus={getLocaleOption}
          onChange={(selected: (MultiValue<any> | SingleValue<any>)) => {
            setSelectedLocale(selected);
            onChange(selected);
          }}
          isLoading={isLoading}
          value={selectedLocale}
          isMulti={!!isMulti}
          isDisabled={!!isDisabled}
          {...DropdownProps}
        />
      </Grid>
    </Grid>
  )
}

export default LocaleDropdown