import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { getConsultantWithId, deleteConsultants } from '../services/plot.service'
import { useNavigate } from 'react-router';

interface AddedConsultants {
    created_date: any,
    cropId: any,
    id: any,
    mobile: any,
    regionId: any
}

const DenseTable: React.FC<any> = (props: any): any => {

    const { newConsultantsArr } = props;
    const [consultantArr, setConsultantArr] = useState<any>(newConsultantsArr);
    const navigate = useNavigate()
    if (newConsultantsArr.length != consultantArr.length) {
        setConsultantArr(newConsultantsArr);
    }
    const onDelete = (Id: any) => {
        if (window.confirm("Do you want to delete?")) {
            getConsultantWithId(Id)
                .then((res: any) => {

                    if (res.id) {
                        deleteConsultants(Id)
                            .then((res: any) => {
                                if (res.count) { alert("Video Deleted"); }
                            })
                    }
                })
        }
    }
    return (
        consultantArr.length > 0 &&
        <TableContainer component={Paper}>
            <div style={{ width: '85vw', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><p style={{ fontWeight: '800', textDecoration: 'underline', fontSize: '20px', fontStyle: 'italic' }}>New Subscriptions Added</p></div>

            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center"><p style={{ fontWeight: '800', textDecoration: 'underline' }}>Mobile</p></TableCell>
                        <TableCell align="center"><p style={{ fontWeight: '800', textDecoration: 'underline' }}>CropId</p></TableCell>
                        <TableCell align="center"><p style={{ fontWeight: '800', textDecoration: 'underline' }}>RegionId</p></TableCell>
                        <TableCell align="center"><p style={{ fontWeight: '800', textDecoration: 'underline' }}>Delete</p></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {consultantArr.map((consultant: any, index: number) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center">{consultant.mobile}</TableCell>
                            <TableCell align="center">{consultant.cropId}</TableCell>
                            <TableCell align="center">{consultant.regionId}</TableCell>
                            <TableCell align="center">
                                <input data-testid="delete-btn" type="button" value="Delete" style={{ font: 'white', backgroundColor: 'red', width: '60%', padding: '10px', fontSize: 18, paddingTop: '15px', alignSelf: 'center', margin: '30px' }} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
export default DenseTable