import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Paper,
  Button,
  Typography,
  Divider,
  useMediaQuery,
} from '@mui/material';
import { generateOTP, login } from "../services/user.service";
import images from '../constants/images'
import { Alert } from "../components/";
import useAuth from "../hooks/useAuth";

const Login: React.FC = (): JSX.Element => {
  const [userId, setuserId] = useState<string>();
  const [otp, setOtp] = useState<string>();
  const [otpSent, setOtpSent] = useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation();
  const prevLocation = (location.state as any)?.from?.pathname;
  const matches = useMediaQuery("(min-width:600px)");
  const [userIdMessageAlert, setIsOpenuserId] = useState<boolean>(false)
  const [passwordMessageAlert, setIsOpenPassword] = useState<boolean>(false)
  const { setAuth } = useAuth()

  const [logo, setLogo] = useState<any>();

  // messages
  const missinguserId = 'Please provide User Id'
  const wrongPassword = 'OTP/Password is incorrect'
  const tryAgain = 'Something went wrong, Please try again!'

  useEffect(() => {
    const clientName = localStorage.getItem('client');
    if(!!clientName) {
      setLogo((images as any)[clientName]);
    } else {
      setLogo(images.logo);
    }

    const access_token = localStorage.getItem('access_token')
    if (access_token) {
      navigate('/', { replace: true })
    }
  }, []) // eslint-disable-line

  const handleResponse = (res: any) => {
    if (!res.hasOwnProperty('error')) {
      setOtpSent(true);
      setuserId(res.farmUserId)
    } else {
      alert(tryAgain)
    }
  }

  const generateUserOTP = () => {
    if (userId?.length) {
      generateOTP({ username: userId })
        .then(handleResponse)
      setOtpSent(true);
    } else {
      setIsOpenuserId(true)
    }
  }

  const validateOTP = () => {
    login({ farmUserId: userId, otp })
      .then((response: any) => {
        if (response?.id) {
          const { id, roles, farmUserId } = response;
          setAuth({ roles, id });
          localStorage.setItem("userId", farmUserId)
          localStorage.setItem('access_token', id)
          localStorage.setItem('roles', JSON.stringify(roles))
          const hasPlantProtectionRole = !!roles && roles.length === 1 && roles[0] === 'plantProtection'
          const hasfarmUserRole = !!roles && roles.length === 1 && roles[0] === 'farmuser'
          hasPlantProtectionRole ? navigate('/region', { replace: true }) : hasfarmUserRole ? window.location.replace('/home') : navigate(prevLocation || '/', { replace: true })
        } else {
          setIsOpenPassword(true)
        }
      })
  }

  const handleClose = (key: string) => {
    switch (key) {
      case 'userId':
        setIsOpenuserId(false)
        break;
      case 'password':
        setIsOpenPassword(false)
        break;
      default:
        return;
    }
  }

  return (
    <div style={{
      height: '100vh',
      backgroundColor: '#006737',
    }}>
      <Grid width={matches ? '45%' : '90%'} marginX={'auto'} pt={10}>
        <Paper elevation={5}>
          <Grid container justifyContent={'center'} p={3}>
            <img src={logo} width={'200px'} alt="Logo" />
            <Grid container justifyContent={'center'} mt={1} >
              <Typography data-testid="userloginText" variant="h6">Login to access your Dashboard</Typography>
            </Grid>
          </Grid>
          <Divider variant="middle" />
          <Grid
            container
            spacing={3}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            p={3}
          >
            <Grid item width={'100%'}>
              <TextField data-testid="userLogin01" label="User Id or Phone" fullWidth onChange={e => setuserId(e.target.value.trim())} />
              <Alert isOpen={userIdMessageAlert} close={() => handleClose('userId')} message={missinguserId} type={'error'} messageFor={'User Id'} />
            </Grid>
            {otpSent && <Grid item width={'100%'}>
              <TextField data-testid="password01" label="Password or OTP" fullWidth type="password" onChange={e => setOtp(e.target.value.trim())} />
            </Grid>}
            <Alert isOpen={passwordMessageAlert} close={() => handleClose('password')} message={wrongPassword} type={'error'} messageFor={'password'} />

            <Grid container xs={12} md={12} lg={12} alignItems={'center'}
              justifyContent='space-evenly'>
              {otpSent &&
                <Grid item  >
                  <Button size="small" onClick={generateUserOTP} >Resend-OTP</Button>
                </Grid>
              }
              <Grid item my={3}>
                <Button data-testid="submitLogin" variant="contained" onClick={() => {
                  setIsOpenPassword(false);
                  setIsOpenuserId(false);
                  if (!otpSent) {
                    generateUserOTP();
                  }
                  else {
                    validateOTP();
                  }
                }}>{otpSent ? 'Login' : 'Submit'} </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div>
  )
}

export default Login
