import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Modal,
  IconButton,
  Tabs,
  Tab,
  CircularProgress,
} from "@mui/material";
import { getRepeatPurchase } from "src/services/device.service";
import {
  getAppAnalytics,
  getReferralAnalytics,
} from "src/services/user.service";
import { CalendarMonthOutlined, Clear } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { ArrowBack, Download } from "@mui/icons-material";
import { AgGridComponent } from "./Inventory";
import { RefferalColDef, RepeatPurchaseColDef } from "src/constants/columnDefs";
import { DatePicker } from "../components";
import * as XLSX from "xlsx";
import dayjs from "dayjs";

const Analytics = (): JSX.Element => {
  const navigate = useNavigate();
  const [isError, setisError] = useState(false);
  const [modalFromDate, setModalFromDate] = useState<any>(
    dayjs().subtract(15, "day").toDate()
  );
  const [modalToDate, setModalToDate] = useState<any>(dayjs().toDate());
  const [repeatPurchaseData, setRepeatPurchaseData] = useState<any[]>([]);
  const [appAnalyticsData, setAppAnalyticsData] = useState<any[]>([]);
  const [selectedModalData, setSelectedModalData] = useState<any>();
  const [referralModal, setRefferalModal] = useState<boolean>(false);
  const [refferalData, setRefferalData] = useState<any>();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [value, setValue] = useState(0);

  const defaultDatePickerPlaceholder = {
    maxDate: new Date(),
    calendarIcon: (
      <IconButton>
        <CalendarMonthOutlined />
      </IconButton>
    ),
    clearIcon: <Clear />,
  };
  const handleChange = (newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (!!searchQuery.length) {
      const filtered = appAnalyticsData.filter(
        (analytics) =>
          String(analytics.regionName).toLowerCase().includes(searchQuery) ||
          String(analytics.regionState).toLowerCase().includes(searchQuery) ||
          String(analytics.regionDistrict).toLowerCase().includes(searchQuery)
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(appAnalyticsData);
    }
  }, [appAnalyticsData, searchQuery]);

  const downloadRepeatPurchaseData = () => {
    const columns = [
      "Region",
      "District",
      "State",
      "Repeat Purchase",
      "Total User",
      "Conversion(%)",
    ];

    const rowData = repeatPurchaseData?.map((dt: any) => [
      dt.region,
      dt.district,
      dt.state,
      dt.count,
      dt.totalUser,
      dt.conversion,
    ]);

    let excelData: any[] = [columns, ...rowData];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const fileName = `repeat_purchase-${dayjs("2024-07-01").format(
      "DD/MMM/YYYY"
    )}-${dayjs("2024-07-15").format("DD/MMM/YYYY")}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const downloadAppAnalyticsData = () => {
    const eventTypesSet = new Set<string>();
    const rowsData = appAnalyticsData.map((dt: any) => {
      const events = dt.eventCount || {};
      const eventTypes = Object.keys(events);

      eventTypes.forEach((eventType) => eventTypesSet.add(eventType));

      return {
        regionName: dt.regionName,
        regionDistrict: dt.regionDistrict,
        regionState: dt.regionState,
        eventCounts: events,
        unique_user_count: dt.unique_user_count,
        totalUser: dt.totalUser,
      };
    });

    const allEventTypes = Array.from(eventTypesSet).sort();

    const columns = [
      "Region",
      "District",
      "State",
      "Active User",
      "Total User",
      ...allEventTypes,
    ];

    const rowData = rowsData.map((row: any) => {
      const { eventCounts } = row;
      const counts = allEventTypes.map(
        (eventType: any) => eventCounts[eventType] || ""
      );

      return [
        row.regionName,
        row.regionDistrict,
        row.regionState,
        row.unique_user_count,
        row.totalUser,
        ...counts,
      ];
    });

    let excelData: any[] = [columns, ...rowData];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const fileName = `app_analytics-${dayjs("2024-07-01").format(
      "DD/MMM/YYYY"
    )}-${dayjs("2024-07-15").format("DD/MMM/YYYY")}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const downloadReferralData = () => {
    const columns = [
      "State",
      "District",
      "Total Referrals",
      "Referrer",
      "Referrer Count",
    ];

    const rows = refferalData?.regionWiseReferral?.map((entry: any) => {
      const uniqueReferrers = entry.uniqueReferrer.join(", ");
      return [
        entry.state,
        entry.district,
        entry.totalReferrals,
        uniqueReferrers,
        entry.uniqueReferrer.length,
      ];
    });

    rows.unshift(columns);

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(rows);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Referral Data");

    const fileName = `referral_data-${dayjs("2024-07-01").format(
      "DD/MMM/YYYY"
    )}-${dayjs("2024-07-15").format("DD/MMM/YYYY")}.xlsx`;

    XLSX.writeFile(workbook, fileName);
  };

  const getData = () => {
    switch (value) {
      case 0:
        setIsLoading(true);
        getRepeatPurchase(modalFromDate, modalToDate).then((res: any) => {
          const repeatedPurchase: any[] = [];
          Object?.keys(res?.districtRegionCounts)?.map((k: string) => {
            repeatedPurchase.push({
              region: k,
              count: res?.districtRegionCounts?.[k]?.count ?? 0,
              state: res?.districtRegionCounts?.[k]?.state ?? "-",
              district: res?.districtRegionCounts?.[k]?.district ?? "-",
              totalUser: res?.districtRegionCounts?.[k]?.totalUser ?? 0,
              conversion: Number(
                (res?.districtRegionCounts?.[k]?.count /
                  res?.districtRegionCounts?.[k]?.totalUser) *
                  100
              ).toFixed(2),
            });
          });
          setRepeatPurchaseData(repeatedPurchase);
          setIsLoading(false);
          setisError(!repeatedPurchase);
        });
        break;
      case 1:
        setIsLoading(true);
        getAppAnalytics(modalFromDate, modalToDate).then((res) => {
          setAppAnalyticsData(res);
          setIsLoading(false);
          setisError(!res);
        });
        break;
      case 2:
        setIsLoading(true);
        getReferralAnalytics(modalFromDate, modalToDate).then((res) => {
          setRefferalData(res);
          setIsLoading(false);
          setisError(!res?.regionWiseReferral);
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getData();
  }, [value]);

  return (
    <Grid my={2} mx={"auto"}>
      <Grid
        p={3}
        borderRadius={20}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <IconButton onClick={() => navigate("/")}>
              <ArrowBack />
            </IconButton>
            <Typography m={1} variant="h5">
              Analytics
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <DatePicker
                {...defaultDatePickerPlaceholder}
                onChange={setModalFromDate}
                value={modalFromDate}
                label="From"
              />
            </Grid>
            <Grid item>
              <Typography variant="h6">-</Typography>
            </Grid>
            <Grid item>
              <DatePicker
                {...defaultDatePickerPlaceholder}
                onChange={setModalToDate}
                value={modalToDate}
                label="To"
              />
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={getData}>
                Load Data
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Tabs value={value} variant="fullWidth" style={{ marginBottom: 3 }}>
        <Tab label="Repeat Purchase" onClick={() => handleChange(0)} />
        <Tab label="App Analytics" onClick={() => handleChange(1)} />
        <Tab label="Referral" onClick={() => handleChange(2)} />
      </Tabs>

      {isLoading && (
        <Grid container margin={"auto"} justifyContent={"center"}>
          <CircularProgress color="success" size={20} />
        </Grid>
      )}

      {value === 0 && (
        <Grid>
          <Grid display={"flex"} justifyContent={"flex-end"} px={3}>
            <IconButton size="small" onClick={downloadRepeatPurchaseData}>
              <Download fontSize="medium" color={"success"} />
            </IconButton>
          </Grid>
          <AgGridComponent
            columnDef={RepeatPurchaseColDef}
            rowData={repeatPurchaseData}
          />
        </Grid>
      )}

      {value === 1 && (
        <Grid>
          <Grid display={"flex"} justifyContent={"flex-end"} px={3}>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{ padding: 10, width: "20%" }}
            />
            <IconButton size="small" onClick={downloadAppAnalyticsData}>
              <Download fontSize="medium" color={"success"} />
            </IconButton>
          </Grid>

          <div
            style={{
              display: "grid",
              gap: "20px",
              gridTemplateColumns: "repeat(auto-fill, minmax(420px, 1fr))",
              padding: 15,
            }}
          >
            {filteredData?.map((region, index) => (
              <div
                key={index}
                style={{ border: "1px solid #ccc", padding: "10px" }}
              >
                <h3>{region?.regionName}</h3>
                <p>
                  <strong>Active User:</strong> {region?.unique_user_count}
                </p>
                <p>
                  <strong>Total User:</strong> {region?.totalUser}
                </p>
                <h4>Event Counts:</h4>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          border: "1px solid #ccc",
                          padding: "8px",
                          textAlign: "left",
                        }}
                      >
                        Event Name
                      </th>
                      <th
                        style={{
                          border: "1px solid #ccc",
                          padding: "8px",
                          textAlign: "left",
                        }}
                      >
                        Count
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(region?.eventCount)
                      .sort(
                        ([, countA]: any, [, countB]: any) => countB - countA
                      )
                      .map(([eventName, count]: any) => (
                        <tr key={eventName}>
                          <td
                            style={{
                              border: "1px solid #ccc",
                              padding: "8px",
                              fontSize: 14,
                            }}
                          >
                            {eventName}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ccc",
                              padding: "8px",
                              fontSize: 14,
                            }}
                          >
                            {count}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        </Grid>
      )}

      {value === 2 && (
        <Grid>
          <Grid display={"flex"} justifyContent={"flex-end"} px={3}>
            <IconButton size="small" onClick={downloadReferralData}>
              <Download fontSize="medium" color={"success"} />
            </IconButton>
          </Grid>
          <AgGridComponent
            columnDef={RefferalColDef}
            rowData={refferalData?.regionWiseReferral}
            setSelectedModalData={setSelectedModalData}
            setMovementOpenModal={setRefferalModal}
          />
        </Grid>
      )}

      <Modal
        open={referralModal}
        style={{ overflow: "scroll" }}
        onClose={() => setRefferalModal(false)}
      >
        <Grid
          width={"50%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <Grid p={2} borderRadius={25} style={{ backgroundColor: "#f5f5f5" }}>
            <Grid container alignItems="center">
              <IconButton onClick={() => setRefferalModal(false)}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h5" style={{ marginLeft: 10 }}>
                {selectedModalData?.regionName}
              </Typography>
            </Grid>
          </Grid>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Referred By
                </th>
                <th
                  style={{
                    border: "1px solid #ccc",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  Referred Count
                </th>
              </tr>
            </thead>
            <tbody>
              {refferalData?.userWiseReferral
                ?.filter(
                  (user: any) => user?.regionId === selectedModalData?.regionId
                )
                ?.map((u: any) => (
                  <tr key={u?.referredBy}>
                    <td
                      style={{
                        border: "1px solid #ccc",
                        padding: "8px",
                        fontSize: 14,
                      }}
                    >
                      {u?.referredBy}
                    </td>
                    <td
                      style={{
                        border: "1px solid #ccc",
                        padding: "8px",
                        fontSize: 14,
                      }}
                    >
                      {u?.count}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Grid>
      </Modal>
      {isError && (
        <Typography
          variant="h5"
          p={6}
          display={"flex"}
          color={"red"}
          justifyContent={"center"}
        >
          Data not available!!
        </Typography>
      )}
    </Grid>
  );
};

export default Analytics;
