import { CORE_API } from "src/constants/api"
import { getData, postData } from "./common.service"

export async function getFarmsByFarmUser(payload: any) {
  const { id, name, mobile } = payload
  const qs: any = {
    "include": [
      {
        "relation": "farms",
        "scope": {
          "include": [
            {
              "relation": "plots"
            }
          ]
        }
      }
    ],
    "where": {
      "and": [
        { farmUserId: { like: `.*${id}.*`, options: 'i' } },
        { name: { like: `.*${name}.*`, options: 'i' } },
        { mobile: { like: `.*${mobile}.*`, options: 'i' } },
      ]
    }
  }

  const url = '/farm-users?filter=' + JSON.stringify(qs)
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })

}

export async function getFarmUsersCount(filters: any[] = []
) {
  let url = `/farm-users/count`;
  if (filters.length) {
    url += `?where=` + JSON.stringify({ and: filters })
  }
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}
export async function getFarmUserByPagination(
  skip: number,
  filters: any[] = []
) {

  const qs: any = {
    order: ["created_date DESC"],
    limit: 30,
    skip: skip,
    include: ['regions']
  };
  if (filters.length) {
    qs.where = { and: filters }
  }

  return getData('/farm-users?filter=' + JSON.stringify(qs), {
    basePath: CORE_API,
    sendTokenInHeader: true
  })
}

export async function updateFarmUser(payload: any) {
  const { farmUserId } = payload;
  const url = `/farm-users/${farmUserId}`;

  return postData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    usePatchMethod: true,
    body: payload,
  });
}