import Clear from 'src/assets/weatherIcons/clear.png';
import Cloudy from 'src/assets/weatherIcons/cloudy.png';
import Cold from 'src/assets/weatherIcons/cold.png';
import East from 'src/assets/right.png';
import ENE from 'src/assets/eastnortheast.png'
import ESE from 'src/assets/eastsoutheast.png'
import Fog from 'src/assets/weatherIcons/fog.png';
import Hail from 'src/assets/weatherIcons/hail.png';
import Hot from 'src/assets/weatherIcons/hot.png';
import NNE from 'src/assets/northnortheast.png'
import NNW from 'src/assets/northnorthwest.png'
import NorthEast from 'src/assets/northeast.png';
import North from 'src/assets/up.png';
import NorthWest from 'src/assets/northwest.png';
import Rain from 'src/assets/weatherIcons/rain.png';
import Snow from 'src/assets/weatherIcons/snow.png';
import SouthEast from 'src/assets/southeast.png';
import South from 'src/assets/down.png';
import SouthWest from 'src/assets/southwest.png';
import SSE from 'src/assets/southsoutheast.png'
import SSW from 'src/assets/southsouthwest.png'
import Storm from 'src/assets/weatherIcons/storm.png';
import Unknown from 'src/assets/weatherIcons/unknown.png';
import West from 'src/assets/left.png';
import Wind from 'src/assets/weatherIcons/windy.png';
import WNW from 'src/assets/westnorthwest.png'
import WSW from 'src/assets/westsouthwest.png'

export const WeatherIcons = {
  Clear, Cloudy, Cold, Fog, Hail, Hot, Rain, Snow, Storm, Unknown, Wind
}

export const WindDirectionIcons = {
  East, ENE, ESE, NNE, NNW, NorthEast, North, NorthWest, SouthEast, South, SouthWest, SSE, SSW, West, WNW, WSW
}