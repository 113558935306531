import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';

import { Chart } from 'chart.js'
import { isArrayWithLength } from 'src/utils/helper';
import { Typography, Grid } from '@mui/material';
import { RegionT } from '../../types';

interface Props {
  data: any;
  onPieClick?: (region: string, regionType: RegionT) => void;
}

const COLORS =[ "#bea9e9", "#c9b8ed", "#FF7F50", "#FFA07A", "#FF8C00", "#FFD700", "#FF6347", "#FF4500", "#FF69B4", "#FF1493", "#FF00FF", "#DA70D6", "#BA55D3", "#800080", "#9370DB", "#7B68EE", "#4169E1", "#0000FF", "#00BFFF", "#bce8e6", "#79d1cc" ];

const TOTAL_COLORS = COLORS.length;

const MultiSeriesPieChart = (props: Props): JSX.Element => {
  const { data, onPieClick } = props;
  const [pieChartLabel, setPieChartLabel] = useState<string[]>([]);
  const [chartRegionDistribution, setChartRegionDistribution] = useState<string[][]>([]);
  const [chartData, setChartData] = useState<any>();

  useEffect(() => {
    if (data) {
      const { labels, regionDistribution } = generatePieChartLabel(data[0]?.value)
      setPieChartLabel(labels)
      setChartRegionDistribution(regionDistribution)
    }
  }, [])

  useEffect(() => {
    if (isArrayWithLength(pieChartLabel)) {
      setChartData({
        labels: pieChartLabel,
        datasets: generateDataSets()
      })
    }
  }, [pieChartLabel])

  const generateDataSets = (): any[] => {
    const apneMatlabKaData: { [key: string]: number } = {};

    for (const obj of data) {
      const { key, value } = obj;
      if (key === "regionDistribution") {
        continue;
      }
      apneMatlabKaData[key] = value;
    }

    const dataset = [];

    let cnt = 0;
    for (const region in chartRegionDistribution) {
      const names = chartRegionDistribution[region];
      const tempDataSet: { backgroundColor: string[], data: number[] } = {
        backgroundColor: [],
        data: []
      }

      for (const name of names) {
        tempDataSet.backgroundColor.push(COLORS[cnt]);
        tempDataSet.data.push(apneMatlabKaData[name])
        cnt = (cnt + 1) % TOTAL_COLORS;
      }

      dataset.push(tempDataSet);
    }

    return dataset
  }

  const generatePieChartLabel = (regionDistribution: any): any => {
    const labels = new Array<string[][]>([[], [], []]);
    const labelsOrder = ['state', 'country', 'continent'];
    for (const region in regionDistribution) {
      const names: string[] = regionDistribution[region];
      const uniqueRegions = sortAndRemoveDublicates(names);
      const labelsIndex = labelsOrder.indexOf(region)
      labels[labelsIndex] = uniqueRegions;
    }
    const allLabels = labels.flat();
    return {
      labels: allLabels,
      regionDistribution: labels
    }
  }

  const sortAndRemoveDublicates = (array: any[]): any[] => {
    return Array.from(new Set(array));
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // make it true if needed labels above chart
        labels: {
          generateLabels: function(chart: any) {
            // Get the default label list
            const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
            const labelsOriginal = original.call(this, chart);

            // Build an array of colors used in the datasets of the chart
            let datasetColors = chart.data.datasets.map(function(e: any) {
              return e.backgroundColor;
            });
            datasetColors = datasetColors.flat();

            // Modify the color and hide state of each label
            labelsOriginal.forEach((label: any) => {
              // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
              label.datasetIndex = (label.index - label.index % 2) / 2;

              // The hidden state must match the dataset's hidden state
              label.hidden = !chart.isDatasetVisible(label.datasetIndex);

              // Change the color to match the dataset
              label.fillStyle = datasetColors[label.index];
            });

            return labelsOriginal;
          }
        },
        onClick: function(mouseEvent: any, legendItem: any, legend: any) {
          // toggle the visibility of the dataset from what it currently is
          legend.chart.getDatasetMeta(
            legendItem.datasetIndex
          ).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
          legend.chart.update();
        }
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const labelIndex = context.dataIndex;
            const layer = context.datasetIndex;
            return chartRegionDistribution[layer][labelIndex] + ': ' + context.formattedValue;
          }
        }
      }
    },
    onClick: (_: any, element: any) => {
      const { datasetIndex, index } = element[0]
      /*
        datasetIndex:
          0 -> State
          1 -> Country
          2 -> Continent

        index -> labels[index]
      */

      if(!!onPieClick) {
        const regionType: RegionT = (
          datasetIndex === 0 ? 'state' :
          (
            datasetIndex === 1 ? 'country' : 'continent'
          )
        )
        const label = getLabel(index, datasetIndex);
        onPieClick(label, regionType);
      }
    }
  };

  const getLabel = (index: number, datasetIndex: number): string => {
    /*
      to get correct label, we need to consider both datasetIndex and index
      labels: [...states, ...countries, ...continents]
      datasetIndex -> 
        0: correctLabel <- labels[index]
        1: correctLabel <- labels[totalStates + index]
        2: correctLabel <- labels[totalStates + totalCountries + index]
    */ 

    const { labels, datasets } = chartData
    const totalStates = datasets[0].data.length;
    const totalCountries = datasets[1].data.length;

    if(datasetIndex === 2) 
      return labels[totalStates + totalCountries + index];
    
    if(datasetIndex === 1)
      return labels[totalStates + index];
    
    return labels[index];
  }

  return (
    <div style={{ maxHeight: 700 }}>
      {chartData &&
        <Grid container direction={'column'} alignItems={'center'}>
          <Pie data={chartData} options={options} />
          <Typography textAlign={'center'} color={'#4f4f4f'} variant={'caption'} mt={1} >Region wise Activity count</Typography>
        </Grid>
      }
    </div>
  )
}

export default MultiSeriesPieChart;
