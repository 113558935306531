import * as React from 'react';
import Switch from '@mui/material/Switch';
import {updateReachOut} from '../services/plot.service';

const ColorSwitch: React.FC<any> = (props:any) =>{
  let {doNotContact} = props;

  const [clicked, setClicked] = React.useState(doNotContact); 
  const updateDoNotContact = async ()=>{
    setClicked(!clicked)
    let payload:any = {
      "farmUserId":props.farmuserId,
      "doNotContact":!clicked,
      "name":props.name,
    }
    const res = await updateReachOut(payload);
  }

  return (
    <div>
      {clicked && <Switch onClick={()=>updateDoNotContact()} defaultChecked color="success" />}
      {!clicked && <Switch onClick={()=>updateDoNotContact()} color="success" />}
    </div>
  );
  
}
export default ColorSwitch