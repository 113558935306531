import React, { useEffect, useState } from "react";
import { TObservationType } from "..";
import { AgGridReact } from "ag-grid-react";
import { CellClickedEvent, ColDef } from "ag-grid-community";
import { deleteObservationType } from "src/services/observationType.service";
import { Box, Button, Card, CardContent, Grid, IconButton, Modal, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

type Props = {
  data: TObservationType[];
  onEdit?: (selectedItem: TObservationType, editType: "copy" | "edit") => void;
};

const DataGrid: React.FC<Props> = (props) => {
  const { data, onEdit } = props;

  const [rowData, setRowData] = useState<any[]>([]);
  const [actionModalVisible, setActionModalVisible] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  useEffect(() => {
    setRowData(data);
  }, [data])

  const handleCellClicked = (params: CellClickedEvent<any, any>) => {
    const { data } = params;
    setSelectedRow(data);
    openActionModal();
  }

  const openActionModal = () => setActionModalVisible(true);
  const closeActionModal = () => setActionModalVisible(false);

  const deleteObservationTypeFn = () => {
    if(window.confirm("delete data")) {
      deleteObservationType(selectedRow.id)
        .then((response) => {
          if(response.error) throw new Error("something went wrong");
          setRowData(rowData => rowData.filter((row) => row.id !== selectedRow.id));
        })
        .catch(error => {
          console.error("something went wrong")
        })
        .finally(() => {
          closeActionModal();
        })
    }
  }

  const editObservationType = () => {
    if(!!onEdit) {
      onEdit(selectedRow, "edit");
    }
    closeActionModal();
  }

  const copyObservationType = () => {
    if(!!onEdit) {
      onEdit(selectedRow, "copy");
    }
    closeActionModal();
  }

  return (
    <div className="ag-theme-alpine" style={{ height: 500, width: "100%", padding: "20px" }}>
      <AgGridReact 
        rowData={rowData} 
        columnDefs={columnDefs} 
        pagination
        paginationPageSize={15}
        onCellClicked={handleCellClicked}
      />

      <Modal open={actionModalVisible} onClose={closeActionModal} >
        <Box style={style as any}>
          <Card>
            <Grid container alignItems={"center"} justifyContent={"space-between"}>
              <Typography variant="subtitle1" ml={2} >Select Action to perform</Typography>
              <IconButton onClick={closeActionModal}>
                <Close />
              </IconButton>
            </Grid>
            <CardContent style={{ display: "flex", justifyContent: "space-around" }} >
              <Button variant="contained" color="primary" onClick={editObservationType} >
                Edit
              </Button>
              <Button variant="contained" color="success" onClick={copyObservationType} >
                Copy
              </Button>
              <Button variant="contained" color="error" onClick={deleteObservationTypeFn} >
                Delete
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </div>
  );
};

const ArrayRenderer: React.FC<{ value: any[] }> = ({ value }) => {
  return <div>{JSON.stringify(value)}</div>;
};

const columnDefs: ColDef[] = [
  { 
    headerName: "Crop ID", 
    field: "cropId", 
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
  },
  {
    headerName: "Category",
    field: "category",
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true
  },
  { 
    headerName: "Disease/Pest ID", 
    field: "diseasePestId", 
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true  
  },
  { 
    headerName: "Name", 
    field: "name", 
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true  
  },
  { 
    headerName: "Description", 
    field: "description", 
    resizable: true,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    flex: 1
  },
  {
    headerName: "Columns",
    field: "columns",
    cellRendererFramework: ArrayRenderer,
    minWidth: 280,
    resizable: true,
    
  },
  {
    headerName: "Subcolumns",
    field: "subColumns",
    cellRendererFramework: ArrayRenderer,
    minWidth: 280,
    resizable: true,
  }
];

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0.5px solid #000',
  boxShadow: 24,
  p: 4,
};

export default DataGrid;
