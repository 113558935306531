import { Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { KeyValues, FilterDataValue, FilterProps } from '../../constants/interfaces'
import { getFarms } from "../../services/farm.service";
import FormikApp from '../DynamicForm'

const FarmFilter: React.FC<FilterProps> = (props: FilterProps): JSX.Element => {
  const { handleFilterDataChange, handleRemove, filterId } = props

  const fields = [
    {
      id: "id",
      label: "Farm Id",
      placeholder: "Farm Id",
      type: "text",
      validationType: "string",
      value: '',
      validations: [],
    },
    {
      id: "name",
      label: "Farm Name",
      placeholder: "Farm Name",
      type: "text",
      validationType: "string",
      value: '',
      validations: [],
    }
  ]

  const [isApplied, setIsApplied] = useState(false)

  const handleFitlerChange = (status: boolean, plotIds: FilterDataValue) => {
    handleFilterDataChange(filterId, status, plotIds)
    setIsApplied(status)
  }

  const onSubmit = (values: KeyValues) => {
    const { id, name } = values
    getFarms(id as string, name as string)
      .then((farms: any[]) => {
        const plotIds2d = farms.map((farm) => farm?.plots?.map((plot: any) => plot.plotId))
        let plotIds: string[] = []
        for (const plotIds1d of plotIds2d) {
          plotIds = [...plotIds1d, ...plotIds]
        }
        handleFitlerChange(true, plotIds)
      })
  }

  const onReset = () => {
    handleFitlerChange(false, undefined)
  }

  return (
    <>
      <Typography variant="subtitle2" mt={1}>Farms</Typography>
      <div style={{
        backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
        margin: '0px 0 15px 0',
        padding: '5px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center'
      }} >
        <FormikApp
          formFields={fields}
          onSubmit={(values: KeyValues) => onSubmit(values)}
          onReset={() => {}}
          filter={true}
          showReset
          removeField = {() => handleRemove(filterId)}
        />
      </div>
    </>
  )
}

export default memo(FarmFilter);