import moment, { isDate } from "moment";
import { CurrentFilter, KeyValues } from "../constants/interfaces";
import { Mappings, UnitMapping } from "../constants/WordMapping";
import dayjs from "dayjs";

export const createApiFilter = (filters: KeyValues) => {
  let Apifilter = [];

  for (const filter in filters) {
    let currentFilter: CurrentFilter = {};
    currentFilter[filter] = {
      like: `.*${(filters[filter] as string).trim()}.*`,
      options: "i",
    };
    Apifilter.push(currentFilter);
  }

  let clause = {
    where: {
      and: Apifilter,
    },
    fields: {
      plotId: true,
    },
    limit: 600, //TODO: showing error for larger data(700)
  };

  return JSON.stringify(clause);
};

export const createFitlerPlotIdIn = (
  plotIds: String[],
  include = ["farm", "farmUser"]
) => {
  const filter = {
    where: {
      plotId: { in: plotIds },
    },
    include: include,
    order: "created_date DESC",
  };
  return filter;
};

export const getValue = (obj: any) => {
  let result = "";

  if (typeof obj === "string" || typeof obj === "number") {
    return obj;
  }

  // adding data
  result += obj.hasOwnProperty("avg")
    ? obj.avg.hasOwnProperty("unit")
      ? `${print(obj.avg.value)} ${obj.avg.unit}`
      : `${print(obj.avg.value)}`
    : +obj.hasOwnProperty("unit")
    ? `${print(obj.value)} ${obj.unit}`
    : `${print(obj.value)}`;

  return result.length > 0 ? result : "N.A";
};

export const getDate = (
  date: string | Date,
  dateFormat = "MMMM Do YYYY, h A"
) => {
  return moment(date).format(dateFormat) ?? "N.A";
};

export const print = (value: any, dateFormat = "MMMM Do YYYY, h A") => {
  if (isDate(value)) {
    return getDate(value, dateFormat);
  }

  if (typeof value === "number") {
    return value.toFixed(2);
  }

  return value ?? "N.A";
};

export const getHeaders = (dataFields: any) => {
  const headers: any[] = [
    {
      label: "Date",
      value: "timestamp",
    },
  ];
  for (const [field, value] of Object.entries(dataFields)) {
    if(typeof value === "number" && !['numberOfRecords'].includes(field)){
    if (field === "timestamp") {
      continue;
    }
    const label = Mappings[field] ?? field;
    const labelUnit = UnitMapping[field] ? `(${UnitMapping[field]})` : "";
    headers.push({
      label: label + labelUnit,
      value: field,
    });
  }
  }

  return headers;
};

const capitalizeWords = (sentence: string): string => {
  return sentence
    .split(" ")
    .map((word) => word[0]?.toUpperCase() + word?.slice(1))
    .join(" ");
};

export const removeUnderScores = (
  text: string | number,
  textTransform:
    | "default"
    | "uppercase"
    | "lowercase"
    | "capitalize" = "lowercase"
) => {
  if (typeof text === "number") {
    return text;
  }

  const updatedText = (text ?? "").replaceAll("_", " ");
  if (textTransform === "lowercase") {
    return updatedText.toLowerCase();
  }

  if (textTransform === "uppercase") {
    return updatedText.toUpperCase();
  }

  if (textTransform === "capitalize") {
    return capitalizeWords(updatedText.toLowerCase());
  }

  return updatedText;
};

export const isDevelopmentMode = () => process.env.NODE_ENV === "development";

export const isArrayWithLength = (arr: any) =>
  Array.isArray(arr) && arr.length > 0;

export const sortByKey = (array: any[], key: string, desc = false) => {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    if (desc) {
      return x > y ? -1 : x < y ? 1 : 0; // TODO: check me later
    }
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const isLoggedIn = () =>
  localStorage.getItem("access_token")?.length! > 0;

export const isDashboardConditionsSatisfied = (
  pathName = "",
  isExporterDashboard = false
) => {
  if (isExporterDashboard) {
    const isExporter = true;
    return pathName === "/exporter" && isLoggedIn() && isExporter;
  }

  const isAdmin = true; // TODO: Fix me later
  return pathName === "/home" && isLoggedIn() && isAdmin;
};

export const isOpenURL = (url: string): boolean => {
  const openURLs = ["info", "login", "unauthorized"];
  return openURLs.includes(url.split("/")[1]);
};

export const generateWhereClauseForFilters = (filters: KeyValues) => {
  const result = Object.keys(filters).map((filter) => {
    const newObj: any = {};
    newObj[filter] = { like: `.*${filters[filter]}.*`, options: "i" };
    return newObj;
  });

  return result;
};

export const getPropertyAndType = (str: string): [string, string] => {
  const prefix = str.substring(0, 3);
  let propertyName = str.replace(/^Min|^Max|^Avg/, "");
  const firstChar = propertyName.charAt(0).toLowerCase();
  const formattedPropertyName = firstChar + propertyName.slice(1);

  switch (prefix) {
    case "Min":
      return [formattedPropertyName, "min"];
    case "Max":
      return [formattedPropertyName, "max"];
    case "Avg":
      return [formattedPropertyName, "avg"];
    default:
      return [formattedPropertyName, "avg"];
  }
};

export const generateOptions = (
  data: any[],
  valueField: string = "",
  labelField: string = "",
  ...otherFields : string[]
) => {
  const uniqueOptions: { value: string; label: string | number}[] = [];
  const seenValues = new Set();

  data.forEach((dt) => {
    const value = !!valueField ? dt[valueField] : dt;
    const label = !!labelField ? dt[labelField] : dt;

    const otherValues : any= {};
    for(let i of otherFields) {
      const value = !!i ? dt[i] : undefined;
      otherValues[i] = value;
    }

    if (!seenValues.has(value)) {
      seenValues.add(value);
      uniqueOptions.push({ value, label, ...otherValues });
    }
  });

  return uniqueOptions;
};

export const generateOptionsFromArray = (data: any[]) => {
  const uniqueOptions: { value: string; label: string | number }[] = [];
  const seenValues = new Set();

  data.forEach((str) => {
    const value = str;
    const label = str;
    if (!seenValues.has(value)) {
      seenValues.add(value);
      uniqueOptions.push({ value, label });
    }
  });

  return uniqueOptions;
};

export const generateLikeFilter = (text: string | number) => {
  return { like: `.*${text}.*`, options: "i" }
}

export const filterInValidValues = (filters: Record<string, any>) => {
  const payload: any = {};
  Object.keys(filters).forEach((filter) => {
    const value = (filters as any)[filter];
    const inValidValue = value === undefined || value === null || value === "";
    if(!inValidValue) {
      payload[filter] = value; 
    }
  });
  return payload;
}

export const getClientName = (location: any) => {
  const { search } = location;

  if(!!search && search.includes("client")) {
    const queries = search.split("&");
    const clientIndex = queries.findIndex((text: string) => text.includes("client"));
    if(clientIndex === -1) return "";

    const clientName = queries[clientIndex].split("=")[1];
    localStorage.setItem("client", clientName);

    return clientName;
  }

  return "";
} 

export const compareDates = (startDate: string, endDate: string) => {
	const diff = dayjs(endDate).diff(dayjs(startDate));

	return diff;
}