import { downloadPlotDetails } from "src/services/plot.service";
import { Button, Grid, Modal, Typography } from "@mui/material";
import React, { FC, useState } from "react";
import Select, { MultiValue } from 'react-select'
import { getExcelData } from "../utils";
import { Loader } from "src/components";
import { ColumnMapping } from "src/constants/WordMapping";

type Props = {
  isOpen: boolean;
  close: () => void;
  columnOptions: any[];
  plotIds: string[]
}

const DefaultSelectedColumns = ['farmUser.name', 'farmUser.mobile', 'farm.name', 'name', 'device.deviceType', 'district', 'cropSown.name', 'farmUser.doNotContact'].map((fieldName) => {
  const colOption = {
    value: fieldName,
    label: ColumnMapping[fieldName] ?? fieldName
  }
  return colOption;
});

const DownloadModal: FC<Props> = ({ isOpen, close, columnOptions, plotIds }) => {

  const [selectedColumns, setSelectedColumns] = useState<MultiValue<any>>(DefaultSelectedColumns);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);


  const downloadGridData = async () => {
    try {
      setIsDownloading(true);
      const response = await downloadPlotDetails(plotIds, ["farm", "farmUser", "device", "cropSown"]);
      const columnsToRender = selectedColumns?.map(column => column.value as string);
      getExcelData(response, columnsToRender ?? []);
    } catch (err: any) {
      close();
    } finally {
      setIsDownloading(false)
      setTimeout(() => {
        close();
      }, 500)
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={close}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Grid bgcolor={'white'} width={'80%'} p={2}>
        <Typography mb={2} variant="h5">Download Plot Data</Typography>
        {
          isDownloading ? (
            <Grid display={'flex'} justifyContent={'center'} justifyItems={'center'} >
              <Loader />
            </Grid>
          ) : (
            <Grid width={'80%'}>
              <Select
                options={columnOptions}
                isMulti={true}
                onChange={(selected) => setSelectedColumns(selected)}
                isSearchable={true}
                placeholder={'- select columns to download -'}
                value={selectedColumns}
              />
              <Button
                variant="outlined"
                style={{ borderRadius: "20px", marginTop: '10px' }}
                size="medium"
                color="success"
                onClick={downloadGridData}> Download </Button>
            </Grid>
          )
        }
      </Grid>
    </Modal>
  )
}

export default DownloadModal;