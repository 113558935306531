import { filterInValidValues } from "src/utils/helper";
import { deleteData, getData } from "./common.service";
import { CORE_API } from "src/constants/api";

const observations = "/observations";

export const getObservation = (plotIds: string[] = []) => {
  const filterQuery: any = {
    order: "_id DESC",
  }

  if(plotIds.length > 0) {
    filterQuery.plotId = plotIds
  }

  return getData(`/${observations}?filter=${JSON.stringify(filterQuery)}`, {
    sendTokenInHeader: true,
    basePath: CORE_API
  });
}

/**
 * @function filterObservation
 * @param filters object having Observation Model properties as key, and string | number | date as value
 * @param fields object having Observation Model properties as key and boolean as value
 * @returns Array of objects
 */
export const filterObservation = (
  filters: ObservationFilters, 
  fields: Fields = {id: true},
  limit: number = 0
): Promise<
  Array<Partial<Record<Filter, any>>>
> => {
  const payload = filterInValidValues(filters);

  const filterQuery: any = {
    where: payload,
    fields,
    order: "observationDate DESC"
  }

  if(!!limit) {
    filterQuery.limit = limit;
  }
  
  return getData(`/${observations}?filter=${JSON.stringify(filterQuery)}`, {
    sendTokenInHeader: true,
    basePath: CORE_API
  });
}

export const downloadPlotWiseObservation = (
  filters: ObservationFilters, 
): Promise<
  Array<Partial<Record<Filter, any>>>
> => {
  const payload = filterInValidValues(filters);

  const filterQuery: any = {
    where: payload,
  }
  
  return getData(`/observations/plot-wise?filter=${JSON.stringify(filterQuery)}`, {
    sendTokenInHeader: true,
    basePath: CORE_API
  });
}

export const deleteObservation = (id: string) => {
  return deleteData(`/${observations}/${id}`, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    useDeleteMethod: true
  })
}

export const recalculateObservation = (id: string) => {
  return getData(`/${observations}/${id}/recalculate`, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export type Filter = "id" | "created_date" | "plotId" | "cropId" | "cropStage" | "diseasePestId" | "observationDate" | "observationTypeId" | "riskLevel" | "probability" | "rating" | "modelPrediction" | "modelRiskLevel";
export type Fields = Partial<Record<Filter, boolean>>;
export type ObservationFilters = Partial<Record<Filter, string | number | Date>>;

