import logo from 'src/assets/fyllo-logo.png'
import GrapesFarm from 'src/assets/grapesFarm.jpg'
import Irrigation from 'src/assets/irrigation.jpg'
import Fertigation from 'src/assets/fertigation.jpg'
import reva from 'src/assets/clients/Reva-logo.png'
import IndiaMap from 'src/assets/india-google-map.png'
//import ViewPlots from 'src/assets/viewPlots.png'

const images = {
  logo,
  GrapesFarm,
  Irrigation,
  Fertigation,
  IndiaMap,
  reva
}

export default images;