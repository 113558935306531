import { Grid, IconButton, Typography, Modal, Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
} from "react";
import { useNavigate } from "react-router";
import {
  getPesticideConfigs,
  getPesticideConfigsCondtion,
  postPesticideConfigs,
  putPesticideConfigs,
  deletePesticideConfig,
} from "src/services/crop.service";
import Select, { SingleValue, MultiValue } from "react-select";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { generateOptions, isArrayWithLength } from "../utils/helper";
import { getCropOptions } from "src/services/crop.service";
import { pesticidesColDef, ruleColDef } from "../constants/columnDefs";
import {
  getCropStages,
  getCropDiseases,
  getCropPests,
  getCropVarieties,
  getcropsStageBasedOnVariety,
} from "src/services/crop.service";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { getFertilizers } from "src/services/farm.service";
import { ToastAlert } from "src/components";
import { ToastSeverity } from "src/components/ToastAlert/types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  SortChangedEvent,
  FilterChangedEvent,
} from "ag-grid-community";
import { Link } from "react-router-dom";
import LocaleDropdown from "src/components/LocaleDropdown";

const Pesticide: React.FC = (): JSX.Element => {
  const gridRef = useRef<AgGridReact>(null);
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "600px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [enableCopy, setEnableCopy] = useState<boolean>(false);
  const [isApplied, setIsApplied] = useState(false);
  const [isError, setisError] = useState(false);
  const [isPesticideData, setisPesticideData] = useState(false);
  const [pesticideData, setpesticideData] = useState([]);
  const [crops, setCrops] = useState<SingleValue<any>>([]);
  const [isCropLoading, setIsCropLoading] = useState<boolean>(true);
  const [selectedCropOption, setSelectedCropOption] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState<any>(null);
  const [cropId, setcropId] = useState<string>("");
  const [baseStage, setbaseStage] = useState<string>("");
  const [condtion, setCondition] = useState<string>("");
  const [purpose, setpurpose] = useState<string[]>([]);
  const [cropVariety, setcropVariety] = useState<string[]>([]);
  const [daysAfterBaseStage, setdaysAfterBaseStage] = useState<number[]>([]);
  const [daysAfterBaseStageView, setdaysAfterBaseStageView] = useState<any>();
  const [note, setnote] = useState<string>("");
  const [waterInLitres, setwaterInLitres] = useState<number>();
  const [chemicals, setchemicals] = useState<any[]>([]);
  const [pestsOptions, setpestsOptions] = useState<any>();
  const [diseaseOptions, setdiseasesOptions] = useState<any>();
  const [rules, setrules] = useState<any>([]);
  const [baseStageOptions, setbaseStageOptions] = useState<any>();
  const [conditionOptions, setConditionOptions] = useState<any>();
  const [pesticideOptions, setPesticideOptions] = useState<any>();
  const [cropVarietyOptions, setcropVarietyOptions] = useState<any>();
  const [selectedPurposeOption, setselectedPurposeOption] = useState<
    MultiValue<any>
  >([]);
  const [selectedcropVarietyOption, setselectedcropVarietyOption] = useState<
    MultiValue<any>
  >([]);
  const [showModal, setshowModal] = useState<boolean>(false);
  const [updateModal, setupdateModal] = useState<boolean>(false);
  const [updateExistingModal, setupdateExistingModal] =
    useState<boolean>(false);
  const [updateIndex, setupdateIndex] = useState<number>(0);
  const [ruleModal, setruleModal] = useState<boolean>(false);
  const [addruleModal, setAddruleModal] = useState<boolean>(false);
  const [updateKey, setupdateKey] = useState<number>(0);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<ToastSeverity>("info");
  const [applicationMode, setApplicationMode] = useState<string[]>();
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [userNotes, setUserNotes] = useState<MultiValue<any>>([]);

  //states
  const [value1, setValue1] = React.useState<number>(0);
  const [value2, setValue2] = React.useState<number>(0);
  const [subsequenceRanges, setsubsequenceRanges] = React.useState<string>("");
  const [sprayGap, setSprayGap] = useState<number>();

  const [chemicalData, setChemicalData] = useState({
    id: "",
    amount: "",
    unit: "",
  });
  const [selectedpesticideID, setselectedpesticideID] = useState({
    label: "",
    value: "",
  });
  const [selectedruleType, setselectedruleType] = useState({
    label: "",
    value: "",
  });
  const [selectedruleTypeId, setselectedruleTypeId] = useState({
    label: "",
    value: "",
  });
  const [severity, setseverity] = useState({
    label: "",
    value: "",
  });
  const [ruleData, setRuleData] = useState({
    type: "",
    typeId: "",
    days: "",
    minDays: "",
    severity: "",
  });

  const ruleTypeOptions: any = [
    {
      label: "pests",
      value: "pests",
    },
    {
      label: "diseases",
      value: "diseases",
    },
  ];
  const severityOptions: any = [
    {
      label: "HIGH",
      value: "HIGH",
    },
    {
      label: "LOW",
      value: "LOW",
    },
    {
      label: "MEDIUM",
      value: "MEDIUM",
    },
  ];

  const navigate = useNavigate();
  const startLoading = () => {
    gridRef?.current?.api?.showLoadingOverlay();
  };
  const stopLoading = () => {
    gridRef?.current?.api?.hideOverlay();
  };
  const assignValuesToState = (data: any) => {
    setcropId(data?.cropId);
    setbaseStage(data?.config?.baseStage);
    setCondition(data?.config?.condition);
    setpurpose(data?.config?.purpose);
    setcropVariety(data?.cropVarietyId);
    setdaysAfterBaseStage(data?.config?.daysAfterBaseStage);
    setdaysAfterBaseStageView(data?.config?.daysAfterBaseStage.join(","));
    setnote(data?.config?.note);
    setwaterInLitres(data?.config?.waterInLitres);
    setchemicals(data?.config?.chemicals);
    setSprayGap(data?.config?.sprayGap);
    setrules(data?.config?.rules);
    setApplicationMode(data?.config?.applicationMode || []);

    // assign array of numbers range to
    // subsequenceRanges
    let s = "";
    const arr = data?.config?.daysAfterBaseStage || [];
    arr.map((item: number, i: number) => {
      s += +item;
      s += ",";
    });
    setsubsequenceRanges(s);

    setUserNotes(data?.config?.userNotes?.map((note: any) => note?.id));

    setselectedPurposeOption(data?.config?.purpose);

    setselectedcropVarietyOption(
      data?.cropVarietyId?.map((item: any) => {
        return {
          value: item,
          label: item,
        };
      })
    );
  };

  const gridOptions: GridOptions = {
    columnDefs: pesticidesColDef,
    rowData: pesticideData,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      setSelectedModalData(e.data);
      assignValuesToState(e.data);
      setOpenModal(true);
    },
  };

  const rulegridOptions: GridOptions = {
    columnDefs: ruleColDef,
    rowData: rules,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      setRuleData({
        type: e.data?.type,
        typeId: e.data?.typeId,
        days: e.data?.days,
        minDays: e.data?.minDays,
        severity: e.data?.severity,
      });
      setselectedruleType({
        label: e.data?.type,
        value: e.data?.type,
      });
      setselectedruleTypeId({
        label: e.data?.typeId,
        value: e.data?.typeId,
      });
      setseverity({
        label: e.data?.severity,
        value: e.data?.severity,
      });
      let index: any = e.rowIndex;
      setupdateIndex(index);
      setruleModal(true);
    },
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // gridRef?.current?.api.setColumnDefs(farmActivitiesColDef);
    // gridRef?.current?.api.setRowData(tableData);
  }, []);

  // function to perform server side sorting
  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
  };

  // function to perform server side filtering
  const handleColumnFilter = (event: FilterChangedEvent) => {
    if (event.afterFloatingFilter) {
    }
  };

  const onPaginationChanged = useCallback(() => {}, []);

  const onReset = () => {
    setIsApplied(false);
    setisError(false);
    setisPesticideData(false);
    setpesticideData([]);
  };

  const onSubmit = async (crop: string) => {
    setisError(false);
    setisPesticideData(false);

    if (crop.length === 0) {
      alert("Choose one crop");
      return;
    }

    setIsApplied(true);

    try {
      let res = await getPesticideConfigs(crop);

      if (res.length > 0) {
        setisPesticideData(true);
        setpesticideData(res);
      } else {
        setisError(true);
        setisPesticideData(true);
        setpesticideData([]);
      }
    } catch (err) {
      console.log(err);
      setisError(true);
      setisPesticideData(false);
      setpesticideData([]);
    } finally {
      setIsApplied(false);
    }
  };

  const onDataSubmit = async () => {
    if (!!sprayGap) {
      let rangeString = subsequenceRanges.substring(
        0,
        subsequenceRanges.length - 1
      );
      // let daysAfterbaseStageArr = daysAfterBaseStageView.split(',');
      let daysAfterbaseStageArr: any = rangeString.split(",");
      daysAfterbaseStageArr = daysAfterbaseStageArr?.map((i: any) =>
        Number(i.trim())
      );

      const numericChemicals = chemicals?.map((chemicalGroup: any) => {
        return chemicalGroup?.map((chemical: any) => {
          return {
            ...chemical,
            amount: Number(chemical?.amount),
          };
        });
      });
      const numericRules = rules?.map((rule: any) => {
        return {
          ...rule,
          days: Number(rule?.days),
          minDays: Number(rule?.minDays),
        };
      });

      const purposeArr = selectedPurposeOption?.map(
        (purpose: any) => purpose?.value || purpose
      );
      const cropVarietyArr = selectedcropVarietyOption?.map(
        (cropVariety: any) => cropVariety?.value
      );
      const userNote = userNotes?.map((note: any) => {
        return { id: note?.value || note };
      });

      const finalData = {
        cropId: selectedCropOption?.value,
        cropVarietyId: cropVarietyArr,
        config: {
          baseStage: baseStage,
          waterInLitres: waterInLitres,
          purpose: purposeArr,
          sprayGap: sprayGap,
          chemicals: numericChemicals,
          daysAfterBaseStage: daysAfterbaseStageArr,
          condition: condtion,
          note: note,
          rules: numericRules,
          applicationMode: applicationMode,
          userNotes: userNote,
        },
      };
      if (selectedModalData?.id) {
        putPesticideConfigs(finalData, selectedModalData?.id)
          .then((res) => {
            if (res?.message) {
              setToastMessage("Configuration Updated");
              setToastSeverity("success");
              setIsToastOpen(true);
              setOpenModal(false);
              onSubmit(selectedCropOption?.value);
            } else {
              setToastMessage("Some Error Occured");
              setToastSeverity("error");
              setIsToastOpen(true);
            }
          })
          .catch((err) => {
            setToastMessage("Some Error Occured");
            setToastSeverity("error");
            setIsToastOpen(true);
          });
      } else {
        postPesticideConfigs(finalData)
          .then((res) => {
            if (res?.id) {
              setToastMessage("Configuration Added");
              setToastSeverity("success");
              setIsToastOpen(true);
              setOpenModal(false);
              onSubmit(selectedCropOption?.value);
            } else {
              setToastMessage("Some Error Occured");
              setToastSeverity("error");
              setIsToastOpen(true);
            }
          })
          .catch((err) => {
            setToastMessage("Some Error Occured");
            setToastSeverity("error");
            setIsToastOpen(true);
          });
      }
    } else {
      setToastMessage("Spray Gap is required");
      setToastSeverity("error");
      setIsToastOpen(true);
    }
  };

  const onDelete = () => {
    if (selectedModalData?.id) {
      if (selectedModalData?.id) {
        deletePesticideConfig(selectedModalData?.id)
          .then((res: any) => {
            if (res?.message) {
              setOpenModal(false);
              setSelectedCropOption(null);
              setisPesticideData(false);
              setpesticideData([]);
              setToastMessage("Configuration Deleted'");
              setToastSeverity("success");
              setIsToastOpen(true);
            }
          })
          .catch((err) => {
            setToastMessage("Some Error Occured");
            setToastSeverity("error");
            setIsToastOpen(true);
          });
      } else {
        setToastMessage("Some Error Occured");
        setToastSeverity("error");
        setIsToastOpen(true);
      }
    } else {
      setToastMessage("No such Configuration");
      setToastSeverity("error");
      setIsToastOpen(true);
    }
  };

  useEffect(() => {
    getCropOptions().then((res) => {
      setCrops(generateOptions(res, "id", "cropName"));
      setIsCropLoading(false);
    });
    getFertilizers().then((res) => {
      setPesticideOptions(generateOptions(res, "id", "name"));
    });
  }, []);

  useEffect(() => {
    getCropStages(selectedCropOption?.value).then((res: any) => {
      setbaseStageOptions(
        [
          {
            label: "--select--",
            value: "--select--",
          },
        ].concat(generateOptions(res, "stageId", "name") as any)
      );
    });

    getPesticideConfigsCondtion(selectedCropOption?.value).then((res: any) => {
      setConditionOptions(["--select--"].concat(res));
    });

    getCropDiseases(selectedCropOption?.value).then((res: any) => {
      const diseaseOptions = generateOptions(res, "diseaseId", "name");
      setdiseasesOptions(diseaseOptions);
      getCropPests(selectedCropOption?.value).then((res: any) => {
        const pestOptions = generateOptions(res, "pestId", "name");
        setpestsOptions(pestOptions);
      });
    });
    getCropVarieties(selectedCropOption?.value).then((res: any) => {
      setcropVarietyOptions(generateOptions(res, "id", "name"));
    });
    setEnableCopy(true);
  }, [selectedCropOption]);

  useEffect(() => {
    if (selectedCropOption?.value) {
      getcropsStageBasedOnVariety(
        selectedCropOption?.value,
        selectedcropVarietyOption?.map((cropVariety: any) => cropVariety?.value)
      ).then((res: any) => {
        setbaseStageOptions(
          [
            {
              label: "--select--",
              value: "--select--",
            },
          ].concat(generateOptions(res, "stageId", "name") as any)
        );
      });
    }
  }, [selectedcropVarietyOption, selectedCropOption]);

  const handleChemicalDataChange = (e: any) => {
    const { name, value } = e.target;
    setChemicalData({ ...chemicalData, [name]: value });
  };

  const handleRangeSelector = () => {
    let s: any = "";
    let min = +value1;
    let max = +value2;
    for (let i = min; i <= max; i++) {
      s += i.toString();
      s += ",";
    }
    let finalRange = subsequenceRanges.slice();
    finalRange += s;
    setsubsequenceRanges(finalRange);
  };

  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid
        p={2}
        borderRadius={20}
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom={3}
      >
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton onClick={() => navigate("/")}>
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Pesticide Schedule
          </Typography>
        </Grid>
        {selectedCropOption && (
          <input
            onClick={() => {
              setSelectedModalData(null);
              assignValuesToState({});
              setApplicationMode(["foliar"]);
              setcropId(selectedCropOption?.value);
              setOpenModal(true);
            }}
            type="button"
            value="Add Pesticide Config"
            style={{
              width: "15%",
              padding: "10px",
              fontSize: 18,
              paddingTop: "10px",
              alignSelf: "center",
            }}
          ></input>
        )}
      </Grid>
      <ToastAlert
        message={toastMessage}
        severity={toastSeverity}
        open={isToastOpen}
        setOpen={setIsToastOpen}
      />
      <Grid>
        <div
          style={{
            backgroundColor: isApplied ? "#B6F7BC" : "#E6E6E6",
            margin: "0px 0 15px 0",
            padding: "5px",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" ml={1} fontWeight="500" my={1}>
            Crop Id
          </Typography>
          <Select
            options={crops}
            onChange={(selected: SingleValue<any>) => {
              setSelectedCropOption(selected);
              if (selected?.value && selected?.value?.length > 0) {
                onSubmit(selected.value);
              } else {
                onReset();
              }
            }}
            isLoading={isCropLoading}
            isDisabled={isCropLoading}
            value={selectedCropOption}
            isClearable={true}
            isSearchable={true}
            placeholder={"- select -"}
            styles={{
              container: (baseStyles: any, state: any) => ({
                ...baseStyles,
                width: "25%",
                marginLeft: "5px",
              }),
              control: (baseStyles: any, state: any) => ({
                ...baseStyles,
                borderTop: state.isFocused ? "1px" : "0px",
                borderLeft: state.isFocused ? "1px" : "0px",
                borderRight: state.isFocused ? "1px" : "0px",
              }),
              menu: (baseStyles: any, state: any) => ({
                ...baseStyles,
                zIndex: 100,
              }),
            }}
          />
        </div>
      </Grid>

      {isPesticideData && (
        <div style={gridStyle} className="ag-theme-alpine">
          {isArrayWithLength(pesticideData) && (
            <Grid p={3}>
              <AgGridReact
                ref={gridRef}
                rowData={pesticideData}
                columnDefs={pesticidesColDef}
                onGridReady={onGridReady}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                animateRows={true}
                pagination={true}
                paginationPageSize={15}
                gridOptions={gridOptions}
                domLayout="autoHeight"
                onPaginationChanged={onPaginationChanged}
                onSortChanged={handleColumnSort}
                onFilterChanged={handleColumnFilter}
              ></AgGridReact>
            </Grid>
          )}
        </div>
      )}

      {isError && (
        <Typography fontSize={17} color={"red"} margin={3}>
          There seems to be an error. Please try again!
        </Typography>
      )}
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setsubsequenceRanges("");
        }}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Grid
          width={"85%"}
          my={1}
          mx={"auto"}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          style={{
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
            maxWidth: "92%",
            maxHeight: "95%",
            overflow: "auto",
          }}
        >
          <Grid
            p={2}
            borderRadius={20}
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid display={"flex"} item alignItems={"center"}>
              <IconButton onClick={() => setOpenModal(false)}>
                <ArrowBack />
              </IconButton>
              <Typography m={1} variant="h5">
                Pesticide Configuration
              </Typography>
            </Grid>
            {selectedModalData?.id && enableCopy && (
              <IconButton
                style={{ marginRight: 25 }}
                onClick={() => {
                  delete selectedModalData.id;
                  setOpenToast(true);
                }}
              >
                <ContentCopyIcon />
                <Typography marginLeft={0.8} fontSize={18}>
                  Copy
                </Typography>
              </IconButton>
            )}
          </Grid>
          <ToastAlert
            open={openToast}
            setOpen={setOpenToast}
            severity={"info"}
            message={"Content Copied"}
          />
          <form>
            <label
              style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}
            >
              Crop
            </label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <br />
            <Select
              options={crops}
              onChange={(selected: SingleValue<any>) => {
                setSelectedCropOption(selected);
                if (selected?.value && selected?.value?.length > 0) {
                  setcropId(selectedCropOption?.value);
                }
              }}
              isLoading={isCropLoading}
              isDisabled={isCropLoading}
              value={selectedCropOption}
              isClearable={true}
              isSearchable={true}
              placeholder={"- select -"}
              styles={{
                container: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  width: "96%",
                  fontSize: 18,
                  paddingTop: "15px",
                  margin: "2px",
                  marginBottom: 11,
                }),
              }}
            />

            <h3 style={{ margin: 5 }}>Crop Variety</h3>

            <Select
              options={cropVarietyOptions}
              onChange={(selected: MultiValue<any>) => {
                setselectedcropVarietyOption(selected);
              }}
              value={selectedcropVarietyOption}
              isClearable={true}
              isSearchable={true}
              placeholder={"- select -"}
              isMulti
              styles={{
                container: (baseStyles: any, state: any) => ({
                  ...baseStyles,
                  width: "96%",
                  fontSize: 18,
                  paddingTop: "15px",
                  margin: "2px",
                  marginBottom: 11,
                }),
              }}
            />
            <h3 style={{ margin: 5 }}>Base Stage</h3>
            <select
              onChange={(e) => {
                setbaseStage(
                  e.target.value === "--select--" ? "" : e.target.value
                );
              }}
              style={{
                width: "96%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 11,
              }}
              value={baseStage}
            >
              {baseStageOptions?.map(({ value, label }: any, index: any) => (
                <option value={value} key={index}>
                  {label}
                </option>
              ))}
            </select>

            <LocaleDropdown
              value={selectedPurposeOption}
              title="Purpose"
              titleStyle={{ fontSize: 19, fontWeight: "bold", width: "15%" }}
              onChange={(selected: MultiValue<any>) => {
                setselectedPurposeOption(selected);
              }}
              isMulti={true}
              containerStyle={{ width: "99%" }}
            />

            <h3 style={{ margin: 5 }}>Days after Base Stage</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                gap: "15px",
              }}
            >
              <input
                name=""
                onChange={(e) => {
                  setValue1(Number(e.target.value));
                }}
                style={{
                  width: "20%",
                  padding: "20px",
                  fontSize: 18,
                  paddingTop: "15px",
                  margin: "3px",
                  marginBottom: 11,
                }}
              />
              <br />
              <input
                name=""
                onChange={(e) => {
                  setValue2(Number(e.target.value));
                }}
                style={{
                  width: "20%",
                  padding: "20px",
                  fontSize: 18,
                  paddingTop: "15px",
                  margin: "3px",
                  marginBottom: 11,
                }}
              />
              <br />
              <Button
                data-testid="submit"
                onClick={handleRangeSelector}
                style={{
                  backgroundColor: "green",
                  height: "40px",
                  marginTop: "10px",
                  width: "120px",
                  borderRadius: "5px",
                  padding: "0px 2px",
                  border: "1px soild #2e7d32",
                }}
              >
                <Typography
                  variant="overline"
                  textTransform={"capitalize"}
                  style={{ color: "#fff", fontWeight: "600" }}
                >
                  Add Range
                </Typography>
              </Button>
            </div>

            <input
              name=""
              onChange={(e) => {
                setsubsequenceRanges(e.target.value);
              }}
              value={subsequenceRanges}
              type="text"
              style={{
                width: "93%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 11,
              }}
            />
            <br />

            <label 
            style={{
                fontSize: 19,
                fontWeight: "bold",
                marginRight: 5,
                margin: 5,
              }}>Spray Gap</label>
            <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
              *
            </label>
            <input
              name=""
              onChange={(e) => {
                setSprayGap(Number(e.target.value));
              }}
              value={sprayGap}
              style={{
                width: "93%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 11,
              }}
            />
            <br />

            <h3 style={{ margin: 5 }}>Note</h3>
            <input
              name=""
              onChange={(e) => {
                setnote(e.target.value);
              }}
              value={note}
              type="text"
              style={{
                width: "93%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 11,
              }}
            />
            <br />

            <h3 style={{ margin: 5 }}>Water In Litres</h3>
            <input
              name=""
              onChange={(e) => {
                setwaterInLitres(Number(e.target.value));
              }}
              value={waterInLitres}
              type="number"
              style={{
                width: "93%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                margin: "3px",
                marginBottom: 11,
              }}
            />
            <br />
            <LocaleDropdown
              value={userNotes}
              title="User Notes"
              onChange={(s: MultiValue<any>) => setUserNotes(s)}
              isMulti={true}
              titleStyle={{ fontWeight: 700 }}
            />

            <Grid
              my={2}
              mx={"auto"}
              boxShadow={4}
              borderRadius={4}
              padding={5}
              style={{
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#fff",
              }}
            >
              <h3 style={{ margin: 5 }}>Condition</h3>
              <select
                onChange={(e) => {
                  setrules([]);
                  setCondition(
                    e.target.value === "--select--" ? "" : e.target.value
                  );
                }}
                style={{
                  width: "96%",
                  padding: "20px",
                  fontSize: 18,
                  paddingTop: "15px",
                  margin: "3px",
                  marginBottom: 11,
                }}
                value={condtion}
              >
                {conditionOptions?.map((res: any, index: any) => (
                  <option key={index} value={res}>
                    {res}
                  </option>
                ))}
              </select>

              <Grid display={"flex"} justifyContent={"center"}>
                <h2 style={{ margin: 5 }}>OR</h2>
              </Grid>

              <Grid
                display={"flex"}
                justifyContent={"space-between"}
                width={"96%"}
              >
                <h3 style={{ margin: 5 }}>Rules</h3>
                <IconButton
                  onClick={() => {
                    setRuleData({
                      minDays: "",
                      days: "",
                      type: "",
                      typeId: "",
                      severity: "",
                    });
                    setselectedruleType({
                      label: "",
                      value: "",
                    });
                    setselectedruleTypeId({
                      label: "",
                      value: "",
                    });
                    setseverity({
                      label: "",
                      value: "",
                    });
                    setAddruleModal(true);
                  }}
                >
                  <AddBoxIcon />
                </IconButton>
              </Grid>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={gridStyle} className="ag-theme-alpine">
                  {isArrayWithLength(rules) && (
                    <Grid p={3}>
                      <AgGridReact
                        ref={gridRef}
                        rowData={rules}
                        columnDefs={ruleColDef}
                        onGridReady={onGridReady}
                        defaultColDef={defaultColDef}
                        sideBar={sideBar}
                        animateRows={true}
                        pagination={true}
                        paginationPageSize={15}
                        gridOptions={rulegridOptions}
                        domLayout="autoHeight"
                        onPaginationChanged={onPaginationChanged}
                        onSortChanged={handleColumnSort}
                        onFilterChanged={handleColumnFilter}
                      ></AgGridReact>
                    </Grid>
                  )}
                </div>
              </div>
            </Grid>

            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              width={"96%"}
            >
              <h3 style={{ margin: 5 }}>Chemicals</h3>
              <IconButton
                onClick={() => {
                  setshowModal(true);
                }}
              >
                <AddBoxIcon />
              </IconButton>
            </Grid>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {chemicals?.map((chemical: any, index: number) => (
                <div key={index} style={{ marginBottom: 11 }}>
                  <span style={{ marginLeft: "10px" }}>
                    {chemical?.map((chem: any, i: number) => (
                      <span key={chem.id}>
                        <input
                          name=""
                          value={`${chemicals[index][i]?.id} (${chemicals[index][i]?.amount} ${chemicals[index][i]?.unit})`}
                          type="text"
                          style={{
                            padding: "20px",
                            fontSize: 18,
                            paddingTop: "15px",
                          }}
                          onClick={() => {
                            setChemicalData({
                              id: chemicals[index][i]?.id,
                              amount: chemicals[index][i]?.amount,
                              unit: chemicals[index][i]?.unit,
                            });
                            setselectedpesticideID({
                              label: chemicals[index][i]?.id,
                              value: chemicals[index][i]?.id,
                            });
                            setupdateIndex(index);
                            setupdateKey(i);
                            setupdateExistingModal(true);
                          }}
                        />

                        {chemical.length !== i + 1 && (
                          <span
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                          >
                            +
                          </span>
                        )}
                      </span>
                    ))}
                  </span>
                  <IconButton
                    onClick={() => {
                      setupdateIndex(index);
                      setupdateModal(true);
                    }}
                  >
                    <AddCircleIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      let chem = chemicals;
                      chem.splice(index, 1);
                      setchemicals(chem);

                      setselectedpesticideID({
                        label: "",
                        value: "",
                      });
                      setupdateKey(0);
                      setupdateIndex(0);
                      setshowModal(false);
                      setupdateModal(false);
                      setupdateExistingModal(false);
                      setChemicalData({
                        id: "",
                        amount: "",
                        unit: "",
                      });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <br />
                  {chemicals.length !== index + 1 && (
                    <span
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        fontWeight: "bolder",
                        fontSize: 17,
                        padding: 2,
                      }}
                    >
                      OR
                    </span>
                  )}
                </div>
              ))}
            </div>

            <h3 style={{ margin: 5, marginBottom: 8, marginTop: 25 }}>
              Application Mode
            </h3>

            <Grid display={"flex"} justifyContent={"center"} width={"99%"}>
              <label
                style={{ fontSize: 18, fontWeight: "bold", marginRight: 11 }}
              >
                Fertigation
              </label>
              <input
                type="checkbox"
                name="fertigation"
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  if (isChecked) {
                    setApplicationMode((prevModes: any) => [
                      ...prevModes,
                      "fertigation",
                    ]);
                  } else {
                    setApplicationMode((prevModes: any) =>
                      prevModes.filter((mode: any) => mode !== "fertigation")
                    );
                  }
                }}
                checked={applicationMode?.includes("fertigation")}
                style={{ width: "22px", height: "22px", marginRight: 30 }}
              ></input>

              <label
                style={{ fontSize: 18, fontWeight: "bold", marginRight: 11 }}
              >
                Soil Application
              </label>
              <input
                type="checkbox"
                name="soilApplication"
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  if (isChecked) {
                    setApplicationMode((prevModes: any) => [
                      ...prevModes,
                      "soilApplication",
                    ]);
                  } else {
                    setApplicationMode((prevModes: any) =>
                      prevModes.filter(
                        (mode: any) => mode !== "soilApplication"
                      )
                    );
                  }
                }}
                checked={applicationMode?.includes("soilApplication")}
                style={{ width: "22px", height: "22px", marginRight: 30 }}
              ></input>

              <label
                style={{ fontSize: 18, fontWeight: "bold", marginRight: 11 }}
              >
                Foliar Application
              </label>
              <input
                type="checkbox"
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  if (isChecked) {
                    setApplicationMode((prevModes: any) => [
                      ...prevModes,
                      "foliar",
                    ]);
                  } else {
                    setApplicationMode((prevModes: any) =>
                      prevModes.filter((mode: any) => mode !== "foliar")
                    );
                  }
                }}
                name="foliar"
                checked={applicationMode?.includes("foliar")}
                style={{ width: "22px", height: "22px", marginRight: 30 }}
              ></input>

              <label
                style={{ fontSize: 18, fontWeight: "bold", marginRight: 11 }}
              >
                Pasting on tree trunk
              </label>
              <input
                type="checkbox"
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  if (isChecked) {
                    setApplicationMode((prevModes: any) => [
                      ...prevModes,
                      "treeTrunkPasting",
                    ]);
                  } else {
                    setApplicationMode((prevModes: any) =>
                      prevModes.filter(
                        (mode: any) => mode !== "treeTrunkPasting"
                      )
                    );
                  }
                }}
                name="treeTrunkPasting"
                checked={applicationMode?.includes("treeTrunkPasting")}
                style={{ width: "22px", height: "22px" }}
              ></input>
            </Grid>
            <Grid
              display={"flex"}
              justifyContent={"space-evenly"}
              style={{ marginTop: 55 }}
            >
              <input
                type="button"
                value="Submit"
                onClick={onDataSubmit}
                style={{ width: "35%", fontSize: 18, padding: "25px" }}
              />
              <input
                type="button"
                value="Delete"
                onClick={onDelete}
                style={{ width: "35%", fontSize: 18, padding: "25px" }}
              />
            </Grid>
          </form>
        </Grid>
      </Modal>
      <Modal
        open={ruleModal || addruleModal}
        onClose={() => {
          setruleModal(false);
          setAddruleModal(false);
          setRuleData({
            minDays: "",
            days: "",
            type: "",
            typeId: "",
            severity: "",
          });
          setselectedruleType({
            label: "",
            value: "",
          });
          setselectedruleTypeId({
            label: "",
            value: "",
          });
          setseverity({
            label: "",
            value: "",
          });
          setupdateIndex(0);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
            width: "20%",
          }}
        >
          <form>
            <label>
              Type:
              <Select
                options={ruleTypeOptions}
                onChange={(selected: SingleValue<any>) => {
                  setRuleData({ ...ruleData, type: selected?.value });
                  setselectedruleType(selected);
                }}
                value={selectedruleType}
                isClearable={true}
                isSearchable={true}
                placeholder={"- select -"}
                styles={{
                  container: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    marginLeft: "10px",
                    padding: "2px",
                    borderRadius: "5px",
                    border: "none",
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                    width: "90%",
                    marginTop: "10px",
                    backgroundColor: "#BFCCB5",
                  }),
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderTop: state.isFocused ? "1px" : "0px",
                    borderLeft: state.isFocused ? "1px" : "0px",
                    borderRight: state.isFocused ? "1px" : "0px",
                  }),
                  menu: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    zIndex: 100,
                  }),
                }}
              />
            </label>
            <br />
            <label>
              Type Id:
              <Select
                options={
                  ruleData?.type === "diseases" ? diseaseOptions : pestsOptions
                }
                onChange={(selected: SingleValue<any>) => {
                  setRuleData({ ...ruleData, typeId: selected?.value });
                  setselectedruleTypeId(selected);
                }}
                value={selectedruleTypeId}
                isClearable={true}
                isSearchable={true}
                placeholder={"- select -"}
                styles={{
                  container: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    marginLeft: "10px",
                    padding: "2px",
                    borderRadius: "5px",
                    border: "none",
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                    width: "90%",
                    marginTop: "10px",
                    backgroundColor: "#BFCCB5",
                  }),
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderTop: state.isFocused ? "1px" : "0px",
                    borderLeft: state.isFocused ? "1px" : "0px",
                    borderRight: state.isFocused ? "1px" : "0px",
                  }),
                  menu: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    zIndex: 100,
                  }),
                }}
              />
            </label>
            <br />
            <label>
              Severity:
              <Select
                options={severityOptions}
                onChange={(selected: SingleValue<any>) => {
                  setRuleData({ ...ruleData, severity: selected?.value });
                  setseverity(selected);
                }}
                value={severity}
                isClearable={true}
                isSearchable={true}
                placeholder={"- select -"}
                styles={{
                  container: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    marginLeft: "10px",
                    padding: "2px",
                    borderRadius: "5px",
                    border: "none",
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                    width: "90%",
                    marginTop: "10px",
                    backgroundColor: "#BFCCB5",
                  }),
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderTop: state.isFocused ? "1px" : "0px",
                    borderLeft: state.isFocused ? "1px" : "0px",
                    borderRight: state.isFocused ? "1px" : "0px",
                  }),
                  menu: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    zIndex: 100,
                  }),
                }}
              />
            </label>
            <br />
            <label>
              Days:
              <input
                type="text"
                name="days"
                value={ruleData?.days}
                onChange={(e) =>
                  setRuleData({ ...ruleData, days: e.target.value })
                }
                style={{
                  marginLeft: "10px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  width: "90%",
                  marginTop: "10px",
                  backgroundColor: "#BFCCB5",
                }}
              />
            </label>
            <br />
            <label>
              Min Days:
              <input
                type="text"
                name="minDays"
                value={ruleData?.minDays}
                onChange={(e) =>
                  setRuleData({ ...ruleData, minDays: e.target.value })
                }
                style={{
                  marginLeft: "10px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  width: "90%",
                  marginTop: "10px",
                  backgroundColor: "#BFCCB5",
                }}
              />
            </label>
            <br />
            <Grid display={"flex"} justifyContent={"space-between"}>
              <input
                type="button"
                value="Add Rule"
                onClick={() => {
                  if (
                    !!!ruleData?.days ||
                    !!!ruleData?.minDays ||
                    !!!ruleData?.severity ||
                    !!!ruleData?.type ||
                    !!!ruleData?.typeId
                  ) {
                    alert("All fields are compulsory");
                    return;
                  }
                  if (Number(ruleData?.days) < Number(ruleData?.minDays)) {
                    alert("Min Days must be less than equal to Days");
                    return;
                  }
                  if (addruleModal) {
                    setrules([ruleData, ...(rules || [])]);
                  } else {
                    let rule = [...rules];
                    rule[updateIndex] = ruleData;
                    setrules(rule);
                  }
                  setruleModal(false);
                  setAddruleModal(false);
                  setRuleData({
                    minDays: "",
                    days: "",
                    type: "",
                    typeId: "",
                    severity: "",
                  });
                  setselectedruleType({
                    label: "",
                    value: "",
                  });
                  setselectedruleTypeId({
                    label: "",
                    value: "",
                  });
                  setseverity({
                    label: "",
                    value: "",
                  });
                  setupdateIndex(0);
                  setCondition("");
                }}
                style={{
                  padding: "20px",
                  fontSize: "18px",
                  backgroundColor: "#008CBA",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginTop: "20px",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                }}
              />
              {ruleModal && (
                <input
                  type="button"
                  value="Delete rule"
                  onClick={() => {
                    if (ruleModal) {
                      let rule = rules;
                      rule.splice(updateIndex, 1);
                      setrules(rule);

                      setselectedpesticideID({
                        label: "",
                        value: "",
                      });
                      setupdateKey(0);
                      setupdateIndex(0);
                      setruleModal(false);
                    }
                  }}
                  style={{
                    padding: "20px",
                    fontSize: "18px",
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    marginTop: "20px",
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  }}
                />
              )}
            </Grid>
          </form>
        </div>
      </Modal>
      <Modal
        open={showModal || updateModal || updateExistingModal}
        onClose={() => {
          setupdateIndex(0);
          setupdateKey(0);
          setChemicalData({
            id: "",
            amount: "",
            unit: "",
          });
          setselectedpesticideID({
            label: "",
            value: "",
          });
          setshowModal(false);
          setupdateModal(false);
          setupdateExistingModal(false);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
            width: "20%",
          }}
        >
          <form>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <p> Chemical ID:</p>
              <Button
                // data-testid="submit"
                onClick={getFertilizers}
                style={{
                  backgroundColor: "green",
                  height: "30px",
                  marginTop: "13px",
                  width: "100px",
                  borderRadius: "5px",
                  padding: "0px 2px",
                  border: "1px soild #2e7d32",
                }}
              >
                <Typography
                  variant="overline"
                  textTransform={"capitalize"}
                  style={{ color: "#fff", fontWeight: "600" }}
                >
                  Force Reload
                </Typography>
              </Button>
            </div>
            <label>
              <Select
                options={pesticideOptions}
                onChange={(selected: SingleValue<any>) => {
                  setselectedpesticideID(selected);
                  setChemicalData({ ...chemicalData, id: selected?.value });
                }}
                value={selectedpesticideID}
                isClearable={true}
                isSearchable={true}
                placeholder={"- select -"}
                styles={{
                  container: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    marginLeft: "10px",
                    padding: "2px",
                    borderRadius: "5px",
                    border: "none",
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                    width: "90%",
                    marginTop: "10px",
                    backgroundColor: "#BFCCB5",
                  }),
                  control: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    borderTop: state.isFocused ? "1px" : "0px",
                    borderLeft: state.isFocused ? "1px" : "0px",
                    borderRight: state.isFocused ? "1px" : "0px",
                  }),
                  menu: (baseStyles: any, state: any) => ({
                    ...baseStyles,
                    zIndex: 100,
                  }),
                }}
              />
            </label>
            <br />
            <label>
              Amount:
              <input
                type="text"
                name="amount"
                value={chemicalData.amount}
                onChange={handleChemicalDataChange}
                style={{
                  marginLeft: "10px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  width: "90%",
                  marginTop: "10px",
                  backgroundColor: "#BFCCB5",
                }}
              />
            </label>
            <br />
            <label>
              Unit:
              <input
                type="text"
                name="unit"
                value={chemicalData.unit}
                onChange={handleChemicalDataChange}
                style={{
                  marginLeft: "10px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "none",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  width: "90%",
                  marginTop: "10px",
                  backgroundColor: "#BFCCB5",
                }}
              />
            </label>
            <br />
            <Grid display={"flex"} justifyContent={"space-between"}>
              <input
                type="button"
                value="Add Chemical"
                onClick={() => {
                  if (
                    !!!chemicalData?.amount ||
                    !!!chemicalData?.id ||
                    !!!chemicalData?.unit
                  ) {
                    alert("All fields are compulsory");
                    return;
                  }
                  if (showModal) {
                    chemicals?.length > 0
                      ? setchemicals([[chemicalData], ...chemicals])
                      : setchemicals([[chemicalData]]);
                  } else if (updateModal) {
                    let chem = chemicals;
                    chem[updateIndex].push(chemicalData);
                    setchemicals(chem);
                  } else {
                    let chem = chemicals;
                    chem[updateIndex][updateKey] = chemicalData;
                    setchemicals(chem);
                  }
                  setselectedpesticideID({
                    label: "",
                    value: "",
                  });
                  setupdateKey(0);
                  setupdateIndex(0);
                  setshowModal(false);
                  setupdateModal(false);
                  setupdateExistingModal(false);
                  setChemicalData({
                    id: "",
                    amount: "",
                    unit: "",
                  });
                }}
                style={{
                  padding: "20px",
                  fontSize: "18px",
                  backgroundColor: "#008CBA",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                  marginTop: "20px",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                }}
              />
              {updateExistingModal && (
                <input
                  type="button"
                  value="Delete Chemical"
                  onClick={() => {
                    if (updateExistingModal) {
                      let chem = chemicals;
                      chem[updateIndex].splice(updateKey, 1);
                      setchemicals(chem);
                    }
                    setselectedpesticideID({
                      label: "",
                      value: "",
                    });
                    setupdateKey(0);
                    setupdateIndex(0);
                    setshowModal(false);
                    setupdateModal(false);
                    setupdateExistingModal(false);
                    setChemicalData({
                      id: "",
                      amount: "",
                      unit: "",
                    });
                  }}
                  style={{
                    padding: "20px",
                    fontSize: "18px",
                    backgroundColor: "red",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    marginTop: "20px",
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                  }}
                />
              )}
            </Grid>
          </form>
        </div>
      </Modal>
    </Grid>
  );
};

export default Pesticide;
