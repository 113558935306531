import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, IconButton, Modal, Typography, TextField } from '@mui/material'
import { ArrowBack, CreateOutlined, DeleteOutline, ExpandMore, CalendarMonthOutlined, Clear } from '@mui/icons-material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { getPlotActivities, getFarmActivitiesType, getCulturalActivities, addFarmActivites, deleteFarmActivites, updateFarmActivites } from '../services/farmData.service'
import Select from 'react-select';
import { DatePicker } from 'src/components';
import dayjs from 'dayjs';
import { ToastAlert } from 'src/components';
import { ToastSeverity } from 'src/components/ToastAlert/types';
import * as XLSX from "xlsx";

interface ActivityProps {
  data: any;
  deleteActivity: any;
  setUpdateActivity: any;
}

interface ShowActivities {
  propsPlotId?: string;
  backToPlot?: any;
  propsCropId?: string
}

interface ActivityCost {
  currency: string,
  cost: number,
}

interface ActivityCostProps {
  activityCost: ActivityCost;
  setActivityCost: any;
}

const Activities: React.FC<ShowActivities> = (props): JSX.Element => {
  const { backToPlot, propsPlotId, propsCropId } = props;
  const [addActivityModal, setAddActivityModal] = useState<boolean>(false);
  const [activityTypes, setActivityTypes] = useState<any[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<any>(null);
  const [cultActivityTypes, setCultActivityTypes] = useState<any[]>([]);
  const [activityValue, setActivityValue] = useState<any>();
  const [activityCost, setActivityCost] = useState<ActivityCost>({
    currency: 'INR',
    cost: -1
  });
  const [activities, setActivities] = useState<any[]>([]);
  const [doneDate, setDoneDate] = useState<Date>(moment().toDate());
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastSeverity, setToastSeverity] = useState<ToastSeverity>('success');
  const [updateActivity, setUpdateActivity] = useState<any>();
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [fromDate, setFromDate] = useState<any>(dayjs().subtract(3, 'month').toDate())
  const [toDate, setToDate] = useState<any>(dayjs().toDate())
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const navigate = useNavigate()
  let { plotId } = useParams()
  let { cropId } = useParams()
  if (propsPlotId !== undefined) {
    plotId = propsPlotId;
  }
  if (propsCropId !== undefined) {
    cropId = propsCropId
  }

  const defaultDatePickerPlaceholder = {
    maxDate: new Date(),
    calendarIcon: <IconButton><CalendarMonthOutlined /></IconButton>,
    clearIcon: <Clear />,
  }

  useEffect(() => {
    if (!addActivityModal) {
      setDoneDate(moment().toDate())
      setSelectedActivity(null)
      setActivityCost({
        currency: 'INR',
        cost: -1
      });
    }
    if (selectedActivity && !updateActivity) {
      setActivityValue('');
      setActivityCost({
        currency: 'INR',
        cost: -1
      });
    }
  }, [selectedActivity, addActivityModal])

  const getPlaceHolder = (activity: string) => {
    if (activity === "scouting")
      return 'For Example: Observerd Downy Mildew in Plot'
    else if (activity === "spray")
      return 'For Example: Sprayed Copper 1Kg in 500 litre water'
    else if (activity === "other")
      return 'For Example: Did Ploughing in the plot'
    return 'For Example: Applied 50 kg Urea'

  }

  useEffect(() => {
    getFarmActivitiesType().then(res => {
      setActivityTypes(res)
    })
  }, [])

  const getFarmActivities = () => {
    getPlotActivities(plotId as string, fromDate, toDate)
      .then(res => {
        setActivities(res)
      })
  }

  useEffect(() => {
    getFarmActivities();
    if (!!cropId) {
      getCulturalActivities(cropId as string).then((res: any) => {
        if (res.length)
          setCultActivityTypes(res);
        else
          setCultActivityTypes([]);
      })
    }
  }, [plotId, addActivityModal, isToastOpen])

  useEffect(() => {
    if (!!updateActivity) {
      setSelectedActivity(activityTypes?.filter((activity: any) => activity?.id === updateActivity?.farmActivityTypeId)[0])
      setDoneDate(updateActivity?.doneDate);
      setActivityValue(updateActivity?.notes)
      if (updateActivity?.activityCost)
        setActivityCost(updateActivity?.activityCost);
      if (updateActivity?.farmActivityTypeId === 'irrigation') {
        setHours(Math.floor(updateActivity?.notes / 60));
        setMinutes(Number(updateActivity?.notes % 60));
      }
      if (updateActivity?.farmActivityTypeId === 'culturalPractice') {
        setActivityValue(cultActivityTypes?.filter((cultActivityType: any) => updateActivity?.culturalPracticeId === cultActivityType?.id)[0])
      }
      setAddActivityModal(true)

    }
  }, [updateActivity])

  const submit = () => {
    let finalData: any = {};

    finalData.notes = activityValue;

    if (selectedActivity?.id === "irrigation") {
      finalData.notes = activityValue.toString();
    }
    if (selectedActivity?.id === "culturalPractice") {
      if (activityValue?.hasOwnProperty("activity")) {
        finalData.notes = activityValue.activity;
        finalData.culturalPracticeId = activityValue.id;
      }
    }
    finalData.done = true;

    if (activityCost.cost > -1) {
      finalData.activityCost = activityCost
    }
    finalData.plotId = plotId;
    finalData.farmActivityTypeId = selectedActivity?.id;
    finalData.doneDate = dayjs(doneDate).add(1, 'day').toDate();
    finalData.cropId = cropId;

    if (updateActivity?.id) {
      updateFarmActivites(updateActivity?.id, finalData)
        .then((res) => {
          if (res.message === 'Success') {
            setIsToastOpen(true);
            setToastMessage('Activity Updated');
            setToastSeverity('success');
            setAddActivityModal(false);
          }
          else {
            setIsToastOpen(true);
            setToastMessage('Some error occured');
            setToastSeverity('error');
          }
        })
        .catch((err) => {
          setIsToastOpen(true);
          setToastMessage('Some error occured');
          setToastSeverity('error');
        })
    }
    else {
      addFarmActivites(finalData)
        .then((res) => {
          if (res?.id) {
            setIsToastOpen(true);
            setToastMessage("Activity Added");
            setToastSeverity('success');
            setAddActivityModal(false);
          }
          else {
            setIsToastOpen(true);
            setToastMessage("Some error occured");
            setToastSeverity('error');
          }
        })
        .catch((err) => {
          setIsToastOpen(true);
          setToastMessage("Some error occured");
          setToastSeverity('error');
        })
    }
  }

  const deleteActivity = (id: string) => {
    if (!!id && window.confirm("Do you want to delete?")) {
      deleteFarmActivites(id)
        .then((res) => {
          if (res.message === 'Success') {
            setIsToastOpen(true);
            setToastMessage('Activity deleted');
            setToastSeverity('success');
            setAddActivityModal(false);
          }
          else {
            setIsToastOpen(true);
            setToastMessage('Some error occured');
            setToastSeverity('error');
          }
        })
        .catch((err) => {
          setIsToastOpen(true);
          setToastMessage('Some error occured');
          setToastSeverity('error');
        })
    }
  }

  const downloadData = async () => {
    try {
      setIsDownloading(true);

      const completedActivities = activities.filter((activity: any) => activity?.done === true);
      const suggestedActivities = activities.filter((activity: any) => activity?.done === false);

      const filterColumns = (activities: any[], columnsToRender: string[], isSuggested: boolean) => {
        return activities.map(activity => {
          const filteredActivity: any = {};
          columnsToRender.forEach(column => {
            if (isSuggested && column === 'farmActivityTypeId' && activity[column] === 'nutrient') {
              const nutrientsArrayText = activity?.metadata?.nutrients?.map((nutrient: any) => `${nutrient.name ?? 'N.A'}(${nutrient.amount ?? 'N.A'} ${nutrient.unit ?? 'N.A'})`).join(', ');
              filteredActivity.purpose = `To provide the required amount of: ${nutrientsArrayText}`;
              filteredActivity[column] = activity[column];
            } else if (isSuggested && column === 'farmActivityTypeId' && activity[column] === 'spray') {
              const sprayText = activity?.metadata?.purpose?.map((p: any) => `${(p?.displayName || p?.id) ?? 'N.A'}`).join(', ');
              const waterInLitres = activity?.metadata?.waterInLitres ?? '';
              filteredActivity.purpose = `Spray in: ${waterInLitres} litres/acre of water for ${sprayText}`;
              filteredActivity[column] = activity[column];
            }
            else if (column === 'doneDate' || column === 'suggestedDate') {
              filteredActivity[column] = dayjs(activity[column]).format("DD MMM YYYY");
            }
            else {
              filteredActivity[column] = activity[column];
            }
          });
          return filteredActivity;
        });
      };

      const filteredCompletedActivities = filterColumns(completedActivities, ['doneDate', 'farmActivityTypeId', 'daysAfterBaseStage', 'baseStage', 'notes'], false);
      const filteredSuggestedActivities = filterColumns(suggestedActivities, ['suggestedDate', 'farmActivityTypeId', 'daysAfterBaseStage', 'baseStage'], true);


      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Convert filtered activities to worksheet data
      const completedActivitiesWS = XLSX.utils.json_to_sheet(filteredCompletedActivities);
      const suggestedActivitiesWS = XLSX.utils.json_to_sheet(filteredSuggestedActivities);

      // Add worksheets to the workbook
      XLSX.utils.book_append_sheet(workbook, completedActivitiesWS, 'Completed Activities');
      XLSX.utils.book_append_sheet(workbook, suggestedActivitiesWS, 'Suggested Activities');

      // Write workbook to a file
      XLSX.writeFile(workbook,`${plotId}_activities.xlsx`);

    } catch (err: any) {
    } finally {
      setIsDownloading(false)
      setTimeout(() => {
      }, 500)
    }
  }


  return (
    <Grid width={'90%'} my={2} mx={'auto'} boxShadow={2} borderRadius={4} >
      <Grid p={2} borderRadius={20}  >
        <Grid container alignItems={'center'} justifyContent={'space-between'} >
          <Grid display={'flex'} item alignItems={'center'}>
            <IconButton onClick={(propsPlotId !== undefined) ? backToPlot : () => navigate('/plots/' + plotId)} ><ArrowBack /></IconButton>
            <Typography m={1} variant='h5'>Farm Activity</Typography>
          </Grid>
          <Grid>
            <Button onClick={() => { setAddActivityModal(true); setUpdateActivity(null) }} variant='outlined' style={{ borderRadius: '20px', border: '0', boxShadow: '1px 1px 7px #ddd', marginRight: '10px' }} >Add Activity</Button>
            <Button onClick={downloadData} disabled={isDownloading} variant='outlined' style={{ borderRadius: '20px', border: '0', boxShadow: '1px 1px 7px #ddd', marginLeft: '10px' }} >Export</Button>
          </Grid>
        </Grid>
        <Grid item width={'45%'} ml={'28%'}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <DatePicker
                {...defaultDatePickerPlaceholder}
                onChange={setFromDate}
                value={fromDate}
                label='From'
              />
            </Grid>
            <Grid item>
              <Typography variant="h6">-</Typography>
            </Grid>
            <Grid item>
              <DatePicker
                {...defaultDatePickerPlaceholder}
                onChange={setToDate}
                value={toDate}
                label='To'
              />
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={getFarmActivities}>Load Data</Button>
            </Grid>
          </Grid>
        </Grid>
        {activities.length ? (
          <Grid px={2} py={1}>
            {
              activities?.map((activity, ind) => {
                const { farmActivityTypeId } = activity
                if (farmActivityTypeId === 'irrigation') {
                  return <Irrigation data={activity} deleteActivity={deleteActivity} setUpdateActivity={setUpdateActivity} />
                } else if (farmActivityTypeId === 'nutrient') {
                  return <Nutrients data={activity} deleteActivity={deleteActivity} setUpdateActivity={setUpdateActivity} />
                } else if (farmActivityTypeId === 'spray') {
                  return <Spray data={activity} deleteActivity={deleteActivity} setUpdateActivity={setUpdateActivity} />
                } else if (['other', 'scouting', 'culturalPractice'].includes(farmActivityTypeId))
                  return <Others data={activity} deleteActivity={deleteActivity} setUpdateActivity={setUpdateActivity} />
                else {
                  return (
                    <Grid></Grid>
                  )
                }
              })
            }
          </Grid>
        ) : (
          <Typography m={8} textAlign={'center'} color={'red'} fontSize={18}>No Data Found!!</Typography>
        )}
      </Grid>
      <Modal
        open={addActivityModal}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
        onClose={() => { setAddActivityModal(false) }}
      >
        <Grid
          style={{
            backgroundColor: '#fff',
            padding: 12,
            borderRadius: 12,
            margin: 5,
            alignSelf: 'center',
            width: '70%',
            minHeight: '70%',
            maxHeight: '95%',
            overflowY: 'auto'
          }}
        >
          <Grid mb={2} display={'flex'} item justifyContent={'space-between'}>
            <Grid display={'flex'} alignItems={'center'} item>
              <IconButton onClick={() => setAddActivityModal(false)} ><ArrowBack /></IconButton>
              <Typography variant='h5'>Add Activity</Typography>
            </Grid>
            <DatePicker
              showCalendarIcon
              label="Done Date:"
              labelStyle={{ fontWeight: "bold" }}
              value={doneDate}
              onChange={(d: any) => setDoneDate(d)}
            />
          </Grid>
          <Typography fontSize={18} mb={1} fontWeight={'700'} alignSelf={'center'}>What Activity?</Typography>
          <Grid container display={'flex'} flexWrap={'nowrap'} style={{ overflowX: 'scroll', scrollbarWidth: 'none' }} pb={1}>
            {activityTypes?.map((activity: any) => (
              <div key={activity?.id} style={{ cursor: 'pointer' }} onClick={() => setSelectedActivity(activity)}>
                <Typography
                  px={2} mr={1} mb={1}
                  borderRadius={10} border={1}
                  borderColor={selectedActivity?.id === activity?.id ? '#fff' : '#0da00a'}
                  color={selectedActivity?.id === activity?.id ? '#fff' : '#000'}
                  bgcolor={selectedActivity?.id === activity?.id ? '#0da00a' : '#fff'}
                  fontSize={15}
                  minWidth={'max-content'}
                  textTransform={'capitalize'}
                >
                  {activity?.displayName || activity?.name}
                </Typography>
              </div>
            ))}
          </Grid>
          {selectedActivity && ['nutrient', 'scouting', 'spray', 'other'].includes(selectedActivity?.id) && (
            <Grid mb={2} container direction="column">
              <Typography fontSize={18} mb={1} fontWeight={'700'}>{selectedActivity?.displayName || selectedActivity?.name} Note</Typography>
              <TextField
                placeholder={getPlaceHolder(selectedActivity?.id)}
                multiline
                rows={2}
                variant="outlined"
                onChange={(event) => setActivityValue(event?.target?.value)}
                value={activityValue}
              />
            </Grid>

          )}
          {selectedActivity?.id === 'irrigation' && (
            <IrrigationTimeCalculator hours={hours} minutes={minutes} setHours={setHours} setMinutes={setMinutes} setValue={setActivityValue} />
          )}
          {selectedActivity?.id === 'culturalPractice' && (
            <CulturalActivitySelection setValue={setActivityValue} cultActivityType={cultActivityTypes} value={activityValue} />
          )}
          <ActivityCost setActivityCost={setActivityCost} activityCost={activityCost} />

          <input
            type="button"
            value="Submit"
            onClick={submit}
            disabled={!selectedActivity || !activityValue}
            style={{
              marginTop: 20,
              width: '99%',
              padding: '10px',
              fontSize: 18,
              backgroundColor: (!selectedActivity || !activityValue) ? '#e2f2e2' : '#4caf50',
              color: '#fff',
              border: 'none',
              borderRadius: 5,
              cursor: 'pointer',
            }}
          />
        </Grid>

      </Modal>
      <ToastAlert message={toastMessage} severity={toastSeverity} open={isToastOpen} setOpen={setIsToastOpen} />

    </Grid >
  )
}

const Irrigation: React.FC<ActivityProps> = (props): JSX.Element => {
  const { baseStage, created_date, daysAfterBaseStage, notes, suggestedDate, doneDate } = props.data
  return (
    <Grid my={2}>
      <Typography variant='subtitle2' >{moment(doneDate).format('DD-MM-YYYY')}</Typography>
      <Grid item bgcolor={'#ededed'} borderRadius={4} display={'flex'} justifyContent={'space-between'} alignItems={'center'} px={3} >
        <Grid display={'flex'} >
          <Grid>
            <Typography>Irrigation</Typography>
            {/* <Typography fontSize={10} > {daysAfterBaseStage} days after {baseStage} </Typography> */}
          </Grid>
          <Typography mx={4}> {notes} </Typography>
        </Grid>

        <Grid>
          <IconButton onClick={() => props.setUpdateActivity(props.data)}><CreateOutlined /></IconButton>
          <IconButton onClick={() => props.deleteActivity(props.data?.id)}><DeleteOutline /></IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Nutrients: React.FC<ActivityProps> = (props): JSX.Element => {
  const { baseStage, created_date, daysAfterBaseStage, fertilizerOptions, metadata, notes, done, doneDate } = props.data
  const [isAccordianOpen, setIsAccordianOpen] = useState<boolean>(false)
  const nutrientsArray: any[] = metadata?.nutrients

  return (
    <Grid my={2}>
      <Grid>
        <Typography variant='subtitle2' >{moment(done ? doneDate : created_date).format('DD-MM-YYYY')}</Typography>
        <Grid item display={'flex'} justifyContent={'space-between'} bgcolor={done ? '#ededed' : '#fff'} boxShadow={3} py={1} px={3} borderRadius={4} alignItems={'center'} >
          <Grid display={'flex'} flexDirection={'column'}>

            {/* upper part: date and other stuff (before options) */}
            <Grid display={'flex'} >
              {daysAfterBaseStage && baseStage && (
                <Grid>
                  <Typography>Nutrients</Typography>
                  <Typography fontSize={8} fontStyle={'italic'} > {daysAfterBaseStage} days after {baseStage} </Typography>
                </Grid>
              )}
              {!done && nutrientsArray ? (
                <Grid mx={4}>
                  <Typography fontSize={12}>Apply one of the below options via Fertigation to provide the required amount of:</Typography>
                  <Grid container>
                    {
                      nutrientsArray?.map((nutrient, ind) => (
                        <Grid alignItems={'center'} display={'flex'} key={'activity_nutrients_' + ind} >
                          <Typography fontSize={11} fontWeight={600}> {nutrient?.name ?? 'N.A'} : </Typography>
                          <Typography fontSize={11} style={{ padding: '0 2px' }} > {nutrient?.amount ?? 'N.A'} </Typography>
                          <Typography fontSize={11} > {nutrient?.unit ?? 'N.A'} </Typography>
                          {(ind + 1) < nutrientsArray.length && <Typography style={{ padding: '0 4px' }} >|</Typography>}
                        </Grid>
                      ))
                    }
                  </Grid>
                </Grid>
              ) : (
                <Grid display={'flex'} >
                  {!daysAfterBaseStage && !baseStage && (
                    <Grid>
                      <Typography>Nutrient</Typography>
                    </Grid>
                  )}
                  <Typography mx={4}> {notes} </Typography>
                </Grid>
              )}
            </Grid>

            {/* options */}
            {!done && nutrientsArray && fertilizerOptions && (
              <Grid>
                <Accordion style={{
                  boxShadow: '0px 0px 0px #FFF',
                }} >
                  <AccordionSummary
                    // expandIcon={<ExpandMore />}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '0',
                      paddingBottom: '0'
                    }}
                  >
                    <Typography>Show Options</Typography>  <ExpandMore color='success' />
                  </AccordionSummary>
                  <AccordionDetails>
                    {
                      fertilizerOptions?.map((option: any[], index: number) => {
                        return (
                          <Grid display={'flex'} mb={1}  >
                            {
                              option?.map((nutrient, ind2) => (
                                <Typography fontSize={12}>
                                  {`${nutrient?.fertilizer} (${nutrient?.amount} ${nutrient?.unit})`}
                                  {(ind2 + 1) < option.length && <span style={{ margin: '0 3px' }} >+</span>}
                                </Typography>
                              ))
                            }
                          </Grid>
                        )
                      })
                    }
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}

          </Grid>

          {done && (
            <Grid>
              <IconButton onClick={() => props.setUpdateActivity(props.data)}><CreateOutlined /></IconButton>
              <IconButton onClick={() => props.deleteActivity(props.data?.id)}><DeleteOutline /></IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid >
  )
}


const Spray: React.FC<ActivityProps> = (props): JSX.Element => {
  const { baseStage, created_date, daysAfterBaseStage, sprayOptions, metadata, notes, done, doneDate } = props.data
  const [isAccordianOpen, setIsAccordianOpen] = useState<boolean>(false)

  return (
    <Grid my={2}>
      <Grid>
        <Typography variant='subtitle2' >{moment(done ? doneDate : created_date).format('DD-MM-YYYY')}</Typography>
        <Grid item display={'flex'} justifyContent={'space-between'} bgcolor={done ? '#ededed' : '#fff'} boxShadow={3} py={1} px={3} borderRadius={4} alignItems={'center'} >
          <Grid display={'flex'} flexDirection={'column'}>


            {/* upper part: date and other stuff (before options) */}
            <Grid display={'flex'} >
              {daysAfterBaseStage && baseStage && (
                <Grid>
                  <Typography>Spray Pest/Disease</Typography>
                  <Typography fontSize={8} fontStyle={'italic'} > {daysAfterBaseStage} days after {baseStage} </Typography>
                </Grid>
              )}
              {!done && metadata ? (
                <Grid mx={4}>
                  <Typography fontSize={12}>
                    Spray one of the below options in
                    <span style={{ margin: '0 4px' }}>{metadata?.waterInLitres || ''}</span>
                    litres/acre of water for
                    <span>
                      {
                        metadata?.purpose?.map((currentPurpose: any) => (
                          <span style={{ margin: '0 4px' }}>{currentPurpose.id || ''}</span>
                        ))
                      }
                    </span>
                  </Typography>
                </Grid>
              ) : (
                <Grid display={'flex'} >
                  {!daysAfterBaseStage && !baseStage && (
                    <Grid>
                      <Typography>Spray Pest/Disease</Typography>
                    </Grid>
                  )}
                  <Typography mx={4}> {notes} </Typography>
                </Grid>
              )}
            </Grid>

            {/* options */}
            {!done && metadata && sprayOptions && (
              <Grid>
                <Accordion style={{
                  boxShadow: '0px 0px 0px #FFF',
                }} >
                  <AccordionSummary
                    // expandIcon={<ExpandMore />}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '0',
                      paddingBottom: '0'
                    }}
                  >
                    <Typography>Show Options</Typography>  <ExpandMore color='success' />
                  </AccordionSummary>
                  <AccordionDetails>
                    {
                      sprayOptions?.map((option: any[], index: number) => {
                        return (
                          <Grid display={'flex'} mb={1}  >
                            {
                              option?.map((spray, ind2) => (
                                <Typography fontSize={12}>
                                  {`${spray?.id} (${spray?.amount} ${spray?.unit})`}
                                  {(ind2 + 1) < option.length && <span style={{ margin: '0 3px' }} >+</span>}
                                </Typography>
                              ))
                            }
                          </Grid>
                        )
                      })
                    }
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}
          </Grid>

          {done && (
            <Grid>
              <IconButton onClick={() => props.setUpdateActivity(props.data)}><CreateOutlined /></IconButton>
              <IconButton onClick={() => props.deleteActivity(props.data?.id)}><DeleteOutline /></IconButton>
            </Grid>
          )}
        </Grid>

      </Grid>
    </Grid>
  )
}

const Others: React.FC<ActivityProps> = (props): JSX.Element => {
  const { baseStage, daysAfterBaseStage, doneDate, notes, done, farmActivityTypeId } = props.data
  if (done)
    return (
      <Grid my={2}>
        <Grid>
          <Typography variant='subtitle2' >{moment(doneDate).format('DD-MM-YYYY')}</Typography>
          <Grid item display={'flex'} justifyContent={'space-between'} bgcolor={done ? '#ededed' : '#fff'} boxShadow={3} py={1} px={3} borderRadius={4} alignItems={'center'} >
            <Grid display={'flex'} flexDirection={'column'}>

              {/* upper part: date and other stuff (before options) */}
              <Grid display={'flex'} >
                {daysAfterBaseStage && baseStage && (
                  <Grid>
                    <Typography>{farmActivityTypeId === 'other' ? 'Other' : farmActivityTypeId === 'scouting' ? 'Scouting' : 'Cultural Practice'}</Typography>
                    <Typography fontSize={8} fontStyle={'italic'} > {daysAfterBaseStage} days after {baseStage} </Typography>
                  </Grid>
                )}
                <Grid display={'flex'} >
                  {!daysAfterBaseStage && !baseStage && (
                    <Grid>
                      <Typography>{farmActivityTypeId === 'other' ? 'Other' : farmActivityTypeId === 'scouting' ? 'Scouting' : 'Cultural Practice'}</Typography>
                    </Grid>
                  )}
                  <Typography mx={4}> {notes} </Typography>
                </Grid>

              </Grid>
            </Grid>

            {done && (
              <Grid>
                <IconButton onClick={() => props.setUpdateActivity(props.data)}><CreateOutlined /></IconButton>
                <IconButton onClick={() => props.deleteActivity(props.data?.id)}><DeleteOutline /></IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid >
    )
  return <></>
}

const IrrigationTimeCalculator = ({ setValue, hours, minutes, setHours, setMinutes }: any) => {

  const calculateTotalTime = () => {
    const IrrigationTime = hours * 60 + minutes;
    setValue(IrrigationTime);
    return IrrigationTime;
  };

  // Options for hours
  const hourOptions = [...Array(24)].map((_, index) => ({
    value: index,
    label: index.toString(),
  }));

  // Options for minutes
  const minuteOptions = [...Array(60)].map((_, index) => ({
    value: index,
    label: index.toString(),
  }));

  return (
    <Grid>
      <Typography fontSize={18} mb={1} fontWeight={'700'} alignSelf={'center'}>Irrigation</Typography>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ marginBottom: '20px', marginRight: '10px', width: '30%' }}>
          <label htmlFor="hours" style={{ fontSize: '18px', marginRight: '10px' }}>Hours</label>
          <Select
            id="hours"
            options={hourOptions}
            value={hourOptions.find(option => option.value === hours)}
            onChange={(selectedOption) => selectedOption?.value && setHours(selectedOption.value)}
          />
        </div>
        <div style={{ marginBottom: '20px', marginRight: '30px', width: '30%' }}>
          <label htmlFor="minutes" style={{ fontSize: '18px', marginRight: '10px' }}>Minutes</label>
          <Select
            id="minutes"
            options={minuteOptions}
            value={minuteOptions.find(option => option.value === minutes)}
            onChange={(selectedOption) => selectedOption?.value && setMinutes(selectedOption.value)}
          />
        </div>
        <div>
          <p style={{ fontSize: '20px' }}>Total Time: {calculateTotalTime()} minutes</p>
        </div>
      </div>
    </Grid>
  );
};

const CulturalActivitySelection = ({ setValue, cultActivityType, value }: any) => {

  return (
    <Grid mb={1}>
      <Typography fontSize={18} mb={1} fontWeight={'700'} alignSelf={'center'}>Which Cultural Activity?</Typography>
      <Grid container display={'flex'} flexWrap={'nowrap'} style={{ overflowX: 'scroll', scrollbarWidth: 'none' }} pb={1}>
        {cultActivityType?.map((activity: any) => (
          <div key={activity?.id} style={{ cursor: 'pointer' }} onClick={() => setValue(activity)}>
            <Typography
              px={2} mr={1} mb={1}
              borderRadius={10} border={1}
              borderColor={value?.id === activity?.id ? '#fff' : '#0da00a'}
              color={value?.id === activity?.id ? '#fff' : '#000'}
              bgcolor={value?.id === activity?.id ? '#0da00a' : '#fff'}
              fontSize={15}
              minWidth={'max-content'}
              textTransform={'capitalize'}
            >
              {activity?.displayName || activity?.activity}
            </Typography>
          </div>
        ))}
      </Grid>
    </Grid>

  );
};

const ActivityCost: React.FC<ActivityCostProps> = ({ activityCost, setActivityCost }) => {
  const currencies = ['INR', 'USD', 'EUR', 'GBP', 'JPY', 'AUD'];
  return (
    <div>
      <Typography fontSize={18} mb={1} fontWeight={'700'} alignSelf={'center'}>Add Activity Cost</Typography>
      <select style={{ width: '15%', height: 56 }} value={activityCost?.currency} onChange={(e) => setActivityCost({ ...activityCost, currency: e.target.value })}>
        {currencies.map((currency, index) => (
          <option key={index} value={currency}>
            {currency}
          </option>
        ))}
      </select>

      <TextField
        placeholder={'For Example: 400'}
        type='number'
        variant="outlined"
        onChange={(e) => setActivityCost({ ...activityCost, cost: e.target.value })}
        value={activityCost.cost > -1 ? activityCost.cost : ''}
        style={{ width: '35%', height: 70, marginLeft: 6 }}
      />
    </div>

  );
};

export default Activities