import { WeatherIcons, WindDirectionIcons } from 'src/constants/icons'

export const diseasePestRanges = [
  {
    name: 'Nil',
    color: 'grey',
    label: 'Probability'
  },
  {
    name: 'Low',
    color: 'green',
    label: 'Probability'
  },
  {
    name: 'Medium',
    color: 'yellow',
    label: 'Probability'
  },
  {
    name: 'High',
    color: 'red',
    label: 'Probability'
  }
];

export const airTempRanges = [
  {
    max: 10,
    name: 'Cold',
    color: 'grey',
    unit: '°C',
    label: 'Air Temperature'
  },
  {
    min: 10,
    max: 22,
    name: 'Low',
    color: 'green',
    unit: '°C',
    label: 'Air Temperature'
  },
  {
    min: 22,
    max: 35,
    name: 'Moderate',
    color: 'yellow',
    unit: '°C',
    label: 'Air Temperature'
  },
  {
    min: 35,
    name: 'Hot',
    color: 'red',
    unit: '°C',
    label: 'Air Temperature'
  }
];

export const airHumidityRanges = [
  {
    min: 0,
    max: 40,
    color: 'grey',
    unit: '%',
    label: 'Air Humidity'
  },
  {
    min: 40,
    max: 60,
    color: 'green',
    unit: '%',
    label: 'Air Humidity'
  },
  {
    min: 60,
    max: 80,
    color: 'yellow',
    unit: '%',
    label: 'Air Humidity'
  },
  {
    min: 80,
    max: 100,
    color: 'red',
    unit: '%',
    label: 'Air Humidity'
  }
];

export const rainFallRanges = [
  {
    min: 0,
    max: 10,
    color: 'grey',
    unit: 'mm',
    label: 'Rain Fall'
  },
  {
    min: 10,
    max: 30,
    color: 'green',
    unit: 'mm',
    label: 'Rain Fall'
  },
  {
    min: 30,
    max: 50,
    color: 'yellow',
    unit: 'mm',
    label: 'Rain Fall'
  },
  {
    min: 50,
    color: 'red',
    unit: 'mm',
    label: 'Rain Fall'
  }
];

export const windSpeedRanges = [
  {
    min: 0,
    max: 10,
    color: 'grey',
    unit: 'KM/hr',
    label: 'Wind Speed'
  },
  {
    min: 11,
    max: 20,
    color: 'green',
    unit: 'KM/hr',
    label: 'Wind Speed'
  },
  {
    min: 21,
    max: 40,
    color: 'yellow',
    unit: 'KM/hr',
    label: 'Wind Speed'
  },
  {
    min: 41,
    max: 100,
    color: 'red',
    unit: 'KM/hr',
    label: 'Wind Speed'
  }
];

export const sunshineHourRanges = [
  {
    min: 0,
    max: 2,
    color: 'grey',
    unit: 'hr',
    label: 'Sunshine Hours'
  },
  {
    min: 3,
    max: 5,
    color: 'green',
    unit: 'hr',
    label: 'Sunshine Hours'
  },
  {
    min: 6,
    max: 8,
    color: 'yellow',
    unit: 'hr',
    label: 'Sunshine Hours'
  },
  {
    min: 9,
    color: 'red',
    unit: 'hr',
    label: 'Sunshine Hours'
  }
];

export const dayLengthRanges = [
  {
    min: 0,
    max: 5,
    color: 'grey',
    unit: 'hr',
    label: 'Day Length'
  },
  {
    min: 6,
    max: 11,
    color: 'green',
    unit: 'hr',
    label: 'Day Length'
  },
  {
    min: 12,
    max: 16,
    color: 'yellow',
    unit: 'hr',
    label: 'Day Length'
  },
  {
    min: 17,
    max: 24,
    color: 'red',
    unit: 'hr',
    label: 'Day Length'
  }
]

export const leafWetnessRanges = [
  {
    min: 0,
    max: 2,
    color: 'grey',
    unit: 'hr',
    label: 'Leaf Wetness'
  },
  {
    min: 3,
    max: 5,
    color: 'green',
    unit: 'hr',
    label: 'Leaf Wetness'
  },
  {
    min: 6,
    max: 8,
    color: 'yellow',
    unit: 'hr',
    label: 'Leaf Wetness'
  },
  {
    min: 9,
    color: 'red',
    unit: 'hr',
    label: 'Leaf Wetness'
  }
];

export const lightIntensityRanges = [
  {
    min: 0,
    max: 1000,
    color: 'grey',
    unit: 'lux',
    label: 'Light Intensity'
  },
  {
    min: 1000,
    max: 10000,
    color: 'green',
    unit: 'lux',
    label: 'Light Intensity'
  },
  {
    min: 10000,
    max: 50000,
    color: 'yellow',
    unit: 'lux',
    label: 'Light Intensity'
  },
  {
    min: 50000,
    max: 100000,
    color: 'red',
    unit: 'lux',
    label: 'Light Intensity'
  }
];

export const soilTempRanges = [
  {
    max: 10,
    name: 'Cold',
    color: 'grey',
    unit: '°C',
    label: 'Soil Temperature'
  },
  {
    min: 10,
    max: 20,
    name: 'Low',
    color: 'green',
    unit: '°C',
    label: 'Soil Temperature'
  },
  {
    min: 20,
    max: 30,
    name: 'Moderate',
    color: 'yellow',
    unit: '°C',
    label: 'Soil Temperature'
  },
  {
    min: 30,
    max: 60,
    name: 'Hot',
    color: 'red',
    unit: '°C',
    label: 'Soil Temperature'
  }
];

export const soilMoistureRanges = [
  {
    min: 0,
    max: 5,
    name: 'Too Wet',
    color: 'grey',
    unit: 'kPa',
    label: 'Soil Moisture'
  },
  {
    min: 6,
    max: 15,
    name: 'Optimum',
    color: 'green',
    unit: 'kPa',
    label: 'Soil Moisture'
  },
  {
    min: 16,
    max: 35,
    name: 'Dry',
    color: 'yellow',
    unit: 'kPa',
    label: 'Soil Moisture'
  },
  {
    min: 36,
    name: 'Too Dry',
    color: 'red',
    unit: 'kPa',
    label: 'Soil Moisture'
  }
];

export const airPressureRanges = [
  {
    max: 85,
    name: 'Low',
    color: 'grey',
    unit: 'kPa',
    label: 'Air Pressure'
  },
  {
    min: 86,
    max: 95,
    name: 'Medium',
    color: 'green',
    unit: 'kPa',
    label: 'Air Pressure'
  },
  {
    min: 96,
    max: 105,
    name: 'High',
    color: 'yellow',
    unit: 'kPa',
    label: 'Air Pressure'
  },
  {
    min: 106,
    name: 'Too High',
    color: 'red',
    unit: 'kPa',
    label: 'Air Pressure'
  }
];

export const baroPressureRanges = [
  {
    max: 850,
    name: 'Low',
    color: 'grey',
    unit: 'hPa',
    label: 'Baro Pressure'
  },
  {
    min: 851,
    max: 950,
    name: 'Medium',
    color: 'green',
    unit: 'hPa',
    label: 'Baro Pressure'
  },
  {
    min: 951,
    max: 1050,
    name: 'High',
    color: 'yellow',
    unit: 'hPa',
    label: 'Baro Pressure'
  },
  {
    min: 1051,
    name: 'Too High',
    color: 'red',
    unit: 'hPa',
    label: 'Baro Pressure'
  }
];

export const signalStrengthRanges = [
  {
    min: 0,
    max: 40,
    name: 'Very Low',
    color: 'grey',
    unit: '%',
    label: 'Signal Strength'
  },
  {
    min: 40,
    max: 60,
    name: 'Low',
    color: 'green',
    unit: '%',
    label: 'Signal Strength'
  },
  {
    min: 60,
    max: 80,
    name: 'Moderate',
    color: 'yellow',
    unit: '%',
    label: 'Signal Strength'
  },
  {
    min: 80,
    max: 100,
    name: 'Good',
    color: 'red',
    unit: '%',
    label: 'Signal Strength'
  }
];

export const WeatherIconRanges = [
  {
    name: 'Unknown',
    color: 'white',
    icon: WeatherIcons.Unknown
  },
  {
    name: 'Clear',
    color: 'white',
    icon: WeatherIcons.Clear
  },
  {
    name: 'Cloudy',
    color: 'white',
    icon: WeatherIcons.Cloudy
  },
  {
    name: 'Fog',
    color: 'white',
    icon: WeatherIcons.Fog
  },
  {
    name: 'Wind',
    color: 'white',
    icon: WeatherIcons.Wind
  },
  {
    name: 'Rain',
    color: 'white',
    icon: WeatherIcons.Rain
  },
  {
    name: 'Snow',
    color: 'white',
    icon: WeatherIcons.Snow
  },
  {
    name: 'Hail',
    color: 'white',
    icon: WeatherIcons.Hail
  },
  {
    name: 'Storm',
    color: 'white',
    icon: WeatherIcons.Storm
  },
  {
    name: 'Hot',
    color: 'white',
    icon: WeatherIcons.Hot
  },
  {
    name: 'Cold',
    color: 'white',
    icon: WeatherIcons.Cold
  },
]

export const WindDirectionanges = [
  {
    name: 'North',
    color: 'white',
    icon: WindDirectionIcons.North
  },
  {
    name: 'South',
    color: 'white',
    icon: WindDirectionIcons.South
  },
  {
    name: 'East',
    color: 'white',
    icon: WindDirectionIcons.East
  },
  {
    name: 'West',
    color: 'white',
    icon: WindDirectionIcons.West
  },
  {
    name: 'North East',
    color: 'white',
    icon: WindDirectionIcons.NorthEast
  },
  {
    name: 'South East',
    color: 'white',
    icon: WindDirectionIcons.SouthEast
  },
  {
    name: 'South West',
    color: 'white',
    icon: WindDirectionIcons.SouthWest
  },
  {
    name: 'North West',
    color: 'white',
    icon: WindDirectionIcons.NorthWest
  },
  {
    name: 'East North East',
    color: 'white',
    icon: WindDirectionIcons.ENE
  },
  {
    name: 'East South East',
    color: 'white',
    icon: WindDirectionIcons.ESE
  },
  {
    name: 'South South East',
    color: 'white',
    icon: WindDirectionIcons.SSE
  },
  {
    name: 'South South West',
    color: 'white',
    icon: WindDirectionIcons.SSW
  },
  {
    name: 'West South West',
    color: 'white',
    icon: WindDirectionIcons.WSW
  },
  {
    name: 'West North West',
    color: 'white',
    icon: WindDirectionIcons.WNW
  },
  {
    name: 'North North West',
    color: 'white',
    icon: WindDirectionIcons.NNW
  },
  {
    name: 'North North East',
    color: 'white',
    icon: WindDirectionIcons.NNE
  }
]

export const fieldLegends: any = {
  airTemp: airTempRanges,
  MinAirTemp: airTempRanges,
  MaxAirTemp: airTempRanges,
  airHumidity: airHumidityRanges,
  MinAirHumidity: airHumidityRanges,
  MaxAirHumidity: airHumidityRanges,
  rainFall: rainFallRanges,
  windSpeed: windSpeedRanges,
  MinWindSpeed: windSpeedRanges,
  MaxWindSpeed: windSpeedRanges,
  lightIntensity: lightIntensityRanges,
  MaxLux: lightIntensityRanges,
  sunshineHours: sunshineHourRanges,
  leafWetnessHours: leafWetnessRanges,
  soilTemp: soilTempRanges,
  MinSoilTemp: soilTempRanges,
  MaxSoilTemp: soilTempRanges,
  soilMoisture1: soilMoistureRanges,
  MinSoilMoisture1: soilMoistureRanges,
  MaxSoilMoisture1: soilMoistureRanges,
  soilMoisture2: soilMoistureRanges,
  MinSoilMoisture2: soilMoistureRanges,
  MaxSoilMoisture2: soilMoistureRanges,
  airPressure: airPressureRanges,
  MinAirPressure: airPressureRanges,
  MaxAirPressure: airPressureRanges,
  baroPressure: baroPressureRanges,
  MinBaroPressure: baroPressureRanges,
  MaxBaroPressure: baroPressureRanges,
  signalStrength: signalStrengthRanges,
  dayLength: dayLengthRanges,
  MinSignalStrength: signalStrengthRanges,
  MaxSignalStrength: signalStrengthRanges,
  cloudCover: signalStrengthRanges,
  weatherIcon: WeatherIconRanges,
  windDirection: WindDirectionanges

};