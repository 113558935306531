import { Grid, Tab } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Skeleton } from "src/components";
import { fetchActivityDetailsByActivityId } from "src/services/userEvent.service";
import {
  PopularActivities,
  TimeButtonGroup,
  InfoTabsHeaders as UserInfoTabs,
  InfoTabPanel as UserTabPanel,
  UsersChart,
  UsersTable
} from "../components";
import { TTimeFrame, TTimeRange } from "../types";
import { userInfoActivityTableCols } from "../utils/colDefs";
import { tabStyles } from "../utils/constant";

const UserTabs = ['Daily Active Users', 'Overall Engagement'];

const UsersInfo: FC = () => {

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  /**
   * For User Info
   * */
  const [userInfoTimeFrame, setUserInfoTimeFrame] = useState<TTimeFrame>('1W');
  const [userInfoTimeRange, setUserInfoTimeRange] = useState<TTimeRange>();

  /**
   * For Popular Activities
   * */
  const [popularActivitiesTimeFrame, setPopularActivitiesTimeFrame] = useState<TTimeFrame>('1W');
  const [popularActivitiesTimeRange, setPopularActivitiesTimeRange] = useState<TTimeRange>();

  /**
   * For table
   * */
  const [userInfoTableData, setUserInfoTableData] = useState<{ loading: boolean, data: any[] }>({
    loading: false, data: []
  });
  const [showUserInfoTable, setShowUserInfoTable] = useState<boolean>(false);

  /**
   * @function
   * updates user info time range when time frame updates
   * */
  useEffect(() => {
    if (!!userInfoTimeFrame) {
      const { startTime, endTime } = getTimeRange(userInfoTimeFrame);

      setUserInfoTimeRange({
        startTime: startTime.toString(),
        endTime: endTime.toString()
      })
    }
  }, [userInfoTimeFrame])

  /**
   * @function
   * updates popular activities time range when time frame updates
   * */
  useEffect(() => {
    if (!!popularActivitiesTimeFrame) {
      const { startTime, endTime } = getTimeRange(popularActivitiesTimeFrame);

      setPopularActivitiesTimeRange({
        startTime: startTime.toString(),
        endTime: endTime.toString()
      })
    }
  }, [popularActivitiesTimeFrame])

  /**
   * @function
   * returns start and end time for the selected Time Frame
   * */
  const getTimeRange = (timeFrame: TTimeFrame): TTimeRange => {
    const todaysStartTime = moment().startOf('day'), todaysEndTime = moment().endOf('day');
    let startTime = todaysStartTime, endTime = todaysEndTime;
    switch (timeFrame) {
      case '1D':
        startTime = todaysStartTime;
        break;
      case '1W':
        startTime = todaysStartTime.add(-1, 'week');
        break;
      case '1M':
        startTime = todaysStartTime.add(-1, 'month');
        break;
      default:
        break;
    }

    return {
      startTime: startTime.toString(),
      endTime: endTime.toString()
    }
  }

  /**
   * @function
   * updates current tab index
   * */
  const handleTabChange = (event: React.SyntheticEvent, newIndex: number) => {
    setCurrentTabIndex(newIndex);
  };

  /**
   * @function
   * wrapper function for updating user info time frame
   * */
  const handleUserInfoTimeFrame = (newTimeFrame: TTimeFrame): void => {
    setUserInfoTimeFrame(newTimeFrame);
  }

  /**
   * @function
   * wrapper function for updating popular activities time frame
   * */
  const handlePopularActivitiesTimeFrame = (newTimeFrame: TTimeFrame): void => {
    setPopularActivitiesTimeFrame(newTimeFrame);
  }

  const getUserDetails = async (activityId: string) => {
    setShowUserInfoTable(true);
    setUserInfoTableData(userInfoTableData => ({ ...userInfoTableData, loading: true }));
    const response: any = await fetchActivityDetailsByActivityId(popularActivitiesTimeRange?.startTime!, popularActivitiesTimeRange?.endTime!, activityId);
    setUserInfoTableData({ data: response.finalData, loading: false });
  }

  return (
    <Grid>
      <Grid container justifyContent={'space-between'} >
        {/**
          * Left section: unique users and active users
          */}
        <Grid item xs={6.5}>
          {/* header */}
          <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
            <UserInfoTabs tabsFor={'users'} tabs={UserTabs} currentTabIndex={currentTabIndex} handleTabChange={handleTabChange} />
            <TimeButtonGroup timeFrameFor='UsersList' activeButton={userInfoTimeFrame} handleDateChange={handleUserInfoTimeFrame} />
          </Grid>

          {/* chart */}
          <Grid minHeight={600}>
            <UserTabPanel panelFor={'unique-users'} value={currentTabIndex} index={0}>
              <UsersChart startTime={userInfoTimeRange?.startTime!} endTime={userInfoTimeRange?.endTime!} />
            </UserTabPanel>
            <UserTabPanel panelFor={'active-users'} value={currentTabIndex} index={1}>
              <UsersTable startTime={userInfoTimeRange?.startTime!} endTime={userInfoTimeRange?.endTime!} />
            </UserTabPanel>
          </Grid>
        </Grid>

        {/**
          * Right section: popular events
          */}
        <Grid item xs={4.5}>
          {/* header */}
          <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'} >
            <Tab sx={tabStyles} label={'Popular Activities'} />
            <TimeButtonGroup timeFrameFor='PopularActivities' activeButton={popularActivitiesTimeFrame} handleDateChange={handlePopularActivitiesTimeFrame} />
          </Grid>

          {/* chart */}
          <PopularActivities startTime={popularActivitiesTimeRange?.startTime!} endTime={popularActivitiesTimeRange?.endTime!} handleChartClick={getUserDetails} />
        </Grid>
      </Grid>

      {
        showUserInfoTable && <Grid>
          <Tab sx={{ ...tabStyles, marginRight: '10px', padding: '0px 5px' }} label={'User Activity Details'} />
          <Grid height={515} className="ag-theme-alpine" p={0} m={0}>
            {
              userInfoTableData.loading ?
                <Skeleton.Table width={200} height={500} noOfRows={5} /> :
                <AgGridReact
                  columnDefs={userInfoActivityTableCols}
                  rowData={userInfoTableData.data || []}
                  pagination={true}
                  paginationPageSize={10}
                />
            }
          </Grid>
        </Grid>
      }
    </Grid>
  )
}

export default UsersInfo