import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  IconButton,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Select,
  Button,
  CircularProgress,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import {
  deleteScreenData,
  getScreenType,
  patchScreenData,
  postScreenData,
} from "src/services/screen.service";
import { getLocale } from "src/services/locale.service";

interface Props {
  openModal?: any;
  setOpenModal?: any;
  modalData?: any;
  screenTitle?: string;
  screenType?: string;
}

type ParameterType =
  | "boolean"
  | "date"
  | "string"
  | "number"
  | "multiSelect"
  | "singleSelect"
  | "expression";

type Option = {
  value: string | number;
  id?: string;
};

interface Parameter {
  id: string;
  name?: string;
  description?: string;
  type: ParameterType;
  order: number;
  options?: Option[];
  expression?: string;
  required?: boolean;
  readonly?: boolean;
}

const DynamicScreen: React.FC<Props> = (props: Props): JSX.Element => {
  const { openModal, setOpenModal, modalData, screenTitle, screenType } = props;
  const [screenField, setScreenField] = useState<any>();
  const [localeIds, setLocaleIds] = useState<any[]>([]);
  const [locales, setLocales] = useState<any>();
  const [formValues, setFormValues] = useState<any>();
  useEffect(() => {
    getScreenType(screenType as string).then((res) => {
      res?.fields?.sort((a: any, b: any) => a.order - b.order);
      setScreenField(res);
      setFormValues(
        res?.fields?.reduce((acc: any, field: any) => {
          acc[field?.id] = modalData?.profile?.[field?.id] || "";
          return acc;
        }, {})
      );
      const ids: string[] = [];
      res?.fields.forEach((param: any) => {
        ids.push(param.id);
        if (param?.options && Array.isArray(param?.options)) {
          param?.options?.forEach((option: any) => {
            if (option?.id) ids.push(option.id);
          });
        }
      });
      setLocaleIds(ids);
    });
  }, [openModal]);

  useEffect(() => {
    if (localeIds.length > 0) {
      const filter = {
        where: {
          id: { inq: localeIds },
        },
      };
      getLocale(filter).then((res) => {
        transformLocale(res, "en");
      });
    }
  }, [localeIds]);

  const transformLocale = (array: any[], language: string) => {
    const result: Record<string, string> = {};

    array.forEach((item) => {
      if (item.id && item[language]) {
        result[item.id] = item[language];
      }
    });
    setLocales(result);
  };

  const handleChange = (event: any) => {
    const { name, value, type } = event.target;
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]:
        type === "number"
          ? Number(value)
          : value === "true"
          ? true
          : value === "false"
          ? false
          : value,
    }));
  };

  const handleSubmit = () => {
    const mandatoryFields = screenField?.fields
      ?.filter((field: any) => field.required)
      ?.map((field: any) => field.id);

    const missingFields = mandatoryFields?.filter(
      (fieldId: any) => !formValues[fieldId] && formValues[fieldId] !== 0
    );

    if (missingFields.length > 0) {
      const missingFieldNames = missingFields.map((fieldId: any) => {
        const field = screenField?.fields.find((f: any) => f.id === fieldId);
        const localizedName = locales?.[fieldId] || field?.name || fieldId;
        return localizedName;
      });

      alert(
        `Please fill out the mandatory fields: ${missingFieldNames.join(", ")}`
      );
      return;
    }

    if (window.confirm("Do you want to submit?")) {
      const filteredValues = Object.entries(formValues)
        .filter(
          ([key, value]) =>
            value !== "" && value !== null && value !== undefined
        )
        .reduce((acc: any, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      if (modalData?.id) {
        patchScreenData(screenField?.endPoint, {
          ...modalData,
          profile: filteredValues,
        }).then((res: any) => {
          if (res.error) alert("Some Error Occurred");
          else {
            setOpenModal(false);
            alert("Data Updated");
          }
        });
      } else {
        postScreenData(screenField?.endPoint, { profile: filteredValues }).then(
          (res: any) => {
            if (res.id) {
              alert("Data added");
              setOpenModal(false);
            } else alert("Some Error Occurred");
          }
        );
      }
    }
  };

  const handleDelete = () => {
    if (modalData.id) {
      if (window.confirm("Do you want to delete?")) {
        deleteScreenData(screenField?.endPoint, modalData?.id).then(
          (res: any) => {
            if (res.error) alert("Some Error Occured");
            else {
              setFormValues(
                screenField.fields.reduce((acc: any, field: any) => {
                  acc[field?.id] = "";
                  return acc;
                }, {})
              );
              alert("Data Deleted");
              setOpenModal(false);
            }
          }
        );
      }
    } else alert("Some Error Occured");
  };  

  const renderField = (field: Parameter) => {
    const isMandatory = field?.required;

    const label = (
      <Typography
        variant="body1"
        component="span"
        sx={{ fontSize: "1.1rem", fontWeight: 400, color: "text.primary" }}
      >
        {locales?.[field?.id] || field.name || field.id}{" "}
        {isMandatory && <span style={{ color: "red" }}>*</span>}
      </Typography>
    );

    switch (field.type) {
      case "boolean":
        return (
          <Grid key={field?.id} item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>{label}</InputLabel>
              <Select
                name={field?.id}
                value={formValues[field?.id] || false}
                onChange={handleChange}
                disabled={field?.readonly}
                MenuProps={{ PaperProps: { style: { maxHeight: 224 } } }}
              >
                <MenuItem value={"true"}>True</MenuItem>
                <MenuItem value={"false"}>False</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        );
      case "date":
        return (
          <Grid key={field?.id} item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel shrink>{label}</InputLabel>
              <TextField
                type="date"
                name={field?.id}
                value={formValues[field?.id] || ""}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                disabled={field?.readonly}
                fullWidth
              />
            </FormControl>
          </Grid>
        );
      case "string":
        return (
          <Grid key={field?.id} item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                type="text"
                name={field?.id}
                label={label}
                value={formValues[field?.id] || ""}
                onChange={handleChange}
                disabled={field?.readonly}
                fullWidth
              />
            </FormControl>
          </Grid>
        );
      case "number":
        return (
          <Grid key={field?.id} item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                type="number"
                name={field?.id}
                label={label}
                value={formValues[field?.id] || ""}
                onChange={handleChange}
                disabled={field?.readonly}
                fullWidth
              />
            </FormControl>
          </Grid>
        );
      case "multiSelect":
        return (
          <Grid key={field?.id} item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>{label}</InputLabel>
              <Select
                multiple
                name={field?.id}
                value={formValues[field?.id] || []}
                onChange={handleChange}
                disabled={field?.readonly}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={{ PaperProps: { style: { maxHeight: 224 } } }}
              >
                {field?.options?.map((option: any) => (
                  <MenuItem
                    key={option?.value || option?.id}
                    value={option?.value || option?.id}
                  >
                    {locales?.[option?.id] || option?.value || option?.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      case "singleSelect":
        return (
          <Grid key={field?.id} item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>{label}</InputLabel>
              <Select
                name={field?.id}
                value={formValues[field?.id] || ""}
                onChange={handleChange}
                disabled={field?.readonly}
                MenuProps={{ PaperProps: { style: { maxHeight: 224 } } }}
              >
                {field?.options?.map((option: any) => (
                  <MenuItem
                    key={option?.value || option?.id}
                    value={option?.value || option?.id}
                  >
                    {locales?.[option?.id] || option?.value || option?.id}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        );
      case "expression":
        return (
          <Grid key={field?.id} item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                type="text"
                name={field?.id}
                label={label}
                value={formValues[field?.id] || ""}
                onChange={handleChange}
                disabled={field?.readonly}
                fullWidth
              />
            </FormControl>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
<Grid my={2} mx={"auto"}>
  {!!screenField?.formId ? (
    <Grid>
      <Grid
        borderRadius={20}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Grid display={"flex"} item alignItems={"center"} margin={1}>
          <IconButton onClick={() => setOpenModal(false)}>
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            {screenTitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
          {modalData?.id && (
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleDelete}
              >
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} margin={5}>
        <Grid container spacing={3}>
          {screenField?.fields?.map((field: Parameter) =>
            renderField(field)
          )}
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height={100}
      width={"100%"}
    >
      <CircularProgress color="success" />
    </Grid>
  )}
</Grid>

  );
};

export default DynamicScreen;
