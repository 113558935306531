import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {
  ColDef,
  ColGroupDef,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  SortChangedEvent,
} from 'ag-grid-community';
import Select, { MultiValue } from 'react-select'
import { Box, Button, CircularProgress, Grid, IconButton, Modal, Switch, Typography } from '@mui/material';
import { KeyboardArrowLeftTwoTone, KeyboardArrowRightTwoTone, KeyboardDoubleArrowRightTwoTone, KeyboardDoubleArrowLeftTwoTone, Download } from '@mui/icons-material'
import { KeyValues } from 'src/constants/interfaces';
import { columnDef } from 'src/constants/columnDefs'
import { downloadPlotDetails, getPlotsByPlotIds, getPlotsWithPagination } from 'src/services/plot.service';
import { useNavigate, useLocation } from 'react-router';
import { ColumnMapping } from 'src/constants/WordMapping';
import { isArrayWithLength, isLoggedIn } from 'src/utils/helper';
import { handleFilters } from '../filters'
import { columnFilteredPlotsAtom, filteredPlotsAtom, plotsAtom } from 'src/recoil/atom/plotsAtom';
import { useRecoilState, useRecoilValue } from 'recoil';
import _ from 'lodash';
import { columnFiltersAtom } from 'src/recoil/atom';
import { getExcelData } from '../utils';
import DownloadModal from './DownloadModal';

interface Props {
  mapLoaded: boolean,
  plotIds: string[] | undefined;
  setMapLocations: any;
  setMapLoaded: any;
  toggleMap: any;
}

const apiPageSize = 30;
const gridPageSize = 15;
function setText(selector: string, text: any) {
  (document.querySelector(selector) as any).innerHTML = text;
}

function setLastButtonDisabled(selector: string, disabled: boolean) {
  (document.querySelector(selector) as any).disabled = disabled;
}

const PlotTable: React.FC<Props> = (props: Props): JSX.Element => {

  let { setMapLocations, setMapLoaded, mapLoaded, toggleMap, plotIds } = props

  const gridRef = useRef<AgGridReact>(null);
  const containerStyle = useMemo(() => ({ width: '100%', height: '792px' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [rowData, setRowData] = useState<KeyValues[]>([]);
  const [columnDefs] = useState<(ColDef | ColGroupDef)[]>(columnDef);

  const navigate = useNavigate()
  let { state } = useLocation();
  // column options (for visibility)
  const [columnOptions, setColumnOptions] = useState<any[]>();
  const [selectedColumns, setSelectedColumns] = useState<MultiValue<any>>();

  // states for pagination
  const [disablePaginationPrevButton, setDisablePaginationPrevButton] = useState<boolean>(false);
  const [disablePaginationNextButton, setDisablePaginationNextButton] = useState<boolean>(false);

  // loading
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // filters (column filters)
  const [columnFilters, setColumnFilters] = useRecoilState<any>(columnFiltersAtom)

  // recoil
  const [columnFilteredPlots, updateFilteredPlots] = useRecoilState(columnFilteredPlotsAtom)
  const recoilPlots = useRecoilValue(plotsAtom) // for table filters
  const filteredPlots = useRecoilValue(filteredPlotsAtom) // for dashboard filters


  // download grid data
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false)

  let selectedPlotsRef = useRef<any>()
  selectedPlotsRef.current = recoilPlots

  let filteredPlotsRef = useRef<any>()
  filteredPlotsRef.current = filteredPlots

  let columnFilteredPlotsRef = useRef<any>()
  columnFilteredPlotsRef.current = columnFilteredPlots

  let commonFilteredSelectedRef = useRef<any>()

  let plotIdsRef = useRef<any>();
  plotIdsRef.current = plotIds;

  useEffect(() => {
    const filteredPlots = filteredPlotsRef.current || {}
    const selectedPlots = selectedPlotsRef.current.ids || []
    if (filteredPlots.length > 0 && selectedPlots.length > 0) {
      commonFilteredSelectedRef.current = _.intersection(filteredPlots, selectedPlots)
    } else if (filteredPlots.length > 0) {
      commonFilteredSelectedRef.current = filteredPlots
    } else if (selectedPlots.length > 0) {
      commonFilteredSelectedRef.current = selectedPlots
    } else {
      commonFilteredSelectedRef.current = []
    }
    gridRef?.current!.api?.paginationGoToPage(0);
  }, [filteredPlots, recoilPlots])

  useEffect(() => {
    if (!_.isEmpty(columnFilteredPlotsRef.current.columnFilters) || (!_.isEmpty(columnFilters.cropSown))) {
      startLoading();
      // cropId will be updated from CropFilter.tsx inside components/ag-grid
      if (!!columnFilters.cropSown) {
        columnFilteredPlotsRef.current = {
          ...columnFilteredPlotsRef.current,
          columnFilters: {
            ...columnFilteredPlotsRef.current.columnFilters,
            cropSown: columnFilters.cropSown,
          }
        }
      }

      handleFiltersWrapper(0, false) 
      .then((res: any[]) => {
          if (res.length === 0) {
            setRowData([])
          }

          updateFilteredPlots({
            ...columnFilteredPlotsRef.current,
            ids: res
          })
      })
      .catch(err => console.error(err))
      .finally(() => stopLoading());
    } else {
      handleFiltersWrapper(0, false)
      .then((res: any[]) => {

        if(res.length === 0){
          setRowData([])
        }

        updateFilteredPlots({
          ...columnFilteredPlotsRef.current,
          ids: res
        })
      })
      .catch(err => console.error(err))
      .finally(() => stopLoading());
    }
  }, [columnFilters])

  // checking for duplicate data, till now no duplicate data
  useEffect(() => {
    const valueArr = rowData.map(function (item) { return item.plotId });
    const isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) != idx
    });
    if (isDuplicate) {
      console.log("%c Duplicate found", 'background: #222; color: orange');
    }
  }, [rowData])

  useEffect(() => {
    const hasAddedNewData: boolean = (state as any)?.addedNewNote;
    if (hasAddedNewData) {
      // refetch() // use query mein tha ye
    }
  }, [state])

  useEffect(() => {
    startLoading();
    initialApiCall();
  }, [plotIds])

  const initialApiCall = () => {
    const dashboardFilterApplied = filteredPlotsRef.current?.text?.length > 0;
    const hasPlotIds = (!!plotIdsRef.current && isArrayWithLength(plotIdsRef.current))
    
    if (hasPlotIds || dashboardFilterApplied) {
      getPlotsByPlotIdsWrapper(0, apiPageSize);
    } else {
      fetchMoreData(0);
    }
  }

  const getPlotsByPlotIdsWrapper = (startIndex: number, endIndex: number) => {
    getPlotsByPlotIds((plotIdsRef.current || []).slice(startIndex, endIndex), ['farm', 'farmUser', 'cropSown', 'device'])
      .then(res => {
        if (startIndex === 0) {
          setRowData(res);
        }
        else setRowData(prev => [...prev, ...res])
      })
      .finally(() => {
        stopLoading();
      })
  }

  const handleFiltersWrapper = (page: number, prePlotIds = true) => {
    return handleFilters(columnFilteredPlots.columnFilters, Math.max(page - 1, 0) * gridPageSize, apiPageSize, prePlotIds ? plotIdsRef.current || [] : [])
  }

  const fetchMoreData = (page: number) => {
    if (!isLoggedIn() || isLoading) {
      return;
    }
    getPlotsWithPagination('', Math.max(page - 1, 0) * gridPageSize, apiPageSize, ['cropSown', 'device', 'farm', 'farmUser', 'plotQualityPredictions', 'plotQuantityPredictions', 'plotStatus', "plotLocation"])
      .then((res: any[]) => {
        const hasDataLessThanPageSize = res.length < apiPageSize
        setDisablePaginationNextButton(hasDataLessThanPageSize)
        setRowData((prev: any) => page === 0 ? [...res] : [...prev, ...res]);
      })
      .finally(() => {
        stopLoading();
      })
  }

  const startLoading = () => {
    gridRef?.current?.api?.showLoadingOverlay();
    setIsLoading(true);
  }

  const stopLoading = () => {
    gridRef?.current?.api?.hideOverlay();
    setIsLoading(false);
  }

  const mapLocationsHandler = () => {
    // set map
    if (gridRef.current!.api!) {
      setMapLocations([]);
      setMapLoaded(!mapLoaded)
      const renderedNodes = gridRef.current!.api;
      const locationsOfRenderedNodes: KeyValues[] = []
      renderedNodes.forEachNodeAfterFilter((node, ind) => {
        const nodeData = node.data;
        const hasRequiredFields: boolean = nodeData?.location && nodeData?.farm?.place && nodeData?.plotId
        if (hasRequiredFields) {
          locationsOfRenderedNodes.push({
            location: nodeData.location,
            place: nodeData.farm.place,
            plotId: nodeData.plotId
          })
        }
      })

      setMapLocations(locationsOfRenderedNodes)
      setMapLoaded(!mapLoaded)
    }
  }

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 200,
      resizable: true,
      floatingFilter: true,
      menuTabs: ['filterMenuTab'],
    };
  }, []);

  // const columnTypes = useMemo<{ [key: string]: ColDef; }>(() => {
  //   return { number: { filter: 'agNumberColumnFilter' }, };
  // }, []);

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return {
      toolPanels: ['filters'],
    };
  }, []);

  /*
    function:
      colDef -> [{value: colDef.field, label: colDef.field}]
  */
  const setDropDownOptions = () => {
    let temp = [];
    let defaultTemp = [];
    for (let col of columnDefs) {
      const fieldName = (col as KeyValues).field as string;
      const colOption = {
        value: fieldName,
        label: ColumnMapping[fieldName] ?? fieldName
      }
      temp.push(colOption)

      if (!(col as KeyValues).hide) {
        defaultTemp.push(colOption)
      }
    }
    setColumnOptions(temp);
    setSelectedColumns(defaultTemp)
  }

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setDropDownOptions()
  }, []);

  // function to perform server side sorting
  const handleColumnSort = (event: SortChangedEvent) => {
    //  gridRef.current!.api.showLoadingOverlay();
    // const columns = event.columnApi.getColumnState();

    // let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId

  }

  // function to perform server side filtering
  const handleColumnFilter = async (event: any) => {
    if (event.afterFloatingFilter) {
      const currentFilterModel = gridRef.current!.api.getFilterModel();
      const columnIds = Object.keys(currentFilterModel)

      const filters: any = {}
      if (isArrayWithLength(columnIds)) {
        for (const filter of columnIds) {
          filters[filter] = currentFilterModel[filter].filter
        }

        // handling cropSown
        if (!!columnFilters.cropSown) {
          filters.cropSown = columnFilters.cropSown
        }

        setColumnFilters(filters)

        updateFilteredPlots({
          ids: [],
          columnFilters: filters
        })

      } else {
        const filters: any = {};

        // handling cropSown
        if (!!columnFilters.cropSown) {
          filters.cropSown = columnFilters.cropSown
        }

        setColumnFilters(filters)
        columnFilteredPlotsRef.current = {
          ids: [],
          columnFilters: filters
        }
        updateFilteredPlots({
          ids: [],
          columnFilters: filters
        })

        // extra api call can be optimied
        initialApiCall(); 
      }
    }
  }
  // value={() => columnOptions?.filter(obj => {console.log('obj', obj)})}
  const handleVisibility = useCallback((value: MultiValue<any>) => {
    const columnDefs: ColDef[] = columnDef;
    columnDefs.forEach(function (colDef) {
      // hide the column if not selected (name and updated_date are exception)
      if (colDef.field !== 'name') {
        const arr = value.filter(obj => obj.value === colDef.field)
        colDef.hide = arr.length === 0;
      }
    });

    gridRef.current!.api.setColumnDefs(columnDefs);
  }, []);

  // default columns: plot id, plot name, district, area, farm name, farmer name, 
  const handleColumnVisibilityChange = (value: MultiValue<any>) => {
    setSelectedColumns(value);
    handleVisibility(value)
  }

  const onPaginationChanged = useCallback(async () => {
    if (!!gridRef?.current!?.api!) {
      const currentPage = gridRef.current!.api.paginationGetCurrentPage();
      const totalPages = gridRef.current!.api.paginationGetTotalPages();
      const isLastPage = currentPage + 1 === totalPages;
      // const localStorageColumnFilters = columnFilteredPlots.columnFilters

      setText('#lbCurrentPage', currentPage + 1);
      setText('#lbTotalPages', totalPages);
      setLastButtonDisabled('#btLast', !gridRef.current!.api.paginationIsLastPageFound());

      setDisablePaginationPrevButton(currentPage === 0);
      setDisablePaginationNextButton(isLastPage)

      if (!!totalPages && !!currentPage && isLastPage) {
        const hasPlotIds = (!!selectedPlotsRef?.current?.text?.length) || (!!columnFilteredPlotsRef?.current?.columnFilters && !_.isEmpty(columnFilteredPlotsRef?.current?.columnFilters));
        const hasFiltered = (filteredPlotsRef.current.text || '').length > 0;

        startLoading();

        if (hasPlotIds || hasFiltered) {
          getPlotsByPlotIdsWrapper(totalPages * gridPageSize, totalPages * gridPageSize + apiPageSize)
        } else {
          fetchMoreData(totalPages + 1)
        }
      }
    }
  }, []);

  const onRowDataUpdated = useCallback(() => {
    mapLocationsHandler();
  }, []);

  const label = { inputProps: { 'aria-label': 'Map Toggle Switch' } };

  const gridOptions: GridOptions = {
    sideBar: sideBar,
    animateRows: true,
    pagination: true,
    paginationPageSize: gridPageSize,
    columnDefs: columnDefs,
    defaultColDef: defaultColDef,
    rowSelection: 'single',
    suppressPaginationPanel: true,
    suppressScrollOnNewData: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => navigate(`plots/${e.data.plotId}`, { replace: true, state })
  };

  const onBtFirst = useCallback(() => {
    gridRef.current!.api.paginationGoToFirstPage();
  }, []);

  const onBtLast = useCallback(() => {
    gridRef.current!.api.paginationGoToLastPage();
  }, []);

  const onBtNext = useCallback(() => {
    gridRef.current!.api.paginationGoToNextPage();
  }, []);

  const onBtPrevious = useCallback(() => {
    gridRef.current!.api.paginationGoToPreviousPage();
  }, []);

  const PaginationIconProps = {
    fontSize: 'small',
    color: 'action'
  }

  const openDownloadGridModal = () => {
    setIsDownloadModalOpen(true)
    // downloadGridData();
  }

  const closeDownloadModal = () => {
    setIsDownloadModalOpen(false)
  }


  return (
    <Box px={2}>
      {/* column visibility and table */}
      <div style={containerStyle}>
        <div style={{ height: '100%', boxSizing: 'border-box' }}>
          {/* column visibility & map toggle */}
          <div style={{
            // width: '45%',
            margin: '10px 0 20px 0',
          }}>
            <h5 style={{ marginBottom: '5px' }}>Show Columns</h5>
            <Grid container alignItems={'center'} justifyContent={'space-between'} data-testid="multiple-filters-inOneTab-onHome" >
              <Grid width={'85%'}>
                <Select

                  options={columnOptions}
                  isMulti={true}
                  onChange={(selected) => { handleColumnVisibilityChange(selected) }}
                  isSearchable={true}
                  placeholder={'- search or select to show -'}
                  value={selectedColumns}
                />
              </Grid>

              <IconButton data-testid="download-excel-onHome" onClick={openDownloadGridModal} > <Download /> </IconButton>
              <Grid display={'flex'} alignItems={'center'} >
                <Typography variant='subtitle2' >Map</Typography>
                <Switch data-testid="map-filter-onHome" {...label} onChange={toggleMap} />
              </Grid>
            </Grid>
          </div> {/* column visibility ends */}

          <div data-testid="filters-ingrid-onHome" style={{ ...gridStyle, position: 'relative' }} className="ag-theme-alpine">
            <AgGridReact

              ref={gridRef}
              rowData={rowData}
              gridOptions={gridOptions}
              onGridReady={onGridReady}
              onRowDataUpdated={onRowDataUpdated}
              onPaginationChanged={onPaginationChanged}
              onSortChanged={handleColumnSort}
              onFilterChanged={(e) => handleColumnFilter(e)}
            />
            <Grid
              container py={2}
              justifyContent={'flex-end'} alignItems={'center'}
              border={1} borderColor={'silver'}
              position='absolute'
              bottom={0} left={0} right={0}
            >
              <Button data-testid="previous-page-oneMove-onhome" onClick={onBtFirst} disabled={disablePaginationPrevButton}  > <KeyboardDoubleArrowLeftTwoTone {...PaginationIconProps as any} /></Button>
              <Button data-testid="previous-page-multipleMoves-onhome" onClick={onBtPrevious} disabled={disablePaginationPrevButton} ><KeyboardArrowLeftTwoTone {...PaginationIconProps as any} /></Button>

              <Typography>
                Page  <span className="value" id="lbCurrentPage"> </span> of <span className="value" id="lbTotalPages"> </span>
              </Typography>

              <Button data-testid="next-page-oneMove-onhome" onClick={onBtNext} disabled={disablePaginationNextButton} id="btNext"><KeyboardArrowRightTwoTone {...PaginationIconProps as any} /></Button>
              <Button data-testid="next-page-multipleMoves-onhome" onClick={onBtLast} disabled={disablePaginationNextButton} id="btLast"> <KeyboardDoubleArrowRightTwoTone {...PaginationIconProps as any} /> </Button>
              {/* <div>
                <span className="label">Last Page Found:</span>
                <span className="value" id="lbLastPageFound">
                  -
                </span>
                <span className="label">Page Size:</span>
                <span className="value" id="lbPageSize">
                  -
                </span>
                <span className="label">Total Pages:</span>
              </div> */}
            </Grid>
          </div>
        </div>
      </div>


      {/* download grid data modal */}
      {
        !!columnOptions && <DownloadModal isOpen={isDownloadModalOpen} close={closeDownloadModal} plotIds={plotIdsRef.current} columnOptions={columnOptions} />
      }
    </Box>
  )
}

export default memo(PlotTable)