import { CORE_API } from "../constants/api";
import { KeyValues } from "../constants/interfaces";
import { getData } from "./common.service";

export const getMonthlyPlans = async (month: Number, year: Number) => {
    let whereFilter: { [key: string]: any } = {
        and: [
            { month: month },
            { year: year }
        ]
    };

    const filter = JSON.stringify({
        order: 'created_date DESC',
        where: whereFilter,
        include: ['salesActivitys']
    });

    const url = `/monthly-sales-plans?filter=${filter}`;
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API
    });
};



export const getSalesActivityTypes = async () => {
    const url = `/sales-activity-types`;
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API
    })
}


export const getSalesActivities = async (planId: string) => {
    const url = `/sales-activities?filter=` + JSON.stringify({
        where: {
            monthlySalesPlanId: planId
        },
        include: ['salesActivityType']
    });
    return getData(url, {
        sendTokenInHeader: true,
        basePath: CORE_API
    })
}

