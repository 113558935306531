import moment from "moment";
import * as XLSX from "xlsx";

type TExcelRow = {
  [key: string]: any;
};

type TExcelData = Array<TExcelRow>;
type FlattenedObject = Record<string, any>;

/**
 * @function to flatted object
 * */
function flattenObject(
  obj: Record<string, any>,
  parentKey = ""
): FlattenedObject {
  let result: FlattenedObject = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;

      if (
        typeof obj[key] === "object" &&
        !Array.isArray(obj[key]) &&
        obj[key] !== null && !['openOrders'].includes(key)
      ) {
        const nestedObj = flattenObject(obj[key], newKey);
        result = { ...result, ...nestedObj };
      } else {
        result[newKey] = obj[key];
      }
    }
  }

  return result;
}

export const getExcelData = (data: Array<any>, columns: string[], isPestDisease = false, isInventoryLocation = false) => {  
  const wb = XLSX.utils.book_new();

  const excelData: TExcelData = generateExcelData(data, columns);
  const ws = XLSX.utils.json_to_sheet(excelData);

  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  const fileName = isPestDisease ? `${data[0].field}_${data[0].date}-${data[data.length - 1].date}_Data.xlsx` : isInventoryLocation ? `Inventory-Locations-details-${moment().toString()}_Data.xlsx` : `Plots-details-${moment().toString()}_Data.xlsx`;

  XLSX.writeFile(wb, fileName);
};

export const generateExcelData = (data: Array<any>, columns: string[]) => {
  let excelData: TExcelData = [];

  data.forEach((dt) => {
    const flattenedObject = flattenObject(dt);
    const excelRow: any = {};

    for (const column of columns) {
      if (column === 'device' && flattenedObject.device && Array.isArray(flattenedObject.device)) {
        flattenedObject.device.forEach((device: any) => {
          if (device.stockQuantity > 0)
            excelRow[`stock_${device.deviceName}`] = device.stockQuantity;
        });
      } else if (column === 'openOrders' && flattenedObject.openOrders) {
        Object.entries(flattenedObject.openOrders).forEach(([order, quantity]: [any, any]) => {
          if (quantity > 0)
            excelRow[`order_${order}`] = quantity;
        });
      } 
      else if(column==='location' && flattenedObject['location.lat'] && flattenedObject['location.lng']){
        excelRow['Geo Location'] = `${flattenedObject['location.lat']}, ${flattenedObject['location.lng']}`
      } 
      else {
        excelRow[formatFieldName(column)] = flattenedObject[column];
      }
    }

    excelData.push(excelRow);
  });

  return excelData;
};


/**
 * @function to format excel column name
 * removed \. and capitalize the text
 * ex: farm.id -> Farm Id
 * 
 * replaces: farmUser --> Farmer
 *           cropSown --> Crop
 * */ 
const formatFieldName = (fieldName: string): string => {
  // Replace underscores with spaces
  fieldName = fieldName.replace(/_/g, " ");
  fieldName = fieldName.replace(/\./g, " ");

  // Replace specific words
  fieldName = fieldName.replace(/\bfarmUser\b/g, "Farmer");
  fieldName = fieldName.replace(/\bcropSown\b/g, "Crop");

  // Split the string by uppercase letters or spaces
  const words = fieldName.split(/(?=[A-Z])|\s+/);

  // Capitalize the first letter of each word
  const formattedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the formatted words with spaces
  return formattedWords.join(" ");
}
