import { GetAdherenceRequest } from "src/services/adherence.service";
import { compareDates } from "src/utils/helper";
import { Tab } from ".";
import { IrrigationAdherence, Adherence } from "./types";

export const validateFilterQuery = (filterQuery: GetAdherenceRequest) => {
	if (!filterQuery && !Object.keys(filterQuery).length)
		return false

	return compareDates(filterQuery.startTime, filterQuery.endTime) >= 0;
}

export const generateExcelData = (activeTab: Tab, data: Array<Adherence | IrrigationAdherence>) => {
	let excelData: any[] = [];

	if (activeTab === "irrigation") {
		excelData = generateIrrigationData(data as IrrigationAdherence[]);
	} else if (activeTab === "nutrient") {
		excelData = generateNutrientData(data as Adherence[]);
	} else {
		excelData = generateSprayData(data as Adherence[]);
	}

	return excelData;
}

const generateIrrigationData = (data: IrrigationAdherence[]) => {
	const columns = [
		"Suggested Date",
		"Plot Id",
		"Result"
	];

	const rowData = data.map((dt) => ([dt.suggestedDate, dt.plotId, dt.result]));

	const finalData = [columns, ...rowData];
	return finalData;
}

const generateNutrientData = (data: Adherence[]) => {
	const columns = [
		"Done Date",
		"Suggested Date",
		"Plot Id",
		"CropId",
		"Base Stage",
		"Days after base stage",
		"Notes",
		"Processed Notes",
		"Suggested and done",
		"Not Done",
		"Not Suggested and done",
		"Metadata",
		"Fertilizer Options"
	]

	const rowData = data.map(dt => ([dt.doneDate, dt.suggestedDate, dt.plotId, dt.cropId, dt.baseStage, dt.daysAfterBaseStage, dt.notes, dt.doneProcessedNotes, dt.suggestedAndDone, dt.notSuggestedAndDone, dt.notDone, formatMetaData(dt.metadata, "nutrient"), formatSprayFertilizerOptions(dt.fertilizerOptions)]));

	const finalData = [columns, ...rowData];
	return finalData;
}

const generateSprayData = (data: Adherence[]) => {
	const columns = [
		"Done Date",
		"Suggested Date",
		"Plot Id",
		"CropId",
		"Base Stage",
		"Days after base stage",
		"Notes",
		"Processed Notes",
		"Suggested and done",
		"Not Done",
		"Not Suggested and done",
		"Metadata",
		"Spray Options"
	]

	const rowData = data.map(dt => ([dt.doneDate, dt.suggestedDate, dt.plotId, dt.cropId, dt.baseStage, dt.daysAfterBaseStage, dt.notes, dt.doneProcessedNotes, dt.suggestedAndDone, dt.notSuggestedAndDone, dt.notDone, formatMetaData(dt.metadata), formatSprayFertilizerOptions(dt.sprayOptions)]));

	const finalData = [columns, ...rowData];
	return finalData;
}

const formatSprayFertilizerOptions = (options: { id: string, amount: number, unit: string }[][] = []) => {
	return (options).map(option => {
		const nestedArray = typeof option === "object" ? (Array.isArray(option) ? option : [option]) : []
		return (nestedArray).map((element, index) => {
			return `${element.id}(${element.amount} ${element.unit})`;
		}).join(' + ');
	}).join(' OR ');
};


const formatMetaData = (data: any, type = 'spray') => {
	if (type === "nutrient") {
		const nutrientsArrayText = data?.nutrients?.map((nutrient: any) => `${nutrient.name ?? 'N.A'}(${nutrient.amount ?? 'N.A'} ${nutrient.unit ?? 'N.A'})`).join(', ');
		if (nutrientsArrayText)
			return (`To provide the required amount of: ${nutrientsArrayText}`)
	} else {
		const sprayText = data?.purpose?.map((p: any) => `${(p?.displayName || p?.id) ?? 'N.A'}`).join(', ');
		const waterInLitres = data?.waterInLitres ?? '';
		if (sprayText && waterInLitres)
			return (`Spray in: ${waterInLitres} litres/acre of water for ${sprayText}`);
	}
	return '';
}