import { FC } from "react";
import { ClickPosition } from "./BarChart";
import { Button, Grid, styled } from "@mui/material";
import { ChartClickProps } from "../../types";

interface Props {
  clickedPosition: ClickPosition;
  viewSingleActivity: (...args: any) => void;
  viewMonthsActivities: (...args: any) => void;
  closeModal: () => void;
  clickedData: ChartClickProps;
}

const buttonsSx = {
  color: 'white',
  textTransform: 'capitalize',
  fontSize: '12px',
  '&:hover': {
    backgroundColor: 'silver',
    color: 'black'
  },
}

const Pointer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '40%',
  left: '-7px',
  transform: 'translateY(-50%)',
  width: '0',
  height: '0',
  borderTop: '7px solid transparent',
  borderBottom: '7px solid transparent',
  borderRight: `7px solid ${theme.palette.common.black}`,
}));

const BarChartOptions: FC<Props> = (props: Props) => {
  const { clickedPosition, viewSingleActivity, viewMonthsActivities, closeModal, clickedData } = props;
  return (
    <Grid
      position={'absolute'}
      top={clickedPosition.y - 45}
      left={clickedPosition.x + 10}
      display={'flex'}
      flexDirection={'column'}
      bgcolor={'rgba(0, 0, 0, 0.75)'}
      borderRadius={2}
      color={'white'}
      p={1}
    >
      <Pointer />
      <Button onClick={viewSingleActivity} sx={buttonsSx}> View {clickedData.dataOf} </Button>
      <Button onClick={viewMonthsActivities} sx={buttonsSx} style={{ margin: '5px 0' }} > View {clickedData.month} </Button>
      <Button onClick={closeModal} sx={buttonsSx} style={{
        backgroundColor: 'rgba(255, 78, 40, 1)'
      }} > Close </Button>
    </Grid>
  )
}

export default BarChartOptions;
