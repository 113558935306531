import { Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { KeyValues, FilterDataValue, FilterProps } from '../../constants/interfaces'
import { getOutlierPlots } from "../../services/plot.service";
import { Alert } from ".."
import FormikApp from '../DynamicForm'

const OutlierPlotsFilter: React.FC<FilterProps> = (props: FilterProps): JSX.Element => {
  const { handleFilterDataChange, handleRemove, filterId } = props

  const fields = [
    {
      id: "hrs",
      label: "Hours",
      placeholder: "Hours",
      type: "text",
      validationType: "string",
      value: '',
      validations: [],
    }
  ]
  
  const [isApplied, setIsApplied] = useState(false)
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)

  const handleFitlerChange = (status: boolean, plotIds: FilterDataValue) => {
    handleFilterDataChange(filterId, status, plotIds)
    setIsApplied(status)
  }

  const onSubmit = (values: KeyValues) => {
    const { hrs } = values
    getOutlierPlots(hrs as string).then((response) => {
      const plotIds = response.map((plot: any) => plot.plotId)
      handleFitlerChange(true, plotIds)
    })
  }

  const onReset = () => {
    handleFitlerChange(false, undefined)
  }

  return (
    <>
      <Typography variant="subtitle2" mt={1}>Outlier Plots</Typography>
      <div style={{
        backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
        margin: '0px 0 15px 0',
        padding: '5px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center'
      }} >
        <FormikApp
          formFields={fields}
          onSubmit={(values: KeyValues) => onSubmit(values)}
          onReset={() => {}}
          removeField={() => handleRemove(filterId)}
          filter={true}
          showReset
        />
      </div>
      <Alert isOpen={isAlertOpen} close={() => setIsAlertOpen(false)} messageFor={'Hours'} type="error" message="Hours should be in range 0-72" />
    </>
  )
}

export default memo(OutlierPlotsFilter);