import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getConversations } from '../services/farm.service';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Loader, ConversationCard } from '../components';

const ConversationPage = (): JSX.Element => {
    const [showAddNewConv, setShowAddNewConv] = useState<Boolean>(false);
    const [updateState, setUpdateState] = useState<Boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [allConversations, setAllConversations] = useState<any>([]);

    let res: any = searchParams.get('data');
    const filtersData = res.split(',');

    let payload = {
        "farmUserId": filtersData[3], //farmUserId
        "supportUserId": filtersData[1], //userId
        "plotId": filtersData[2], //cellPlotId
        "farmId": filtersData[0],  //farmId
    }

    const getAllConversations = async () => {
        const data = await getConversations(filtersData);
        setAllConversations(data.reverse());
    }
    useEffect(() => {
        getAllConversations();
    }, [showAddNewConv])

    return (
        <div style={{ height: '95vh' }}>
            {
                allConversations.map((conv: any, index: any) => (
                    <ConversationCard key={`conversation${index}${conv.id}`} conversations={conv} index={index + 1} />
                ))
            }
            {!allConversations.length && <h1 style={{ position: 'relative', left: '40%', width: '300px' }}>No Conversations</h1>}
            {!showAddNewConv &&
                <button data-testid="add-new" onClick={() => setShowAddNewConv(true)} style={{ backgroundColor: 'green', height: '50px', width: '30%', position: 'sticky', maxWidth: '200px', bottom: '35px', top: '85%', left: '85%', right: '15px', marginRight: '5%', borderRadius: '20px', padding: '0px 2px', border: '1px soild #2e7d32' }} >
                    <Typography variant="overline" textTransform={'capitalize'} style={{ color: '#fff', fontWeight: '600' }}>Add New</Typography>
                </button>}
            {showAddNewConv && <ConversationCard getAllConversations={getAllConversations} payload={payload} plotID={filtersData[2]} conversations={allConversations} setConversations={setAllConversations} showAddNewConv={showAddNewConv} setShowAddNewConv={setShowAddNewConv} />}
        </div>
    )
}

export default ConversationPage