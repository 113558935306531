import { ColDef } from "ag-grid-community";

export const usersTableCols: (ColDef)[] = [
  {
    headerName: 'S No',
    field: 'index',
    resizable: true,
  },
  {
    headerName: 'User Id',
    field: 'userId',
    minWidth: 360,
    resizable: true,
  },
  {
    headerName: 'Activities',
    field: 'eventCount',
    resizable: true,
  },
]

export const userInfoActivityTableCols: ColDef[] = [
  {
    headerName: 'User Id',
    field: 'userId',
    minWidth: 360,
    resizable: true,
  },
  {
    headerName: 'Crop',
    field: 'cropName',
    resizable: true,
  },
  {
    headerName: 'Variety',
    field: 'varietyId',
    resizable: true,
  },
  {
    headerName: 'Farmer',
    field: 'farmerName',
    resizable: true,
  },
  {
    headerName: 'Language',
    field: 'language',
    resizable: true,
  },
  {
    headerName: 'Location',
    field: 'district',
    resizable: true,
  },
  {
    headerName: 'Max Event',
    field: 'maxEvent',
    resizable: true,
  },
  {
    headerName: 'Max Count',
    field: 'maxCount',
    resizable: true,
  },
  {
    headerName: 'Crop',
    field: 'cropName',
    resizable: true,
  },
]