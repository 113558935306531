import { DatePicker } from "src/components";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Collapsibles, getFieldColor } from "../sections/FilterSection";
import { fieldLegends } from "../constant";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { getDailyData } from "src/services/crop.service";
import dayjs from "dayjs";
import { GraphType } from "..";

type Props = {
  buttonDisabled: any;
  setButtonDisabled: any;
  checkboxOptions: any;
  isVisible: boolean;
  closeRestCollapsiblesExcept: (x: Collapsibles) => void;
  handleFilterUpdate: (x: any) => void;
  setMapsDate: (x: any) => void;
  setIndexData: (x: any) => void;
  setCropData: (x: any) => void;
  setGraphType: (x: GraphType) => void;
  setGraphSubType: (x: string) => void;
  setIsFilterLoading: (x: boolean) => void;
};

const SensorData: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    checkboxOptions,
    isVisible,
    closeRestCollapsiblesExcept,
    handleFilterUpdate,
    setIndexData,
    setMapsDate,
    setCropData,
    setGraphType,
    setGraphSubType,
    setIsFilterLoading
  } = props;
  const [selectedSensors, setSelectedSensors] = useState<string>("");

  // default selected airTemp
  useEffect(() => {
    setSelectedSensors('airTemp')
    setIsLoading(true)
  }, [])

  const todaysDate = new Date();
  const [sensorDate, setSensorDataDate] = useState<Date | null>(
    dayjs(todaysDate).add(-1, "day").toDate()
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const date = useRef<any>(null);

  const dateHandler = (newDate: Date | null): void => {
    setSensorDataDate(newDate);
  };

  const handleSensorsChange = (updatedSensors: string) => {
    setSelectedSensors(updatedSensors);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    label: string,
    index: number,
    i: number
  ) => {
    checkboxOptions.map((checkboxLabel: any, index: number) => {
      checkboxLabel.field.map((value: any) => {
        value.isChecked = false;
      });
      checkboxLabel.isChecked = false;
    });
    if (event.target.checked) {
      handleSensorsChange(label);
      setGraphSubType(checkboxOptions[index].value)
      setGraphType('environment')
      if (i === -1) {
        checkboxOptions[index].isChecked = true;
      } else {
        checkboxOptions[index].field[i].isChecked = true;
      }
    } else {
      handleSensorsChange("");
    }
  };

  useEffect(() => {
    showResults();
  }, [isLoading]);

  const showResults = () => {
    if (selectedSensors.length > 0) {
      setIsFilterLoading(true)
      setGraphType('environment')
      setMapsDate(dayjs(sensorDate).format("DD/MM/YYYY").toString());
      handleFilterUpdate(selectedSensors);
      setIndexData([]);
      getDailyData(selectedSensors, dayjs(sensorDate).format('MM-DD-YYYY'))
        .then((res) => {
          let filterData = res
            ?.filter((val: any) => val[selectedSensors] && val["location"])
            .map((val: any) => ({
              ...val,
              color: getFieldColor(val[selectedSensors], selectedSensors),
              value: val[selectedSensors],
            }));
          if (res.length > 0) setCropData(filterData);
          else setCropData([]);
          if (fieldLegends[selectedSensors]) {
            setIndexData(fieldLegends[selectedSensors]);
          }
          setIsFilterLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!dayjs(date.current).isSame(dayjs(sensorDate))) {
      date.current = sensorDate;
      showResults();
    }
  }, [sensorDate]);

  return (
    <Grid my={3} boxShadow={2} borderRadius={3} px={2}>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        data-testid="dashboard-Environment"
      >
        <Typography
          letterSpacing={0.8}
          variant="overline"
          textTransform={"capitalize"}
          fontSize={20}

        >
          Environment
        </Typography>
        <IconButton
          onClick={() => closeRestCollapsiblesExcept(Collapsibles.sensor)}
        >
          {isVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Grid>
      {isVisible && (
        <Grid pb={2}>
          <FormGroup>
            {checkboxOptions.map((checkboxLabel: any, index: number) => (
              <Grid
                flexDirection={"row"}
                display={"flex"}
                justifyContent={"space-between"}
                key={`environment-checkbox-${index}`}
              >
                <FormControlLabel
                  style={{ width: 150 }}
                  key={`sensor-${checkboxLabel.value}-${index}`}
                  control={
                    <Checkbox
                      checked={checkboxLabel.isChecked}
                      onChange={(event) =>
                        handleChange(event, checkboxLabel.value, index, -1)
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                    />
                  }
                  label={
                    <Typography fontSize={13}>{checkboxLabel.label}</Typography>
                  }
                />
                {checkboxLabel.field.map((checkboxValue: any, i: number) => (
                  <FormControlLabel
                    key={`sensor-${checkboxValue.value}-${index}-${i}`}
                    control={
                      <Checkbox
                        checked={checkboxValue.isChecked}
                        onChange={(event) =>
                          handleChange(event, checkboxValue.value, index, i)
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                      />
                    }
                    label={
                      <Typography fontSize={13}>
                        {checkboxValue.label}
                      </Typography>
                    }
                  />
                ))}
              </Grid>
            ))}
          </FormGroup>

          <Grid>
            <DatePicker
              onChange={dateHandler}
              value={sensorDate}
              hasAutoplay
              hasDateChangeIcon
              maxDate={dayjs(new Date()).add(-1, 'day').toDate()}
            />
          </Grid>

          <Grid mt={2}>
            <Button
              variant="outlined"
              fullWidth
              style={{ borderRadius: "20px" }}
              size="large"
              color="success"
              onClick={showResults}
            >
              {" "}
              Show Results{" "}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default SensorData;
