export interface KeyValues {
  [key: string]: string | KeyValues;
}

// helpers
interface Filter {
  like: string;
  options: string;
}

export interface CurrentFilter {
  [key: string]: Filter | string;
}

// app.tsx
export interface AppliedFilter {
  [key: string]: boolean;
}

export interface Option {
  label: string | number;
  value: string | number;
}

// filters
export interface FilterForm {
  [key: string]: TempCrop;
}

interface TempCrop {
  fields: Array<CropFields>;
}

interface OnSubmit {
  (
    values: KeyValues,
    rowData: KeyValues[],
    setRowData: any,
    appliedFilter: AppliedFilter,
    setAppliedFilter: any,
    filter: string,
    filterData: FilterData,
    setFilterData: any
  ): void;
}

interface OnReset {
  (
    appliedFilter: AppliedFilter,
    setAppliedFilter: any,
    filter: string,
    filterData: FilterData,
    setFilterData: any,
    setRowData: any
  ): void;
}

export interface CropFields {
  id: string;
  label: string;
  placeholder: string;
  type: string;
  validationType: string;
  value: string;
  validations: Array<Validation>;
  options?: string[] | Option[];
}

interface Validation {
  type: string;
  params: string[];
}

export interface FilterData {
  [key: string]: FilterDataValue;
}

export type FilterDataValue = string[] | undefined;

// props
export interface PlotFiltersProps {
  handlePlotIdChange: any;
  toggleGridView?: ()=>void;
  isGridView?: boolean;
}

export interface FilterProps {
  handleFilterDataChange: any;
  handleRemove?: any;
  filterId?: any;
  handleFilterSearchDataChange?: any;
  value?: any;
  getLocation?: any;
  allLocation?: string;
  getLocationOnly?: boolean;
  setRegionsToMap?: any;
  setDynamicType?:Boolean
  checked?:Boolean,
  onSubmit?: any
}

export enum Roles {
  ADMIN = "admin",
  AGRONOMIST = "agronomist",
  EXPORTER = "exporter",
  FARM_MANAGER = "farmManager",
  FARM_USER = "farmuser",
  OPS = "ops",
  SUPPORT = "support",
  PLANTPROTECTION = "plantProtection",
}

export const ChartColors = [
  'rgba(255, 99, 132, 0.5)',     // Red
  'rgba(54, 162, 235, 0.5)',     // Blue
  'rgba(255, 206, 86, 0.5)',     // Yellow
  'rgba(75, 192, 192, 0.5)',     // Cyan
  'rgba(153, 102, 255, 0.5)',    // Lavender
  'rgba(255, 159, 64, 0.5)',     // Orange
  'rgba(255, 140, 0, 0.5)',      // Dark Orange
  'rgba(32, 178, 170, 0.5)',     // Light Sea Green
  'rgba(147, 112, 219, 0.5)',    // Medium Purple
  'rgba(72, 209, 204, 0.5)',     // Medium Turquoise
  'rgba(255, 69, 0, 0.5)',       // Orange Red
  'rgba(0, 206, 209, 0.5)',      // Dark Turquoise
  'rgba(255, 20, 147, 0.5)',     // Deep Pink
  'rgba(50, 205, 50, 0.5)',      // Lime Green
  'rgba(255, 215, 0, 0.5)',      // Gold
  'rgba(64, 224, 208, 0.5)',     // Turquoise
  'rgba(255, 105, 180, 0.5)',    // Hot Pink
  'rgba(0, 255, 127, 0.5)',      // Spring Green
  'rgba(173, 255, 47, 0.5)',     // Green Yellow
  'rgba(138, 43, 226, 0.5)'      // Blue Violet
];