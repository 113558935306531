import {
  getImageAnalysis,
  getImageAnalysisCount,
} from "src/services/imageAnalysis.service";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { NonValidatedColDef, TImageAnalysis } from "./types";
import { AgGridReact } from "ag-grid-react";
import { RowClickedEvent } from "ag-grid-community";
import { Button, Modal, Grid, Typography } from "@mui/material";
import ImageAnalysisRenderer from "./components/ImageAnalysisRenderer";
import { ToastSeverity } from "src/components/ToastAlert/types";
import { ToastAlert } from "src/components";
import {
  KeyboardArrowLeftTwoTone,
  KeyboardArrowRightTwoTone,
} from "@mui/icons-material";
import { s } from "msw/lib/glossary-58eca5a8";

const ImageAnalysis: React.FC = () => {
  const [imageAnalysis, setImageAnalysis] = useState<TImageAnalysis[]>([]);
  const [selectedAnalysis, setSelectedAnalysis] =
    useState<TImageAnalysis | null>();
  const [totalAnalysis, setTotalAnalysis] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);

  // toast
  const [toast, setToast] = useState({
    isOpen: false,
    message: "",
    type: "success" as ToastSeverity,
  });

  /**
   * callback methods
   */
  const handleResponse = (res: any) => {
    if (Array.isArray(res)) {
      setImageAnalysis(res);
    } else if (!!res.error) {
      setToast({
        type: "error",
        message: "Something went wrong",
        isOpen: true,
      });
      setImageAnalysis([]);
    }
  };

  const updateSelectedRowData = (data: any) => {
    const updatedAnalysis = {
      ...selectedAnalysis,
      ...data,
    } as TImageAnalysis;

    const index = imageAnalysis.findIndex(
      (analysis) => analysis.id === updatedAnalysis.id
    );

    if (index !== -1) {
      const updatedImageAnalysis = [...imageAnalysis];
      updatedImageAnalysis[index] = updatedAnalysis;
      setImageAnalysis(updatedImageAnalysis);
    }
  };

  /**
   * event handlers
   */
  const onRowClicked = (event: RowClickedEvent<any>) => {
    const { data } = event;
    openAnalysisModal(data);
  };

  const onAnalysisUpdate = (updatedData: any) => {
    setToast({
      type: "success",
      message: "Analysis updated successfully",
      isOpen: true,
    });

    updateSelectedRowData(updatedData);
    closeAnalysisModal();
  };

  const onAnalysisDelete = () => {
    setToast({
      type: "success",
      message: "Analysis deleted successfully",
      isOpen: true,
    });

    closeAnalysisModal();
    fetchNonValidatedAnalyses(0, handleResponse);
  };

  const handleAnalysisUpdateFail = () => {
    setToast({
      type: "error",
      message: "Something went wrong",
      isOpen: true,
    });
  };

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const onPaginationChanged = useCallback(() => {}, []);

  /**
   * state handlers
   */
  const handleToastVisibility = (isOpen: boolean) => {
    setToast((toast) => ({ ...toast, isOpen }));
  };

  const openAnalysisModal = (data: TImageAnalysis) => {
    setSelectedAnalysis(data);
  };

  const closeAnalysisModal = () => {
    setSelectedAnalysis(null);
  };

  const onBtNext = () => {
    setSkip(skip + 30);
  };

  const onBtPrevious = () => {
    setSkip(skip - 30);
  };

  useEffect(() => {
    setSkip(0);
    fetchNonValidatedAnalyses(0, handleResponse);

    getImageAnalysisCount().then((res: any) => {
      let cnt = Math.round(res.count / 30);

      if (res.count % 30 !== 0) cnt += 1;
      setTotalAnalysis(cnt);
    });
  }, []);

  const PaginationIconProps = {
    fontSize: "small",
    color: "action",
  };

  useEffect(() => {
    fetchNonValidatedAnalyses(skip, handleResponse);
  }, [skip]);

  return (
    <div>
      <div style={{ height: 650 }} className="ag-theme-alpine">
        <AgGridReact
          columnDefs={NonValidatedColDef}
          rowData={imageAnalysis}
          gridOptions={{
            rowHeight: 100,
          }}
          onRowClicked={onRowClicked}
          onPaginationChanged={onPaginationChanged}
        />
        <Grid
          container
          py={2}
          justifyContent={"flex-end"}
          alignItems={"center"}
          border={1}
          borderColor={"silver"}
          bottom={0}
          left={0}
          right={0}
        >
          <Button onClick={onBtPrevious} disabled={skip === 0}>
            <KeyboardArrowLeftTwoTone {...(PaginationIconProps as any)} />
          </Button>

          <Typography>
            Page {Math.round(skip / 30) + 1} of {totalAnalysis}
          </Typography>

          <Button
            onClick={onBtNext}
            disabled={skip === (totalAnalysis - 1) * 30}
            id="btNext"
          >
            <KeyboardArrowRightTwoTone {...(PaginationIconProps as any)} />
          </Button>
        </Grid>
      </div>

      <Modal open={!!selectedAnalysis} onClose={closeAnalysisModal}>
        <div style={{ position: "relative" }}>
          <Button
            variant="contained"
            color="error"
            style={{
              position: "absolute",
              top: "1%",
              right: "3%",
              textTransform: "capitalize",
            }}
            onClick={closeAnalysisModal}
          >
            {" "}
            Close{" "}
          </Button>

          {!!selectedAnalysis && (
            <ImageAnalysisRenderer
              analysis={selectedAnalysis}
              onUpdate={onAnalysisUpdate}
              onDelete={onAnalysisDelete}
              onError={handleAnalysisUpdateFail}
            />
          )}
        </div>
      </Modal>

      <ToastAlert
        message={toast.message}
        severity={toast.type}
        open={toast.isOpen}
        setOpen={handleToastVisibility}
        duration={3000}
      />
    </div>
  );
};

export default ImageAnalysis;

/**
 * Helper methods
 */

const fetchNonValidatedAnalyses = (skip: number, callback: Function) => {
  const filterQuery = {
    order: "isCorrect ASC",
  };
  getImageAnalysis(skip, filterQuery).then((res) => {
    callback(res);
  });
};
