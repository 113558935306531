import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  SortChangedEvent,
  FilterChangedEvent,
} from "ag-grid-community";
import { paymentDef } from "../constants/columnDefs";
import { getPayments, authorizePayment } from "src/services/payment.service";
import { isArrayWithLength } from "../utils/helper";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { Grid, IconButton } from "@mui/material";
import { ArrowBack, CalendarMonthOutlined, Clear } from "@mui/icons-material";
import { DatePicker, ToastAlert } from "src/components";
import { ToastSeverity } from "src/components/ToastAlert/types";
import dayjs from "dayjs";
import * as XLSX from "xlsx";

const StatusButtonRenderer = (props: any) => {
  const { value, data } = props;

  const [openToast, setOpenToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastSeverity, setToastSeverity] = useState<ToastSeverity>("success");

  const authorizesPayment = () => {
    if (value === "INITIATED") {
      if (window.confirm("Do you want to authorize this payment?")) {
        authorizePayment(data?.id).then((res) => {
          if (res === true) {
            setToastMessage("Payment authorized");
            setToastSeverity("success");
            setOpenToast(true);
          } else {
            setToastMessage("Some Error Ocuured");
            setToastSeverity("error");
            setOpenToast(true);
          }
        });
      }
    }
  };

  return (
    <span>
      <span>{value}</span>&nbsp;
      {value === "INITIATED" && (
        <button style={{ marginLeft: 6 }} onClick={authorizesPayment}>
          Authorize
        </button>
      )}
      <ToastAlert
        open={openToast}
        setOpen={setOpenToast}
        severity={toastSeverity}
        message={toastMessage}
      />
    </span>
  );
};

const Payments = (): JSX.Element => {
  const gridRef = useRef<AgGridReact>(null);
  const containerStyle = useMemo(
    () => ({ width: "100%", height: "600px" }),
    []
  );
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [tableData, setTableData] = useState<any[]>([]);
  const [modalFromDate, setModalFromDate] = useState<any>(
    dayjs().subtract(1, "week").toDate()
  );
  const [modalToDate, setModalToDate] = useState<any>(
    dayjs().subtract(1, "D").toDate()
  );
  const [payments, setPayments] = useState<any>([]);

  const navigate = useNavigate();

  const startLoading = () => {
    gridRef?.current?.api?.showLoadingOverlay();
  };
  const stopLoading = () => {
    gridRef?.current?.api?.hideOverlay();
  };

  const gridOptions: GridOptions = {
    columnDefs: paymentDef,
    rowData: tableData,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    components: {
      statusButtonRenderer: StatusButtonRenderer,
    },
  };

  const sumPaymentsInRange = (array: any) => {
    const selectedPayments: any[] = [];

    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      selectedPayments.push(item);
    }
    setPayments(selectedPayments);
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // gridRef?.current?.api.setColumnDefs(farmActivitiesColDef);
    // gridRef?.current?.api.setRowData(tableData);
  }, []);

  const defaultDatePickerPlaceholder = {
    maxDate: new Date(),
    calendarIcon: (
      <IconButton>
        <CalendarMonthOutlined />
      </IconButton>
    ),
    clearIcon: <Clear />,
  };

  // function to perform server side sorting
  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
  };

  // function to perform server side filtering
  const handleColumnFilter = (event: FilterChangedEvent) => {
    if (event.afterFloatingFilter) {
    }
  };

  const onPaginationChanged = useCallback(() => {}, []);

  useEffect(() => {
    callGetPaymentsApi();
  }, []);

  const callGetPaymentsApi = (): void => {
    getPayments(modalFromDate, dayjs(modalToDate).add(1, "day").toDate()).then(
      (res: any) => {
        setTableData(res);
      }
    );
  };

  const downloadExcel = () => {
    const processedData = payments?.map((row: any) => ({
      "Created Date": dayjs(row.createdAt).format("LLL"),
      "User Id": row.userId,
      "Plot Id": row.items.map((item: any) => item.plotId).join(", "),
      "Created/Modified By": row.modifiedBy || row.createdBy,
      "Amount": row.amount,
      "Status": row.status,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(processedData);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Payments");

    XLSX.writeFile(
      workbook,
      `payment_${dayjs(modalFromDate).format("DD MMM YYYY")}_to_${dayjs(
        modalToDate
      ).format("DD MMM YYYY")}.xlsx`
    );
  };

  return (
    <div style={containerStyle}>
      <Grid
        p={3}
        borderRadius={20}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <IconButton onClick={() => navigate("/")}>
              <ArrowBack />
            </IconButton>
            <Typography m={1} variant="h5">
              Payments
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <DatePicker
                {...defaultDatePickerPlaceholder}
                onChange={setModalFromDate}
                value={modalFromDate}
                label="From"
              />
            </Grid>
            <Grid item>
              <Typography variant="h6">-</Typography>
            </Grid>
            <Grid item>
              <DatePicker
                {...defaultDatePickerPlaceholder}
                onChange={setModalToDate}
                value={modalToDate}
                label="To"
              />
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={callGetPaymentsApi}>
                Load Data
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={downloadExcel}>
                Download
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div style={gridStyle} className="ag-theme-alpine">
        {isArrayWithLength(tableData) && (
          <Grid p={3}>
            <AgGridReact
              ref={gridRef}
              rowData={tableData}
              columnDefs={paymentDef}
              onGridReady={onGridReady}
              defaultColDef={defaultColDef}
              sideBar={sideBar}
              animateRows={true}
              pagination={true}
              paginationPageSize={15}
              gridOptions={gridOptions}
              domLayout="autoHeight"
              onSortChanged={handleColumnSort}
              onFilterChanged={handleColumnFilter}
              onPaginationChanged={(e: any) => {
                const rowsToDisplay = e?.api?.rowModel?.rowsToDisplay || [];
                sumPaymentsInRange(
                  rowsToDisplay.map((item: any) => item.data)
                );
              }}
            ></AgGridReact>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default Payments;
