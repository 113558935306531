import { Grid, IconButton, Typography, Modal, Tab, Tabs } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router';
import { getNutrient, getNutrientFertigationSchedule, getNutrientFoliarSchedule, getNutrientPetioleStage, getNutrientSoilSchedule } from '../services/plot.service';
import Select, { SingleValue } from "react-select";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { generateOptions, isArrayWithLength } from '../utils/helper';
import { getCropOptions } from "src/services/crop.service";
import { nutrientsColDef, petioleColDef } from '../constants/columnDefs';
import {
    ColDef,
    GridOptions,
    GridReadyEvent,
    SideBarDef,
    SortChangedEvent,
    FilterChangedEvent,
} from 'ag-grid-community';
import NutrientDetails from './NutrientDetails';
import FertigationSchedule from './FertigationSchedule';
import FoliarSchedule from './FoliarSchedule';
import SoilApplicationSchedule from './SoilApplicationSchedule';
import PetioleAnalysis from './PetioleAnalysis';


const Nutrient: React.FC = (): JSX.Element => {
    const gridRef = useRef<AgGridReact>(null);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [isApplied, setIsApplied] = useState(false)
    const [isError, setisError] = useState(false)
    const [errorValue, setErrorValue] = useState(-1)
    const [isNutrientData, setisNutrientData] = useState(false)
    const [nutrientData, setnutrientData] = useState<any[]>([])
    const [crops, setCrops] = useState<SingleValue<any>>([])
    const [isCropLoading, setIsCropLoading] = useState<boolean>(true)
    const [selectedCropOption, setSelectedCropOption] = useState<any>(null)
    const [openModal, setOpenModal] = useState(false)
    const [fertigationScheduleModal, setfertigationScheduleModal] = useState(false)
    const [foliarScheduleModal, setfoliarScheduleModal] = useState(false)
    const [soilApplicationScheduleModal, setsoilApplicationScheduleModal] = useState(false)
    const [petioleAnalysisModal, setpetioleAnalysisModal] = useState(false)
    const [selectedModalData, setSelectedModalData] = useState<any>(null)
    const [value, setValue] = useState(0);


    const appendGrid = (type: any) => {
        handleChange(type);
    }



    const handleChange = (newValue: number) => {
        setValue(newValue);
        onSubmit(newValue, selectedCropOption?.value);
    };

    const navigate = useNavigate();
    const startLoading = () => {
        gridRef?.current?.api?.showLoadingOverlay();
    }
    const stopLoading = () => {
        gridRef?.current?.api?.hideOverlay();
    }

    const gridOptions: GridOptions = {
        columnDefs: value === 0 ? petioleColDef : nutrientsColDef,
        rowData: nutrientData,
        rowSelection: 'single',
        animateRows: true,
        overlayLoadingTemplate:
            '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
        onCellClicked: (e) => {
            if (value === 0) { setSelectedModalData(e.data); setpetioleAnalysisModal(true); }
            if (value === 1) { setSelectedModalData(e.data); setOpenModal(true); }
            if (value === 2) { setSelectedModalData(e.data); setfertigationScheduleModal(true); }
            if (value === 3) { setSelectedModalData(e.data); setfoliarScheduleModal(true); }
            if (value === 4) { setSelectedModalData(e.data); setsoilApplicationScheduleModal(true); }
        }
    };

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            resizable: true,
            sortable: true,
            floatingFilter: true,
            menuTabs: ['filterMenuTab'],
        };
    }, []);

    const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
        return {
            toolPanels: ['filters'],
        };
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        // gridRef?.current?.api.setColumnDefs(farmActivitiesColDef);
        // gridRef?.current?.api.setRowData(tableData);
    }, []);

    // function to perform server side sorting
    const handleColumnSort = (event: SortChangedEvent) => {
        const columns = event.columnApi.getColumnState();
        let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId
    }

    // function to perform server side filtering
    const handleColumnFilter = (event: FilterChangedEvent) => {
        if (event.afterFloatingFilter) { }
    }

    const onPaginationChanged = useCallback(() => { }, []);

    const onReset = () => {
        setIsApplied(false);
        setisError(false);
        setisNutrientData(false);
        setnutrientData([]);
    }

    const onSubmit = async (type: number, crop: string) => {
        setisError(false);
        setisNutrientData(false);
        setnutrientData([]);

        if (crop?.length === 0) {
            alert('Choose one crop');
            return;
        }

        setIsApplied(true);

        try {
            let res;
            switch (type) {
                case 0:
                    res = await getNutrientPetioleStage(crop);
                    break;
                case 1:
                    res = await getNutrient(crop);
                    break;
                case 2:
                    res = await getNutrientFertigationSchedule(crop);
                    break;
                case 3:
                    res = await getNutrientFoliarSchedule(crop);
                    break;
                case 4:
                    res = await getNutrientSoilSchedule(crop);
                    break;
                default:
                    break;
            }

            if (res.length > 0) {
                setisNutrientData(true);
                setnutrientData(res);
            } else {
                setisError(true);
                setErrorValue(type);
            }
        } catch (err) {
            console.log(err);
            setisError(true);
            setisNutrientData(false);
            setnutrientData([]);
        } finally {
            setIsApplied(false);
        }
    };

    useEffect(() => {
        getCropOptions()
            .then(res => {
                setCrops(generateOptions(res, 'id', 'cropName'))
                setIsCropLoading(false)
            })
    }, [])

    return (
        <Grid width={'90%'} my={2} mx={'auto'} boxShadow={2} borderRadius={4} paddingBottom={5}>
            <Grid p={2} borderRadius={20} display={'flex'} justifyContent={'space-between'} marginBottom={3}>
                <Grid display={'flex'} item alignItems={'center'}>
                    <IconButton onClick={() => navigate('/')} ><ArrowBack /></IconButton>
                    <Typography m={1} variant='h5'>Fertigation</Typography>
                </Grid>
                {selectedCropOption?.value && (
                    <input type="button" value={value === 0 ? "Add Type" : value === 1 ? "Add Nutrient Config" : value === 2 ? "Add Fertigation Config" : value === 3 ? "Add Foliar Config" : " Add Soil Application Config"} style={{ width: '15%', padding: '10px', fontSize: 18, paddingTop: '10px', alignSelf: 'center', }}
                        onClick={() => { setSelectedModalData({ cropId: selectedCropOption?.value }); value === 0 ? setpetioleAnalysisModal(true) : value === 1 ? setOpenModal(true) : value === 2 ? setfertigationScheduleModal(true) : value === 3 ? setfoliarScheduleModal(true) : setsoilApplicationScheduleModal(true) }}
                    ></input>
                )}
            </Grid>

            <Grid>
                <div style={{
                    backgroundColor: isApplied ? '#B6F7BC' : '#E6E6E6',
                    margin: '0px 0 15px 0',
                    padding: '5px',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'column'
                }} >
                    <Typography variant='h6' ml={1} fontWeight='500' my={1}>Crop Id</Typography>
                    <Select
                        options={crops}
                        onChange={(selected: SingleValue<any>) => {
                            setSelectedCropOption(selected);
                            if (selected?.value && selected?.value?.length > 0) {
                                setValue(0);
                                onSubmit(0, selected.value);
                            }
                            else {
                                onReset()
                            }
                        }}
                        isLoading={isCropLoading}
                        isDisabled={isCropLoading}
                        value={selectedCropOption}
                        isClearable={true}
                        isSearchable={true}
                        placeholder={'- select -'}
                        styles={{
                            container: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                width: '25%',
                                marginLeft: '5px',
                            }),
                            control: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                borderTop: (state.isFocused ? '1px' : '0px'),
                                borderLeft: (state.isFocused ? '1px' : '0px'),
                                borderRight: (state.isFocused ? '1px' : '0px'),
                            }),
                            menu: (baseStyles: any, state: any) => ({
                                ...baseStyles,
                                zIndex: 100
                            })
                        }}
                    />
                </div>
            </Grid>
            {
                selectedCropOption?.value && (
                    <Tabs value={value} variant="fullWidth">
                        <Tab label="Type" onClick={() => handleChange(0)} />
                        <Tab label="Nutrient Config" onClick={() => handleChange(1)} />
                        <Tab label="Fertigation Schedule" onClick={() => handleChange(2)} />
                        <Tab label="Foliar Schedule" onClick={() => handleChange(3)} />
                        <Tab label="Soil Application Schedule" onClick={() => handleChange(4)} />
                    </Tabs>
                )
            }
            {
                isNutrientData && (
                    <div style={gridStyle} className="ag-theme-alpine">
                        {
                            isArrayWithLength(nutrientData) &&
                            <Grid p={3}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={nutrientData}
                                    columnDefs={value === 0 ? petioleColDef : nutrientsColDef}
                                    onGridReady={onGridReady}
                                    defaultColDef={defaultColDef}
                                    sideBar={sideBar}
                                    animateRows={true}
                                    pagination={true}
                                    paginationPageSize={15}
                                    gridOptions={gridOptions}
                                    domLayout="autoHeight"
                                    onPaginationChanged={onPaginationChanged}
                                    onSortChanged={handleColumnSort}
                                    onFilterChanged={handleColumnFilter}
                                ></AgGridReact>
                            </Grid>
                        }
                    </div>

                )
            }

            {
                isError && value === errorValue && (
                    <Typography
                        fontSize={17}
                        color={'red'}
                        margin={3}
                    >There seems to be an error. Please try again!</Typography>
                )
            }

            <Modal
                open={openModal}
                style={{ overflow: 'scroll' }}
                onClose={() => setOpenModal(false)}
            >
                <Grid width={'95%'} my={1} mx={'auto'} boxShadow={2} borderRadius={4} padding={2} style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }} >
                    <NutrientDetails selectedModalData={selectedModalData} setOpenModal={setOpenModal} />
                </Grid>
            </Modal>
            <Modal
                open={fertigationScheduleModal}
                style={{ overflow: 'scroll' }}
                onClose={() => setfertigationScheduleModal(false)}
            >
                <Grid width={'95%'} my={1} mx={'auto'} boxShadow={2} borderRadius={4} padding={2} style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }} >
                    <FertigationSchedule appendGrid={appendGrid} selectedModalData={selectedModalData} setOpenModal={setfertigationScheduleModal} />
                </Grid>
            </Modal>
            <Modal
                open={foliarScheduleModal}
                style={{ overflow: 'scroll' }}
                onClose={() => setfoliarScheduleModal(false)}
            >
                <Grid width={'95%'} my={1} mx={'auto'} boxShadow={2} borderRadius={4} padding={2} style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }} >
                    <FoliarSchedule appendGrid={appendGrid} selectedModalData={selectedModalData} setOpenModal={setfoliarScheduleModal} />
                </Grid>
            </Modal>
            <Modal
                open={soilApplicationScheduleModal}
                style={{ overflow: 'scroll' }}
                onClose={() => setsoilApplicationScheduleModal(false)}
            >
                <Grid width={'95%'} my={1} mx={'auto'} boxShadow={2} borderRadius={4} padding={2} style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }} >
                    <SoilApplicationSchedule appendGrid={appendGrid} selectedModalData={selectedModalData} setOpenModal={setsoilApplicationScheduleModal} />
                </Grid>
            </Modal>
            <Modal
                open={petioleAnalysisModal}
                style={{ overflow: 'scroll' }}
                onClose={() => setpetioleAnalysisModal(false)}
            >
                <Grid width={'95%'} my={1} mx={'auto'} boxShadow={2} borderRadius={4} padding={2} style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }} >
                    <PetioleAnalysis appendGrid={appendGrid} selectedModalData={selectedModalData} setOpenModal={setpetioleAnalysisModal} />
                </Grid>
            </Modal>
        </Grid >
    )


}

export default Nutrient