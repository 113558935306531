import { filterObservation } from 'src/services/observation.service';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { Line } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import moment from 'moment';
import { getHistoricalDiseasePrediction, getHistoricalPestPrediction } from 'src/services/plot.service';
import { removeUnderScores } from 'src/utils/helper';

moment.defineLocale("en-in", null);
const dateFormat = "ll";

type Props = {
  observationTypeId: string;
  diseasePestIds?: string[];
  startDate?: string;
  endDate?: string;
}

const ChartSection: React.FC<Props> = (props) => {
  const { diseasePestIds, startDate, endDate, observationTypeId } = props;

  const location: any = useLocation();
  const { cropId, plotId } = location.state;

  const [chartData, setChartData] = useState<ChartData>();
  const [predictions, setPredictions] = useState<Record<string, any[]>>();
  const [observations, setObservations] = useState<any[]>();

  useEffect(() => {
    fetchPredictions();
    fetchObservations();
  }, [plotId, startDate, endDate, diseasePestIds, observationTypeId]) // eslint-disable-line

  useEffect(() => {
    if (!!predictions && !!observations) {
      if (diseasePestIds?.length === 1) {
        const diseasePestPrediction = predictions[diseasePestIds[0]];
        const chartData = generateChartData(observations, diseasePestPrediction);
        setChartData(chartData);
      }
    } else {
      console.warn("Insufficient data for charts");
    }
  }, [predictions, observations]) // eslint-disable-line

  // api calls
  const fetchPredictions = () => {
    const from = startDate ?? moment().subtract(15, "days").toISOString();
    const to = endDate ?? moment().toISOString();

    getHistoricalDiseasePrediction(plotId, from, to)
      .then(res => {
        const converted = convertData(res);
        setPredictions(predictions => ({ ...predictions, ...converted }));
      })

    getHistoricalPestPrediction(plotId, from, to)
      .then(res => {
        const converted = convertData(res);
        setPredictions(predictions => ({ ...predictions, ...converted }));
      })
  }

  const fetchObservations = () => {
    const filterQuery: any = {
      and: [
        { diseasePestId: { inq: diseasePestIds } },
        { plotId },
        { cropId },
        { observationDate: { gte: startDate } },
        { observationDate: { lte: endDate } },
      ]
    };

    if(!!observationTypeId) {
      filterQuery.and.push({ observationTypeId });
    }

    filterObservation(
      filterQuery,
      {
        observationDate: true,
        probability: true,
        riskLevel: true,
        observationTypeId: true,
        diseasePestId: true,
        modelPrediction: true,
        modelRiskLevel: true
      }
    ).then(observations => {
      setObservations(observations);
    })
  }

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        // text: Mappings[field] ?? field,
      },
      tooltip: {
        callbacks: {
          // footer: footer,
        }
      }
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: 5,
          minRotation: 0,
          maxRotation: 0
        },
      },
      y: {
        title: {
          color: 'green',
          display: true,
          // text: UnitMapping[field] ?? ''
        },
        gridLines: {
          display: false,
          color: "rgba(0, 0, 0, 0)",
        }
      }
    }
  };

  const hasChartData = !!chartData && !!chartData.labels && chartData.labels.length > 0;
  const hasDiseasePest = !!diseasePestIds && diseasePestIds.length > 0
  return (
    <section style={{ paddingBottom: "20px" }}>
      <div style={{ width: "60%", margin: "auto" }}>
        {hasDiseasePest && hasChartData && (
          <p style={{
            padding: 0,
            margin: 0,
            textAlign: "center",
            textTransform: "capitalize",
            position: "relative",
            top: "20px",
            fontSize: "14px",
          }}> Chart for {removeUnderScores(diseasePestIds[0])}</p>
        )}

        { hasChartData && <Line options={options} data={chartData as any} />}
      </div>
    </section>
  )
}

export default ChartSection;

/**
 * ================== Helper Functions ======================
 */

const generateChartData = (observations: any[] = [], predictions: any[] = []): ChartData => {
  // observations
  const uniqueObservations: any[] = observations.reduce((accumulator, observation) => {
    if (!accumulator.some((item: any) => item.observationDate === observation.observationDate)) {
      accumulator.push(observation);
    }
    return accumulator;
  }, []);

  const predictionDateProbability: Record<string, number | null> = {};
  const observationDateProbability: Record<string, number | null> = {};

  uniqueObservations.forEach(observation => {
    const currentValue = observationDateProbability[moment(observation.observationDate).format(dateFormat)];
    observationDateProbability[moment(observation.observationDate).format(dateFormat)] = Math.max(observation.probability, currentValue ?? 0);
  })

  // predictions
  predictions.forEach((prediction: any) => {
    predictionDateProbability[moment(prediction.calculatedDate).format(dateFormat)] = prediction.probability;
  })

  const predictionDates = Object.keys(predictionDateProbability);
  const observationDates = Object.keys(observationDateProbability);
  const sortedDates = sortDates([...new Set([...predictionDates, ...observationDates])])

  const observationData = sortedDates.map(date => observationDateProbability[date] ?? null);
  const predictionData = sortedDates.map(date => predictionDateProbability[date] ?? null);

  const observationDataset: any = {
    label: "Observation",
    fill: false,
    borderColor: '#36A2EB',
    backgroundColor: '#9BD0F5',
    data: observationData
  }

  const predictionDataset: any = {
    label: "Prediction",
    fill: false,
    borderColor: '#FF6384',
    backgroundColor: '#FFB1C1',
    data: predictionData,
  }

  let chartData: ChartData;

  chartData = {
    labels: sortedDates,
    datasets: [predictionDataset, observationDataset]
  };

  return chartData;
}

function sortDates(dates: string[], format: string = dateFormat) {
  const dateObjects = dates.map(date => {
    if (!moment(date).isValid()) {
      console.log("invalid", date);
    }
    return moment(date);
  });

  dateObjects.sort((a: any, b: any) => a - b);

  const sortedDates = dateObjects.map(date => date.format(format));
  return sortedDates;
}

const convertData = (data: any[]) => {
  const result: Record<string, any[]> = {};

  data.forEach((item) => {
    result[item.id] = item.data;
  });

  return result;
};