import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getAllNotifications } from "../services/farm.service";
import moment from "moment";
import { useNavigate, useLocation } from "react-router";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  SortChangedEvent,
  FilterChangedEvent,
} from "ag-grid-community";
import { columnDef } from "../constants/columnDefs";
import { getPlotsByPlotIds } from "../services/plot.service";
import { PlotDetails, PlotData, Activities } from "src/pages";
import { Loader, ConversationCard } from "../components";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

const NotificationType: any = {
  "1": "IRRIGATION",
  "2": "CULTURAL_PRACTICE",
  "3": "NUTRIENT",
  "4": "SPRAY",
  "5": "SEED",
  "6": "FIELD_PREPARATION",
  "7": "DISEASE",
  "8": "PEST",
  "9": "HIGH_HUMIDITY",
  "10": "HIGH_TEMPERATURE",
  "11": "LOW_HUMIDITY",
  "12": "LOW_TEMPERATURE",
  "13": "LEAF_WET",
  "14": "DEVICE_DYSFUNCTION",
  "15": "WEATHER_UPDATE",
  "16": "DAILY_REPORT",
  "17": "LOW_SOIL_TEMERATURE",
  "18": "HIGH_SOIL_TEMPERATURE",
  "19": "WET_SOIL_MOISTURE1",
  "20": "DRY_SOIL_MOISTURE1",
  "21": "WET_SOIL_MOISTURE2",
  "22": "DRY_SOIL_MOISTURE2",
  "23": "RAIN",
  "24": "HIGH_WIND_SPEED",
  "25": "LOW_WIND_SPEED",
  "26": "MIN_AVERAGE_LUX",
  "27": "WEATHER_RAIN_CHANCE",
  "28": "PLOT_EXPIRED",
  "29": "PLOT_EXPIRING",
  "30": "SOIL_REPORT_UPLOAD",
  "31": "LEAF_REPORT_UPLOAD",
  "32": "PETIOLE_REPORT_UPLOAD",
  "33": "UPDATE_APP",
  "34": "REFER_FRIEND",
  "35": "UPDATE_CROP_STAGE",
  "36": "END_CROP",
  "37": "CROP_STAGE_UPDATED",
  "38": "NETWORK_ISSUE",
  "39": "FERTIGATION_SCHEDULE_GENERATED",
  "40": "BATTERY_ALERT",
  "41": "SOLAR_PANEL_ALERT",
  "42": "SOLAR_PANEL_CLEAN",
  "45": "WELCOME",
  "47": "EARLY_IRRIGATION",
  "48": "RECEIVABLES",
  "49": "PREVENTIVE_SPRAY",
  "50": "DYNAMIC_SPRAY",
};

const user = window.localStorage.getItem("userId");

const Notifications = (): JSX.Element => {
  const [visible, setVisible] = useState<any>(false);
  type Anchor = "right";

  // display components or not
  const [showAddNewConv, setShowAddNewConv] = useState<Boolean>(false);
  const [conversations, setConversations] = useState<any>([]);
  const [doNotContact, setDoNotContact] = useState<Boolean>(false);

  const [showLoader, setShowLoader] = useState<Boolean>(true);
  const [showGrid, setShowGrid] = useState<Boolean>(true);
  const [showPlot, setShowPlot] = useState<Boolean>(false);
  const [showPlotData, setShowPlotData] = useState<Boolean>(false);
  const [showFarm, setShowFarm] = useState<Boolean>(false);

  const [farmId, setFarmId] = useState<string>("");
  const [userId, setUserId] = useState<any>(user);
  const [cellPlotId, setCellPlotId] = useState<string>("");
  const [farmUserId, setFarmUserId] = useState<string>("");
  const [cellCropId, setCellCropId] = useState<string>("");

  const [clickId, setClickId] = useState<string>("");
  const [idsArray, setIdsArray] = useState<any>([]);
  const [divideIntoCategories, setDivideIntoCategories] =
    useState<Boolean>(true);
  const [idsObjectArray, setIdsObjectArray] = useState<any>([]);
  const [cropsArr, setCrops] = useState<any>([]);
  const [weatherArr, setweather] = useState<any>([]);
  const [rechargeArr, setRecharge] = useState<any>([]);
  const [deviceRelatedArr, setDeviceRelated] = useState<any>([]);
  const [othersArr, setOthers] = useState<any>([]);

  const [tableData, setTableData] = useState<any[]>([]);
  const gridRef = useRef<AgGridReact>(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridStyleTwo = useMemo(
    () => ({ height: "100%", width: "100%", display: "none" }),
    []
  );
  const navigate = useNavigate();

  let crops: any = [];
  let weather: any = [];
  let recharge: any = [];
  let deviceRelated: any = [];
  let others: any = [];

  const startLoading = () => {
    gridRef?.current?.api?.showLoadingOverlay();
  };
  const stopLoading = () => {
    gridRef?.current?.api?.hideOverlay();
  };

  const backToPlot = () => {
    setShowGrid(false);
    setShowPlot(true);
    setShowPlotData(false);
    setShowFarm(false);
  };

  const gridOptions: GridOptions = {
    columnDefs: columnDef,
    rowData: idsArray,
    rowSelection: "single",
    // suppressHorizontalScroll: false,
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => {
      setDoNotContact(e.data.farmUser.doNotContact);
      setFarmUserId(e.data.farmUserId);
      setFarmId(e.data.farmId);
      setCellPlotId(e.data.plotId);
      setCellCropId(e.data.cropSown.cropId);

      setShowGrid(false);
      setShowPlot(true);
      setShowPlotData(false);
      setShowFarm(false);
      return;
    },
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {}, []);

  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
  };

  const handleColumnFilter = (event: FilterChangedEvent) => {
    if (event.afterFloatingFilter) {
    }
  };

  const onPaginationChanged = useCallback(() => {}, []);

  const getUniquePlotIdsNotis = (v: any) => {
    let uniqueIds: any = [];
    let uniquePlotIdsArr: any = [];
    for (let i = 0; i < v.length; i++) {
      if (!uniqueIds.includes(v[i].plotId)) {
        uniqueIds.push(v[i].plotId);
        uniquePlotIdsArr.push(v[i]);
      }
    }
    return uniquePlotIdsArr;
  };

  // restructuring notif array for classification of notifications
  const restructuringNotifArray = (allNotis: Array<any>): any => {
    let res: any = {};
    let notifTypeIdArray: Array<string> = [];
    for (let i = 0; i < allNotis.length; i++) {
      const { notifTypeId } = allNotis[i];
      let notiId = notifTypeId.toString();
      const exist = notifTypeIdArray.includes(notiId);
      if (!exist) {
        notifTypeIdArray.push(notiId);
        res[notiId] = [allNotis[i]];
      } else {
        res[notiId].push(allNotis[i]);
      }
    }
    // loop through each notifTypeId
    const keysArr = Object.keys(res);
    keysArr.map((key, index) => {
      res[key] = getUniquePlotIdsNotis(res[key]);
    });
    return res;
  };

  const getNotis = async () => {
    let tryDate = moment().subtract(1, "day").toISOString();
    let allNotis = await getAllNotifications({ date: tryDate });
    const restructuredNotifyArray = restructuringNotifArray(allNotis);
    setShowLoader(false);
    setIdsObjectArray([restructuredNotifyArray]); //{[{...},{...} ...]}
    setIdsArray(Object.keys(restructuredNotifyArray));
  };

  useEffect(() => {
    getNotis();
  }, []);

  const extractPlotIds = (arrOfObjs: any, id: any) => {
    const length = arrOfObjs.length;
    const plotIdArr = [];
    for (let i = 0; i < length; i++) {
      plotIdArr.push(arrOfObjs[i].plotId);
    }
    getPlotsByPlotIds(plotIdArr, [
      "farm",
      "farmUser",
      "cropSown",
      "device",
      "plotLocation",
    ]).then((res) => {
      //    console.log(res);
      setTableData(res);
      setClickId(id);
      return res.length;
    });
  };

  const classifyIntoCategory = () => {
    idsArray.map((id: any, index: number) => {
      const idsParentObj = idsObjectArray[0];
      const idArray = idsParentObj[id];
      if ([14, 38, 40, 41, 42].includes(+id)) {
        if (!deviceRelated.includes(id)) {
          deviceRelated.push(id);
        }
      }
      if ([1, 2, 3, 4, 5, 6, 7, 8, 49, 50].includes(+id)) {
        if (!crops.includes(id)) {
          crops.push(id);
        }
      }
      if (
        [
          9, 10, 11, 12, 13, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
        ].includes(+id)
      ) {
        if (!weather.includes(id)) {
          weather.push(id);
        }
      }
      if ([28, 29].includes(+id)) {
        if (!recharge.includes(id)) {
          recharge.push(id);
        }
      }
      if (+id >= 30 && ![38, 40, 41, 42, 49, 50].includes(+id)) {
        if (!others.includes(id)) {
          others.push(id);
        }
      }
    });

    setCrops(crops);
    setweather(weather);
    setRecharge(recharge);
    setDeviceRelated(deviceRelated);
    setOthers(others);
    setDivideIntoCategories(false);
  };
  {
    idsArray.length > 0 && divideIntoCategories && classifyIntoCategory();
  }
  console.log(cropsArr);

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          textDecoration: "underline",
        }}
      >
        <Typography m={1} variant="h5">
          Notifications
        </Typography>
      </div>

      {showLoader == true ? (
        <div
          style={{
            marginTop: "20vh",
            width: "95%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div
            data-testid="crops-sections"
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginLeft: "2%",
              border: "1px solid black",
            }}
          >
            <p
              style={{
                margin: "6px 10px",
                fontWeight: "600",
                textDecoration: "underline",
                fontStyle: "italic",
              }}
            >
              Crops :{" "}
            </p>
            {cropsArr.length > 0
              ? cropsArr.map((id: any, index: number) => {
                  const idsParentObj = idsObjectArray[0];
                  const idArray = idsParentObj[id];
                  return (
                    <Link
                      to={"/notifications"}
                      onClick={() => extractPlotIds(idArray, id)}
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      <Button
                        key={index}
                        variant="outlined"
                        size="small"
                        color="success"
                        style={{
                          margin: "4px 5px",
                          borderRadius: "20px",
                          padding: "0px 10px",
                          backgroundColor: clickId == id ? "#2e7d32" : "",
                        }}
                      >
                        <Typography
                          variant="overline"
                          textTransform={"capitalize"}
                          style={{ color: clickId == id ? "white" : "" }}
                        >
                          {" "}
                          {NotificationType[id] || "Extracting Data"} -{" "}
                        </Typography>
                        <Typography
                          variant="overline"
                          ml={0.5}
                          fontWeight={600}
                          style={{ color: clickId == id ? "white" : "" }}
                        >
                          {" "}
                          {idArray.length || "length"}{" "}
                        </Typography>
                      </Button>
                    </Link>
                  );
                })
              : null}
          </div>
          <div
            data-testid="weather"
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginLeft: "2%",
              border: "1px solid black",
            }}
          >
            <p
              style={{
                margin: "6px 10px",
                fontWeight: "600",
                textDecoration: "underline",
                fontStyle: "italic",
              }}
            >
              Weather :{" "}
            </p>
            {weatherArr.length > 0
              ? weatherArr.map((id: any, index: number) => {
                  const idsParentObj = idsObjectArray[0];
                  const idArray = idsParentObj[id];
                  return (
                    <Link
                      to={"/notifications"}
                      onClick={() => extractPlotIds(idArray, id)}
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      <Button
                        key={index}
                        variant="outlined"
                        size="small"
                        color="success"
                        style={{
                          margin: "4px 5px",
                          borderRadius: "20px",
                          padding: "0px 10px",
                          backgroundColor: clickId == id ? "#2e7d32" : "",
                        }}
                      >
                        <Typography
                          variant="overline"
                          textTransform={"capitalize"}
                          style={{ color: clickId == id ? "white" : "" }}
                        >
                          {" "}
                          {NotificationType[id] || "Extracting Data"} -{" "}
                        </Typography>
                        <Typography
                          variant="overline"
                          ml={0.5}
                          fontWeight={600}
                          style={{ color: clickId == id ? "white" : "" }}
                        >
                          {" "}
                          {idArray.length || "length"}{" "}
                        </Typography>
                      </Button>
                    </Link>
                  );
                })
              : null}
          </div>
          <div
            data-testid="recharge"
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginLeft: "2%",
              border: "1px solid black",
            }}
          >
            <p
              style={{
                margin: "6px 10px",
                fontWeight: "600",
                textDecoration: "underline",
                fontStyle: "italic",
              }}
            >
              Recharge :{" "}
            </p>
            {rechargeArr.length > 0
              ? rechargeArr.map((id: any, index: number) => {
                  const idsParentObj = idsObjectArray[0];
                  const idArray = idsParentObj[id];
                  return (
                    <Link
                      to={"/notifications"}
                      onClick={() => extractPlotIds(idArray, id)}
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      <Button
                        key={index}
                        variant="outlined"
                        size="small"
                        color="success"
                        style={{
                          margin: "4px 5px",
                          borderRadius: "20px",
                          padding: "0px 10px",
                          backgroundColor: clickId == id ? "#2e7d32" : "",
                        }}
                      >
                        <Typography
                          variant="overline"
                          textTransform={"capitalize"}
                          style={{ color: clickId == id ? "white" : "" }}
                        >
                          {" "}
                          {NotificationType[id] || "Extracting Data"} -{" "}
                        </Typography>
                        <Typography
                          variant="overline"
                          ml={0.5}
                          fontWeight={600}
                          style={{ color: clickId == id ? "white" : "" }}
                        >
                          {" "}
                          {idArray.length || "length"}{" "}
                        </Typography>
                      </Button>
                    </Link>
                  );
                })
              : null}
          </div>
          <div
            data-testid="device-related"
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginLeft: "2%",
              border: "1px solid black",
            }}
          >
            <p
              style={{
                margin: "6px 10px",
                fontWeight: "600",
                textDecoration: "underline",
                fontStyle: "italic",
              }}
            >
              Device-Related :{" "}
            </p>
            {deviceRelatedArr.length > 0
              ? deviceRelatedArr.map((id: any, index: number) => {
                  const idsParentObj = idsObjectArray[0];
                  const idArray = idsParentObj[id];
                  return (
                    <Link
                      to={"/notifications"}
                      onClick={() => extractPlotIds(idArray, id)}
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      <Button
                        key={index}
                        variant="outlined"
                        size="small"
                        color="success"
                        style={{
                          margin: "4px 5px",
                          borderRadius: "20px",
                          padding: "0px 10px",
                          backgroundColor: clickId == id ? "#2e7d32" : "",
                        }}
                      >
                        <Typography
                          variant="overline"
                          textTransform={"capitalize"}
                          style={{ color: clickId == id ? "white" : "" }}
                        >
                          {" "}
                          {NotificationType[id] || "Extracting Data"} -{" "}
                        </Typography>
                        <Typography
                          variant="overline"
                          ml={0.5}
                          fontWeight={600}
                          style={{ color: clickId == id ? "white" : "" }}
                        >
                          {" "}
                          {idArray.length || "length"}{" "}
                        </Typography>
                      </Button>
                    </Link>
                  );
                })
              : null}
          </div>
          <div
            data-testid="others"
            style={{
              width: "95%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginLeft: "2%",
              border: "1px solid black",
            }}
          >
            <p
              style={{
                margin: "6px 10px",
                fontWeight: "600",
                textDecoration: "underline",
                fontStyle: "italic",
              }}
            >
              Others :{" "}
            </p>

            {othersArr.length > 0
              ? othersArr.map((id: any, index: number) => {
                  const idsParentObj = idsObjectArray[0];
                  const idArray = idsParentObj[id];
                  return (
                    <Link
                      to={"/notifications"}
                      onClick={() => extractPlotIds(idArray, id)}
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      <Button
                        key={index}
                        variant="outlined"
                        size="small"
                        color="success"
                        style={{
                          margin: "4px 5px",
                          borderRadius: "20px",
                          padding: "0px 10px",
                          backgroundColor: clickId == id ? "#2e7d32" : "",
                        }}
                      >
                        <Typography
                          variant="overline"
                          textTransform={"capitalize"}
                          style={{ color: clickId == id ? "white" : "" }}
                        >
                          {" "}
                          {NotificationType[id] || "Extracting Data"} -{" "}
                        </Typography>
                        <Typography
                          variant="overline"
                          ml={0.5}
                          fontWeight={600}
                          style={{ color: clickId == id ? "white" : "" }}
                        >
                          {" "}
                          {idArray.length || "length"}{" "}
                        </Typography>
                      </Button>
                    </Link>
                  );
                })
              : null}
          </div>
          <div
            data-testid="ag-grid-noti"
            style={showGrid ? gridStyle : gridStyleTwo}
            className="ag-theme-alpine"
          >
            {idsArray.length > 0 && (
              <Grid p={3}>
                <AgGridReact
                  ref={gridRef}
                  rowData={tableData}
                  columnDefs={columnDef}
                  onGridReady={onGridReady}
                  defaultColDef={defaultColDef}
                  sideBar={sideBar}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={15}
                  gridOptions={gridOptions}
                  domLayout="autoHeight"
                  onPaginationChanged={onPaginationChanged}
                  onSortChanged={handleColumnSort}
                  onFilterChanged={handleColumnFilter}
                ></AgGridReact>
              </Grid>
            )}
          </div>
          {showPlot && (
            <PlotDetails
              cellPlotId={cellPlotId}
              setShowGrid={setShowGrid}
              setShowPlot={setShowPlot}
              setShowPlotData={setShowPlotData}
              setShowFarm={setShowFarm}
              doNotContact={doNotContact}
            />
          )}
          {showPlotData && (
            <PlotData backToPlot={backToPlot} propsPlotId={cellPlotId} />
          )}
          {showFarm && (
            <Activities
              backToPlot={backToPlot}
              propsPlotId={cellPlotId}
              propsCropId={cellCropId}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Notifications;
