import { ArrowBack } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Select, { MultiValue } from "react-select";
import { getCropOptions } from "src/services/crop.service";
import { generateOptions } from "src/utils/helper";
import LocationFilter, {
  Location,
} from "../components/Filters/location.filter";
import {
  deleteConsultants,
  getConsultantWithId,
  getRegionDetailsWithId,
  postConsultant,
  updateConsultants,
} from "../services/plot.service";
import DenseTable from "./AddedConsultantsTable";

const AddConsultants: React.FC = (): JSX.Element => {
  let res: any = {};
  const navigate = useNavigate();
  const { id } = useParams();

  const [regionsToMap, setRegionsToMap] = useState<any>([]);
  const [crops, setCrops] = useState<MultiValue<any>>([]);
  const [addedConsultants, setAddedConsultants] = useState<any[]>([]);
  const [isCropLoading, setIsCropLoading] = useState<boolean>(true);
  const [updateData, setUpdateData] = useState<boolean>(false);
  const [fylloWeatherCount, setFylloWeatherCount] = useState<boolean>(true);
  const [selectedCropOption, setSelectedCropOption] = useState<any>(null);
  const [isAllLocationsFetched, setIsAllLocationsFetched] =
    useState<boolean>(true);

  const [Id, setId] = useState<any>("");
  const [mobile, setMobile] = useState<any>("");
  const [allLocation, setAllLocation] = useState<any>("");

  const handleCropChange = (selected: MultiValue<any>) => {
    setSelectedCropOption(selected);
  };

  const handleDropdownChange = (
    dropdown: string,
    selected: MultiValue<any>
  ) => {
    handleCropChange(selected);
  };

  const DropDownStyles = {
    container: (baseStyles: any, state: any) => ({
      ...baseStyles,
      width: "100%",
      marginLeft: "5px",
      marginTop: "12px",
    }),
    control: (baseStyles: any, state: any) => ({
      ...baseStyles,
      borderTop: state.isFocused ? "1px" : "0px",
      borderLeft: state.isFocused ? "1px" : "0px",
      borderRight: state.isFocused ? "1px" : "0px",
    }),
    menu: (baseStyles: any, state: any) => ({
      ...baseStyles,
      zIndex: 100,
    }),
  };

  const DropDownProps = {
    styles: DropDownStyles,
    isClearable: true,
    isSearchable: true,
    placeholder: "- select -",
  };

  useEffect(() => {
    getCropOptions().then((res) => {
      setCrops(generateOptions(res, "id", "cropName"));
      setIsCropLoading(false);
    });
  }, []);

  const getFylloWeatherPlotCount = async (id: any) => {
    const regionDetail = await getRegionDetailsWithId(id);
    if (regionDetail?.fylloWeatherPlotCount <= 0) {
      alert("Can't update as fyllo weather plot count is less!");
      setFylloWeatherCount(false);
    } else {
      setFylloWeatherCount(true);
    }
  };

  useEffect(() => {
    if (id) {
      getConsultantWithId(id).then((result: any) => {
        if (result.id) {
          res = result;
          setId(res?.id || "");
          setMobile(res?.mobile || "");
          setAllLocation(res?.regionId || "");
          setSelectedCropOption(
            { value: res?.cropId, label: res?.cropId } || ""
          );
          handleDropdownChange("crop", [{
            value: res?.cropId,
            label: res?.cropId,
          }]);
          setUpdateData(true);
        }
      });
    }
  }, [id]);

  const fetchingAllConsultants = (res: any) => {
    setAddedConsultants(addedConsultants.concat(res));
  };

  const onSubmit = () => {
    if (window.confirm("Do you want to submit?")) {
      if (Id) {
        updateConsultants({
          Id,
          mobile,
          cropValue: selectedCropOption[0].value,
          allLocation: regionsToMap[0]?.id || allLocation,
        }).then((res: any) => {
          if (res.message === "Success") {
            alert("Consultant Details Updated Successfully!");
            navigate("/consultants");
          } else {
            navigate("/consultants");
          }
        })
      } else {
        const payload: any[] =
          selectedCropOption?.flatMap((crop: any) => {
            return (
              mobile
                ?.filter((mb: string) => !!mb.length)
                ?.map((mb: string) => {
                  return { mobile: mb, cropId: crop.value };
                }) || []
            );
          }) || [];
        const finalPayload: any = [];
        payload.map((data: any) => {
          regionsToMap.map((region: any) =>
            finalPayload.push({ ...data, regionId: region.id })
          );
        });
        postConsultant(finalPayload).then((res: any) => {
          if (res.length > 0) {
            alert("New Consultant Added Successfully!");
            fetchingAllConsultants(res);
          }
        });
      }
    }
  };

  const getLocation = (locObj: Location) => {
    const regions = Object.values(locObj);
    const region = regions.reverse().join("_");
    setAllLocation(region);
    setIsAllLocationsFetched(false);
    getFylloWeatherPlotCount(region);
  };

  const onDelete = () => {
    if (Id) {
      if (window.confirm("Do you want to delete?")) {
        getConsultantWithId(Id).then((res: any) => {
          if (res.id) {
            deleteConsultants(Id).then((res: any) => {
              if (res.message === "Success") {
                alert("Consultant Deleted");
                navigate("/consultants");
              } else {
                navigate("/consultants");
              }
            });
          }
        });
      }
    }
  };
  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid p={2} borderRadius={20}>
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton
            data-testid="back-button"
            onClick={() => navigate("/consultants")}
          >
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Subscriptions Details Here
          </Typography>
        </Grid>
      </Grid>
      <div>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 30,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <div>
              <div>
                <label style={{ fontSize: 19, marginRight: 5, margin: 5 }}>
                  Mobile
                </label>
              </div>
              <input
                data-testid="mobile-input"
                placeholder="Enter the new/updated consultant mobile number"
                name="mobile"
                onChange={(e: any) => {
                  const contacts = e.target.value.split(",")?.map((val: any) => val.trim());
                  if(Id){
                    setMobile(contacts[0])
                  }
                  else{
                    setMobile(contacts);
                  }
                }}
                value={mobile}
                type="text"
                style={{
                  padding: "20px",
                  width: "45vw",
                  fontSize: 18,
                  paddingTop: "15px",
                  margin: "3px",
                  marginBottom: 11,
                  marginTop: 10,
                }}
              ></input>
              <br />
            </div>
            <div
              data-testid="crop-input"
              style={{ marginTop: "40px", height: "60px", width: "45vw" }}
            >
              <Typography variant="subtitle1" mr={3} mt={-5}>
                Crop
              </Typography>
              <Select
                options={crops}
                onChange={(selected: MultiValue<any>) =>
                  handleDropdownChange("crop", selected)
                }
                isMulti
                isLoading={isCropLoading}
                isDisabled={isCropLoading}
                value={selectedCropOption}
                {...DropDownProps}
              />
            </div>
          </div>
          {updateData === true ? (
            <LocationFilter
              allLocation={allLocation}
              setRegionsToMap={setRegionsToMap}
              setDynamicType={true}
              getLocation={getLocation}
              handleFilterDataChange={undefined}
              handleRemove={undefined}
            />
          ) : isAllLocationsFetched === false ? (
            <LocationFilter
              allLocation={allLocation}
              setRegionsToMap={setRegionsToMap}
              setDynamicType={true}
              getLocation={getLocation}
              handleFilterDataChange={undefined}
              handleRemove={undefined}
            />
          ) : (
            <LocationFilter
              getLocation={getLocation}
              setRegionsToMap={setRegionsToMap}
              setDynamicType={true}
              handleFilterDataChange={undefined}
              handleRemove={undefined}
            />
          )}
          <Grid display={"flex"} justifyContent={"space-between"}>
            {fylloWeatherCount === false ? (
              <input
                data-testid="submit-btn-disabled"
                disabled
                type="button"
                value="Submit"
                onClick={onSubmit}
                style={{
                  width: "60%",
                  padding: "20px",
                  fontSize: 18,
                  paddingTop: "15px",
                  alignSelf: "center",
                  margin: "30px",
                }}
              />
            ) : (
              <input
                data-testid="submit-btn-enabled"
                type="button"
                value="Submit"
                onClick={onSubmit}
                style={{
                  width: "60%",
                  padding: "20px",
                  fontSize: 18,
                  paddingTop: "15px",
                  alignSelf: "center",
                  margin: "30px",
                }}
              />
            )}
            <input
              data-testid="delete-btn"
              type="button"
              value="Delete"
              onClick={onDelete}
              style={{
                width: "60%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
                alignSelf: "center",
                margin: "30px",
              }}
            />
          </Grid>
        </form>
      </div>
      <div>
        {addedConsultants.length > 0 && (
          <DenseTable newConsultantsArr={addedConsultants} />
        )}
      </div>
    </Grid>
  );
};
export default AddConsultants;
