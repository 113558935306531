import { Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  postCropDisease,
  patchCropDisease,
  postCropPest,
  patchCropPest,
  deleteCropDisease,
  deleteCropPest,
  getCropVarieties,
  getcropsStageBasedOnVariety,
} from "src/services/crop.service";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Select, { SingleValue, MultiValue } from "react-select";
import { ToastAlert } from "src/components";
import { generateOptions } from "src/utils/helper";
import LocaleDropdown from "../LocaleDropdown";
import { ToastSeverity } from "../ToastAlert/types";

interface Props {
  selectedModalData?: any;
  setOpenModal?: any;
  cropOptions?: any;
  isPest?: boolean;
}

const DropdownProps = {
  isClearable: true,
  isSearchable: true,
  placeholder: "- select -",
  styles: {
    container: (baseStyles: any) => ({
      ...baseStyles,
      width: "100%",
      padding: 2,
      fontSize: 16,
      margin: "3px 0",
      marginBottom: 11,
      marginTop: 6,
    }),
  },
};

const DiseasePest: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    selectedModalData,
    setOpenModal,
    cropOptions,
    isPest = false,
  } = props;
  const [cropId, setcropId] = useState<SingleValue<any>>(
    cropOptions?.filter((c: any) => c?.id === selectedModalData?.cropId)[0] ||
      ""
  );
  const [id, setId] = useState(
    isPest ? selectedModalData?.pestId : selectedModalData?.diseaseId || ""
  );
  const [applicableStages, setApplicableStages] = useState<any[]>(
    selectedModalData?.applicableStages || []
  );
  const [stageOptions, setStageOptions] = useState<any[]>([]);
  const [selectedApplicableStages, setSelectedApplicableStages] = useState<
    MultiValue<any>
  >([]);
  const [diseasePest, setDiseasePest] = useState<SingleValue<any>>({value: isPest ? selectedModalData?.pestId : selectedModalData?.diseaseId , label: selectedModalData?.name});
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [varietyOption, setVarietyOption] = useState<any[]>([]);
  const [cropVarietyId, setCropVarietyId] = useState<MultiValue<any>>([]);
  const [varietyId, setVarietyId] = useState<any[]>(
    selectedModalData?.cropVarietyId || []
  );
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<ToastSeverity>("success");

  const onSubmit = async () => {
    if (cropId?.id) {
      const finalData: any = {
        name: diseasePest?.label,
        cropId: cropId?.id,
      };
      if (selectedApplicableStages.length) {
        finalData.applicableStages = selectedApplicableStages?.map(
          (v) => v?.value
        );
      }
      if (!!cropVarietyId.length) {
        finalData.cropVarietyId = cropVarietyId?.map((v) => v?.value);
      }
      if (isPest) {
        finalData.pestId = diseasePest?.value;
      } else {
        finalData.diseaseId = diseasePest?.value;
      }
      if (window.confirm("Do you want to submit?")) {
        if (selectedModalData?.id) {
          let response;
          if (isPest) {
            response = await patchCropPest(finalData, selectedModalData?.id);
          } else {
            response = await patchCropDisease(finalData, selectedModalData?.id);
          }
          if (response.message === "Success") {
            setToastMessage("Data Updated");
            setToastSeverity("success");
            setOpenModal(false);
          } else {
            setToastMessage("Some Error Ocuured");
            setToastSeverity("error");
            setOpenModal(false);
          }
        } else {
          let response;
          if (isPest) {
            response = await postCropPest(finalData?.cropId, [finalData]);
          } else {
            response = await postCropDisease(finalData?.cropId, [finalData]);
          }
          if (response.id) {
            setToastMessage("Data Added");
            setToastSeverity("success");
            setOpenModal(false);
          } else {
            setToastMessage("Some Error Ocuured");
            setToastSeverity("error");
            setOpenModal(false);
          }
        }
      }
    } else {
      setToastMessage("Some Error Ocuured");
      setToastSeverity("error");
    }
    setOpenToast(true);
  };

  const onDelete = async () => {
    if (selectedModalData?.id) {
      if (window.confirm("Do you want to delete?")) {
        let response;
        if (isPest) {
          response = await deleteCropPest(selectedModalData?.id);
        } else {
          response = await deleteCropDisease(selectedModalData?.id);
        }
        if (response.message === "Success") {
          setToastMessage("Data Deleted");
          setToastSeverity("success");
          setOpenModal(false);
        } else {
          setToastMessage("Some Error Ocuured");
          setToastSeverity("error");
          setOpenModal(false);
        }
      }
    } else {
      setToastMessage("Some Error Ocuured");
      setToastSeverity("error");
    }
    setOpenToast(true);
  };

  useEffect(() => {
    if (cropId?.id) {
      getCropVarieties(cropId?.id).then((res: any) =>
        setVarietyOption(generateOptions(res, "id", "name"))
      );
    }
  }, [cropId]);

  useEffect(() => {
    getcropsStageBasedOnVariety(
      cropId?.id,
      cropVarietyId?.map((v) => v?.value)
    ).then((res: any) =>
      setStageOptions(generateOptions(res, "stageId", "name"))
    );
  }, [cropVarietyId]);

  useEffect(() => {
    if (varietyOption.length) {
      setCropVarietyId(
        varietyOption?.filter((variety) => varietyId.includes(variety?.value))
      );
    }
  }, [varietyOption]);

  useEffect(() => {
    if (stageOptions.length) {
      setSelectedApplicableStages(
        stageOptions?.filter((stage) => applicableStages.includes(stage?.value))
      );
    }
  }, [stageOptions]);

  const navigate = useNavigate();

  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid
        p={2}
        borderRadius={20}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton
            onClick={() =>
              setOpenModal
                ? setOpenModal(false)
                : navigate("/cropCharacteristics")
            }
          >
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            {isPest ? "Crop Pest" : "Crop Disease"}
          </Typography>
        </Grid>
      </Grid>
      <ToastAlert
        open={openToast}
        setOpen={setOpenToast}
        severity={toastSeverity}
        message={toastMessage}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 30,
        }}
      >
        <form style={{ width: "94%" }}>
          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Crop
          </label>
          <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
            *
          </label>
          <Select
            options={cropOptions}
            onChange={(selected: SingleValue<any>) => {
              setcropId(selected);
            }}
            value={cropId}
            {...DropdownProps}
          />

          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Variety
          </label>
          <Select
            options={varietyOption}
            onChange={(selected: MultiValue<any>) => {
              setCropVarietyId(selected);
            }}
            isMulti
            value={cropVarietyId}
            {...DropdownProps}
          />

          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Applicable Stages
          </label>
          <Select
            options={stageOptions}
            onChange={(selected: MultiValue<any>) => {
              setSelectedApplicableStages(selected);
            }}
            isMulti
            value={selectedApplicableStages}
            {...DropdownProps}
          />

          <LocaleDropdown
            value={id}
            title={isPest ? "Pest" : "Disease"}
            titleStyle={{ fontSize: 19, fontWeight: "bold" }}
            onChange={(selected: SingleValue<any>) => {
              setDiseasePest(selected);
            }}
            containerStyle={{ width: "98%" }}
            isImportant={true}
          />

          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            style={{ marginTop: 55 }}
          >
            <input
              type="button"
              value="Submit"
              onClick={onSubmit}
              style={{
                width: "45%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
              }}
            />
            <input
              type="button"
              value="Delete"
              onClick={onDelete}
              style={{
                width: "45%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
              }}
            />
          </Grid>
        </form>
      </div>
    </Grid>
  );
};

export default DiseasePest;
