import React, { useState } from 'react';
import { MaintenanceSlider, NewMaintenanceForm } from '../components';

const MaintenancePage = (): JSX.Element => {

  const [formState, setFormState] = useState({
    "details": '',
    "plotId": '',
    "id": '',
    "farmId": '',
    "farmUserId": '',
    "servicedBy": '',
    "done": false,
    "date": '',
    "tasks": [{
      "maintenanceType": '',
      "sensor": '',
    }],
  })
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if (edit === true) { setEdit(false); }
    setOpen(false);
  };
  return (
    <div style={{ minHeight: '100vh' }}>
      <div style={{ position: 'sticky', top: '90vh', zIndex: '10', width: '120px', height: '38px', left: '85vw', bottom: '10px' }} >
        <NewMaintenanceForm formState={formState} setFormState={setFormState} open={open} setOpen={setOpen} edit={edit} setEdit={setEdit} handleOpen={handleOpen} handleClose={handleClose} />
      </div>
      <MaintenanceSlider formState={formState} setFormState={setFormState} open={open} setOpen={setOpen} edit={edit} setEdit={setEdit} handleOpen={handleOpen} handleClose={handleClose} />

    </div>
  )
}

export default MaintenancePage