import _ from 'lodash'
import { generateWhereClauseForFilters } from "src/utils/helper"
import { KeyValues } from "src/constants/interfaces"
import { getCrops } from "src/services/crop.service"
import { getFarms } from "src/services/farm.service"
import { getFarmsByFarmUser } from "src/services/farmUser.service"
import { getPlotsWithPagination } from "src/services/plot.service"
import { getDevices } from 'src/services/device.service'

type Args = [number, number, string[]]

// filter handlers
const FarmHandler = async (text: string) => {
  let plotIds: string[] = []
  const farms = await getFarms('', text)
  const plotIds2d = farms.map((farm: any) => farm?.plots?.map((plot: any) => plot.plotId))
  for (const plotIds1d of plotIds2d) {
    plotIds = [...plotIds1d, ...plotIds]
  }
  return plotIds;
}

const CropHandler = async (cropId: string) => {
  let payload: any = {
    cropId
  }
  const res: KeyValues[] = await getCrops(payload);
  const plotIds = res.map((plotId) => plotId.plotId) as string[];

  return plotIds;
}

const getPlotIds = (farmUsers: any) => {
  const plotIds: string[] = []
  for (const farmUser of farmUsers) {
    const { farms } = farmUser
    if (!farms) {
      continue;
    }
    for (const farm of farms) {
      const { plots } = farm
      if (!plotIds) {
        continue
      }
      for (const plot of plots) {
        const { plotId } = plot
        plotIds.push(plotId)
      }
    }
  }

  return plotIds
}

const FarmUserHandler = async (text: string = '', mobile: string = '') => {
  const id = ''
  const name = text

  const values = {
    name, id, mobile
  }

  if (
    (id.length > 0 && id.length < 3) ||
    (name.length > 0 && name.length < 3) ||
    (mobile.length > 0 && mobile.length < 3)
  ) {
    // alert('atleast 3 characters required for farm user')
  }

  const farmUsers: any = await getFarmsByFarmUser(values)
  return getPlotIds(farmUsers)
}

const PlotIdHandler = async (text: string) => {
  const where = generateWhereClauseForFilters({ plotId: text })
  const plots: any[] = await getPlotsWithPagination('', 0, 0, [], where)
  const plotIds: string[] = plots.map((plot) => plot.plotId)
  return plotIds
}

const DistrictHandler = async (text: string) => {
  const where = generateWhereClauseForFilters({ district: text })
  const plots: any[] = await getPlotsWithPagination('', 0, 0, [], where)
  const plotIds: string[] = plots.map((plot) => plot.plotId)
  return plotIds
}

const PlotHandler = async (text: string) => {
  const where = generateWhereClauseForFilters({name: text})
  const plots: any[] = await getPlotsWithPagination('', 0, 0, [], where)
  const plotIds: string[] = plots.map((plot) => plot.plotId)
  return plotIds
}

const DeviceHandler = async (key: string, text: string) => {
  const plots: any[] = await getDevices({ [key]: text });
  const plotIds: string[] = plots.map((plot) => plot.plotId);
  return plotIds;
}

const FilterHandelerMapping: any = {
  name: PlotHandler,
  plotId: PlotIdHandler,
  farm: FarmHandler,
  farmUser: FarmUserHandler,
  district: DistrictHandler,
  cropSown: CropHandler,
  device: DeviceHandler,
}

export const handleFilters = async (filters: any, ...args: Args) => {
  let allResults: any[] = []
  let count = 0;
  for (let filter in filters) {
    const [filterMap, filterKey] = filter.split('.')
    const filterFunction = FilterHandelerMapping[filterMap]
    if(filterMap === 'farmUser' && filter.split('.')[1] === 'mobile')
      allResults.push(await filterFunction('', filters[filter]))
    else if(filterMap === 'device') 
      allResults.push(await filterFunction(filterKey, filters[filter]));
    else 
      allResults.push(await filterFunction(filters[filter]));
    count++;
  }

  if(count === 0) return [];

  let intersectedArray: string[] = [...allResults[0]]

  for(const plotIds of allResults){
    intersectedArray = _.intersection(intersectedArray, plotIds)
  }

  if(args[2].length > 0){
    return _.intersection(intersectedArray, args[2])
  }

  return intersectedArray
}
