import {
  ICellRendererComp,
  ICellRendererParams,
} from 'ag-grid-community';
import { isArrayWithLength } from '../helper';

export class QuantityRenderer implements ICellRendererComp {
  eGui!: HTMLSpanElement;
  plotQuantityPredictions: number | string = 0;
  init(params: ICellRendererParams) {
    this.eGui = document.createElement('span');
    this.eGui.style.display = "flex";
    this.eGui.style.alignItems = "center";
    this.eGui.style.padding = "0px";
    const { plotQuantityPredictions = [] } = params.data;

    if (isArrayWithLength(plotQuantityPredictions)) {
      this.plotQuantityPredictions = plotQuantityPredictions.slice(-1)[0].value.toFixed(2);
    } else {
      this.plotQuantityPredictions = `<span style="padding: 5px 10px; border-radius: 10px; border: 1px solid silver; background-color: lightgreen">Add Data</span>`
    }
    this.eGui.innerHTML += ` <span style="font-size: 12px;"> ${this.plotQuantityPredictions}</span>`;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}