import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Modal, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { memo, useEffect, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import { GraphType } from "src/pages/BusinessDashboard";
import { diseasePestRanges } from 'src/pages/BusinessDashboard/constant';
import {
  getCropDiseases,
  getCropOptions,
  getCropPests,
} from "src/services/crop.service";
import { getPestDiseasePrediction, getUserRegionCrop } from "src/services/region.service";
import { generateOptions, generateOptionsFromArray, isArrayWithLength } from "src/utils/helper";
import DownloadRegionData from "../sections/DownloadData";
import {
  Collapsibles,
  CropDetails,
  getDiseasePestColor,
} from "../sections/FilterSection";
import DownloadDataIcon from 'src/assets/download-data.png';
import { ToastAlert } from "src/components";

type Props = {
  isVisible: boolean;
  closeRestCollapsiblesExcept: (x: Collapsibles) => void;
  setIndexData: (x: any) => void;
  setCropData: (x: any) => void;
  handleFilterUpdate: (x: any) => void;
  setGraphType: (x: GraphType) => void;
  setGraphSubType: (x: string) => void;
  setIsFilterLoading: (x: boolean) => void;
  mapsDate: any;
  selectedCropOption: any;
  setSelectedCropOption: (x: any) => void;
};


export const DropDownStyles = {
  container: (baseStyles: any, state: any) => ({
    ...baseStyles,
    width: "100%",
    marginLeft: "5px",
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    borderTop: state.isFocused ? "1px" : "0px",
    borderLeft: state.isFocused ? "1px" : "0px",
    borderRight: state.isFocused ? "1px" : "0px",
  }),
  menu: (baseStyles: any, state: any) => ({
    ...baseStyles,
    zIndex: 100,
  }),
};

export const DropDownProps = {
  styles: DropDownStyles,
  isClearable: true,
  isSearchable: true,
  placeholder: "- select -",
};

export const DiseaseGridProps = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: "1px",
};

const Severities = generateOptionsFromArray(['Nil', 'Low', 'Medium', 'High']);

export type TDownloadStatus = 'failed' | 'fulfilled' | 'pending';
const DownloadSuccessMessage = 'Download Completed Successfully!';
const DownloadErrorMessage = 'Something went wrong, please try again';

const CropFilter: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    isVisible,
    closeRestCollapsiblesExcept,
    setIndexData,
    setCropData,
    handleFilterUpdate,
    setGraphType,
    setGraphSubType,
    setIsFilterLoading,
    mapsDate,
    selectedCropOption,
    setSelectedCropOption,
  } = props;

  // diesase
  const [cropDetails, setCropDetails] = useState<CropDetails>({
    name: "",
    disease: "",
    pest: "",
  });
  const todaysDate = new Date();
  const [diseaseDate, setDiseaseDate] = useState<Date | null>(
    dayjs(todaysDate).add(-1, "day").toDate()
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // drodown options
  const [crops, setCrops] = useState<SingleValue<any>>([]);
  const [diseases, setDiseases] = useState<SingleValue<any>>([]);
  const [pests, setPests] = useState<SingleValue<any>>([]);

  // selected options
  const [selectedDiseasesOption, setSelectedDiseasesOption] = useState<any>(null);
  const [selectedPestsOption, setSelectedPestsOption] = useState<any>(null);
  const [selectedSeverityOptions, setSelectedSeverityOptions] = useState<any>(Severities);

  // loading states
  const [isCropLoading, setIsCropLoading] = useState<boolean>(true);
  const [isDiseasesLoading, setIsDiseasesLoading] = useState<boolean>(false);
  const [isPestsLoading, setIsPestsLoading] = useState<boolean>(false);

  // submit button
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true);

  // download-region data modal
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState<boolean>(false);
  const [downloadDataCrops, setDownloadDataCrops] = useState<MultiValue<any>>();
  const [downloadStatus, setDownloadStatus] = useState<TDownloadStatus>('pending');


  const handleCropDataChange = (updatedCropDetails: any): void => {
    setCropDetails(updatedCropDetails);
  };

  useEffect(() => {
    getCropOptions().then((res) => {
      const roles = JSON.parse(localStorage.getItem('roles')!);
      if (roles?.length === 1 && roles[0] === 'plantProtection') {
        const userId = localStorage.getItem('userId');
        getUserRegionCrop(userId as string).then((crop) => {
          const cropIds: string[] = Array.from(
            new Set(crop.flatMap((d: any) => d.regionCropMappings.map((m: any) => m.cropId)))
          )
          const filteredCrops = res.filter((crop: any) => cropIds.includes(crop.id));
          setCrops(generateOptions(filteredCrops, "id", "cropName"));
        })
      }
      else {
        setCrops(generateOptions(res, "id", "cropName"));
      }
      setIsCropLoading(false);
    });
  }, []);

  useEffect(() => {
    if (isArrayWithLength(crops) && !downloadDataCrops) {
      setDownloadDataCrops(crops);
    }
  }, [crops]);

  useEffect(() => {
    setDiseaseDate(mapsDate)
  }, [mapsDate])

  useEffect(() => {
    if (!!diseaseDate && isVisible && !isSubmitButtonDisabled) {
      showResults()
    }
  }, [diseaseDate]);

  const handleCropChange = (selected: SingleValue<any>) => {
    setSelectedCropOption(selected);
    setSelectedSeverityOptions(Severities);
    const { value } = selected;

    // handleCropDataChange((x: any) => { return { ...prevData, name: value } })
    handleCropDataChange((x: any) => {
      return { ...x, name: value };
    });

    setIsDiseasesLoading(true);
    setIsPestsLoading(true);
    unselectOptions(["pest", "disease"]);

    getCropDiseases(value).then((res) => {
      setDiseases(generateOptions(res, "diseaseId", "name"));
      setIsDiseasesLoading(false);
    });

    getCropPests(value).then((res) => {
      setPests(generateOptions(res, "pestId", "name"));
      setIsPestsLoading(false);
    });
  };

  const handleDiseaseChange = (selected: SingleValue<any>) => {
    setSelectedDiseasesOption(selected);
    handleCropDataChange((prevData: CropDetails) => {
      return { ...prevData, disease: selected.value, pest: "" };
    });
  };

  const handlePestChange = (selected: SingleValue<any>) => {
    setSelectedPestsOption(selected);
    handleCropDataChange((prevData: CropDetails) => {
      return { ...prevData, pest: selected.value, disease: "" };
    });
  };

  const handleDropdownChange = (
    dropdown: string,
    selected: SingleValue<any> | MultiValue<any>
  ) => {

    if (dropdown === 'severity') {
      if (selected.length === 0)
        setSelectedSeverityOptions(Severities)
      else
        setSelectedSeverityOptions(selected);

      return;
    }

    const value = selected?.value;
    switch (dropdown) {
      case "crop":
        setIsSubmitButtonDisabled(true);
        if (value) handleCropChange(selected);
        else unselectOptions(["crop", "disease", "pest"]);
        break;
      case "disease":
        if (value) {
          setIsSubmitButtonDisabled(false);
          handleDiseaseChange(selected);
          unselectOptions(["pest"]);
          if (!!errorMessage) setErrorMessage(null);
        } else {
          unselectOptions(["disease"]);
          setIsSubmitButtonDisabled(true);
        }
        break;
      case "pest":
        if (value) {
          setIsSubmitButtonDisabled(false);
          handlePestChange(selected);
          unselectOptions(["disease"]);
          if (!!errorMessage) setErrorMessage(null);
        } else {
          unselectOptions(["pest"]);
          setIsSubmitButtonDisabled(true);
        }
        break;
      default:
        break;
    }
  };

  const unselectOptions = (toUnselect: string[]) => {
    let cnt = 0;
    for (const field of toUnselect) {
      if (field === "crop") {
        setSelectedCropOption(null);
        handleCropDataChange((prevData: CropDetails) => {
          return { ...prevData, name: "" };
        });
        cnt++;
      } else if (field === "disease") {
        setSelectedDiseasesOption(null);
        handleCropDataChange((prevData: CropDetails) => {
          return { ...prevData, disease: "" };
        });
        if (cnt++ > 0) setDiseases([]);
      } else {
        setSelectedPestsOption(null);
        handleCropDataChange((prevData: CropDetails) => {
          return { ...prevData, pest: "" };
        });
        if (cnt++ > 0) setPests([]);
      }
    }
  };

  const showResults = () => {
    const { disease, name, pest } = cropDetails;

    if (disease.length === 0 && name.length > 0 && pest.length > 0) {
      setIsFilterLoading(true)
      setGraphType('pest')
      setGraphSubType(pest)
      getPestDiseasePrediction({
        cropId: name,
        type: 'pest',
        typeId: pest,
        date: dayjs(diseaseDate!).format('YYYY-MM-DD').toString(),
        riskLevel: selectedSeverityOptions
      }).then((res) => {
        if (res.length > 0) {
          setCropData(
            res.map((val: any) => ({
              ...val,
              color: getDiseasePestColor(val.riskLevel),
              value: val.probability,
              location: {
                lat: val.region.geoLocation.coordinates[1],
                lng: val.region.geoLocation.coordinates[0]
              }
            }))
          );
          setIndexData(diseasePestRanges);
        } else {
          setCropData([]);
        }
      })
        .finally(() => {
          setIsFilterLoading(false)
        })
    }
    if (disease.length > 0 && name.length > 0 && pest.length === 0) {
      setIsFilterLoading(true)
      setGraphType('disease')
      setGraphSubType(disease)
      getPestDiseasePrediction({
        cropId: name,
        type: 'disease',
        typeId: disease,
        date: dayjs(diseaseDate!).format('YYYY-MM-DD').toString(),
        riskLevel: selectedSeverityOptions
      }).then((res) => {
        if (res.length > 0) {
          setCropData(
            res.map((val: any) => ({
              ...val,
              color: getDiseasePestColor(val.riskLevel),
              value: val.probability,
              location: {
                lat: val.region.geoLocation.coordinates[1],
                lng: val.region.geoLocation.coordinates[0]
              }
            }))
          );
          setIndexData(diseasePestRanges);
        } else {
          setCropData([]);
        }
      })
        .finally(() => {
          setIsFilterLoading(false)
        })
    }

    handleFilterUpdate("crop");
  };

  const openDownloadModal = () => setIsDownloadModalVisible(true);
  const closeDownloadModal = () => setIsDownloadModalVisible(false);

  const openDownloadResultAlert = (state: boolean) => {
    setDownloadStatus('pending');
  }

  return (
    <Grid my={3} boxShadow={2} borderRadius={3} px={2}>
      <Grid
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid display={"flex"} alignItems={"center"}>
          <Typography
            letterSpacing={0.8}
            variant="overline"
            textTransform={"capitalize"}
            fontSize={20}
            data-testid="dashboard-Pest-Disease"
            mr={0.7}
          >
            Pest/Disease
          </Typography>
          <IconButton onClick={openDownloadModal}> <img src={DownloadDataIcon} alt="download-region-data-icon" style={{ width: 15, height: 15 }} /> </IconButton>
        </Grid>
        <IconButton
          onClick={() => closeRestCollapsiblesExcept(Collapsibles.disease)}
        >
          {isVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </Grid>
      {isVisible && (
        <Grid pb={2}>
          <Grid px={2} mb={2}>
            <Grid {...DiseaseGridProps}>
              <Typography variant="subtitle1" mr={3}>
                Crop
              </Typography>
              <Select
                options={crops}
                onChange={(selected: SingleValue<any>) =>
                  handleDropdownChange("crop", selected)
                }
                isLoading={isCropLoading}
                isDisabled={isCropLoading}
                value={selectedCropOption}
                {...DropDownProps}
              />
            </Grid>

            <Grid {...DiseaseGridProps}>
              <Typography variant="subtitle1">Disease</Typography>
              <Select
                options={diseases}
                onChange={(selected: SingleValue<any>) =>
                  handleDropdownChange("disease", selected)
                }
                isLoading={isDiseasesLoading}
                isDisabled={!selectedCropOption || isDiseasesLoading}
                value={selectedDiseasesOption}
                {...DropDownProps}
              />
            </Grid>

            <Grid {...DiseaseGridProps}>
              <Typography variant="subtitle1" mr={3.23}>
                Pest
              </Typography>
              <Select
                options={pests}
                onChange={(selected: SingleValue<any>) =>
                  handleDropdownChange("pest", selected)
                }
                isLoading={isPestsLoading}
                isDisabled={!selectedCropOption || isPestsLoading}
                value={selectedPestsOption}
                {...DropDownProps}
              />
            </Grid>

            <Grid {...DiseaseGridProps}>
              <Typography variant="subtitle1">
                Severity
              </Typography>
              <Select
                options={Severities}
                isMulti
                onChange={(selected: MultiValue<any>) =>
                  handleDropdownChange("severity", selected)
                }
                value={selectedSeverityOptions}
                {...DropDownProps}
              />
            </Grid>
          </Grid>

          <Grid>
            <Button
              variant="outlined"
              fullWidth
              style={{ borderRadius: "20px" }}
              size="large"
              color="success"
              disabled={isSubmitButtonDisabled}
              onClick={showResults}
            >
              Show Results
            </Button>
          </Grid>

          {!!errorMessage && (
            <Grid mt={1}>
              <Typography
                ml={1}
                variant="caption"
                fontSize={10}
                color={"red"}
                fontWeight={"bold"}
              >
                {errorMessage}
              </Typography>
            </Grid>
          )}
        </Grid>
      )}

      {/* download region-data in excel */}
      <Modal
        open={isDownloadModalVisible}
        onClose={closeDownloadModal}
      >
        <Box sx={modalStyle}>
          <DownloadRegionData crops={downloadDataCrops!} setDownloadStatus={setDownloadStatus} />
        </Box>
      </Modal>


      {/* 
        if we use common component for alert,
        just before success alert closes, error alert is visible for few ms
      */}

      {/* error tost */}
      <ToastAlert
        open={downloadStatus === 'failed'}
        setOpen={openDownloadResultAlert}
        severity={'error'}
        message={DownloadErrorMessage}
      />

      {/* success toast */}
      <ToastAlert
        open={downloadStatus === 'fulfilled'}
        setOpen={openDownloadResultAlert}
        severity={'success'}
        message={DownloadSuccessMessage}
      />
    </Grid>
  );
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '0.5px solid #000',
  boxShadow: 24,
  borderRadius: 3,
};

export default memo(CropFilter);
