import { Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  postCropStage,
  patchCropStage,
  deleteCropStage,
  getCropVarieties,
} from "src/services/crop.service";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Select, { SingleValue, MultiValue } from "react-select";
import { ToastAlert } from "src/components";
import LocaleDropdown from "../LocaleDropdown";
import { generateOptions } from "src/utils/helper";
import { ToastSeverity } from "../ToastAlert/types";

interface Props {
  selectedModalData?: any;
  setOpenModal?: any;
  cropOptions?: any;
}

const DropdownProps = {
  isClearable: true,
  isSearchable: true,
  placeholder: "- select -",
  styles: {
    container: (baseStyles: any) => ({
      ...baseStyles,
      width: "100%",
      padding: 2,
      fontSize: 16,
      margin: "3px 0",
      marginBottom: 11,
      marginTop: 6,
    }),
  },
};

const Stage: React.FC<Props> = (props: Props): JSX.Element => {
  const { selectedModalData, setOpenModal, cropOptions } = props;
  const [cropId, setcropId] = useState<SingleValue<any>>(
    cropOptions?.filter((c: any) => c?.id === selectedModalData?.cropId)[0] ||
      ""
  );
  const [order, setOrder] = useState(selectedModalData?.order || 0);
  const [duration, setDuration] = useState(selectedModalData?.duration || 0);
  const [stage, setStage] = useState<SingleValue<any>>();
  const [varietyOption, setVarietyOption] = useState<any[]>([]);
  const [cropVarietyId, setCropVarietyId] = useState<MultiValue<any>>([]);
  const [varietyId, setVarietyId] = useState<any[]>(
    selectedModalData?.cropVarietyId || []
  );
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState<ToastSeverity>("success");
  const [openToast, setOpenToast] = useState<boolean>(false);

  const onSubmit = () => {
    if (cropId?.id) {
      if (window.confirm("Do you want to submit?")) {
        const finalData: any = {
          stageId: stage?.value,
          name: stage?.label,
          order: order,
          duration: duration,
          cropId: cropId?.id,
        };
        if (cropVarietyId.length) {
          finalData.cropVarietyId = cropVarietyId?.map((v) => v?.value);
        }
        if (selectedModalData?.id) {
          patchCropStage(finalData, selectedModalData?.id).then(
            (response: any) => {
              if (response.message === "Success") {
                setToastMessage("Stage Updated");
                setToastSeverity("success");
                setOpenModal(false);
              } else {
                setToastMessage("Some Error Ocuured");
                setToastSeverity("error");
                setOpenModal(false);
              }
            }
          );
        } else {
          postCropStage(finalData?.cropId, [finalData]).then(
            (response: any) => {
              if (response[0]?.id) {
                setToastMessage("Stage Added");
                setToastSeverity("success");
                setOpenModal(false);
              } else {
                setToastMessage("Some Error Ocuured");
                setToastSeverity("error");
                setOpenModal(false);
              }
            }
          );
        }
      }
    } else {
      setToastMessage("Some Error Ocuured");
      setToastSeverity("error");
    }
    setOpenToast(true);
  };

  const onDelete = () => {
    if (selectedModalData?.id) {
      if (window.confirm("Do you want to delete?")) {
        deleteCropStage(selectedModalData?.id).then((response: any) => {
          if (response.message === "Success") {
            setToastMessage("Stage Deleted");
            setToastSeverity("success");
            setOpenModal(false);
          } else {
            setToastMessage("Some Error Ocuured");
            setToastSeverity("error");
            setOpenModal(false);
          }
        });
      }
    } else {
      setToastMessage("Some Error Ocuured");
      setToastSeverity("error");
    }
    setOpenToast(true);
  };

  useEffect(() => {
    if (cropId?.id) {
      getCropVarieties(cropId?.id).then((res) =>
        setVarietyOption(generateOptions(res, "id", "name"))
      );
    }
  }, [cropId]);

  useEffect(() => {
    if (varietyOption.length) {
      setCropVarietyId(
        varietyOption?.filter((variety) => varietyId.includes(variety?.value))
      );
    }
  }, [varietyOption]);

  const navigate = useNavigate();

  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid
        p={2}
        borderRadius={20}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton
            onClick={() =>
              setOpenModal
                ? setOpenModal(false)
                : navigate("/cropCharacteristics")
            }
          >
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Crop Stage
          </Typography>
        </Grid>
      </Grid>
      <ToastAlert
        open={openToast}
        setOpen={setOpenToast}
        severity={toastSeverity}
        message={toastMessage}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 30,
        }}
      >
        <form style={{ width: "94%" }}>
          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Crop
          </label>
          <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
            *
          </label>
          <Select
            options={cropOptions}
            onChange={(selected: SingleValue<any>) => {
              setcropId(selected);
            }}
            value={cropId}
            {...DropdownProps}
          />

          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Variety
          </label>
          <Select
            options={varietyOption}
            onChange={(selected: MultiValue<any>) => {
              setCropVarietyId(selected);
            }}
            isMulti
            value={cropVarietyId}
            {...DropdownProps}
          />

          <LocaleDropdown
            value={selectedModalData?.stageId}
            title="Stage"
            titleStyle={{ fontSize: 19, fontWeight: "bold" }}
            onChange={(selected: SingleValue<any>) => {
              setStage(selected);
            }}
            containerStyle={{ width: "98%" }}
            isImportant={true}
          />

          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Order
          </label>
          <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
            *
          </label>
          <input
            onChange={(e) => setOrder(Number(e.target.value))}
            value={order}
            type="number"
            min={0}
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
          ></input>
          <br />

          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Duration
          </label>
          <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
            *
          </label>
          <input
            onChange={(e) => setDuration(Number(e.target.value))}
            value={duration}
            type="number"
            min={0}
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
          ></input>
          <br />

          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            style={{ marginTop: 55 }}
          >
            <input
              type="button"
              value="Submit"
              onClick={onSubmit}
              style={{
                width: "45%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
              }}
            />
            <input
              type="button"
              value="Delete"
              onClick={onDelete}
              style={{
                width: "45%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
              }}
            />
          </Grid>
        </form>
      </div>
    </Grid>
  );
};

export default Stage;
